/* eslint-disable new-cap */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps,
    NavigationAbstractContainer as SourceNavigationAbstract
} from 'SourceComponent/NavigationAbstract/NavigationAbstract.container';

import { DEFAULT_STATE_NAME } from './NavigationAbstract.config';

export const DEFAULT_STATE = { name: DEFAULT_STATE_NAME };

/** @namespace Pwa/Component/NavigationAbstract/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/NavigationAbstract/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch)
});

/** @namespace Pwa/Component/NavigationAbstract/Container/NavigationAbstractContainer */
export class NavigationAbstractContainer extends SourceNavigationAbstract {

}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationAbstractContainer);
