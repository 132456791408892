/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable fp/no-let */
/* eslint-disable fp/no-let */

import { isEmpty } from 'lodash';

/**
 * Has free shipping?
*/
export const hasFreeShipping = (product = {}, singleCheck = '') => {
    if (isEmpty(product)) {
        return false;
    }
    const { attributes = {} } = product;
    if (isEmpty(attributes)) {
        return false;
    }

    if (Array.isArray(attributes)) {
        const shipping_group_attribute = attributes.find((item) => item.attribute_id === 293);

        if (shipping_group_attribute) {
            const { attribute_value } = shipping_group_attribute;
            const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;
            if (attribute_value.length > 0) {
                const shippingGroups = attribute_value.split(',');

                // Never allow free shipping for B2B
                if (isB2B) {
                    if (shippingGroups.includes('7043')) {
                        return false;
                    }
                }

                // Never allow free shipping
                if (shippingGroups.includes('7033')) {
                    return false;
                }

                // Free shipping / Free shipping allowed
                if (!isEmpty(singleCheck)) {
                    if (shippingGroups.includes(singleCheck)) {
                        return true;
                    }
                } else if (shippingGroups.includes('1406') || shippingGroups.includes('7035')) {
                    return true;
                }
            }
        }
    }

    const { shipperhq_shipping_group = {} } = attributes;
    if (isEmpty(shipperhq_shipping_group)) {
        return false;
    }

    if (shipperhq_shipping_group) {
        // Check if B2B customer (Use local storage check)
        const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        const { attribute_value = '' } = shipperhq_shipping_group;
        if (attribute_value.length > 0) {
            const shippingGroups = attribute_value.split(',');

            // Never allow free shipping for B2B
            if (isB2B) {
                if (shippingGroups.includes('7043')) {
                    return false;
                }
            }

            // Never allow free shipping
            if (shippingGroups.includes('7033')) {
                return false;
            }

            // Free shipping / Free shipping allowed
            if (!isEmpty(singleCheck)) {
                if (shippingGroups.includes(singleCheck)) {
                    return true;
                }
            } else if (shippingGroups.includes('1406') || shippingGroups.includes('7035')) {
                return true;
            }
        }
    }

    return false;
};

/**
 * Check if product is a roof rack kit
 * TODO: Check by category ids
 */
export const isRackKit = (productOrVariant) => {
    const { sku, name } = productOrVariant;

    if (isEmpty(sku)) {
        return false;
    }

    // Check if sku starts with KR or KS or KV and contains the word "rack"
    if ((sku.startsWith('KR') || sku.startsWith('KS') || sku.startsWith('KV') || sku.startsWith('PB')) && (!sku.startsWith('KRX') && !sku.startsWith('KVX') && !sku.startsWith('PBAC')) && (name.toLowerCase().includes('slimline') || name.toLowerCase().includes('slimsport') || name.toLowerCase().includes('slimpro') || name.toLowerCase().includes('pro bed'))) {
        return true;
    }

    return false;
};
