import { lowerCase } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CountryFlags.style';

/** @namespace Pwa/Component/CountryFlags/Component/CountryFlagsComponent */
export class CountryFlagsComponent extends PureComponent {
  static propTypes = {
      code: PropTypes.string
  };

  static defaultProps = {
      code: 'us'
  };

  render() {
      const { code } = this.props;
      return (
          <div className={ `flag flag-32 flag-${lowerCase(code)}` } />
      );
  }
}

export default CountryFlagsComponent;
