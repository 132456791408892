import { Field } from 'Util/Query';

/** @namespace Pwa/Query/Authnet/Query/AuthnetQuery */
export class AuthnetQuery {
    getSavedCards() {
        return new Field('tokenBaseCards')
            .addFieldList([
                'hash',
                'customer_email',
                'customer_id',
                'customer_ip',
                'profile_id',
                'payment_id',
                'method',
                'active',
                'created_at',
                'updated_at',
                'last_use',
                'expires',
                'label'
            ])
            .addField(this.getAdditionalFields());
    }

    getAdditionalFields() {
        return new Field('additional')
            .addFieldList([
                'cc_type',
                'cc_owner',
                'cc_bin',
                'cc_last4',
                'cc_exp_year',
                'cc_exp_month',
                'echeck_bank_name',
                'echeck_routing_no',
                'echeck_routing_number_last4',
                'echeck_account_name',
                'echeck_account_type',
                'echeck_account_no',
                'echeck_account_number_last4'
            ]);
    }
}

export default new AuthnetQuery();
