/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { compact, values } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

// import { goToPreviousHeaderState } from 'Store/Header/goToPreviousHeaderState';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import ProductDispatcher from 'Store/Product/Product.dispatcher';
import { customerType } from 'Type/Account';
import { ProductType } from 'Type/ProductList';
import { RatingItemsType } from 'Type/Rating.type';

import ProductQAForm from './ProductQAForm.component';

/** @namespace Pwa/Component/ProductQAForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    isSignedIn: state.MyAccountReducer.isSignedIn
});

/** @namespace Pwa/Component/ProductQAForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProductQA: (options) => ProductDispatcher.submitProductQA(dispatch, options),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE))
});

/** @namespace Pwa/Component/ProductQAForm/Container/ProductQAFormContainer */
export class ProductQAFormContainer extends PureComponent {
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        product: ProductType.isRequired,
        customer: customerType.isRequired
    };

    containerFunctions = ({
        onQASubmitAttempt: this._onQASubmitAttempt.bind(this),
        onQASubmitSuccess: this._onQASubmitSuccess.bind(this),
        handleNameChange: this._handleFieldChange.bind(this, 'name'),
        handleEmailChange: this._handleFieldChange.bind(this, 'email'),
        handleDescriptionChange: this._handleFieldChange.bind(this, 'description'),
        handleCaptchaChange: this._handleFieldChange.bind(this, 'captcha'),
        onQAError: this._onQAError.bind(this)
    });

    __construct(props) {
        super.__construct(props);

        const { customer: { firstname: name } } = this.props;
        const qaData = { name };

        this.state = {
            isLoading: false,
            qaData
        };
    }

    _onQAError() {
        this.setState({ isLoading: false });
    }

    _onQASubmitAttempt(_, invalidFields) {
        const { showNotification } = this.props;
        const { qaData = {} } = this.state;
        this.setState({ showValidationError: '' });

        const SubmissionNotValid = invalidFields || !(compact(values(qaData)).length === 4);

        if (SubmissionNotValid) {
            // showNotification('error', 'Incorrect data! Please check fields.');
        }

        this.setState({ isLoading: !SubmissionNotValid });
    }

    _onQASubmitSuccess(fields) {
        const {
            qaData
        } = this.state;
        const {
            product,
            customer,
            addProductQA,
            hideActiveOverlay,
            goToPreviousHeaderState
        } = this.props;

        // this.setState({ isLoading: false });

        // return

        const {
            name: author_name,
            email: author_email,
            description: question,
            captcha: recaptcha_response
        } = qaData;

        const { sku: product_sku } = product;
        const { id: customer_id = 0 } = customer;

        addProductQA({
            author_name,
            author_email,
            question,
            product_sku,
            recaptcha_response,
            customer_id
        }).then(
            /** @namespace Pwa/Component/ProductQAForm/Container/addProductQA/then */
            (success) => {
                if (success) {
                    this.setState({
                        qaData: {},
                        isLoading: false
                    });

                    goToPreviousHeaderState();
                    hideActiveOverlay();

                    return;
                }
                this.setState({ isLoading: false });
            }
        );
    }

    _handleFieldChange(fieldName, value) {
        this.setState(({ qaData }) => ({
            qaData: { ...qaData, [fieldName]: value }
        }));
    }

    render() {
        return (
            <ProductQAForm
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductQAFormContainer);
