/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { get } from 'lodash';

// import { MenuQuery, CmsBlockQuery } from 'Query'
import HeaderAndMenuQuery from 'Query/HeaderAndMenu.query';
import { updateMenu } from 'Store/HeaderAndFooter/HeaderAndFooter.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { QueryDispatcher } from 'Util/Request';
// import { updateCmsBlocks } from 'Store/CmsBlocksAndSlider'

/** @namespace Pwa/Store/HeaderAndFooter/Dispatcher/HeaderAndFooterDispatcher */
export class HeaderAndFooterDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess(options, dispatch) {
        const mobileMenu = get(options, 'customMobileHeader.mobile_menu', {});
        const mobileHeaderTextBanner = get(options, 'customMobileHeader.header_text_banner', '');
        const desktopMenu = get(options, 'desktopMenu.pannel_header[0].header_links[0]', {});

        dispatch(updateMenu({ mobileMenu, desktopMenu, mobileHeaderTextBanner }));
    }

    onError(error, dispatch) {
        dispatch(showNotification('error', 'Error fetching Header and Menu!', error));
    }

    /**
     * Prepare Header and Footer requests
     * @param {{ identifier: String }} options A object containing different aspects of query, each item can be omitted
     * @return {Query} Menu query
     * @memberof HeaderAndFooterDispatcher
     */
    prepareRequest(options) {
        return [
            HeaderAndMenuQuery.getMobileQuery(),
            HeaderAndMenuQuery.getDesktopQuery()
        ];
    }
}

export default new HeaderAndFooterDispatcher();
