/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { STATE_CONFIRM_EMAIL } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { FooterDispatcher } from 'Store/Footer';
import { updateGoogleRecaptcha } from 'Store/GoogleRecaptcha/GoogleRecaptcha.action';
import { NewsletterSubscriptionDispatcher } from 'Store/NewsletterSubscription';
import { showNotification } from 'Store/Notification/Notification.action';

import Footer from './Footer.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Pwa/Component/Footer/Container/FooterContainer */
export class FooterContainer extends Component {
    static propTypes = {
        onSignIn: PropTypes.func,
        setLoadingState: PropTypes.func

    };

    componentDidMount() {
        this.props.fetchFooter();
    }

    containerFunctions = {
        onCreateAccountSuccess: this.onCreateAccountSuccess.bind(this),
        onCreateAccountAttempt: this.onCreateAccountAttempt.bind(this)
    };

    onCreateAccountAttempt(_, invalidFields) {
        const { showNotification = () => {}, setLoadingState = () => {} } = this.props;
        this.setState({ showValidationError1: '' });

        if (invalidFields) {
            showNotification('info', __('Incorrect data! Please resolve all field validation errors.'));
        }

        setLoadingState(!invalidFields);
    }

    async onCreateAccountSuccess(fields) {
        const {
            createAccount,
            onSignIn = () => {},
            setSignInState,
            setLoadingState = () => {},
            isLoading,
            isLandingPage,
            showNotification,
            device
        } = this.props;
        const {
            password,
            email,
            firstname,
            lastname,
            is_subscribed,
            taxvat
        } = fields;
        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                is_subscribed,
                taxvat
            },
            password
        };

        if (isLoading) {
            return;
        }

        try {
            const code = await createAccount(customerData);

            // if user needs confirmation
            if (code === 2) {
                setSignInState(STATE_CONFIRM_EMAIL);

                if (isLandingPage || device.isMobile) {
                    showNotification(
                        'success',
                        // eslint-disable-next-line max-len
                        __('The email confirmation link has been sent to your email. Please confirm your account to proceed.')
                    );
                }
            } else {
                onSignIn();
            }
        } finally {
            setLoadingState(false);
        }
    }

    render() {
        return <Footer { ...this.props } { ...this.state } { ...this.containerFunctions } />;
    }
}

/** @namespace Pwa/Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    copyright: state.ConfigReducer.copyright,
    headerNotifications: state.ConfigReducer.header_notifications,
    data: state.FooterReducer.data,
    newLetterConfig: state.FooterReducer.newLetterConfig,
    isLoading: state.FooterReducer.isLoading,
    isLoadingMember: state.MyAccountReducer.isLoading,
    isNewsletterLoading: state.NewsletterSubscriptionReducer.isLoading,
    subscriptionResponse: state.NewsletterSubscriptionReducer.data,
    store_id: window.storeConfig.storeId,
    fsync_url: state.ConfigReducer.fsync_url,
    freeShippingFooterText: state.ConfigReducer.free_shipping_footer_text,
    free_shipping_footer_text_b2b: state.ConfigReducer.free_shipping_footer_text_b2b,
    is_free_shipping_enable: state.ConfigReducer.is_free_shipping_enable,
    showErrorNotification: PropTypes.func.isRequired,
    maintenance_data: state.MaintenanceReducer.data,
    customer: state.MyAccountReducer.customer,
    googleRecaptchaToken: state.GoogleRecaptchaReducer.recaptchaToken

});

/** @namespace Pwa/Component/Footer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    fetchFooter: () => FooterDispatcher.handleData(dispatch),
    newsletterSubscribePopup: (options) => NewsletterSubscriptionDispatcher.subscribePopup(dispatch, options),
    newsletterSubscribe: (options) => NewsletterSubscriptionDispatcher.subscribe(dispatch, options),
    showErrorNotification: (error) => dispatch(showNotification('error', error)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateGoogleRecaptcha: (recaptchaToken) => dispatch(updateGoogleRecaptcha(recaptchaToken)),
    createAccount: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.createAccount(options, dispatch)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
