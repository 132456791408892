/* eslint-disable no-magic-numbers */
/* eslint-disable import/prefer-default-export */

import { isProduction } from '../../util/Helper';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// export const YOTPO_DISABLE_STORE_IDS = [1, 8, 9, 11, 12, 14, 15, 17];
export const YOTPO_DISABLE_STORE_IDS = [];

export const STORE_IDS_FOR_SUBTOTAL_EXCLUDING_TAX = [5, 16];
export const STORE_IDS_FOR_SUBTOTAL_INCLUDING_TAX = [4, 7, 8, 9, 12, 14, 15, 17];

/** @namespace Pwa/Component/YotpoLoyalty/Config/getYotpoApiKey */
export function getYotpoApiKey() {
    if (!isProduction()) {
        return 'ogurJnoI8cWWIYu9kJhG6Qtt'; // US Staging
    }

    const storeCode = window.storeConfig.code;
    switch (storeCode) {
    case 'en_us':
        return 'vxcIbhFjNFa1Os3xpLY9rQtt';
    case 'es_us':
        return 'taqknksawmAj8QLgNXSEgQtt';
    case 'en_za':
        return 'LPsSrOMss7CgNtCUw5RjvAtt';
    case 'en_au':
        return 'oW0x1HGFfkwPyYOA8WZgewtt';
    case 'en_de':
        return 'CrLJSOLEOV7WGrBNE4nllwtt';
    case 'de_de':
        return 'f6B5blABkPE7qAN65QBKqgtt';
    case 'es_be':
        return 'oSpwiSdrZn4S1UbwoDQ7PAtt';
    case 'fr_be':
        return 'jNF7pwhCaJhAcaWjMQUtxAtt';
    case 'nl_be':
        return '4FJYjZNqKKO37gJpnHTQzgtt';
    case 'en_uk':
        return 'LL4r7E2fIFsjutKV2zT3HAtt';
    default:
        return 'ogurJnoI8cWWIYu9kJhG6Qtt';
    }
}

/** @namespace Pwa/Component/YotpoLoyalty/Config/getYotpoCheckoutWidgetId */
export function getYotpoCheckoutWidgetId() {
    if (!isProduction()) {
        return '557422'; // US Staging
    }

    const storeCode = window.storeConfig.code;
    switch (storeCode) {
    case 'en_us':
        return '542350';
    case 'es_us':
        return '544730';
    case 'en_za':
        return '542638';
    case 'en_au':
        return '542389';
    case 'en_de':
        return '544805';
    case 'de_de':
        return '542326';
    case 'es_be':
        return '542407';
    case 'fr_be':
        return '533716';
    case 'nl_be':
        return '541742';
    case 'en_uk':
        return '541741';
    default:
        return null;
    }
}
