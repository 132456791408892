/* eslint-disable @scandipwa/scandipwa-guidelines/always-both-mappings */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-console */
// import { get } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';

import LanguageSelector from './LanguageSelector.component';

/** @namespace Pwa/Component/LanguageSelector/Container/LanguageSelectorContainer */
export class LanguageSelectorContainer extends Component {
    render() {
        return (
            <LanguageSelector
                // currentStore={ 5 } // This will be removed later
              { ...this.props }
            />
        );
    }
}

/** @namespace Pwa/Component/LanguageSelector/Container/mapStateToProps */
export const mapStateToProps = (/* state */) => ({
    // data: get(state, 'HeaderAndFooterReducer.desktopMenu.store_switcher[0]', {})
    // blocks: state.CmsBlocksAndSliderReducer.blocks
});

export default connect(mapStateToProps, null)(LanguageSelectorContainer);
