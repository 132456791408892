/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CMS_PAGE } from 'Component/Header/Header.config';
import CmsPageQuery from 'Query/CmsPage.query';
import InstallationGuideQuery from 'Query/InstallationGuide.query';
import OggGraphQuery from 'Query/OggGraph.query';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import {
    ContactPageDispatcher, updateContactPageMakes, updateContactPageModels, updateContactPageOptions,
    updateContactPageYears
} from 'Store/ContactPage';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { NewsletterSubscriptionDispatcher } from 'Store/NewsletterSubscription';
import { showNotification } from 'Store/Notification/Notification.action';
import { setBigOfflineNotice } from 'Store/Offline/Offline.action';
import {
    MakesDispatcher, ModelsDispatcher, OptionsDispatcher, YearsDispatcher
} from 'Store/PartsFinder';
import { LocationType, MatchType } from 'Type/Common.type';
import { getGuestQuoteId } from 'Util/Cart';
// import { CmsPageDispatcher } from 'Store/CmsPage';
import { formatCleanQuery, getCurrentUrlForOggData } from 'Util/Helper';
import history from 'Util/History';
import { debounce, fetchMutation } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';
import { appendWithStoreCode, getUrlParam } from 'Util/Url';

import CmsPage from './CmsPage.component';
import { LOADING_TIME } from './CmsPage.config';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace Pwa/Route/CmsPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isOffline: state.OfflineReducer.isOffline,
    isNewsletterLoading: state.NewsletterSubscriptionReducer.isLoading,
    showErrorNotification: PropTypes.func.isRequired,
    yearsData: state.ContactPageReducer.yearsData,
    makesData: state.ContactPageReducer.makesData,
    modelsData: state.ContactPageReducer.modelsData,
    optionsData: state.ContactPageReducer.optionsData,
    isLoading: state.ContactPageReducer.isLoading
});

/** @namespace Pwa/Route/CmsPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    // requestPage: (options) => CmsPageDispatcher.handleData(dispatch, options),
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateWithCmsPage(breadcrumbs, dispatch)
    ),
    newsletterSubscribe: (options) => NewsletterSubscriptionDispatcher.subscribe(dispatch, options),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    toggleBreadcrumbs: (isActive) => {
        BreadcrumbsDispatcher.then(
            ({ default: dispatcher }) => dispatcher.update([], dispatch)
        );
        dispatch(toggleBreadcrumbs(isActive));
    },
    requestPartsFinderYears: (options) => YearsDispatcher.handleFsyncData(dispatch, options),
    requestPartsFinderMakes: (options) => MakesDispatcher.handleFsyncData(dispatch, options),
    requestPartsFinderModels: (options) => ModelsDispatcher.handleFsyncData(dispatch, options),
    requestPartsFinderOptions: (options) => OptionsDispatcher.handleFsyncData(dispatch, options),
    submitContactInformation: (options) => ContactPageDispatcher.submit(dispatch, options),
    showErrorNotification: (error) => dispatch(showNotification('error', error))
});

/** @namespace Pwa/Route/CmsPage/Container/CmsPageContainer */
export class CmsPageContainer extends DataContainer {
    static propTypes = {
        match: MatchType.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        setBigOfflineNotice: PropTypes.func.isRequired,
        location: LocationType.isRequired,
        toggleBreadcrumbs: PropTypes.func.isRequired,
        pageIds: PropTypes.number,
        pageIdentifiers: PropTypes.string,
        isOnlyPlaceholder: PropTypes.bool,
        isBreadcrumbsActive: PropTypes.bool
    };

    static defaultProps = {
        pageIds: -1,
        urlKey: '',
        pageIdentifiers: '',
        isOnlyPlaceholder: false,
        isBreadcrumbsActive: true
    };

    state = {
        page: {},
        isLoading: true,
        isPageLoaded: false,
        isChecked: true
    };

    __construct(props) {
        super.__construct(props);

        this.updateBreadcrumbs();
        this.state = {
            isChecked: true
        };
    }

    updateBreadcrumbs() {
        const {
            toggleBreadcrumbs,
            isBreadcrumbsActive
        } = this.props;

        toggleBreadcrumbs(isBreadcrumbsActive);
    }

    componentDidMount() {
        const {
            isOffline,
            isOnlyPlaceholder
        } = this.props;

        const { isLoading } = this.state;
        const { yearsData, updateMeta } = this.props;
        const current_url = window.location.href;
        if (isEmpty(yearsData)) {
            this.storeIdLookupAndRequest();
        }
        if (isOffline && isLoading) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        if (!isOnlyPlaceholder) {
            this.requestPage();
        }

        this.cmsVacanciesData();
    }

    async cmsVacanciesData() {
        this.fetchData(
            [CmsPageQuery.getCmsVacancies()],
            (res) => {
                this.setState({ vacancies: res });
            },
            (err) => {
                console.log('err', err);
            }
        );
    }

    storeIdLookupAndRequest= () => {
        const pName = window.location.pathname;
        if (pName && !pName.includes('/workshop')) {
            return;
        }

        const period = 600; // ms
        const sleepyAlert = setInterval(() => {
            const { storeId } = window.storeConfig;
            if (storeId !== undefined) {
                clearInterval(sleepyAlert);
                const { requestPartsFinderYears } = this.props;
                requestPartsFinderYears({ endpointUrl: '/vfits?', cb: updateContactPageYears });
            }
        }, period);
    };

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
            pageIdentifiers,
            pageIds
        } = this.props;

        const {
            location: { pathname: prevPathname },
            pageIdentifiers: prevPageIdentifiers,
            pageIds: prevPageIds
        } = prevProps;

        const pName = window.location.pathname;

        if (pName && pName.includes('/reviews')) {
            if (typeof window.yotpo === 'object') {
                const api = new window.Yotpo.API(window?.yotpo);
                api.refreshWidgets();
            }
        }

        let retryCount = 0;
        function initYotpoWidgets() {
            if (window.yotpoWidgetsContainer && typeof window.yotpoWidgetsContainer.initWidgets === 'function') {
                setTimeout(() => {
                    window.yotpoWidgetsContainer.initWidgets();
                }, 1000);
            } else if (retryCount < 2) { // Stop after 2 attempts
                console.warn('yotpoWidgetsContainer or initWidgets is not available, retrying...');
                retryCount++;
                setTimeout(initYotpoWidgets, 1000); // Retry after 1000ms
            } else {
                console.warn('yotpoWidgetsContainer init failed after 2 attempts');
            }
        }

        // Start the initial attempt
        initYotpoWidgets();

        if (pName && pName.includes('/rewards')) {
            initYotpoWidgets();
        }

        if (
            pathname !== prevPathname
            || pageIds !== prevPageIds
            || pageIdentifiers !== prevPageIdentifiers
        ) {
            this.requestPage();
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }, 3000);
        }
    }

    setOfflineNoticeSize = () => {
        const { setBigOfflineNotice } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            setBigOfflineNotice(true);
        } else {
            setBigOfflineNotice(false);
        }
    };

    onPageLoad = ({ cmsPage, oggraphData = {} }) => {
        const {
            location: { pathname },
            updateMeta,
            setHeaderState,
            updateBreadcrumbs
        } = this.props;
        const page = { ...cmsPage, ...oggraphData };

        const {
            content_heading,
            meta_title,
            title,
            meta_description,
            meta_keywords,
            hreflang,
            contact_us_snippet,
            oggraph
        } = page;

        debounce(this.setOfflineNoticeSize, LOADING_TIME)();

        updateBreadcrumbs(page);
        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.href,
            hreflang,
            contact_us_snippet,
            oggraph
        });

        if (
            pathname !== appendWithStoreCode('/')
            && pathname !== '/'
        ) {
            setHeaderState({
                name: CMS_PAGE,
                title: content_heading,
                onBackClick: () => history.goBack()
            });
        }

        this.setState({ page, isLoading: false, isPageLoaded: true });
    };

    getRequestQueryParams() {
        const {
            location,
            match,
            pageIdentifiers: identifier,
            pageIds: id
        } = this.props;

        if (identifier) {
            return { identifier };
        }

        if (id !== -1) {
            return { id };
        }

        const urlKey = getUrlParam(match, location);

        return {
            identifier: urlKey
        };
    }

    requestPage() {
        const params = this.getRequestQueryParams();
        const { id, identifier } = params;

        if (!id && !identifier) {
            return;
        }

        this.setState({ isLoading: true });
        const current_url = getCurrentUrlForOggData();

        this.fetchData(
            [
                CmsPageQuery.getQuery(params),
                OggGraphQuery.getQuery({ current_url, type: 'CMS_PAGE' })
            ],
            this.onPageLoad,
            () => this.setState({ isLoading: false })
        );
    }

    async fetchGuides(sku) {
        this.setState({ installationLoading: true });
        const graphql = InstallationGuideQuery.getInstallationGuide(sku);
        this.fetchFsyncData(
            formatCleanQuery(graphql),
            '/fittingInstructions?',
            this.onGuideLoad,
            () => this.setState({ installationLoading: false })
        );
    }

    onGuideLoad = ({ fittingInstructions = [] }) => {
        if (fittingInstructions.length > 0) {
            this.setState({ installationguides: fittingInstructions, installationLoading: false });
        } else {
            this.setState({ error: __('No results found') });
            this.setState({ installationguides: false, installationLoading: false });
        }
    };

    containerFunctions = ({
        handleNameChange: this._handleFieldChange.bind(this, 'name'),
        handleEmailChange: this._handleFieldChange.bind(this, 'email'),
        handleContactChange: this._handleFieldChange.bind(this, 'contact'),
        handleTopicChange: this._handleFieldChange.bind(this, 'topic'),
        handleMessageChange: this._handleFieldChange.bind(this, 'message'),
        handleCaptchaChange: this._handleFieldChange.bind(this, 'captcha'),
        handleSelectVehicleChange: this._handleCheckedVehicle.bind(this),
        handleOptionsDataChange: this._handleFieldChange.bind(this, 'partOption'),
        handleSubscriptionChange: this._handleCheckedSubscription.bind(this),
        handleFilesChange: this._handleFieldChange.bind(this, 'files'),
        onSubmitAttempt: this._onSubmitAttempt.bind(this),
        onSubmitSuccess: this._onSubmitSuccess.bind(this),
        onError: this._onError.bind(this),
        requestGuideForSku: this._requestGuideForSku.bind(this)

    });

    _requestGuideForSku(guideForSku) {
        this.setState({ error: '' });
        // const { requestQuickItems } = this.props;
        if (guideForSku) {
            // requestQuickItems(skus);
            this.fetchGuides(guideForSku);
        }
    }

    _handleFieldChange(fieldName, value) {
        const { formData } = this.state;
        this.setState({ formData: { ...formData, [fieldName]: value } });
    }

    _handleCheckedVehicle(value) {
        const { isChecked } = this.state;
        this.setState({ isChecked: value });
    }

    _handleCheckedSubscription(value) {
        const { isCheckedSubscription } = this.state;
        this.setState({ isCheckedSubscription: value });
    }

    handleYearsDataChange = (value) => {
        const { requestPartsFinderMakes } = this.props;
        requestPartsFinderMakes({ yearId: value, endpointUrl: '/vfits?', cb: updateContactPageMakes });
        this._handleFieldChange('year', value);
    };

    handleMakesDataChange = (value) => {
        const { requestPartsFinderModels } = this.props;
        const { formData: { year } } = this.state;
        requestPartsFinderModels({
            yearId: year, makeId: value, endpointUrl: '/vfits?', cb: updateContactPageModels
        });
        this._handleFieldChange('make', value);
    };

    handleModelDataChange = (value) => {
        const { requestPartsFinderOptions } = this.props;

        const { formData: { year, make } } = this.state;

        requestPartsFinderOptions({
            yearId: year, makeId: make, modelId: value, endpointUrl: '/vfits?', cb: updateContactPageOptions
        });
        this._handleFieldChange('model', value);
    };

    _onError() {
        this.setState({ isLoading: false });
    }

    _onSubmitAttempt(_, invalidFields) {
        const { showNotification } = this.props;
        const { formData = {} } = this.state;

        const SubmissionNotValid = invalidFields;
        if (SubmissionNotValid) {
            showNotification('error', 'Incorrect data! Please check fields.');
        }
        this.setState({ isLoading: !SubmissionNotValid, showValidationError: '' });
    }

    _onSubmitSuccess() {
        const {
            formData
        } = this.state;
        const {
            submitContactInformation
        } = this.props;

        // Path name
        const pName = window.location.pathname;

        const { isChecked = true, isCheckedSubscription = false } = this.state;
        this.setState({ isLoading: true });
        const z_vehicle_checked = (isChecked) ? '1' : '0';
        const z_newsletter_subscription = (isCheckedSubscription) ? '1' : '0';
        const {
            name: z_name = '',
            email: z_requester = '',
            contact: z_contact_number = '',
            topic: z_topic = '',
            year: z_v_year = '',
            make: z_v_make = '',
            model: z_v_model = '',
            partOption: z_v_option = '',
            message: z_description = '',
            // z_vehicle_checked: "1", // TODO: YKS [Check why checkbox value is not catured by scandi code.]
            // z_newsletter_subscription: "on", // TODO: YKS
            captcha: recaptcha_response = '',
            files: z_files
        } = formData;

        let subject = `Contact Form ${window.storeConfig.code} website`;
        if (pName && pName.includes('/workshop')) {
            subject = `Workshop Form ${window.storeConfig.code} website`;
        }

        const options = {
            z_support_group: '28229485',
            z_subject: subject,
            z_name,
            z_requester,
            z_contact_number,
            z_description,
            z_topic,
            z_vehicle_checked,
            z_v_year,
            z_v_make,
            z_v_model,
            z_v_option,
            recaptcha_response,
            z_vehicle_name: '',
            z_newsletter_subscription
            // z_files,
        };

        if (isEmpty(z_name) || isEmpty(z_requester) || isEmpty(z_contact_number) || isEmpty(z_topic) || isEmpty(z_description)) {
            this.setState({ showValidationError: '' });
        } else {
            this.setState({ showValidationError: 'noError' });
        }

        submitContactInformation({
            options
        }).then(
            /** @namespace Pwa/Route/CmsPage/Container/submitContactInformation/then */
            (success) => {
                this.setState({
                    formData: {},
                    isLoading: false
                });
            }
        );
    }

    render() {
        return (
            <CmsPage
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              handleYearsDataChange={ this.handleYearsDataChange }
              handleMakesDataChange={ this.handleMakesDataChange }
              handleModelDataChange={ this.handleModelDataChange }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
