// import {
//     BILLING_STEP as SourceBILLING_STEP,
//     CHECKOUT_URL as SourceCHECKOUT_URL,
//     DETAILS_STEP as SourceDETAILS_STEP,
//     PAYMENT_TOTALS as SourcePAYMENT_TOTALS,
//     SHIPPING_STEP as SourceSHIPPING_STEP,
//     UPDATE_EMAIL_CHECK_FREQUENCY as SourceUPDATE_EMAIL_CHECK_FREQUENCY
// } from 'SourceRoute/Checkout/Checkout.config';

// TODO: implement SHIPPING_STEP
// export const SHIPPING_STEP = SourceSHIPPING_STEP;

// // TODO: implement BILLING_STEP
// export const BILLING_STEP = SourceBILLING_STEP;

// // TODO: implement DETAILS_STEP
// export const DETAILS_STEP = SourceDETAILS_STEP;

// // TODO: implement CHECKOUT_URL
// export const CHECKOUT_URL = SourceCHECKOUT_URL;

// // TODO: implement PAYMENT_TOTALS
// export const PAYMENT_TOTALS = SourcePAYMENT_TOTALS;

// // TODO: implement UPDATE_EMAIL_CHECK_FREQUENCY
// export const UPDATE_EMAIL_CHECK_FREQUENCY = SourceUPDATE_EMAIL_CHECK_FREQUENCY;

import { appendWithStoreCode } from 'Util/Url';

export const SHIPPING_STEP = 'SHIPPING_STEP';
export const BILLING_STEP = 'BILLING_STEP';
export const DETAILS_STEP = 'DETAILS_STEP';

export const BILLING_URL_STEP = 'billing';
export const SHIPPING_URL_STEP = 'shipping';
export const DETAILS_URL_STEP = 'success';

export const CHECKOUT_URL = '/checkout';
export const BILLING_URL = '/checkout/billing';
export const SHIPPING_URL = '/checkout/shipping';

export const CHECKOUT_URL_REGEX = new RegExp(`^(${appendWithStoreCode('')})?${CHECKOUT_URL}(/)?$`);

export const PAYMENT_TOTALS = 'PAYMENT_TOTALS';

export const UPDATE_EMAIL_CHECK_FREQUENCY = 1500; // ms

export const CmsBlock = 'CmsBlock';
