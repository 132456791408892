/* eslint-disable no-undef */
/* eslint-disable new-cap */
import { connect } from 'react-redux';

import AddTeamForm from './AddTeamForm.component';

/** @namespace Pwa/Component/AddTeamForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    companyRoles: state.CompanyRolesReducer.companyRoles
});

/** @namespace Pwa/Component/AddTeamForm/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddTeamForm);
