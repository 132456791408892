/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { get } from 'lodash';

import AuthnetQuery from 'Query/Authnet.query';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, QueryDispatcher } from 'Util/Request';

import { updateSavedCards } from './Authnet.action';

/** @namespace Pwa/Store/Authnet/Dispatcher/AuthnetDispatcher */
export class AuthnetDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess(data, dispatch) {
        const cards = get(data, 'tokenBaseCards', []);
        dispatch(updateSavedCards({ data: cards }, false));
    }

    onError(_, dispatch) {
        dispatch(showNotification('error', __('Saved cards could not be fetched')));
    }

    prepareRequest(options, dispatch) {
    // dispatch(updateContactPage({}, true))
        return AuthnetQuery.getSavedCards(options);
    }

    // submit(dispatch, options) {
    //   return fetchMutation(ContactPageQuery.submit(
    //     options
    //   )).then(
    //       () => dispatch(showNotification('success', 'Success!!')),
    //       error => dispatch(showNotification('error', 'Error! Please try again.'))
    //   );
    // }
}

export default new AuthnetDispatcher();
