/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable react/no-danger */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-new */
/* eslint-disable no-undef */
/* eslint-disable fp/no-let */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/no-unused-prop-types */
import {
    faGlobe
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '@scandipwa/scandipwa/src/component/Link';
import {
    find, get, isEmpty, map, upperCase
} from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Select from 'react-select';
import { persistStore } from 'redux-persist';

import ClickOutside from 'Component/ClickOutside';
import CountryFlags from 'Component/CountryFlags';
// import LangSwitcher from 'Component/LangSwitcher';
import Overlay from 'Component/Overlay';
import { STORE_CONFIG_KEY } from 'Component/StoreSwitcher/StoreSwitcher.config';
import { updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { deleteGuestQuoteId } from 'Util/Cart';
import { getGeoCountries, isEurStore, isUsaStore } from 'Util/FrontRunner/Store';
import getStore from 'Util/Store';

import { storeConfig } from '../../store/Config/Config.reducer';

import './CountrySelector.style';

/** @namespace Pwa/Component/CountrySelector/Component/CountrySelector */
export class CountrySelector extends PureComponent {
  static propTypes = {
      currentStore: PropTypes.number
  };

  static defaultProps = {
      currentStore: 5
  };

  componentDidMount() {
      // googleTranslateElementInit(() => {
      //     new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
      // });
      // const script = document.createElement('script');
      // script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      // script.async = true;
      // document.body.appendChild(script);
      //   const country = getGeoCountries();
      //   console.log('country', country);

      const countryPromise = getGeoCountries();

      countryPromise.then(
          (response) => {
              this.setState({
                  countryOptions: response.countries,
                  userCountry: response.geoip_country,
                  allowedCountries: response.allowed_countries
              });
          },
          (error) => {
              console.error(error);
          }
      );
  }

  __construct(props) {
      super.__construct(props);
      this.state = {
          currentContinent: 'americas',
          selectValue: '',
          countryOptions: null,
          userCountry: null,
          allowedCountries: null
      };
  }

  onContinentClick = (continent) => this.setState({ currentContinent: continent });

  renderContinents = (continents) => {
      const { currentContinent } = this.state;
      return (map(continents, ({ label }, key) => (
            <div
              key={ key }
              onClick={ () => this.onContinentClick(label) }
              className={ (label === currentContinent) ? 'activeCountry' : '' }
            >
                { upperCase(label) }
            </div>
      )));
  };
  //  TODO:: comented by AD
  //   renderCountries = (data) => map(data, ({ name, country_code, store_url }, key) => (
  //       <div key={ key }>
  //         <Link
  //           to={ store_url }
  //           block="CountrySelector"
  //           elem="Link"
  //         >
  //           <CountryFlags code={ country_code } />
  // { ' ' }
  // { name }
  //         </Link>
  //       </div>
  //   ));

    handleCountryUrl= (store_url, store_id) => {
        // eslint-disable-next-line no-useless-escape
        let storeCode = store_url.replaceAll('\/', '_').replaceAll('\\', '');
        if (storeCode.startsWith('_') && storeCode.charAt(storeCode.length - 1) === '_') {
            storeCode = storeCode.slice(1, -1);
        }
        if (storeCode.startsWith('_') && !storeCode.charAt(storeCode.length - 1) === '_') {
            storeCode = storeCode.slice(1, 0);
        }

        if (!storeCode.startsWith('_') && storeCode.charAt(storeCode.length - 1) === '_') {
            storeCode = storeCode.slice(0, -1);
        }

        if ('caches' in window) {
            caches.keys().then(
                /** @namespace Pwa/Component/CountrySelector/Component/keys/then */
                (names) => {
                // Delete all the cache files
                    names.forEach((name) => {
                        caches.delete(name);
                    });
                }
            );
        }

        const active_store = localStorage.getItem('active_store_code', '');
        const active_store_store_ex_lan = active_store?.split('_') || [];

        const url_store_ex_lan = storeCode?.split('_') || [];
        let currentStoreCode = '';
        let nextGoingStorCode = '';
        if (active_store_store_ex_lan.length > 1 && url_store_ex_lan.length > 1) {
            currentStoreCode = active_store_store_ex_lan[1];
            nextGoingStorCode = url_store_ex_lan[1];
        }

        if (currentStoreCode !== nextGoingStorCode) {
            const store = getStore();
            const { dispatch } = store;
            deleteGuestQuoteId();
            BrowserDatabase.deleteItem(STORE_CONFIG_KEY);
            deleteGuestQuoteId();

            dispatch(updateCustomerSignInStatus(false));

            const MyAccountDispatcher = import('../../store/MyAccount/MyAccount.dispatcher');
            MyAccountDispatcher.then(
                ({ default: dispatcher }) => dispatcher.logout(true, dispatch)
            );
        }

        persistStore(this.props).purge();
        localStorage.setItem('active_store_code', storeCode);
        window.storeConfig.code = storeCode;
        window.storeConfig.baseName = store_url;
        window.storeConfig.store_id = store_id;
        // TODO:  have to build store code  from data: /en\/int\/

        const newHomeUrl = window.location.origin + store_url;

        window.location.replace(newHomeUrl);
    };

    renderCountries = (data) => map(data, ({
        name, country_code, store_url, store_id
    }, key) => (
      <div key={ key }>
        <div
          block="CountrySelector"
          elem="Link"
          onClick={ () => this.handleCountryUrl(store_url, store_id) }
        >
          <CountryFlags code={ country_code } />
            { name }
        </div>
      </div>
    ));

    updateName = (codeName) => {
        // this.setState({ codeName });
        // localStorage.setItem('code_name', codeName);
    };

    generateCurrentRedirectUrl(language, country) {
        const baseUrl = `${window.location.protocol
        }//${window.location.host
        }`;

        const pathArray = window.location.pathname.split('/');
        pathArray[1] = language;
        pathArray[2] = country;

        return baseUrl + pathArray.join('/');
    }

    renderLanguageSw() {
        let storeLanguage = window.storeConfig.lang ?? 'en';
        storeLanguage = storeLanguage.substring(0, 2);

        const { onStoreSwitcherClick } = this.props;
        const baseUrl = window.location.pathname;

        const languages = {
            en: {
                label: 'English'
            },
            de: {
                label: 'Deutsch'
            },
            fr: {
                label: 'Français'
            },
            es: {
                label: 'Español'
            },
            nl: {
                label: 'Nederlands'
            }
        };
        // Find selected language using storeLanguage in languages
        const selectedLanguage = languages[storeLanguage]?.label || languages.en?.label;

        if (isEurStore(true)) {
            return (
                <div className="switcher-language-wrap">
                    <div className="switcher-language">
                        <div className="switcher-trigger">
                            { `${selectedLanguage} (${storeLanguage})` }
                        </div>
                        <ul>
                            {(storeLanguage !== 'en') && (
                                <li className="switcher-option">
                                    <a href={this.generateCurrentRedirectUrl('en', 'de')} onClick={() => this.updateName('en')}>English (EN)</a>
                                </li>
                            )}
                            {(storeLanguage !== 'de') && (
                                <li className="switcher-option">
                                    <a href={this.generateCurrentRedirectUrl('de', 'de')} onClick={() => this.updateName('de')}>Deutsch (DE)</a>
                                </li>
                            )}
                            {(storeLanguage !== 'fr') && (
                                <li className="switcher-option">
                                    <a href={this.generateCurrentRedirectUrl('fr', 'be')} onClick={() => this.updateName('fr')}>Français (FR)</a>
                                </li>
                            )}
                            {(storeLanguage !== 'es') && (
                                <li className="switcher-option">
                                    <a href={this.generateCurrentRedirectUrl('es', 'be')} onClick={() => this.updateName('es')}>Español (ES)</a>
                                </li>
                            )}
                            {(storeLanguage !== 'nl') && (
                                <li className="switcher-option">
                                    <a href={this.generateCurrentRedirectUrl('nl', 'be')} onClick={() => this.updateName('nl')}>Nederlands (NL)</a>
                                </li>
                            )}
                            {/* <li className="switcher-option">
                                <button onClick={ onStoreSwitcherClick }>Other Languages</button>
                            </li> */}
                        </ul>
                    </div>
                </div>
            );
        }

        if (isUsaStore()) {
            return (
                <div className="switcher-language-wrap">
                    <div className="switcher-language">
                        <div className="switcher-trigger">
                            { `${selectedLanguage} (${storeLanguage})` }
                        </div>
                        <ul>
                            {(storeLanguage !== 'en') && (
                                <li className="switcher-option">
                                    <a href={this.generateCurrentRedirectUrl('en', 'us')} onClick={() => this.updateName('en')}>English (EN)</a>
                                </li>
                            )}
                            {(storeLanguage !== 'es') && (
                                <li className="switcher-option">
                                    <a href={this.generateCurrentRedirectUrl('es', 'us')} onClick={() => this.updateName('es')}>Español (ES)</a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            );
        }

        return (
            <div className="switcher-language-wrap">
                <div className="switcher-language">
                    <div className="switcher-trigger">
                        { `${selectedLanguage} (${storeLanguage})` }
                    </div>
                </div>
            </div>
        );
    }

    handleChange = (e) => {
        // Get website url
        const currentUrl = window.location.pathname;

        const storeBaseName = window.storeConfig.baseName;

        const updateUrl = currentUrl.replace(storeBaseName, '');

        const newUrl = e.value + updateUrl;

        this.setState({ selectValue: newUrl });
    };

    closeStore = () => {
        const { onStoreSwitcherOutsideClick } = this.props;
        onStoreSwitcherOutsideClick();
    };

    render() {
        const { currentContinent, selectValue } = this.state;
        const pathname = window.location.origin;

        const {
            data, flagSrc = '', website_name, default_country
        } = this.props;

        const options = [];

        // const continents = get(data, 'continent', []);
        // const label = get(data, 'label', '');
        // const currentContinentData = find(continents, ({ label = '' }) => (label === currentContinent)) || {};
        // const { countries = [], pinned_countries: pinnedCountries = [] } = currentContinentData;

        const defaultFlagSrc = '/media/skin/images/flags/us.png';
        const flagImg = isEmpty(flagSrc) ? defaultFlagSrc : flagSrc;
        let flagIcon = {};
        const storeCode = window.storeConfig.code;
        if (storeCode === 'en_int') {
            flagIcon = <FontAwesomeIcon icon={ faGlobe } />;
        } else {
            flagIcon = (
                <img
                  alt="Flage"
                  src={flagImg}
                  loading="eager"
                  width="32"
                  height="32"
                />
            );
        }

        // if (isEmpty(data)) {
        //     return null;
        // }

        const mapWarehouse = {
            en_us: {
                region: 'US',
                flag: '/media/skin/images/flags/us.png'
            },
            es_us: {
                region: 'US',
                flag: '/media/skin/images/flags/us.png'
            },
            en_za: {
                region: 'ZA',
                flag: '/media/skin/images/flags/za.png'
            },
            en_uk: {
                region: 'DE',
                flag: '/media/skin/images/flags/de.png'
            },
            en_de: {
                region: 'DE',
                flag: '/media/skin/images/flags/de.png'
            },
            de_de: {
                region: 'DE',
                flag: '/media/skin/images/flags/de.png'
            },
            fr_be: {
                region: 'DE',
                flag: '/media/skin/images/flags/de.png'
            },
            es_be: {
                region: 'DE',
                flag: '/media/skin/images/flags/de.png'
            },
            nl_be: {
                region: 'DE',
                flag: '/media/skin/images/flags/de.png'
            },
            en_au: {
                region: 'AU',
                flag: '/media/skin/images/flags/au.png'
            },
            en_int: {
                region: 'ZA',
                flag: '/media/skin/images/flags/za.png'
            }
        };
        const warehouseCountryFlag = mapWarehouse[storeCode].flag;
        const warehouseCountryRegion = mapWarehouse[storeCode].region;
        const { allowedCountries, userCountry, countryOptions } = this.state;

        // Dynamicaly get countries and visitor location from API
        let visitorCountryFlag = null;
        if (userCountry) {
            // this.handleChange({ value: userCountry });
            const visitorCountryCodeLowerCase = userCountry.toLowerCase();
            visitorCountryFlag = `/media/skin/images/flags/${visitorCountryCodeLowerCase}.png`;
        }

        // Check if visitorCountryCode === warehouseCountryRegion;

        // Check if this store in this.state.allowedCountries has visitorCountryCode
        let canShipToCountry = false;
        if (allowedCountries && allowedCountries.includes(userCountry)) {
            canShipToCountry = true;
        }

        return (
            <>
            <div
              id="storeSwitcher"
              mix={ { block: 'storeSwitcher' } }
            >
                <div className="storeSwitcherPopup">
                <h2>
                    { __('Select your language/country') }
                </h2>
                <div className="storeSwitcherLang">
                    <h3>
                        {__('Available Languages')}
                    </h3>
                    {this.renderLanguageSw()}
                </div>
                <div className="storeSwitcherCountry">
                    {(!isEmpty(visitorCountryFlag) && warehouseCountryRegion !== userCountry && canShipToCountry && (
                        <div className="storeSwitcherText" dangerouslySetInnerHTML={{ __html: __('Orders ship to %s from %s', `<img alt="Flag" src="${visitorCountryFlag}" loading="eager" width="32" height="32" /> ${userCountry}`, `<img alt="Flag" src="${warehouseCountryFlag}" loading="eager" width="32" height="32" /> ${warehouseCountryRegion}`) }} />
                    ))}
                    {(!isEmpty(visitorCountryFlag) && warehouseCountryRegion !== userCountry && !canShipToCountry && (
                        <div className="storeSwitcherText storeSwitcherText-NoShipping" dangerouslySetInnerHTML={{ __html: __('This store does not ship to %s', `<img alt="Flag" src="${visitorCountryFlag}" loading="eager" width="32" height="32" /> ${userCountry}`) }} />
                    ))}
                    <h3>
                        { __('Shipping to a different country?') }
                    </h3>
                    <div className="CountSelect">
                        <Select
                          // value={selectValue}
                          onChange={ this.handleChange }
                          options={ countryOptions }
                          placeholder={ __('Select your country') }
                        />
                    </div>
                </div>
                <Link
                  className="btn btn-primary"
                  to={`${pathname}${selectValue}`}
                >
                      {__('Submit')}
                </Link>
                {/* <div className="store-switcher-wrap">
                    <div className="continents">
                        { ' ' }
                        { this.renderContinents(continents) }
                        { ' ' }
                    </div>
                    <div className="language-country-main">
                        <div className="pinnedCountries">
                            { ' ' }
                            { this.renderCountries(pinnedCountries) }
                            { ' ' }
                        </div>
                        <div className="countries">
                            { ' ' }
                            { this.renderCountries(countries) }
                            { ' ' }
                        </div>
                    </div>
                </div> */}
                {/* <div className="language-popup-footer">
                    <div className="language-popup-region"> Translate this website </div>
                    <div id="google_translate_element"> </div>
                </div> */}
                </div>
            </div>
            <div className="storeSwitcherOverlay" onClick={ this.closeStore } />
            </>
        );
    }
}

export default CountrySelector;
