/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-let */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

export class Meta extends SourceMeta {
    static propTypes = {
        metadata: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                property: PropTypes.string,
                content: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ])
            })
        ).isRequired,
        canonical_url: PropTypes.string,
        default_title: PropTypes.string.isRequired,
        title_prefix: PropTypes.string.isRequired,
        title_suffix: PropTypes.string.isRequired,
        title: PropTypes.string,
        pdp_script: PropTypes.any,
        oggraph: PropTypes.any,
        hreflang: PropTypes.any,
        contact_us_snippet: PropTypes.any
    };

    state = { currentPage: '' };

    async updateToGaDataPageView() {
        const { currentPage = '' } = this.state;
        const nextPage = location.href;
        if (nextPage !== currentPage) {
            setTimeout(() => {
                if (isEmpty(document.title)) {
                    return;
                }
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'pageview',
                    pageTitle: document.title,
                    url: location.href,
                    page: {
                        url: location,
                        title: document.title
                    }
                });
            }, 2000);
            this.setState({ currentPage: location.href });
        }
    }

    renderTitle() {
        const {
            default_title,
            // title_prefix,
            title_suffix,
            title,
            meta_ogg = [],
            meta_tag_title
        } = this.props;

        // const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        // eslint-disable-next-line fp/no-let
        const ogg_meta_title = meta_ogg.find(({ property }) => property === 'og:title');
        const meta_title = ogg_meta_title?.content || title;

        let titleSuffix = title_suffix ? ` ${ title_suffix }` : '';
        let finalTitle = meta_tag_title || meta_title || default_title;

        if (finalTitle.includes('| Front Runner') || finalTitle.includes('|') || finalTitle.includes('|  Front Runner')) {
            titleSuffix = '';
        }
        // Strip away all contents after "|"
        finalTitle = finalTitle.split('| Front')[0];
        finalTitle = finalTitle.split('|  Front')[0];
        // Trim the finalTitle
        finalTitle = finalTitle.trim();

        this.updateToGaDataPageView(`${ finalTitle }${ titleSuffix }`);

        // Set meta title suffix
        // titleSuffix = window.storeConfig.metaTitle ?? 'Front Runner';
        // TODO: Chris revise | Testing if changing title suffix to Front Runner fixes serp issues
        titleSuffix = 'Front Runner';

        // Replace finalTitle "- by Front Runner" with empty string
        finalTitle = finalTitle.replace(' - by Front Runner', '');
        finalTitle = finalTitle.replace(' - By Front Runner', '');
        finalTitle = finalTitle.replace(' - von Front Runner', '');
        finalTitle = finalTitle.replace(' - Von Front Runner', '');
        finalTitle = finalTitle.replace(' - Front Runner', '');

        return (
            <title>
                { `${ finalTitle }` }
            </title>
        );
    }

    renderScripts() {
        const { pdp_script, contact_us_snippet } = this.props;
        const scripts = [];

        if (contact_us_snippet && contact_us_snippet.length > 0) {
            scripts.push({
                type: 'application/ld+json',
                innerHTML: contact_us_snippet
            });
        }

        if (pdp_script) {
            scripts.push(pdp_script);
        }

        return (
            <>
                { scripts.map((sc) => <script type={ sc.type } innerHTML={ sc.innerHTML } { ...sc } />) }
            </>
        );
    }

    renderCanonical() {
        const { canonical_url } = this.props;

        const links = [];

        if (canonical_url) {
            links.push({ ...(canonical_url && { rel: 'canonical', href: canonical_url }) });
        }

        return (
            <>
                { links.map((lin) => {
                    const { rel = 'canonical', href } = lin;

                    return (<link key="canonical" rel={ rel } href={ href } { ...lin } />);
                }) }
            </>
        );
    }

    renderHreflang() {
        const { hreflang } = this.props;

        const links = [];

        if (hreflang && hreflang.length > 0) {
            links.push(...hreflang);
        }

        return (
            <>
                { links.map((lin) => {
                    const { rel = 'alternate', href, lang = 'en-us' } = lin;

                    return (<link key={ `alternate_${lang}` } rel={ rel } href={ href } hrefLang={ lang } />);
                }) }
            </>
        );
    }

    renderDescription() {
        const { description, metadata } = this.props;
        const checkDescription = (obj) => obj.name === 'description';
        if (metadata.length !== 0 && metadata.some(checkDescription) === true) {
            return false;
        }

        return (
        <meta name="description" content={ description } />
        );
    }

    renderMetadata(metadata) {
        return metadata.map((tag) => {
            let tagContent = tag.content;
            if (tag.name === 'title') {
                // Strip away all contents after "|"
                tagContent = tagContent.split('| Front')[0];
                tagContent = tagContent.split('|  Front')[0];
                // Trim the tagContent
                tagContent = tagContent.trim();
                // Set the meta title suffix
                const titleSuffix = window.storeConfig.metaTitle ?? 'Front Runner';
                // Replace tagContent "- by Front Runner" with empty string
                tagContent = tagContent.replace(' - by Front Runner', '');
                tagContent = tagContent.replace(' - By Front Runner', '');

                tag.content = `${ tagContent } | ${ titleSuffix }`;
            }

            return <meta key={ tag.name || tag.property } { ...tag } />;
        });
    }

    renderMeta() {
        const { metadata, oggraph = [] } = this.props;
        if (oggraph && oggraph.length > 0) {
            metadata.push(...oggraph);
        }

        return (
            <>
                { this.renderTitle() }
                { /* { metadata.map((tag) => <meta key={ tag.name || tag.property } { ...tag } />) } */ }
                { this.renderMetadata(metadata) }
                { this.renderCanonical() }
                { this.renderHreflang() }
                { this.renderScripts() }
            </>
        );
    }

    render() {
        return createPortal(
            { ...this.renderMeta() },
            document.head
        );
    }
}

export default Meta;
