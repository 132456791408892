/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import PrivacyConsentCheckboxes from '../component/PrivacyConsentCheckboxes';
import { AREA_CHECKOUT } from '../util/Privacy';

const updateSelection = (selection, isSatisfied, instance) => {
    const { updatePrivacyConsentSelection } = instance.props;
    instance.setState({ isTermsAndConditionsAccepted: isSatisfied });

    updatePrivacyConsentSelection(selection, isSatisfied);
};

export const renderTermsAndConditions = (args, callback, instance) => (
    <PrivacyConsentCheckboxes
      area={ AREA_CHECKOUT }
      /* eslint-disable-next-line react/jsx-no-bind */
      updateSelection={ (selection, isSatisfied) => updateSelection(selection, isSatisfied, instance) }
    />
);

// eslint-disable-next-line
export const mapStateToProps = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    termsAreEnabled: true
});

export default {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderTermsAndConditions
        }
    },
    'Component/CheckoutBilling/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
