/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author  Zintis Scerbakovs <info@scandiweb.com>
 */

export const FIRST_VISIT_PRIVACY_POLICY_POPUP_ID = 'FirstVisitPrivacyPolicyPopup';
export const COOKIES_ACCEPTED = 'cookies_accepted';
