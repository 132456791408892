export const UPDATE_AFFILIATE_BALANCE_DATA = 'UPDATE_AFFILIATE_BALANCE_DATA';
export const UPDATE_AFFILIATE_PROMO_DATA = 'UPDATE_AFFILIATE_PROMO_DATA';
export const UPDATE_AFFILIATE_REFERAL_LINK = 'UPDATE_AFFILIATE_REFERAL_LINK';
export const UPDATE_AFFILIATE_WITHDRAWAL_DATA = 'UPDATE_AFFILIATE_WITHDRAWAL_DATA';
export const SET_AFFILIATE_DATA_LOADING = 'SET_AFFILIATE_DATA_LOADING';
export const SET_AFFILIATE_ACCOUNT_SELECTED_TAB = 'SET_AFFILIATE_ACCOUNT_SELECTED_TAB';

/** @namespace Pwa/Store/MyAffiliateAccount/Action/updateAffiliateBalanceData */
export const updateAffiliateBalanceData = ({ getaffliliatedbalance = {} }, isLoading = false) => ({
    type: UPDATE_AFFILIATE_BALANCE_DATA,
    getaffliliatedbalance,
    isLoading
});

/** @namespace Pwa/Store/MyAffiliateAccount/Action/updateAffiliatePromoData */
export const updateAffiliatePromoData = ({ getaffliliatedPromoData = [] }, isLoading = false) => ({
    type: UPDATE_AFFILIATE_PROMO_DATA,
    getaffliliatedPromoData,
    isLoading
});

/** @namespace Pwa/Store/MyAffiliateAccount/Action/updateAffiliateReferalLink */
export const updateAffiliateReferalLink = ({ getaffliliatedReferalLink = {} }, isLoading = false) => ({
    type: UPDATE_AFFILIATE_REFERAL_LINK,
    getaffliliatedReferalLink,
    isLoading
});

/** @namespace Pwa/Store/MyAffiliateAccount/Action/updateAffiliateWithdrawalData */
export const updateAffiliateWithdrawalData = ({ getaffliatedwithdraw = {} }, isLoading = false) => ({
    type: UPDATE_AFFILIATE_WITHDRAWAL_DATA,
    getaffliatedwithdraw,
    isLoading
});

/** @namespace Pwa/Store/MyAffiliateAccount/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_AFFILIATE_DATA_LOADING,
    isLoading
});

/** @namespace Pwa/Store/MyAffiliateAccount/Action/setActiveTab */
export const setActiveTab = (selectedTab) => ({
    type: SET_AFFILIATE_ACCOUNT_SELECTED_TAB,
    selectedTab
});
