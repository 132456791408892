/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
import { connect } from 'react-redux';

import {
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps,
    NewVersionPopupContainer as SourceNewVersionPopupContainer
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.container';
import { isCrawler, isSSR } from 'Util/Browser';
import history from 'Util/History';

/** @namespace Pwa/Component/NewVersionPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/NewVersionPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/NewVersionPopup/Container/NewVersionPopupContainer */
export class NewVersionPopupContainer extends SourceNewVersionPopupContainer {
    // TODO implement logic
    componentDidMount() {
        const { showPopup, goToPreviousHeaderState, device } = this.props;

        if (isCrawler() || isSSR()) {
            // disable popup for crawlers so page content is not blocked and page is scrollable
            return;
        }

        const { serviceWorker: { controller } = {} } = navigator;

        if (!controller) {
            return;
        }

        if ('serviceWorker' in navigator) {
            // navigator.serviceWorker.addEventListener('controllerchange', () => {
            //     if (controller) {
            //         showPopup({
            //             title: __('New version available!')
            //         });
            //         if (device.isMobile) {
            //             goToPreviousHeaderState();
            //         }
            //     } else {
            //         console.log('SW in wating state.');
            //     }
            // });
        }
    }

    handleDismiss() {
        const { hideActiveOverlay } = this.props;

        hideActiveOverlay();
        history.goBack();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionPopupContainer);
