import {
    SET_AFFILIATE_ACCOUNT_SELECTED_TAB,
    SET_AFFILIATE_DATA_LOADING,
    UPDATE_AFFILIATE_BALANCE_DATA,
    UPDATE_AFFILIATE_PROMO_DATA,
    UPDATE_AFFILIATE_REFERAL_LINK,
    UPDATE_AFFILIATE_WITHDRAWAL_DATA
} from './MyAffiliateAccount.action';

export const initialState = {
    getaffliliatedbalance: {},
    getaffliliatedPromoData: [],
    getaffliatedwithdraw: {},
    getaffliliatedReferalLink: {},
    isLoading: true,
    selectedTab: 'my_balance'
};

// selectedTab: should be any of  'my_balance', 'my_promo', 'withdrawal', it will be defined in Config of MyAffilate component

/** @namespace Pwa/Store/MyAffiliateAccount/Reducer/MyAffiliateAccountReducer */
export const MyAffiliateAccountReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        getaffliliatedbalance = {},
        getaffliliatedPromoData = [],
        getaffliliatedReferalLink = {},
        getaffliatedwithdraw = {},
        selectedTab = 'my_balance'
    } = action;

    switch (type) {
    case UPDATE_AFFILIATE_BALANCE_DATA:
        return {
            ...state,
            isLoading,
            getaffliliatedbalance
        };
    case UPDATE_AFFILIATE_PROMO_DATA:
        return {
            ...state,
            isLoading,
            getaffliliatedPromoData
        };
    case UPDATE_AFFILIATE_REFERAL_LINK:
        return {
            ...state,
            isLoading,
            getaffliliatedReferalLink
        };
    case UPDATE_AFFILIATE_WITHDRAWAL_DATA:
        return {
            ...state,
            isLoading,
            getaffliatedwithdraw
        };

    case SET_AFFILIATE_DATA_LOADING:
        return {
            ...state,
            isLoading
        };

    case SET_AFFILIATE_ACCOUNT_SELECTED_TAB:
        return {
            ...state,
            selectedTab
        };

    default:
        return state;
    }
};

export default MyAffiliateAccountReducer;
