/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Maintenance from './Maintenance.component';

/** @namespace Pwa/Route/Maintenance/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
});

/** @namespace Pwa/Route/Maintenance/Container/MaintenanceContainer */
export class MaintenanceContainer extends PureComponent {
     static propTypes = {
     };

     componentDidMount() {
     }

     render() {
         return (
             <Maintenance
               { ...this.props }
             />
         );
     }
}

/** @namespace Pwa/Route/Maintenance/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    maintenance_data: state.MaintenanceReducer.data
});

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceContainer);
