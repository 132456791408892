/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import { NavigationAbstractContainer } from 'Component/NavigationAbstract/NavigationAbstract.container';
import { SHARE_WISHLIST_POPUP_ID } from 'Component/ShareWishlistPopup/ShareWishlistPopup.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { ConfigDispatcher } from 'Store/Config/Config.dispatcher';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { DeviceType } from 'Type/Device.type';
import { isSignedIn as isSignedInWithToken } from 'Util/Auth';
import { scrollToTop } from 'Util/Helper';
import history from 'Util/History';
import { appendWithStoreCode, setQueryParams } from 'Util/Url';

import Header from './Header.component';
import {
    CART,
    CART_OVERLAY, CATEGORY,
    CHECKOUT, CHECKOUT_ACCOUNT, CHECKOUT_SUCCESS,
    CMS_PAGE, CONTACT_US, CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE, CUSTOMER_SUB_ACCOUNT,
    LANG_SWITCHER,
    MENU, PDP,
    SEARCH,
    STORE_SWITCHER,
    VEHICLE_GARAGE
} from './Header.config';

export const MyAccountDispatcher = import(
    'Store/MyAccount/MyAccount.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Pwa/Component/Header/Container/getFlag */
export const getFlag = (state) => {
    const {
        flag,
        secure_base_media_url
    } = state;

    const updateUrl = isEmpty(flag) ? {} : { src: `${secure_base_media_url}/${flag}` };
    return updateUrl;
};

/** @namespace Pwa/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState,
    cartTotals: state.CartReducer.cartTotals,
    header_logo_src: state.ConfigReducer.header_logo_src,
    isOffline: state.OfflineReducer.isOffline,
    logo_alt: state.ConfigReducer.logo_alt,
    logo_height: state.ConfigReducer.logo_height,
    logo_width: state.ConfigReducer.logo_width,
    isLoading: state.ConfigReducer.isLoading,
    device: state.ConfigReducer.device,
    activeOverlay: state.OverlayReducer.activeOverlay,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    desktopMenu: state.HeaderAndFooterReducer.desktopMenu,
    isWishlistLoading: state.WishlistReducer.isLoading,
    productsInWishlist: state.WishlistReducer.productsInWishlist,
    flag: getFlag(state.ConfigReducer),
    headerNotifications: state.ConfigReducer.header_notifications,
    freeShippingHeaderText: state.ConfigReducer.free_shipping_header_text,
    free_shipping_header_text_b2b: state.ConfigReducer.free_shipping_header_text_b2b,
    customer: state.MyAccountReducer.customer,
    is_free_shipping_enable: state.ConfigReducer.is_free_shipping_enable,
    default_country: state.ConfigReducer.default_country,
    code: state.ConfigReducer.code,
    apr_upto_months: state.ConfigReducer.apr_upto_months
});

/** @namespace Pwa/Component/Header/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    setNavigationState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    showPopup: (payload) => dispatch(showPopup(SHARE_WISHLIST_POPUP_ID, payload)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    updateCartTotal: () => CartDispatcher.then(
        ({ default: dispatcher }) => {
            dispatcher.updateInitialCartData(dispatch);
        }
    ),
    requestCustomerLogout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(null, dispatch)
    ),
    updateCurrency: (options) => ConfigDispatcher.updateCurrency(dispatch, options)

});

export const DEFAULT_HEADER_STATE = {
    name: DEFAULT_STATE_NAME,
    isHiddenOnMobile: false
};

/** @namespace Pwa/Component/Header/Container/HeaderContainer */
export class HeaderContainer extends NavigationAbstractContainer {
    static propTypes = {
        showOverlay: PropTypes.func.isRequired,
        isWishlistLoading: PropTypes.bool.isRequired,
        showPopup: PropTypes.func.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        header_logo_src: PropTypes.string,
        flag: PropTypes.object,
        device: DeviceType.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        productsInWishlist: PropTypes.any,
        freeShippingHeaderText: PropTypes.any,
        free_shipping_header_text_b2b: PropTypes.any,
        is_free_shipping_enable: PropTypes.any,
        default_country: PropTypes.any,
        apr_upto_months: PropTypes.any,
        code: PropTypes.any,
        requestCustomerData: PropTypes.func.isRequired

    };

    static defaultProps = {
        storeConfig: {},
        header_logo_src: ''
    };

    default_state = DEFAULT_HEADER_STATE;

    routeMap = {
        // eslint-disable-next-line max-len
        '/account/confirm': { name: CMS_PAGE, title: __('Confirm account'), onBackClick: () => history.push(appendWithStoreCode('/')) },
        '/category': { name: CATEGORY },
        '/checkout/success': { name: CHECKOUT_SUCCESS },
        '/checkout': { name: CHECKOUT, onBackClick: () => history.push(appendWithStoreCode('/cart')) },
        '/my-account': { name: CUSTOMER_ACCOUNT_PAGE, onBackClick: () => history.push(appendWithStoreCode('/')) },
        '/product': { name: PDP, onBackClick: () => history.goBack() },
        '/cart': { name: CART },
        '/menu': { name: MENU },
        '/page': { name: CMS_PAGE, onBackClick: () => history.goBack() },
        '/contact': { name: CONTACT_US, onBackClick: () => history.goBack() },
        '/': { name: DEFAULT_STATE_NAME, isHiddenOnMobile: true }
    };

    containerFunctions = {
        onBackButtonClick: this.onBackButtonClick.bind(this),
        onCloseButtonClick: this.onCloseButtonClick.bind(this),
        onSearchBarFocus: this.onSearchBarFocus.bind(this),
        onClearSearchButtonClick: this.onClearSearchButtonClick.bind(this),
        onMyAccountButtonClick: this.onMyAccountButtonClick.bind(this),
        onSearchBarChange: this.onSearchBarChange.bind(this),
        onClearButtonClick: this.onClearButtonClick.bind(this),
        onEditButtonClick: this.onEditButtonClick.bind(this),
        onMinicartButtonClick: this.onMinicartButtonClick.bind(this),
        onVehicleGarageButtonClick: this.onVehicleGarageButtonClick.bind(this),
        onOkButtonClick: this.onOkButtonClick.bind(this),
        onCancelButtonClick: this.onCancelButtonClick.bind(this),
        onSearchOutsideClick: this.onSearchOutsideClick.bind(this),
        onMyAccountOutsideClick: this.onMyAccountOutsideClick.bind(this),
        onMinicartOutsideClick: this.onMinicartOutsideClick.bind(this),
        onSignIn: this.onSignIn.bind(this),
        shareWishlist: this.shareWishlist.bind(this),
        hideActiveOverlay: this.props.hideActiveOverlay,
        onStoreSwitcherClick: this.onStoreSwitcherClick.bind(this),
        onStoreSwitcherOutsideClick: this.onStoreSwitcherOutsideClick.bind(this),
        onLangSwitcherClick: this.onLangSwitcherClick.bind(this),
        onLangSwitcherOutsideClick: this.onLangSwitcherOutsideClick.bind(this),
        onForceCurrencyUpdate: this.onForceCurrencyUpdate.bind(this),
        onHeaderMenuClick: this.onHeaderMenuClick.bind(this),
        onHeaderOverMenuClick: this.onHeaderOverMenuClick.bind(this),
        onHeaderNoticeClose: this.onHeaderNoticeClose.bind(this)
    };

    containerProps = () => {
        const {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            device,
            desktopMenu,
            isWishlistLoading,
            flag,
            productsInWishlist,
            is_free_shipping_enable,
            freeShippingHeaderText = {},
            free_shipping_header_text_b2b = {},
            requestCustomerData,
            customer,
            headerNotifications,
            default_country,
            apr_upto_months,
            code
        } = this.props;

        const {
            isClearEnabled,
            searchCriteria,
            showMyAccountLogin,
            shouldRenderGarageOverlay
        } = this.state;

        const {
            location: {
                pathname
            }
        } = history;

        const isCheckout = pathname.includes(CHECKOUT_URL);

        return {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            isClearEnabled,
            searchCriteria,
            isCheckout,
            showMyAccountLogin,
            device,
            desktopMenu,
            isWishlistLoading,
            flag,
            productsInWishlist,
            is_free_shipping_enable,
            freeShippingHeaderText,
            free_shipping_header_text_b2b,
            requestCustomerData,
            customer,
            shouldRenderGarageOverlay,
            headerNotifications,
            default_country,
            apr_upto_months,
            code
        };
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            prevPathname: '',
            searchCriteria: '',
            isClearEnabled: this.getIsClearEnabled(),
            showMyAccountLogin: false,
            shouldRenderGarageOverlay: false,
            headerMenuClick: true,
            headerOverMenuClick: false,
            noticeView: true
        };
    }

    componentDidMount() {
        this.handleHeaderVisibility();
        super.componentDidMount();

        const {
            isSignedIn
        } = this.props;

        if (isSignedIn && !isSignedInWithToken()) {
            return;
        }

        if (isSignedInWithToken()) {
            this.props.requestCustomerData();
        }
    }

    componentDidUpdate(prevProps) {
        this.hideSearchOnStateChange(prevProps);
        this.handleHeaderVisibility();
    }

    shareWishlist() {
        const { showPopup } = this.props;
        showPopup({ title: __('Share Wishlist') });
    }

    onHeaderNoticeClose() {
        const { noticeView } = this.state;
        this.setState({ noticeView: !noticeView });
    }

    onHeaderMenuClick() {
        const { headerMenuClick } = this.state;
        this.setState({ headerMenuClick: !headerMenuClick });
    }

    onHeaderOverMenuClick() {
        const { headerOverMenuClick } = this.state;
        this.setState({ headerOverMenuClick: !headerOverMenuClick });
    }

    onStoreSwitcherClick() {
        const { showOverlay, setNavigationState, navigationState: { name } } = this.props;

        if (name !== STORE_SWITCHER) {
            showOverlay(STORE_SWITCHER);
            setNavigationState({ name: STORE_SWITCHER });
        } else {
            showOverlay();
            setNavigationState({ name: '' });
        }
    }

    onStoreSwitcherOutsideClick() {
        const {
            goToPreviousNavigationState, hideActiveOverlay, navigationState: { name }, device
        } = this.props;

        if (device.isMobile || name !== STORE_SWITCHER) {
            return;
        }

        goToPreviousNavigationState();
        hideActiveOverlay();
    }

    onLangSwitcherClick() {
        const { showOverlay, setNavigationState, navigationState: { name } } = this.props;

        if (name !== LANG_SWITCHER) {
            showOverlay(LANG_SWITCHER);
            setNavigationState({ name: LANG_SWITCHER });
        }
    }

    onLangSwitcherOutsideClick() {
        const {
            goToPreviousNavigationState, hideActiveOverlay, navigationState: { name }, device
        } = this.props;

        if (device.isMobile || name !== LANG_SWITCHER) {
            return;
        }

        goToPreviousNavigationState();
        hideActiveOverlay();
    }

    onForceCurrencyUpdate(currencyCode) {
        const { updateCurrency } = this.props;
        updateCurrency({ currencyCode }).then(
            /** @namespace Pwa/Component/Header/Container/updateCurrency/then */
            () => location.reload()
        );
    }

    getNavigationState() {
        const { navigationState } = this.props;

        const { pathname } = location;
        const { state: historyState } = window.history || {};
        const { state = {} } = historyState || {};

        // TODO: something here breaks /<STORE CODE> from being opened, and / when, the url-based stores are enabled.

        const activeRoute = Object.keys(this.routeMap)
            .find((route) => (
                route !== '/'
                || pathname === appendWithStoreCode('/')
                || pathname === '/'
            ) && pathname.includes(route));

        if (state.category || state.product || state.page || state.popupOpen) { // keep state if it category is in state
            return navigationState;
        }

        return this.routeMap[activeRoute] || this.default_state;
    }

    hideSearchOnStateChange(prevProps) {
        const { navigationState: { name: prevName } } = prevProps;
        const { navigationState: { name } } = this.props;

        if (prevName === SEARCH && prevName !== name) {
            this.hideSearchOverlay();
        }
    }

    hideSearchOverlay() {
        const { hideActiveOverlay, activeOverlay } = this.props;

        this.setState({ searchCriteria: '' });

        document.activeElement.blur();

        if (activeOverlay === SEARCH) {
            hideActiveOverlay();
        }
    }

    handleHeaderVisibility() {
        const { navigationState: { isHiddenOnMobile } } = this.props;

        if (isHiddenOnMobile) {
            document.documentElement.classList.add('hiddenHeader');
            return;
        }

        document.documentElement.classList.remove('hiddenHeader');
    }

    handleMobileUrlChange(history) {
        const { prevPathname } = this.state;
        const { pathname } = history;
        const isClearEnabled = this.getIsClearEnabled();

        if (prevPathname === pathname) {
            return { isClearEnabled };
        }

        return {
            isClearEnabled,
            showMyAccountLogin: false
        };
    }

    getIsClearEnabled() {
        const { location: { search } } = history;

        return new RegExp([
            'customFilters',
            'priceMax',
            'priceMin'
        ].join('|')).test(search);
    }

    onBackButtonClick(e) {
        const { navigationState: { onBackClick } } = this.props;

        this.setState({ searchCriteria: '' });

        if (history.length <= 2 || history.length <= '2' || history.location.search !== '') {
            history.push(appendWithStoreCode('/'));
            scrollToTop();
        } else {
            onBackClick(e);
        }
    }

    onCloseButtonClick(e) {
        const { hideActiveOverlay, goToPreviousNavigationState } = this.props;
        const { navigationState: { onCloseClick } } = this.props;

        this.setState({ searchCriteria: '' });

        if (onCloseClick) {
            onCloseClick(e);
        }

        hideActiveOverlay();
        goToPreviousNavigationState();
    }

    onSearchOutsideClick() {
        const {
            goToPreviousNavigationState,
            navigationState: { name },
            device
        } = this.props;

        if (name === SEARCH) {
            this.hideSearchOverlay();
            goToPreviousNavigationState();
            this.goToDefaultHeaderState();
        }
    }

    onSearchBarFocus() {
        const {
            setNavigationState,
            goToPreviousNavigationState,
            showOverlay,
            navigationState: { name },
            device
        } = this.props;

        if (
            (!device.isMobile && name === SEARCH)
            || (device.isMobile && name !== MENU)
        ) {
            return;
        }

        showOverlay(SEARCH);

        setNavigationState({
            name: SEARCH,
            onBackClick: () => {
                showOverlay(MENU);
                goToPreviousNavigationState();
            }
        });
    }

    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setState({ searchCriteria });
    }

    onClearSearchButtonClick() {
        this.setState({ searchCriteria: '' });
    }

    onMyAccountButtonClick() {
        const {
            showOverlay,
            setNavigationState,
            isSignedIn
        } = this.props;

        if (isSignedIn && !isSignedInWithToken()) {
            return;
        }
        if (isSignedInWithToken()) {
            history.push({ pathname: appendWithStoreCode('/my-account/dashboard') });
            return;
        }

        this.setState({ shouldRenderGarageOverlay: false });
        this.setState({ showMyAccountLogin: true }, () => {
            showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);
            setNavigationState({
                name: CHECKOUT_ACCOUNT,
                title: 'Sign in',
                onCloseClick: this.closeOverlay
            });
        });
    }

    // onMyWishListButtonClick() {
    //     const {
    //         showOverlay,
    //         setNavigationState,
    //         isSignedIn
    //     } = this.props;

    //     if (isSignedIn && !isSignedInWithToken()) {
    //         return;
    //     }
    //     if (isSignedInWithToken()) {
    //         history.push({ pathname: appendWithStoreCode('/my-account/my-wishlist') });
    //         return;
    //     }
    //     this.setState({ showMyAccountLogin: true }, () => {
    //         showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);
    //         setNavigationState({
    //             name: CHECKOUT_ACCOUNT,
    //             title: 'Sign in',
    //             onCloseClick: this.closeOverlay
    //         });
    //     });
    // }

    onMyAccountOutsideClick() {
        const {
            goToPreviousNavigationState,
            hideActiveOverlay,
            navigationState: { name },
            device
        } = this.props;

        if (device.isMobile || ![CUSTOMER_ACCOUNT, CUSTOMER_SUB_ACCOUNT, CHECKOUT_ACCOUNT].includes(name)) {
            return;
        }

        if (name === CUSTOMER_SUB_ACCOUNT) {
            goToPreviousNavigationState();
        }

        this.goToDefaultHeaderState();
        hideActiveOverlay();
    }

    closeOverlay = () => {
        const { location: { pathname } } = history;

        if (pathname.includes(CHECKOUT_URL)) {
            this.setState({ showMyAccountLogin: false });
        }
    };

    onSignIn() {
        const { location: { pathname } } = history;

        if (pathname.includes(CHECKOUT_URL)) {
            this.setState({ showMyAccountLogin: false });
        }
    }

    onClearButtonClick() {
        const {
            hideActiveOverlay,
            goToPreviousNavigationState
        } = this.props;

        setQueryParams(
            {
                customFilters: '',
                priceMax: '',
                priceMin: ''
            },
            history.location,
            history
        );

        this.setState({ isClearEnabled: false });

        hideActiveOverlay();
        goToPreviousNavigationState();
    }

    onMinicartButtonClick() {
        const {
            showOverlay,
            navigationState: { name },
            device,
            updateCartTotal
        } = this.props;

        if (name === CART_OVERLAY) {
            return;
        }
        localStorage.setItem('isProgressCheckout', '');
        updateCartTotal();

        if (!device.isMobile) {
            this.setState({ shouldRenderCartOverlay: true });
            this.setState({ shouldRenderGarageOverlay: false });
            showOverlay(CART_OVERLAY);
            return;
        }

        history.push(appendWithStoreCode(`/${ CART }`));
    }

    onVehicleGarageButtonClick() {
        const {
            showOverlay,
            navigationState: { name },
            device
            // shouldRenderGarageOverlay
        } = this.props;

        const { shouldRenderGarageOverlay } = this.state;

        if (!device.isMobile) {
            this.setState({ shouldRenderGarageOverlay: true });
            if (shouldRenderGarageOverlay) {
                this.setState({ shouldRenderGarageOverlay: false });
            }
        }
    }

    onMinicartOutsideClick() {
        const {
            goToPreviousNavigationState,
            hideActiveOverlay,
            navigationState: { name },
            device
        } = this.props;

        if (device.isMobile || name !== CART_OVERLAY) {
            return;
        }

        goToPreviousNavigationState();
        hideActiveOverlay();
    }

    onEditButtonClick(e) {
        const { navigationState: { onEditClick } } = this.props;

        if (onEditClick) {
            onEditClick(e);
        }
    }

    onOkButtonClick(e) {
        const {
            navigationState: { onOkClick, shouldNotGoToPrevState = false },
            goToPreviousNavigationState
        } = this.props;

        if (onOkClick) {
            onOkClick(e);
        }

        if (!shouldNotGoToPrevState) {
            goToPreviousNavigationState();
        }
    }

    onCancelButtonClick() {
        const {
            navigationState: { onCancelClick },
            goToPreviousNavigationState
        } = this.props;

        if (onCancelClick) {
            onCancelClick();
        }

        goToPreviousNavigationState();
    }

    render() {
        return (
            <Header
              { ...this.state }
              { ...this.props }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
