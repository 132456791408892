/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    faAngleRight
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, map } from 'lodash';
import { Component } from 'react';

import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { isSignedIn } from 'Util/Auth';
import { convertStringToSlug } from 'Util/Url/Url';

import './CustomerGarageHeader.style';

/** @namespace Pwa/Component/CustomerGarageHeader/Component/CustomerGarageHeaderComponent */
export class CustomerGarageHeader extends Component {
  processUrl = (url) => url.replace(/ /g, '-');

  renderRow = (rowData, key) => {
      const {
          lyear, lmake, lmodel, loption, year, make, model, options
      } = rowData;
      const { onVehicleGarageButtonClick } = this.props;
      const vehicleSlug = convertStringToSlug(`${this.processUrl(lyear)}-${this.processUrl(lmake)}-${this.processUrl(lmodel)}-${this.processUrl(loption)}-${options}`);
      const url = `/vehicle/${vehicleSlug}`;
      const vehiclePageSubTitle = `${this.processUrl(lyear)} ${this.processUrl(lmake)} ${this.processUrl(lmodel)} ${this.processUrl(loption)}`;

      return (
      <Link
        to={ {
            pathname: url,
            state: {
                vehiclePageSubTitle,
                partFinderData: {
                    modelId: model, yearId: year, makeId: make, optionId: options
                }
            }
        } }
        onClick={ onVehicleGarageButtonClick }
        block="Garage"
        elem="Link"
      >
        <div className="my-garage-list" key={ key }>
          <div className="garageName">
            <div>
              { lmake }
              { ' ' }
              { lmodel }
            </div>
            <div className="subTitle">
              { lyear }
              { ' ' }
              { loption }
            </div>
          </div>
          <div className="actions">
              <FontAwesomeIcon icon={ faAngleRight } />
          </div>
        </div>
      </Link>
      );
  };

  renderGargeButton() {
      let linkTo = '/my-account/my-garage';
      if (!isSignedIn()) {
          linkTo = '/login';
      }
      const { onVehicleGarageButtonClick } = this.props;
      return (
          <Link
            to={ linkTo }
            onClick={ onVehicleGarageButtonClick }
            className="btn btn-primary GargeButton"
          >
            {__('Manage Garage')}
          </Link>
      );
  }

  render() {
      let { data, isLoading } = this.props;
      if (!isEmpty(data)) {
          data = data.reverse();
      }
      let vehicleData = JSON.parse(localStorage.getItem('vehicleDataLocal', ''));

      if (!isSignedIn()) {
          return (
          <div className="customerGargeHeader">
            <div className="customerGargeHeaderData">
              { !isEmpty(vehicleData) && map(vehicleData, this.renderRow) }
            </div>
            { isEmpty(vehicleData) && <div className="NoVehicles">{__('No vehicles have been added')}</div> }
            { this.renderGargeButton() }
          </div>
          );
      }

      return (
        <div className="customerGargeHeader">
          { isSignedIn() && <Loader isLoading={ isLoading } /> }
          <div className="customerGargeHeaderData">
            { isSignedIn() && !isEmpty(data) && map(data, this.renderRow) }
          </div>
          { isEmpty(data) && !isLoading && <div className="NoVehicles">{__('No vehicles have been added')}</div> }
          { this.renderGargeButton() }
        </div>
      );
  }
}

export default CustomerGarageHeader;
