/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable fp/no-delete */
/* eslint-disable eqeqeq */
/* eslint-disable new-cap */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable react/no-unused-state */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable max-len */
/* eslint-disable max-lines */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    get, isEmpty, lowerCase, replace
} from 'lodash';
import { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Field from 'Component/Field';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import ProductCard from 'Component/ProductCard';
import ProductPrice from 'Component/ProductPrice';
import SearchIcon from 'Component/SearchIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import isMobile from 'Util/Mobile';

// import { convertQueryStringToKeyValuePairs } from 'Util/Url';
import './QuickOrder.style';

/** @namespace Pwa/Route/QuickOrder/Component/QuickOrder */
export class QuickOrder extends Component {
    __construct() {
        super.__construct();
        this.state = {
            data: '',
            enteredSkuCode: '',
            enteredQty: '1'
        };
    }

    componentDidMount() {
        // const { form_key } = convertQueryStringToKeyValuePairs(this.props.location.search);
        // this.setState({ form_key });
    }

    renderHeading() {
        return (
            <h1>
                { __('Quick Order') }
            </h1>
        );
    }

    renderInput() {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                return <input type="text" />;
            }
        };

        return <input type="text" onKeyDown={ handleKeyDown } />;
    }

    handleKeyPress = (event) => <input type="text" />;

    handleItemsRequest = (event) => {
        const { requestItemsForSku } = this.props;
        const { enteredSkuCode, prevsku = '' } = this.state;
        if (isEmpty(enteredSkuCode) || prevsku == enteredSkuCode) {
            return;
        }
        this.setState({ prevsku: enteredSkuCode });
        let myArray = enteredSkuCode.split(',');
        // Trim whitespace around sku
        myArray = myArray.map((element) => element.trim());
        requestItemsForSku({ skus: myArray });
    };

    removeItem = (id, sku) => {
        const { readyToQuickOrderItems } = this.props;
        const { enteredSkuCode } = this.state;

        delete this.state[id];
        const { deletItem } = this.props;
        deletItem(id);

        let myArray = enteredSkuCode.split(',');
        myArray = myArray.map((element) => element.trim());
        const index = myArray.indexOf(sku);
        if (index > -1) {
            myArray.splice(index, 1);
            this.setState({ enteredSkuCode: myArray.join(', ') });
        }

        if (readyToQuickOrderItems.length === 0) {
            this.handleSkuChange('');
        }
    };

    handleSkuChange = (enteredSkuCode) => {
        if (enteredSkuCode.length > 0) {
            enteredSkuCode = enteredSkuCode.toUpperCase();
            enteredSkuCode = enteredSkuCode.replace(/#|;|:|\/|\|\\/g, ',');
        }
        this.setState({
            enteredSkuCode
        });
    };

    handleItemsQtyUpdate = (event) => {
        const { enteredQty } = this.state;
    };

    handleQtyChange = (enteredQty, id) => {
        this.setState({
            [id]:
            enteredQty
        });
    };

    renderQuickForm = () => {
        const { enteredSkuCode, enteredQty } = this.state;
        const { error = '' } = this.props;
        const isFindDisabled = isEmpty(enteredSkuCode);
        return (
            <div className="quick-block">
                <div className="quick-order-form">
                    <div className="box box-items">
                        <div className="fields additional deletable-item-title">
                            <div className="field sku required">
                                <label>{ __('Enter Single or Multiple SKUs') }</label>
                            </div>
                        </div>

                        <div className="fields additional deletable-item">
                            <div className="field sku">
                                <Field
                                  type="text"
                                  id="skuCode"
                                  name="skuCode"
                                  value={ enteredSkuCode }
                                  placeholder={ __('Enter SKU') }
                                  onChange={ this.handleSkuChange }
                                  onBlur={ this.handleItemsRequest }
                                  mix={ { block: 'SkuCode', elem: 'Input' } }
                                />
                                 { (!isEmpty(error)) && (
                                    <div className="errorMessage">
                                        { error }
                                    </div>
                                 ) }
                            </div>
                            <div className="field">
                                <button className="Button find" disabled={ isFindDisabled }>{ __('Find') }</button>
                            </div>
                            { /* { (!isEmpty(error)) && (
                                <div className="clearSku" onClick={ () => this.clearError() }>
                                    { __('delete') }
                                </div>
                            ) } */ }
                        </div>
                        <div className="note-bt">
                            { __('Use commas to separate SKUs.') }
                        </div>

                        <div className="SingleSkuSection">
                            { this.renderItems() }
                            <div className="actions-toolbar add-to-cart">
                                <button className="Button btn add_to_cart" onClick={ () => this.addItems() }>
                                    { __('Add to Cart') }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    addItems = () => {
        const { addToCart, readyToQuickOrderItems } = this.props;
        if (!readyToQuickOrderItems || readyToQuickOrderItems.length < 1) {
            return null;
        }
        const readycartitems = readyToQuickOrderItems.map((item) => {
            const { id } = item;
            const qty = this.state[id] || 1;

            return { ...item, qty };
        });

        if (readycartitems && readycartitems.length > 0) {
            addToCart(readycartitems);
        }
    };

    renderCardWrapper(children) {
        const { linkTo, product: { url, product_url, url_resolver = {} } } = this.props;
        const storeBaseName = window.storeConfig.baseName;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <RouterLink
              className="ProductCard-Link"
              to={ linkTo }
              onClick={ () => this.handleClick() }
            //   to={ {
            //       pathname: url,
            //       state: {
            //           url_resolver
            //       }
            //   } }
            >
              { children }
            </RouterLink>
        );
    }

    renderTypeStatus = (type_id, productUrl) => {
        if (isEmpty(type_id)) {
            return;
        }
        let moreInfo = '';
        if (type_id != 'simple') {
            moreInfo = __('More Info');
        }

        return (
            <>
            { (!isEmpty(moreInfo) && (
                <div block="ProductCard" elem="Type">
                    <TextPlaceholder content={ __('Out of stock') } length="medium" />
                </div>
            )) }
            </>
        );
    };

    renderProductSliderItem = (product) => {
        // eslint-disable-next-line no-unused-vars
        const { isRelated = false } = this.props;
        const {
            name,
            id,
            price_range,
            sku,
            thumbnail: { path, url },
            stock_status,
            stock_item: { min_sale_qty = 1, max_sale_qty = 20 },
            type_id,
            tax_description,
            url: productUrl
        } = product;

        const productlink = productUrl.replace(window.storeConfig.baseName, '/');

        const qtyValue = this.state[id] || '1';

        return (
            <>
            <div key={ id } className="QuickOrderItems">
                <Image
                  src={ url }
                  alt={ name }
                  ratio="custom"
                  mix={ { block: 'ProductCard', elem: 'Picture' } }
                  isPlaceholder={ !id }
                />
                <div className="ItemContent">
                    <p>
                    <RouterLink
                      className="ProductCard-Link"
                      to={ productUrl }
                    //   onClick={ () => this.handleClick() }
                    >
                        <TextPlaceholder content={ name } length="medium" />
                    </RouterLink>
                    </p>
                    <div className="SkuView">
                        { sku }
                    </div>
                    <ProductPrice
                      price={ price_range }
                      mix={ { block: 'ProductCard', elem: 'Price' } }
                      tax_description={ tax_description }
                    />
                </div>
                <div className="ItemTopSection">
                    <Field
                      type="number"
                      id={ id }
                      name="itemqty"
                      value={ qtyValue }
                      placeholder={ __('Qty') }
                      onChange={ (value) => this.handleQtyChange(value, id) }
                      mix={ { block: 'SkuCode', elem: 'Input' } }
                    />
                    { this.renderTypeStatus(type_id, productUrl) }
                    <button className="action remove btn" title="Remove Row" onClick={ () => this.removeItem(id, sku) } data-role="delete">
                        <FontAwesomeIcon icon={ ['fas', 'trash-alt'] } />
                    </button>
                </div>
            </div>
            { this.renderTypeStatus(type_id, productUrl) }
            </>
        );
    };

    renderItems() {
        const { productsSuggestions = {}, readyToQuickOrderItems, loading } = this.props;
        if (!readyToQuickOrderItems || readyToQuickOrderItems.length < 1) {
            return null;
        }

        // const readyToQuickOrderIt = readyToQuickOrderItems.reverse();

        return (
            <>
                <Loader isLoading={ loading } />

                { readyToQuickOrderItems.map((product) => (
                    this.renderProductSliderItem(product)
                )) }
            </>
        );
    }

    render() {
        return (
        <main className="QuickOrder">
            <div className="container">
                { this.renderHeading() }
                { this.renderQuickForm() }
            </div>
        </main>
        );
    }
}

export default QuickOrder;
