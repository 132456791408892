/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-lines */
/* eslint-disable fp/no-let */
/* eslint-disable no-undef */
/* eslint-disable react/forbid-prop-types */
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CATEGORY } from 'Component/Header/Header.config';
import {
    BreadcrumbsDispatcher as SourceBreadcrumbsDispatcher,
    CategoryDispatcher as SourceCategoryDispatcher,
    CategoryPageContainer as SourceCategoryPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaDispatcher as SourceMetaDispatcher,
    NoMatchDispatcher as SourceNoMatchDispatcher,
    ProductListInfoDispatcher as SourceProductListInfoDispatcher
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import CategoryReducer from 'Store/Category/Category.reducer';
import {
    ProductSkuDispatcher, ProductUniversalSkuDispatcher
} from 'Store/PartsFinder';
import {
    updateLoadStatus,
    updatePageLoadingStatus,
    updateProductListItems
} from 'Store/ProductList/ProductList.action';
import {
    updateVehicleFinderProductLoading
} from 'Store/VehicleFinder/VehicleFinder.action';
import { withReducers } from 'Util/DynamicReducer';
import { processAffiliateCode } from 'Util/FrontRunner/Affiliates/AffiliateCode';
import { debounce } from 'Util/Request';
import {
    appendWithStoreCode,
    getQueryParam,
    getUrlParam
} from 'Util/Url';

import CategoryPage from './CategoryPage.component';
import { LOADING_TIME } from './CategoryPage.config';

// TODO: implement ProductListInfoDispatcher
export const ProductListInfoDispatcher = SourceProductListInfoDispatcher;

// TODO: implement BreadcrumbsDispatcher
export const BreadcrumbsDispatcher = SourceBreadcrumbsDispatcher;

// TODO: implement CategoryDispatcher
export const CategoryDispatcher = SourceCategoryDispatcher;

// TODO: implement MetaDispatcher
export const MetaDispatcher = SourceMetaDispatcher;

// TODO: implement NoMatchDispatcher
export const NoMatchDispatcher = SourceNoMatchDispatcher;

/** @namespace Pwa/Route/CategoryPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    sideMenuData: state.HeaderAndFooterReducer.desktopMenu,
    skuData: state.VehicleFinderReducer.skuData,
    isDataLoading: state.VehicleFinderReducer.isProductLoading,
    vehicleSpecificSkus: state.VehicleFinderReducer.vehicleSpecificSkus,
    device: state.ConfigReducer.device

    // TODO extend mapStateToProps
});

/** @namespace Pwa/Route/CategoryPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    requestPartsFinderSkus: (options) => ProductSkuDispatcher.handleFsyncData(dispatch, options),
    requestPartsFinderUniversalSkus: (options) => ProductUniversalSkuDispatcher.handleData(dispatch, options),
    updateLoadStatus: (isLoading) => dispatch(updateLoadStatus(isLoading)),
    updateVehicleProductLoadStatus: (isProductLoading) => dispatch(updateVehicleFinderProductLoading(isProductLoading)),
    setProductLoadingStatus: () => dispatch(updatePageLoadingStatus()),
    cleanProductListItems: (items,
        currentPage,
        total_count,
        total_pages,
        args) => dispatch(updateProductListItems(items,
        currentPage,
        total_count,
        total_pages,
        args))

    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Route/CategoryPage/Container/CategoryPageContainer */
export class CategoryPageContainer extends SourceCategoryPageContainer {
    static propTypes = {
        ...SourceCategoryPageContainer.propTypes,
        sideMenuData: PropTypes.any
    };

    config = {
        sortKey: 'position',
        sortDirection: 'ASC'
    };

    componentDidMount() {
        // Process if any affiliate codes detected in the URL
        processAffiliateCode();
        this.updateBreadcrumbs();
    }

    // TODO implement logic
    componentDidUpdate(prevProps) {
        const {
            isOffline,
            categoryIds,
            category: {
                id
            },
            currentArgs: {
                filter
            } = {}
        } = this.props;

        const {
            breadcrumbsWereUpdated
        } = this.state;

        const {
            categoryIds: prevCategoryIds,
            category: {
                id: prevId
            },
            currentArgs: {
                filter: prevFilter
            } = {}
        } = prevProps;

        let modelId = get(this.props, 'match.params.modelId', undefined);
        let prevModelId = get(prevProps, 'match.params.modelId', undefined);
        // TODO: category scrolls up when coming from PDP

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        /**
         * If the URL rewrite has been changed, make sure the category ID
         * will persist in the history state.
         */

        if (this.isVehiclesPage()) {
            modelId = this.getVehicleModelId();
            prevModelId = this.getVehiclePreModelId(prevProps);
            if (modelId !== prevModelId) {
                this.updateHistory();
            }

            /**
             * If the currently loaded category ID does not match the ID of
             * category from URL rewrite, request category.
             */

            // if (modelId !== prevModelId) {
            // this._requestCategoryWithPageList();
            // this._requestVehicleSKUs();
            // }
            this.storeIdLookupAndRequest();

            /**
             * If category ID was changed => it is loaded => we need to
             * update category specific information, i.e. breadcrumbs.
             *
             * Or if the breadcrumbs were not yet updated after category request,
             * and the category ID expected to load was loaded, update data.
             */
            const categoryChange = modelId !== prevModelId || (!breadcrumbsWereUpdated && modelId === prevModelId);
            if (categoryChange) {
                this.checkIsActive();
                this.updateMeta();
                this.updateBreadcrumbs();
                this.updateHeaderState();
            }

            /*
            ** if category wasn't changed we still need to update meta for correct robots meta tag [#928](https://github.com/scandipwa/base-theme/issues/928)
            */
            if (!categoryChange
                && filter
                && prevFilter
                && Object.keys(filter.customFilters).length !== Object.keys(prevFilter.customFilters).length
            ) {
                this.updateMeta();
            }
        } else {
            if (categoryIds !== prevCategoryIds) {
                this.updateHistory();
            }

            /**
             * If the currently loaded category ID does not match the ID of
             * category from URL rewrite, request category.
             */

            if (categoryIds !== id) {
                this._requestCategoryWithPageList();
            }

            /**
             * If category ID was changed => it is loaded => we need to
             * update category specific information, i.e. breadcrumbs.
             *
             * Or if the breadcrumbs were not yet updated after category request,
             * and the category ID expected to load was loaded, update data.
             */
            const categoryChange = id !== prevId || (!breadcrumbsWereUpdated && id === categoryIds);
            if (categoryChange) {
                this.checkIsActive();
                this.updateMeta();
                this.updateBreadcrumbs();
                this.updateHeaderState();
            }
            this.updateHeaderState();
            /*
            ** if category wasn't changed we still need to update meta for correct robots meta tag [#928](https://github.com/scandipwa/base-theme/issues/928)
            */
            if (!categoryChange
                && filter
                && prevFilter
                && Object.keys(filter.customFilters).length !== Object.keys(prevFilter.customFilters).length
            ) {
                this.updateMeta();
            }
        }
    }

    isCurrentCategoryLoaded() {
        const {
            categoryIds,
            category: {
                id
            },
            isSearchPage
        } = this.props;

        const isLoaded = isSearchPage || categoryIds == id;
        return isLoaded;
    }

    getSelectedSortFromUrl() {
        const {
            location,
            category: {
                default_sort_by
            },
            isSearchPage
        } = this.props;

        let {
            sortKey: globalDefaultSortKey,
            sortDirection: defaultSortDirection
        } = this.config;

        if (isSearchPage) {
            globalDefaultSortKey = 'relevance';
            defaultSortDirection = 'DESC';
        }

        if (this.isVehiclesPage() && !window.location.href.includes('?find=')) {
            globalDefaultSortKey = 'price';
            defaultSortDirection = 'DESC';
        }

        /**
         * Default SORT DIRECTION is taken from (sequentially):
         * - URL param "sortDirection"
         * - CategoryPage class property "config"
         * */
        const sortDirection = getQueryParam('sortDirection', location) || defaultSortDirection;

        /**
         * Default SORT KEY is taken from (sequentially):
         * - URL param "sortKey"
         * - Category default sort key (Magento 2 configuration)
         * - CategoryPage class property "config"
         * */
        const defaultSortKey = default_sort_by || globalDefaultSortKey;
        const sortKey = getQueryParam('sortKey', location) || defaultSortKey;

        return {
            sortDirection,
            sortKey
        };
    }

    // onSortChange(sortDirection, sortKey) {
    //     const { location, history } = this.props;

    //     setQueryParams({ sortKey, sortDirection, page: '' }, location, history);
    //     this.updateMeta();
    // }

    storeIdLookupAndRequest= () => {
        const period = 600; // ms
        const sleepyAlert = setInterval(() => {
            const { storeId } = window.storeConfig;
            if (storeId !== undefined) {
                clearInterval(sleepyAlert);
                this._requestVehicleSKUs();
            }
        }, period);
    };

    updateHeaderState(isUnmatchedCategory = false) {
        const {
            changeHeaderState,
            category: {
                name
            },
            history
        } = this.props;

        const { category } = history?.location?.state || {};

        const onBackClick = category
            ? () => history.goBack()
            : () => history.push(appendWithStoreCode('/menu'));

        /**
         * Ensure the name is not set if the category IDs do not
         * match. Otherwise, the previous value is displayed.
         */
        let title = isUnmatchedCategory ? undefined : name;

        if (this.isVehiclesPage()) {
            title = __('Vehicle Part');
        }

        changeHeaderState({
            name: CATEGORY,
            title,
            onBackClick
        });
    }

    isVehiclesPage = () => {
        const vehicle = get(this.props, 'match.params.vehicle', undefined);
        if (window.location.href.includes('?find=')) {
            return true;
        }
        const isvehicle = !!vehicle;
        return isvehicle;
    };

    getVehicleModelId = () => {
        const partFinderData = get(this.props, 'location.state.partFinderData', '');
        const { modelId } = partFinderData || {};
        return modelId;
    };

    getVehiclePreModelId = (props) => {
        const partFinderData = get(props, 'location.state.partFinderData', '');
        const { modelId } = partFinderData || {};
        return modelId;
    };

    _requestCategoryWithPageList() {
        // const { categoryIds, requestOgGraph } = this.props;

        if (!this.isVehiclesPage()) {
            return this.requestCategory(); // Return added by AD
        }
        this._requestCategoryProductsInfo();
    }

    _requestCategoryProductsInfo() {
        if (!this.isVehiclesPage()) {
            const { requestProductListInfo } = this.props;
            requestProductListInfo(this._getProductListOptions(1, false, true));
        }
    }

    _requestVehicleSKUs = () => {
        // eslint-disable-next-line no-unused-vars
        const {
            requestPartsFinderSkus, requestPartsFinderUniversalSkus, updateLoadStatus, updateVehicleProductLoadStatus
        } = this.props;
        const partFinderData = get(this.props, 'location.state.partFinderData', '');
        const categoryId = get(this.props, 'location.state.categoryId', '');

        const {
            modelId, yearId, makeId, optionId
        } = partFinderData || {};

        const newPartId = `${modelId}${optionId}${categoryId}`;
        const { currentPartId = '', currentActiveUrl = '', currentFilterCategory = '' } = this.state;
        const newActiveUrl = window.location.href;

        const sortObj = this.getSelectedSortFromUrl();

        // if (newPartId !== currentPartId || newActiveUrl !== currentActiveUrl) {
        if (newPartId !== currentPartId || newActiveUrl !== currentActiveUrl || currentFilterCategory !== categoryId) {
            updateVehicleProductLoadStatus(true);
            this.setState({ currentPartId: newPartId });
            this.setState({ currentActiveUrl: newActiveUrl });
            this.setState({ currentFilterCategory: categoryId });

            updateLoadStatus(true);
            if (isEmpty(categoryId)) {
                requestPartsFinderSkus({
                    yearId, makeId, modelId, optionId, categoryIds: categoryId, sortObj, endpointUrl: '/vfits?', cb: updateProductListItems
                });

                return;
            }
            const { vehicleSpecificSkus } = this.props;
            requestPartsFinderUniversalSkus({
                yearId, makeId, modelId, optionId, categoryIds: categoryId, sortObj, vehicleSpecificSkus, cb: updateProductListItems
            });
        }
    };

    _getProductListOptions(currentPage) {
        const { categoryIds } = this.props;
        const categoryUrlPath = !categoryIds ? this._getCategoryUrlPath() : null;
        const isVehiclesPage = this.isVehiclesPage();
        let filterOptions = {};

        if (!isVehiclesPage) {
            filterOptions = {
                ...filterOptions,
                categoryIds,
                categoryUrlPath
            };
        } else {
            filterOptions = {
                ...filterOptions,
                partsFinderModel: this.getVehicleModelId()
            };
        }

        return {
            args: {
                filter: filterOptions
            },
            currentPage
        };
    }

    _getCategoryUrlPath() {
        const { location, match } = this.props;
        const path = getUrlParam(match, location);
        return path.indexOf('search') === 0 ? null : path;
    }

    getFilter() {
        const { categoryIds = -1 } = this.props;
        const customFilters = this.getSelectedFiltersFromUrl();
        const priceRange = this.getSelectedPriceRangeFromUrl();
        // const categoryUrlPath = !categoryIds ? this._getCategoryUrlPath() : null;
        const isVehiclesPage = this.isVehiclesPage();

        if (!isVehiclesPage) {
            if (categoryIds === -1) {
                return {
                    priceRange,
                    customFilters
                };
            }

            return {
                priceRange,
                customFilters,
                categoryIds
            };
        }

        if (categoryIds === -1) {
            return {
                priceRange,
                customFilters,
                partsFinderModel: this.getVehicleModelId()

            };
        }

        return {
            priceRange,
            customFilters,
            categoryIds,
            partsFinderModel: this.getVehicleModelId()

        };
    }

    render() {
        const { pageSize } = this.config;
        const {
            defaultPlpType,
            selectedLayoutType,
            activeLayoutType
        } = this.state;

        return (
            <CategoryPage
              { ...this.props }
              pageSize={ pageSize }
              defaultPlpType={ defaultPlpType }
              selectedLayoutType={ selectedLayoutType }
              activeLayoutType={ activeLayoutType }
              { ...this.containerFunctions }
              { ...this.containerProps() }
              isVehiclesPage={ this.isVehiclesPage() }
            />
        );
    }
}

export default withReducers({
    CategoryReducer
})(connect(mapStateToProps, mapDispatchToProps)(CategoryPageContainer));
