/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    SET_LOADING_DETAILS_PAGE,
    SET_LOADING_PAGE,
    UPDATE_GEAR_PAGE_CATEGORIES,
    UPDATE_GEAR_PAGE_POST_DETAILS,
    UPDATE_GEAR_PAGE_POSTS,
    UPDATE_GEAR_PAGE_RECENTS_POSTS,
    UPDATE_GEARZETTE_POST_DETAIL_RESOLVER
} from './GearzettePage.action';

export const initialState = {
    posts: [],
    recentsPost: [],
    categories: [],
    postDetails: {},
    isPostDetailsLoading: true,
    isLoading: true,
    postDetailResolver: {}
};

/** @namespace Pwa/Store/GearzettePage/Reducer/GearzettePageReducer */
export const GearzettePageReducer = (state = initialState, action) => {
    const {
        posts,
        categories,
        recentsPost,
        isLoading,
        isPostDetailsLoading,
        postDetails,
        type,
        postDetailResolver
    } = action;

    switch (type) {
    case UPDATE_GEAR_PAGE_POSTS:
        return {
            ...state,
            posts,
            isLoading
        };

    case SET_LOADING_PAGE:
        return {
            ...state,
            isLoading
        };

    case UPDATE_GEAR_PAGE_RECENTS_POSTS:
        return {
            ...state,
            recentsPost,
            isLoading
        };
    case SET_LOADING_DETAILS_PAGE:
        return {
            ...state,
            isPostDetailsLoading
        };
    case UPDATE_GEAR_PAGE_CATEGORIES:
        return {
            ...state,
            categories
        };
    case UPDATE_GEAR_PAGE_POST_DETAILS:
        return {
            ...state,
            postDetails,
            isPostDetailsLoading
        };
    case UPDATE_GEARZETTE_POST_DETAIL_RESOLVER:
        return {
            ...state,
            postDetailResolver
        };
    default:
        return state;
    }
};

export default GearzettePageReducer;
