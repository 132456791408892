/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { DeviceType } from '@scandipwa/scandipwa/src/type/Device.type';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getIsAdminLoggedInAsCustomer } from 'Util/Auth';

import { TopBannerComponent } from './TopBanner.component';

export const MyAccountDispatcher = import('Store/MyAccount/MyAccount.dispatcher');
/** @namespace Pwa/Component/TopBanner/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestCustomerLogout: () => MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.logout(null, dispatch))
});

/** @namespace Pwa/Component/TopBanner/Container/mapStateToProps */
export const mapStateToProps = (state, ownProps) => ({
    customer: ownProps.customer,
    device: state.ConfigReducer.device

});
/** @namespace Pwa/Component/TopBanner/Container/TopBannerContainer */
export class TopBannerContainer extends PureComponent {
    static propTypes = {
        requestCustomerLogout: PropTypes.func.isRequired,
        customer: PropTypes.object.isRequired,
        device: DeviceType.isRequired
    };

    containerFunctions = { onCloseSessionButtonClick: this.onCloseSessionButtonClick.bind(this) };

    onCloseSessionButtonClick() {
        const { requestCustomerLogout } = this.props;
        this.closeWindow();
        requestCustomerLogout().then(
            this.closeTheWindow()
        );
    }

    closeWindow() {
        window.opener = null;
        window.open('', '_self');
        window.close();
    }

    renderBannerComponent() {
        const response = getIsAdminLoggedInAsCustomer();
        return (
              <TopBannerComponent
                { ...this.props }
                { ...this.containerFunctions }
              />
        );
    }

    render() {
        return (
            <>
            { this.renderBannerComponent() }
            </>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TopBannerContainer);
