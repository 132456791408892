/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * CMS Page Query
 * @class InstafeedsQuery
 * @namespace Pwa/Query/Instafeeds/Query/InstafeedsQuery */
export class InstafeedsQuery {
    /**
     * get CMS Page query
     * @param  {{url_key: String, title: Int, content: String, content_heading: String, page_layout: String, meta_title: String, meta_description: String, meta_keywords, string}} options A object containing different aspects of query, each item can be omitted
     * @return {Query} CMS Page query
     * @memberof InstafeedsQuery
     */
    getQuery({ storeId }) {
        if (!storeId) {
            throw new Error('Missing argument `options`');
        }
        const graphql = `query={
            instagram(storeId:${storeId}) {
                id
                region
                type
                thumbnail
                link
                handle
                profile_url
                comments
                likes
                created_at
                updated_at
            }
        }`;

        return graphql;
    }
}

export default new InstafeedsQuery();
