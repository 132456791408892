/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import media from 'Util/Media';
import isMobile from 'Util/Mobile';

/** @namespace Pwa/Component/DesktopMenuItem/MenuItem/Component/MenuItemComponent */
export class MenuItem extends PureComponent {
    static propTypes = {
        activeMenuItemsStack: PropTypes.array.isRequired,
        item: PropTypes.object.isRequired,
        itemMods: PropTypes.object,
        handleCategoryHover: PropTypes.func.isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        handleCategoryClick: PropTypes.func.isRequired,
        isLink: PropTypes.bool,
        closeMenu: PropTypes.func
    };

    static defaultProps = {
        itemMods: {},
        isLink: false,
        closeMenu: () => {}
    };

    renderItemContentImage(icon, itemMods) {
        const { isBanner, isLogo, type } = itemMods;

        if (!icon
            || (!isMobile.any() && !isBanner && !isLogo)
            || (type === 'subcategory')
        ) {
            return null;
        }

        return (
            <Image
              mix={ { block: 'Menu', elem: 'Image', mods: itemMods } }
              src={ icon && media(icon) }
              ratio="custom"
            />
        );
    }

    renderItemContentTitle(isBanner, title) {
        if (isBanner) {
            return (
                <>
                    { title }
                </>
            );
        }

        return title;
    }

    renderItemContent(item, itemMods = {}) {
        let { label, name, icon } = item;
        const { isBanner } = itemMods;
        if (isEmpty(label) && !isEmpty(name)) {
            label = name;
        }

        return (
            <figure
              block="Menu"
              elem="ItemFigure"
              mods={ itemMods }
            >
                { this.renderItemContentImage(icon, itemMods) }
                <figcaption
                  block="Menu"
                  elem="ItemCaption"
                  mods={ itemMods }
                >
                    { this.renderItemContentTitle(isBanner, label) }
                </figcaption>
            </figure>
        );
    }

    renderItemLinkContent() {
        const {
            activeMenuItemsStack,
            item,
            itemMods,
            handleCategoryHover,
            closeMenu
        } = this.props;

        let {
            url,
            label,
            name
        } = item;

        if (isEmpty(label) && !isEmpty(name)) {
            label = name;
        }

        const isHovered = activeMenuItemsStack.includes(label);

        if (url && url.includes('frontrunnerpride')) {
            return (
                <Link
                  to={ url }
                  block="Menu"
                  elem="Link"
                  id={ label }
                  onMouseEnter={ handleCategoryHover }
                  mods={ { isHovered } }
                  onClick={ closeMenu }
                  target="_blank"
                >
                    { this.renderItemContent(item, itemMods) }
                </Link>
            );
        }

        const baseUrl = window.location.pathname;
        const url1 = url?.split('.html')[0];
        if (baseUrl.includes(url1)) {
            return (
                <Link
                  to={ url }
                  block="Menu"
                  elem="Link active_menu"
                  id={ label }
                  onMouseEnter={ handleCategoryHover }
                  mods={ { isHovered } }
                  onClick={ closeMenu }
                >
                    { this.renderItemContent(item, itemMods) }
                </Link>
            );
        }
        if (!url || url === '/#') {
            return (
                <Link
                  to="/"
                  block="Menu"
                  elem="Link NoUrl"
                  id={ label }
                  mods={ { isHovered } }
                >
                    { this.renderItemContent(item, itemMods) }
                </Link>
            );
        }

        // If external link, add noopener and noreferrer
        if (url && (url.includes('http://') || url.includes('https://'))) {
            return (
                <Link
                  to={ url }
                  block="Menu"
                  elem="Link"
                  id={ label }
                  onMouseEnter={ handleCategoryHover }
                  mods={ { isHovered } }
                  onClick={ closeMenu }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                    { this.renderItemContent(item, itemMods) }
                </Link>
            );
        }

        return (
            <Link
              to={ url }
              block="Menu"
              elem="Link"
              id={ label }
              onMouseEnter={ handleCategoryHover }
              mods={ { isHovered } }
              onClick={ closeMenu }
            >
                { this.renderItemContent(item, itemMods) }
            </Link>
        );
    }

    render() {
        const { item, itemMods, isLink } = this.props;

        if (isLink) {
            return this.renderItemLinkContent();
        }

        return this.renderItemContent(item, itemMods);
    }
}

export default MenuItem;
