/* eslint-disable no-console */
/* eslint-disable max-len */
export const UPDATE_COMPANY_USERS_DATA = 'UPDATE_COMPANY_USERS_DATA';
export const SET_COMPANY_USERS_LOADING = 'SET_COMPANY_USERS_LOADING';
export const SAVE_COMPANY_USERS_DATA = 'SAVE_COMPANY_USERS_DATA';
export const SET_COMPANY_USERS_ADD = 'SET_COMPANY_USERS_ADD';
export const SET_COMPANY_USERS_EDIT = 'SET_COMPANY_USERS_EDIT';

/** @namespace Pwa/Store/CompanyUsers/Action/updateCompanyUsersData */
export const updateCompanyUsersData = ({ companyUsers = {} }, isLoading = false) => ({
    type: UPDATE_COMPANY_USERS_DATA,
    companyUsers,
    isLoading
});

/** @namespace Pwa/Store/CompanyUsers/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_COMPANY_USERS_LOADING,
    isLoading
});

/** @namespace Pwa/Store/CompanyUsers/Action/setAddUser */
export const setAddUser = (isOpenPopup) => ({
    type: SET_COMPANY_USERS_ADD,
    isOpenPopup
});

/** @namespace Pwa/Store/CompanyUsers/Action/setEditUser */
export const setEditUser = (isOpenPopup) => ({
    type: SET_COMPANY_USERS_EDIT,
    isOpenPopup
});

/** @namespace Pwa/Store/CompanyUsers/Action/saveCompanyUsersData */
export const saveCompanyUsersData = (data, isLoading) => ({
    type: SAVE_COMPANY_USERS_DATA,
    data,
    isLoading
});
