/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Cookies from 'universal-cookie';

import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';

export const GUEST_QUOTE_ID = 'guest_quote_id';
export const cookies = new Cookies();
export const CART_ID = 'cart_id';

/** @namespace Pwa/Util/Cart/Token/setGuestQuoteId */
export const setGuestQuoteId = (token) => {
    const now = new Date();
    now.setTime(now.getTime() + 5 * 24 * 3600 * 1000);

    const cookieData = {
        path: '/',
        expires: now
    };

    if (process.env.NODE_ENV === 'production') {
        cookieData.domain = 'frontrunneroutfitters.com';
    }

    cookies.set('guest_quote_id', token, cookieData);
    BrowserDatabase.setItem({
        token,
        isCustomerToken: isSignedIn()
    }, GUEST_QUOTE_ID);
};

/** @namespace Pwa/Util/Cart/Token/getGuestQuoteId */
export const getGuestQuoteId = () => {
    const {
        token,
        isCustomerToken
    } = BrowserDatabase.getItem(GUEST_QUOTE_ID) || {};

    if (isCustomerToken && !isSignedIn()) {
        return null;
    }

    return token;
};

/** @namespace Pwa/Util/Cart/Token/deleteGuestQuoteId */
export const deleteGuestQuoteId = () => {
    cookies.remove('guest_quote_id');
    BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
};

/** @namespace Pwa/Util/Cart/Token/deleteCartId */
export const deleteCartId = () => {
    const { website_code } = window;

    const tokens = BrowserDatabase.getItem(CART_ID);

    // tokens[website_code] = undefined;

    BrowserDatabase.setItem(tokens, CART_ID);
};

/** @namespace Pwa/Util/Cart/Token/getCartId */
export const getCartId = () => {
    const { website_code } = window;

    const tokens = BrowserDatabase.getItem(CART_ID) || {};

    const token = tokens[website_code];

    if (token) {
        if (token.isCustomerToken && !isSignedIn()) {
            return null;
        }

        return token.token;
    }

    return null;
};
