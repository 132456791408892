/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import EmailFriendForm from 'Component/EmailFriendForm';
import Popup from 'Component/Popup';
import { ProductType } from 'Type/ProductList';

import './EmailFriend.style';

export const EF_POPUP_ID = 'EF_POPUP_ID';

/** @namespace Pwa/Component/EmailFriend/Component/EmailFriendComponent */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/derived-class-names
export class EmailFriend extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        showPopup: PropTypes.func.isRequired
    };

    renderPopup() {
        const { product } = this.props;

        return (
            <Popup
              id={ EF_POPUP_ID }
              mix={ { block: 'EmailFriend', elem: 'Popup' } }
            >
              <EmailFriendForm product={ product } />
            </Popup>
        );
    }

    renderButton() {
        const { showPopup, icon } = this.props;

        return (
            <button
              block="EmailFriend"
              elem="Button"
              mix={ { block: 'Button' } }
              onClick={ showPopup }
            >
              { icon }
              <span>{ __('Email') }</span>
            </button>
        );
    }

    render() {
        return (
            <ContentWrapper
              label="Product QA"
              mix={ { block: 'ProductQA' } }
              wrapperMix={ { block: 'ProductQA', elem: 'Wrapper' } }
            >
              { this.renderButton() }
              { this.renderPopup() }
            </ContentWrapper>
        );
    }
}

export default EmailFriend;
