/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/NewsletterSubscription/Query/NewsletterSubscriptionQuery */
class NewsletterSubscriptionQuery {
    subscribe = ({ email }) => new Field('subscribeNewsletter')
        .addArgument('email', 'String!', email)
        .addFieldList([
            'message'
        ]);

    subscribePopup = ({ email, firstname, lasttname }) => new Field('subscribeNewsletterPopUp')
        .addArgument('email', 'String!', email)
        .addArgument('first_name', 'String!', firstname)
        .addArgument('last_name', 'String!', lasttname)
        .addFieldList([
            'message'
        ]);
}

export default new NewsletterSubscriptionQuery();
