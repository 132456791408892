/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-useless-escape */
/* eslint-disable radix */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable max-lines */
/* eslint-disable fp/no-let */
/* eslint-disable eqeqeq */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import {
    faPlus,
    faShippingFast
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import HTML from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import { MixType } from 'Type/Common.type';
import { PriceType } from 'Type/ProductList';
import {
    isAusStore, isEurStore, isIntStore,
    isRsaStore, isUsaStore
} from 'Util/FrontRunner/Store';
import { formatPrice, removeCurrencySymbol, roundPrice } from 'Util/Price';

import './ProductPrice.style';

/**
 * Product price
 * @class ProductPrice
 * @namespace Pwa/Component/ProductPrice/Component/ProductPrice */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/derived-class-names
export class ProductPrice extends PureComponent {
    static propTypes = {
        isSchemaRequired: PropTypes.bool,
        roundedRegularPrice: PropTypes.string,
        priceCurrency: PropTypes.string,
        discountPercentage: PropTypes.any,
        formattedFinalPrice: PropTypes.string,
        formattedSubPrice: PropTypes.string,
        variantsCount: PropTypes.number,
        price: PriceType,
        mix: MixType,
        type_id: PropTypes.string,
        gift_price: PropTypes.object
    };

    static defaultProps = {
        isSchemaRequired: false,
        roundedRegularPrice: '0',
        priceCurrency: 'USD',
        discountPercentage: 0,
        formattedFinalPrice: '0',
        formattedSubPrice: null,
        variantsCount: 0,
        mix: {},
        price: {},
        type_id: 'simple',
        gift_price: {}
    };

    renderPlaceholder() {
        const { mix } = this.props;

        return (
            <p block="ProductPrice" aria-label="Product Price" mix={ mix }>
                <TextPlaceholder mix={ { block: 'ProductPrice', elem: 'Placeholder' } } length="custom" />
            </p>
        );
    }

    getCurrencySchema() {
        const { isSchemaRequired, priceCurrency } = this.props;
        return isSchemaRequired ? { itemProp: 'priceCurrency', content: priceCurrency } : {};
    }

    getCurrentPriceSchema() {
        const { isSchemaRequired, variantsCount, price } = this.props;
        const content_price = price.minimum_price.final_price
            ? price.minimum_price.final_price.value : price.minimum_price.regular_price.value;

        if (variantsCount > 1) {
            return isSchemaRequired ? { itemProp: 'lowPrice', content: parseFloat(content_price).toFixed(2) } : {};
        }

        return isSchemaRequired ? { itemProp: 'price', content: parseFloat(content_price).toFixed(2) } : {};
    }

    renderCurrentPrice() {
        const {
            discountPercentage,
            formattedFinalPrice,
            tax_description,
            type_id,
            price_range,
            roundedRegularPrice,
            final_price = [],
            price
        } = this.props;

        const priceSchema = this.getCurrentPriceSchema();
        const finalPrice = final_price[0]?.selection_details[0]?.final_option_price;
        const finalPrice2 = price_range?.maximum_price?.default_final_price?.value;
        const finalFprice = formatPrice(roundPrice(finalPrice));
        const finalFprice2 = formatPrice(roundPrice(finalPrice2));
        const roundedRPrice = parseFloat(roundedRegularPrice.replace(/[^0-9\-]+/g, ''));
        const formattedFPrice = parseFloat(formattedFinalPrice.replace(/[^0-9\-]+/g, ''));
        const giftPrice = price?.minimum_price?.final_price?.value;
        const giftFinalPrice = formatPrice(roundPrice(giftPrice));
        let formatted_finalPrice = finalFprice;
        if (!finalPrice) {
            if (roundedRPrice < formattedFPrice && !(type_id == 'amgiftcard') && !(type_id == 'configurable')) {
                formatted_finalPrice = roundedRegularPrice;
            } else {
                formatted_finalPrice = formattedFinalPrice;
            }
        }
        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'span' : 'span';
        // eslint-disable-next-line max-len
        // force unequal comparatment - unsure of resulting type
        // eslint-disable-next-line eqeqeq
        if (formattedFinalPrice == 0) {
            return null;
        }

        let formattedFnlPrice = formatted_finalPrice;
        if (formattedFinalPrice.includes('NaN')) {
            formattedFnlPrice = finalFprice2;
            // formattedFnlPrice = formattedFnlPrice.replace('NaN', ' 0.00');
        }

        if (type_id == 'amgiftcard') {
            formattedFnlPrice = giftFinalPrice;
        }

        const priceData = {
            formattedPrice: formattedFnlPrice,
            priceSchema: { ...priceSchema }
        };

        return (
            <PriceSemanticElementName>
                <span className="price-current">
                    { this.renderBuildCroPrice(priceData) }
                    { this.renderOldPrice() }
                </span>
                { isEurStore() && (
                    <span className="price-box-text">{ tax_description }</span>
                )}
            </PriceSemanticElementName>
        );
    }

    renderBuildCroPrice(priceData) {
        const { formattedPrice, priceSchema } = priceData;
        let cusPrice = removeCurrencySymbol(formattedPrice);
        const storeCurrency = window.storeConfig.currencySymbol;

        // Split according to price formatting
        if (['de_de', 'nl_be', 'es_be', 'fr_be', 'es_us'].includes(window.storeConfig.code)) {
            cusPrice = cusPrice.split(',');
        } else {
            cusPrice = cusPrice.split('.');
        }

        if (isUsaStore() || isAusStore() || isIntStore() || isEurStore()) {
            if (window.storeConfig.code === 'de_de') {
                return (
                <div className="price-amznstyle">
                    <div className="price-container" area-hidden="true">
                        <span className="price-whole">
                            { cusPrice[0] }
                        </span>
                        <span className="price-decimal">
                            { cusPrice[1] }
                        </span>
                        <span className="price-symbol">
                            { ' ' }
                            { storeCurrency }
                        </span>
                    </div>
                    <span className="offscreen" { ...priceSchema }>
                        { formattedPrice }
                    </span>
                </div>
                );
            }

            return (
                <div className="price-amznstyle">
                    <div className="price-container" area-hidden="true">
                        <span className="price-symbol">
                            { storeCurrency }
                        </span>
                        <span className="price-whole">
                            { cusPrice[0] }
                        </span>
                        <span className="price-decimal">
                            { cusPrice[1] }
                        </span>
                    </div>
                    <span className="offscreen" { ...priceSchema }>
                        { formattedPrice }
                    </span>
                </div>
            );
        }

        if (isRsaStore()) {
            const decimal = cusPrice[1];
            let decimalText = (
                <span className="price-decimal">
                    { cusPrice[1] }
                </span>
            );

            if (parseInt(decimal) === 0) {
                decimalText = null;
            }

            return (
                <div className="price-amznstyle">
                    <div className="price-container" area-hidden="true">
                        <span className="price-symbol">
                            { storeCurrency }
                        </span>
                        <span className="price-whole">
                            { cusPrice[0] }
                        </span>
                        { decimalText }
                    </div>
                    <span className="offscreen" { ...priceSchema }>
                        { formattedPrice }
                    </span>
                </div>
            );
        }

        return formattedPrice;
    }

    renderSubPrice() {
        const { formattedSubPrice } = this.props;

        if (!formattedSubPrice) {
            return null;
        }

        return (
            <span
              aria-label={ __('Current product price excl. tax') }
              block="ProductPrice"
              elem="SubPrice"
            >
                { `${ __('Excl. tax:') } ${ formattedSubPrice }` }
            </span>
        );
    }

    renderOldPrice() {
        const {
            roundedRegularPrice,
            formattedFinalPrice,
            discountPercentage,
            isSchemaRequired,
            tax_description,
            variantsCount,
            final_price = [],
            type_id,
            price,
            price_range,
            variants,
            configurableVariantIndex
        } = this.props;

        const finalPrice2 = price_range?.maximum_price?.default_final_price?.value;

        // Check if B2B customer (Use local storage check)
        const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        const storeCurrency = window.storeConfig.currencySymbol;
        const schema = isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : {};

        // Final selling price
        let finalPrice = final_price[0]?.selection_details[0]?.final_option_price ?? 0;
        if (final_price && (final_price === undefined || finalPrice === 0)) {
            finalPrice = price?.minimum_price?.final_price?.value ?? 0;
        }
        let finalFprice = parseFloat(roundPrice(finalPrice));
        if (type_id === 'bundle') {
            finalPrice = price?.minimum_price?.final_price?.value ?? 0;
            finalFprice = parseFloat(roundPrice(finalPrice));
        }

        const roundedRPrice = parseFloat(roundedRegularPrice.replace(/[^0-9\-]+/g, ''));
        const formattedFPrice = parseFloat(formattedFinalPrice.replace(/[^0-9\-]+/g, ''));

        // Regular price
        let regularPrice = final_price[0]?.selection_details[0]?.regular_option_price ?? 0;
        if (final_price && (final_price === undefined || regularPrice === 0)) {
            regularPrice = price?.minimum_price?.regular_price?.value ?? 0;
        }
        let regularFprice = parseFloat(roundPrice(regularPrice));
        if (type_id === 'bundle') {
            regularPrice = price_range?.minimum_price?.regular_price?.value ?? 0;
            regularFprice = parseFloat(roundPrice(regularPrice));
        }

        const regularRoundPrice = roundPrice(regularPrice);

        if (finalFprice >= regularFprice) {
            return null;
        }
        if (type_id && type_id === 'configurable' && formattedFPrice >= roundedRPrice) {
            return null;
        }

        if (finalFprice < regularFprice) {
            let discountAmount = roundPrice(regularFprice - finalPrice2);

            if (type_id && type_id === 'configurable' && variants) {
                const productOrVariant = variants[configurableVariantIndex];
                if (productOrVariant) {
                    const {
                        price_range: {
                            minimum_price: {
                                final_price: {
                                    value: finalFprice
                                } = {}
                            } = {}
                        } = {}
                    } = productOrVariant;

                    discountAmount = roundPrice(regularFprice - finalFprice);
                }
            }

            return (
                <div
                  block="ProductPrice"
                  elem="HighPrice"
                  aria-label={ __('Old product price') }
                >
                    <del
                      { ...schema }
                    >
                        { `${roundedRegularPrice}` }
                    </del>
                    { !isB2B && parseFloat(discountAmount) > 0 && (
                        <span
                          block="ProductPrice"
                          elem="SaveDiscount"
                          aria-label="Save Discount"
                        >
                            { __('Save %s', `${storeCurrency}${discountAmount}`) }
                        </span>
                    ) }
                </div>
            );
        }

        if (roundedRPrice > finalFprice) {
            return (
                <del
                  block="ProductPrice"
                  elem="HighPrice"
                  aria-label={ __('Old product price') }
                  { ...schema }
                >
                    { roundedRegularPrice }
                </del>
            );
        }
        if (discountPercentage <= 0 || roundedRegularPrice == 'NaN' || roundedRegularPrice.includes('NaN')) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              { ...schema }
            >
                { roundedRegularPrice }
                {/* <span className="price-box-text">{ tax_description }</span> */}
            </del>
        );
    }

    renderSchema() {
        const { isSchemaRequired } = this.props;

        if (isSchemaRequired) {
            const currencySchema = this.getCurrencySchema();
            return (
                <meta { ...currencySchema } />
            );
        }

        return null;
    }

    renderFreeShipping() {
        const {
            discountPercentage,
            shipping_block
        } = this.props;

        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';
        const free_shipping_product_text = get(shipping_block, '0.free_shipping_product_text', '');
        const free_shipping_product_subtext = get(shipping_block, '0.free_shipping_product_subtext', '');

        if (!free_shipping_product_text) {
            return null;
        }

        return (
            <PriceSemanticElementName className="free_shipping_wrap">
                <div
                  className="fr_product_free_shipping"
                >
                    <div className="fr_product_free_shipping-icon">
                        <FontAwesomeIcon icon={ faShippingFast } size="1x" color="#222" />
                    </div>
                    <div className="fr_product_free_shipping-content">
                        <div className="title">
                            { free_shipping_product_text }
                        </div>
                        <div className="des">
                            <HTML content={ free_shipping_product_subtext } />
                        </div>
                    </div>
                </div>
            </PriceSemanticElementName>
        );
    }

    render() {
        const {
            price: {
                minimum_price: {
                    final_price,
                    regular_price,
                    baseBundlePrice
                } = {}
            } = {},
            discountPercentage,
            formattedFinalPrice,
            mix,
            type_id,
            tax_description,
            shipping_block
        } = this.props;

        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';
        const canShipNow = get(shipping_block, '0.can_ship_now', '');

        return (
            <>
                { /* { canShipNow && (
                    <span className="can-ship-now">
                    <FontAwesomeIcon icon={ ['fas', 'check'] } />
                    { ' ' }
                    { canShipNow }
                    { ' ' }
                    </span>
                ) } */ }
                <div
                  block="ProductPrice"
                  mods={ { hasDiscount: discountPercentage !== 0 } }
                  mix={ mix }
                  aria-label={ `Product price: ${formattedFinalPrice}` }
                >
                    { this.renderCurrentPrice() }
                    { /* { this.renderOldPrice() } */ }
                    { this.renderSubPrice() }
                    { this.renderSchema() }
                </div>
            </>
        );
    }
}

export default ProductPrice;
