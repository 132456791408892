/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */

import CloseIcon from '@scandipwa/scandipwa/src/component/CloseIcon';
import { DeviceType } from '@scandipwa/scandipwa/src/type/Device.type';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { getIsAdminLoggedInAsCustomer } from 'Util/Auth';
import { capitalizeFirstLetter } from 'Util/StringOprations/index';

import './TopBanner.style';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/** @namespace Pwa/Component/TopBanner/Component/TopBannerComponent */
export class TopBannerComponent extends PureComponent {
    static propTypes = {
        customer: PropTypes.object.isRequired,
        onCloseSessionButtonClick: PropTypes.func.isRequired,
        device: DeviceType.isRequired

    };

    renderTopBanner() {
        const { customer = {}, device = {} } = this.props;
        if (isEmpty(customer)) {
            return null;
        }
        const value = getIsAdminLoggedInAsCustomer();
        if (!value) {
            return null;
        }
        if (device.isMobile) {
            return null;
        }

        return (
            <div className="top_nav top_banner">
                <nav block="Header" elem="Nav">
                    { this.renderTopBannerComponent(customer, device) }
                </nav>
            </div>
        );
    }

    renderClearSessionButton() {
        const { onCloseSessionButtonClick } = this.props;
        return (
            <button
              block="Header"
              elem="MyAccountWrapper"
              tabIndex="0"
              aria-label="Close Session"
              id="closeSession"
              onClick={ onCloseSessionButtonClick }
            >
                <span>
                    { __('Close Session') }
                </span>
                <CloseIcon />
            </button>
        );
    }

    renderBannerLogo() {
        return (
            <div
              className="image-div"
            >
                <img
                  src="/media/images/logo_white.svg"
                  alt="Front Runner | Off-Road Tough Roof Racks &amp; Vehicle Adventure Gear"
                  loading="lazy"
                />
            </div>
        );
    }

    renderMobileBannerComponent(firstname, lastname, website_name) {
        return (
            <div className="banner-div">
                { this.renderBannerLogo() }
                <div>
                    You are connected as
                    { ' ' }
                    <b>
                        { firstname }
                        { ' ' }
                        { lastname }
                    </b>
                    { ' ' }
                    on
                    { ' ' }
                    { website_name }
                </div>
                { this.renderClearSessionButton() }
            </div>
        );
    }

    renderTopBannerComponent(customer, device) {
        const { firstname = '', lastname = '' } = customer;
        const { website_name } = window.storeConfig;
        // if (device.isMobile) {
        //     return (<>{ this.renderMobileBannerComponent(firstname, lastname, website_name) }</>);
        // }

        return (
            <div className="Banner-Top">
                <div className="Left-Sec">
                    {/* { this.renderBannerLogo() } */}
                    <div className="banner-text">
                        { __('You are connected as ') }
                        { ' ' }
                        <span className="banner-text-name">
                            { ' ' }
                            { capitalizeFirstLetter(firstname) }
                            { ' ' }
                            { capitalizeFirstLetter(lastname) }
                            { ' ' }
                        </span>
                        <span className="banner-text">
                            { __('on') }
                            {' '}
                            { website_name }
                        </span>
                    </div>
                </div>
                <div className="Right-Sec">
                    { this.renderClearSessionButton() }
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                { this.renderTopBanner() }
            </>
        );
    }
}
export default TopBannerComponent;
