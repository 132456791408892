/* eslint-disable no-console */
/* eslint-disable max-len */
export const UPDATE_COMPANY_STATEMENTS_DATA = 'UPDATE_COMPANY_STATEMENTS_DATA';
export const SET_COMPANY_STATEMENTS_LOADING = 'SET_COMPANY_STATEMENTS_LOADING';
export const SAVE_COMPANY_STATEMENTS_DATA = 'SAVE_COMPANY_STATEMENTS_DATA';

/** @namespace Pwa/Store/CompanyStatements/Action/updateCompanyStatementsData */
export const updateCompanyStatementsData = ({ companyStatements = {} }, isLoading = false) => ({
    type: UPDATE_COMPANY_STATEMENTS_DATA,
    companyStatements,
    isLoading
});

/** @namespace Pwa/Store/CompanyStatements/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_COMPANY_STATEMENTS_LOADING,
    isLoading
});

/** @namespace Pwa/Store/CompanyStatements/Action/saveCompanyStatementsData */
export const saveCompanyStatementsData = (CompanyStatements, isLoading) => ({
    type: SAVE_COMPANY_STATEMENTS_DATA,
    CompanyStatements,
    isLoading
});
