import { isProduction } from 'Util/Helper';

/** @namespace Pwa/Util/BreadpayKeys/Index/breadpayKey */
export const breadpayKey = () => {
    let breadKey = 'a531dc3a-d9a8-46a2-8394-1d77448227d5';
    if (isProduction() && process.env.NODE_ENV === 'production') {
        breadKey = 'c984854e-0341-4758-807e-3ced2d9c97c1';
    }

    return breadKey;
};
