/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ProductReviewRating from 'Component/ProductReviewRating';
import {
    ProductReviews as SourceProductReviews
} from 'SourceComponent/ProductReviews/ProductReviews.component';
import { showNewReviewPopup } from 'Util/Product';

import './ProductReviews.override.style';
/** @namespace Pwa/Component/ProductReviews/Component/ProductReviews */
export class ProductReviews extends SourceProductReviews {
    renderButton() {
        return (
            <button
              block="ProductReviews"
              elem="Button"
              mix={ { block: 'Button' } }
              onClick={ showNewReviewPopup }
            >
                { __('Write a review') }
            </button>
        );
    }

    renderRatingData() {
        const {
            product: {
                review_summary: {
                    rating_summary,
                    review_count
                } = {}
            }
        } = this.props;

        const STARS_COUNT = 5;
        const PERCENT = 100;

        // eslint-disable-next-line no-mixed-operators
        const percent = parseFloat(STARS_COUNT * (rating_summary || 0) / PERCENT).toFixed(2);

        if (!review_count) {
            return this.renderNoRating();
        }

        return (
            <>
                { this.renderRatingSchema(rating_summary, review_count) }
                <ProductReviewRating
                  mix={ { block: 'ProductReviews', elem: 'SummaryRating' } }
                  summary={ rating_summary }
                />
                <p block="ProductReviews" elem="SummaryDetails">
                    { percent }
                    <span>{ __('%s reviews', review_count || 0) }</span>
                </p>
            </>
        );
    }

    render() {
        return (
            <div className="ProductReviews">
               { this.renderSummary() }
               { this.renderList() }
            </div>
        );
    }
}

export default ProductReviews;
