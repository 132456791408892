// TODO: implement GET_ORDER_LIST
export const GET_ORDER_LIST = 'GET_ORDER_LIST';
// TODO: implement getOrderList
// export const getOrderList = 'getOrderList';
/** @namespace Pwa/Store/Order/Action/getOrderList */
export const getOrderList = (orderList, status) => ({
    type: GET_ORDER_LIST,
    orderList,
    status
});

export const GET_INVOICE_LIST = 'GET_INVOICE_LIST';
export const SET_ORDER_LOADING_STATUS = 'SET_ORDER_LOADING_STATUS';

/** @namespace Pwa/Store/Order/Action/getInvoiceList */
export const getInvoiceList = (invoiceList, status) => ({
    type: GET_INVOICE_LIST,
    invoiceList,
    status
});

/** @namespace Pwa/Store/Order/Action/setLoadingStatus */
export const setLoadingStatus = (status) => ({
    type: SET_ORDER_LOADING_STATUS,
    status
});
