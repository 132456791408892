/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrowserDatabase from 'Util/BrowserDatabase';

import { UPDATE_CONFIG, UPDATE_CONFIG_DEVICE, UPDATE_NOTIFICATIONS } from './Config.action';

export const MAX_WIDTH = 150;
export const MAX_HEIGHT = 40;

/** @namespace Pwa/Store/Config/Reducer/filterStoreConfig */
export const filterStoreConfig = (config) => Object.entries(config).reduce(
    (acc, [key, value]) => (value !== null ? { ...acc, [key]: value } : acc),
    {}
);

export const {
    countries, reviewRatings, storeConfig, currencyData, cartDisplayConfig
} = BrowserDatabase.getItem('config') || {
    countries: [],
    reviewRatings: [],
    storeConfig: {},
    currencyData: {},
    cartDisplayConfig: {
        display_tax_in_price: '',
        display_tax_in_subtotal: '',
        display_tax_in_shipping_amount: '',
        include_tax_in_order_total: false,
        display_full_tax_summary: false,
        display_zero_tax_subtotal: false
    }
};

/** @namespace Pwa/Store/Config/Reducer/getIndexedRatings */
export const getIndexedRatings = (reviewRatings) => ((reviewRatings) ? reviewRatings.items || [] : []);

/** @namespace Pwa/Store/Config/Reducer/getCurrencyData */
export const getCurrencyData = (base, state) => (base || state.currencyData || {});

/** @namespace Pwa/Store/Config/Reducer/getCountryData */
export const getCountryData = (base, state) => (base || state.countries || {});

/** @namespace Pwa/Store/Config/Reducer/getCheckoutAgreementData */
export const getCheckoutAgreementData = (base, state) => (base || state.checkoutAgreements || {});

/** @namespace Pwa/Store/Config/Reducer/getInitialState */
export const getInitialState = () => ({
    ...filterStoreConfig(storeConfig),
    countries,
    reviewRatings,
    checkoutAgreements: [],
    currencyData,
    isLoading: true,
    cartDisplayConfig,
    priceTaxDisplay: {},
    device: {
        isMobile: true,
        android: true,
        ios: false,
        blackberry: false,
        opera: false,
        windows: false,
        standaloneMode: window.matchMedia('(display-mode: standalone)').matches
    }
});

/** @namespace Pwa/Store/Config/Reducer/ConfigReducer */
export const ConfigReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        config: {
            countries,
            reviewRatings,
            checkoutAgreements,
            currencyData,
            storeConfig = {},
            cartDisplayConfig = {}
        } = {},
        device,
        notifications
    } = action;

    switch (type) {
    case UPDATE_CONFIG:
        const filteredStoreConfig = filterStoreConfig(storeConfig);
        const { secure_base_media_url } = filteredStoreConfig;
        window.secure_base_media_url = secure_base_media_url;
        const {
            homepage_small_slider_fsync_category_id: productSliderCategoryId,
            homepage_main_slider_fsync_category_id: sliderCategoryId,
            reCaptchaKey,
            secure_base_url,
            facebookPixelCode,
            gtmCode,
            isDisableZaCheckout,
            currencySymbol,
            default_display_currency_code,
            googleAnalyticsAppId,
            id,
            fsync_url,
            locale,
            breadpay_key_config_data,
            website_name
        } = storeConfig;

        if (fsync_url) {
            // productSliderCategoryId, TODO:: have to fix from backend then to enable for dynamioc cases
            // sliderCategoryId,
            const storeConfig = {
                ...window.storeConfig,
                reCaptchaKey,
                baseUrl: secure_base_url,
                facebookPixelCode,
                gtmCode,
                productSliderCategoryId,
                sliderCategoryId,
                isDisableZaCheckout,
                currencySymbol,
                currencyCode: default_display_currency_code,
                googleAnalyticsAppId,
                storeId: id,
                fsync_url: `${fsync_url}graphql`,
                breadpay_key_config_data,
                website_name
            };

            window.storeConfig = storeConfig;
            window.defaultLocale = locale;
            window.storeConfig.googleAnalyticsAppId = googleAnalyticsAppId;
            window.storeConfig.gtmCode = gtmCode;
            window.storeConfig.facebookPixelCode = facebookPixelCode;
        }

        return {
            ...state,
            countries: getCountryData(countries, state),
            reviewRatings: getIndexedRatings(reviewRatings),
            checkoutAgreements: getCheckoutAgreementData(checkoutAgreements, state),
            currencyData: getCurrencyData(currencyData, state),
            ...filteredStoreConfig,
            // Should be updated manually as filteredStoreConfig does not contain header_logo_src when it is null
            // and header_logo_src takes old value
            isLoading: false,
            cartDisplayConfig
        };

    case UPDATE_CONFIG_DEVICE:
        return {
            ...state,
            device: {
                ...state.device,
                ...device
            }
        };

    case UPDATE_NOTIFICATIONS:
        return {
            ...state,
            header_notifications: notifications
        };

    default:
        return state;
    }
};

export default ConfigReducer;
