export const SET_NEWSLETTER_SUBSCRIPTION_LOADING = 'SET_NEWSLETTER_SUBSCRIPTION_LOADING';
export const SET_NEWSLETTER_SUBSCRIPTION_RESPONSE = 'SET_NEWSLETTER_SUBSCRIPTION_RESPONSE';

/** @namespace Pwa/Store/NewsletterSubscription/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_NEWSLETTER_SUBSCRIPTION_LOADING,
    isLoading
});

/** @namespace Pwa/Store/NewsletterSubscription/Action/setResponse */
export const setResponse = (data) => ({
    type: SET_NEWSLETTER_SUBSCRIPTION_RESPONSE,
    data
});
