/* eslint-disable no-console */
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/ContactPage/Query/ContactPageQuery */
export class ContactPageQuery {
  getContent = () => new Field('contactUsData')
      .addFieldList([
          'google_map_api_key',
          'latitude',
          'longitude',
          'contact_address',
          'help_link'
      ]);

  getYears = () => new Field('years_data')
      .addField(this.getYearsData());

  getYearsData = () => new Field('data')
      .addFieldList([
          'label',
          'value'
      ]);

  submit = (data) => {
      const contactUsItem = { ...data.options };

      return new Field('contactUsSubmit')
          .addArgument('contactUsItem', 'ContactUsInput!', contactUsItem)
          .addFieldList([
              'status',
              'message'
          ]);
  };
}

export default new ContactPageQuery();
