/* eslint-disable no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_FOOTER } from './Footer.action';

export const initialState = {
    data: {},
    newLetterConfig: {},
    isLoading: true
};

/** @namespace Pwa/Store/Footer/Reducer/FooterReducer */
export const FooterReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        data,
        newLetterConfig
    } = action;

    switch (type) {
    case UPDATE_FOOTER:
        if (newLetterConfig && newLetterConfig !== undefined && data !== undefined) {
            return {
                ...state,
                isLoading,
                data,
                newLetterConfig
            };
        }

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default FooterReducer;
