/* eslint-disable no-unused-vars */
/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';

import './Maintenance.style';

/** @namespace Pwa/Route/Maintenance/Component/MaintenanceComponent */
export class MaintenanceComponent extends PureComponent {
     static propTypes = {
         //  onClick: PropTypes.func.isRequired,
         //  errorDetails: PropTypes.shape({
         //      err: PropTypes.shape({

         //      }),
         //      info: PropTypes.shape({
         //          componentStack: PropTypes.string
         //      })
         //  }).isRequired
     };

     renderErrorDetails() {
         if (process.env.NODE_ENV === 'production') {
             return null;
         }

         return (
             <div block="Maintenance" elem="Debug">
                 { 'errorString' }
                 { 'componentStack' }
             </div>
         );
     }

     render() {
         // eslint-disable-next-line no-unused-vars
         const { onClick, maintenance_data = {} } = this.props;
         const { status = false } = maintenance_data;

         if (!status) {
             return null;
         }

         if (document.getElementsByName('prerender-status-code').length === 0) {
             const prerender404 = document.createElement('meta');
             prerender404.name = 'prerender-status-code';
             prerender404.content = '503';
             document.getElementsByTagName('head')[0].appendChild(prerender404);
         }

         return (
             <main block="Maintenance">
                 <ContentWrapper label="Maintenance went wrong on the page.">
                     <div className="mnt-logo">
                        <img src="/media/logo/logo.svg" alt="logo" />
                     </div>
                     <h1 block="Maintenance" elem="Heading">{ __('Oops! You caught us in maintenance mode.') }</h1>
                     <h2 block="Maintenance" elem="SubHeading">
                         { __('Grab a cup of coffee and check back shortly.') }
                         <br />
                         { __("We don't want to leave you hanging!") }
                     </h2>
                     <div>
        <div className="aligner">
            <div className="aligner-item coffee-container">
                <div className="steam-container">
                    <div className="squiggle-container squiggle-container-1">
                        <div className="squiggle">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28.1 80.6" xmlSpace="preserve">
        <path className="" fill="none" strokeWidth="11" strokeLinecap="round" strokeMiterlimit="10" d="M22.6,75.1c-8-5.6-15.2-10.5-15.2-19.9c0-12.1,14.1-17.2,14.1-29.6c0-9.1-6.7-15.7-16-20.1" />
                            </svg>
                        </div>
                    </div>
                    <div className="squiggle-container squiggle-container-2">
                        <div className="squiggle">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28.1 80.6" xmlSpace="preserve">
        <path className="" fill="none" stroke="#fff" strokeWidth="11" strokeLinecap="round" strokeMiterlimit="10" d="M22.6,75.1c-8-5.6-15.2-10.5-15.2-19.9c0-12.1,14.1-17.2,14.1-29.6c0-9.1-6.7-15.7-16-20.1" />
                            </svg>
                        </div>
                    </div>
                    <div className="squiggle-container squiggle-container-3">
                        <div className="squiggle">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28.1 80.6" xmlSpace="preserve">
        <path className="" fill="none" stroke="#fff" strokeWidth="11" strokeLinecap="round" strokeMiterlimit="10" d="M22.6,75.1c-8-5.6-15.2-10.5-15.2-19.9c0-12.1,14.1-17.2,14.1-29.6c0-9.1-6.7-15.7-16-20.1" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="coffee-cup-container">
                    <svg className="coffee-cup" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.15 31">
                        <path className="a" d="M30.06,2V23.75c0,2.63-.87,5.13-5.12,5.13H7.06A4.86,4.86,0,0,1,2,23.81V2H30.06Z" transform="translate(0 -0.06)" />
                        <path className="b" d="M40.64,9.52a10.2,10.2,0,0,0-8.64-5V0.06H0V23.81a7,7,0,0,0,7.06,7.24H24.94c2.34,0,6.06-.81,6.93-5.18a10.6,10.6,0,0,0,8.89-5.29A11.29,11.29,0,0,0,40.64,9.52ZM28,23.75c0,2.07-.42,3.31-3.06,3.31H7.06A3,3,0,0,1,4,23.81V4.06H28V23.75Zm9.26-5.17A7.13,7.13,0,0,1,32,21.78V8.45a7,7,0,0,1,5.18,3.1A7.24,7.24,0,0,1,37.26,18.58Z" transform="translate(0 -0.06)" />
                    </svg>
                </div>
            </div>
        </div>
                     </div>
                     { /* <a
                       href="/"
                       block="Maintenance"
                       elem="Button"
                       mix={ { block: 'Button' } }
                       onClick={ onClick }
                     >
                         { __('Refresh') }
                     </a>
                     { this.renderErrorDetails() } */ }
                 </ContentWrapper>
             </main>
         );
     }
}

export default MaintenanceComponent;
