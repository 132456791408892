/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */

import {
    faShoppingCart
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    AddToCart as SourceAddToCart
} from 'SourceComponent/AddToCart/AddToCart.component';
import { MixType } from 'Type/Common.type';
import { LayoutType } from 'Type/Layout';
import { ProductType } from 'Type/ProductList';

import './AddToCart.override.style';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/derived-class-names
export class AddToCart extends SourceAddToCart {
    // TODO implement logic
    static propTypes = {
        isLoading: PropTypes.bool,
        product: ProductType,
        mix: MixType,
        added: PropTypes.bool,
        notifyAdded: PropTypes.bool,
        layout: LayoutType,
        isWithIcon: PropTypes.bool,
        disabled: PropTypes.bool,
        handleButtonClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        product: {},
        mix: {},
        isLoading: false,
        added: false,
        layout: GRID_LAYOUT,
        isWithIcon: false,
        disabled: false
    };

    renderCartIcon() {
        const { isWithIcon } = this.props;

        if (!isWithIcon) {
            return null;
        }

        return <FontAwesomeIcon icon={ faShoppingCart } />;
    }

    renderPlaceholder() {
        const { isLoading, mix } = this.props;

        return (
            <div
              block="AddToCart"
              mods={ { isLoading, isPlaceholder: true } }
              mix={ mix }
            />
        );
    }

    render() {
        const {
            mix,
            product: { type_id, configurable_product_options_selection = [], sku },
            isLoading,
            handleButtonClick,
            layout,
            added = false,
            disabled,
            enableAddToCart
        } = this.props;

        const { variant = [] } = configurable_product_options_selection;

        if (!type_id) {
            this.renderPlaceholder();
        }

        let isShowAdded = false;
        if (added) {
            isShowAdded = true;
        }

        let addToCartDis = '';
        if ((type_id === 'configurable') && sku === ('KRTRCUSTOM' || 'KRLBCUSTOM')) {
            addToCartDis = 'addToCartDis';
            if (!isEmpty(variant) && enableAddToCart) {
                addToCartDis = '';
            }
        }

        const fieldView = (isShowAdded) ? (
            <span className="item_added">
            { ' ' }
            { __('Added') }
            { ' ' }
            </span>
        ) : (
            <button
              onClick={ handleButtonClick }
              //  block="Button AddToCart"
              block={ `Button AddToCart ${ addToCartDis }` }
              mix={ mix }
              mods={ { isLoading, layout } }
              disabled={ isLoading || disabled }
            >
                { this.renderCartIcon() }
                <span>{ isLoading ? __('Adding...') : __('Add to cart') }</span>
            </button>
        );

        return fieldView;
    }

    // render() {
    //     const {
    //         mix,
    //         product: { type_id },
    //         isLoading,
    //         buttonClick,
    //         added = false,
    //         notifyAdded = true
    //     } = this.props;

    //     if (!type_id) {
    //         this.renderPlaceholder();
    //     }

    //     let isShowAdded = false;
    //     if (notifyAdded && added) {
    //         isShowAdded = true;
    //     }

    //     const fieldView = (isShowAdded) ? (<span className="item_added"> Added </span>) : (
    //                         <button
    //                           onClick={ buttonClick }
    //                           block="Button AddToCart"
    //                           mix={ mix }
    //                           mods={ { isLoading } }
    //                           disabled={ isLoading }
    //                         >
    //                                 <span>{ __('Add to cart') }</span>
    //                         </button>
    //     );

    //     return fieldView;
    // }
}

export default AddToCart;
