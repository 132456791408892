/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-undef */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import { NO_MATCH } from 'Component/Header/Header.config';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';

import NoMatch from './NoMatch.component';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace Pwa/Route/NoMatch/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) => {
        BreadcrumbsDispatcher.then(
            ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
        );
    },
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state)),
    updateNoMatch: (options) => dispatch(updateNoMatch(options))
});

/** @namespace Pwa/Route/NoMatch/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    urlRewrite: state.UrlRewritesReducer.urlRewrite
});

/** @namespace Pwa/Route/NoMatch/Container/NoMatchContainer */
export class NoMatchContainer extends PureComponent {
    static propTypes = {
        changeHeaderState: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        updateNoMatch: PropTypes.func.isRequired,
        urlRewrite: PropTypes.object.isRequired
    };

    componentDidMount() {
        this.updateHeaderState();
        this.updateMeta();
        this.updateNoMatch();
    }

    updateHeaderState() {
        const { changeHeaderState } = this.props;

        changeHeaderState({
            name: NO_MATCH,
            title: __('Page not found'),
            isHiddenOnMobile: true
        });
    }

    updateMeta() {
        const { updateMeta } = this.props;

        // Add Prerender.io 404 status code
        if (document.getElementsByName('prerender-status-code').length === 0) {
            const prerender404 = document.createElement('meta');
            prerender404.name = 'prerender-status-code';
            prerender404.content = '404';
            document.getElementsByTagName('head')[0].appendChild(prerender404);
        }

        // Add noindex meta tag
        if (document.getElementsByName('robots').length === 0) {
            const noIndex = document.createElement('meta');
            noIndex.name = 'robots';
            noIndex.content = 'noindex';
            document.getElementsByTagName('head')[0].appendChild(noIndex);
        }

        updateMeta({ title: __('Page not found'), status_code: '404' });
    }

    updateNoMatch() {
        const { updateNoMatch, updateMeta } = this.props;

        updateNoMatch(true);
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ cleanUpTransition }) => (
                    <NoMatch
                      { ...{ ...this.props, cleanUpTransition } }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoMatchContainer);
