/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { Component } from 'react';
import { connect } from 'react-redux';

import Faq from './Faq.component';

/** @namespace Pwa/Component/Faq/Container/FaqContainer */
export class FaqContainer extends Component {
    render() {
        return <Faq { ...this.props } />;
    }
}

/** @namespace Pwa/Component/Faq/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
});

/** @namespace Pwa/Component/Faq/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(FaqContainer);
