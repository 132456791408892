/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import { Component } from 'react';

import Field from 'Component/Field';

/** @namespace Pwa/Component/NotifyMe/Component/NotifyMe */
export class NotifyMe extends Component {
    render() {
        const {
            updateEmail, onClick, isSignedIn, isLoading
        } = this.props;

        const buttonText = isLoading ? __('Wait') : __('Go');
        const onButtonClick = isLoading ? () => {} : onClick;

        return (
      <div className="notify_me">
        { !isSignedIn
          && (
<Field
  type="email"
  placeholder={ __('Email') }
  id="email"
  name="email"
  onChange={ updateEmail }
/>
          ) }
        <button onClick={ onButtonClick } className="Button">
          { buttonText }
        </button>
      </div>
        );
    }
}

export default NotifyMe;
