/* eslint-disable no-console */
/* eslint-disable max-len */
export const UPDATE_COMPANY_CREDIT_DATA = 'UPDATE_COMPANY_CREDIT_DATA';
export const SET_COMPANY_CREDIT_LOADING = 'SET_COMPANY_CREDIT_LOADING';
export const SAVE_COMPANY_CREDIT_DATA = 'SAVE_COMPANY_CREDIT_DATA';

/** @namespace Pwa/Store/CompanyCredit/Action/updateCompanyCreditData */
export const updateCompanyCreditData = ({ companyCredit = {} }, isLoading = false) => ({
    type: UPDATE_COMPANY_CREDIT_DATA,
    companyCredit,
    isLoading
});

/** @namespace Pwa/Store/CompanyCredit/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_COMPANY_CREDIT_LOADING,
    isLoading
});

/** @namespace Pwa/Store/CompanyCredit/Action/saveCompanyCreditData */
export const saveCompanyCreditData = (companyCredit, isLoading) => ({
    type: SAVE_COMPANY_CREDIT_DATA,
    companyCredit,
    isLoading
});
