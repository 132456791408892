/* eslint-disable no-magic-numbers */
/* eslint-disable no-param-reassign */
/* eslint-disable no-await-in-loop */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    App as SourceApp
} from 'SourceComponent/App/App.component';

/** @namespace Pwa/Component/ProductReviews/Component/ProductReviews */
export class App extends SourceApp {
    componentDidMount() {
        const timeoutNumber = 1000;
        const loopLimit = 10;
        async function checkPageLoad(limit, current = 1) {
            console.log(document.readyState);

            while (current < limit) {
                await new Promise((resolve) => setTimeout(resolve, timeoutNumber));

                if (document.readyState === 'complete') {
                    // Set timeout to 8 seconds before setting window.prerenderReady = true
                    // Some pages don't fully render so allowing it more time to render before setting prerenderReady
                    setTimeout(() => {
                        window.prerenderReady = true;
                    }, 8000);

                    return true;
                }

                current++;
            }

            return false;
        }

        (async () => {
            const result = await checkPageLoad(loopLimit);
            console.log('Page load status:', result);
        })();
    }
}

export default App;
