/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { Component } from 'react';
import { connect } from 'react-redux';

import { CustomerGarageDispatcher } from 'Store/CustomerGarage';

import CustomerGarageHeader from './CustomerGarageHeader.component';

/** @namespace Pwa/Component/CustomerGarageHeader/Container/CustomerGarageHeaderContainer */
export class CustomerGarageHeaderContainer extends Component {
    componentDidMount() {
        this.props.fetchList();
    }

    render() {
        return <CustomerGarageHeader { ...this.props } />;
    }
}

/** @namespace Pwa/Component/CustomerGarageHeader/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    data: state.CustomerGarageReducer.data,
    isLoading: state.CustomerGarageReducer.isLoading
});

/** @namespace Pwa/Component/CustomerGarageHeader/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    fetchList: () => CustomerGarageDispatcher.handleData(dispatch),
    removeItem: (options) => CustomerGarageDispatcher.removeItem(options, dispatch),
    onSaveClick: (options) => CustomerGarageDispatcher.addItem(options, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerGarageHeaderContainer);
