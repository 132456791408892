/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';

import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchMutation } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import GdprCookieQuery from '../../query/GdprCookie.query';
import GdprCookiePopup from './GdprCookiePopup.component';
import { GDPR_COOKIES_ACCEPTED } from './GdprCookiePopup.config';

export const cookies = new Cookies();
/** @namespace Pwa/Component/GdprCookiePopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    isCookieBarActive: state?.ConfigReducer?.gdpr_cookie_bar
});

/** @namespace Pwa/Component/GdprCookiePopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Pwa/Component/GdprCookiePopup/Container/GdprCookiePopupContainer */
export class GdprCookiePopupContainer extends DataContainer {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
        isCookieBarActive: PropTypes.bool.isRequired
    };

    state = {
        isLoading: true,
        isStatic: true,
        checkedGroups: [],
        isCookieSet: !!this.handleGetCookie('gdpr_allowed'),
        cookieSettings: null
    };

    containerFunctions = {
        handleCheckedGroups: this.handleCheckedGroups.bind(this),
        handleAcceptAllGroups: this.handleAcceptAllGroups.bind(this),
        handleDeclineCookies: this.handleDeclineCookies.bind(this),
        handleAcceptCookies: this.handleAcceptCookies.bind(this),
        handleClosePopup: this.handleClosePopup.bind(this)
    };

    componentDidMount() {
        const {
            isCookieSet
        } = this.state;

        if (!isCookieSet) {
            this._getCookieSettings();
        }
    }

    componentDidUpdate() {
        const { isCookieBarActive } = this.props;
        const {
            isLoading,
            isCookieSet
        } = this.state;

        if (isLoading && isCookieBarActive && !isCookieSet) {
            this._getCookieGroups();
            document.documentElement.classList.add('scrollDisabled');
        }
    }

    _hasConsent(consentType) {
        const { checkedGroups } = this.state;

        if (consentType === 'google_analytics') {
            const result = checkedGroups.includes('3');
            return result;
        }
        if (consentType === 'marketing') {
            const result = checkedGroups.includes('2');
            return result;
        }

        return false;
    }

    handleCheckedGroups(groupId, isChecked) {
        const { checkedGroups } = this.state;

        if (isChecked) {
            this.setState({ checkedGroups: [...checkedGroups, groupId] });
            this.setState({ group_Id: groupId });
        } else {
            this.setState({ checkedGroups: checkedGroups.filter((checkedGroupId) => checkedGroupId !== groupId) });
        }

        // if (isChecked && groupId === '2') {
        //     this.setState({ marketing: groupId });
        // } if (isChecked && groupId === '3') {
        //     this.setState({ google_analytics: groupId });
        // } else if (!isChecked) {
        //     this.setState({ google_analytics: '', marketing: '' });
        // }
    }

    async handleAcceptCookies() {
        const { hideActiveOverlay, showNotification } = this.props;
        const { checkedGroups } = this.state;

        const request = await fetchMutation(GdprCookieQuery.setSelectedCookies(checkedGroups));

        const { setSelectedCookies: { message, result } } = request;
        // const { marketing, google_analytics } = this.state;
        if (result) {
            window.dataLayer.push({
                event: 'consent',
                consent_analytics: !!this._hasConsent('google_analytics'),
                consent_marketing: !!this._hasConsent('marketing')
            });
            // Setting the consent cookie in JSON format
            const consentData = {
                consent_analytics: !!this._hasConsent('google_analytics'),
                consent_marketing: !!this._hasConsent('marketing')
            };

            const now = new Date();
            now.setFullYear(now.getFullYear() + 1);

            cookies.set('consent', JSON.stringify(consentData), { expires: now });

            showNotification('success', message);
            this.setState({ isStatic: false });
            hideActiveOverlay();
            BrowserDatabase.setItem(true, GDPR_COOKIES_ACCEPTED, 2160000);
            // cookies.set('amcookie_allowed', '1');
            // if (marketing) {
            //     cookies.set('amcookie_marketing', '1');
            // }
            // if (google_analytics) {
            //     cookies.set('amcookie_google_analytics', '1');
            // }
        }
    }

    async handleAcceptAllGroups() {
        const { hideActiveOverlay, showNotification } = this.props;
        const checkedGroups = ['1', '2', '3'];

        const request = await fetchMutation(GdprCookieQuery.setSelectedCookies(checkedGroups));

        const { setSelectedCookies: { message, result } } = request;
        if (result) {
            // Configure GA4 and other analytics based on consent
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'consent',
                consent_analytics: true,
                consent_marketing: true
            });
            const consentData = {
                consent_analytics: true,
                consent_marketing: true
            };
            const now = new Date();
            now.setFullYear(now.getFullYear() + 1);

            cookies.set('consent', JSON.stringify(consentData), { expires: now });

            showNotification('success', message);
            this.setState({ isStatic: false });
            hideActiveOverlay();
            BrowserDatabase.setItem(true, GDPR_COOKIES_ACCEPTED, 2160000);
        }
    }

    async handleAcceptAllGroups1() {
        const { hideActiveOverlay, showNotification } = this.props;

        const request = await fetchMutation(GdprCookieQuery.setAllCookies());

        const { setAllCookies: { message, result } } = request;

        if (result) {
            showNotification('success', message);
            this.setState({ isStatic: false });
            hideActiveOverlay();
            // cookies.set('amcookie_allowed', '1');
            // cookies.set('amcookie_marketing', '1');
            // cookies.set('amcookie_google_analytics', '1');
            BrowserDatabase.setItem(true, GDPR_COOKIES_ACCEPTED, 2160000);
        }
    }

    async handleDeclineCookies() {
        const { hideActiveOverlay } = this.props;

        await fetchMutation(GdprCookieQuery.setSelectedCookies([]));

        this.setState({ isStatic: false });
        hideActiveOverlay();
    }

    handleClosePopup() {
        const { hideActiveOverlay } = this.props;

        this.setState({ isStatic: false });
        hideActiveOverlay();
    }

    handleGetCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }

        return '';
    }

    _getCookieGroups() {
        this.fetchData(
            [GdprCookieQuery.getCookieGroups()],
            ({ cookieGroups }) => {
                this.setState({ isLoading: false });
                this.setState({ cookieGroups });
            }
        );
    }

    _getCookieSettings() {
        this.fetchData(
            [GdprCookieQuery.getCookieSettings()],
            ({ gdprCookieSettings }) => {
                this.setState({ cookieSettings: gdprCookieSettings });
            }
        );
    }

    render() {
        const {
            cookieGroups, isCookieSet, isLoading, isStatic, cookieSettings
        } = this.state;
        // eslint-disable-next-line no-unused-vars
        const isAccepted = BrowserDatabase.getItem(GDPR_COOKIES_ACCEPTED);
        const isAcceptedCookie = cookies.get('consent', '');

        if (isAcceptedCookie) {
            return null;
        }

        const { isCookieBarActive } = this.props;
        return (
            <GdprCookiePopup
              cookieGroups={ cookieGroups }
              isCookieSet={ isCookieSet }
              isLoading={ isLoading }
              isStatic={ isStatic }
              isCookieBarActive={ isCookieBarActive }
              cookieSettings={ cookieSettings }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GdprCookiePopupContainer);
