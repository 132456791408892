/* eslint-disable no-console */
export const FETCH_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_LIST = 'FETCH_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_LIST';
export const REMOVE_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_ITEM = 'REMOVE_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_ITEM';
export const SET_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_LOADING = 'SET_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_LOADING';

/** @namespace Pwa/Store/CustSubscriptions/Action/updateSubscriptionList */
export const updateSubscriptionList = ({ data }, isLoading) => ({
    type: FETCH_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_LIST,
    data,
    isLoading
});

/** @namespace Pwa/Store/CustSubscriptions/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_LOADING,
    isLoading
});

/** @namespace Pwa/Store/CustSubscriptions/Action/removeItemFromBackInSubscriptionsList */
export const removeItemFromBackInSubscriptionsList = (alert_id, isLoading) => ({
    type: REMOVE_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_ITEM,
    data: alert_id,
    isLoading
});
