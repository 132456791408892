/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * CMS Page Query
 * @class GearzettePageQuery
 * @namespace Pwa/Query/GearzettePage/Query/GearzettePageQuery
 */
export class GearzettePageQuery {
    /**
     * get CMS Page query
     * @param  {{url_key: String, title: Int, content: String, content_heading: String, page_layout: String, meta_title: String, meta_description: String, meta_keywords, string}} options A object containing different aspects of query, each item can be omitted
     * @return {Query} CMS Page query
     * @memberof GearzettePageQuery
     */
    getQuery({
        // eslint-disable-next-line no-magic-numbers
        slug, store_id, perPage = 15, page = 1, orderBy = 'published_at', descending = true
    }) {
        if (!slug || store_id === undefined) {
            console.log('slug is undefined');
        }
        const graphql = `query={
            posts(storeId:${store_id}, perPage:${perPage}, page:${page}, orderBy:"${orderBy}", descending:${descending}) {
                ${ this._getPostDataField()}
            }
        }`;

        return graphql;
    }

    getRecentsQuery({
        // eslint-disable-next-line no-magic-numbers
        store_id, perPage = 15, page = 1, orderBy = 'published_at', descending = true
    }) {
        if (store_id) {
            sessionStorage.setItem('storeId', store_id);
        }
        const strId = sessionStorage.getItem('storeId');
        const storeId = store_id ?? strId;

        const graphql = `query={
            posts(storeId:${storeId},  perPage:${perPage}, page:${page}, orderBy:"${orderBy}", descending:${descending}) {
                ${ this._getPostDataField()}
            }
        }`;

        return graphql;
    }

    _getPostDataField() {
        const graphql = `
                        ${ this._getPostPaginationField()}
                        ${ this._getPostDetailsField()}
                        `;

        return graphql;
    }

    _getPostPaginationField() {
        const graphql = `
            last_page
            per_page
            current_page
        `;

        return graphql;
    }

    _getPostDetailsField() {
        const graphql = `data {
            id
            title
            short_body
            body
            meta_tag_title
            meta_tag_description
            meta_tag_keywords
            date_published
            date_created
            updated_at_iso8601
            created_at_iso8601
            published_at_iso8601
            featured_image_mobile
            featured_image_desktop
            featured_image_desktop_url
            featured_image_mobile_url
            images
            ${ this._getPostLinkField()}
          }`;

        return graphql;
    }

    _getPostDetailField() {
        const graphql = `id
                        title
                        short_body
                        body
                        meta_tag_title
                        meta_tag_description
                        meta_tag_keywords
                        date_published
                        date_created
                        updated_at_iso8601
                        created_at_iso8601
                        published_at_iso8601
                        featured_image_mobile
                        featured_image_desktop
                        featured_image_desktop_url
                        featured_image_mobile_url
                        images`;

        return graphql;
    }

    _getPostLinkField() {
        const graphql = `post_links {
            category_id
            post_link
        }`;

        return graphql;
    }

    getCategoryPostsQuery({ id, store_id }) {
        if (id === undefined) {
            console.log('post id is undefined');
        }
        const graphql = `query={
            posts(categoryId:${id}, store_id:${store_id}) {
                ${ this._getPostDataField()}
            }
        }`;

        return graphql;
    }

    getSearchPostsQuery({ searchData, store_id }) {
        if (searchData === undefined) {
            console.log(' searchData is undefined');
        }
        const graphql = `query={
            posts(search:"${searchData}",storeId:${store_id}) {
                ${ this._getPostDataField()}
            }
        }`;

        return graphql;
    }

    getQueryPostDetails({ id, store_id }) {
        if (id === undefined) {
            console.log('post id is undefined');
        }
        const graphql = `query={
            post(id:${id}, storeId:${store_id}) {
                ${ this._getPostDetailField()}
            }
        }`;

        return graphql;
    }

    getCategoriesQuery({ store_id }) {
        const graphql = `query={
            categories(storeId:${store_id}) {
                id
                name
                slug
                ${ this._getChildrenField()}
            }
        }`;

        return graphql;
    }

    _getChildrenField() {
        const graphql = `children {
            id
            name
            slug
        }`;

        return graphql;
    }

    getQueryPostDetailResolver({ slug, store_id }) {
        if (slug === undefined) {
            console.log('post slug is undefined');
        }
        const updatedSlug = slug.replace('/gearzette/', '');

        const graphql = `query={
            post(, storeId:${store_id}, slug:"${updatedSlug}") {
                ${ this._getPostDetailField()}
            }
        }`;

        return graphql;
    }
}

export default new GearzettePageQuery();
