/* eslint-disable fp/no-let */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-console */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { get } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import Overlay from 'Component/Overlay';
import ProductPrice from 'Component/ProductPrice';
import SearchItem from 'Component/SearchItem';
import TextPlaceholder from 'Component/TextPlaceholder';
import TierPrices from 'Component/TierPrices';
import { ItemsType } from 'Type/ProductList';
import { isEurStore } from 'Util/FrontRunner/Store';
import media, { PRODUCT_MEDIA } from 'Util/Media';
import { CONFIGURABLE } from 'Util/Product';

import './SearchOverlay.style';

export const SEARCH_TIMEOUT = 800;
export const AMOUNT_OF_PLACEHOLDERS = 10;

/** @namespace Pwa/Component/SearchOverlay/Component/SearchOverlayComponent */
export class SearchOverlay extends PureComponent {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        searchCriteria: PropTypes.string,
        searchResults: ItemsType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        getProductLinkTo: PropTypes.func.isRequired,
        makeSearchRequest: PropTypes.func.isRequired,
        clearSearchResults: PropTypes.func.isRequired
    };

    static defaultProps = {
        searchCriteria: ''
    };

    componentDidUpdate(prevProps) {
        const { searchCriteria: prevSearchCriteria } = prevProps;
        const { searchCriteria, clearSearchResults, makeSearchRequest } = this.props;
        if (searchCriteria !== prevSearchCriteria && searchCriteria && searchCriteria.length > 1) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            clearSearchResults();
            this.timeout = setTimeout(() => {
                this.timeout = null;
                makeSearchRequest();
            }, SEARCH_TIMEOUT);
        }
    }

    renderSKU(product) {
        const {
            variants,
            type_id,
            attributes: { brand = null } = {}
        } = product;

        // Brand
        let brandText = '';
        if (brand) {
            const brandId = brand?.attribute_value ?? null;
            if (brandId) {
                brandText = brand?.attribute_options[brandId]?.label ?? '';
            }
        }

        if (type_id === 'configurable') {
            const { sku } = product;
            return (
                <section
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: 'product_code' } }
                  aria-label="Product Code"
                >
                  <span block="ProductActions" elem="ProductCode" itemProp="product_code">
                    { `${__('SKU')}: ${ sku }` }
                  </span>
                </section>
            );
        }

        // This code is not tested with configurable product [YKS]
        const productOrVariant = variants && variants[0] !== undefined
            ? variants[0]
            : product;

        const { sku } = productOrVariant;

        if (!sku) {
            return <TextPlaceholder />;
        }

        return (
        <section
          block="ProductActions"
          elem="Section"
          mods={ { type: 'product_code' } }
          aria-label="Product Code"
        >
            <span block="ProductActions" elem="ProductCode" itemProp="product_brand">
                { brandText }
            </span>
            <span className="separator" />
            <span block="ProductActions" elem="ProductCode" itemProp="product_code">
                { `${__('SKU')}: ${ sku }` }
            </span>
        </section>
        );
    }

    renderTaxDescription(tax_description) {
        if (!isEurStore()) {
            return null;
        }

        return (
        <div>
          { tax_description }
        </div>
        );
    }

    renderProductPrice(product) {
        // We are not sending product or variant as of now, this might lead to a bug. [YKS]
        // const { productOrVariant: { price, tax_description }, product } = this.props;
        const {
            price_range, type_id, tax_description, bundle_options
        } = product;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
            { /* { this.renderTierPrice() }
            { this.renderConfigurablePriceBadge() } */ }
            <ProductPrice
              final_price={ bundle_options }
              price={ price_range }
              mix={ { block: 'ProductCard', elem: 'Price' } }
              tax_description={ tax_description }
            />
            </div>
        );
    }

    renderConfigurablePriceBadge() {
        const {
            product: { type_id },
            siblingsHavePriceBadge,
            setSiblingsHavePriceBadge
        } = this.props;

        if (type_id !== CONFIGURABLE) {
            return null;
        }

        if (!siblingsHavePriceBadge) {
            setSiblingsHavePriceBadge();
        }

        return (
            <p
              mix={ {
                  block: 'ProductCard',
                  elem: 'PriceBadge'
              } }
            >
                { __('Price From:') }
            </p>
        );
    }

    renderTierPrice() {
        const {
            product: { variants },
            product,
            siblingsHavePriceBadge,
            setSiblingsHavePriceBadge
        } = this.props;

        // This code is not tested with configurable product [YKS]
        const productOrVariant = variants && variants[0] !== undefined
            ? variants[0]
            : product;

        const { price_tiers } = productOrVariant;

        if (!price_tiers || !price_tiers.length) {
            return null;
        }

        return (
            <TierPrices
              product={ productOrVariant }
              isLowestPrice
            />
        );
    }

    renderSearchItemContent(name, brand) {
        return (
            <p block="SearchOverlay" elem="Title" mods={ { isLoaded: !!name } }>
                <TextPlaceholder content={ name } length="long" />
            </p>
        );
    }

    renderSearchItem(product, i) {
        const { hideActiveOverlay, getProductLinkTo } = this.props;
        const {
            name,
            thumbnail: { path } = {},
            attributes: { brand: { attribute_value: brand } = {} } = {},
            small_image = {},
            tax_description = ''
        } = product;

        const imageSrc = get(small_image, 'url', undefined);
        if (name === null || name === undefined) {
            return null;
        }

        // const imageSrc = path ? media(path, PRODUCT_MEDIA) : undefined;
        return (
            <li
              block="SearchOverlay"
              elem="Item"
              key={ i }
            >
                <Link
                  block="SearchOverlay"
                  elem="Link"
                  to={ getProductLinkTo(product) }
                  onClick={ hideActiveOverlay }
                >
                    <figure
                      block="SearchOverlay"
                      elem="Wrapper"
                    >
                        <Image
                          block="SearchOverlay"
                          elem="Image"
                          src={ imageSrc }
                          alt={ __('Product %s thumbnail.', name) }
                          isPlaceholder={ !imageSrc }
                        />
                        <figcaption block="SearchOverlay" elem="Content">
                            { this.renderSearchItemContent(name, brand) }
                            { this.renderSKU(product) }
                            { this.renderProductPrice(product) }
                            { this.renderTaxDescription(tax_description) }

                        </figcaption>
                    </figure>
                </Link>
            </li>
        );
    }

    renderSearchCriteria() {
        const { searchCriteria } = this.props;

        return (
            <p
              block="SearchOverlay"
              elem="Criteria"
              mods={ { isVisible: !!searchCriteria } }
            >
                { __('Results for:') }
                <strong>{ searchCriteria }</strong>
            </p>
        );
    }

    renderNoSearchCriteria() {
        return <p>{ __('Start typing to see search results!') }</p>;
    }

    renderNoResults() {
        return <p>{ __('No results found!') }</p>;
    }

    renderSearchItems(product, i) {
        return (
            <SearchItem
              product={ product }
              key={ i }
            />
        );
    }

    renderSearchResults() {
        const { searchCriteria, searchResults, isLoading } = this.props;

        if (!searchCriteria) {
            return this.renderNoSearchCriteria();
        }
        if (!searchResults.length && !isLoading && !this.timeout) {
            return this.renderNoResults();
        }
        const resultsToRender = (isLoading || this.timeout) ? Array(AMOUNT_OF_PLACEHOLDERS).fill({}) : searchResults;

        return (
            <ul>
                { resultsToRender.map((item, i) => this.renderSearchItem(item, i)) }
            </ul>
        );
    }

    render() {
        return (
            <Overlay
              id="search"
              mix={ { block: 'SearchOverlay' } }
            >
                { this.renderSearchCriteria() }
                <article
                  block="SearchOverlay"
                  elem="Results"
                  aria-label="Search results"
                >
                    { this.renderSearchResults() }
                </article>
            </Overlay>
        );
    }
}

export default SearchOverlay;
