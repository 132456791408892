/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import currencyMap from './Price.config';

export * from 'SourceUtil/Price/Price';

/** @namespace Pwa/Util/Price/formatCurrency */
export const formatCurrency = (currency = 'USD') => currencyMap[currency];

/** @namespace Pwa/Util/Price/formatPrice */
export const formatPrice = (price, currentCurrency) => {
    const currency = currentCurrency || 'USD';
    // const language = navigator.languages ? navigator.languages[0] : navigator.language;
    const language = window.defaultLocale ? window.defaultLocale : navigator.language;
    const fPrice = new Intl.NumberFormat(language.replace('_', '-'), { style: 'currency', currency }).format(price);
    // const fPrice = new Intl.NumberFormat(language?.replace('_', '-')).format(price);
    const currencyMap = formatCurrency(currency);
    const formatedPrice = fPrice?.replace(currency, currencyMap);
    return formatedPrice;
};

/** @namespace Pwa/Util/Price/removeCurrencySymbol */
export const removeCurrencySymbol = (price) => {
    // If price is a float value, then return it as it is
    if (typeof price === 'number') {
        return price;
    }

    // If price is undefined, then return as is
    if (price === undefined) {
        return price;
    }

    // If type is a string, then remove the currency symbol
    if (typeof price === 'string') {
        const priceWithoutSymbol = price.replace(/£|\$|€|R|US|A\$|US\$/g, '');
        return priceWithoutSymbol.replace(/^\s+|\s+$/gm, '');
    }

    return price;
};

/** @namespace Pwa/Util/Price/removeCurrencynCharacter */
export const removeCurrencynCharacter = (price) => {
    if (price !== undefined) {
        const priceWithoutSymbol = price.replace(/£|\$|€|R|US|,|A\$|US\$/g, '');
        return priceWithoutSymbol.replace(/^\s+|\s+$/gm, '');
    }

    return price;
};

/** @namespace Pwa/Util/Price/addCommaCurrency */
export const addCommaCurrency = (number) => {
    if (isNaN(number)) {
        return 'Invalid input';
    }

    const parts = number.toString().split('.'); parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};

/** @namespace Pwa/Util/Price/addDotCurrency */
export const addDotCurrency = (number) => {
    if (isNaN(number)) {
        return 'Invalid input';
    }

    const parts = number.toString().split('.'); parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
};
