import {
    CategoryReducer as SourceCategoryReducer,
    getInitialState as SourceGetInitialState
} from 'SourceStore/Category/Category.reducer';

// TODO: implement getInitialState
export const getInitialState = SourceGetInitialState;

// TODO: implement CategoryReducer
export const CategoryReducer = SourceCategoryReducer;

export default CategoryReducer;
