/* eslint-disable no-dupe-class-members */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/sort-comp */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { PDP } from 'Component/Header/Header.config';
import { MENU_TAB } from 'Component/NavigationTabs/NavigationTabs.config';
import { IN_STOCK, OUT_OF_STOCK } from 'Component/ProductCard/ProductCard.config';
import { LOADING_TIME } from 'Route/CategoryPage/CategoryPage.config';
import MaintenanceDispatcher from 'Store/Maintenance/Maintenance.dispatcher';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { setBigOfflineNotice } from 'Store/Offline/Offline.action';
import ProductDispatcher from 'Store/Product/Product.dispatcher';
import ProductReducer from 'Store/Product/Product.reducer';
import { addRecentlyViewedProduct } from 'Store/RecentlyViewedProducts/RecentlyViewedProducts.action';
import { HistoryType, LocationType, MatchType } from 'Type/Common.type';
import { ProductType } from 'Type/ProductList';
import { withReducers } from 'Util/DynamicReducer';
import { processAffiliateCode } from 'Util/FrontRunner/Affiliates/AffiliateCode';
import { updatePageInCookies } from 'Util/Helper';
import { getIsConfigurableParameterSelected, getNewParameters, getVariantIndex } from 'Util/Product';
import { debounce } from 'Util/Request';
import {
    convertQueryStringToKeyValuePairs,
    objectToUri,
    removeQueryParamWithoutHistory,
    updateQueryParamWithoutHistory
} from 'Util/Url';

import ProductPage from './ProductPage.component';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

export const MetaDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Meta/Meta.dispatcher'
);

export const ProductDispatcher1 = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Product/Product.dispatcher'
);

/** @namespace Pwa/Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isOffline: state.OfflineReducer.isOffline,
    product: state.ProductReducer.product,
    navigation: state.NavigationReducer[TOP_NAVIGATION_TYPE],
    metaTitle: state.MetaReducer.title,
    device: state.ConfigReducer.device,
    store: state.ConfigReducer.code,
    customer: state.MyAccountReducer.customer,
    isLoading: state.ProductReducer.isLoading,
    category: state.CategoryReducer.category
});

/** @namespace Pwa/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state)),
    changeNavigationState: (state) => dispatch(changeNavigationState(BOTTOM_NAVIGATION_TYPE, state)),
    requestProduct: (options) => {
        // TODO: check linked products, there might be issues :'(
        ProductDispatcher1.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
        );
    },
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    updateBreadcrumbs: (breadcrumbs, prevCategoryId) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateWithProduct(breadcrumbs, prevCategoryId, dispatch)
    ),
    updateMetaFromProduct: (product) => MetaDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateWithProduct(product, dispatch)
    ),
    goToPreviousNavigationState: (state) => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE, state)),
    addRecentlyViewedProduct: (product, store) => dispatch(addRecentlyViewedProduct(product, store)),
    checkMaintenance: () => MaintenanceDispatcher.handleData(dispatch),
    // fetchData: () => ProductDispatcher.handleData(dispatch),
    fetchValue: (options, id, dropDownCount, additionalInformation, oggraphData) => ProductDispatcher.fetchConfigurableproduts(options, id, dropDownCount, dispatch, additionalInformation, oggraphData)
});

/** @namespace Pwa/Route/ProductPage/Container/ProductPageContainer */
export class ProductPageContainer extends PureComponent {
    myContainerFunctions = {
        ...this.containerFunctions,
        updateGiftCardFormData: this.updateGiftCardFormData.bind(this),
        updateGiftCardPrice: this.updateGiftCardPrice.bind(this),
        updateGiftCardDesign: this.updateGiftCardDesign.bind(this),
        videoTab: this.videoTab.bind(this),
        isEnabledVideoTab: this.isEnabledVideoTab.bind(this)
    };

    state = {
        configurableVariantIndex: -1,
        parameters: {},
        productOptionsData: {},
        selectedInitialBundlePrice: 0,
        selectedBundlePrice: 0,
        selectedBundlePriceExclTax: 0,
        selectedLinkPrice: 0,
        currentProductSKU: '',
        optionsSelected: [],
        selectedInxData: 0,
        selectedInxDataVal: 0
        // isChipBotVideoRendered: false
    };

    containerFunctions = {
        updateConfigurableVariant: this.updateConfigurableVariant.bind(this),
        getLink: this.getLink.bind(this),
        getSelectedCustomizableOptions: this.getSelectedCustomizableOptions.bind(this),
        setBundlePrice: this.setBundlePrice.bind(this),
        setLinkedDownloadables: this.setLinkedDownloadables.bind(this),
        setLinkedDownloadablesPrice: this.setLinkedDownloadablesPrice.bind(this),
        isProductInformationTabEmpty: this.isProductInformationTabEmpty.bind(this),
        isProductAttributesTabEmpty: this.isProductAttributesTabEmpty.bind(this),
        setActiveProduct: this.setActiveProduct.bind(this),
        updateGiftCardFormData: this.updateGiftCardFormData.bind(this),
        updateGiftCardPrice: this.updateGiftCardPrice.bind(this),
        updateGiftCardDesign: this.updateGiftCardDesign.bind(this),
        videoTab: this.videoTab.bind(this),
        isEnabledVideoTab: this.isEnabledVideoTab.bind(this)
    };

    static propTypes = {
        location: LocationType,
        changeHeaderState: PropTypes.func.isRequired,
        setBigOfflineNotice: PropTypes.func.isRequired,
        changeNavigationState: PropTypes.func.isRequired,
        updateMetaFromProduct: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        requestProduct: PropTypes.func.isRequired,
        isOffline: PropTypes.bool.isRequired,
        productSKU: PropTypes.string,
        product: ProductType.isRequired,
        history: HistoryType.isRequired,
        match: MatchType.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        navigation: PropTypes.shape(PropTypes.shape).isRequired,
        metaTitle: PropTypes.string,
        addRecentlyViewedProduct: PropTypes.func.isRequired,
        store: PropTypes.string.isRequired
    };

    static defaultProps = {
        location: { state: {} },
        productSKU: '',
        metaTitle: undefined
    };

    setActiveProduct(product) {
        this.setState({ activeProduct: product });
    }

    updateGiftCardFormData(your_name, recipient_name, recipient_email, gift_message) {
        const { giftCardData = {} } = this.state;
        this.setState({
            giftCardData: {
                ...giftCardData, your_name, recipient_name, recipient_email, gift_message
            }
        });
    }

    updateGiftCardPrice(giftPrice) {
        const { giftCardData = {} } = this.state;
        this.setState({ giftCardData: { ...giftCardData, giftPrice } });
    }

    updateGiftCardDesign(giftImage) {
        const { giftCardData = {} } = this.state;
        this.setState({ giftCardData: { ...giftCardData, giftImage } });
    }

    videoTab() {
        this.setState({
            selectedInxData: 3,
            selectedInxDataVal: 3
        });
        setTimeout(() => {
            this.setState({ selectedInxData: 0 });
        }, 500);
    }

    isEnabledVideoTab() {
        this.setState({
            selectedInxDataVal: 0
        });
    }

    triggerFunction() {

    }

    static getDerivedStateFromProps(props, state) {
        const {
            product: {
                sku,
                variants,
                configurable_options,
                options,
                productOptionsData
            },
            location: { search }
        } = props;

        const {
            currentProductSKU: prevSKU,
            productOptionsData: prevOptionData
        } = state;

        const currentProductSKU = prevSKU === sku ? '' : prevSKU;

        /**
         * If the product we expect to load is loaded -
         * reset expected SKU
         */
        if (!configurable_options && !variants) {
            return {
                currentProductSKU
            };
        }

        const parameters = Object.entries(convertQueryStringToKeyValuePairs(search))
            .reduce((acc, [key, value]) => {
                if (key in configurable_options) {
                    return { ...acc, [key]: value };
                }

                return acc;
            }, {});

        if (Object.keys(parameters).length !== Object.keys(configurable_options).length) {
            return {
                parameters,
                currentProductSKU
            };
        }

        const configurableVariantIndex = getVariantIndex(variants, parameters, true);

        const newOptionsData = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        const prevRequiredOptions = productOptionsData?.requiredOptions || [];
        const requiredOptions = [...prevRequiredOptions, ...newOptionsData];

        return {
            parameters,
            currentProductSKU,
            configurableVariantIndex,
            productOptionsData: {
                ...prevOptionData, ...productOptionsData, requiredOptions
            }
        };
    }

    componentDidMount() {
        /**
         * Always make sure the navigation switches into the MENU tab
         * */
        // Process if any affiliate codes detected in the URL
        processAffiliateCode();
        updatePageInCookies('ProductPage');
        this.updateNavigationState();

        /**
         * Ensure transition PDP => homepage => PDP always having proper meta
         */
        this.updateMeta();

        /**
         * Make sure to update header state, the data-source will
         * define the correct information to use for update
         * (it can be a product, history state product or an empty object).
         */
        this.updateHeaderState();
        this.updateBreadcrumbs();

        /**
         * Scroll page top in order to display it from the start
         */
        // this.scrollTop();
        this.props.checkMaintenance();
        const baseUrl = window.location.pathname;

        if (window.$zopim) {
            $zopim.livechat.button.setOffsetVerticalMobile(67);
        }
    }

    // componentWillUnmount() {
    //     window.asyncChipBotApi = window.asyncChipBotApi || [];
    //     window.asyncChipBotApi.push(['hideVideoIcon']);
    // }

    componentDidUpdate(prevProps) {
        const {
            isOffline,
            productSKU,
            product: {
                sku,
                options,
                items
            }
        } = this.props;

        const {
            productSKU: prevProductSKU,
            product: {
                sku: prevSku,
                options: prevOptions,
                items: prevItems
            }
        } = prevProps;

        const { sku: stateSKU } = history?.state?.state?.product || {};

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        /**
         * We should also update product based data if, the URL
         * rewrite SKU has changed to matching the product history SKU
         * one. At this point there could be sufficient data for
         * some updates (i.e. header state).
         */
        if (
            productSKU !== prevProductSKU
            && stateSKU === productSKU
        ) {
            this.updateHeaderState();
        }

        /**
         * If the currently loaded category ID does not match the ID of
         * category ID from URL rewrite, request category.
         */
        if (productSKU !== sku) {
            this.requestProduct();
        }

        /**
         * If product ID was changed => it is loaded => we need to
         * update product specific information, i.e. breadcrumbs.
         */
        if (sku !== prevSku) {
            this.updateBreadcrumbs();
            this.updateHeaderState();
            this.updateMeta();
            // this.renderChipBotVideo();
        }

        /**
         * LEGACY: needed to make sure required items are
         * selected in the bundle product.
         */
        if (JSON.stringify(options) !== JSON.stringify(prevOptions)) {
            this.getRequiredProductOptions(options);
        }

        /**
         * LEGACY needed to make sure required options are
         * selected in the customizable options product.
         */
        if (JSON.stringify(items) !== JSON.stringify(prevItems)) {
            this.getRequiredProductOptions(items);
        }

        this._addToRecentlyViewedProducts();
    }

    isProductInformationTabEmpty() {
        const dataSource = this.getDataSource();

        return dataSource?.description?.html?.length === 0;
    }

    isProductAttributesTabEmpty() {
        const dataSource = this.getDataSource();

        return Object.keys(dataSource?.attributes || {}).length === 0;
    }

    _addToRecentlyViewedProducts() {
        const {
            product,
            product: { sku },
            addRecentlyViewedProduct,
            store
        } = this.props;

        // necessary for skipping not loaded products
        if (!sku) {
            return;
        }

        // push into localstorage only preview of product (image, name and etc)
        const {
            canonical_url,
            categories,
            configurable_options,
            description,
            items,
            meta_description,
            meta_keyword,
            meta_title,
            options,
            product_links,
            reviews,
            short_description,
            variants,
            ...productPreview
        } = product;

        addRecentlyViewedProduct(productPreview, store);
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    setOfflineNoticeSize = () => {
        const { setBigOfflineNotice, productSKU } = this.props;
        const { sku } = this.getDataSource();

        /**
         * If there is any information about the product, in example,
         * we know it's URL-rewrite SKU is matching the product SKU -
         * show the small offline notice, else - show larger one.
         */
        if (sku !== productSKU) {
            setBigOfflineNotice(true);
        } else {
            setBigOfflineNotice(false);
        }
    };

    getLink(key, value) {
        const { location: { search, pathname } } = this.props;
        const obj = {
            ...convertQueryStringToKeyValuePairs(search)
        };

        if (key) {
            obj[key] = value;
        }

        const query = objectToUri(obj);

        return `${pathname}${query}`;
    }

    getRequiredProductOptions(options) {
        const { productOptionsData } = this.state;

        if (!options) {
            return [];
        }
        const requiredOptions = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        return this.setState({
            productOptionsData:
                { ...productOptionsData, requiredOptions }
        });
    }

    setLinkedDownloadablesPrice(price) {
        this.setState({
            selectedLinkPrice: price
        });
    }

    setBundlePrice(prices) {
        const { price = 0, priceExclTax = 0, finalPrice = 0 } = prices;
        this.setState({
            selectedInitialBundlePrice: price,
            selectedBundlePrice: finalPrice,
            selectedBundlePriceExclTax: priceExclTax
        });
    }

    setLinkedDownloadables(links) {
        const { productOptionsData } = this.state;
        this.setState({
            productOptionsData: {
                ...productOptionsData, downloadableLinks: links
            }
        });
    }

    getSelectedCustomizableOptions(values, updateArray = false) {
        const { productOptionsData } = this.state;

        if (updateArray) {
            this.setState({
                productOptionsData:
                    { ...productOptionsData, productOptionsMulti: values }
            });
        } else {
            this.setState({
                productOptionsData:
                    { ...productOptionsData, productOptions: values }
            });
        }
    }

    // containerProps() {
    //     const { isMobile, location, areReviewsEnabled } = this.props;
    //     const { parameters } = this.state;

    //     return {
    //         areDetailsLoaded: this.getAreDetailsLoaded(),
    //         isAttributesTabEmpty: this.isProductAttributesTabEmpty(),
    //         isInformationTabEmpty: this.isProductInformationTabEmpty(),
    //         activeProduct: this.getActiveProductDataSource(),
    //         dataSource: this.getDataSource(),
    //         useEmptyGallerySwitcher: this.getUseEmptyGallerySwitcher(),
    //         isVariant: this.getIsVariant(),
    //         isMobile,
    //         parameters,
    //         location,
    //         areReviewsEnabled,
    //         productOrVariant: this.getProductOrVariant()
    //     };
    //  }

    containerProps = () => ({
        productOrVariant: this.getProductOrVariant(),
        dataSource: this.getDataSource(),
        areDetailsLoaded: this.getAreDetailsLoaded(),
        isInformationTabEmpty: this.isProductInformationTabEmpty(),
        isAttributesTabEmpty: this.isProductAttributesTabEmpty()
    });

    updateConfigurableVariant(key, value, id, index, dropDownCount, attributeValue) {
        const { parameters: prevParameters, optionsSelected } = this.state;
        optionsSelected[index] = attributeValue;
        const parameters = getNewParameters(prevParameters, key, value);
        this.setState({ parameters, optionsSelected });

        this.updateUrl(key, value, parameters);
        this.updateConfigurableVariantIndex(parameters);
        this.fetchMoreOptions(id, dropDownCount);
    }

    fetchMoreOptions(id, dropDownCount) {
        const { fetchValue, productSKU, product: { additionalInformation, oggraphData } } = this.props;
        fetchValue(productSKU, id, dropDownCount, additionalInformation, oggraphData);
    }

    updateUrl(key, value, parameters) {
        const { location, history } = this.props;

        const isParameterSelected = getIsConfigurableParameterSelected(parameters, key, value);

        if (isParameterSelected) {
            updateQueryParamWithoutHistory(key, value, history, location);
        } else {
            removeQueryParamWithoutHistory(key, history, location);
        }
    }

    updateConfigurableVariantIndex(parameters) {
        const { product: { variants, configurable_options } } = this.props;
        const { configurableVariantIndex } = this.state;

        const newIndex = Object.keys(parameters).length === Object.keys(configurable_options).length
            ? getVariantIndex(variants, parameters, true)
            // Not all parameters are selected yet, therefore variantIndex must be invalid
            : -1;

        if (configurableVariantIndex !== newIndex) {
            this.setState({ configurableVariantIndex: newIndex });
        }
    }

    getAreDetailsLoaded() {
        const { id, isLoading } = this.props;
        if (isLoading) {
            return false;
        }

        const dataSource = this.getDataSource();
        const { id: dataProId } = dataSource;
        const yesLoaded = dataProId === id;

        return yesLoaded;
    }

    getProductOrVariant() {
        const dataSource = this.getDataSource();
        const { variants } = dataSource;
        const currentVariantIndex = this.getConfigurableVariantIndex(variants);
        const variant = variants && variants[currentVariantIndex];

        if (variants?.length > 0) {
            dataSource.stock_status = variants.some((v) => v.stock_status === IN_STOCK) ? IN_STOCK : OUT_OF_STOCK;
        }

        return variant || dataSource;
    }

    getConfigurableVariantIndex(variants) {
        const { configurableVariantIndex, parameters } = this.state;
        const hasParameters = !!Object.keys(parameters).length;

        if (configurableVariantIndex >= 0) {
            return configurableVariantIndex;
        }

        if (variants && hasParameters) {
            return getVariantIndex(variants, parameters, true);
        }

        return -1;
    }

    getDataSource() {
        const {
            productSKU,
            product
        } = this.props;

        const { sku } = product;
        const { product: stateProduct } = history?.state?.state || {};
        const { sku: stateSKU } = stateProduct || {};
        let pdp_script = '';
        if (window.storeConfig.code === 'en_au') {
            pdp_script = { src: 'https://static.zipmoney.com.au/lib/js/zm-widget-js/dist/zip-widget.min.js', type: 'text/javascript' };
        }
        /**
         * If URL rewrite requested matches loaded product SKU
         * assume it is a data-source.
         */
        if (productSKU === sku) {
            return { ...product, pdp_script };
        }

        /**
         * If URL rewrite requested matches product SKU from
         * history state - it is a data-source.
         */
        if (productSKU === stateSKU) {
            return { ...stateProduct, pdp_script };
        }

        /**
         * Else there is no place to get a up-to-date
         * information about the product from.
         */
        return { pdp_script };
    }

    getProductRequestFilter() {
        const { productSKU } = this.props;

        return { productSKU };
    }

    requestProduct() {
        const { requestProduct, productSKU } = this.props;
        const { currentProductSKU } = this.state;

        /**
         * If URL rewrite was not passed - do not request the product.
         */
        if (!productSKU) {
            return;
        }

        /**
         * Skip loading the same product SKU the second time
         */
        if (currentProductSKU === productSKU) {
            return;
        }

        this.setState({ currentProductSKU: productSKU });

        const options = {
            isSingleProduct: true,
            args: { filter: this.getProductRequestFilter() }
        };

        requestProduct(options);
    }

    updateNavigationState() {
        const { changeNavigationState } = this.props;
        changeNavigationState({ name: MENU_TAB });
    }

    updateMeta() {
        const { updateMetaFromProduct } = this.props;
        updateMetaFromProduct(this.getDataSource());
    }

    updateHeaderState() {
        const { name = '' } = this.getDataSource();
        const { changeHeaderState } = this.props;

        changeHeaderState({
            name: PDP,
            title: name,
            onBackClick: () => history.back()
        });
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs, location } = this.props;
        const { state: { prevCategoryId = null } = {} } = location;
        updateBreadcrumbs(this.getDataSource(), prevCategoryId);
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs, location, category = {} } = this.props;
        const { state: { prevCategoryId = null } = {} } = location;
        const { id: categoryId } = category;
        const setPreviousCategoryID = prevCategoryId || categoryId;
        updateBreadcrumbs(this.getDataSource(), setPreviousCategoryID);
    }

    // Render ChipBot video
    /* renderChipBotVideo() {
        const {
            product: {
                categories = {},
                attributes = {},
                sku
            }
        } = this.props;

        const {
            isChipBotVideoRendered
        } = this.state;

        if (!isChipBotVideoRendered && this.getAreDetailsLoaded()) {
            window.asyncChipBotApi = window.asyncChipBotApi || [];
            let videoMatchFound = false;

            // Product video - these will take precedence over category videos
            // Get video from Magento attributes
            const chipBotVideo = attributes?.chipbot_video?.attribute_value ?? null;
            if (chipBotVideo && !isEmpty(chipBotVideo)) {
                window.asyncChipBotApi.push(['onReady', () => {
                    window.asyncChipBotApi.push(['showVideoIcon', chipBotVideo]);
                    this.setState({ isChipBotVideoRendered: true });
                }]);
                videoMatchFound = true;
            }

            // If no video match found, use video from categories (category order priority matters here)
            if (!videoMatchFound && !isEmpty(categories)) {
                // Slimline 2 Rack
                if (!videoMatchFound) {
                    const isSlimeline2Rack = categories.some((category) => {
                        // If category.id is one of the following, return true
                        const categoryId = category.id;
                        return [4, 6, 7, 8, 84, 90, 119, 117, 141].includes(categoryId);
                    });

                    // If isSlimeline2Rack and sku starts with KR, render video
                    if (isSlimeline2Rack && sku.startsWith('KR')) {
                        if (window.storeConfig.code === 'de_de') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_typ-c8H-bYTj']);
                        } else if (window.storeConfig.code === 'fr_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_48016DjPhgGW']);
                        } else if (window.storeConfig.code === 'es_be' || window.storeConfig.code === 'es_us') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_J-pm4dzC-qVK']);
                        } else if (window.storeConfig.code === 'nl_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_ydGs9Scc83cp']);
                        } else {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_qZpk-v332dwk']);
                        }
                        videoMatchFound = true;
                        this.setState({ isChipBotVideoRendered: true });
                    }
                }

                // Slimsport Racks
                if (!videoMatchFound) {
                    const isSlimsportRack = categories.some((category) => {
                        // If category.id is one of the following, return true
                        const categoryId = category.id;
                        return [160].includes(categoryId);
                    });

                    // If isSlimeline2Rack and sku starts with KR, render video
                    if (isSlimsportRack && sku.startsWith('KS')) {
                        if (window.storeConfig.code === 'de_de') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp__RLsHhrlF8SK']);
                        } else if (window.storeConfig.code === 'fr_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_8Y75rXPV5SJr']);
                        } else if (window.storeConfig.code === 'es_be' || window.storeConfig.code === 'es_us') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_KLW06Pywmc1_']);
                        } else if (window.storeConfig.code === 'nl_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_h18zT1k9SH53']);
                        } else {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_Y3QH93wHf33q']);
                        }
                        videoMatchFound = true;
                        this.setState({ isChipBotVideoRendered: true });
                    }
                }

                // Slimpro Van Racks
                if (!videoMatchFound) {
                    const isSlimproVanRack = categories.some((category) => {
                        // If category.id is one of the following, return true
                        const categoryId = category.id;
                        return [161].includes(categoryId);
                    });

                    // Slimpro Van Racks
                    if (isSlimproVanRack && sku.startsWith('KV')) {
                        if (window.storeConfig.code === 'de_de') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_pdP96y-4dKvG']);
                        } else if (window.storeConfig.code === 'fr_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_Fl_Z81SCwLd-']);
                        } else if (window.storeConfig.code === 'es_be' || window.storeConfig.code === 'es_us') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_Pvc6xZNdMTD4']);
                        } else if (window.storeConfig.code === 'nl_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_lChZtLJX2Hf3']);
                        } else {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_m4h8wM3KWChv']);
                        }
                        videoMatchFound = true;
                        this.setState({ isChipBotVideoRendered: true });
                    }
                }

                // Pro Bed Racks
                if (!videoMatchFound) {
                    const isProBedRack = categories.some((category) => {
                        // If category.id is one of the following, return true
                        const categoryId = category.id;
                        return [174].includes(categoryId);
                    });

                    // Pro Bed Racks
                    if (isProBedRack && sku.startsWith('PB')) {
                        if (window.storeConfig.code === 'de_de') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_c85nZs8XKcCR']);
                        } else if (window.storeConfig.code === 'fr_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_gvC76xCX-QVw']);
                        } else if (window.storeConfig.code === 'es_be' || window.storeConfig.code === 'es_us') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_drMMF2z4Nmbm']);
                        } else if (window.storeConfig.code === 'nl_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_rTM0fQQ6KNt-']);
                        } else {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_KRMPM6dVr47j']);
                        }
                        videoMatchFound = true;
                        this.setState({ isChipBotVideoRendered: true });
                    }
                }

                // Drawer Systems
                if (!videoMatchFound) {
                    const isDrawerSystems = categories.some((category) => {
                        const categoryId = category.id;
                        return [27].includes(categoryId);
                    });

                    if (isDrawerSystems) {
                        if (window.storeConfig.code === 'de_de') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_gn0bR2snmj1S']);
                        } else if (window.storeConfig.code === 'fr_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_WYtyNfh0Wgp9']);
                        } else if (window.storeConfig.code === 'es_be' || window.storeConfig.code === 'es_us') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_SNl9Bwhf_lq2']);
                        } else if (window.storeConfig.code === 'nl_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_ml3fJt4BvDR8']);
                        } else {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_p6pMdbrm8fKx']);
                        }
                        videoMatchFound = true;
                        this.setState({ isChipBotVideoRendered: true });
                    }
                }

                // Cargo Slides
                if (!videoMatchFound) {
                    const isCargoSlides = categories.some((category) => {
                        const categoryId = category.id;
                        return [28].includes(categoryId);
                    });

                    if (isCargoSlides) {
                        if (window.storeConfig.code === 'de_de') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_4KjrpTbXzvff']);
                        } else if (window.storeConfig.code === 'fr_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_YT2kkXVKBnSY']);
                        } else if (window.storeConfig.code === 'es_be' || window.storeConfig.code === 'es_us') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_VVW0q5Vjw_wk']);
                        } else if (window.storeConfig.code === 'nl_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_RhZ-VQMFjwZL']);
                        } else {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_8NjX7Q-JxZrk']);
                        }
                        videoMatchFound = true;
                        this.setState({ isChipBotVideoRendered: true });
                    }
                }

                // Camping Solutions
                if (!videoMatchFound) {
                    const isCampingSolutions = categories.some((category) => {
                        const categoryId = category.id;
                        return [
                            18, // Camping Gear
                            17, // Tents & Awnings
                            19, // Camop Kitchen
                            175, // Camp Cooking
                            20, // Chairs & Tables
                            21, // Storage & Bags
                            22 // Tools & Other
                        ].includes(categoryId);
                    });

                    if (isCampingSolutions) {
                        if (window.storeConfig.code === 'de_de') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_GVS6NdR0wHv6']);
                        } else if (window.storeConfig.code === 'fr_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_vcDG2BPVYZrv']);
                        } else if (window.storeConfig.code === 'es_be' || window.storeConfig.code === 'es_us') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_4xyrhkMKlvXx']);
                        } else if (window.storeConfig.code === 'nl_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_GMYVk6_Jh52S']);
                        } else {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_vZwH_6Zxnwts']);
                        }
                        videoMatchFound = true;
                        this.setState({ isChipBotVideoRendered: true });
                    }
                }

                // Water Solutions
                if (!videoMatchFound) {
                    const isWaterSolutions = categories.some((category) => {
                        const categoryId = category.id;
                        return [
                            25, // Water
                            110, // Tanks & Mounts
                            111, // Bags
                            112, // Shower
                            113 // Hoses & Pumps
                        ].includes(categoryId);
                    });

                    if (isWaterSolutions) {
                        if (window.storeConfig.code === 'de_de') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_DDkMvTf7-Pm6']);
                        } else if (window.storeConfig.code === 'fr_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_tTFt1NXQgT2m']);
                        } else if (window.storeConfig.code === 'es_be' || window.storeConfig.code === 'es_us') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_PDtxF6yP7K7W']);
                        } else if (window.storeConfig.code === 'nl_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_QsT5SwrvwtGc']);
                        } else {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_cY5Srk6wGQfJ']);
                        }
                        videoMatchFound = true;
                        this.setState({ isChipBotVideoRendered: true });
                    }
                }

                // Roof Access Solutions
                if (!videoMatchFound) {
                    const isRoofAccessSolutions = categories.some((category) => {
                        const categoryId = category.id;
                        return [
                            47 // Ladders
                        ].includes(categoryId);
                    });

                    if (isRoofAccessSolutions) {
                        if (window.storeConfig.code === 'de_de') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_NLf6_2rzyfKq']);
                        } else if (window.storeConfig.code === 'fr_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_MCt7XPDWSxQF']);
                        } else if (window.storeConfig.code === 'es_be' || window.storeConfig.code === 'es_us') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_m9n-9ndcb08S']);
                        } else if (window.storeConfig.code === 'nl_be') {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_3nVBsYnprLSw']);
                        } else {
                            window.asyncChipBotApi.push(['showVideoIcon', 'videxp_mqP-9YDt0Dz1']);
                        }
                        videoMatchFound = true;
                        this.setState({ isChipBotVideoRendered: true });
                    }
                }
            }
        }
    } */

    render() {
        return (
            <ProductPage
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withReducers({
    ProductReducer
})(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer)
));
