/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isInitiallySignedIn } from 'Util/Auth';

import {
    ADD_GIFT_CARD,
    GET_GIFT_CARD_DETAILS,
    REMOVE_GIFT_CARD,
    UPDATE_CUSTOMER_ADDRESSES,
    UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_IS_LOADING,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS
} from './MyAccount.action';

/** @namespace Pwa/Store/MyAccount/Reducer/getInitialState */
export const getInitialState = () => ({
    isSignedIn: isInitiallySignedIn(),
    passwordResetStatus: false,
    isPasswordForgotSend: false,
    isLoading: false,
    customer: {},
    getassignAmGiftCard: {},
    message: ''
});

/** @namespace Pwa/Store/MyAccount/Reducer/MyAccountReducer */
export const MyAccountReducer = (
    state = getInitialState(),
    action
) => {
    const {
        status, customer, customeraddress, message, getassignAmGiftCard
    } = action;

    switch (action.type) {
    case UPDATE_CUSTOMER_SIGN_IN_STATUS:
        return {
            ...state,
            isSignedIn: status
        };

    case UPDATE_CUSTOMER_PASSWORD_RESET_STATUS:
        return {
            ...state,
            passwordResetStatus: status,
            passwordResetMessage: message
        };

    case UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS:
        return {
            ...state,
            isPasswordForgotSend: !state.isPasswordForgotSend
        };

    case UPDATE_CUSTOMER_DETAILS:
        return {
            ...state,
            customer
        };

    case UPDATE_CUSTOMER_ADDRESSES:
        const { customer: customerData } = state;
        const { addresses = [] } = customerData || {};
        return {
            ...state,
            customer: { ...customerData, addresses: Array.from(new Set([...addresses, ...customeraddress])) }
        };
    case UPDATE_CUSTOMER_IS_LOADING:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };
    case GET_GIFT_CARD_DETAILS:
        return {
            ...state,
            getassignAmGiftCard
        };
    case ADD_GIFT_CARD:
        return {
            ...state,
            getassignAmGiftCard
        };
    case REMOVE_GIFT_CARD:
        return {
            ...state,
            getassignAmGiftCard
        };
    default:
        return state;
    }
};

export default MyAccountReducer;
