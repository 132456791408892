export const PLEASE_SELECT = { value: '', label: __('Please Select...') };

export const TOPICS = [
    PLEASE_SELECT,
    { value: 'sales', label: __('Sales') },
    { value: 'fitment_question', label: __('Fitment Question') },
    { value: 'general_praise', label: __('General Praise') },
    { value: 'dealer_enquiry', label: __('Dealer Inquiry') },
    { value: 'order_cancellation', label: __('Order Cancellation') },
    { value: 'refunds', label: __('Refunds') }
];
