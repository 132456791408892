/* eslint-disable no-unused-vars */
import {
    CLEAR_INTENT_SECRET,
    GET_SAVED_CARDS,
    SAVE_SELECTED_CARD,
    UPDATE_CARD_INTENT_SECRET,
    UPDATE_GERO_PAY_INTENT_SECRET,
    UPDATE_INTENT_LOADING,
    UPDATE_KLARNA_INTENT_SECRET,
    UPDATE_SOFORT_INTENT_SECRET
} from './SavedCard.action';

export const initialState = {
    cards: [],
    selectedCard: '',
    isStripePaymentClicked: false,
    intent_secret_card: '',
    intent_secret_gero_pay: '',
    intent_secret_sofort: '',
    intent_secret_klarna: '',
    isLoading: false
};

/** @namespace Pwa/Store/SavedCard/Reducer/StripeReducer */
export const StripeReducer = (state = initialState, action) => {
    const {
        type,
        data,
        selectedCard,
        isStripePaymentClicked,
        intent_secret_card,
        intent_secret_gero_pay,
        intent_secret_sofort,
        intent_secret_klarna,
        loading
    } = action;

    switch (type) {
    case GET_SAVED_CARDS:
        return {
            ...state,
            cards: data.data
        };
    case SAVE_SELECTED_CARD:
        return {
            ...state,
            selectedCard
        };
    case UPDATE_CARD_INTENT_SECRET:
        return {
            ...state,
            intent_secret_card,
            isLoading: false

        };
    case UPDATE_GERO_PAY_INTENT_SECRET:
        return {
            ...state,
            intent_secret_gero_pay,
            isLoading: false

        };
    case UPDATE_KLARNA_INTENT_SECRET:
        return {
            ...state,
            intent_secret_klarna,
            isLoading: false

        };
    case UPDATE_SOFORT_INTENT_SECRET:
        return {
            ...state,
            intent_secret_sofort,
            isLoading: false
        };
    case UPDATE_INTENT_LOADING:
        return {
            ...state,
            isLoading: loading
        };
    case CLEAR_INTENT_SECRET:
        return {
            ...state,
            isLoading: false,
            intent_secret_card: '',
            intent_secret_gero_pay: '',
            intent_secret_sofort: '',
            intent_secret_klarna: ''
        };
    default:
        return state;
    }
};

export default StripeReducer;
