/* eslint-disable no-console */
export const UPDATE_CONTACT_PAGE = 'UPDATE_CONTACT_PAGE';
export const UPDATE_CONTACT_PAGE_MAKES = 'UPDATE_CONTACT_PAGE_MAKES';
export const UPDATE_CONTACT_PAGE_YEARS = 'UPDATE_CONTACT_PAGE_YEARS';
export const UPDATE_CONTACT_PAGE_MODELS = 'UPDATE_CONTACT_PAGE_MODELS';
export const UPDATE_CONTACT_PAGE_OPTIONS = 'UPDATE_CONTACT_PAGE_OPTIONS';

export const CONTACT_PAGE_SUBMIT_REQUEST = 'CONTACT_PAGE_SUBMIT_REQUEST';
export const CONTACT_PAGE_SUBMIT_RESPONSE = 'CONTACT_PAGE_SUBMIT_RESPONSE';

/** @namespace Pwa/Store/ContactPage/Action/updateContactPage */
export const updateContactPage = ({ content }, isLoading) => ({
    type: UPDATE_CONTACT_PAGE,
    content,
    isLoading
});

/** @namespace Pwa/Store/ContactPage/Action/updateContactPageYears */
export const updateContactPageYears = (data, isLoading) => ({
    type: UPDATE_CONTACT_PAGE_YEARS,
    data,
    isLoading
});

/** @namespace Pwa/Store/ContactPage/Action/updateContactPageMakes */
export const updateContactPageMakes = (data, isLoading) => ({
    type: UPDATE_CONTACT_PAGE_MAKES,
    data,
    isLoading
});

/** @namespace Pwa/Store/ContactPage/Action/updateContactPageModels */
export const updateContactPageModels = (data, isLoading) => ({
    type: UPDATE_CONTACT_PAGE_MODELS,
    data,
    isLoading
});

/** @namespace Pwa/Store/ContactPage/Action/updateContactPageOptions */
export const updateContactPageOptions = (data, isLoading) => ({
    type: UPDATE_CONTACT_PAGE_OPTIONS,
    data,
    isLoading
});

/** @namespace Pwa/Store/ContactPage/Action/requestContact */
export const requestContact = (isLoading) => ({
    type: CONTACT_PAGE_SUBMIT_REQUEST,
    isLoading
});
/** @namespace Pwa/Store/ContactPage/Action/responseContact */
export const responseContact = (isLoading) => ({
    type: CONTACT_PAGE_SUBMIT_RESPONSE,
    isLoading
});
