/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
// import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import ChevronIcon from 'Component/ChevronIcon';
import Link from 'Component/Link';
import {
    Breadcrumb as SourceBreadcrumb
} from 'SourceComponent/Breadcrumb/Breadcrumb.component';

import './Breadcrumb.override.style';

/** @namespace Pwa/Component/Breadcrumb/Component/BreadcrumbComponent */
export class BreadcrumbComponent extends SourceBreadcrumb {
    // TODO implement logic
    static propTypes = {
        linkTo: PropTypes.shape({}),
        index: PropTypes.number.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        url: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({})
        ]),
        name: PropTypes.string
    };

    static defaultProps = {
        linkTo: {},
        url: '',
        name: ''
    };

    getLinkUrl() {
        const {
            url = ''
        } = this.props;

        if (typeof url === 'string' || !url) {
            return {
                pathname: url || '',
                search: '',
                state: {
                    ...this.state
                }
            };
        }

        return url;
    }

    renderName() {
        const { name } = this.props;
        const cleanName = name.replace(/([+])/g, ' ');

        return (
            <>
                { /* <TextPlaceholder content={ cleanName } /> */ }
                { cleanName }
            </>
        );
    }

    renderLink= () => {
        const {
            index,
            isDisabled
        } = this.props;
        const url = this.getLinkUrl() || {};
        const link = url.pathname;

        if (link[0] === '/') {
            return (
                <Link
                  block="Breadcrumb"
                  elem="Link"
                  to={ link }
                  tabIndex={ isDisabled ? '-1' : '0' }
                >
                    <meta itemProp="item" content={ window.location.origin + url.pathname } />
                    <span itemProp="name">
                        { this.renderName() }
                    </span>
                    <meta itemProp="position" content={ index } />
                    <ChevronIcon />
                </Link>
            );
        }

        return (
            <Link
              block="Breadcrumb"
              elem="Link"
              to={ `/${link}` }
              tabIndex={ isDisabled ? '-1' : '0' }
            >
                <meta itemProp="item" content={ window.location.origin + url.pathname } />
                <span itemProp="name">
                    { this.renderName() }
                </span>
                <meta itemProp="position" content={ index } />
                <ChevronIcon />
            </Link>
        );
    };
}

export default BreadcrumbComponent;
