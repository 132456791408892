/* eslint-disable fp/no-let */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable arrow-parens */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import {
    isEmpty, map
} from 'lodash';
import PropTypes from 'prop-types';

import FieldForm from 'Component/FieldForm';

import './AddUserForm.override.style';

export const userStatus = [{
    id: 'ACTIVE',
    label: 'Active',
    value: 'ACTIVE'
},
{
    id: 'INACTIVE',
    label: 'InActive',
    value: 'INACTIVE'

}];
/** @namespace Pwa/Component/AddUserForm/Component/AddUserForm */
export class AddUserForm extends FieldForm {
    // TODO implement logic
    static propTypes = {
        user: PropTypes.any,
        roleList: PropTypes.any,
        onSave: PropTypes.func
    };

    static defaultProps = {
        onSave: () => {}
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            roleId: 'Select',
            stateId: 'Select'
        };
    }

    get fieldMap() {
        const { roleId, stateId } = this.state;
        const { companyRoles, user, roleList } = this.props;
        let roles = [];
        if (companyRoles && companyRoles.roles && companyRoles.roles.items) {
            roles = companyRoles.roles.items;
        }
        if (!isEmpty(roleList)) {
            roles = roleList;
        }
        const roleOptions = roles;

        return {
            job_title: {
                label: __('Job Title'),
                validation: ['notEmpty']
            },
            role_id: {
                type: 'select',
                label: __('User Role'),
                validation: ['notEmpty'],
                value: roleId,
                selectOptions: roleOptions.map(({ id, name }) => ({ id, label: name, value: id })),
                onChange: this.onRoleChange
            },

            firstname: {
                label: __('First name'),
                validation: ['notEmpty']
            },
            lastname: {
                label: __('Last name'),
                validation: ['notEmpty']
            },
            email: {
                label: __('Email'),
                validation: ['notEmpty'],
                type: 'email'
            },
            telephone: {
                label: __('Phone number'),
                validation: ['notEmpty']
            },
            status: {
                type: 'select',
                label: __('State'),
                validation: ['notEmpty'],
                value: stateId,
                selectOptions: userStatus.map(({ id, label, value }) => ({ id, label, value })),
                onChange: this.onStateChange
            }

        };
    }

    onRoleChange = (roleId) => {
        this.setState({
            roleId
        });
    };

    onStateChange = (stateId) => {
        this.setState({
            stateId
        });
    };

    onFormSuccess = (fields) => {
        const { onSave } = this.props;
        const updateFields = this.setAddUserFormObject(fields);

        onSave(updateFields);
    };

  setAddUserFormObject = (fields) => {
      // removing street related fields from the form object
      const newFields = Object.keys(fields)
          .reduce(
              (acc, key) => {
                  acc[key] = fields[key];
                  return acc;
              }, {}
          );

      return newFields;
  };

  getDefaultValues(fieldEntry) {
      const [key, { value }] = fieldEntry;
      const { user: { [key]: userValue } } = this.props;

      return {
          ...super.getDefaultValues(fieldEntry),
          value: value !== undefined ? value : userValue
      };
  }

  renderActions() {
      return (
        <button
          type="submit"
          block="Button"
          mix={ { block: 'AddUser', elem: 'Button' } }
        >
            { __('Save') }
        </button>
      );
  }
}

export default AddUserForm;
