/* eslint-disable no-console */
// import { isEmpty } from 'lodash';

import {
    UPDATE_SELECTED_VEHICLE,
    UPDATE_VEHICLE_FINDER_PAGE_FORMDATA,
    UPDATE_VEHICLE_FINDER_PAGE_MAKES,
    UPDATE_VEHICLE_FINDER_PAGE_MODELS,
    UPDATE_VEHICLE_FINDER_PAGE_OPTIONS,
    UPDATE_VEHICLE_FINDER_PAGE_YEARS,
    UPDATE_VEHICLE_FINDER_PRODUCT_LOADING,
    UPDATE_VEHICLE_FINDER_SPECIFIC_SKUS,
    UPDATE_VEHICLE_SPECIFIED_DATA
} from './VehicleFinder.action';

export const initialState = {
    yearsData: [],
    makesData: [],
    modelsData: [],
    optionsData: [],
    vehicleSpecificSkus: [],
    isLoading: true,
    specifiedData: {},
    isProductLoading: true,
    formData: {
        year: '',
        make: '',
        model: '',
        option: '',
        nick_date: '',
        purchase_date: ''
    },
    selectedVehicle: ''
};

/** @namespace Pwa/Store/VehicleFinder/Reducer/VehiclesFinderPageReducer */
export const VehiclesFinderPageReducer = (state = initialState, action) => {
    const {
        type, isLoading, isProductLoading = true, data, vehicleSpecificSkus, specifiedData, selectedVehicle
    } = action;

    switch (type) {
    case UPDATE_VEHICLE_FINDER_PAGE_YEARS:
        if ((state.yearsData && state.yearsData.length > 0) || data.data === undefined || data.data.length < 0) {
            return {
                ...state,
                isLoading
            };
        }

        return {
            ...state,
            isLoading,
            yearsData: data.data
        };
    case UPDATE_VEHICLE_FINDER_PRODUCT_LOADING:
        return {
            ...state,
            isProductLoading
        };
    case UPDATE_VEHICLE_SPECIFIED_DATA:
        return {
            ...state,
            specifiedData
        };

    case UPDATE_VEHICLE_FINDER_PAGE_MAKES:
        return {
            ...state,
            isLoading,
            makesData: data.data,
            modelsData: [],
            optionsData: []
        };
    case UPDATE_VEHICLE_FINDER_PAGE_MODELS:

        return {
            ...state,
            isLoading,
            modelsData: data.data,
            optionsData: []
        };
    case UPDATE_VEHICLE_FINDER_PAGE_OPTIONS:

        return {
            ...state,
            isLoading,
            optionsData: data.data
        };
    case UPDATE_VEHICLE_FINDER_PAGE_FORMDATA:
        return {
            ...state,
            formData: data
        };
    case UPDATE_VEHICLE_FINDER_SPECIFIC_SKUS:
        return {
            ...state,
            vehicleSpecificSkus
        };
    case UPDATE_SELECTED_VEHICLE:
        return {
            ...state,
            selectedVehicle
        };
    default:
        return state;
    }
};

export default VehiclesFinderPageReducer;
