/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/sort-comp */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import Link from '@scandipwa/scandipwa/src/component/Link';
import { get, map, toLower } from 'lodash';
import { Component } from 'react';

import Html from 'Component/Html';
import Image from 'Component/Image';

import './BrochureSearch.style.scss';

/** @namespace Pwa/Component/BrochureSearch/Component/BrochureSearch */
export class BrochureSearch extends Component {
    __construct(props) {
        super.__construct(props);
        this.state = {
            search: '',
            mouseOver: false
        };
    }

  handleChange = (e) => {
      const search = e.target.value;
      this.setState({ search });
  };

  componentDidMount() {
  }

  clearSearch = () => this.setState({ search: '' });

  handleSubmit = () => {};

  onMouseOut = (index) => this.setState({ [`mouseOver_${index}`]: false });

  onMouseOver = (index) => this.setState({ [`mouseOver_${index}`]: true });

  renderBrochure = (data, index) => {
      const { search } = this.state;
      // const mouseOver = get(this.state, `mouseOver_${index}`, false);

      const {
          img_front_url,
          img_alt,
          img_back_url,
          pdf_url,
          link_url,
          link_text,
          brand,
          model
      } = data;

      const lSearch = toLower(search);
      const lContent = toLower(`${brand} ${model}`);

      const isHidden = lContent.indexOf(lSearch) === -1;

      if (isHidden) {
          return <div key={ index } />;
      }
      window.scrollTo({
          top: 0,
          behavior: 'smooth'
      });

      return (
      <div className="brochure" key={ index }>
        <div
          className="brochure-top"
          // onClick={ () => this.onMouseOver(index) }
          // onMouseOver={ () => this.onMouseOver(index) }
          // onMouseOut={ () => this.onMouseOut(index) }
        >
            <Image
              src={ img_front_url }
              alt={ img_alt }
              ratio="custom"
              mix={ { block: 'Brochure', elem: 'Picture' } }
              isPlaceholder={ !img_front_url }
            />
            <div className="brochure-image-back">
              <Image
                src={ img_back_url }
                alt={ img_alt }
                ratio="custom"
                mix={ { block: 'Brochure', elem: 'Picture' } }
                isPlaceholder={ !img_back_url }
              />
              <div className="brochure-action">
                <a target="_blank" href={ pdf_url } className="Button">
                    { __('View Brochure') }
                </a>
                <a target="_blank" href={ pdf_url } className="Button" download>
                    { __('Download') }
                </a>
                { link_url && (
                  <a href={ link_url }>
                    { ' ' }
                    { link_text }
                    { ' ' }
                  </a>
                ) }
              </div>
            </div>
        </div>
        <div className="brochure-bottom">
          <div className="brochure-title">
                { brand }
          </div>
          <div className="brochure-subtitle">
              { model }
          </div>
        </div>
      </div>
      );
  };

  render() {
      const { search } = this.state;
      const { data } = this.props;
      let brochureText = '<p>You can also find further brochures in English here.</p>';

      // DE DE
      if (window.storeConfig.code === 'de_de') {
          brochureText = (
          <p>
              Zusätzlich findest Du
              <a href="/en/de/download-brochures"> hier </a>
              {' '}
              weitere Broschüren in englischer Sprache.
          </p>
          );
      }
      // FR BE
      if (window.storeConfig.code === 'fr_be') {
          brochureText = (
            <p>
              Vous trouverez également
              <a href="/en/de/download-brochures"> ici </a>
              d'autres brochures en anglais.
            </p>
          );
      }
      // ES BE
      if (window.storeConfig.code === 'es_be') {
          brochureText = (
              <p>
                Adicionalmente,
                <a href="/en/de/download-brochures"> aquí </a>
                encontraras más folletos en inglés.
              </p>
          );
      }
      // NL BE
      if (window.storeConfig.code === 'nl_be') {
          brochureText = (
              <p>
                Je kunt
                <a href="/en/de/download-brochures"> hier </a>
                ook meer Engelstalige brochures vinden.
              </p>
          );
      }

      return (
      <div>
        <div className="search-container">
          <input placeholder={ __('Search Brochures...') } value={ search } onChange={ this.handleChange } />
          <button onClick={ this.clearSearch } className="btn">
            { __('Clear') }
          </button>
        </div>
        <div className="brochure-container">
          { map(data, this.renderBrochure) }
        </div>
        <div className="brochureBottmText">
          {brochureText}
        </div>
      </div>
      );
  }
}

export default BrochureSearch;
