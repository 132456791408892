/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import {
    Notification as SourceNotification
} from 'SourceComponent/Notification/Notification.component';
import {
    deleteAuthorizationToken
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';

import './Notification.style.override';

export const CUSTOMER = 'customer';

/** @namespace Pwa/Component/Notification/Component/Notification */
export class Notification extends SourceNotification {
    // TODO implement logic

    // in case on not autherized login page will be apear
    async forceRedirectToLoginPage() {
        deleteGuestQuoteId();
        deleteAuthorizationToken();
        BrowserDatabase.deleteItem(CUSTOMER);
        removeUid();
        setTimeout(() => {
            // your code to be executed after 1 second
            history.push({ pathname: '/account/login' });
            window.location.reload(true);
        }, 1500);
    }

    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgDebug = '', msgType } = notification;
        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        // if (msgText && msgText.toLowerCase().includes('not autherized')) {
        //     this.forceRedirectToLoginPage();
        // }

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>Close</button>
                <p block="Notification" elem="Text">{ msgText }</p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default Notification;
