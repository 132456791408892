/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    faHeart
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import HeartIcon from 'Component/HeartIcon';
import Loader from 'Component/Loader';
import { ProductType } from 'Type/ProductList';
import { appendWithStoreCode } from 'Util/Url';

import './ProductWishlistButton.style';

/** @namespace Pwa/Component/ProductWishlistButton/Component/ProductWishlistButtonComponent */
export class ProductWishlistButtonComponent extends PureComponent {
    static propTypes = {
        isReady: PropTypes.bool,
        isLoading: PropTypes.bool,
        quantity: PropTypes.number,
        isDisabled: PropTypes.bool,
        isInWishlist: PropTypes.bool,
        product: ProductType.isRequired,
        addToWishlist: PropTypes.func.isRequired,
        removeFromWishlist: PropTypes.func.isRequired,
        mix: PropTypes.shape({ block: PropTypes.string, elem: PropTypes.string, mod: PropTypes.string }),
        isSignedIn: PropTypes.bool.isRequired
    };

    static defaultProps = {
        mix: {},
        quantity: 1,
        isReady: true,
        isLoading: false,
        isDisabled: false,
        isInWishlist: false
    };

    getTitle = () => {
        const { isInWishlist, isReady, isSignedIn } = this.props;

        if (!isSignedIn) {
            return __('Please sign in first!');
        }

        if (!isReady) {
            return __('Please select variant first!');
        }

        if (isInWishlist) {
            return __('Remove from Wishlist');
        }

        return __('Add to Wishlist');
    };

    updateToGaDataAddWishList = () => {
        const {
            product,
            quantity
        } = this.props;
        const {
            name,
            id,
            sku,
            attributes
        } = product;
        const pro_id = id;
        const { currencyCode, storeId } = window.storeConfig;

        // Brand
        let brand = '';
        if (attributes && attributes.brand) {
            const brandId = attributes.brand?.attribute_value ?? null;
            if (brandId) {
                brand = attributes.brand.attribute_options[brandId].label ?? '';
            }
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: 'addToWishList',
            ecommerce: {
                currencyCode,
                storeId,
                pageType: 'productPage',
                wishListItem: {
                    name,
                    id: sku,
                    sku,
                    product_id: `${pro_id}`,
                    quantity,
                    brand
                }
            }
        });
    };

    onClick = (e) => {
        const {
            product,
            quantity,
            isInWishlist,
            addToWishlist,
            removeFromWishlist,
            addBundleProductToWishlist,
            productOptionsData
        } = this.props;

        const {
            bundle_options, type_id, id, sku
        } = product;
        const dataList = [];
        const argDataList = [];
        const { productOptions = [] } = productOptionsData;
        for (const option in productOptions) {
            const productOption = productOptions[option];
            const OPTION_ID = productOption.id;
            const OPTION_VALUE_ID = productOption.value[0];
            const OPTION_TYPE = type_id;
            const OPTION_QUANTITY = productOption.quantity;
            const normalValue = `${OPTION_TYPE}/${OPTION_ID}/${OPTION_VALUE_ID}/${OPTION_QUANTITY}`;
            // eslint-disable-next-line new-cap
            const encodedString = Buffer.from(normalValue).toString('base64');
            dataList.push(encodedString);
        }
        const dataArgs = {
            wishlistId: '',
            wishListItems: [
                {
                    sku,
                    quantity,
                    selected_options: dataList
                }
            ]
        };

        if (type_id === 'bundle') {
            return addBundleProductToWishlist(dataArgs);
        }
        e.preventDefault();

        if (!isInWishlist) {
            this.updateToGaDataAddWishList();
            return addToWishlist(product, quantity);
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });

        return history.push(appendWithStoreCode('/my-account/my-wishlist'));

        // return removeFromWishlist(product, quantity);
    };

    renderLoader() {
        const { isLoading } = this.props;

        return (
            <Loader isLoading={ isLoading } />
        );
    }

    renderButton() {
        const { isInWishlist, isDisabled, mix } = this.props;
        const btnTitle = isInWishlist ? __('Go to Wishlist') : __('Add to Wishlist');

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled } }
              mix={ { block: 'Button', mods: { isHollow: !isInWishlist }, mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                <FontAwesomeIcon icon={ faHeart } />
                <div className="wishlistText">
                    { btnTitle }
                </div>
            </button>
        );
    }

    renderContent() {
        return (
            <div block="ProductWishlistButton">
                { this.renderButton() }
                { this.renderLoader() }
            </div>
        );
    }

    render() {
        const { product: { id } = {} } = this.props;

        if (id !== -1) {
            return this.renderContent();
        }

        return null;
    }
}

export default ProductWishlistButtonComponent;
