import {
    SET_NOTIFYME_LOADING
} from './NotifyMe.action';

export const initialState = {
    isLoading: false
};

/** @namespace Pwa/Store/NotifyMe/Reducer/NotifyMeReducer */
export const NotifyMeReducer = (state = initialState, action) => {
    const {
        isLoading,
        type
    } = action;

    switch (type) {
    case SET_NOTIFYME_LOADING:
        return {
            ...state,
            isLoading
        };
    default:
        return state;
    }
};

export default NotifyMeReducer;
