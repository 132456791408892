/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import {
    ClickOutside as SourceClickOutside
} from 'SourceComponent/ClickOutside/ClickOutside.component';

import './ClickOutside.style.override';

/** @namespace Pwa/Component/ClickOutside/Component/ClickOutside */
export class ClickOutside extends SourceClickOutside {
    // TODO implement logic
    // handleClick = ({ target }) => {
    //     const { onClick } = this.props;

    //     if (this.childrenRefs.every(
    //         (ref) => {
    //             // if (ref.current && ref.current.overlayRef) {
    //             const elementRef = ref.current?.overlayRef?.current || ref.current;
    //             return !elementRef.contains(target);
    //             // }
    //             // const elementRef = ref.current;
    //             // if (!elementRef) {
    //             //     return !elementRef.contains(target);
    //             // }

    //             // return null;
    //         }
    //     )) {
    //         onClick();
    //     }
    // };
    handleClick = ({ target }) => {
        const { onClick } = this.props;

        if (this.childrenRefs.every(
            (ref) => {
                const elementRef = ref.current?.overlayRef?.current || ref.current;

                return !elementRef.contains(target);
            }
        )) {
            onClick();
        }
    };
}

export default ClickOutside;
