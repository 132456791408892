/* eslint-disable no-console */
import { get } from 'lodash';

import PartsFinderQuery from 'Query/PartsFinder.query';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { QueryDispatcher } from 'Util/Request';

/** @namespace Pwa/Store/PartsFinder/Options/Dispatcher/OptionsDispatcher */
export class OptionsDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess(response, dispatch, { cb }) {
        const data = get(response, 'options', {});
        if (data.length > 0) {
            dispatch(cb({ data }, false));
        }
    }

    onError(_, dispatch) {
        dispatch(updateNoMatch(true));
    }

    prepareRequest(options, dispatch) {
        const { cb } = options;
        dispatch(cb({}, true));
        return PartsFinderQuery.getOptions(options);
    }
}

export default new OptionsDispatcher();
