/* eslint-disable no-unused-vars */
import NotifyMeQuery from 'Query/NotifyMe.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, QueryDispatcher } from 'Util/Request';

import { setLoading } from './NotifyMe.action';

/** @namespace Pwa/Store/NotifyMe/Dispatcher/NotifyMeDispatcher */
export class NotifyMeDispatcher extends QueryDispatcher {
    notifyme(options, dispatch) {
        dispatch(setLoading(true));
        return fetchMutation(NotifyMeQuery.notifyme(options)).then(
            /** @namespace Pwa/Store/NotifyMe/Dispatcher/fetchMutation/then */
            ({ addStockNotif }) => {
                dispatch(showNotification('success', addStockNotif.message));
                dispatch(setLoading(false));
            },
            /** @namespace Pwa/Store/NotifyMe/Dispatcher/fetchMutation/then */
            (error) => {
                dispatch(showNotification('error', __('Something went wrong, please try again.')));
                dispatch(setLoading(false));
            }
        );
    }
}

export default new NotifyMeDispatcher();
