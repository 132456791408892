/* eslint-disable no-console */
/* eslint-disable new-cap */
/* eslint-disable react/prop-types */
/* eslint-disable fp/no-let */
import { Component } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { NotifyMeDispatcher } from 'Store/NotifyMe';
import { isSignedIn } from 'Util/Auth';
import { validateEmail } from 'Util/Helper';

import NotifyMe from './NotifyMe.component';

/** @namespace Pwa/Component/NotifyMe/Container/NotifyMeContainer */
export class NotifyMeContainer extends Component {
  state = {
      guest_email: ''
      // xnotif_qty: 1,
  };

  // if (isSignedIn()) {

  updateEmail = (guest_email) => this.setState({ guest_email });

  onClick = () => {
      const { guest_email } = this.state;
      const {
          showNotification, productId: product_id, quantity: xnotif_qty, AddNotifyMe
      } = this.props;
      const sendEmail = !isSignedIn();

      if (sendEmail && !validateEmail(guest_email)) {
          showNotification(__('Invalid Email Address'));
          return;
      }

      let stockNotifInput = {
          product_id,
          xnotif_qty
      };

      if (sendEmail) {
          stockNotifInput = { ...stockNotifInput, guest_email };
      }

      AddNotifyMe(stockNotifInput);
  };

  render() {
      const { productId, isLoading } = this.props;
      return (
      <NotifyMe
        isLoading={ isLoading }
        productId={ productId }
        updateEmail={ this.updateEmail }
        onClick={ this.onClick }
        isSignedIn={ isSignedIn() }
      />
      );
  }
}

/** @namespace Pwa/Component/NotifyMe/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isLoading: state.NotifyMeReducer.isLoading
});

/** @namespace Pwa/Component/NotifyMe/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (message) => dispatch(showNotification('error', message)),
    AddNotifyMe: (options) => NotifyMeDispatcher.notifyme(options, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(NotifyMeContainer);
