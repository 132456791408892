import {
    CONTACT_PAGE_SUBMIT_REQUEST,
    CONTACT_PAGE_SUBMIT_RESPONSE,
    UPDATE_CONTACT_PAGE,
    UPDATE_CONTACT_PAGE_MAKES,
    UPDATE_CONTACT_PAGE_MODELS,
    UPDATE_CONTACT_PAGE_OPTIONS,
    UPDATE_CONTACT_PAGE_YEARS
} from './ContactPage.action';

export const initialState = {
    content: {},
    yearsData: [],
    makesData: [],
    modelsData: [],
    optionsData: [],
    isLoading: true
};

/** @namespace Pwa/Store/ContactPage/Reducer/ContactPageReducer */
export const ContactPageReducer = (state = initialState, action) => {
    const { type, isLoading, data = {} } = action;
    switch (type) {
    case UPDATE_CONTACT_PAGE:
        const { content } = action;
        return {
            ...state,
            isLoading,
            content
        };
    case UPDATE_CONTACT_PAGE_YEARS:
        return {
            ...state,
            isLoading,
            yearsData: data.data,
            makesData: [],
            modelsData: [],
            optionsData: []
        };
    case UPDATE_CONTACT_PAGE_MAKES:
        return {
            ...state,
            isLoading,
            makesData: data.data,
            modelsData: [],
            optionsData: []
        };
    case UPDATE_CONTACT_PAGE_MODELS:
        return {
            ...state,
            isLoading,
            modelsData: data.data,
            optionsData: []
        };
    case UPDATE_CONTACT_PAGE_OPTIONS:
        return {
            ...state,
            isLoading,
            optionsData: data.data
        };
    case CONTACT_PAGE_SUBMIT_REQUEST:
        return {
            ...state,
            isLoading
        };
    case CONTACT_PAGE_SUBMIT_RESPONSE:
        return {
            ...state,
            isLoading
        };
    default:
        return state;
    }
};

export default ContactPageReducer;
