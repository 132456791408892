/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import {
    LOAD_AMAZON_PAY_CONFIG,
    SAVE_AMAZON_PAY_SESSION_ID,
    UPDATE_AMAZON_PAY_ADDRESS,
    UPDATE_AMAZON_PAY_CONFIG,
    UPDATE_AMAZON_PAY_EMAIL,
    UPDATE_STRIPE_PAYMENT_REQUEST
} from './AmazonPay.action';

export const initialState = {
    amazonPaySessionId: '',
    email: '',
    amazonPayConfig: {},
    amazonPayShippingAddress: {},
    isLoading: true,
    options: {}
};

/** @namespace Pwa/Store/AmazonPay/Reducer/AmazonPayReducer */
export const AmazonPayReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        amazonPayConfig,
        amazonPayShippingAddress = {},
        amazonPaySessionId,
        email,
        options
    } = action;

    switch (type) {
    case UPDATE_AMAZON_PAY_CONFIG:
        return {
            ...state,
            isLoading,
            amazonPayConfig
        };

    case UPDATE_AMAZON_PAY_ADDRESS:
        return {
            ...state,
            amazonPayShippingAddress
        };
    case SAVE_AMAZON_PAY_SESSION_ID:
        return {
            ...state,
            amazonPaySessionId
        };
    case UPDATE_AMAZON_PAY_EMAIL:
        return {
            ...state,
            email
        };
    case LOAD_AMAZON_PAY_CONFIG:
        return {
            ...state,
            isLoading
        };
    case UPDATE_STRIPE_PAYMENT_REQUEST:
        const { pr, isApplePay, isGooglePay } = options;
        return {
            ...state,
            options
        };
    default:
        return state;
    }
};

export default AmazonPayReducer;
