/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-magic-numbers */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable consistent-return */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable fp/no-let */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import {
    get, isEmpty, map, replace
} from 'lodash';
import PropTypes from 'prop-types';
import {
    cloneElement,
    PureComponent
} from 'react';
import OwlCarousel from 'react-owl-carousel';
import Popup from 'reactjs-popup';

import Breadcrumbs from 'Component/Breadcrumbs';
import BrochureSearch from 'Component/BrochureSearch';
import CloseIcon from 'Component/CloseIcon';
import CmsSlider from 'Component/CmsSlider/CmsSlider';
import Field from 'Component/Field';
import Form from 'Component/Form';
import FRSlider from 'Component/FRSlider/FRSlider';
import Html from 'Component/Html';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import ReturnsForm from 'Component/ReturnsForm';
import {
    BREADCRUMBS_TYPE
} from 'Component/Router/Router.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import WebshopForm from 'Component/WebshopForm';
import NoMatch from 'Route/NoMatch';
import { BlockListType } from 'Type/CMS.type';
import {
    isAusStore, isIntStore, isRsaStore, isUsaStore
} from 'Util/FrontRunner/Store';

import Faq from '../../component/Faq';
import { PLEASE_SELECT, TOPICS } from './constants';

import './CmsPage.style';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

/** @namespace Pwa/Route/CmsPage/Component/CmsPage */
export class CmsPage extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isBreadcrumbsActive: PropTypes.bool,
        page: BlockListType.isRequired,
        isPageLoaded: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isBreadcrumbsActive: true
    };

    state = {
        email: '',
        checked: true,
        guideforSku: '',
        retunesFrom: false,
        webshopFrom: false
    };

    componentDidMount() {
        const pName = window.location.pathname;
        if (pName.includes('/installation-guides')) {
            this.skuChangeInUrl();
        }
    }

    componentDidUpdate() {
        this.detectButtonClick();
        const pName = window.location.pathname;
        const BreadFinancialObj = window.BreadPayments;

        if (pName && pName.includes('/bread-finance')) {
            const noProductPlacement = {
                allowCheckout: false,
                domID: 'bread-finance-btn'
            };

            const noProductPlacement2 = {
                allowCheckout: false,
                domID: 'bread-finance-btn-2'
            };

            const placement = [
                noProductPlacement,
                noProductPlacement2
            ];

            if (BreadFinancialObj) {
                BreadFinancialObj.setup({
                    integrationKey: 'c984854e-0341-4758-807e-3ced2d9c97c1'
                });

                BreadFinancialObj.setInitMode('manual'); // puts Bread into manual mode, is 'auto' by default

                // callback runs after customer application result
                BreadFinancialObj.on('INSTALLMENT:APPLICATION_DECISIONED', (installmentResult) => {
                    console.log(installmentResult);
                });
                // callback runs after customer completes checkout
                BreadFinancialObj.on('INSTALLMENT:APPLICATION_CHECKOUT', (installmentResult) => {
                    console.log(installmentResult);
                });

                BreadFinancialObj.registerPlacements(placement);

                BreadFinancialObj.init(); // call the 'init' function when you want Bread to render on the page
            }
        }
    }

    __construct(props) {
        super.__construct(props);
        this.state = {
            search: '',
            checked: true,
            retunesFrom: false,
            webshopFrom: false
        };
    }

    [BREADCRUMBS_TYPE] = [
        {
            component: <Breadcrumbs />,
            position: 30
        }
    ];

    renderHeading() {
        const { page: { content_heading } } = this.props;

        if (!content_heading) {
            return null;
        }
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth'
        // });

        return (
            <h1 block="CmsPage" elem="Heading">
                <TextPlaceholder content={ content_heading } />
            </h1>
        );
    }

    getSliderContent = (gallery) => {
        const {
            button_text,
            button_url,
            content_bottom,
            content_bottom_color,
            content_bottom_size,
            content_position,
            content_position_vertical,
            content_top,
            content_top_color,
            content_top_size
        } = gallery;

        const topTextShadowClass = ((content_top_color === '#000') || (content_top_color === '#000000')) ? '' : 'text-shadow';
        const bottomTextShadowClass = ((content_bottom_color === '#000') || (content_bottom_color === '#000000')) ? '' : 'text-shadow';

        return (
        <div
          className={ `content ${content_position} ${content_position_vertical}` }
          style={ { verticalAlign: content_position_vertical, textAlign: content_position } }
        >
          <div
            className={ `fr_slider_content_top ${topTextShadowClass}` }
            style={ { color: content_top_color, size: content_top_size } }
          >
            <Html content={ content_top } />
          </div>
          <div
            className={ `fr_slider_content_bottom ${bottomTextShadowClass}` }
            style={ { color: content_bottom_color, size: content_bottom_size } }
          >
            <Html content={ content_bottom } />
          </div>
          { !isEmpty(button_url) && (
            <div className="link">
              <Link
                block="Homepage"
                elem="Link btn btn-primary"
                to={ button_url }
              >
                { button_text }
              </Link>
            </div>
          ) }
        </div>
        );
    };

    addBase = (src) => {
        const baseUrl = window.location.origin;
        let imgUrl = '';
        imgUrl = `${baseUrl }/${ src}`;

        return imgUrl;
    };

    renderSliderImages = (gallery) => (map(gallery, (g, key) => {
        const mobile_image = this.addBase(g.image_mobile);
        const desktop_image = this.addBase(g.image);
        const contentDiv = this.getSliderContent(g);
        const sliderId = g.slider_category_id;

        let clsName = 'item';
        if (sliderId && sliderId === 14) {
            clsName = 'item_14';
        }

        return (
          <div className={ clsName } key={ key }>
            { contentDiv }
            <img srcSet={ `${mobile_image} 1200w, ${desktop_image} 1920w` } loading="lazy" />
          </div>
        );
    }));

    renderSlider = (gallery) => (
        <OwlCarousel
          className="owl-theme"
          margin={ 0 }
          nav={ false }
          items={ 1 }
          loop="true"
          dots={ false }
          autoplay
          autoplayTimeout={ 3000 }
          autoplayHoverPause
          lazyLoad
        >
          { this.renderSliderImages(gallery) }
        </OwlCarousel>
    );

    brochureSearch = (search) => this.setState({ search });

     escapeRegExp=(string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

     /* Define functin to find and replace specified term with replacement string */
      replaceAll=(str, term, replacement) => str.replace(new RegExp(this.escapeRegExp(term), 'g'), replacement);

    preProcessContent = (rawContent) => {
        const { search } = this.state;
        const { isLoading, page: { slider_data = {}, brochure_data = {} } } = this.props;

        let content = rawContent;
        // remove target="_blank"
        content = replace(content, /target\s*?=\s*?["']_blank["']\s*?/g, '');
        // Add Parts Finder
        content = replace(content, /{{pwa_partsfinder}}/g, '<VehicleFinder></VehicleFinder>');

        // Update single image present on Jobs Page
        content = this.replaceAll(content, '<img class="owl-lazy" data-src=', '<img class="owl-lazy" src=');

        // temporary
        content = replace(content, /http:\/\/localpwa\.frontrunneroutfitters\.com/g, 'http://scandipwa.local');
        // Replace search form on brochuer page
        // content = replace(
        //   content,
        //   /<div[^>]*>(?:(?!<\/?div\b).)*?\bfr-search-brochures-btn\b(?:(?!<\/?div\b).)*<\/div>/si,
        //   '<BrochureSearch></BrochureSearch>'
        // )

        // content = replace(
        //   content,
        //   /<div[^>]*>(?:(?!<\/?div\b).)*?\bbrochure-container\b(?:(?!<\/?div\b).)*<\/div>/si,
        //   'yash yash'
        // )

        if (slider_data != null && slider_data !== undefined) {
            const { sliderdata = [] } = slider_data;
            if (sliderdata && sliderdata.length > 0) {
                for (let i = 0; i < sliderdata.length; i++) {
                    const itemobj = sliderdata[i];
                    const { slides = [], category = {}, productslidesku = '' } = itemobj;
                    const strname = `slider_${ i}`;
                    const productstr = `product_slider_${i}`;
                    const productstrprev = `product_slider_${i - 1}`;

                    if (slides && slides.length > 0) {
                        let data = JSON.stringify(slides);
                        data = btoa(data);
                        content = replace(content, `{{${strname}}}`, `<CmsSlider slides=${data} ></CmsSlider>`);
                    }
                    if (productslidesku && !isEmpty(productslidesku)) {
                        content = replace(content, `{{${productstr}}}`, `<CmsProductSlider productsskuarray=${productslidesku} ></CmsProductSlider>`);
                        content = replace(content, `{{${productstrprev}}}`, `<CmsProductSlider productsskuarray=${productslidesku} ></CmsProductSlider>`);
                    }
                }
            }
        }

        return content;
    };

    mySlider =() => (
        <CmsSlider />
    );

    renderContent() {
        const { isLoading, page: { content, slider_data = {}, brochure_data = {} } } = this.props;
        const slides = get(slider_data, 'slides', {});
        const brochure = get(brochure_data, 'brochure', {});

        if (isLoading || (!content && isEmpty(brochure))) {
            return (
                <>
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                </>
            );
        }

        if (!isLoading && !content && isEmpty(brochure)) {
            return null;
        }

        if (!isEmpty(brochure)) {
            return <BrochureSearch data={ brochure } />;
        }

        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth'
        // });
        // let updatedContent = content.replaceAll('id="bread-promo-1"', 'id="bread-finance-btn"');
        // updatedContent = content.replaceAll('class="bread-promo btn btn-primary"', 'class="bread-promo btn btn-primary" id="bread-finance-btn"');
        return (
            <>
            { !isEmpty(slides) && this.renderSlider(slides) }
            <Html
              content={ this.preProcessContent(content) }
            />
            { /* <Html content={ <VehicleFinder /> } /> */ }
            </>
        );
    }

    renderProducts=(mProducts = []) => {
        if (isEmpty(mProducts)) {
            return;
        }

        // <HorizontalSlider title="Menu 1" data={items} width={320} height={300} id="1" />
        return (
                  <FRSlider
                    data={ mProducts.items }
                    isRelated={ false }
                    id="1"
                  />
        );
    };

    handleEmailChange = (email) => {
        this.setState({ email });
    };

    newsLetterSignup = () => {
        const { email, firstname = '', lasttname = '' } = this.state;
        const { newsletterSubscribe, showErrorNotification } = this.props;
        if (isEmpty(email)) {
            showErrorNotification(__('Invalid Email Address'));
        } else {
            newsletterSubscribe({ email, firstname, lasttname });
        }
    };

    renderNewsLetterBlock =() => {
        const { email } = this.state;
        const { isNewsletterLoading, onSubmitSuccess = () => {} } = this.props;
        const pName = window.location.pathname;
        let needToShow = false;
        if (pName && pName.includes('vehicles/2020-land-rover-defender-roof-rack')) {
            needToShow = true;
        }

        if (!needToShow) {
            return null;
        }

        return (
        <div className="social_newsletter_cont">
          <Loader isLoading={ isNewsletterLoading } />
          <div className="social_newsletter_wrap">
            <div className="title_1"><h2>{ __('STAY TUNED') }</h2></div>
            <div className="title_2"><p>{ __('Be the first to be in the know for all new gear and latest developments') }</p></div>

            <div>
                <Form
                  key="email-friend"
                  mix={ { block: 'EmailFriend' } }
                  onSubmit={ this.newsLetterSignup }
                  onSubmitSuccess={ onSubmitSuccess }
                >
                <Field
                  type="email"
                  placeholder="Your email address"
                  id="email"
                  name="email"
                //   validation={ ['notEmpty', 'email'] }
                  value={ email }
                  onChange={ this.handleEmailChange }
                />
                <button className="btn btn-primary">
                    { __('Sign up') }
                </button>
                </Form>
            </div>
          </div>
        </div>
        );
    };

    getSelectOptions = (options) => map(options, (option) => ({
        ...option,
        id: option.value
    }));

    handleSelectVehicleChange=(_value) => {
        const { handleSelectVehicleChange } = this.props;
        const { checked = true } = this.state;
        const chk = !(checked);
        handleSelectVehicleChange(chk);
        this.setState({ checked: chk });
    };

  handleSubscriptionChange= (_value) => {
      const { handleSubscriptionChange, isCheckedSubscription } = this.props;
      const chk = !(isCheckedSubscription);
      handleSubscriptionChange(chk);
  };

  renderButton() {
      return (
        <button
          block="Contact"
          elem="Button"
          mix={ { block: 'Button' } }
        >
          { __('Submit') }
        </button>
      );
  }

  renderContactForm() {
      const {
          handleNameChange,
          handleEmailChange,
          handleContactChange,
          handleTopicChange,
          handleSelectVehicleChange,
          handleYearsDataChange,
          handleMakesDataChange,
          handleModelDataChange,
          handleOptionsDataChange,
          handleSubscriptionChange,
          handleMessageChange,
          handleFilesChange,
          handleCaptchaChange,
          formData = {},
          content = {},
          yearsData = [],
          makesData = [],
          modelsData = [],
          optionsData = [],
          isChecked,
          isCheckedSubscription,
          isSubmitted = false
      } = this.props;

      const {
          name = '',
          email = '',
          contact = '',
          topic = '',
          selectvehicle = false,
          year = '',
          make = '',
          model = '',
          partOption = '',
          message = '',
          newsletter_subscription = '',
          files = ''
      } = formData;

      const { checked = true } = this.state;

      const yearsSelectData = [PLEASE_SELECT, ...yearsData];

      const isMakesDisabled = isEmpty(makesData);
      const makesSelectData = (isMakesDisabled) ? [] : [PLEASE_SELECT, ...makesData];

      const isModelsDisabled = isEmpty(modelsData);
      const modelsSelectData = (isModelsDisabled) ? [] : [PLEASE_SELECT, ...modelsData];

      const isOptionsDisabled = isEmpty(optionsData);
      // const optionsSelectData = (isOptionsDisabled) ? [] : [PLEASE_SELECT, ...optionsData];
      const optionsSelectData = (isOptionsDisabled) ? [] : optionsData;

      return (
          <div
            block="ProductQAForm"
            elem="Wrapper"
          >
            <div
              block="ProductQAForm"
              elem="Content"
            >
              <Field
                type="text"
                label={ __('Name') }
                id="name"
                name="name"
                validation={ ['notEmpty'] }
                value={ name }
                onChange={ handleNameChange }
                validateSeparately={ isSubmitted }
              />
              <Field
                type="email"
                label={ __('Email Address') }
                id="email"
                name="email"
                validation={ ['notEmpty', 'email'] }
                value={ email }
                onChange={ handleEmailChange }
                validateSeparately={ isSubmitted }
              />
              <Field
                type="tel"
                label={ __('Phone number') }
                id="contact"
                name="contact"
                value={ contact }
                onChange={ handleContactChange }
                validateSeparately={ isSubmitted }
              />
              <Field
                type="select"
                label={ __('Reference') }
                id="topic"
                name="topic"
                sorting="0"
                validation={ ['notEmpty'] }
                value={ topic }
                onChange={ handleTopicChange }
                selectOptions={ this.getSelectOptions(TOPICS) }
              />
              <Field
                type="checkbox vehicle"
                label={ __('Shop By Vehicle') }
                validation={ ['notEmpty'] }
              />
              <div className="cnt_vehicle">
             { (checked) && (
              <Field
                type="select"
                id="year"
                name="year"
                sorting="0"
                value={ year }
                onChange={ handleYearsDataChange }
                selectOptions={ this.getSelectOptions(yearsSelectData) }
              />
             ) }
               { (checked) && (
                <Field
                  type="select"
                  id="make"
                  isDisabled={ isMakesDisabled }
                  name="make"
                  sorting="0"
                  value={ make }
                  onChange={ handleMakesDataChange }
                  selectOptions={ this.getSelectOptions(makesSelectData) }
                />
               ) }

               { (checked) && (
                <Field
                  type="select"
                  id="model"
                  isDisabled={ isModelsDisabled }
                  name="model"
                  sorting="0"
                  value={ model }
                  onChange={ handleModelDataChange }
                  selectOptions={ this.getSelectOptions(modelsSelectData) }
                />
               ) }
               { (checked) && (
                  <Field
                    type="select"
                    id="partOption"
                    isDisabled={ isOptionsDisabled }
                    name="partOption"
                    sorting="0"
                    value={ partOption }
                    onChange={ handleOptionsDataChange }
                    selectOptions={ this.getSelectOptions(optionsSelectData) }
                  />
               ) }
              </div>
              <Field
                type="textarea"
                label={ __('Message') }
                id="message"
                name="message"
                validation={ ['notEmpty'] }
                value={ message }
                onChange={ handleMessageChange }
                validateSeparately={ isSubmitted }
              />
              <Field
                type="checkbox"
                label={ __('Subscribe to the free newsletter') }
                id="newsletter_subscription"
                name="newsletter_subscription"
                value={ isCheckedSubscription }
                onChange={ this.handleSubscriptionChange }
              />
              { /* <ReCaptcha
                sitekey={ window.storeConfig.reCaptchaKey }
                // action='action_name'
                verifyCallback={ handleCaptchaChange }
              /> */ }
            </div>
          </div>
      );
  }

  renderContForm() {
      const {
          onSubmitAttempt,
          onSubmitSuccess,
          onError,
          showValidationError = 'noError'
      } = this.props;
      const pName = window.location.pathname;

      let needToShow = false;
      if (pName && pName.includes('/workshop')) {
          needToShow = true;
      }

      if (!needToShow) {
          return null;
      }

      return (
          <div className="contact_form">
              <Form
                key="contact"
                mix={ { block: `Contact ${ showValidationError}` } }
                onSubmit={ onSubmitAttempt }
                onSubmitSuccess={ onSubmitSuccess }
                onSubmitError={ onError }
              >
                    { this.renderContactForm() }
                    { this.renderButton() }
              </Form>
          </div>
      );
  }

  skuChangeInUrl() {
      const urlName = window.location.search;
      let skuName = '';
      if (urlName.includes('?sku=')) {
          skuName = urlName.split('=').filter(Boolean).pop();
          this.setState({
              guideforSku: skuName
          });
          setTimeout(() => {
              this.requestGuideForSku();
          }, 1000);
      }
  }

  handleSkuChange = (event) => {
      event.persist();
      this.setState({
          guideforSku: event.target.value
      });
  };

  requestGuideForSku =(event) => {
      const { requestGuideForSku } = this.props;
      const { guideforSku, prevsku = '' } = this.state;
      if (isEmpty(guideforSku) || prevsku == guideforSku) {
          return;
      }
      this.setState({ prevsku: guideforSku });
      requestGuideForSku(guideforSku);
  };

  renderInstGuidesSearch() {
      const pName = window.location.pathname;
      const { guideforSku } = this.state;
      const { error = '', installationLoading = false } = this.props;

      let needToShow = false;
      if (pName && pName.includes('/installation-guides')) {
          needToShow = true;
      }
      if (!needToShow) {
          return null;
      }

      return (
        <div className="installation-guides">
          <div id="ftg-search-sku">
              <input
                type="text"
                id="sku-val"
                name="sku"
                value={ guideforSku }
                placeholder={ __('Enter a product SKU') }
                onChange={ this.handleSkuChange }
                onBlur={ this.requestGuideForSku }
                mix={ { block: 'SkuCode', elem: 'Input' } }
              />
              <button id="search-sku" className="btn btn-primary">{ __('Search') }</button>
          </div>
            <Loader isLoading={ installationLoading } />
            <div id="ftg-results-loading">{ __('Loading...') }</div>
            { (!isEmpty(error)) && (
                <div className="errorMessage" id="ftg-results-loading">
                    { error }
                </div>
            ) }
            { this.renderInstalationsItems() }
        </div>
      );
  }

  renderInstalationsItems() {
      const { installationguides = [] } = this.props;
      if (!installationguides || installationguides.length < 1) {
          return null;
      }

      return (
          <div className="GuideItem">
            { installationguides.map((item) => (
                this.renderInstallationGuidesItem(item)
            )) }
          </div>
      );
  }

  renderInstallationGuidesItem = (item) => {
      // eslint-disable-next-line no-unused-vars
      const {
          code,
          daysAgo,
          downloadUrl,
          filename,
          filesize,
          id,
          previewUrl,
          thumbUrl
      } = item;

      return (
        <div key={ id } className="ft-block">
            <div className="ft-block-title">
              { code }
            </div>
            <div className="ft-block-image">
            <Link
              block="Pdf"
              elem="Link"
              to={ downloadUrl }
              target="_blank"
            >
                <img src={ previewUrl } alt="pdf" />
            </Link>
            </div>
            <div className="ft-block-content">
            <Link
              block="btn btn"
              elem="primary"
              to={ downloadUrl }
              target="_blank"
            >
                { __('Download') }
            </Link>
            </div>
        </div>
      );
  };

  renderBreadPay() {
      const pName = window.location.pathname;
      let needToShow = false;
      if (pName && pName.includes('/bread-finance')) {
          needToShow = true;
      }
      if (!needToShow) {
          return null;
      }

      return (
    <div id="bred_div" className="bread-checkout-btn">
      <div id="bread-finance-btn" />
    </div>
      );
  }

  renderItemsOfType(type) {
      return this.getSortedItems(type)
          .map(({ position, component }) => cloneElement(component, { key: position }));
  }

  getSortedItems(type) {
      return this[type].sort(
          (a, b) => a.position - b.position
      ).filter(
          (entry) => {
              if (!entry.component) {
                  // eslint-disable-next-line no-console
                  console.warn('There is an item without a component property declared in main router.');
                  return false;
              }

              return true;
          }
      );
  }

  clickReturnsFormPopup = () => {
      const { retunesFrom } = this.state;
      const bTag = document.getElementsByTagName('body');
      this.setState({ retunesFrom: true });
      bTag[0].style.overflowY = 'hidden';
  };

  closeRetunForm = () => {
      const { retunesFrom } = this.state;
      const bTag = document.getElementsByTagName('body');
      this.setState({ retunesFrom: false });
      bTag[0].style.overflowY = 'unset';
  };

  clickWebshopFormPopup = () => {
      const { webshopFrom } = this.state;
      const bTag = document.getElementsByTagName('body');
      if (!webshopFrom) {
          this.setState({ webshopFrom: true });
          bTag[0].style.overflowY = 'hidden';
      } else {
          this.setState({ webshopFrom: false });
          bTag[0].style.overflowY = 'unset';
      }
  };

  returnPopupVisiblity(isVisible) {
      this.setState({ retunesFrom: isVisible });
  }

  webshopPopupVisiblity(isVisible) {
      this.setState({ retunesFrom: isVisible });
  }

    // render ReturnsForm Popup
    renderReturnsFormPopup = () => {
        const { retunesFrom } = this.state;
        return (
            <Popup
              open={ retunesFrom }
              className="ReturnsForm_Popup_wrap"
              closeOnDocumentClick={ false }
              closeOnEscape={ false }
            >
                <div className="cross_subs_popup">
                    <button
                      key="close"
                      block="Header"
                      elem="Button"
                      mods={ { type: 'close' } }
                      onClick={ this.closeRetunForm.bind(this) }
                      aria-label="Close"
                    >
                        <CloseIcon />
                    </button>
                </div>
            <div className="PopUpWrap">
                <ReturnsForm isVisible={ this.returnPopupVisiblity.bind(this) } />
            </div>
            </Popup>
        );
    };

    renderReturnsFormButton() {
        if (isUsaStore() || isRsaStore() || isIntStore() || isAusStore()) {
            const pName = window.location.pathname;
            if (pName && pName.includes('/return-rights')) {
                return (
                    <button className="btn btn-primary returns_btn" onClick={ this.clickReturnsFormPopup }>
                        { __('Return Request') }
                    </button>
                );
            }
        }

        return null;
    }

    // render Webshop Fitment Requests Popup
    renderWebshopFitmentFormPopup = () => {
        const { webshopFrom } = this.state;
        return (
            <Popup
              open={ webshopFrom }
              className="ReturnsForm_Popup_wrap"
              closeOnDocumentClick={ false }
              closeOnEscape={ false }
            >
                <div className="cross_subs_popup">
                    <button
                      key="close"
                      block="Header"
                      elem="Button"
                      mods={ { type: 'close' } }
                      onClick={ this.clickWebshopFormPopup }
                      aria-label="Close"
                    >
                        <CloseIcon />
                    </button>
                </div>
            <div className="PopUpWrap">
                <WebshopForm isVisible={ this.webshopPopupVisiblity.bind(this) } />
            </div>
            </Popup>
        );
    };

    renderWebshopFitmentFormButton() {
        if (!isUsaStore()) {
            return null;
        }

        const pName = window.location.pathname;
        if (pName && pName.includes('/about-us')) {
            return (
                <button className="btn btn-primary btn-Webshop-Fitment" onClick={ this.clickWebshopFormPopup }>
                    Webshop Fitment Requests
                </button>
            );
        }

        return null;
    }

    detectButtonClick() {
        const returnRequestBtn = document.getElementById('return_request_btn');
        if (returnRequestBtn) {
            returnRequestBtn.addEventListener('click', () => {
                this.clickReturnsFormPopup();
            });
        }
    }

    renderVacanciesBlock() {
        const pName = window.location.pathname;
        const { vacancies = {} } = this.props;
        const { vacanciesList = {} } = vacancies;
        const { content = {} } = vacanciesList;
        if (pName && pName.includes('/jobs') && !isEmpty(content)) {
            return (
                <div className="container vacanciesWrap">
                    <div className="accordion">
                        <Html content={ content } />
                    </div>
                </div>
            );
        }

        return null;
    }

    renderFaqBlock() {
        const pName = window.location.pathname;
        if (pName && pName.includes('/rewards')) {
            return (
                <div className="rewardsFAQ container">
                    <Faq />
                </div>
            );
        }

        return null;
    }

    render() {
        const {
            page,
            isBreadcrumbsActive,
            isLoading,
            isPageLoaded,
            location: { pathname = '' }
        } = this.props;
        // const { page_width } = page;
        const pageName = pathname.replace(/\//g, '-');

        if (!isLoading && !isPageLoaded) {
            return <NoMatch />;
        }
        if (isLoading) {
            return <Loader isLoading={ isLoading } />;
        }

        return (
            <>
        <main
          block="CmsPage CmsPage"
          mods={ { isBreadcrumbsHidden: !isBreadcrumbsActive } }
          elem={ pageName }
        >
            <div className="CmsPageCont">
                <div block="CmsPage" elem="Wrapper">
                    { this.renderHeading() }
                    <div block="CmsPage" elem="Content">
                        { this.renderContent() }
                        { this.renderNewsLetterBlock() }
                        { this.renderContForm() }
                        { this.renderInstGuidesSearch() }
                        { this.renderBreadPay() }
                        { this.renderReturnsFormButton() }
                        { this.renderReturnsFormPopup() }
                        { this.renderVacanciesBlock() }
                        { this.renderFaqBlock() }
                        { /* { this.renderWebshopFitmentFormButton() } */ }
                        { /* { this.renderWebshopFitmentFormPopup() } */ }
                    </div>
                </div>
            </div>
        </main>
        { this.renderItemsOfType(BREADCRUMBS_TYPE) }
            </>
        );
    }
}

export default CmsPage;
