/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import { renderLink } from 'Util/Helper';

import './AccordionWrapper.style.scss';
// import 'react-accessible-accordion/dist/fancy-example.css'

/** @namespace Pwa/Component/AccordionWrapper/Component/AccordionWrapper */
export class AccordionWrapper extends PureComponent {
  static propTypes = {
      allowMultipleExpanded: PropTypes.bool,
      data: PropTypes.array
  };

  static defaultProps = {
      allowMultipleExpanded: false,
      data: []
  };

  getItemState({ expanded }) {
      return (
      <div className="toggle-nav">
        { expanded ? '-' : '+' }
      </div>
      );
  }

  renderAccordionItem = ({
      title, child, borderLeftColor = '', url, url_resolver = {}, onHeaderOverMenuClick
  }, key) => {
      const buttonClass = `${borderLeftColor} accordion__button`;
      return (
      <AccordionItem key={ key } uuid={ url }>
        <AccordionItemHeading>
          <AccordionItemButton className={ buttonClass }>
            { renderLink({
                url, title, url_resolver, onHeaderOverMenuClick
            }) }
            <AccordionItemState>
                { this.getItemState }
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          { child }
        </AccordionItemPanel>
      </AccordionItem>
      );
  };

  renderAccordian = () => {
      const {
          data, allowMultipleExpanded, onChange = () => {}, preExpanded = [], onHeaderOverMenuClick
      } = this.props;

      data[0].onHeaderOverMenuClick = onHeaderOverMenuClick;

      return (
      <Accordion
        allowMultipleExpanded={ allowMultipleExpanded }
        allowZeroExpanded
        onChange={ onChange }
        preExpanded={ preExpanded }
      >
        { map(data, this.renderAccordionItem) }
      </Accordion>
      );
  };

  render = this.renderAccordian;
}

export default AccordionWrapper;
