/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// eslint-disable-next-line simple-import-sort/sort
import {
    combineReducers,
    createStore
} from 'redux';

import { CookiesReducer } from 'Store/Cookies/Cookies.reducer';

import HomePageReducer from './HomePage/HomePage.reducer';
import { CustomerGarageReducer } from './CustomerGarage';
import { VehicleFinderReducer } from './VehicleFinder';
import { HeaderAndFooterReducer } from './HeaderAndFooter';
import { NewsletterSubscriptionReducer } from './NewsletterSubscription';
import { FooterReducer } from './Footer';
import UrlRewritesReducer from './UrlRewrites/UrlRewrites.reducer';
import NotifyMeReducer from './NotifyMe/NotifyMe.reducer';
import CmsPageReducer from './CmsPage/CmsPage.reducer';
import AdventurePageReducer from './AdventurePage/AdventurePage.reducer';
import ContactPageReducer from './ContactPage/ContactPage.reducer';
import CustomerInterestsReducer from './CustomerInterests/CustomerInterests.reducer';
import CustSubscriptionsReducer from './CustSubscriptions/CustSubscriptions.reducer';
import { CreditcardReducer } from './Creditcard';
import { StripePaymentReducer } from './SavedCard';
import { AuthnetReducer } from './Authnet';

import { PostalCodeLookUpReducer } from './PostalCodeLookUp';
import { AddToCartPopUpReducer } from './AddToCartPopUp';
import CustomerQuotationsReducer from './CustomerQuotations/CustomerQuotations.reducer';
import CompanyReducer from './CompanyAccount/CompanyAccount.reducer';
import CompanyCreditReducer from './CompanyCredit/CompanyCredit.reducer';
import CompanyStructureReducer from './CompanyStructure/CompanyStructure.reducer';
import CompanyUsersReducer from './CompanyUsers/CompanyUsers.reducer';
import CompanyRolesReducer from './CompanyRoles/CompanyRoles.reducer';
import CompanyPriceListReducer from './CompanyPriceList/CompanyPriceList.reducer';

import BreadcrumbsReducer from 'Store/Breadcrumbs/Breadcrumbs.reducer';
import CartReducer from 'Store/Cart/Cart.reducer';
import CategoryReducer from 'Store/Category/Category.reducer';
import CheckoutReducer from './Checkout/Checkout.reducer';
import ConfigReducer from './Config/Config.reducer';
import ContactFormReducer from 'Store/ContactForm/ContactForm.reducer';
import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import MetaReducer from 'Store/Meta/Meta.reducer';
import MyAccountReducer from 'Store/MyAccount/MyAccount.reducer';
import NavigationReducer from 'Store/Navigation/Navigation.reducer';
import NoMatchReducer from 'Store/NoMatch/NoMatch.reducer';
import NotificationReducer from 'Store/Notification/Notification.reducer';
import OfflineReducer from 'Store/Offline/Offline.reducer';
import OrderReducer from 'Store/Order/Order.reducer';
import OverlayReducer from 'Store/Overlay/Overlay.reducer';
import PopupReducer from 'Store/Popup/Popup.reducer';
import ProductReducer from 'Store/Product/Product.reducer';
import ProductCompareReducer from 'Store/ProductCompare/ProductCompare.reducer';
import ProductListReducer from 'Store/ProductList/ProductList.reducer';
import ProductListInfoReducer from 'Store/ProductListInfo/ProductListInfo.reducer';
import RecentlyViewedProductsReducer from 'Store/RecentlyViewedProducts/RecentlyViewedProducts.reducer';
import SearchBarReducer from 'Store/SearchBar/SearchBar.reducer';
import WishlistReducer from 'Store/Wishlist/Wishlist.reducer';
import AmazonPayReducer from 'Store/AmazonPay/AmazonPay.reducer';
import { GearzettePageReducer } from 'Store/GearzettePage';
import QuickOrderReducer from 'Store/QuickOrder/QuickOrder.reducer';
import MaintenanceReducer from 'Store/Maintenance/Maintenance.reducer';
import CompanyStatementsReducer from 'Store/CompanyStatements/CompanyStatements.reducer';
import MyAffiliateAccountReducer from 'Store/MyAffiliateAccount/MyAffiliateAccount.reducer';
import GoogleRecaptchaReducer from 'Store/GoogleRecaptcha/GoogleRecaptcha.reducer';
import { TermsAndConditionsReducer } from 'Store/TermsAndConditions/TermsAndConditions.reducer';
import ShippingReducer from 'Store/Shipping/Shipping.reducer';

/** @namespace Pwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    CategoryReducer,
    NotificationReducer,
    BreadcrumbsReducer,
    ProductReducer,
    ProductListReducer,
    ProductListInfoReducer,
    CartReducer,
    OrderReducer,
    WishlistReducer,
    NoMatchReducer,
    SearchBarReducer,
    MyAccountReducer,
    NavigationReducer,
    OverlayReducer,
    OfflineReducer,
    PopupReducer,
    UrlRewritesReducer,
    ConfigReducer,
    MetaReducer,
    LinkedProductsReducer,
    CheckoutReducer,
    ContactFormReducer,
    RecentlyViewedProductsReducer,
    ProductCompareReducer,
    HomePageReducer,
    CustomerGarageReducer,
    VehicleFinderReducer,
    HeaderAndFooterReducer,
    NewsletterSubscriptionReducer,
    FooterReducer,
    NotifyMeReducer,
    CmsPageReducer,
    AdventurePageReducer,
    ContactPageReducer,
    CustomerInterestsReducer,
    CustSubscriptionsReducer,
    CreditcardReducer,
    PostalCodeLookUpReducer,
    StripePaymentReducer,
    AuthnetReducer,
    AddToCartPopUpReducer,
    CustomerQuotationsReducer,
    CompanyReducer,
    CompanyCreditReducer,
    CompanyStructureReducer,
    CompanyRolesReducer,
    CompanyUsersReducer,
    CompanyPriceListReducer,
    AmazonPayReducer,
    GearzettePageReducer,
    CookiesReducer,
    QuickOrderReducer,
    MaintenanceReducer,
    CompanyStatementsReducer,
    MyAffiliateAccountReducer,
    GoogleRecaptchaReducer,
    TermsAndConditionsReducer,
    ShippingReducer
});

export function createReducer(asyncReducers) {
    return combineReducers({
        ...getStaticReducers(),
        ...asyncReducers
    });
}

export const store = createStore(
    createReducer(),
    ( // enable Redux dev-tools only in development
        process.env.NODE_ENV === 'development'
        && window.__REDUX_DEVTOOLS_EXTENSION__
    ) && window.__REDUX_DEVTOOLS_EXTENSION__({
        trace: true
    })
);

/**
 * Configure the store
 * @namespace Pwa/Store/Index/configureStore
 * */
export function configureStore() {
    // Add a dictionary to keep track of the registered async reducers
    store.asyncReducers = {};

    // Create an inject reducer function
    // This function adds the async reducer, and creates a new combined reducer
    store.injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(store.asyncReducers));
    };

    // Return the modified store
    return store;
}

export function getStore() {
    return store;
}

export default function injectStaticReducers(store) {
    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
