/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_GEAR_PAGE_POSTS = 'UPDATE_GEAR_PAGE_POSTS';
export const UPDATE_GEAR_PAGE_CATEGORIES = 'UPDATE_GEAR_PAGE_CATEGORIES';
export const UPDATE_GEAR_PAGE_RECENTS_POSTS = 'UPDATE_GEAR_PAGE_RECENT_POSTS';

export const SET_LOADING_PAGE = 'GEAR_PAGE_LOADING';
export const SET_LOADING_DETAILS_PAGE = 'GEAR_PAGE_DETAILS_LOADING';
export const UPDATE_GEAR_PAGE_POST_DETAILS = 'UPDATE_GEAR_PAGE_POSTS_DETAILS';
export const UPDATE_GEARZETTE_POST_DETAIL_RESOLVER = 'UPDATE_GEARZETTE_POST_DETAIL_RESOLVER';

/**
 * Update CMS Page information
 * @param {String} urlKey URL Key of the page that must be returned
 * @namespace Pwa/Store/GearzettePage/Action/updateGearzettePagePosts */
export const updateGearzettePagePosts = (posts, isLoading) => ({
    type: UPDATE_GEAR_PAGE_POSTS,
    posts,
    isLoading
});

/**
 * Update CMS Page information
 * @param {String} urlKey URL Key of the page that must be returned
 * @namespace Pwa/Store/GearzettePage/Action/updateGearzettePageCategories */
export const updateGearzettePageCategories = (categories) => ({
    type: UPDATE_GEAR_PAGE_CATEGORIES,
    categories
});

/**
 * Update CMS Page information
 * @param {String} urlKey URL Key of the page that must be returned
 * @namespace Pwa/Store/GearzettePage/Action/updateGearzettePageRecentsPost */
export const updateGearzettePageRecentsPost = (recentsPost, isLoading) => ({
    type: UPDATE_GEAR_PAGE_RECENTS_POSTS,
    recentsPost,
    isLoading
});

/** @namespace Pwa/Store/GearzettePage/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_LOADING_PAGE,
    isLoading
});

/**
 * Update CMS Page information
 * @param {String} urlKey URL Key of the page that must be returned
 * @namespace Pwa/Store/GearzettePage/Action/updateGearzettePagePostDetails */
export const updateGearzettePagePostDetails = (postDetails, isPostDetailsLoading) => ({
    type: UPDATE_GEAR_PAGE_POST_DETAILS,
    postDetails,
    isPostDetailsLoading
});

/** @namespace Pwa/Store/GearzettePage/Action/setPostDetailsLoading */
export const setPostDetailsLoading = (isPostDetailsLoading) => ({
    type: SET_LOADING_DETAILS_PAGE,
    isPostDetailsLoading
});

/** @namespace Pwa/Store/GearzettePage/Action/updateGearzettePostDetailResolver */
export const updateGearzettePostDetailResolver = (postDetailResolver) => ({
    type: UPDATE_GEARZETTE_POST_DETAIL_RESOLVER,
    postDetailResolver
});
