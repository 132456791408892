/* eslint-disable react/jsx-no-bind */
/* eslint-disable fp/no-let */
/* eslint-disable global-require */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import { isEmpty } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import Cookies from 'universal-cookie';

import Link from 'Component/Link';
import CheckoutQuery from 'Query/Checkout.query';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchMutation } from 'Util/Request';

export const SELECTED_VEHICLE_LOCALSTORAGE = 'selected_vehicle';
/** @namespace Pwa/Util/Helper/Index/scrollToTop */
export const scrollToTop = () => window.scrollTo({ behavior: 'smooth', top: 0 });

export const cookies = new Cookies();
/** @namespace Pwa/Util/Helper/Index/renderLink */
export const renderLink = ({
    key = 'key',
    url = null,
    // onClick = () => {},
    onHeaderOverMenuClick,
    block = 'Link',
    elem = 'Link',
    title = 'Link',
    url_resolver = null
}) => {
    if (isEmpty(url)) {
        return title;
    }
    // Create a combined click handler
    const handleClick = () => {
        scrollToTop();
        if (onHeaderOverMenuClick) {
            onHeaderOverMenuClick();
        }
    };

    if (isEmpty(url_resolver)) {
        return (
    <Link
      key={ key }
      to={ url }
      onClick={ handleClick } // Use the combined handler
      block={ block }
      elem={ elem }
    >
      { title }
    </Link>
        );
    }

    return (
    <RouterLink
      key={ key }
      to={ {
          pathname: url,
          state: {
              url_resolver
          }
      } }
      onClick={ handleClick } // Use the combined handler
      block={ block }
      elem={ elem }
    >
      { title }
    </RouterLink>
    );
};

export const baseNameRegex = new RegExp('/en/za/', 'g');
/** @namespace Pwa/Util/Helper/Index/removeBaseName */
export const removeBaseName = (url) => url.replace(baseNameRegex, '');

/** @namespace Pwa/Util/Helper/Index/validateEmail */
// export const validateEmail = (email) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
export const validateEmail = (email) => /^[\w.-]+@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

/** @namespace Pwa/Util/Helper/Index/formatCleanQuery */
export const formatCleanQuery = (query) => query?.replace(/#.*\n/g, '')
    .replace(/[\s|,]*\n+[\s|,]*/g, ' ')
    .replace(/:\s/g, ':')
    .replace(/,\s/g, ',')
    .replace(/\)\s\{/g, '){');

/** @namespace Pwa/Util/Helper/Index/isProduction */
export const isProduction = () => {
    const hostUrl = window.location.href;
    if (hostUrl.includes('https://www.frontrunneroutfitters.com') || hostUrl.includes('https://frontrunneroutfitters.com')) {
        return true;
    }

    return false;
};

/** @namespace Pwa/Util/Helper/Index/isB2BUser */
export const isB2BUser = (is_b2b) => {
    if (is_b2b === undefined || (isEmpty(`${is_b2b}`) || is_b2b === 'false' || is_b2b === false)) {
        return false;
    }

    return true;
};

/** @namespace Pwa/Util/Helper/Index/formatStandardCurrencyAmount */
export const formatStandardCurrencyAmount = (inputAmount) => {
    // If inputAmount is a float value, then return it as it is
    // if (typeof inputAmount === 'number') {
    //     return inputAmount;
    // }

    // const inputAmount= '1,2.334,5';
    // for testing
    // it will remove all currency symbol too
    const amount = `${inputAmount}`.replace(/[^\d.,]/g, '');
    let unitAmount = amount;
    let fractionAmount = '';
    if (amount.length > 2 && (amount.charAt(amount.length - 3) === '.' || amount.charAt(amount.length - 3) === ',')) {
        fractionAmount = amount.substring(amount.length - 2, amount.length);
        unitAmount = amount.substring(0, amount.length - 3);
    } else if (amount.length > 2 && (amount.charAt(amount.length - 5) === '.' || amount.charAt(amount.length - 5) === ',')) {
        fractionAmount = amount.substring(amount.length - 4, amount.length - 2);
        unitAmount = amount.substring(0, amount.length - 5);
    } else if (amount.length > 1 && (amount.charAt(amount.length - 2) === '.' || amount.charAt(amount.length - 2) === ',')) {
        fractionAmount = amount.substring(amount.length - 1, amount.length);
        unitAmount = amount.substring(0, amount.length - 2);
    }
    if ((`${fractionAmount}`).length > 2) {
        fractionAmount = (`${fractionAmount}`).substring(0, 2);
    }
    unitAmount = unitAmount.replaceAll('.', '');
    unitAmount = unitAmount.replaceAll(',', '');
    const formatedAmount = parseFloat(`${unitAmount}.${fractionAmount}`);

    return formatedAmount;
};

/** @namespace Pwa/Util/Helper/Index/getCurrentUrlForOggData */
export const getCurrentUrlForOggData = () => {
    let current_url;
    if (window.location.href.includes('?')) {
        current_url = window.location.href.substring(0, window.location.href.indexOf('?'));
    } else if (window.location.href.includes('#')) {
        current_url = window.location.href.substring(0, window.location.href.indexOf('#'));
    } else {
        current_url = window.location.href;
    }

    return current_url;
};
/** @namespace Pwa/Util/Helper/Index/ContainsAllowedCountryCode */
export const ContainsAllowedCountryCode = (countryCode) => {
    let containsCountryCode = false;
    const allowedCountryCodes = [
        'AE', 'AT', 'AU', 'BE', 'BG', 'BR', 'CA',
        'CH', 'CI', 'CR', 'CY', 'CZ', 'DE', 'DK',
        'DO', 'EE', 'ES', 'FI', 'FR', 'GB', 'GI', 'GR',
        'GT', 'HK', 'HR', 'HU', 'ID', 'IE', 'IN', 'IT', 'JP', 'LI', 'LT', 'LU',
        'LV', 'MT', 'MX', 'MY', 'NL', 'NO', 'NZ', 'PE', 'PH', 'PL', 'PT',
        'RO', 'SE', 'SG', 'SI', 'SK', 'SN', 'TH', 'TT', 'US', 'UY'
    ];

    if (allowedCountryCodes.includes(countryCode)) {
        containsCountryCode = true;
    }

    return containsCountryCode;
};
/** @namespace Pwa/Util/Helper/Index/md5 */
export const md5 = require('md5');

/** @namespace Pwa/Util/Helper/Index/updatePageInCookies */
export const updatePageInCookies = (pageType) => {
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + (20 * 60 * 1000));
    cookies.set('page_type', pageType, { path: '/', expires: expiryDate });
};

/** @namespace Pwa/Util/Helper/Index/saveLocalData */
export const saveLocalData = (key, data) => {
    try {
        localStorage.setItem(key, data);
    } catch (error) {
        console.log(error);
        localStorage.removeItem(key);
    }
};

/** @namespace Pwa/Util/Helper/Index/saveSelectedVehicle */
export const saveSelectedVehicle = async (guestCartId) => {
    const selectedVehicle = BrowserDatabase.getItem(SELECTED_VEHICLE_LOCALSTORAGE);
    if (selectedVehicle) {
        try {
            await fetchMutation(CheckoutQuery.captureVehicle({
                selectedVehicle,
                guestCartId
            }));
            localStorage.setItem('selected_vehicle', '');
        } catch (err) {
            console.log(err);
            return null;
        }
    } else {
        return null;
    }

    return null;
};
