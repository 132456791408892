/* eslint-disable fp/no-let */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import { makeCancelable } from 'Util/Promise';
import { prepareQuery } from 'Util/Query';
import { executeFsyncGet, executeGet } from 'Util/Request';
import { hash } from 'Util/Request/Hash';

import { ONE_MONTH_IN_SECONDS } from './QueryDispatcher';

/** @namespace Pwa/Util/Request/DataContainer/DataContainer */
export class DataContainer extends PureComponent {
    dataModelName = 'DataContainer';

    componentWillUnmount() {
        if (this.promise) {
            this.promise.cancel();
        }
    }

    fetchData(rawQueries, onSucces = () => {}, onError = () => {}) {
        const preparedQuery = prepareQuery(rawQueries);
        const { query, variables } = preparedQuery;
        const queryHash = hash(query + JSON.stringify(variables));

        if (!window.dataCache) {
            window.dataCache = {};
        }

        if (window.dataCache[queryHash]) {
            onSucces(window.dataCache[queryHash]);
            return;
        }

        this.promise = makeCancelable(
            executeGet(preparedQuery, this.dataModelName, ONE_MONTH_IN_SECONDS)
        );

        this.promise.promise.then(
            /** @namespace Pwa/Util/Request/DataContainer/then */
            (response) => {
                window.dataCache[queryHash] = response;
                onSucces(response);
            },
            /** @namespace Pwa/Util/Request/DataContainer/then */
            (err) => onError(err)
        );
    }

    fetchFsyncData(rawQueries, endpointUrl, onSucces = () => {}, onError = () => {}) {
        // const preparedQuery = prepareQuery(rawQueries);
        // const { query, variables = {} } = preparedQuery;
        // const queryHash = hash(query + JSON.stringify(variables));

        if (!window.dataCache) {
            window.dataCache = {};
        }

        let fsyncUrl = window.storeConfig.fsync_url;
        let fsyncEndpointUrl = endpointUrl;

        // If production
        if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
            // If endpoint url contains /landingPages then change the url
            if (endpointUrl.includes('/landingPages')) {
                fsyncUrl = 'https://graphql-flp.frontrunneroutfitters.com/';
                // Remove /landingPages from endpointUrl
                fsyncEndpointUrl = endpointUrl.replace('/landingPages', '');
            }

            // If endpoint url contains /vfits then change the url
            if (endpointUrl.includes('/vfits')) {
                fsyncUrl = 'https://graphql-fvf.frontrunneroutfitters.com/';
                // Remove /landingPages from endpointUrl
                fsyncEndpointUrl = endpointUrl.replace('/vfits', '');
            }

            // If endpoint url contains /instagram then change the url
            if (endpointUrl.includes('/instagram')) {
                fsyncUrl = 'https://graphql-fin.frontrunneroutfitters.com/';
                // Remove /landingPages from endpointUrl
                fsyncEndpointUrl = endpointUrl.replace('/instagram', '');
            }

            // If endpoint url contains /gearzette then change the url
            if (endpointUrl.includes('/gearzette')) {
                fsyncUrl = 'https://graphql-fgb.frontrunneroutfitters.com/';
                // Remove /landingPages from endpointUrl
                fsyncEndpointUrl = endpointUrl.replace('/gearzette', '');
            }
        }

        if (!fsyncUrl) {
            fsyncUrl = 'https://fsync.frontrunneroutfitters.com/graphql';
        }

        if (window.dataCache[fsyncUrl + fsyncEndpointUrl + rawQueries]) {
            onSucces(window.dataCache[fsyncUrl + fsyncEndpointUrl + rawQueries]);
            return;
        }

        const url = fsyncUrl + fsyncEndpointUrl;
        this.promise = makeCancelable(
            executeFsyncGet(url, rawQueries, this.dataModelName, ONE_MONTH_IN_SECONDS)
        );

        this.promise.promise.then(
            /** @namespace Pwa/Util/Request/DataContainer/then */
            (response) => {
                window.dataCache[fsyncUrl + fsyncEndpointUrl + rawQueries] = response;
                onSucces(response);
            },
            /** @namespace Pwa/Util/Request/DataContainer/then */
            (err) => onError(err)
        );
    }
}

export default DataContainer;
