/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getCurrentUrlForOggData } from 'Util/Helper';
import { Field } from 'Util/Query';

/**
 * Ogg Graph Query
 * @class OggGraphQuery
 * @namespace Pwa/Query/OggGraph/Query/OggGraphQuery
 */
export class OggGraphQuery {
    /**
     * get CMS Page query
     * @return {Query}  query
     * @memberof OggGraphQuery
     */
    getQuery({
        id, type, sku
    }) {
        // if (!id) throw new Error('Missing argument `options`');
        const current_url = getCurrentUrlForOggData();

        if (id) {
            return new Field('oggraphData')
                .addArgument('og_id', 'Int!', id)
                .addArgument('type', 'String!', type)
                .addArgument('current_url', 'String!', current_url)
                .addFieldList(this._getDataList());
        }

        const oggQuery = new Field('oggraphData')
            .addArgument('og_id', 'Int!', 0)
            .addArgument('type', 'String!', type)
            .addArgument('current_url', 'String!', current_url);

        if (sku) {
            oggQuery.addArgument('sku', 'String', sku);
        }

        return oggQuery.addFieldList(this._getDataList());
    }

    _getDataList() {
        return [
            'contact_us_snippet',
            this._getHrefLang(),
            this._getOggraph()
        ];
    }

    _getHrefLang() {
        return new Field('hreflang')
            .addFieldList([
                'href',
                'lang'
            ]);
    }

    _getOggraph() {
        return new Field('oggraph')
            .addFieldList([
                'property',
                'content'
            ]);
    }
}

export default new OggGraphQuery();
