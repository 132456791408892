import {
    SET_MAINTENANCE_RESPONSE
} from './Maintenance.action';

export const initialState = {
    data: {}
};

/** @namespace Pwa/Store/Maintenance/Reducer/MaintenanceReducer */
export const MaintenanceReducer = (state = initialState, action) => {
    const {
        data,
        type
    } = action;

    switch (type) {
    case SET_MAINTENANCE_RESPONSE:
        return {
            ...state,
            data
        };
    default:
        return state;
    }
};

export default MaintenanceReducer;
