/* eslint-disable fp/no-let */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import Select from 'react-select';

import Field from 'Component/Field';
import {
    ProductConfigurableAttributeDropdown as SourceProductConfigurableAttributes
} from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.component';

import './ProductConfigurableAttributeDropdown.style';

/** @namespace Pwa/Component/ProductConfigurableAttributeDropdown/Component/ProductConfigurableAttributeDropdownComponent */
export class ProductConfigurableAttributeDropdownComponent extends SourceProductConfigurableAttributes {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        selectOptions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            id: PropTypes.string,
            value: PropTypes.string
        })).isRequired,
        selectValue: PropTypes.string,
        selectLabel: PropTypes.string,
        selectName: PropTypes.string.isRequired
    };

    static defaultProps = {
        selectValue: '',
        selectLabel: 'attribute'
    };

    render() {
        const {
            selectOptions,
            selectValue,
            selectName,
            selectLabel,
            onChange,
            isEnable, index, selectedValueList, selectAndSetValue,
            sele
        } = this.props;

        for (let i = 0; i < selectOptions.length; i++) {
            if (selectOptions[i].value === 'null' || selectOptions[i].label === 'N/A') {
                selectOptions.splice(i, 1);
                i--;
            }
        }

        return (
            <Select
              isDisabled={ !isEnable }
              selectValue={ selectValue }
              value={ selectedValueList[index] ?? null }
              onChange={ (key) => this.onChangeWrapper(key, onChange, index) }
              options={ selectOptions }
            />

        // <Field
        //   id={ selectName }
        //   name={ selectLabel }
        //   type="select"
        //   placeholder={ __('Choose %s', selectLabel.toLowerCase()) }
        //   mix={ { block: 'ProductConfigurableAttributeDropdown' } }
        //   selectOptions={ selectOptions }
        //   value={ selectValue }
        //   onChange={ (e) => {
        //       console.log('1263456', e);
        //   } }
        // />
        // <Field
        //   type="select"
        //   id="list"
        //   name="list"
        //   sorting="0"
        //   value={ selectedValue }
        //   onChange={ this.handleListDataChange }
        //   selectOptions={ selectOptions }
        // />
        );
    }

    onChangeWrapper(key, onChange, index) {
        const { selectAndSetValue } = this.props;
        selectAndSetValue(key);
        onChange(key, index);
    }
}

export default ProductConfigurableAttributeDropdownComponent;
