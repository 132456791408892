/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable fp/no-let */
import { filter, isEmpty } from 'lodash';

import {
    ADD_CUSTOMER_GARAGE_ITEM,
    REMOVE_CUSTOMER_GARAGE_ITEM,
    SET_GARAGE_LOADING,
    UPDATE_CUSTOMER_GARAGE_LIST
} from './CustomerGarage.action';

export const initialState = {
    data: {},
    isLoading: true
};

/** @namespace Pwa/Store/CustomerGarage/Reducer/CustomerGarageReducer */
export const CustomerGarageReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        data
    } = action;

    let list = state.data || [];
    switch (type) {
    case UPDATE_CUSTOMER_GARAGE_LIST:
        if (isEmpty(list)) {
            return {
                ...state,
                isLoading: false,
                data
            };
        }

        return {
            ...state,
            isLoading,
            data
        };
    case SET_GARAGE_LOADING:
        return {
            ...state,
            isLoading
        };
    case REMOVE_CUSTOMER_GARAGE_ITEM:
        const removeItemId = data;
        const updatedList = filter(list, (l) => l.id !== removeItemId);
        return {
            ...state,
            data: updatedList,
            isLoading
        };
    case ADD_CUSTOMER_GARAGE_ITEM:
        if (list == null) {
            list = [];
        }

        return {
            ...state,
            data: [data, ...list],
            isLoading
        };
    default:
        return state;
    }
};

export default CustomerGarageReducer;
