import {
    BreadcrumbsReducer as SourceBreadcrumbsReducer,
    getInitialState as SourceGetInitialState
} from 'SourceStore/Breadcrumbs/Breadcrumbs.reducer';

// TODO: implement getInitialState
export const getInitialState = SourceGetInitialState;

// TODO: implement BreadcrumbsReducer
export const BreadcrumbsReducer = SourceBreadcrumbsReducer;

export default BreadcrumbsReducer;
