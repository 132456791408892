/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_GOOGLE_RECAPTCHA_TOKEN = 'UPDATE_GOOGLE_RECAPTCHA_TOKEN';

/** @namespace Pwa/Store/GoogleRecaptcha/Action/updateGoogleRecaptcha */
export const updateGoogleRecaptcha = (recaptchaToken) => ({
    type: UPDATE_GOOGLE_RECAPTCHA_TOKEN,
    recaptchaToken
});
