/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

/** @namespace Pwa/Component/ProductReviews/Component/ProductReviews */
export class ProductAttributeValue extends SourceProductAttributeValue {
    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        const appendStoreCode = `${window.storeConfig.baseName}${href}`;
        const newHref = appendStoreCode.replace(/\/\//, '/');

        if (isFormattedAsText) {
            return (
                <div
                  block="ProductAttributeValue"
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </div>
            );
        }

        if (!showProductAttributeAsLink) {
            return (
                <span
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable } }
                  onClick={ this.clickHandler }
                  onKeyDown={ this.clickHandler }
                  role="link"
                  tabIndex="-1"
                  aria-hidden={ isNotAvailable }
                  mix={ mix }
                >
                { this.renderAttributeByType() }
                </span>
            );
        }

        return (
            <a
              href={ newHref }
              block="ProductAttributeValue"
              mods={ { isNotAvailable } }
              onClick={ this.clickHandler }
              aria-hidden={ isNotAvailable }
              mix={ mix }
            >
                { this.renderAttributeByType() }
            </a>
        );
    }
}

export default ProductAttributeValue;
