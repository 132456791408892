/* eslint-disable no-console */
import { get, isEmpty } from 'lodash';

import ProductListQuery from 'Query/ProductList.query';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import {
    updateLoadStatus
} from 'Store/ProductList/ProductList.action';
import {
    updateVehicleFinderProductLoading
} from 'Store/VehicleFinder/VehicleFinder.action';
// import { fetchQuery, QueryDispatcher } from 'Util/Request';
import { QueryDispatcher } from 'Util/Request';

export const SkuProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/PartsFinder/SkuProducts.dispatcher'
);

/** @namespace Pwa/Store/PartsFinder/ProductUniversalSku/Dispatcher/ProductUniversalSkuDispatcher */
export class ProductUniversalSkuDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess(response, dispatch, {
        cb, categoryIds, sortObj, vehicleSpecificSkus
    }) {
        const products = get(response, 'universalProductSkus', []);
        // const productArr = JSON.parse(products);
        let skus = products.map(({ sku }) => sku).join(',');
        if (!isEmpty(vehicleSpecificSkus)) {
            skus = `${skus},${vehicleSpecificSkus}`;
        }

        if (isEmpty(skus)) {
            const currentPage = 1;
            const args = {};

            const items = [];
            const total_count = 0;
            const total_pages = 0;
            dispatch(updateLoadStatus(false));

            dispatch(cb(items,
                currentPage,
                total_count,
                total_pages,
                args));
            dispatch(updateVehicleFinderProductLoading(false));
            return;
        }
        const productsskuarray = skus.split(',');

        const options = { sku: { in: productsskuarray }, category_id: categoryIds };

        const pageSize = productsskuarray.length;
        const currentPage = 1;
        const sort = { position: 'ASC' };
        if (sortObj && !isEmpty(sortObj.sortKey)) {
            const { sortKey, sortDirection } = sortObj;
            sort[sortKey] = sortDirection;
        }

        SkuProductsDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch, {
                ...options, pageSize, currentPage, sort, cb
            })
        );
    }

    onError(_, dispatch) {
        dispatch(updateNoMatch(true));
        dispatch(updateVehicleFinderProductLoading(false));
    }

    // eslint-disable-next-line no-unused-vars
    prepareRequest(options, dispatch) {
        const { cb } = options;
        const currentPage = 1;
        const args = {};

        const items = [];
        const total_count = 0;
        const total_pages = 0;
        dispatch(updateLoadStatus(true));
        dispatch(updateVehicleFinderProductLoading(true));

        dispatch(cb(items,
            currentPage,
            total_count,
            total_pages,
            args, true));

        const { categoryIds } = options;
        return ProductListQuery.getUniversalProductSkus(categoryIds);
    }
}

export default new ProductUniversalSkuDispatcher();
