/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    NavigationAbstract as SourceNavigationAbstract
} from 'SourceComponent/NavigationAbstract/NavigationAbstract.component';

/** @namespace Pwa/Component/ProductReviews/Component/ProductReviews */
export class NavigationAbstract extends SourceNavigationAbstract {
    renderNavigationState() {
        const { navigationState: { name, hiddenElements = [] } } = this.props;

        // Get current page/state render methods
        const source = this.stateMap[name]
            ? this.stateMap[name]
            : this.stateMap[this.defaultStateName];

        // Return defined render methods for current page/state
        // * Dont render methods which id's are passed inside hiddenElements
        return Object.entries(this.renderMap).map(
            ([key, renderFunction]) => renderFunction(source[key] && !hiddenElements.includes(key), key)
        );
    }
}

export default NavigationAbstract;
