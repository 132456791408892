/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { filter, map, size } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { v1 as uuid } from 'uuid';

import Field from 'Component/Field';
import Form from 'Component/Form';
import Loader from 'Component/Loader';

import './EmailFriendForm.style';

/** @namespace Pwa/Component/EmailFriendForm/Component/EmailFriendFormComponent */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/derived-class-names
export class EmailFriendForm extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        onSubmitAttempt: PropTypes.func.isRequired,
        onSubmitSuccess: PropTypes.func.isRequired,
        onError: PropTypes.func.isRequired,
        handleNameChange: PropTypes.func.isRequired,
        handleEmailChange: PropTypes.func.isRequired,
        handleDescriptionChange: PropTypes.func.isRequired,
        data: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
            description: PropTypes.string,
            recipients: PropTypes.object
        }).isRequired
    };

    state = {
        showValidationError: 'noError'
    };

    renderButton() {
        const { data: { recipients = {} }, handleRecipientFieldChange } = this.props;
        // Forced to hide Add recipient button this way due to strange behavior
        const addRecipientButtonClass = (size(filter(recipients, (r) => r.hide !== true)) < 5) ? 'show btn' : 'hide btn';

        return (
        <div className="EmailFriend-Buttons">
          <div
            className={ addRecipientButtonClass }
            onClick={ () => handleRecipientFieldChange(uuid()) }
          >
            { __('ADD RECIPIENT') }
          </div>
          <button
            block="ProductQAForm-AgainTest"
            elem="Button"
            mix={ { block: 'Button' } }
          >
            { __('SEND EMAIL') }
          </button>
        </div>
        );
    }

    renderFormContent() {
        const {
            handleNameChange,
            handleEmailChange,
            handleDescriptionChange,
            data
        } = this.props;

        const {
            name = '',
            email = '',
            description = ''
        } = data;

        return (
        <div
          block="EmailFriendForm"
          elem="Wrapper"
        >
          <div
            block="EmailFriendForm"
            elem="Content"
          >
            <h1> Sender: </h1>
            <Field
              type="text"
              label="Name"
              id="name"
              name="name"
              validation={ ['notEmpty'] }
              value={ name }
              onChange={ handleNameChange }
            />
            <Field
              type="email"
              label="Email Address"
              id="email"
              name="email"
              validation={ ['notEmpty', 'email'] }
              value={ email }
              onChange={ handleEmailChange }
            />
            <Field
              type="textarea"
              label="Message"
              id="description"
              name="description"
              validation={ ['notEmpty'] }
              value={ description }
              onChange={ handleDescriptionChange }
            />
          </div>
          <div
            block="EmailFriendForm"
            elem="Content"
          >
            <h1> Recipient: </h1>
            { this.renderRecipients() }
          </div>
        </div>
        );
    }

    renderRecipient = (recipient, key) => {
        const { name, email, hide } = recipient;
        const { handleRecipientFieldChange, removeRecipientField } = this.props;
        const wrapperClass = (hide === true) ? 'hide' : '';
        return (
        <div className={ wrapperClass } key={ key }>
          <div className="remove-wrap">
            <div
              className="Remove btn"
              onClick={ () => removeRecipientField(key) }
            >
              Remove
            </div>
          </div>
          <Field
            type="text"
            label="Name"
            id={ `${key}-name` }
            name={ `${key}-name` }
            validation={ ['notEmpty'] }
            value={ name }
            onChange={ (val) => handleRecipientFieldChange(key, 'name', val) }
          />
          <Field
            type="email"
            label="Email"
            id={ `${key}-email` }
            name={ `${key}-email` }
            value={ email }
            validation={ ['notEmpty', 'email'] }
            onChange={ (val) => handleRecipientFieldChange(key, 'email', val) }
          />
        </div>
        );
    };

    renderRecipients = () => {
        const { data: { recipients = {} } } = this.props;
        return (
            map(recipients, this.renderRecipient)
        );
    };

    onSubmitSendemail = (_, invalidFields) => {
        const {
            onSubmitAttempt
        } = this.props;

        this.setState({ showValidationError: '' });
        onSubmitAttempt(_, invalidFields);
    };

    render() {
        const {
            isLoading,
            onSubmitSuccess,
            onError
        } = this.props;
        const {
            showValidationError = 'noError'
        } = this.state;

        return (
        <Form
          key="email-friend"
          mix={ { block: `EmailFriend ${ showValidationError}` } }
          onSubmit={ this.onSubmitSendemail }
          onSubmitSuccess={ onSubmitSuccess }
          onSubmitError={ onError }
        >
          <Loader isLoading={ isLoading } />
          { this.renderFormContent() }
          { this.renderButton() }
        </Form>
        );
    }
}

export default EmailFriendForm;
