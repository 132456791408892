export const GET_SAVED_CARDS = 'GET_SAVED_CARDS';
export const SAVE_SELECTED_CARD = 'SAVE_SELECTED_CARD';
export const AUTHNET_PAYMENT = 'AUTHNET_PAYMENT';

/** @namespace Pwa/Store/Authnet/Action/updateSavedCards */
export const updateSavedCards = (data) => ({
    type: GET_SAVED_CARDS,
    data
});

/** @namespace Pwa/Store/Authnet/Action/saveSelectedCardAction */
export const saveSelectedCardAction = (selectedCard) => ({
    type: SAVE_SELECTED_CARD,
    selectedCard
});

/** @namespace Pwa/Store/Authnet/Action/authnetPaymentAction */
export const authnetPaymentAction = (isClicked) => ({
    type: AUTHNET_PAYMENT,
    isAuthnetPaymentClicked: isClicked
});
