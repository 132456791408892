/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { filter } from 'lodash';

import {
    SET_CUSTOMER_QUOTATIONS_LOADING,
    UPDATE_CUSTOMER_QUOTATIONS_ACCEPT_DETAILS,
    UPDATE_CUSTOMER_QUOTATIONS_DATA,
    UPDATE_CUSTOMER_QUOTATIONS_DETAILS
} from './CustomerQuotations.action';

export const initialState = {
    customer_quotations: {},
    quote_details: {},
    isLoading: true
};

/** @namespace Pwa/Store/CustomerQuotations/Reducer/CustomerQuotationsReducer */
export const CustomerQuotationsReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        customer_quotations,
        customerQuote,
        quotationcartmanagement
    } = action;

    switch (type) {
    case UPDATE_CUSTOMER_QUOTATIONS_DATA:
        return {
            ...state,
            isLoading,
            customer_quotations
        };
    case SET_CUSTOMER_QUOTATIONS_LOADING:
        return {
            ...state,
            isLoading
        };
    case UPDATE_CUSTOMER_QUOTATIONS_DETAILS:
        // const updated_customer_quotations = filter(all_activities, (aa) => data.includes(aa.id));
        return {
            ...state,
            quote_details: customerQuote,
            isLoading
        };
    default:
        return state;
    }
};

export default CustomerQuotationsReducer;
