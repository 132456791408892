/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable eqeqeq */
/* eslint-disable no-self-assign */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-lines */
/* eslint-disable no-console */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    faChevronDown,
    faShippingFast
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { each, isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import AccordionWrapper from 'Component/AccordionWrapper';
import CmsBlock from 'Component/CmsBlock';
import { MENU_SUBCATEGORY } from 'Component/Header/Header.config';
import Link from 'Component/Link';
import CategoryPage from 'Route/CategoryPage';
import { isUsaStore } from 'Util/FrontRunner/Store';
import { isB2BUser } from 'Util/Helper';

import './Sidebar.style';

export const TYPE_PRODUCT = 'PRODUCT';

/** @namespace Pwa/Component/SidebarMenu/Sidebar/Component/SidebarComponent */
export class SidebarComponent extends PureComponent {
    static propTypes = {
        goToPreviousHeaderState: PropTypes.func.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        sideMenuData: PropTypes.any,
        category: PropTypes.any,
        freeShippingHeaderText: PropTypes.any,
        free_shipping_header_text_b2b: PropTypes.any,
        is_free_shipping_enable: PropTypes.any
    };

    state = { activeMenuItemsStack: [] };

    closeMenuOverlay = this.closeMenuOverlay.bind(this);

    showSubCategory(e, activeSubcategory) {
        const { activeMenuItemsStack, expandedItem = '' } = this.state;
        const { changeHeaderState, goToPreviousHeaderState } = this.props;
        const { category_id: item_id, name: title } = activeSubcategory;
        if (expandedItem == item_id) {
            this.setState({ expandedItem: '' });
        } else {
            this.setState({ expandedItem: item_id });
        }

        e.stopPropagation();
        changeHeaderState({
            name: MENU_SUBCATEGORY,
            force: true,
            title,
            onBackClick: () => {
                this.setState(({ activeMenuItemsStack }) => (
                    { activeMenuItemsStack: activeMenuItemsStack.slice(1) }
                ));
                goToPreviousHeaderState();
            }
        });

        if (!activeMenuItemsStack.includes(item_id)) {
            this.setState({ activeMenuItemsStack: [item_id, ...activeMenuItemsStack] });
        }
    }

    closeMenuOverlay(e) {
        const { hideActiveOverlay, updateVehicleProductLoadStatus } = this.props;
        e.stopPropagation();
        const { vehicleFinderState, isVehiclesPage } = this.props;

        if (isVehiclesPage && updateVehicleProductLoadStatus) {
            updateVehicleProductLoadStatus();
        }

        this.setState({ activeMenuItemsStack: [], expandedItem: '' });
        // hideActiveOverlay();
    }

    handleMenuOverlayClick = (e) => {
        const {
            hideActiveOverlay, updateVehicleProductLoadStatus, setProductLoadingStatus, cleanProductListItems
        } = this.props;

        e.stopPropagation();
        const { vehicleFinderState, isVehiclesPage } = this.props;
        if (isVehiclesPage && updateVehicleProductLoadStatus) {
            updateVehicleProductLoadStatus();
        } else {
            setProductLoadingStatus();
            const currentPage = 1;
            const args = {};
            const items = [];
            const total_count = 0;
            const total_pages = 0;

            cleanProductListItems(items,
                currentPage,
                total_count,
                total_pages,
                args);
        }

        this.setState({ activeMenuItemsStack: [], expandedItem: '' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // hideActiveOverlay();
    };

    getCategoryLinkTo(id, url) {
        if (!url) {
            return undefined;
        }
        const storeBaseName = window.storeConfig.baseName;
        let new_url_key = url.replace(storeBaseName, '/');
        const { vehicleFinderState, isVehiclesPage } = this.props;

        if (!new_url_key) {
            return {};
        }
        if (isVehiclesPage) {
            new_url_key = `${new_url_key}?find=${vehicleFinderState.findPath}`;
        }

        const data = {
            pathname: new_url_key,
            state: { categoryId: id, partFinderData: { ...vehicleFinderState, categoryId: id } }
        };

        return { ...data };
    }

    renderItemContent(item, mods = {}) {
        const {
            category_id, name: title, icon, item_class, url
        } = item;
        const itemMods = item_class === 'MenuOverlay-ItemFigure_type_banner' ? { type: 'banner' } : mods;
        const { category = {}, vehicleFinderState = {} } = this.props;
        let { name, id } = category;
        const { categoryId = '' } = vehicleFinderState;
        if (!isEmpty(categoryId) && window.location.href.includes('?find=')) {
            id = categoryId;
        }
        const { isDataLoading = false, isVehiclesPage } = this.props;

        let cls = 'Normal';

        if (category_id == id) {
            cls = 'Selected';
        }
        let linkClass = 'Link';
        if (isDataLoading && isVehiclesPage) {
            linkClass = 'Link disabled';
        }

        return (
            <Link
              key={ category_id }
              to={ this.getCategoryLinkTo(category_id, url) }
              onClick={ this.handleMenuOverlayClick }
              block="MenuOverlay"
              elem={ linkClass }
              className={ cls }
            >
            <figure block="MenuOverlay" elem="ItemFigure" mods={ itemMods } className={ cls }>
                <figcaption
                  block="MenuOverlay"
                  elem="ItemCaption"
                  mods={ itemMods }
                >
                    { title }
                </figcaption>
            </figure>
            </Link>
        );
    }

    renderSubLevel(category) {
        const { activeMenuItemsStack } = this.state;
        const { category_id: item_id, name: parentName, sub_category: children } = category;
        const childrenArray = Object.values(children);

        const { category: activeCat, vehicleFinderState = {} } = this.props;
        let { name, id } = activeCat;

        const { categoryId = '' } = vehicleFinderState;
        if (!isEmpty(categoryId) && window.location.href.includes('?find=')) {
            id = categoryId;
        }

        let cls = 'parent';

        for (let i = 0; i < children.length; i++) {
            const { category_id } = children[i];
            if (category_id == id) {
                cls = 'active-parent';
                this.setState({ defaultExpanded: item_id });
            }
        }

        const { expandedItem = '', defaultExpanded = '' } = this.state;

        let openCls = 'Item HasChildMenuClosed';
        if (expandedItem == item_id) {
            openCls = 'Item HasChildMenuOpen';
        } else if (expandedItem == '' && defaultExpanded == item_id) {
            openCls = 'Item HasChildMenuOpen';
        }

        const isVisible = activeMenuItemsStack.includes(item_id);
        const subcategoryMods = { type: 'subcategory' };

        return (
            <div
              block="MenuOverlay"
              elem={ openCls }
              mods={ { ...subcategoryMods, isVisible } }
              className={ cls }
            >
                { childrenArray.map((item) => {
                    let { url, category_id: item_id, sub_category: children } = item;

                    if (!isEmpty(children)) {
                        children = children;
                    } else {
                        children = [];
                    }
                    const childrenArray = Object.values(children);

                    return (childrenArray.length
                        ? (
                            <div
                              key={ item_id }
                              tabIndex="0"
                              role="button"
                              className={ openCls }
                            >
                                { this.renderItemContent(item, subcategoryMods) }
                                <div
                                  key={ item_id }
                                  onClick={ (e) => this.showSubCategory(e, item) }
                                  className="fr_btn-show"
                                >
                                  'open'
                                </div>
                                <FontAwesomeIcon icon={ faChevronDown } />
                                { this.renderSubLevel(item) }
                            </div>
                        ) : (

                            <>
                                { ' ' }
                                { this.renderItemContent(item, subcategoryMods) }
                                { ' ' }
                            </>
                        )
                    );
                }) }
            </div>
        );
    }

    renderFirstLevel(itemList, itemMods) {
        return Object.values(itemList).map((item) => {
            const {
                category_id: item_id, name: pname, sub_category = [], url
            } = item;

            const { category = {}, vehicleFinderState = {} } = this.props;
            let { name, id } = category;

            const { categoryId = '' } = vehicleFinderState;
            if (!isEmpty(categoryId) && window.location.href.includes('?find=')) {
                id = categoryId;
            }

            let cls = 'parent';
            if (item_id == id) {
                cls = 'active-parent';
                this.setState({ defaultExpanded: item_id });
            }

            let children = [];
            if (!isEmpty(sub_category)) {
                children = sub_category;
            }

            for (let i = 0; i < children.length; i++) {
                const { category_id } = children[i];

                if (category_id == id) {
                    cls = 'active-parent';
                    this.setState({ defaultExpanded: item_id });
                }
            }

            const { expandedItem = '', defaultExpanded = '' } = this.state;

            let openCls = 'Item HasChildMenuClosed';
            if (expandedItem == item_id) {
                openCls = 'Item HasChildMenuOpen';
            } else if (expandedItem == '' && defaultExpanded == item_id) {
                openCls = 'Item HasChildMenuOpen';
            }

            const childrenArray = Object.values(children);

            return (
                <li key={ item_id } block="MenuOverlay" elem={ openCls } className={ cls }>
                    { childrenArray.length
                        ? (
                            <div
                              tabIndex="0"
                              role="button"
                              className={ openCls }
                            >
                                { this.renderItemContent(item, itemMods) }
                                <div
                                  key={ `${item_id}_child` }
                                  onClick={ (e) => this.showSubCategory(e, item) }
                                  className="fr_btn-show"
                                >
                                    <FontAwesomeIcon icon={ faChevronDown } />
                                </div>
                                { this.renderSubLevel(item) }
                            </div>
                        ) : (
                            <>
                                { this.renderItemContent(item, itemMods) }
                            </>
                        ) }
                </li>
            );
        });
    }

    renderAdditionalInformation() {
        return (
            <aside block="MenuOverlay" elem="AdditionalInformation">
                <h3 block="MenuOverlay" elem="PageLink">
                    <Link
                      to="/page/about-us"
                      onClick={ this.closeMenuOverlay }
                      block="MenuOverlay"
                      elem="Link"
                    >
                        { __('ABOUT US') }
                    </Link>
                </h3>
                <h3 block="MenuOverlay" elem="PageLink">
                    <Link
                      to="/page/about-us"
                      onClick={ this.closeMenuOverlay }
                      block="MenuOverlay"
                      elem="Link"
                    >
                    { __('CONTACTS') }
                    </Link>
                </h3>
                <div block="MenuOverlay" elem="Social">
                    <CmsBlock identifiers={ ['social-links'] } />
                </div>
            </aside>
        );
    }

    renderTopLevel = () => {
        const { sideMenuData, isVehiclesPage, copySlideMenu = [] } = this.props;
        const categoryArray = Object.values(sideMenuData);
        if (!categoryArray.length) {
            return null;
        }

        let [{ sub_category: mainCategories, name: mainCategoriesTitle }] = sideMenuData;

        const mainMods = { type: 'sideMenu' };
        const trendingMods = { type: 'trending' };
        if (isVehiclesPage) {
            mainCategoriesTitle = 'VEHICLE SPECIFIC GEAR';
        }

        return (
            <div block="MenuOverlay" elem="Menu">
                <ul
                  block="MenuOverlay"
                  elem="ItemList"
                  mods={ mainMods }
                  aria-label={ mainCategoriesTitle }
                >
                    { this.renderFirstLevel(copySlideMenu, mainMods) }
                </ul>
            </div>
        );
    };

    renderMobileMenu = (data, key = '1') => {
        const {
            name,
            name: title,
            url,
            color = '',
            text_color,
            type = 'category',
            sub_category,
            url_resolver = {}
        } = data;

        const internalStyle = isEmpty(text_color) ? {} : { color: text_color };
        if (isEmpty(sub_category)) {
            return (
                <div key={ name } style={ internalStyle }>
                    { this.handleActionView(title, key, type, url, url_resolver) }
                </div>
            );
        }

        if (isEmpty(sub_category)) {
            return (
                <div key={ name } style={ internalStyle }>
                    { title }
                </div>
            );
        }

        const accordionData = [{
            title,
            url,
            sub_category: map(sub_category, this.renderMobileMenu),
            borderLeftColor: color,
            url_resolver
        }];

        return (
            <AccordionWrapper
              key={ name }
              allowMultipleExpanded
              data={ accordionData }
            />
        );
    };

    handleActionView = (title, key, type, url, url_resolver = {}) => {
        if (url.includes('http')) {
            if (url === '/http://frontrunnerpride.com') {
                return (
                    <a className="MenuOverlay-Link menu_link_ex" href="http://frontrunnerpride.com" rel="nofollow noopener noreferrer" onClick={ this.closeMenuOverlay } key={ key } target="_blank">
                        { title }
                    </a>
                );
            } if (url === 'https://m.youtube.com/user/FrontRunnerOutfitter') {
                return (
                    <a className="MenuOverlay-Link menu_link_ex" href="https://m.youtube.com/user/FrontRunnerOutfitter" rel="noopener noreferrer" onClick={ this.closeMenuOverlay } key={ key } target="_blank">
                        { title }
                    </a>
                );
            }

            return (
                <a className="MenuOverlay-Link menu_link_ex" href={ url } onClick={ this.closeMenuOverlay } key={ key } target="_blank">
                    { title }
                </a>
            );
        }

        return (
            <RouterLink
              key={ key }
              to={ {
                  pathname: url,
                  state: {
                      url_resolver
                  }
              } }
              onClick={ this.closeMenuOverlay }
              className={ `MenuOverlay-Link menu_link menu_${type}` }
            >
                { title }
            </RouterLink>
        );

        // }
    };

    renderPage({ type, id, url_key }) {
        const { props } = this;
        e.stopPropagation();
        this.setState({ activeMenuItemsStack: [] });
        return <CategoryPage { ...props } categoryIds={ id } />;
    }

    renderHeading() {
        // const { isContentFiltered } = this.props;
        return (
            <h3 block="CategoryFilterOverlay" elem="Heading">
                { __('Categories') }
            </h3>
        );
    }

    moveToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    renderBreadPromo() {
        if (!isUsaStore()) {
            return null;
        }

        const promoImages = [
            'bread-finance-ford-raptor.jpg',
            'bread-finance-ford-raptor-2.jpg',
            'bread-finance-jeep.jpg',
            'bread-finance-toyota-4runner.jpg',
            'bread-finance-toyota-4runner-2.jpg',
            'bread-finance-toyota-tacoma.jpg',
            'bread-finance-toyota-tacoma-2.jpg'
        ];
        const randomImage = promoImages[Math.floor(Math.random() * promoImages.length)];

        return (
            <div className="bread-promo">
                <Link
                  to="/bread-finance"
                  block="bread-finance"
                  elem="link"
                  onClick={ () => this.moveToTop() }
                >
                    <img src={ `/media/wysiwyg/bread-finance/${randomImage}` } border="0" alt="0% APR Financing Available | Bread Payments" loading="lazy" width="220" height="220" />
                </Link>
            </div>
        );
    }

    renderPromoContent() {
        const { is_free_shipping_enable, freeShippingHeaderText = {}, free_shipping_header_text_b2b = {} } = this.props;
        const { customer = {} } = this.props;
        const { is_b2b } = customer;
        if (isEmpty(free_shipping_header_text_b2b)) {
            return null;
        }

        if (is_free_shipping_enable === '1' && !isB2BUser(is_b2b)) {
            return (
                <figure
                  block="SideMenu"
                  elem="PromoBlock"
                >
                    <figcaption block="SideMenu" elem="PromoText">
                            <div className="PromoText-Image">
                                <FontAwesomeIcon icon={ faShippingFast } size="1x" color="#222" />
                            </div>
                            <div className="PromoText-Wrapper">
                                <div className="PromoText-Title">
                                    { freeShippingHeaderText }
                                </div>
                                <div className="PromoText-Terms">
                                    <Link
                                      to="/shipping-policies"
                                      block="promo"
                                      elem="link"
                                      target="_blank"
                                    >
                                        { __("T&C's apply") }
                                    </Link>
                                </div>
                            </div>
                    </figcaption>
                </figure>
            );
        }

        if (is_free_shipping_enable === '1' && isB2BUser(is_b2b)) {
            return (
                <figure
                  block="SideMenu"
                  elem="PromoBlock"
                >
                    <figcaption block="SideMenu" elem="PromoText">
                            <div className="PromoText-Image">
                                <FontAwesomeIcon icon={ faShippingFast } size="1x" color="#222" />
                            </div>
                            <div className="PromoText-Wrapper">
                                <div className="PromoText-Title">
                                    { free_shipping_header_text_b2b }
                                </div>
                                <div className="PromoText-Terms">
                                    <Link
                                      to="/shipping-policies"
                                      block="promo"
                                      elem="link"
                                      target="_blank"
                                    >
                                        { __("T&C's apply") }
                                    </Link>
                                </div>
                            </div>
                    </figcaption>
                </figure>
            );
        }

        return null;
    }

    render() {
        const { sideMenuData, category = {}, isVehiclesPage = false } = this.props;
        let { name, id } = category;
        if (isEmpty(category) && !(isVehiclesPage)) {
            return null;
        }

        if (isVehiclesPage) {
            name = __('VEHICLE SPECIFIC GEAR');
        }

        /* { (name) && (<div className="block-title-cat">{ name }</div>) } */

        return (
            <>
                { this.renderHeading() }
                { this.renderTopLevel() }
                { this.renderPromoContent() }
                { this.renderBreadPromo() }
                { /* { map(sideMenuData, this.renderMobileMenu)} */ }
            </>
        );
    }
}

export default SidebarComponent;
