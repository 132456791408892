/* eslint-disable prefer-destructuring */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable no-unused-vars */
import { get, isEmpty, slice } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
// import Popup from 'Component/Popup';
import Popup from 'reactjs-popup';

import CloseIcon from 'Component/CloseIcon';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ProductType } from 'Type/ProductList';
import history from 'Util/History';

import FRSlider from '../FRSlider/FRSlider';

import './AddToCartPopUp.style';

export const ADDTOCART_POPUP_ID = 'ADDTOCART_POPUP_ID';

/** @namespace Pwa/Component/AddToCartPopUp/Component/AddToCartPopUpComponent */
export class AddToCartPopUp extends PureComponent {
  static propTypes = {
      products: ProductType,
      currentItem: PropTypes.any.isRequired,
      showPopup: PropTypes.func.isRequired,
      isVisble: PropTypes.bool
  };

  renderPopup() {
      const { mCurrentItem, isShowingPupup = true } = this.props;

      if (isEmpty(mCurrentItem)) {
          return;
      }

      return (
      <Popup
        open={ isShowingPupup }
        onClose={ this.closePopup }
        position="right center"
      >
        { ' ' }
        { /* { this.renderTitleHeader() } */ }
        { /* { this.renderProducts() } */ }
        { this.renderAddedItem() }
        { this.renderButtonOptions() }
      </Popup>
      );
  }

  renderTitleHeader =() => {
      const { mProducts = [] } = this.props;
      const titleMessage = __('You may also be interested in...');
      // let titleMessage= __('RELATED PRODUCTS')

      const isVisible = true;
      return (
        <div>
            { (!isEmpty(mProducts)) && (<h4>{ titleMessage }</h4>) }
            <button
              key="close"
              block="Header"
              elem="Button"
              mods={ { type: 'close', isVisible } }
              onClick={ this.closePopup }
              aria-label="Close"
            >
                <CloseIcon />
            </button>
        </div>
      );
  };

  renderProducts = () => {
      const { mProducts = [], currentItem, products = [] } = this.props;
      let productTypePopup = 'crosssell';

      const { location } = history;

      const { state } = location;

      if (!state || state === undefined) {
          productTypePopup = 'related';
      }

      if (isEmpty(products)) {
          return;
      }

      // Current item cross sell products
      let currentItems = products.items ?? [];
      const productLinks = currentItem[0]?.product?.product_links ?? [];
      if (productLinks.length > 0) {
          // Get original cross sell products
          const crossSellProducts = productLinks.filter((item) => item.link_type === `${productTypePopup}`);
          const crossSellProductSkus = crossSellProducts.map((item) => item.linked_product_sku);
          // Loop through current items and remove items sku not found in crossSellProductSKus
          currentItems = currentItems.filter((item) => crossSellProductSkus.includes(item.sku));
      }

      if (isEmpty(currentItems)) {
          return;
      }

      return (
        <FRSlider
          data={ currentItems }
          isRelated={ false }
          id="4"
          handleClick={ this.closePopup }

        />
      );
  };

  renderAddedItem=() => {
      const {
          mCurrentItem = [], productLoading, productName = ''
      } = this.props;

      const product = mCurrentItem && mCurrentItem.length > 0 ? mCurrentItem[0].product : {};

      if (isEmpty(product)) {
          return;
      }

      const { name, thumbnail } = product; // Use thumbnail for the image
      const message = __('Has been added to your cart!');

      const setProductName = productLoading ? '' : productName;
      const productImage = thumbnail?.url ?? null; // Use thumbnail URL

      return (
        <div
          id="popup-parent-product"
          block="ProductCard"
          elem="Name"
          itemProp="name"
        >
          { productImage && <img src={ productImage } alt={ name } /> }

          <div className="ProductCardText">
            <TextPlaceholder content={ setProductName } length="medium" />
            { !!name && (
              <div>
              { message }
              </div>
            ) }
          </div>
        </div>
      );
  };

  renderButtonOptions=() => (
      <>
      <div
        block="CartPopupButtons"
        elem="option"
        itemProp="options"
      >
        <button
          block="cart"
          elem="Button"
          mix={ { block: 'Button' } }
          onClick={ () => {
              this.viewCart();
          } }

        >
          { __('View cart') }
        </button>
        { ' ' }
        <button
          block="checkout"
          elem="Button"
          mix={ { block: 'Button' } }
          onClick={ () => {
              this.checkout();
          } }
        >
          { __('Checkout') }
        </button>
      </div>

      <a
        href="javascript:void(0);"
        onClick={ () => {
            this.closePopup();
        } }
        className="CartPopupButtons-link"
      >
            { __('Continue shopping') }
      </a>
      </>
  );

  closePopup=() => {
      const { onClickContinue } = this.props;
      if (onClickContinue) {
          onClickContinue();
      }
  };

  checkout=() => {
      const { onClickCheckout } = this.props;
      if (onClickCheckout) {
          onClickCheckout();
      }
  };

  viewCart=() => {
      const { onClickViewCart } = this.props;
      if (onClickViewCart) {
          onClickViewCart();
      }
  };

  render() {
      return (
      <div className="UpSellPopup">
       { this.renderPopup() }
      </div>

      );
  }
}

export default AddToCartPopUp;
