/* eslint-disable fp/no-let */
import { get, isEmpty } from 'lodash';

import ContactPageQuery from 'Query/ContactPage.query';
import OggGraphQuery from 'Query/OggGraph.query';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, QueryDispatcher } from 'Util/Request';

import { requestContact, responseContact, updateContactPage } from './ContactPage.action';

/** @namespace Pwa/Store/ContactPage/Dispatcher/ContactPageDispatcher */
export class ContactPageDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess(data, dispatch) {
        let content = get(data, 'contactUsData', {});
        const oggraphData = get(data, 'oggraphData', {});
        if (!isEmpty(content)) {
            content = { ...content, oggraphData };
            dispatch(updateContactPage({ content }, false));
        }
    }

    onError(_, dispatch) {
        dispatch(updateNoMatch(true));
    }

    prepareRequest(options, dispatch) {
        dispatch(updateContactPage({}, true));
        return [
            ContactPageQuery.getContent(options),
            OggGraphQuery.getQuery({ ...options, type: 'CONTACT' })
        ];
    }

    submit(dispatch, options) {
        dispatch(requestContact(true));

        return fetchMutation(ContactPageQuery.submit(
            options
        )).then(
            /** @namespace Pwa/Store/ContactPage/Dispatcher/fetchMutation/then */
            (data) => {
                const { contactUsSubmit: { message = 'Success!' } } = data;
                dispatch(responseContact(false));
                dispatch(showNotification('success', message));
            },
            /** @namespace Pwa/Store/ContactPage/Dispatcher/fetchMutation/then */
            (error) => dispatch(showNotification('error', 'Error! Please try again.')) && console.log(error)
        );
    }
}

export default new ContactPageDispatcher();
