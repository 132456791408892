/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    APPLE_PAY, GIROPAY,
    GOOGLE_PAY, SOFORT, STRIPE,
    STRIPE_KLARNA
} from 'Component/CheckoutPayments/CheckoutPayments.config';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { prepareMutation } from 'Util/Query';
import { fetchMutation } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import StripeQuery from '../../query/Stripe.query';
import { Address, PaymentTotals } from '../../type/Stripe';
import Stripe from './Stripe.component';
import { STRIPE_MODE_TEST } from './Stripe.config';

/** @namespace StripePayments/Component/Stripe/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    stripe_mode: state.ConfigReducer.stripe_mode,
    stripe_live_pk: state.ConfigReducer.stripe_live_pk,
    stripe_test_pk: state.ConfigReducer.stripe_test_pk,
    intent_secret_card: state.StripePaymentReducer.intent_secret_card,
    intent_secret_gero_pay: state.StripePaymentReducer.intent_secret_gero_pay,
    intent_secret_sofort: state.StripePaymentReducer.intent_secret_sofort,
    intent_secret_klarna: state.StripePaymentReducer.intent_secret_klarna,
    isIntentLoading: state.StripePaymentReducer.isLoading
});

/** @namespace StripePayments/Component/Stripe/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
});
/** @namespace StripePayments/Component/Stripe/Container/StripeContainer */
export class StripeContainer extends PureComponent {
    static propTypes = {
        setStripeRef: PropTypes.func.isRequired,
        billingAddress: Address.isRequired,
        onPaymentMethod: PropTypes.func.isRequired,
        paymentTotals: PaymentTotals.isRequired
    };

    state = {
        isLoading: false,
        storeConfig: {}
    };

    __construct(props) {
        super.__construct(props);
        if (window.Stripe) {
            // this._requestStripeData();
        } else {
            const script = document.createElement('script');
            script.src = 'https://js.stripe.com/v3/';
            script.crossOrigin = true;
            document.head.appendChild(script);
            script.addEventListener('load', () => {
                this.setState({ scriptLoade: true });
            }, false);
        }
    }

    componentDidUpdate() {
        const { selectedPaymentCode, selectedPaymentTitle } = this.props;
        const { activePaymentCode, activePaymentTitle, iselectedPaymentTitlentent_client_secret } = this.state;
        if (selectedPaymentCode !== activePaymentCode) {
            this.setState({
                activePaymentCode: selectedPaymentCode,
                intent_client_secret: ''
            });
        }
        this._updateIntentSecret();
    }

    componentDidMount() {
        this._ismounted = true;
        this.setState({ paymentCode: '' });
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    containerProps = () => {
        const { isLoading, intent_client_secret } = this.state;
        const {
            setStripeRef,
            billingAddress,
            onPaymentMethod,
            paymentTotals,
            paymentMethod,
            selectedPaymentCode,
            selectedPaymentTitle

        } = this.props;

        return {
            isLoading,
            setStripeRef,
            billingAddress,
            onPaymentMethod,
            paymentTotals,
            paymentMethod,
            intent_client_secret,
            selectedPaymentCode,
            selectedPaymentTitle,
            stripeKey: this._getStripeKey()
        };
    };

    _updateIntentSecret() {
        const {
            selectedPaymentCode,
            isIntentLoading = false
        } = this.props;

        const { intent_client_secret, paymentCode: prevPaymentCode = '', isLoading = false } = this.state;
        if (isLoading || isIntentLoading) {
            return;
        }

        // eslint-disable-next-line fp/no-let
        let paymentCode = 'card';
        switch (selectedPaymentCode) {
        case STRIPE:
            paymentCode = 'card';
            if (prevPaymentCode != paymentCode) {
                this.setState({ intent_client_secret: '', isLoading: true, paymentCode }, () => {
                    this.setState({ intent_client_secret: this.props.intent_secret_card, isLoading: false });
                });
            }
            break;
        case GOOGLE_PAY:
            paymentCode = 'google_pay';
            if (prevPaymentCode != paymentCode) {
                this.setState({ intent_client_secret: '', isLoading: true, paymentCode }, () => {
                    this.setState({ intent_client_secret: this.props.intent_secret_card, isLoading: false });
                });
            }
            break;
        case APPLE_PAY:
            paymentCode = 'apple_pay';
            if (prevPaymentCode != paymentCode) {
                this.setState({ intent_client_secret: '', isLoading: true, paymentCode }, () => {
                    this.setState({ intent_client_secret: this.props.intent_secret_card, isLoading: false });
                });
            }
            break;
        case GIROPAY:
            paymentCode = 'giropay';
            if (prevPaymentCode != paymentCode) {
                this.setState({ intent_client_secret: '', isLoading: true, paymentCode }, () => {
                    this.setState({ intent_client_secret: this.props.intent_secret_gero_pay, isLoading: false });
                });
            }
            break;
        case SOFORT:
            paymentCode = 'sofort';
            if (prevPaymentCode != paymentCode) {
                this.setState({ intent_client_secret: '', isLoading: true, paymentCode }, () => {
                    this.setState({ intent_client_secret: this.props.intent_secret_sofort, isLoading: false });
                });
            }
            break;
        case STRIPE_KLARNA:
            paymentCode = 'klarna';
            if (prevPaymentCode != paymentCode) {
                this.setState({ intent_client_secret: '', isLoading: true, paymentCode }, () => {
                    this.setState({ intent_client_secret: this.props.intent_secret_klarna, isLoading: false });
                });
            }
            break;
        default:
              // code block
        }
    }

    _getStripeKey() {
        const { stripe_mode, stripe_live_pk, stripe_test_pk } = this.props;

        return stripe_mode === STRIPE_MODE_TEST
            ? stripe_test_pk
            : stripe_live_pk;
    }

    render() {
        return (
            <Stripe
              { ...this.containerProps() }
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StripeContainer);
