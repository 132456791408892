/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

import { getIcons, getLinks, getSocialIcons } from './helper';

/**
 * CMS Blocks Query
 * @class CmsBlocksQuery
 */
class FooterQuery {
    /**
     * get CMS Block query
     * @param  {{identifier: String, title: String, content: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Field} CMS Block query
     * @memberof CmsBlocksQuery
     */

    getQuery() {
        // if (!identifiers) throw new Error('Missing argument `options`');

        return new Field('customFooter')
            .addField(this.getWarrantyFields())
            .addField(this.getStoreDetailsFields())
            .addField(this.getFooterBlockFields())
            .addField(this.getConnectFields())
            .addField(this.getFooterCopyrightFields())
            .setAlias('footer');
    }

    getWarrantyFields = () => new Field('warranty')
        .addField(this.getWarrantyBlock())
        .addFieldList([
            'logo'
        ]);

    getWarrantyBlock = () => new Field('warranty_block')
        .addFieldList([
            'text1',
            'text2',
            'text3'
        ]);

    getStoreDetailsFields = () => new Field('store_details')
        .addField(this.getHelpLink())
        .addField(this.getSupport())
        .addFieldList([
            'label1',
            'label2',
            'label3',
            'store_hours'
        ]);

    getHelpLink = () => new Field('help_link')
        .addFieldList([
            'label',
            'link'
        ]);

    getSupport = () => new Field('support')
        .addFieldList([
            'label',
            'phone'
        ]);

    getFooterBlockFields = () => new Field('footer_block')
        .addField(this.getOurStory())
        .addField(this.getFooterLinks())
        .addField(this.getFaceBookPost());
        // .addField(this.getInstagram());

    getOurStory = () => new Field('our_story')
        .addField(this.getFooterInfo())
        .addFieldList([
            'label',
            'story'
        ]);

    getFooterInfo = () => new Field('footer_info')
        .addFieldList([
            'heading_label',
            'subheading_label'
        ]);

    getFooterLinks = () => new Field('footer_links')
        .addField(this.getFooterLinksRows())
        .addFieldList([
            'label',
            'link'
        ]);

    getFooterLinksRows = () => new Field('footer_links_rows')
        .addFieldList([
            'label',
            'link'
        ]);

    getFaceBookPost = () => new Field('facebook_post')
        .addField(getLinks('facebook_feeds'))
        .addFieldList([
            'label',
            'link'
        ]);

    // getInstagram = () => new Field('instagram_post')
    //     .addField(getLinks('instagram_feeds'))
    //     .addFieldList([
    //         'id',
    //         'region',
    //         'type',
    //         'thumbnail',
    //         'link',
    //         'caption',
    //         'comments',
    //         'likes',
    //         'created_at',
    //         'updated_at'
    //     ]);

    getConnectFields = () => new Field('connect')
        .addField(this.getSocial())
        .addField(this.getAddress());

    getSocial = () => getSocialIcons('social');

    getAddress = () => new Field('address')
        .addField(this.getAddressLine())
        .addFieldList([
            'label'
        ]);

    getAddressLine = () => new Field('address_line')
        .addFieldList([
            'line'
        ]);

    getFooterCopyrightFields = () => new Field('footer_copyright')
        .addField(getLinks('links'))
        .addField(getIcons('icons'))
        .addFieldList([
            'label'
        ]);
}

export default new FooterQuery();
