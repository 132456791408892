/* eslint-disable no-unused-vars */
import MaintenanceQuery from 'Query/Maintenance.query';
import { QueryDispatcher } from 'Util/Request';

import { setMaintenanceResponse } from './Maintenance.action';

/** @namespace Pwa/Store/Maintenance/Dispatcher/MaintenanceDispatcher */
export class MaintenanceDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    // onSuccess({ MpBetterMaintenanceConfigDetails = { } }, dispatch) {
    //     dispatch(setMaintenanceResponse({ MpBetterMaintenanceConfigDetails }));
    //     // dispatch(setMaintenanceResponse(MpBetterMaintenanceConfig));
    // }

    // onError(_, dispatch) {
    //     const MpBetterMaintenanceConfigDetails = { enabled: true, end_time: '10010' };
    //     dispatch(setMaintenanceResponse({ MpBetterMaintenanceConfigDetails }));
    // }

    prepareRequest(options, dispatch) {
        // return [
        //     MaintenanceQuery.fetchMainetance(options)
        // ];
        return null;
    }

    async checkMaintainance(dispatch, options) {
        const query = MaintenanceQuery.checkMainetance;
        const response = await fetch(query,
            {
                method: 'GET',
                headers: {
                    Accept: '*/*'

                }
            });

        response.json().then(
            /*  @namespace Pwa/Store/Maintenance/Dispatcher/json/then */
            (resp) => {
                dispatch(setMaintenanceResponse(resp));
            },
            /* @namespace Pwa/Store/Maintenance/Dispatcher/json/then */
            (err) => {
                dispatch(setMaintenanceResponse(err));
            }
        );
    }
}

export default new MaintenanceDispatcher();
