/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { filter } from 'lodash';

import {
    SAVE_CUSTOMER_INTERESTS_DATA,
    SET_CUSTOMER_INTERESTS_LOADING,
    UPDATE_CUSTOMER_INTERESTS_DATA
} from './CustomerInterests.action';

export const initialState = {
    data: {},
    isLoading: true
};

/** @namespace Pwa/Store/CustomerInterests/Reducer/CustomerInterestsReducer */
export const CustomerInterestsReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        active,
        all_activities,
        customer_interest,
        data
    } = action;

    const list = state.data;

    switch (type) {
    case UPDATE_CUSTOMER_INTERESTS_DATA:
        return {
            ...state,
            isLoading,
            active,
            all_activities,
            customer_interest
        };
    case SET_CUSTOMER_INTERESTS_LOADING:
        return {
            ...state,
            isLoading
        };
    case SAVE_CUSTOMER_INTERESTS_DATA:
        const updated_customer_interest = filter(all_activities, (aa) => data.includes(aa.id));
        return {
            ...state,
            customer_interest: updated_customer_interest,
            isLoading
        };
    default:
        return state;
    }
};

export default CustomerInterestsReducer;
