import {
    CLEAR_WISHLIST as SourceCLEAR_WISHLIST,
    clearWishlist as SourceClearWishlist,
    REMOVE_ITEM_FROM_WISHLIST as SourceREMOVE_ITEM_FROM_WISHLIST,
    removeItemFromWishlist as SourceRemoveItemFromWishlist,
    UPDATE_ALL_PRODUCTS_IN_WISHLIST as SourceUPDATE_ALL_PRODUCTS_IN_WISHLIST,
    UPDATE_IS_LOADING_IN_WISHLIST as SourceUPDATE_IS_LOADING_IN_WISHLIST,
    UPDATE_ITEM_OPTIONS as SourceUPDATE_ITEM_OPTIONS,
    updateAllProductsInWishlist as SourceUpdateAllProductsInWishlist,
    updateIsLoading as SourceUpdateIsLoading,
    updateItemOptions as SourceUpdateItemOptions
} from 'SourceStore/Wishlist/Wishlist.action';

// TODO: implement CLEAR_WISHLIST
export const CLEAR_WISHLIST = SourceCLEAR_WISHLIST;

// TODO: implement UPDATE_ITEM_OPTIONS
export const UPDATE_ITEM_OPTIONS = SourceUPDATE_ITEM_OPTIONS;

// TODO: implement REMOVE_ITEM_FROM_WISHLIST
export const REMOVE_ITEM_FROM_WISHLIST = SourceREMOVE_ITEM_FROM_WISHLIST;

// TODO: implement UPDATE_ALL_PRODUCTS_IN_WISHLIST
export const UPDATE_ALL_PRODUCTS_IN_WISHLIST = SourceUPDATE_ALL_PRODUCTS_IN_WISHLIST;

// TODO: implement UPDATE_IS_LOADING_IN_WISHLIST
export const UPDATE_IS_LOADING_IN_WISHLIST = SourceUPDATE_IS_LOADING_IN_WISHLIST;

// TODO: implement removeItemFromWishlist
export const removeItemFromWishlist = SourceRemoveItemFromWishlist;

// TODO: implement updateAllProductsInWishlist
export const updateAllProductsInWishlist = SourceUpdateAllProductsInWishlist;

// TODO: implement updateIsLoading
export const updateIsLoading = SourceUpdateIsLoading;

// TODO: implement updateItemOptions
export const updateItemOptions = SourceUpdateItemOptions;

// TODO: implement clearWishlist
export const clearWishlist = SourceClearWishlist;
