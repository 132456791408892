/* eslint-disable no-console */
import { GET_CITY_FOR_POSTAL_CODE } from './PostalCodeLookUp.action';

export const initialState = {
    city: []
};

/** @namespace Pwa/Store/PostalCodeLookUp/Reducer/PostalCodeLookUpReducer */
export const PostalCodeLookUpReducer = (state = initialState, action) => {
    const {
        type,
        data
    } = action;

    switch (type) {
    case GET_CITY_FOR_POSTAL_CODE:
        const { city = {} } = data;
        return {
            city
        };
    default:
        return state;
    }
};

export default PostalCodeLookUpReducer;
