/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import CartQuery from 'Query/Cart.query';
import ConfigQuery from 'Query/Config.query';
import RegionQuery from 'Query/Region.query';
import ReviewQuery from 'Query/Review.query';
import { updateConfig, updateNotificationsConfig } from 'Store/Config/Config.action';
import { setMaintenanceResponse } from 'Store/Maintenance/Maintenance.action';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { setCurrency } from 'Util/Currency';
import { fetchMutation, fetchQuery, QueryDispatcher } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);
export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Pwa/Store/Config/Dispatcher/ConfigDispatcher */
export class ConfigDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct('Config');
    }

    static updateCurrency(dispatch, options) {
        const { currencyCode } = options;

        return fetchMutation(ConfigQuery.getSaveSelectedCurrencyMutation(
            currencyCode
        )).then(
            setCurrency(currencyCode),
            dispatch(updateConfig())
        );
    }

    static updateConfigReducer(dispatch) {
        return fetchQuery(ConfigQuery.getQuery()).then(
            /** @namespace Pwa/Store/Config/Dispatcher/fetchQuery/then */
            (data) => {
                const { storeConfig } = data;
                const { header_notifications } = storeConfig;
                dispatch(updateNotificationsConfig(header_notifications));
            }
        );
    }

    onSuccess(data, dispatch) {
        if (data) {
            BrowserDatabase.setItem(data, 'config', ONE_MONTH_IN_SECONDS);
            dispatch(updateConfig(data));
            MyAccountDispatcher.then(
                ({ default: dispatcher }) => dispatcher.handleCustomerDataOnInit(dispatch)
            );
            WishlistDispatcher.then(
                ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
            );
            CartDispatcher.then(
                ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
            );
            // ProductCompareDispatcher.then(
            //     ({ default: dispatcher }) => dispatcher.updateInitialProductCompareData(dispatch)
            // );
        }
    }

    onError(error, dispatch) {
        dispatch(showNotification('error', __('Error fetching Config!'), error));
        const MpBetterMaintenanceConfigDetails = { enabled: true, end_time: '10010' };
        dispatch(setMaintenanceResponse({ MpBetterMaintenanceConfigDetails }));
    }

    prepareRequest() {
        return [
            RegionQuery.getCountriesQuery(),
            ReviewQuery.getRatingQuery(),
            ConfigQuery.getQuery(),
            ConfigQuery.getCheckoutAgreements(),
            ConfigQuery.getCurrencyData(),
            CartQuery.getCartDisplayConfig()
        ];
    }
}

export default new ConfigDispatcher();
