/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

// import MyAccountPrivacySettings from '../component/MyAccountPrivacySettings';

export const PRIVACY_SETTINGS = 'privacy-settings';
export const PRIVACY_SETTINGS_URL = '/privacy-settings';

// const renderMap = (originalMember, instance) => ({
//     ...originalMember,
//     [PRIVACY_SETTINGS]: () => renderPrivacySetting(instance)
// });

// export const renderPrivacySetting = (instance) => {
//     return (
//         <MyAccountPrivacySettings />
//     );
// };

// // eslint-disable-next-line no-unused-vars
// const tabMap = (originalMember, instance) => ({
//     ...originalMember,
//     [PRIVACY_SETTINGS]: {
//         url: PRIVACY_SETTINGS_URL,
//         name: __('Privacy Settings')
//     }
// });

export default {
    // 'Route/MyAccount/Container': {
    //     'static-member': {
    //         tabMap
    //     }
    // },
    // 'Route/MyAccount/Component': {
    //     'member-property': { renderMap }
    // }
};
