/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

import { getCountry, getUrls } from './helper';

/**
 * Header And Menu Query
 * @class HeaderAndMenuQuery
 * @namespace Pwa/Query/HeaderAndMenu/Query/HeaderAndMenuQuery
 */
class HeaderAndMenuQuery {
    /**
     * get Header and Menu Data
     * @return {Field}
     */
    getDesktopQuery = () => new Field('customHeader')
        .addField(this.getPanelHeader())
        .setAlias('desktopMenu');

    getPanelHeader = () => new Field('pannel_header')
        .addField(this.getHeaderLinks());

    getHeaderLinks = () => new Field('header_links')
        .addField(this.getHeaderShip())
        // .addField(this.getStoreSwitcher())
        .addField(this.getHeaderContent());

    getHeaderShip = () => getUrls('header_ship');

    // getStoreSwitcher = () => new Field('store_switcher')
    //     .addField(this.getContinent())
    //     .addFieldList([
    //         'label'
    //     ]);

    getContinent = () => new Field('continent')
        .addField(this.getCountries())
        .addField(this.getPinnedCountries())
        .addFieldList([
            'label'
        ]);

    getCountries = () => getCountry('countries');

    getPinnedCountries = () => getCountry('pinned_countries');

    getHeaderContent = () => new Field('header_content')
        .addField(this.getLogo())
        .addField(this.getShop())
        .addField(this.getDiscoverMore())
        // .addField(this.getShopByAdventure())
        // .addField(this.getDometicOutdoor())
        // .addField(this.getExplore())
        .addField(this.getContact());

    getLogo = () => new Field('logo')
        .addFieldList([
            'logo',
            'checkout_logo',
            'url',
            'alt'
        ]);

    getDiscoverMore = () => new Field('discover_more')
        .addField(this.getDiscoverMoreCategory());

    getDiscoverMoreCategory = () => new Field('category')
        .addField(this.getDiscoverMoreSubCategory())
        .addFieldList([
            'menu_position',
            'name'
        ]);

        getDiscoverMoreSubCategory = () => new Field('sub_category')
            .addFieldList([
                'name',
                'url'
            ]);

    getShop = () => new Field('shop')
        .addField(this.getCategory());

    getCategory = () => new Field('category')
        .addField(this.getSubCategory())
        .addFieldList([
            'menu_position',
            'name',
            'image',
            'category_id',
            'url',
            'description'
        ]);

    getSubCategory = () => new Field('sub_category')
        .addFieldList([
            'name',
            'image',
            'category_id',
            'url',
            'description',
            this.getSubSubCategoryFields()
        ]);

    getSubSubCategoryFields = () => new Field('sub_sub_category')
        .addFieldList([
            'name',
            'image',
            'category_id',
            'url'
        ]);

    // getShopByAdventure = () => getUrls('shop_by_adventure');

    // getDometicOutdoor = () => getUrls('dometic_outdoor');

    // getExplore = () => getUrls('explore');

    getContact = () => getUrls('contact');

    getMobileQuery = () => new Field('customMobileHeader')
        .addField(this.getMobileMenu())
        .addFieldList([
            'header_text_banner'
        ]);

    getMobileMenu = () => new Field('mobile_menu')
        .addFieldList([
            'title',
            'url',
            'color',
            'text_color',
            'type'
        ])
        .addField(this.getFirstChild());

    getFirstChild = () => new Field('child')
        .addFieldList([
            'title',
            'url',
            this.getResolverFileds()

        ])
        .addField(this.getSecondChild());

    getSecondChild = () => new Field('child')
        .addFieldList([
            'title',
            'url',
            this.getResolverFileds()
        ]);

      getResolverFileds =() => new Field('url_resolver')
          .addFieldList(['id',
              'type',
              'url_key']);
}

export default new HeaderAndMenuQuery();
