/* eslint-disable no-console */
/* eslint-disable max-len */
export const UPDATE_COMPANY_DATA = 'UPDATE_COMPANY_DATA';
export const SET_COMPANY_LOADING = 'SET_COMPANY_LOADING';
export const SET_COMPANY_EDITMODE = 'SET_COMPANY_EDITMODE';
export const SAVE_COMPANY_DATA = 'SAVE_COMPANY_DATA';

/** @namespace Pwa/Store/CompanyAccount/Action/updateCompanyProfileData */
export const updateCompanyProfileData = ({ company = {} }, isLoading = false) => ({
    type: UPDATE_COMPANY_DATA,
    company,
    isLoading
});

/** @namespace Pwa/Store/CompanyAccount/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_COMPANY_LOADING,
    isLoading
});

/** @namespace Pwa/Store/CompanyAccount/Action/setEditMode */
export const setEditMode = (editMode) => ({
    type: SET_COMPANY_EDITMODE,
    editMode
});

/** @namespace Pwa/Store/CompanyAccount/Action/saveCompanyData */
export const saveCompanyData = (data, isLoading) => ({
    type: SAVE_COMPANY_DATA,
    data,
    isLoading
});
