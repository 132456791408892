/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable fp/no-let */
/* eslint-disable no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import OggGraphQuery from 'Query/OggGraph.query';
import ProductListQuery from 'Query/ProductList.query';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateProductAdditinoalInfo, updateProductDetails } from 'Store/Product/Product.action';
import { isSignedIn } from 'Util/Auth';
import { getCurrentUrlForOggData } from 'Util/Helper';
import { fetchMutation, fetchQuery, QueryDispatcher } from 'Util/Request';

import { isLoading } from './Product.action';

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkedProducts/LinkedProducts.dispatcher'
);

/**
  * Product List Dispatcher
  * @class ProductDispatcher
  * @extends ProductDispatcher
  * @namespace Pwa/Store/Product/Dispatcher/ProductDispatcher */
export class ProductDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct('Product');
    }

    onSuccess(data = {}, dispatch) {
        const { products = {} } = data;
        const { items = [] } = products;
        // const { products: { items } } = data;
        const { additionalInformation = {}, oggraphData = {} } = data;
        /**
          * In case there are no items, or item count is
          * smaller then 0 => the product was not found.
          */
        if (!items || items.length <= 0) {
            dispatch(updateNoMatch(true));
            return;
        }

        const product_links = items.reduce((links, product) => {
            const { product_links } = product;

            if (product_links) {
                Object.values(product_links).forEach((item) => {
                    links.push(item);
                });
            }

            return links;
        }, []);

        LinkedProductsDispatcher.then(
            ({ default: dispatcher }) => {
                const { products: { items } } = data;
                if (product_links.length > 0 && items.length !== 0) {
                    dispatcher.handleData(dispatch, items[0].sku);
                } else {
                    dispatcher.clearLinkedProducts(dispatch);
                }
            }
        );

        items[0] = { ...items[0], additionalInformation, oggraphData };
        const [product] = items;
        dispatch(updateProductDetails(product));
    }

    onError(_, dispatch) {
        dispatch(updateNoMatch(true));
    }

    prepareRequest(options, id, count) {
        const loginStatus = isSignedIn();
        // const current_url = window.location.href.includes('?')
        //     ? window.location.href.substring(0, window.location.href.indexOf('?'))
        //     : window.location.href;
        const current_url = getCurrentUrlForOggData();

        const currentSku = options.args.filter.productSKU;
        const skuList = ['KRTRCUSTOM',
            'KRLBCUSTOM'

        ];
        const configurableOptions = {
            args: { filter: { sku: { eq: currentSku } } }
        };

        return [
            skuList.indexOf(currentSku) !== -1
                ? ProductListQuery.getConfigurableProductData(configurableOptions, id, count)
                : ProductListQuery.getQuery({ ...options, isSignedIn: loginStatus }),
            ProductListQuery.getAdditionalProductInfo({ ...options, isSignedIn: loginStatus }),
            OggGraphQuery.getQuery({ current_url, type: 'PRODUCT', sku: currentSku })
        ];
    }

    submitProductQA(dispatch, options) {
        return fetchMutation(ProductListQuery.getAddProductQAMutation(
            options
        )).then(
            /** @namespace Pwa/Store/Product/Dispatcher/fetchMutation/then */
            () => dispatch(showNotification('success', 'You submitted your Question for moderation.')),
            /** @namespace Pwa/Store/Product/Dispatcher/fetchMutation/then */
            (error) => dispatch(showNotification('error', 'Error submitting question!')) && console.log(error)
        );
    }

    async fetchAdditionalInformation(sku, dispatch) {
        await fetchQuery(ProductListQuery.getAdditionalInformation(sku)).then(
            /** @namespace Pwa/Store/Product/Dispatcher/fetchQuery/then */
            ({ additionalInformation }) => {
                dispatch(updateProductAdditinoalInfo(additionalInformation));
            },
            /** @namespace Pwa/Store/Product/Dispatcher/fetchQuery/then */
            (err) => {
                console.log(err);
            }
        );
    }

    async fetchConfigurableproduts(sku, id, count, dispatch, additionalInformation, oggraphData) {
        const configurableOptions = {
            args: { filter: { sku: { eq: sku } } }
        };
        const options = {
            args: {
                filter: {
                    productSKU: sku
                }
            }
        };
        const loginStatus = isSignedIn();
        const current_url = getCurrentUrlForOggData();
        const currentSku = options.args.filter.productSKU;
        // const currentSku = options.args.filter.productSKU;

        // return ProductListQuery.getQuery({ ...options, isSignedIn: loginStatus });
        dispatch(isLoading(true));
        try {
            const skuList = ['KRTRCUSTOM', 'KRLBCUSTOM'];

            if (skuList.indexOf(currentSku) === 0) {
                const query = [
                    ProductListQuery.getConfigurableProductData(configurableOptions, id, count),
                    ProductListQuery.getAdditionalProductInfo({ ...options, isSignedIn: loginStatus }),
                    OggGraphQuery.getQuery({ current_url, type: 'PRODUCT', sku: currentSku })
                ];
                const option = ProductListQuery.getAdditionalProductInfo({ ...options, isSignedIn: loginStatus });
                const response = await fetchQuery(query);
                const { products = {} } = response;
                const { items = [] } = products;
                // const { products: { items } } = data;
                /**
                  * In case there are no items, or item count is
                  * smaller then 0 => the product was not found.
                  */
                if (!items || items.length <= 0) {
                    dispatch(updateNoMatch(true));
                    return;
                }

                const product_links = items.reduce((links, product) => {
                    const { product_links } = product;
                    if (product_links) {
                        Object.values(product_links).forEach((item) => {
                            links.push(item);
                        });
                    }

                    return links;
                }, []);

                LinkedProductsDispatcher.then(
                    ({ default: dispatcher }) => {
                        const { products: { items } } = response;
                        if (product_links.length > 0 && items.length !== 0) {
                            dispatcher.handleData(dispatch, items[0].sku);
                        } else {
                            dispatcher.clearLinkedProducts(dispatch);
                        }
                    }
                );
                items[0] = { ...items[0], additionalInformation, oggraphData };
                const [product] = items;

                if (product?.configurable_product_options_selection?.variant?.sku) {
                    this.fetchAdditionalInformation(product.configurable_product_options_selection.variant.sku, dispatch);
                }
                dispatch(updateProductDetails(product));
                dispatch(isLoading(false));
            }
            dispatch(isLoading(false));
        } catch (error) {
        }
    }

    emailFriend(dispatch, options) {
        return fetchMutation(ProductListQuery.emailFriend(
            options
        )).then(
            /** @namespace Pwa/Store/Product/Dispatcher/fetchMutation/then */
            () => dispatch(showNotification('success', 'You submitted your Question for moderation.')),
            /** @namespace Pwa/Store/Product/Dispatcher/fetchMutation/then */
            (error) => dispatch(showNotification('error', 'Error submitting question!')) && console.log(error)
        );
    }
}

export default new ProductDispatcher();
