/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable no-undef */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable max-lines */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { data } from 'jquery';
import { isEmpty } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';

import { CustomerGarageDispatcher } from 'Store/CustomerGarage';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    MakesDispatcher,
    ModelsDispatcher,
    OptionsDispatcher,
    ProductHomepageSliderDispatcher,
    ProductSkuDispatcher,
    YearsDispatcher
} from 'Store/PartsFinder';
import { updateLoadStatus } from 'Store/ProductList/ProductList.action';
import {
    updateSelectedVehicle,
    updateVehicleFinderPageFormData,
    updateVehicleFinderPageMakes,
    updateVehicleFinderPageModels,
    updateVehicleFinderPageOptions,
    updateVehicleFinderPageYears,
    updateVehicleSpecificSkus
} from 'Store/VehicleFinder';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';

import VehicleFinder from './VehicleFinder.component';

/** @namespace Pwa/Component/VehicleFinder/Container/VehicleFinderContainer */
export class VehicleFinderContainer extends Component {
    state = {
        selectedYear: 0
    };

    componentDidMount() {
        const {
            yearsData
        } = this.props;

        const { selectedYear } = this.state;
        if (isEmpty(yearsData)) {
            this.storeIdLookupAndRequest();
        }
        if (isSignedIn()) {
            this.props.fetchList();
        }
        if (selectedYear === 0) {
            this.getValuesAndSetState();
        }
    }

    getValuesAndSetState() {
        const { yearsData } = this.props;
        const vehicleUrl = window.location.href;
        const vehicalData = vehicleUrl.split('/').pop().split('-')[0];
        if (!isEmpty(vehicalData) && !isEmpty(yearsData)) {
            const dataValue = yearsData.filter((val) => val.label == vehicalData);
            if (!isEmpty(dataValue)) {
                this.setValueWithIndex(dataValue[0].value);
            }
        }
    }

    setValueWithIndex(dataValue) {
        this.setState({ selectedYear: dataValue });
    }

    storeIdLookupAndRequest = () => {
        const period = 600; // ms
        const sleepyAlert = setInterval(() => {
            const { storeId } = window.storeConfig;
            if (storeId !== undefined) {
                clearInterval(sleepyAlert);
                const { requestPartsFinderYears } = this.props;
                try {
                    requestPartsFinderYears({ endpointUrl: '/vfits?', cb: updateVehicleFinderPageYears });
                } catch (error) {

                }
            }
        }, period);
    };

    _handleFieldChange(fieldName, value) {
        const { formData, saveFormData } = this.props;
        saveFormData({
            ...formData,
            [fieldName]: value
        });
    }

    handleYearsDataChange = (value) => {
        if (value === undefined || value === '') {
            return;
        }
        const { requestPartsFinderMakes, saveFormData } = this.props;
        // const { year } = formData;

        //   if (year == value) {
        //       return;
        //   }
        requestPartsFinderMakes({ yearId: value, endpointUrl: '/vfits?', cb: updateVehicleFinderPageMakes });

        saveFormData({
            year: value
        });
        //   saveFormData({
        //     year: value,
        //     make: '',
        //     model: '',
        //     options: ''

        // });
    };

    handleMakesDataChange = (value) => {
        if (value === undefined || value === '') {
            return;
        }
        const { requestPartsFinderModels, saveFormData, formData } = this.props;
        const { year } = formData;
        requestPartsFinderModels({
            yearId: year, makeId: value, endpointUrl: '/vfits?', cb: updateVehicleFinderPageModels
        });
        saveFormData({
            ...formData,
            make: value,
            model: '',
            options: ''

        });
    };

    handleModelsDataChange = (value) => {
        if (value === undefined || value === '') {
            return;
        }
        const { requestPartsFinderOptions, saveFormData, formData } = this.props;
        const { year, make } = formData;
        requestPartsFinderOptions({
            yearId: year, makeId: make, modelId: value, endpointUrl: '/vfits?', cb: updateVehicleFinderPageOptions
        });
        saveFormData({
            ...formData,
            model: value,
            options: ''
        });
    };

    handleOptionsDataChange = (value) => this._handleFieldChange('options', value);

    handleNickNameDataChange = (value) => this._handleFieldChange('nick_name', value);

    handlePurchaseDateDataChange = (value) => this._handleFieldChange('purchase_date', value);

    findResetFormData = () => {
        const { clearVehicleSpecificSkus, updateLoadStatus } = this.props;
        updateLoadStatus(true);
        clearVehicleSpecificSkus('');
        //   this.props.saveFormData({});
    };

    resetFormData = () => {
        const { clearVehicleSpecificSkus } = this.props;
        clearVehicleSpecificSkus('');
        this.props.saveFormData({});
        const baseUrl = window.location.pathname;
        if (!(baseUrl.includes('/racks/slimline-ii-roof-rack') || baseUrl.includes('/outfit-your-vehicle')
            || baseUrl.includes('/vehicle') || baseUrl.includes('/my-garage'))) {
            history.push('/');
        }
    };

    render() {
        return (
            <VehicleFinder
              { ...this.props }
              { ...this.state }
              handleYearsDataChange={ this.handleYearsDataChange }
              handleMakesDataChange={ this.handleMakesDataChange }
              handleModelsDataChange={ this.handleModelsDataChange }
              handleOptionsDataChange={ this.handleOptionsDataChange }
              handleNickNameDataChange={ this.handleNickNameDataChange }
              handlePurchaseDateDataChange={ this.handlePurchaseDateDataChange }
              findResetFormData={ this.findResetFormData }
              resetFormData={ this.resetFormData }
            />
        );
    }
}

/** @namespace Pwa/Component/VehicleFinder/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    yearsData: state.VehicleFinderReducer.yearsData,
    makesData: state.VehicleFinderReducer.makesData,
    modelsData: state.VehicleFinderReducer.modelsData,
    optionsData: state.VehicleFinderReducer.optionsData,
    formData: state.VehicleFinderReducer.formData,
    isLoading: state.VehicleFinderReducer.isLoading,
    loadedData: state.VehicleFinderReducer.specifiedData,
    list: state.CustomerGarageReducer.data,
    selectedVehicle: state.VehicleFinderReducer.selectedVehicle
});

/** @namespace Pwa/Component/VehicleFinder/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    fetchList: () => CustomerGarageDispatcher.handleData(dispatch),
    requestPartsFinderYears: (options) => YearsDispatcher.handleFsyncData(dispatch, options),
    requestPartsFinderMakes: (options) => MakesDispatcher.handleFsyncData(dispatch, options),
    requestPartsFinderModels: (options) => ModelsDispatcher.handleFsyncData(dispatch, options),
    requestPartsFinderOptions: (options) => OptionsDispatcher.handleFsyncData(dispatch, options),
    clearVehicleSpecificSkus: (d) => dispatch(updateVehicleSpecificSkus(d)),
    saveFormData: (options) => dispatch(updateVehicleFinderPageFormData(options)),
    updateLoadStatus: (isLoading) => dispatch(updateLoadStatus(isLoading)),
    requestPartsFinderSkus: (options) => ProductSkuDispatcher.handleFsyncData(dispatch, options),
    requestHomePageSlider: (options) => ProductHomepageSliderDispatcher.handleFsyncData(dispatch, options),
    updateSelectedVehicle: (options) => dispatch(updateSelectedVehicle(options)),
    showNotification: (type, message) => dispatch(showNotification(type, message))

});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleFinderContainer);
