/* eslint-disable no-console */
export const UPDATE_VEHICLE_FINDER_PAGE_YEARS = 'UPDATE_VEHICLE_FINDER_PAGE_YEARS';
export const UPDATE_VEHICLE_FINDER_PAGE_MAKES = 'UPDATE_VEHICLE_FINDER_PAGE_MAKES';
export const UPDATE_VEHICLE_FINDER_PAGE_MODELS = 'UPDATE_VEHICLE_FINDER_PAGE_MODELS';
export const UPDATE_VEHICLE_FINDER_PAGE_OPTIONS = 'UPDATE_VEHICLE_FINDER_PAGE_OPTIONS';
export const UPDATE_VEHICLE_FINDER_PAGE_FORMDATA = 'UPDATE_VEHICLE_FINDER_PAGE_FORMDATA';
export const UPDATE_VEHICLE_FINDER_PRODUCT_LOADING = 'UPDATE_VEHICLE_FINDER_PRODUCT_LOADING';
export const UPDATE_VEHICLE_FINDER_SPECIFIC_SKUS = 'UPDATE_VEHICLE_FINDER_SPECIFIC_SKUS';
export const UPDATE_VEHICLE_SPECIFIED_DATA = 'UPDATE_VEHICLE_SPECIFIED_DATA';
export const UPDATE_SELECTED_VEHICLE = 'UPDATE_SELECTED_VEHICLE';

/** @namespace Pwa/Store/VehicleFinder/Action/updateVehicleFinderPageYears */
export const updateVehicleFinderPageYears = (data, isLoading) => ({
    type: UPDATE_VEHICLE_FINDER_PAGE_YEARS,
    data,
    isLoading
});

/** @namespace Pwa/Store/VehicleFinder/Action/updateVehicleFinderProductLoading */
export const updateVehicleFinderProductLoading = (isProductLoading) => ({
    type: UPDATE_VEHICLE_FINDER_PRODUCT_LOADING,
    isProductLoading
});

/** @namespace Pwa/Store/VehicleFinder/Action/updateVehicleFinderPageMakes */
export const updateVehicleFinderPageMakes = (data, isLoading) => ({
    type: UPDATE_VEHICLE_FINDER_PAGE_MAKES,
    data,
    isLoading
});

/** @namespace Pwa/Store/VehicleFinder/Action/updateVehicleFinderPageModels */
export const updateVehicleFinderPageModels = (data, isLoading) => ({
    type: UPDATE_VEHICLE_FINDER_PAGE_MODELS,
    data,
    isLoading
});

/** @namespace Pwa/Store/VehicleFinder/Action/updateVehicleFinderPageOptions */
export const updateVehicleFinderPageOptions = (data, isLoading) => ({
    type: UPDATE_VEHICLE_FINDER_PAGE_OPTIONS,
    data,
    isLoading
});

/** @namespace Pwa/Store/VehicleFinder/Action/updateVehicleFinderPageFormData */
export const updateVehicleFinderPageFormData = (data) => ({
    type: UPDATE_VEHICLE_FINDER_PAGE_FORMDATA,
    data
});

/** @namespace Pwa/Store/VehicleFinder/Action/updateVehicleSpecificSkus */
export const updateVehicleSpecificSkus = (vehicleSpecificSkus) => ({
    type: UPDATE_VEHICLE_FINDER_SPECIFIC_SKUS,
    vehicleSpecificSkus
});
/** @namespace Pwa/Store/VehicleFinder/Action/updateVehicleSpecifiedData */
export const updateVehicleSpecifiedData = (value) => ({
    type: UPDATE_VEHICLE_SPECIFIED_DATA,
    specifiedData: value
});

/** @namespace Pwa/Store/VehicleFinder/Action/updateSelectedVehicle */
export const updateSelectedVehicle = (value) => ({
    type: UPDATE_SELECTED_VEHICLE,
    selectedVehicle: value
});
