/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Field from 'Component/Field';
import NotifyMe from 'Component/NotifyMe';
import {
    GroupedProductsItem as SourceGroupedProductsItem
} from 'SourceComponent/GroupedProductsItem/GroupedProductsItem.component';

import './GroupedProductsItem.override.style';
/** @namespace Pwa/Component/GroupedProductsItem/Component/GroupedProductsItem */
export class GroupedProductsItem extends SourceGroupedProductsItem {
    renderStockStatus() {
        const { product, defaultQuantity, itemCount } = this.props;
        const { stock_status, id } = product;

        if (stock_status === 'OUT_OF_STOCK') {
            return (
                <>
                <div className="out_of_stock">
                    { __('Out of stock') }
                </div>
                <div className="ProductNotifyEmail">
                    <div className="Elabel">
                        { __('Email me when this is available!') }
                    </div>
                    <NotifyMe
                      quantity={ defaultQuantity }
                      productId={ id }
                    />
                    <div block="ProductNotifyEmail" elem="Message">
                        { __('Missed it again? Resubmit a request to be notified.') }
                    </div>
                </div>

                </>
            );
        }

        return null;
    }

    renderQuantity() {
        const {
            changeCount,
            itemCount,
            product
        } = this.props;
        const { stock_status } = product;
        if (stock_status === 'OUT_OF_STOCK') {
            return null;
        }

        return (
            <div block="GroupedProductsItem" elem="Quantity">
                <Field
                  type="number"
                  id="HeaderInput"
                  name="HeaderInput"
                  onChange={ changeCount }
                  value={ itemCount }
                  min={ 0 }
                />
            </div>
        );
    }

    render() {
        return (
            <li block="GroupedProductsItem" aria-label="Product Item">
                { this.renderImage() }
                { this.renderTitle() }
                { this.renderQuantity() }
                { this.renderStockStatus() }
            </li>
        );
    }
}

export default GroupedProductsItem;
