/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-will-update-set-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/require-default-props */
/* eslint-disable react/sort-comp */
/* eslint-disable no-unused-vars */
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { CART } from 'Component/Header/Header.config';
import { CART_TAB } from 'Component/NavigationTabs/NavigationTabs.config';
import { setAddToCartPopupVisibility } from 'Store/AddToCartPopUp/AddToCartPopUp.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { HistoryType, LocationType, MatchType } from 'Type/Common.type';
import { history as browserHistory } from 'Util/History';

import AddToCartPopUp, { ADDTOCART_POPUP_ID } from './AddToCartPopUp.component';

/** @namespace Pwa/Component/AddToCartPopUp/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(ADDTOCART_POPUP_ID, payload)),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state)),
    setNavigationState: (state) => dispatch(changeNavigationState(BOTTOM_NAVIGATION_TYPE, state)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    setAddToCartPopupVisibility: (isShowingPupup) => dispatch(setAddToCartPopupVisibility(isShowingPupup))

});

/** @namespace Pwa/Component/AddToCartPopUp/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    products: state.AddToCartPopUpReducer.products,
    currentItem: state.AddToCartPopUpReducer.currentItem,
    isShowingPupup: state.AddToCartPopUpReducer.isVisible
});

/** @namespace Pwa/Component/AddToCartPopUp/Container/AddToCartPopUpContainer */
export class AddToCartPopUpContainer extends Component {
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        isShowingPupup: PropTypes.bool,
        location: LocationType,
        changeHeaderState: PropTypes.func,
        changeNavigationState: PropTypes.func,
        history: HistoryType,
        match: MatchType
    };

    __construct(props) {
        super.__construct(props);
        this.state = {
            isVisible: false,
            cancelledPopup: '',
            productName: '',
            productLoading: true
        };
    }

    containerFunctions = {
        showPopup: this._showPopup.bind(this),
        onClickContinue: this._onClickContinue.bind(this),
        onClickCheckout: this._onClickCheckout.bind(this),
        onClickViewCart: this._onClickViewCart.bind(this),
        updateProductname: this._updateProductName.bind(this)

    };

    componentWillUpdate(nextProps, nextState) {
        const { cancelledPopup, isVisible, mCurrentItem = [] } = nextState;
        const { currentItem = [], products = {} } = nextProps;
        const product = currentItem && currentItem.length > 0 ? currentItem[0].product : {};
        const mProduct = mCurrentItem && mCurrentItem.length > 0 ? mCurrentItem[0].product : {};

        if (cancelledPopup != product.id && !isVisible && currentItem && currentItem.length > 0) {
            this.setState({ mProducts: products, mCurrentItem: currentItem, [product.id]: 'added' });
        }
        const flag = get(nextState, [product.id], null);
        if (flag == null) {
            this.setState({ [product.id]: 'added' });
        }
    }

    componentDidUpdate(prevProps) {
        // const items = get(this.props, 'products.items', null)
        // const prevItems = get(prevProps, 'products.items', null)
        const { currentItem } = this.props;
        const product = currentItem && currentItem.length > 0 ? currentItem[0].product : {};

        const { name } = product;

        const { cancelledPopup, isVisible } = this.state;

        if (cancelledPopup == product.id || isVisible) {
            this._showPopup();
        } else if (currentItem.length > 0 && !isVisible) {
            this.setState({
                isVisible: true
            }, () => {
                this._showPopup();
            });
        }

        if (this.props !== prevProps) {
            this._updateProductName(name);
        }
    }

    _updateProductName(productName) {
        this.setState({ productLoading: true });
        this.setState({ productName }, () => {
            this.setState({ productLoading: false });
        });
    }

    _showPopup() {
        const { showPopup } = this.props;

        showPopup({ title: __('Related Products') });
    }

    _onClickContinue() {
        const { currentItem, setAddToCartPopupVisibility } = this.props;
        const product = currentItem && currentItem.length > 0 ? currentItem[0].product : {};
        setAddToCartPopupVisibility(false);
        this.setState({ isVisible: false, cancelledPopup: product.id });
    }

    _onClickViewCart() {
        const { currentItem, setAddToCartPopupVisibility } = this.props;
        const product = currentItem && currentItem.length > 0 ? currentItem[0].product : {};
        setAddToCartPopupVisibility(false);
        this.navigateToCartScreen('cart');

        // this.setState({ isVisible: false, cancelledPopup: product.id }, () => {
        //     this.navigateToCartScreen();
        // });
    }

    _onClickCheckout() {
        const { currentItem, setAddToCartPopupVisibility } = this.props;
        const product = currentItem && currentItem.length > 0 ? currentItem[0].product : {};
        setAddToCartPopupVisibility(false);
        this.navigateToCartScreen('checkout');

        // this.setState({ isVisible: false, cancelledPopup: product.id }, () => {
        //     this.navigateToCartScreen();
        // });
    }

    navigateToCartScreen = (page = 'checkout') => {
        const { changeHeaderState, hideActiveOverlay, setNavigationState } = this.props;
        const { pathname } = location;
        browserHistory.push(`/${page}`);

        hideActiveOverlay();

        if (pathname === '/') {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        changeHeaderState(CART);
        setNavigationState({ name: CART_TAB });
    };

    render() {
        return (
            <AddToCartPopUp
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartPopUpContainer);
