/* eslint-disable max-len */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// import ProductConfigurableAttributeDropdown from '@scandipwa/scandipwa/src/component/ProductConfigurableAttributeDropdown';

import ProductConfigurableAttributeDropdown from 'Component/ProductConfigurableAttributeDropdown';
import {
    ProductConfigurableAttributes as SourceProductConfigurableAttributes
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

// import { SMALL_PLACEHOLDER_CONFIG } from './ProductReviews.config';
import './ProductConfigurableAttributes.override.style';
/** @namespace Pwa/Component/ProductConfigurableAttributes/Component/ProductConfigurableAttributes */
export class ProductConfigurableAttributes extends SourceProductConfigurableAttributes {
    renderDropdown(option, index) {
        const {
            updateConfigurableVariant,
            getIsConfigurableAttributeAvailable,
            parameters,
            isEnable,
            addIdToIdList,
            enableDropDown,
            selectedIdList,
            dropDownCount,
            selectedValueList,
            selectAndSetValue
        } = this.props;

        return (
            <ProductConfigurableAttributeDropdown
              option={ option }
              selectedValueList={ selectedValueList }
              selectAndSetValue={ selectAndSetValue }
              isEnable={ isEnable[index] }
              selectedIdList={ selectedIdList }
              dropDownCount={ dropDownCount }
              index={ index }
              addIdToIdList={ addIdToIdList }
              enableDropDown={ enableDropDown }
              updateConfigurableVariant={ updateConfigurableVariant }
              getIsConfigurableAttributeAvailable={ getIsConfigurableAttributeAvailable }
              parameters={ parameters }
            />
        );
    }

    renderConfigurableAttributes() {
        const {
            configurable_options,
            isExpandable
        } = this.props;

        const newArray = [];
        Object.values(configurable_options).map((option) => newArray.push(option));
        return Object.values(configurable_options).map((option) => {
            const index = newArray.indexOf(option);
            const {
                attribute_label,
                attribute_options
            } = option;

            // const [{ swatch_data }] = attribute_value ? Object.values(attribute_value) : [{}];
            const [{ swatch_data }] = attribute_options ? Object.values(attribute_options) : [{}];
            const isSwatch = !!swatch_data;
            // render content without heading and subheading
            if (!isExpandable) {
                return isSwatch ? this.renderSwatch(option) : this.renderDropdown(option, index);
            }

            return (
                <div className="ProductConfigAttributesWrap">
                    <p block="ProductConfigurableAttributes" elem="Title">{ attribute_label }</p>
                    { isSwatch ? this.renderSwatch(option) : this.renderDropdown(option, index) }
                </div>
            );
        });
    }

    removeObjectWithId(arr, id) {
        const objWithIdIndex = arr.findIndex((obj) => obj.id === id); arr.splice(objWithIdIndex, 1); return arr;
    }

    renderSwatch(option) {
        const { attribute_values, attribute_code } = option;
        const {
            configurable_options,
            variantsConfig
        } = this.props;
        // eslint-disable-next-line no-unused-vars, fp/no-let
        let checkVariants = [];
        if (Object.keys(configurable_options).length === 1 && Object.keys(configurable_options)[0] === 'color') {
            // eslint-disable-next-line fp/no-let
            for (let i = 0; i < variantsConfig.length; i++) {
                checkVariants.push(variantsConfig[i].attributes.color.attribute_value);
            }
        } else {
            checkVariants = attribute_values;
        }

        return (
            <div
              block="ProductConfigurableAttributes"
              elem="SwatchList"
              key={ attribute_code }
            >
                { checkVariants.map((attribute_value) => (
                    this.renderConfigurableAttributeValue({ ...option, attribute_value })
                )) }
            </div>
        );
    }

    // render() {
    //     const { isReady, mix } = this.props;

    //     return (
    //         <div
    //           block="ProductConfigurableAttributes"
    //           mods={ { isLoading: !isReady } }
    //           mix={ mix }
    //         >
    //             { isReady ? this.renderConfigurableAttributes() : this.renderPlaceholders() }
    //         </div>
    //     );
    // }
}

export default ProductConfigurableAttributes;
