/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isEmpty } from 'lodash';

import CustomerGarageQuery from 'Query/CustomerGarage.query';
import { fetchMutation, QueryDispatcher } from 'Util/Request';

import {
    addItemInGarageList, removeItemFromGarageList, setLoading, updateGarageList
} from './CustomerGarage.action';
/**
 * CMS Page Dispatcher
 * @class CmsPageDispatcher
 * @extends QueryDispatcher
 * @namespace Pwa/Store/CustomerGarage/Dispatcher/CustomerGarageDispatcher
 */
export class CustomerGarageDispatcher extends QueryDispatcher {
    onSuccess(data = {}, dispatch) {
        const { customerVehicle = {} } = data;
        if (!isEmpty(customerVehicle)) {
            localStorage.setItem('vehicleDataLocal', JSON.stringify(customerVehicle));
            dispatch(updateGarageList({ data: customerVehicle }, false));
        }
    }

    onError(_, dispatch) {
        dispatch(updateGarageList({ data: {} }, false));
    }

    /**
     * Prepare CMS Page query
     * @param {{url_key: String, title: Int, content: String, content_heading: String, page_layout: String, meta_title: String, meta_description: String, meta_keywords, string}} options A object containing different aspects of query, each item can be omitted
     * @return {Query} CMS Page query
     * @memberof CmsPageDispatcher
     */
    prepareRequest(options, dispatch) {
        dispatch(updateGarageList({}, true));
        return CustomerGarageQuery.getList(options);
    }

    removeItem(id, dispatch) {
        dispatch(setLoading(true));
        return fetchMutation(CustomerGarageQuery.removeItem(id)).then(
            /** @namespace Pwa/Store/CustomerGarage/Dispatcher/fetchMutation/then */
            () => {
                dispatch(removeItemFromGarageList(id, false));
            },
            /** @namespace Pwa/Store/CustomerGarage/Dispatcher/fetchMutation/then */
            (error) => {
                dispatch(setLoading(false));
            }
        );
    }

    addItem(data, dispatch) {
        // const { year, make, model, purchase_date, nick_name } = data
        dispatch(setLoading(true));
        return fetchMutation(CustomerGarageQuery.addItem(data)).then(
            /** @namespace Pwa/Store/CustomerGarage/Dispatcher/fetchMutation/then */
            ({ setCustomerVehicle }) => {
                dispatch(addItemInGarageList({ ...data, ...setCustomerVehicle }, false));
            },
            /** @namespace Pwa/Store/CustomerGarage/Dispatcher/fetchMutation/then */
            (error) => {
                dispatch(setLoading(false));
            }
        );
    }
}

export default new CustomerGarageDispatcher();
