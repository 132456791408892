import {
    clearWishlist as SourceClearWishlist,
    deleteProperty as SourceDeleteProperty,
    getInitialState as SourceGetInitialState,
    PRODUCTS_IN_WISHLIST as SourcePRODUCTS_IN_WISHLIST,
    removeItemFromWishlist as SourceRemoveItemFromWishlist,
    updateAllProductsInWishlist as SourceUpdateAllProductsInWishlist,
    updateItemOptions as SourceUpdateItemOptions,
    WishlistReducer as SourceWishlistReducer
} from 'SourceStore/Wishlist/Wishlist.reducer';

// TODO: implement PRODUCTS_IN_WISHLIST
export const PRODUCTS_IN_WISHLIST = SourcePRODUCTS_IN_WISHLIST;

// TODO: implement getInitialState
export const getInitialState = SourceGetInitialState;

// TODO: implement deleteProperty
export const deleteProperty = SourceDeleteProperty;

// TODO: implement removeItemFromWishlist
export const removeItemFromWishlist = SourceRemoveItemFromWishlist;

// TODO: implement clearWishlist
export const clearWishlist = SourceClearWishlist;

// TODO: implement updateAllProductsInWishlist
export const updateAllProductsInWishlist = SourceUpdateAllProductsInWishlist;

// TODO: implement updateItemOptions
export const updateItemOptions = SourceUpdateItemOptions;

// TODO: implement WishlistReducer
export const WishlistReducer = SourceWishlistReducer;

export default WishlistReducer;
