/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import {
    ADD_NEW_COMPANY_ROLES,
    EDIT_COMPANY_ROLES,
    SAVE_COMPANY_ROLES_DATA,
    SET_COMPANY_ROLES_LOADING,
    UPDATE_COMPANY_ROLES_DATA,
    UPDATE_COMPANY_ROLES_EDITOR_DATA
} from './CompanyRoles.action';

export const initialState = {
    companyRoles: {},
    isLoading: true,
    isOpenEdit: false,
    editorData: {},
    isAddRole: false
};

/** @namespace Pwa/Store/CompanyRoles/Reducer/CompanyRolesReducer */
export const CompanyRolesReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        isOpenEdit = false,
        companyRoles,
        editorData,
        isAddRole
    } = action;

    const list = state.data;

    switch (type) {
    case UPDATE_COMPANY_ROLES_EDITOR_DATA:
        return {
            ...state,
            isLoading,
            editorData
        };
    case UPDATE_COMPANY_ROLES_DATA:
        return {
            ...state,
            isLoading,
            companyRoles
        };
    case SET_COMPANY_ROLES_LOADING:
        return {
            ...state,
            isLoading
        };

    case EDIT_COMPANY_ROLES:
        return {
            ...state,
            isOpenEdit
        };
    case ADD_NEW_COMPANY_ROLES:
        return {
            ...state,
            isAddRole
        };
    case SAVE_COMPANY_ROLES_DATA:
        const updated_company_users = {};
        return {
            ...state,
            customer_interest: updated_company_users,
            isLoading
        };
    default:
        return state;
    }
};

export default CompanyRolesReducer;
