/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ProductListQuery from 'Query/ProductList.query';
import { updateLinkedProducts } from 'Store/LinkedProducts/LinkedProducts.action';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchQuery, QueryDispatcher } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

export const LINKED_PRODUCTS = 'LINKED_PRODUCTS';

/**
 * Linked Prodcts List Dispatcher
 * @class LinkedProductsDispatcher
 * @extends QueryDispatcher
 * @namespace Pwa/Store/LinkedProducts/Dispatcher/LinkedProductsDispatcher */
export class LinkedProductsDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct('LinkedProducts', ONE_MONTH_IN_SECONDS);
    }

    onSuccess(data = {}, dispatch, _product_links) {
        const { productLinkedProduct } = data;
        const crosssellData = productLinkedProduct?.crosssell_products ?? [];
        const upsellData = productLinkedProduct?.upsell_products ?? [];
        const relatedData = productLinkedProduct?.related_products ?? [];
        const successData = {
            crosssell: {
                items: crosssellData,
                total_count: crosssellData.length
            },
            upsell: {
                items: upsellData,
                total_count: upsellData.length
            },
            related: {
                items: relatedData,
                total_count: relatedData.length
            }
        };

        BrowserDatabase.setItem(
            successData, LINKED_PRODUCTS
        );
        dispatch(updateLinkedProducts(
            successData
        ));
    }

    onError(error, dispatch) {
        dispatch(showNotification('error', __('Error fetching LinkedProducts!'), error));
    }

    /**
     * Prepare LinkedProducts query
     * @return {Query} ProductList query
     * @memberof LinkedProductsDispatcher
     * @param product_links
     */
    prepareRequest(sku) {
        return [
            ProductListQuery.getUpsellCrossSellData(
                sku
            )
        ];
    }

    /**
     * Clear linked products list
     * @param {{productsSkuArray: Array<String>}} options A object containing different aspects of query, each item can be omitted
     * @return {Query} ProductList query
     * @memberof LinkedProductsDispatcher
     */
    clearLinkedProducts(dispatch, updateCrossSell = false) {
        const linkedProducts = {
            upsell: { total_count: 0, items: [] },
            related: { total_count: 0, items: [] },
            crosssell: { total_count: 0, items: [] }
        };

        BrowserDatabase.setItem(linkedProducts, LINKED_PRODUCTS);

        dispatch(updateLinkedProducts({
            ...linkedProducts,
            updateCrossSell
        }));
    }

    async fetchCrossSellProducts(dispatch, sku) {
        const query = this.prepareRequest(sku);
        const data = await fetchQuery(query);
        const { productLinkedProduct } = data;
        const crosssellData = productLinkedProduct.crosssell_products ?? [];
        const linkedProducts = BrowserDatabase.getItem(LINKED_PRODUCTS) || {};

        Object.assign(linkedProducts, {
            crosssell: {
                items: crosssellData,
                total_count: crosssellData.length
            },
            updateCrossSell: true
        });
        dispatch(updateLinkedProducts(linkedProducts));
    }

    clearCrossSellProducts(dispatch) {
        const linkedProducts = BrowserDatabase.getItem(LINKED_PRODUCTS) || {};

        Object.assign(linkedProducts, {
            crosssell: { total_count: 0, items: [] },
            updateCrossSell: true
        });

        dispatch(updateLinkedProducts(linkedProducts));
    }
}

export default new LinkedProductsDispatcher();
