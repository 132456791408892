/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/** @namespace Pwa/Query/ErrorDetails/Query/ErrorDetailsQuery */
export class ErrorDetailsQuery {
    sendError(errDetails, componentStack) {
        const input = {
            errDetails,
            componentStack
        };

        return new Field('orderSuccessError')
            .addArgument('input', 'OrdererrorPayload!', input)
            .addField('success');
    }
}

export default new ErrorDetailsQuery();
