/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    compact, filter, get, map, set, unset, values
} from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { v1 as uuid } from 'uuid';

// import { goToPreviousHeaderState } from 'Store/Header';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import ProductDispatcher from 'Store/Product/Product.dispatcher';
import { ProductType } from 'Type/ProductList';

import EmailFriendForm from './EmailFriendForm.component';
// export const ProductDispatcher = import(
//     'Store/Product/Product.dispatcher'
// );

/** @namespace Pwa/Component/EmailFriendForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    isSignedIn: state.MyAccountReducer.isSignedIn
});

/** @namespace Pwa/Component/EmailFriendForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    emailFriend: (options) => ProductDispatcher.emailFriend(dispatch, options),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE))
});

/** @namespace Pwa/Component/EmailFriendForm/Container/EmailFriendFormContainer */
export class EmailFriendFormContainer extends PureComponent {
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        product: ProductType.isRequired
    };

    containerFunctions = ({
        onSubmitAttempt: this._onSubmitAttempt.bind(this),
        onSubmitSuccess: this._onSubmitSuccess.bind(this),
        handleNameChange: this._handleFieldChange.bind(this, 'name'),
        handleEmailChange: this._handleFieldChange.bind(this, 'email'),
        handleDescriptionChange: this._handleFieldChange.bind(this, 'description'),
        onError: this._onError.bind(this)
    });

    __construct(props) {
        super.__construct(props);

        const { customer: { firstname: name } } = this.props;
        const id = uuid();
        const recipients = { [id]: {} };
        const data = { name, recipients };

        this.state = {
            isLoading: false,
            data
        };
    }

    _onError() {
        this.setState({ isLoading: false });
    }

    _onSubmitAttempt(_, invalidFields) {
        const { showNotification } = this.props;
        const { data = {} } = this.state;

        const SubmissionNotValid = invalidFields || !(compact(values(data)).length === 4);

        if (SubmissionNotValid) {
            showNotification('error', 'Incorrect data! Please check fields.');
        }

        this.setState({ isLoading: !SubmissionNotValid });
    }

    _onSubmitSuccess() {
        const {
            data
        } = this.state;
        const {
            product,
            customer,
            addProductQA,
            hideActiveOverlay,
            goToPreviousHeaderState,
            emailFriend
        } = this.props;

        // this.setState({ isLoading: false });

        // return

        const {
            name,
            email,
            description: message,
            recipients
        } = data;

        const { id: product_id } = product;
        const recipientList = map(
            filter(
                recipients,
                (r) => r.hide !== true
            ),
            ({ name, email }) => ({ name, email })
        );

        emailFriend({
            product_id,
            sender: {
                name,
                email,
                message
            },
            recipients: recipientList
        }).then(
            /** @namespace Pwa/Component/EmailFriendForm/Container/emailFriend/then */
            (success) => {
                if (success) {
                    this.setState({
                        data: {},
                        isLoading: false
                    });

                    goToPreviousHeaderState();
                    hideActiveOverlay();

                    return;
                }
                this.setState({ isLoading: false });
            }
        );
    }

    _handleFieldChange(fieldName, value) {
        this.setState(({ data }) => ({
            data: { ...data, [fieldName]: value }
        }));
    }

    handleRecipientFieldChange = (id, key, value) => {
        const { data: { recipients = {} } } = this.state;

        if (!key) {
        // Only id needs to be set
            const currentValue = get(recipients, id, {});
            set(recipients, id, currentValue);
        }

        if (key) {
            set(recipients, `${id}.${key}`, value);
        }

        this._handleFieldChange('recipients', recipients);
    };

    // This function closed the popup unexpectedly, adding alternate approach to hide the removed value
    removeRecipientFieldOld = (key) => {
        const { data: { recipients = {} } } = this.state;
        unset(recipients, key);
        this._handleFieldChange('recipients', recipients);
    };

    removeRecipientField = (id) => {
        const { data: { recipients = {} } } = this.state;
        set(recipients, `${id}.hide`, true);
        this._handleFieldChange('recipients', recipients);
    };

    render() {
        return (
            <EmailFriendForm
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.state }
              handleRecipientFieldChange={ this.handleRecipientFieldChange }
              removeRecipientField={ this.removeRecipientField }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailFriendFormContainer);
