/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import {
    NewVersionPopup as SourceNewVersionPopup
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.component';

import './NewVersionPopup.style.override';

/** @namespace Pwa/Component/NewVersionPopup/Component/NewVersionPopup */
export class NewVersionPopup extends SourceNewVersionPopup {
    // TODO implement logic
}

export default NewVersionPopup;
