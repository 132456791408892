/* eslint-disable no-undef */
/* eslint-disable no-console */
import { get } from 'lodash';

// import PartsFinderQuery from 'Query/PartsFinder.query';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { QueryDispatcher } from 'Util/Request';

export const yearData = [
    { value: 65, label: '2025' },
    { value: 64, label: '2024' },
    { value: 63, label: '2023' },
    { value: 62, label: '2022' },
    { value: 61, label: '2021' },
    { value: 60, label: '2020' },
    { value: 59, label: '2019' },
    { value: 58, label: '2018' },
    { value: 57, label: '2017' },
    { value: 56, label: '2016' },
    { value: 55, label: '2015' },
    { value: 54, label: '2014' },
    { value: 53, label: '2013' },
    { value: 52, label: '2012' },
    { value: 51, label: '2011' },
    { value: 50, label: '2010' },
    { value: 49, label: '2009' },
    { value: 48, label: '2008' },
    { value: 47, label: '2007' },
    { value: 46, label: '2006' },
    { value: 45, label: '2005' },
    { value: 44, label: '2004' },
    { value: 43, label: '2003' },
    { value: 42, label: '2002' },
    { value: 41, label: '2001' },
    { value: 40, label: '2000' },
    { value: 39, label: '1999' },
    { value: 38, label: '1998' },
    { value: 37, label: '1997' },
    { value: 36, label: '1996' },
    { value: 35, label: '1995' },
    { value: 34, label: '1994' },
    { value: 33, label: '1993' },
    { value: 32, label: '1992' },
    { value: 31, label: '1991' },
    { value: 30, label: '1990' },
    { value: 29, label: '1989' },
    { value: 28, label: '1988' },
    { value: 27, label: '1987' },
    { value: 26, label: '1986' },
    { value: 25, label: '1985' },
    { value: 24, label: '1984' },
    { value: 23, label: '1983' },
    { value: 22, label: '1982' },
    { value: 21, label: '1981' },
    { value: 20, label: '1980' },
    { value: 19, label: '1979' },
    { value: 18, label: '1978' },
    { value: 17, label: '1977' },
    { value: 16, label: '1976' },
    { value: 15, label: '1975' },
    { value: 14, label: '1974' },
    { value: 13, label: '1973' },
    { value: 12, label: '1972' },
    { value: 11, label: '1971' },
    { value: 10, label: '1970' },
    { value: 9, label: '1969' },
    { value: 8, label: '1968' },
    { value: 7, label: '1967' },
    { value: 6, label: '1966' },
    { value: 5, label: '1965' },
    { value: 4, label: '1964' },
    { value: 3, label: '1963' },
    { value: 2, label: '1962' },
    { value: 1, label: '1961' }
];
/** @namespace Pwa/Store/PartsFinder/Years/Dispatcher/YearsDispatcher */
export class YearsDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess(response, dispatch, { cb }) {
        const data = get(response, 'years', []);
        dispatch(cb({ data }, false));
    }

    onError(_, dispatch, { cb }) {
        dispatch(cb({ }, false));
        dispatch(updateNoMatch(true));
    }

    prepareRequest(options, dispatch) {
        const { cb } = options;
        dispatch(cb({ data: yearData }, false));
    }
}

export default new YearsDispatcher();
