/* eslint-disable no-undef */
/* eslint-disable new-cap */
import { connect } from 'react-redux';

import {
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps
} from 'SourceComponent/SearchField/SearchField.container';

import SearchField from './SearchField.component';

/** @namespace Pwa/Component/SearchField/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    isLoading: state.SearchBarReducer.isLoading,
    customer: state.MyAccountReducer.customer
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/SearchField/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
