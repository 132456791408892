/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable @scandipwa/scandipwa-guidelines/always-both-mappings */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-console */
// import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import CountrySelector from './CountrySelector.component';

/** @namespace Pwa/Component/CountrySelector/Container/CountrySelectorContainer */
export class CountrySelectorContainer extends Component {
    static propTypes = {
        website_name: PropTypes.any,
        default_country: PropTypes.any
    };

    render() {
        return (
            <CountrySelector
              currentStore={ 5 } // This will be removed later
              { ...this.props }
            />
        );
    }
}

/** @namespace Pwa/Component/CountrySelector/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    // data: get(state, 'HeaderAndFooterReducer.desktopMenu.store_switcher[0]', {}),
    website_name: state.ConfigReducer.website_name,
    default_country: state.ConfigReducer.default_country
    // blocks: state.CmsBlocksAndSliderReducer.blocks
});

export default connect(mapStateToProps, null)(CountrySelectorContainer);
