/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
/* eslint-disable no-console */
import { isInteger } from 'lodash';

import { Field } from 'SourceUtil/Query';
import history from 'Util/History';

class PartsFinderQuery {
    getYears() {
        // return new Field('years')
        //     .addField(this.getData());

        // const graphql = 'query={years%20{value%20label%20}%20}';
        const graphql = `query={
            years {
                value
                label
            }
        }`;

        return graphql;
    }

    getMakes({ yearId }) {
        // return new Field('partsFinderMakes')
        //     .addArgument('year', 'ID!', year)
        //     .addField(this.getData());

        if (!yearId) {
            throw new Error('Missing argument `options`');
        }
        const { storeId } = window.storeConfig;
        // const graphql = `query={makes(storeId:${storeId},yearId:${yearId})%20{value%20label%20}%20}`;
        const graphql = `query={
            makes(
                storeId:${storeId},
                yearId:${yearId}
            )
            { 
                value
                label
                group
            }
        }`;

        return graphql;
    }

  getData = () => new Field('data')
      .addFieldList([
          'label',
          'value'
      ]);

  getModels({ yearId, makeId }) {
      //   return new Field('partsFinderModels')
      //       .addArgument('make', 'ID!', make)
      //       .addField(this.getData());
      if (!yearId && makeId) {
          throw new Error('Missing argument `options`');
      }
      const { storeId } = window.storeConfig;

      //   const graphql = `query={models(storeId:${storeId},yearId:${yearId},makeId:${makeId})%20{value%20label%20}%20}`;
      const graphql = `query={
                            models(
                                storeId:${storeId},
                                yearId:${yearId},
                                makeId:${makeId}
                            )
                            {
                                value
                                label
                                group
                            }
                        }`;

      return graphql;
  }

  getOptions({ yearId, makeId, modelId }) {
      //   return new Field('partsFinderOptions')
      //       .addArgument('model', 'ID!', make)
      //       .addField(this.getData());
      if (!yearId && makeId && modelId) {
          throw new Error('Missing argument `options`');
      }
      const { storeId } = window.storeConfig;

      //   const graphql = `query={options(storeId:${storeId},yearId:${yearId},makeId:${makeId},modelId:${modelId})%20{value%20label%20}%20}`;
      const graphql = `query={
                            options(
                                    storeId:${storeId},
                                    yearId:${yearId},
                                    makeId:${makeId},
                                    modelId:${modelId}
                                )
                                {
                                    value
                                    label
                                    vehicle{
                                        id
                                    }
                                }
                        }`;

      return graphql;
  }

  getProducts({
      yearId, makeId, modelId, optionId
  }) {
      //   return new Field('partsFinderOptions')
      //       .addArgument('model', 'ID!', make)
      //       .addField(this.getData());
      if (!yearId && makeId && modelId && optionId) {
          throw new Error('Missing argument `options`');
      }
      const { storeId } = window.storeConfig;
      //   const graphql = `query={products(storeId:${storeId},yearId:${yearId},makeId:${makeId},modelId:${modelId},optionId:${optionId})%20{sku%20}%20}`;
      //   const graphql = `query={
      //                         products(
      //                             storeId:${storeId},
      //                             yearId:${yearId},
      //                             makeId:${makeId},
      //                             modelId:${modelId},
      //                             optionId:${optionId}
      //                         )
      //                         {
      //                             sku
      //                         }
      //                     }`;
      const vehicle_url = window.location.href;
      const vehicle_id = parseFloat(vehicle_url.split('-').pop());
      if (!isInteger(vehicle_id)) {
          return history.push('/');
      }
      const graphql = `query={
        vehicle(vehicleId:${vehicle_id} ){
            id
            make {
                id
                name
            }
            model {
                id
                name
            }
            option {
                id
                name
            },
            products{
                sku
            }
        }
    }`;

      return graphql;
  }

  getProductsFromOptionId(
      vehicle_id
  ) {
      if (!vehicle_id) {
          throw new Error('Missing argument `options`');
      }
      const graphql = `query={
      vehicle(vehicleId:${vehicle_id} ){
          id
          make {
              id
              name
          }
          model {
              id
              name
          }
          option {
              id
              name
          },
          products{
              sku
          }
      }
  }`;

      return graphql;
  }
}

export default new PartsFinderQuery();
