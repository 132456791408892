/* eslint-disable no-console */
import { filter } from 'lodash';

import {
    FETCH_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_LIST,
    REMOVE_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_ITEM,
    SET_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_LOADING
} from './CustSubscriptions.action';

export const initialState = {
    data: {},
    isLoading: true
};

/** @namespace Pwa/Store/CustSubscriptions/Reducer/CustSubscriptionsReducer */
export const CustSubscriptionsReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        data
    } = action;

    const list = state.data;

    switch (type) {
    case FETCH_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_LIST:
        return {
            ...state,
            isLoading,
            data
        };
    case SET_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_LOADING:
        return {
            ...state,
            isLoading
        };
    case REMOVE_CUSTOMER_BACK_IN_STOCK_SUBSCRIPTION_ITEM:
        const removeItemId = data;
        const updatedList = filter(list, (l) => l.alert_id !== removeItemId);
        return {
            ...state,
            data: updatedList,
            isLoading
        };
    default:
        return state;
    }
};

export default CustSubscriptionsReducer;
