/* eslint-disable no-magic-numbers */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import {
    isEmpty
} from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { items } from 'Component/CompanyStructure/CompanyStructure.component';
import { HOME_TAB } from 'Component/NavigationTabs/NavigationTabs.config';
import CartQuery from 'Query/Cart.query';
import ProductListQuery from 'Query/ProductList.query';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { updateAddedQuickItems, updateLoading, updateQuickItemDetails } from 'Store/QuickOrder/QuickOrder.action';
import QuickOrderDispatcher from 'Store/QuickOrder/QuickOrder.dispatcher';
import { HistoryType, LocationType, MatchType } from 'Type/Common.type';
import history, { history as browserHistory } from 'Util/History';
import { fetchMutation, fetchQuery } from 'Util/Request';

import QuickOrder from './QuickOrder.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Pwa/Route/QuickOrder/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isLoading: state.QuickOrderReducer.isLoading,
    products: state.QuickOrderReducer.products,
    addedProducts: state.QuickOrderReducer.addedProducts

});

export const DEFAULT_STATE_NAME = 'default';

/** @namespace Pwa/Route/QuickOrder/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => {
            dispatcher.updateInitialCartData(dispatch);
        }
    ),
    requestQuickItems: (options) => QuickOrderDispatcher.handleData(dispatch, options),
    updateAddedQuickItems: (totalAddedItems) => dispatch(updateAddedQuickItems(totalAddedItems)),
    updateLoading: (loading) => dispatch(updateLoading(loading)),
    updateQuickItemDetails: (products) => dispatch(updateQuickItemDetails(products))

});

export const DEFAULT_HEADER_STATE = {
    name: DEFAULT_STATE_NAME,
    isHiddenOnMobile: false
};

/** @namespace Pwa/Route/QuickOrder/Container/QuickOrderContainer */
export class QuickOrderContainer extends Component {
  static propTypes = {
      location: LocationType,
      history: HistoryType,
      match: MatchType
  };

  state = {
      loading: false,
      error: '',
      readyToQuickOrderItems: []
  };

  componentDidMount() {
      // this._updateHeaderState();
  }

  containerFunctions = ({
      requestItemsForSku: this._requestItemsForSku.bind(this),
      deletItem: this._deletItem.bind(this),
      addToCart: this._addToCart.bind(this),
      resetError: this._resetError.bind(this)

  });

  _requestItemsForSku(data) {
      const { skus } = data;
      this.setState({ error: '' });
      // const { requestQuickItems } = this.props;
      if (skus) {
          // requestQuickItems(skus);
          this.fetchProducts(skus);
      }
  }

  _resetError() {
      this.setState({ error: '' });
  }

  _handleError = (error) => {
      this.setState({ loading: false });

      const [{ message, debugMessage }] = error;
      showNotification('error', debugMessage || message);
  };

  async fetchProducts(productsskuarray) {
      const skuSize = productsskuarray.length;
      let options = { sku: { in: productsskuarray } };
      if (skuSize == 1) {
          options = { sku: { eq: productsskuarray[0] } };
      }

      this.setState({ loading: true });
      // const options= { sku:{in:["KRLD034T","KRLD037T","RRAC137","RRAC149","WTAN030","JCHO014","GBHO012","TBRA017","RRAC159","RRAC103","RRAC024","RRAC023","RRAC160","RRAC059","RRAC169","TBMK011","SSLD007"]} };
      fetchQuery(ProductListQuery.getPopupQuery(options)).then(
          /** @namespace Pwa/Route/QuickOrder/Container/fetchQuery/then */
          (data) => {
              const { products } = data;
              const { readyToQuickOrderItems = [] } = this.state;
              const { items = [] } = products;
              if (items.length > 0) {
                  let i = 0;

                  items.forEach((it) => {
                      if (i <= skuSize) {
                          readyToQuickOrderItems.push({ ...it });
                      }
                      i++;
                  });
                  const filteredArr = readyToQuickOrderItems.reduce((acc, current) => {
                      const x = acc.find((it) => it.id === current.id);
                      if (!x) {
                          return acc.concat([current]);
                      }

                      return acc;
                  }, []);

                  this.setState({ productsSuggestions: products, readyToQuickOrderItems: filteredArr, loading: false });
              } else {
                  this.setState({ error: 'The SKU was not found in the catalog.' });
              }
          },
          this._handleError
      );
  }

  async _addToCart(itemArray) {
      if (itemArray.length < 1) {
          return;
      }
      this.setState({ loading: true });
      const input = [];
      itemArray.forEach((item) => {
          const {
              id, sku, stock_status, qty, name
          } = item;

          input.push({
              id, sku, name, qty
          });
      });
      // const options= { sku:{in:["KRLD034T","KRLD037T","RRAC137","RRAC149","WTAN030","JCHO014","GBHO012","TBRA017","RRAC159","RRAC103","RRAC024","RRAC023","RRAC160","RRAC059","RRAC169","TBMK011","SSLD007"]} };
      fetchMutation(CartQuery.getAddToCartMutionQuery(input)).then(
          /** @namespace Pwa/Route/QuickOrder/Container/fetchMutation/then */
          (data) => {
              const { status } = data;
              showNotification('success', status);
              this.props.resetCart();
              setTimeout(() => {
                  this.setState({ loading: false });
                  history.push('/cart');
              }, 2000);

              //   const { readyToQuickOrderItems = [] } = this.state;
              //   const { items = [] } = products;
              //   if (items.length > 0) {
              //       let i = 0;

              //       items.forEach((it) => {
              //           if (i <= skuSize) {
              //               readyToQuickOrderItems.push({ ...it });
              //           }
              //           i++;
              //       });
              //       this.setState({ productsSuggestions: products, loading: false });
              //   } else {
              //       this.setState({ error: 'The SKU was not found in the catalog.' });
              //   }
          },
          this._handleError
      );
  }

  _deletItem(id) {
      const { requestQuickItems } = this.props;
      const { readyToQuickOrderItems } = this.state;
      if (!isEmpty(`${id}`)) {
          const index = readyToQuickOrderItems.findIndex((o) => o.id == id);
          if (index !== -1) {
              readyToQuickOrderItems.splice(index, 1);
          }
          this.setState({ readyToQuickOrderItems });
      }
  }

  _updateHeaderState() {
      const { changeHeaderState } = this.props;
      changeHeaderState({
          name: __('Quick Order'),
          title: __('Quick Order'),
          onBackClick: () => this.handleContinueShopping()
      });
  }

  handleContinueShopping =() => {

  };

  render() {
      return (
        <QuickOrder
          { ...this.props }
          { ...this.state }
          { ...this.containerFunctions }
        />
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickOrderContainer);
