import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCcAmex,
    faCcMastercard,
    faCcPaypal,
    faCcVisa,
    faFacebook,
    faInstagram,
    faPinterestP,
    faTumblr,
    faTwitter,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
    faAngleLeft,
    faAngleRight,
    faCheck,
    faCheckSquare,
    faCoffee,
    faEnvelope,
    faGlobe,
    faGlobeAmericas,
    faMapMarker,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

import {
    faTestIcon,
    friAmazon,
    friComodo,
    friGiropay,
    friKlarna,
    friPayDirect,
    friSofort
} from './friIcons';

library.add(
    faFacebook,
    faTwitter,
    faPinterestP,
    faYoutube,
    faInstagram,
    faTumblr,
    faCcMastercard,
    faCcVisa,
    faCcPaypal,
    faCcAmex,
    faCheckSquare,
    faCoffee,
    faTestIcon,
    friAmazon,
    friComodo,
    friPayDirect,
    friSofort,
    faAngleLeft,
    faAngleRight,
    faMapMarker,
    faCheck,
    faEnvelope,
    faGlobe,
    faGlobeAmericas,
    faTrashAlt,
    friGiropay,
    friKlarna
);
