/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ReCaptcha } from 'react-recaptcha-v3';

import Field from 'Component/Field';
import Form from 'Component/Form';
import WithGoogleRecaptcha from 'Component/GoogleRecaptchaV3/GoogleRecaptchaV3.component';
import Loader from 'Component/Loader';

import './ProductQAForm.style';

/** @namespace Pwa/Component/ProductQAForm/Component/ProductQAFormComponent */
export class ProductQAForm extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        onQASubmitAttempt: PropTypes.func.isRequired,
        onQASubmitSuccess: PropTypes.func.isRequired,
        onQAError: PropTypes.func.isRequired,
        handleNameChange: PropTypes.func.isRequired,
        handleEmailChange: PropTypes.func.isRequired,
        handleDescriptionChange: PropTypes.func.isRequired,
        handleCaptchaChange: PropTypes.func.isRequired,
        qaData: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
            description: PropTypes.string,
            captcha: PropTypes.string
        }).isRequired
    };

    renderButton() {
        return (
            <button
              block="ProductQAForm"
              elem="Button"
              mix={ { block: 'Button' } }
            >
                { __('Add Question') }
            </button>
        );
    }

    onRecaptchaChange = (value) => {
        // console.log('Re captch value is', value);
    };

    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!
        // console.log(recaptchaToken, '<= your recaptcha token');
    };

    renderQAFormContent() {
        const {
            handleNameChange,
            handleEmailChange,
            handleDescriptionChange,
            handleCaptchaChange,
            showValidationError = 'noError',
            qaData
        } = this.props;

        const {
            name = '',
            email = '',
            description = ''
        } = qaData;
        const elmClass = `Content ${ showValidationError}`;
        return (
            <div
              block="ProductQAForm"
              elem="Wrapper"
            >
                <div
                  block="ProductQAForm"
                  elem={ elmClass }
                >
                    <Field
                      type="text"
                      label={ __('Name') }
                      id="name"
                      name="name"
                      validation={ ['notEmpty'] }
                      value={ name }
                      onChange={ handleNameChange }

                    />
                    <Field
                      type="email"
                      label={ __('Email Address') }
                      id="email"
                      name="email"
                      validation={ ['notEmpty', 'email'] }
                      value={ email }
                      onChange={ handleEmailChange }

                    />
                    <Field
                      type="textarea"
                      label={ __('Question') }
                      id="description"
                      name="description"
                      validation={ ['notEmpty'] }
                      value={ description }
                      onChange={ handleDescriptionChange }

                    />
                    { /* <ReCAPTCHA
                      sitekey="6Ld7rcMUAAAAAAD-PDPvZA2zE9fkqtHO0UtFaJK0"
                      onChange={ handleCaptchaChange }
                    /> */ }
                    { /* <ReCaptcha
                      sitekey={ window.storeConfig.reCaptchaKey }
                      // action='action_name'
                      verifyCallback={ handleCaptchaChange }
                    /> */ }
                    <WithGoogleRecaptcha />
                </div>
            </div>
        );
    }

    render() {
        const {
            isLoading,
            onQASubmitAttempt,
            onQASubmitSuccess,
            onQAError
        } = this.props;

        return (
            <Form
              key="product-qa"
              mix={ { block: 'ProductQAForm' } }
              onSubmit={ onQASubmitAttempt }
              onSubmitSuccess={ onQASubmitSuccess }
              onSubmitError={ onQAError }
            >
                <Loader isLoading={ isLoading } />
                { this.renderQAFormContent() }
                { this.renderButton() }
            </Form>
        );
    }
}

export default ProductQAForm;
