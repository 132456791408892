/* eslint-disable no-console */
/* eslint-disable max-len */
export const UPDATE_COMPANY_PRICELIST_DATA = 'UPDATE_COMPANY_PRICELIST_DATA';
export const SET_COMPANY_PRICELIST_LOADING = 'SET_COMPANY_PRICELIST_LOADING';
export const SAVE_COMPANY_PRICELIST_DATA = 'SAVE_COMPANY_PRICELIST_DATA';
export const UPDATE_COMPANY_PRICELIST_API_DATA = 'UPDATE_COMPANY_PRICELIST_API_DATA';
/** @namespace Pwa/Store/CompanyPriceList/Action/updateCompanyPriceListData */
export const updateCompanyPriceListData = ({ company = {} }, isLoading = false) => ({
    type: UPDATE_COMPANY_PRICELIST_DATA,
    company,
    isLoading
});

/** @namespace Pwa/Store/CompanyPriceList/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_COMPANY_PRICELIST_LOADING,
    isLoading
});

/** @namespace Pwa/Store/CompanyPriceList/Action/saveCompanyPriceListData */
export const saveCompanyPriceListData = (data, isLoading) => ({
    type: SAVE_COMPANY_PRICELIST_DATA,
    data,
    isLoading
});

/** @namespace Pwa/Store/CompanyPriceList/Action/updateCompanyPriceListApiData */
export const updateCompanyPriceListApiData = (api_data, isLoading) => ({
    type: UPDATE_COMPANY_PRICELIST_API_DATA,
    api_data,
    isLoading
});
