/* eslint-disable no-console */
import { UPDATE_CC_INPUT } from './Creditcard.action';

export const initialState = {
    cvc: '',
    expiry: '',
    name: '',
    number: '',
    cardType: ''
};

/** @namespace Pwa/Store/Creditcard/Reducer/CreditcardReducer */
export const CreditcardReducer = (state = initialState, action) => {
    const {
        type,
        data
    } = action;

    switch (type) {
    case UPDATE_CC_INPUT:
        return {
            ...state,
            ...data
        };
    default:
        return state;
    }
};

export default CreditcardReducer;
