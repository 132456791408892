/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_QUICK_ITEMS_DETAILS = 'UPDATE_QUICK_ITEMS_DETAILS';
export const UPDATE_QUICK_ITEMS_LOADING = 'UPDATE_QUICK_ITEMS_LOADING';
export const UPDATE_QUICK_ADDED_ITEMS = 'UPDATE_QUICK_ADDED_ITEMS';

/**
 * Update product list with new list (rewrite if already exists).
 * @param  {Array<Object>} items List of products returned from fetch
 * @param  {Number} totalItems Total number of products in this filter
 * @return {void}
 * @namespace Pwa/Store/QuickOrder/Action/updateQuickItemDetails */
export const updateQuickItemDetails = (products, isLoading) => ({
    type: UPDATE_QUICK_ITEMS_DETAILS,
    products,
    isLoading
});

/** @namespace Pwa/Store/QuickOrder/Action/updateLoading */
export const updateLoading = (isLoading) => ({
    type: UPDATE_QUICK_ITEMS_LOADING,
    isLoading
});

/** @namespace Pwa/Store/QuickOrder/Action/updateAddedQuickItems */
export const updateAddedQuickItems = (addedProducts) => ({
    type: UPDATE_QUICK_ADDED_ITEMS,
    addedProducts
});
