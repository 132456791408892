import {
    DISPLAY_MODE_BOTH as SourceDISPLAY_MODE_BOTH,
    DISPLAY_MODE_CMS_BLOCK as SourceDISPLAY_MODE_CMS_BLOCK,
    DISPLAY_MODE_PRODUCTS as SourceDISPLAY_MODE_PRODUCTS,
    LOADING_TIME as SourceLOADING_TIME
} from 'SourceRoute/CategoryPage/CategoryPage.config';

// TODO: implement LOADING_TIME
export const LOADING_TIME = SourceLOADING_TIME;

// TODO: implement DISPLAY_MODE_PRODUCTS
export const DISPLAY_MODE_PRODUCTS = SourceDISPLAY_MODE_PRODUCTS;

// TODO: implement DISPLAY_MODE_CMS_BLOCK
export const DISPLAY_MODE_CMS_BLOCK = SourceDISPLAY_MODE_CMS_BLOCK;

// TODO: implement DISPLAY_MODE_BOTH
export const DISPLAY_MODE_BOTH = SourceDISPLAY_MODE_BOTH;
export * from 'SourceRoute/CategoryPage/CategoryPage.config';
