/* eslint-disable fp/no-let */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable new-cap */
import { isSignedIn } from '@scandipwa/scandipwa/src/util/Auth';
import {
    compact, each, filter, get, isEmpty, map
} from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NotifyMe from 'Component/NotifyMe';
import { IN_STOCK } from 'Component/ProductCard/ProductCard.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps
    // WishlistDispatcher as SourceWishlistDispatcher
} from 'SourceComponent/AddToCart/AddToCart.container';
import { setAddToCartPopupVisibility } from 'Store/AddToCartPopUp/AddToCartPopUp.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { ProductType } from 'Type/ProductList';
import {
    ADD_TO_CART,
    BUNDLE,
    CONFIGURABLE,
    GIFT_CARD,
    GROUPED,
    validateProductQuantity
} from 'Util/Product';
import { magentoProductTransform } from 'Util/Product/Transform';

import AddToCart from './AddToCart.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

// TODO: implement WishlistDispatcher
// export const WishlistDispatcher = SourceWishlistDispatcher;

/** @namespace Pwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    cartTotals: state.CartReducer.cartTotals,
    wishlistItems: state.WishlistReducer.productsInWishlist
    // TODO extend mapStateToProps
});

/** @namespace Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    removeFromWishlist: (options) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeItemFromWishlist(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    setAddToCartPopupVisibility: (isShowingPupup) => dispatch(setAddToCartPopupVisibility(isShowingPupup))
});

/** @namespace Pwa/Component/AddToCart/Container/AddToCartContainer */
export class AddToCartContainer extends SourceAddToCartContainer {
    // TODO implement logic
    static propTypes = {
        isLoading: PropTypes.bool,
        product: ProductType.isRequired,
        quantity: PropTypes.number,
        notifyAdded: PropTypes.bool,
        configurableVariantIndex: PropTypes.number,
        groupedProductQuantity: PropTypes.objectOf(PropTypes.number).isRequired,
        showNotification: PropTypes.func.isRequired,
        setQuantityToDefault: PropTypes.func,
        addProduct: PropTypes.func.isRequired,
        removeFromWishlist: PropTypes.func.isRequired,
        wishlistItems: PropTypes.objectOf(ProductType).isRequired,
        onProductValidationError: PropTypes.func,
        productOptionsData: PropTypes.object,
        disableHandler: PropTypes.bool,
        giftCardData: PropTypes.object,
        disabled: PropTypes.bool,
        noNeedPopup: PropTypes.bool
    };

    static defaultProps = {
        quantity: 1,
        configurableVariantIndex: 0,
        setQuantityToDefault: () => {},
        onProductValidationError: () => {},
        isLoading: false,
        disableHandler: false,
        mix: {},
        disabled: false,
        notifyAdded: true,
        noNeedPopup: false
    };

    state = { isLoading: false, added: false };

    globalValidationMap = [
        this.validateStock.bind(this),
        this.validateQuantity.bind(this),
        this.validateCustomizable.bind(this),
        this.validateByType.bind(this)
    ];

    addToCartHandlerMap = {
        [CONFIGURABLE]: this.addConfigurableProductToCart.bind(this),
        [GROUPED]: this.addGroupedProductToCart.bind(this),
        [GIFT_CARD]: this.addGiftProductToCart.bind(this)
    };

    componentDidUpdate() {
    }

    containerFunctions = {
        addProductToCart: this.addProductToCart.bind(this),
        handleButtonClick: this.handleButtonClick.bind(this)
    };

    removeProductFromWishlist() {
        const {
            wishlistItems,
            removeFromWishlist,
            configurableVariantIndex,
            product,
            product: { type_id, variants = {} } = {}
        } = this.props;

        if (type_id !== CONFIGURABLE) {
            return;
        }

        const { sku } = variants[configurableVariantIndex] ?? product;

        const wishlistItemKey = Object.keys(wishlistItems)
            .find((key) => {
                const { wishlist: { sku: wSku } } = wishlistItems[key];

                return wSku === sku;
            });

        if (!isSignedIn() || wishlistItemKey === undefined) {
            return;
        }

        const { wishlist: { id: item_id } } = wishlistItems[wishlistItemKey];
        removeFromWishlist({ item_id, sku, noMessage: true });
    }

    addGroupedProductToCart() {
        const {
            product,
            product: { items },
            groupedProductQuantity,
            addProduct
        } = this.props;

        Promise.all(items.map((item) => {
            const { product: groupedProductItem } = item;

            const newProduct = {
                ...groupedProductItem,
                parent: product
            };

            const quantity = groupedProductQuantity[groupedProductItem.id];

            if (!quantity) {
                return Promise.resolve();
            }

            return addProduct({
                product: newProduct,
                quantity
            });
        })).then(
            /** @namespace Component/AddToCart/Container/addGroupedProductToCartPromiseAllThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addGroupedProductToCartPromiseAllCatch */
            () => this.resetLoading()
        );
    }

    addConfigurableProductToCart() {
        const {
            product,
            quantity,
            addProduct,
            configurableVariantIndex,
            productOptionsData,
            optionsSelected
        } = this.props;

        //     product: {
        //         ...product,
        //         configurableVariantIndex
        //     },
        //     quantity,
        //     productOptionsData
        // });
        addProduct({
            product: {
                ...product,
                configurableVariantIndex
            },
            quantity,
            optionsSelected,
            productOptionsData
        }).then(
            /** @namespace Component/AddToCart/Container/addConfigurableProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addConfigurableProductToCartAddProductCatch */
            () => this.resetLoading()
        );
    }

    addSimpleProductToCart() {
        const {
            product,
            quantity,
            addProduct,
            productOptionsData
        } = this.props;

        addProduct({
            product,
            quantity,
            productOptionsData
        }).then(
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductCatch */
            () => this.resetLoading()
        );
    }

    addProductToCart() {
        const {
            product: { type_id }
        } = this.props;

        const addToCartHandler = this.addToCartHandlerMap[type_id];

        if (addToCartHandler) {
            addToCartHandler();
            return;
        }

        this.addSimpleProductToCart();
    }

    // async addProductToCart() {
    //     const {
    //         addToCart, updateSelectedValues, product,
    //         giftCardData,
    //         quantity,
    //         productOptionsData,
    //         noNeedPopup,
    //         optionsSelected
    //     } = this.props;

    //     if (updateSelectedValues) {
    //         await updateSelectedValues();
    //     }

    //     if ((!product || Object.keys(product).length === 0) && !addToCart) {
    //         return;
    //     }

    //     this.setState({ isAdding: true });

    //     if (!this.validate()) {
    //         return;
    //     }

    //     if (typeof addToCart === 'function') {
    //         try {
    //             await addToCart();
    //         } finally {
    //             this.setState({ isAdding: false });
    //         }
    //     } else {
    //         const {
    //             quantity,
    //             cartId,
    //             fallbackAddToCart
    //         } = this.props;
    //         const magentoProduct = magentoProductTransform(ADD_TO_CART, product, quantity);

    //         try {
    //             await fallbackAddToCart({
    //                 products: magentoProduct,
    //                 cartId,
    //                 product,
    //                 giftCardData,
    //                 quantity,
    //                 productOptionsData,
    //                 noNeedPopup,
    //                 optionsSelected
    //             });
    //         } finally {
    //             this.setState({ isAdding: false });
    //         }
    //     }

    //     this.setState({ isAdding: false });
    // }

    handleButtonClick(e) {
        const {
            product: { type_id } = {},
            onProductValidationError,
            disableHandler,
            clearDropDownList,
            showNotification,
            cartTotals: { items }
        } = this.props;

        const checkForMultipleGiftCard = items?.some((item) => item?.sku === 'VOUC002');

        if (checkForMultipleGiftCard && type_id === 'amgiftcard') {
            return (
                showNotification('info', __('Only one gift card can be added at a time.'))
            );
        }

        if (clearDropDownList) {
            clearDropDownList();
        }
        if (disableHandler) {
            return null;
        }
        // if (!this.validateAddToCart()) {
        //     onProductValidationError(type_id);

        //     return;
        // }

        e.preventDefault();

        return (
            this.setState({ isLoading: true }, () => this.addProductToCart())
        );
    }

    addGiftProductToCart() {
        const {
            product,
            quantity,
            addProduct,
            productOptionsData,
            giftCardData = {},
            noNeedPopup = false
        } = this.props;

        addProduct({
            product,
            giftCardData,
            quantity,
            productOptionsData,
            noNeedPopup
        }).then(
        /** @namespace Pwa/Component/AddToCart/Container/addGiftProductToCartAddProductthen */
            () => this.afterAddToCart(),
            /** @namespace Pwa/Component/AddToCart/Container/addGiftProductToCartAddProductCatch */
            () => this.resetLoading()
        );
    }

    validateGiftProduct() {
        const {
            showNotification,
            giftCardData = {},
            onProductValidationError
        } = this.props;

        const {
            your_name = '', recipient_name = '', recipient_email = '', gift_message = '', giftPrice, giftImage
        } = giftCardData;

        if (onProductValidationError) {
            onProductValidationError();
        }

        if (isEmpty(your_name)) {
            showNotification('info', __('Sorry! Your Name is invalid!'));
            return false;
        }
        if (isEmpty(recipient_name)) {
            showNotification('info', __('Sorry! Recipient Name is invalid!'));
            return false;
        }
        if (isEmpty(recipient_email)) {
            showNotification('info', __('Sorry! Recipient Email is invalid!'));
            return false;
        }

        return true;
    }

    resetLoading() {
        this.setState({ isLoading: false });
    }

    afterAddToCart() {
        const {
            showNotification,
            setQuantityToDefault,
            setAddToCartPopupVisibility,
            notifyAdded,
            noNeedPopup = false
        } = this.props;

        showNotification('success', __('Product added to cart!'));
        setQuantityToDefault();

        this.removeProductFromWishlist();
        if (noNeedPopup) {
            this.setState({ isLoading: false, added: true });
        } else {
            this.setState({ isLoading: false });
        }
        setAddToCartPopupVisibility(true);
    }

    render() {
        const { product: { type_id = 'simple' } = {}, quantity } = this.props;

        const {
            configurableVariantIndex,
            product = {},
            product: {
                variants,
                items,
                sku,
                configurable_product_options_selection = []
            } = {}
        } = this.props;

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { stock_status = 'IN_STOCK', id } = productOrVariant;

        let bundle_stock_status = '';
        if (!isEmpty(items && items[0].options) && items.length > 0 && items[0].options.length > 0) {
            bundle_stock_status = items[0].options[0].product.stock_status;
        }

        let showNotifyMe = (stock_status === 'OUT_OF_STOCK' || bundle_stock_status === 'OUT_OF_STOCK');

        if (type_id === 'configurable' && configurableVariantIndex < 0) {
            showNotifyMe = false;
        }
        if (type_id === GROUPED) {
            showNotifyMe = false;
        }

        const { variant = [] } = configurable_product_options_selection;
        if ((type_id === 'configurable') && sku === ('KRTRCUSTOM' || 'KRLBCUSTOM')) {
            const { stock_status } = variant;
            if (stock_status === 'OUT_OF_STOCK') {
                showNotifyMe = true;
            }
        }

        return (
            <>
            { !showNotifyMe
              && (
                <AddToCart
                  { ...this.props }
                  { ...this.state }
                  { ...this.containerFunctions }
                />
              ) }
            { showNotifyMe
              && (
                <NotifyMe
                  quantity={ quantity }
                  productId={ id }
                />
              ) }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
