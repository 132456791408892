/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-typos */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable brace-style */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './Autocomplete.style';

/** @namespace Pwa/Component/AutoComplete/Autocomplete/Component/AutocompleteComponent */
export class AutocompleteComponent extends Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array),
        data: PropTypes.Array,
        name: PropTypes.string,
        updateParentStructure: PropTypes.func
    };

  static defaultProps = {
      suggestions: []
  };

  __construct(props) {
      super.__construct(props);

      this.state = {
      // The active selection's index
          activeSuggestion: 0,
          // The suggestions that match the user's input
          filteredSuggestions: [],
          // Whether or not the suggestion list is shown
          showSuggestions: false,
          // What the user has entered
          userInput: '',
          // What the user has selected
          selectedSuggestion: {}

      };
  }

  onChange = (e) => {
      const { suggestions } = this.props;
      const userInput = e.currentTarget.value;
      // Filter our suggestions that don't contain the user's input
      const filteredSuggestions = suggestions.filter(
          (suggestion) => suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      this.setState({
          activeSuggestion: 0,
          filteredSuggestions,
          showSuggestions: true,
          userInput: e.currentTarget.value
      });
  };

  onClick = (suggestion) => {
      const { updateParentStructure } = this.props;
      this.setState({
          activeSuggestion: 0,
          filteredSuggestions: [],
          showSuggestions: false,
          userInput: suggestion.name,
          selectedSuggestion: suggestion
      });
      updateParentStructure(suggestion);
  };

  onKeyDown = (e) => {
      const { activeSuggestion, filteredSuggestions } = this.state;

      // User pressed the enter key
      if (e.keyCode === 13) {
          this.setState({
              activeSuggestion: 0,
              showSuggestions: false,
              userInput: filteredSuggestions[activeSuggestion]
          });
      }
      // User pressed the up arrow
      else if (e.keyCode === 38) {
          if (activeSuggestion === 0) {
              return;
          }

          this.setState({ activeSuggestion: activeSuggestion - 1 });
      }
      // User pressed the down arrow
      else if (e.keyCode === 40) {
          if (activeSuggestion - 1 === filteredSuggestions.length) {
              return;
          }

          this.setState({ activeSuggestion: activeSuggestion + 1 });
      }
  };

  render() {
      const {
          onChange,
          onClick,
          onKeyDown,
          state: {
              activeSuggestion,
              filteredSuggestions,
              showSuggestions,
              userInput,
              // eslint-disable-next-line no-unused-vars
              selectedSuggestion

          }
      } = this;

      let suggestionsListComponent;

      if (showSuggestions && userInput) {
          if (filteredSuggestions.length) {
              suggestionsListComponent = (
          <ul className="suggestions">
            { filteredSuggestions.map((suggestion, index) => {
                let className;

                // Flag the active suggestion with a class
                if (index === activeSuggestion) {
                    className = 'suggestion-active';
                }

                return (
                <li className={ className } key={ suggestion.id } onClick={ () => onClick(suggestion) }>
                  { suggestion.name }
                </li>
                );
            }) }
          </ul>
              );
          } else {
              suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
              );
          }
      }

      return (
          <>
        <input
          type="text"
          onChange={ onChange }
          onKeyDown={ onKeyDown }
          value={ userInput }
        />
        { suggestionsListComponent }
          </>
      );
  }
}

export default AutocompleteComponent;
