/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { loadScript } from '@paypal/paypal-js';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import Html from 'Component/Html';
import Loader from 'Component/Loader';
import { TotalsType } from 'Type/MiniCart.type';

import { PAYPAL_SCRIPT, PAYPAL_TERMS_CHECK } from './PayPal.config';

import './PayPal.style';

/**
 * *Note*
 * This component currently can be rendered only once
 * Please try to have no more than 1 component per page and use isDisabled to hide it.
 * @namespace Paypal/Component/PayPal/Component/PayPalComponent */
export class PayPalComponent extends PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool,
        isLoadedScript: PropTypes.bool,
        paypal: PropTypes.any.isRequired,
        clientId: PropTypes.string.isRequired,
        cartTotals: TotalsType.isRequired,
        onError: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onApprove: PropTypes.func.isRequired,
        createOrder: PropTypes.func.isRequired,
        environment: PropTypes.oneOf([
            'production',
            'sandbox'
        ]).isRequired
    };

    static defaultProps = {
        isDisabled: false,
        isLoadedScript: false
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    _isArrayIncludes(array, value) {
        return array.includes(value);
    }

    getClientId = () => {
        const {
            clientId
        } = this.props;

        // if (environment === 'sandbox') {
        //     return 'sb';
        // }

        return clientId;
    };

    getPayPalScript = () => {
        const { currencyCode: currency } = window.storeConfig;
        const {
            clientId,
            scriptLoaded

        } = this.props;

        const params = {
            currency,
            // TODO implement action fetch from BE
            intent: 'capture',
            'client-id': clientId
        };

        loadScript(params)

            .then(
                /** @namespace Paypal/Component/PayPal/Component/loadScript/then */
                (paypal) => {
                    // paypal.Buttons().render('#your-container-element');
                    scriptLoaded(true);
                }
            )
            .catch(
                /** @namespace Paypal/Component/PayPal/Component/loadScript/then/catch */
                (err) => {
                    console.error('failed to load the PayPal JS SDK script', err);
                }
            );

        const paramsString = (Object.entries(params).map(([key, value]) => `${key}=${value}`)).join('&');

        return `<script id="${PAYPAL_SCRIPT}" src="https://www.paypal.com/sdk/js?${paramsString}"></script>`;
    };

    renderButtons() {
        const {
            paypal,
            onError,
            onCancel,
            onApprove,
            createOrder,
            environment,
            isLoadedScript
        } = this.props;

        if (!paypal) {
            return <Loader isLoading />;
        }

        if (!isLoadedScript) {
            return null;
        }

        const PayPalButton = paypal && paypal.Buttons.driver('react', { React, ReactDOM });

        return (
            <PayPalButton
              env={ environment }
              onError={ onError }
              onCancel={ onCancel }
              onApprove={ onApprove }
              createOrder={ createOrder }
              style={ {
                  layout: 'horizontal',
                  label: 'pay'
              } }
            />
        );
    }

    render() {
        const { isDisabled, TermsAndConditions } = this.props;
        const { storeId = '' } = window.storeConfig;
        const isPaypalEnabledByDefault = this._isArrayIncludes(PAYPAL_TERMS_CHECK, storeId);
        const paypalCheckboxClass = isPaypalEnabledByDefault ? TermsAndConditions : true;

        return (
            <div block="Checkout-StickyButtonWrapper">
                <div block="PayPal" mods={ { isDisabled } } className={ `TermsConditions${paypalCheckboxClass}` }>
                    <Html content={ this.getPayPalScript() } />
                    { this.renderButtons() }
                </div>
            </div>
        );
    }
}

export default PayPalComponent;
