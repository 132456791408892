/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isProduction } from 'Util/Helper';

/** @namespace Pwa/Component/SearchField/Config/algoliaSearchKeys */
export const algoliaSearchKeys = () => {
    const alogoliaKeys = {
        Application: 'testingE30RUCGO67',
        SearchOnlyApiKey: 'b9fdea64114318fbd1c6ff295659e69a'
    };

    if (isProduction() && process.env.NODE_ENV === 'production') {
        const alogoliaKeys = {
            Application: 'GEXHB2QM8Z',
            SearchOnlyApiKey: '5ae2bee2ac4ff8c935edfc06e97c4b42'
        };

        return alogoliaKeys;
    }

    return alogoliaKeys;
};
