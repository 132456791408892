/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import {
    GroupedProductsItemContainer as SourceGroupedProductsItemContainer
} from 'SourceComponent/GroupedProductsItem/GroupedProductsItem.container';

/** @namespace Pwa/Component/GroupedProductsItem/Container/GroupedProductsItemContainer */
export class GroupedProductsItemContainer extends SourceGroupedProductsItemContainer {
    __construct(props) {
        const {
            product: { stock_status }
        } = this.props;

        super.__construct(props);

        let { defaultQuantity } = this.props;
        if (stock_status === 'OUT_OF_STOCK') {
            defaultQuantity = 0;
        }
        this.changeCount(defaultQuantity);
    }
}

export default GroupedProductsItemContainer;
