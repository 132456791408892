/* eslint-disable new-cap */
/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

import { roundPrice } from '@scandipwa/scandipwa/src/util/Price';
import { CardElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Loader from 'Component/Loader';
import CheckoutQuery from 'Query/Checkout.query';
import StripePaymentQuery from 'Query/StripePayment.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { getGuestQuoteId } from 'Util/Cart';
import { ContainsAllowedCountryCode, formatStandardCurrencyAmount } from 'Util/Helper';
import history from 'Util/History';
import { fetchMutation } from 'Util/Request';

import {
    Address,
    PaymentTotals,
    Stripe,
    StripeElements
} from '../../type/Stripe';
import InjectedStripeCheckoutForm from './CheckoutPaymentButton.component';

/** @namespace StripePayments/Component/CheckoutPaymentButton/CheckoutPaymentButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace StripePayments/Component/CheckoutPaymentButton/CheckoutPaymentButton/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace StripePayments/Component/CheckoutPaymentButton/CheckoutPaymentButton/Container/CheckoutPaymentButtonContainer */
export class CheckoutPaymentButtonContainer extends PureComponent {
    static propTypes = {
        stripe: Stripe.isRequired,
        email: PropTypes.string,
        billingAddress: Address.isRequired,
        showNotification: PropTypes.func.isRequired,
        onRef: PropTypes.func.isRequired,
        paymentTotals: PaymentTotals.isRequired,
        onPaymentMethod: PropTypes.func.isRequired,
        selectedPaymentCode: PropTypes.string,
        elements: StripeElements.isRequired
    };

    static defaultProps = {
        email: null
    };

    componentDidMount() {
        const { onRef } = this.props;
        onRef(this);
        this.createPaymentRequest();
    }

    componentWillUnmount() {
        const { onRef } = this.props;
        onRef(undefined);
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            complete: false,
            pr: null
        };
        // this.submit = this.submit.bind(this);
        // this.submit = this.handleBillingSubmit.bind(this);
        this.isReadyToProceedOrder = this.handleReadyToProceedOrder.bind(this);
        // this.handleAuthorization = this.handleAuthorization.bind(this);
    }

    containerProps() {
        const {
            paymentTotals,
            billingAddress,
            stripe,
            showNotification,
            onPaymentMethod
        } = this.props;

        return {
            paymentTotals,
            billingAddress,
            stripe,
            showNotification,
            onPaymentMethod
        };
    }

    /**
    * Handles the response from a card action or a card payment after authorization is complete
    * @param response the API response
    * @param savePaymentInformation
    * @param paymentInformation
    * @returns {boolean} true on success, false otherwise
    */
    handlePostAuthorization(response, savePaymentInformation, paymentInformation) {
        const { showNotification } = this.props;

        if (response.error) {
            showNotification('error', response.error.message);
            return false;
        }

        savePaymentInformation(paymentInformation);
        return true;
    }

    onPaymentReady =(isReadyToPlaceOrder) => {
        this.setState({ isReadyToPlaceOrder });
    };

    /**
    * If card required 3ds authorization - handle it and place order if success
    * @param paymentInformation
    * @param secret
    * @param savePaymentInformation
    */
    handleAuthorization(paymentInformation, secret, savePaymentInformation) {
        const {
            stripe: { retrievePaymentIntent, handleCardAction, handleCardPayment }
        } = this.props;

        return retrievePaymentIntent(secret).then(
            /** @namespace StripePayments/Component/CheckoutPaymentButton/CheckoutPaymentButton/Container/retrievePaymentIntent/then */
            (result) => {
                const { paymentIntent: { status, confirmation_method } } = result;

                if (['requires_action', 'requires_source_action'].includes(status)) {
                    if (confirmation_method === 'manual') {
                        return handleCardAction(secret).then(
                            /** @namespace StripePayments/Component/CheckoutPaymentButton/CheckoutPaymentButton/Container/handleCardAction/then */
                            (response) => this.handlePostAuthorization(
                                response,
                                savePaymentInformation,
                                paymentInformation
                            )
                        );
                    }

                    return handleCardPayment(secret).then(
                        /** @namespace StripePayments/Component/CheckoutPaymentButton/CheckoutPaymentButton/Container/handleCardPayment/then */
                        (response) => this.handlePostAuthorization(
                            response,
                            savePaymentInformation,
                            paymentInformation
                        )
                    );
                }

                return null;
            }
        );
    }

    handleReadyToProceedOrder(e) {
        const { isReadyToPlaceOrder = false } = this.state;
        if (!isReadyToPlaceOrder) {
            const {
                showNotification
            } = this.props;

            showNotification('error', __('Please enter correct payment details.'));
        }

        return isReadyToPlaceOrder;
    }

    navigateToStripeSuccess(id, secret, status) {
        const returnUrl = `/stripepay/success?
    payment_intent=${id}&payment_intent_client_secret=${secret}&redirect_status=${status}`;

        history.push(returnUrl);
    }

    _getElementValue = (elementId) => {
        const element = document.getElementById(elementId);

        if (element) {
            return element.value || '';
        }
        console.error(`Element with ID '${elementId}' not found.`);
        return '';
    };

    _getCheckboxValueFromDiv(divClass) {
        // Select the div by class name
        const checkboxDiv = document.querySelector(`.${divClass}`);

        if (checkboxDiv) {
            // Find the checkbox input inside the div
            const checkbox = checkboxDiv.querySelector('input[type="checkbox"]');

            // Check if the checkbox exists inside the div
            if (checkbox) {
                // Return the value of the checkbox (true if checked, false if not)
                return checkbox.checked;
            }
        }

        // Return null if the checkbox or div was not found
        return null;
    }

    _getSelectedOptionLabel(selectId) {
        // Get the select element by its id
        const element = document.getElementById(selectId);

        // Check if the element exists
        if (element && element.options && element.selectedIndex !== -1) {
            // Get the selected option
            const selectedOption = element.options[element.selectedIndex];

            // Return the label of the selected option
            return selectedOption.label;
        }

        // Return null if no element or no selected option
        return null;
    }

    _setBillingAddressAppleAndGooglePay(billingAddress) {
        const setBillingAddress = async (billingAddress) => {
            const guest_cart_id = getGuestQuoteId();

            localStorage.setItem('appleBillingAddress', billingAddress);

            await fetchMutation(CheckoutQuery.getAppleGooglepaySetBillingAddress({
                guest_cart_id,
                same_as_shipping: false,
                billing_address: billingAddress
            }));
        };

        const {
            city, firstname, region, telephone, country_code, postcode
        } = billingAddress.address;

        if (city || firstname || region || telephone || country_code || postcode) {
            setBillingAddress(billingAddress);
        }
    }

    async _handleApplePayMissedOrders(guestCartId, clientSecret, status) {
        try {
            fetchMutation(
                StripePaymentQuery.placeApplePayMissedOrders(guestCartId, clientSecret, status)
            ).then(
                /** @namespace StripePayments/Component/CheckoutPaymentButton/CheckoutPaymentButton/Container/fetchMutation/then */
                (response) => {
                    console.log('Order placed successfully:', response);
                }
            ).catch(
                /** @namespace StripePayments/Component/CheckoutPaymentButton/CheckoutPaymentButton/Container/fetchMutation/then/catch */
                (error) => {
                    console.error('Failed to place order:', error);
                }
            );
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    }

    createPaymentRequest() {
        const {
            elements, stripe, showNotification, onPaymentMethod, selectedPaymentCode,
            onBillingSuccess
        } = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        onBillingSuccess({}, [{
            stripePayment: () => {
            }
        }]);

        const guest_cart_id = getGuestQuoteId();
        this.setState({ loading: true });
        localStorage.setItem('isProgressCheckout', '1');
        const { loading = false } = this.state || {};

        const storeUrl = window.storeConfig.baseName.endsWith('/') ? window.storeConfig.baseName : `${window.storeConfig.baseName}/`;
        const { paymentTotals: { base_grand_total, quote_currency_code }, intent_client_secret } = this.props;

        const { code, currencyCode } = window.storeConfig;
        const countryCode = code.split('_')[1].toUpperCase();
        let newCountryCode;
        if (countryCode === 'UK') {
            newCountryCode = 'GB';
        }
        if (!ContainsAllowedCountryCode(newCountryCode ?? countryCode)) {
            return;
        }

        // const finalPrice = parseFloat(roundPrice(base_grand_total * 100));
        const finalPrice = formatStandardCurrencyAmount(base_grand_total);
        const upFinalPrice = parseFloat(roundPrice(finalPrice * 100));

        const paymentRequest = stripe.paymentRequest({
            country: newCountryCode ?? countryCode,
            currency: currencyCode.toLowerCase(),
            total: {
                label: 'Total',
                amount: upFinalPrice
            },
            requestPayerName: true,
            requestPayerEmail: true
        });

        // dummy for now
        paymentRequest.canMakePayment().then(
            /** @namespace StripePayments/Component/CheckoutPaymentButton/CheckoutPaymentButton/Container/canMakePayment/then */
            (rsult) => {
                if (rsult) {
                    this.setState({ pr: paymentRequest });
                    // Mount paymentRequestButtonElement to the DOM
                }
            },
        );

        // if (elements) {
        //     return;
        // }        const billingSteetValue = getElementValue('street0');

        paymentRequest.on('paymentmethod', (ev) => {
            // Confirm the PaymentIntent without handling potential next actions (yet).
            // eslint-disable-next-line no-unused-vars
            if (!this._getCheckboxValueFromDiv('CheckoutBilling-Checkbox')) {
                const billingSteetValue = this._getElementValue('street0');
                const billingPostcodeValue = this._getElementValue('postcode');
                const billingFirstNameValue = this._getElementValue('firstname');
                const billingLastNameValue = this._getElementValue('lastname');
                const billingCityValue = this._getElementValue('city');
                const billingStateValue = this._getElementValue('region_id');
                const billingStateLabel = this._getSelectedOptionLabel('region_id');
                const billingCountryValue = this._getElementValue('country_id');
                const billingVatValue = this._getElementValue('vat_id');
                const billingCompanyValue = this._getElementValue('company');
                const billingPhoneValue = this._getElementValue('telephone');

                // eslint-disable-next-line fp/no-let
                let billingAddress = {};

                if (billingFirstNameValue || billingCityValue || billingStateValue || billingPhoneValue || billingCountryValue || billingPostcodeValue) {
                    billingAddress = {
                        address: {
                            city: billingCityValue,
                            company: billingCompanyValue,
                            country_code: billingCountryValue,
                            firstname: billingFirstNameValue,
                            lastname: billingLastNameValue,
                            street: billingSteetValue,
                            region_id: billingStateValue,
                            postcode: billingPostcodeValue,
                            telephone: billingPhoneValue,
                            vat_id: billingVatValue
                        }
                    };
                }
                this._setBillingAddressAppleAndGooglePay(billingAddress);
            }
            stripe.confirmCardPayment(
                intent_client_secret,
                {
                    payment_method: ev.paymentMethod.id
                    // return_url: returnUrl
                },
                { handleActions: false }
            ).then(
                /** @namespace StripePayments/Component/CheckoutPaymentButton/CheckoutPaymentButton/Container/confirmCardPayment/then */
                (confirmResult) => {
                    if (confirmResult.error) {
                        // Report to the browser that the payment failed, prompting it to
                        // re-show the payment interface, or show an error message and close
                        // the payment interface.
                        ev.complete('fail');
                    } else {
                        const { paymentIntent: { id, client_secret, status } } = confirmResult;

                        // this._setBillingAddressAppleAndGooglePay(billingAddress);

                        this._handleApplePayMissedOrders(guest_cart_id, client_secret, status);

                        this.navigateToStripeSuccess(id, client_secret, status);
                        // const { paymentIntent: { client_secret } } = confirmResult;
                        // Report to the browser that the confirmation was successful, prompting
                        // it to close the browser payment method collection interface.
                        ev.complete('success');
                        // Check if the PaymentIntent requires any actions and if so let Stripe.js
                        // handle the flow. If using an API version older than "2019-02-11"
                        // instead check for: `paymentIntent.status === "requires_source_action"`.
                        if (confirmResult.paymentIntent.status === 'requires_action') {
                            stripe.confirmCardPayment(intent_client_secret).then(
                                /** @namespace StripePayments/Component/CheckoutPaymentButton/CheckoutPaymentButton/Container/confirmCardPayment/then */
                                (result) => {
                                    if (result.error) {
                                        console.log(result.error);
                                    } else {
                                        const { paymentIntent: { id, client_secret, status } } = result;

                                        this._handleApplePayMissedOrders(guest_cart_id, client_secret, status);

                                        this.navigateToStripeSuccess(id, client_secret, status);
                                    }
                                }
                            );
                        }
                    }
                }
            );
        });
        localStorage.setItem('isProgressCheckout', '0');

        this.setState({ loading: false });
    }

    /**
    * Submit order information and create token
    * @returns {Promise<{handleAuthorization: InjectedStripeCheckoutForm.handleAuthorization, token: string}|{handleAuthorization: null, token: null}>}
    */

    /**
    * Submit order information and create token
    * @returns {Promise<{handleAuthorization: InjectedStripeCheckoutForm.handleAuthorization, token: string}|{handleAuthorization: null, token: null}>}
    */
    async submit22() {
        const {
            stripe: { createPaymentMethod },
            billingAddress: {
                firstname,
                lastname,
                telephone: phone,
                city,
                country_id: country,
                street,
                region: state
            },
            email,
            elements
        } = this.props;

        const billingName = `${ firstname } ${ lastname }`;
        const cardElement = elements.getElement(CardElement);

        const { paymentMethod } = await createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: billingName,
                email,
                phone,
                address: {
                    city,
                    country,
                    line1: street[0],
                    state
                }
            }
        });

        if (!paymentMethod) {
            return { token: null, handleAuthorization: null };
        }

        return {
            token: `${paymentMethod.id}:${paymentMethod.card.brand}:${paymentMethod.card.last4}`,
            handleAuthorization: this.handleAuthorization
        };
    }

    render() {
        const { loading = false } = this.state;
        return (
            <>
                <InjectedStripeCheckoutForm
                  { ...this.containerProps() }
                  { ...this.state }
                  { ...this.props }
                  onPaymentReady={ this.onPaymentReady }
                />
                   { (loading) && (
                    <div className="fullScreenloader">
                        { ' ' }
                        <Loader isLoading={ loading } />
                        { ' ' }
                    </div>
                   ) }
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutPaymentButtonContainer);
