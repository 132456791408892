/* eslint-disable max-len */
/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable fp/no-let */
/* eslint-disable fp/no-let */

import axios from 'axios';

/**
 * Is Europe Store?
 * @namespace Util/FrontRunner/Store/isEurStore
*/
export const isEurStore = (excludeUk = false) => {
    const stores = ['en_de', 'de_de', 'fr_be', 'es_be', 'nl_be', 'en_uk'];

    if (excludeUk) {
        stores.splice(stores.indexOf('en_uk'), 1);
    }

    if (window.storeConfig && stores.includes(window.storeConfig.code)) {
        return true;
    }

    return false;
};

/**
 * Is South Africa Store?
 * @namespace Util/FrontRunner/Store/isRsaStore
*/
export const isRsaStore = () => {
    const stores = ['en_za'];

    if (window.storeConfig && stores.includes(window.storeConfig.code)) {
        return true;
    }

    return false;
};

/**
 * Is USA Store?
 * @namespace Util/FrontRunner/Store/isUsaStore
*/
export const isUsaStore = () => {
    const stores = ['en_us', 'es_us'];

    if (window.storeConfig && stores.includes(window.storeConfig.code)) {
        return true;
    }

    return false;
};

/**
 * Is Australia Store?
 * @namespace Util/FrontRunner/Store/isAusStore
*/
export const isAusStore = () => {
    const stores = ['en_au'];

    if (window.storeConfig && stores.includes(window.storeConfig.code)) {
        return true;
    }

    return false;
};

/**
 * Is United Kingdom Store?
 * @namespace Util/FrontRunner/Store/isGbrStore
*/
export const isGbrStore = () => {
    const stores = ['en_uk'];

    if (window.storeConfig && stores.includes(window.storeConfig.code)) {
        return true;
    }

    return false;
};

/**
 * Is International Store?
 * @namespace Util/FrontRunner/Store/isIntStore
*/
export const isIntStore = () => {
    const stores = ['en_int'];

    if (window.storeConfig && stores.includes(window.storeConfig.code)) {
        return true;
    }

    return false;
};

/**
 * Format Price
 * @namespace Util/FrontRunner/Store/formatPrice
*/
export const formatPrice = (price) => {
    const { currencySymbol, code } = window.storeConfig;
    const priceFixed = (Math.round(price * 100) / 100).toFixed(2);
    let priceFormatted = currencySymbol + priceFixed;
    if (code === 'de_de') {
        priceFormatted = `${priceFixed} ${currencySymbol}`;
    }

    return priceFormatted;
};

/**
 * Geo Countries
 * @namespace Util/FrontRunner/Store/getGeoCountries
 */
export const getGeoCountries = async () => {
    // Fsync Url
    let fsyncUrl = 'https://mcfsync-staging.frontrunneroutfitters.com/';
    if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
        fsyncUrl = 'https://fsync.frontrunneroutfitters.com/';
    }

    // Send request
    try {
        const response = await axios.get(`${fsyncUrl}api/v2/magento/geo-countries?store=${window.storeConfig.code}`);
        return response.data;
    } catch (error) {
        console.error('Geo Countries Error', error);
    }

    return [];
};
