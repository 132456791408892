/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/sort-comp */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { APPLE_PAY, GOOGLE_PAY } from 'Component/CheckoutPayments/CheckoutPayments.config';
import Loader from 'Component/Loader';

import { Address, PaymentTotals } from '../../type/Stripe';
import CheckoutPaymentButtonContainer from '../checkoutPaymentButton/CheckoutPaymentButton.container';
import InjectedStripeCheckoutForm from '../InjectedStripeCheckoutForm';

import './Stripe.style';

/** @namespace StripePayments/Component/Stripe/Component/StripeComponent */
export class StripeComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        stripeKey: PropTypes.string,
        setStripeRef: PropTypes.func.isRequired,
        billingAddress: Address.isRequired,
        onPaymentMethod: PropTypes.func.isRequired,
        paymentTotals: PaymentTotals.isRequired,
        intent_client_secret: PropTypes.string,
        selectedPaymentCode: PropTypes.string
    };

    static defaultProps = {
        stripeKey: '',
        isLoading: false
    };

    renderNoStripeKey() {
        return (
            <p>{ __('Error loading Stripe! No API-key specified.') }</p>
        );
    }

    renderStripeForm() {
        const {
            setStripeRef,
            stripeKey,
            billingAddress,
            onPaymentMethod,
            paymentTotals,
            intent_client_secret,
            selectedPaymentCode,
            onBillingSuccess,
            billingAddress: {
                firstname,
                lastname,
                telephone: phone,
                city,
                country_id: country,
                street,
                postcode = '',
                region: state
            },
            email
        } = this.props;

        localStorage.setItem('stripeKey', stripeKey);
        const stripePromise = loadStripe(stripeKey);
        const appearance = {
            theme: 'stripe'
        };

        const billingName = `${firstname} ${lastname}`;

        const codes = window.storeConfig.code.split('_');
        const locale = isEmpty(codes[0]) ? 'auto' : codes[0];
        const line1 = 'line1';
        const loader = 'auto';

        const options = {
            clientSecret: intent_client_secret,
            appearance,
            locale,
            loader,
            value: { postalCode: postcode, country },
            billing_details: {
                name: billingName,
                email,
                phone,
                address: {
                    city,
                    country,
                    line1,
                    state
                }
            }
        };

        if (isEmpty(intent_client_secret)) {
            return null;
        }

        return (
            <Elements options={ options } stripe={ stripePromise }>
                { ' ' }
                <ElementsConsumer>
                    { ({ elements, stripe }) => {
                        if (!stripe || !elements) {
                            return null;
                        }

                        return (
                            <>
                                { selectedPaymentCode === APPLE_PAY || selectedPaymentCode === GOOGLE_PAY
                                    ? (
                                        <CheckoutPaymentButtonContainer
                                          onRef={ setStripeRef }
                                          billingAddress={ billingAddress }
                                          onPaymentMethod={ onPaymentMethod }
                                          intent_client_secret={ intent_client_secret }
                                          paymentTotals={ paymentTotals }
                                          selectedPaymentCode={ selectedPaymentCode }
                                          elements={ elements }
                                          stripe={ stripe }
                                          onBillingSuccess={ onBillingSuccess }
                                        />
                                    )
                                    : (
                                        <InjectedStripeCheckoutForm
                                          onRef={ setStripeRef }
                                          billingAddress={ billingAddress }
                                          onPaymentMethod={ onPaymentMethod }
                                          intent_client_secret={ intent_client_secret }
                                          paymentTotals={ paymentTotals }
                                          selectedPaymentCode={ selectedPaymentCode }
                                          elements={ elements }
                                          stripe={ stripe }

                                        />
                                    ) }
                            </>
                        );
                    } }
                </ElementsConsumer>
            </Elements>
        );
    }

    renderContent() {
        const {
            stripeKey,
            isLoading
        } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        if (!stripeKey) {
            return this.renderNoStripeKey();
        }

        return this.renderStripeForm();
    }

    render() {
        return (
            <div block="Stripe">
                { this.renderContent() }
            </div>
        );
    }
}

export default StripeComponent;
