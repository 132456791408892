/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-const-assign */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-dupe-class-members */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Breadcrumb from 'Component/Breadcrumb';
import ContentWrapper from 'Component/ContentWrapper';
import {
    Breadcrumbs as SourceBreadcrumbs
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';
import { appendWithStoreCode } from 'Util/Url';

import './Breadcrumbs.override.style';

export class Breadcrumbs extends SourceBreadcrumbs {
    // TODO implement logic

    static propTypes ={
        label: PropTypes.any
    };

    componentDidMount() {

    }

    goBack = () => {
        window.history.back();
        // window.history.go(-1);
    };

    renderReturnPage() {
        return (
            <a className="Return-page" onClick={ this.goBack }>
                 { __('Return to Previous Page') }
            </a>
        );
    }

    renderBreadcrumb({ url, name }, i) {
        const { breadcrumbs } = this.props;
        const isDisabled = !url || breadcrumbs.length - 1 === i;

        return (
            <Breadcrumb
              name={ name }
              url={ url }
              index={ i + 1 }
              key={ i }
              isDisabled={ isDisabled }
            />
        );
    }

    _removeDuplicatesByKey(arr, key) {
        const seen = new Set();
        return arr.filter((obj) => {
            const value = obj[key];
            if (!seen.has(value)) {
                seen.add(value);
                return true;
            }

            return false;
        });
    }

    breadCrumbSchema(breadcrumbs) {
        if (isEmpty(breadcrumbs)) {
            return null;
        }
        const dataList = [];
        for (const value in breadcrumbs) {
            const valueMap = {
                '@type': 'ListItem',
                position: value + 1,
                name: breadcrumbs[value].name,
                // item: window.location.href + breadcrumbs[value].url
                item: window.location.href

            };

            dataList.push(valueMap);
        }
        const schemaData = {
            '@context': 'http://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: dataList

        };
        const structuredData = JSON.stringify(schemaData);
        const script = document.getElementById('breadcrumb_schema');
        if (script) {
            script.parentNode.removeChild(script);
        }
        const breadCrumbSchemaScript = document.createElement('script');
        breadCrumbSchemaScript.setAttribute('id', 'breadcrumb_schema');
        breadCrumbSchemaScript.setAttribute('type', 'application/ld+json');
        breadCrumbSchemaScript.async = true;
        breadCrumbSchemaScript.textContent = structuredData;
        document.head.appendChild(breadCrumbSchemaScript);
    }

    render() {
        const { breadcrumbs, areBreadcrumbsVisible } = this.props;
        const { baseName } = window.storeConfig || {};
        const storeBaseName = baseName ? baseName.slice(0, -1) : '';

        this.breadCrumbSchema(breadcrumbs);

        if (
            !areBreadcrumbsVisible

        || location.pathname === appendWithStoreCode('/')
            || location.pathname.match(appendWithStoreCode('/account'))
            || location.pathname === '/'
            || location.pathname === storeBaseName
            // || location.pathname === storeBaseNameSlase
        ) {
            return null;
        }

        let pathName = location.pathname;

        // Remove `baseName` from the path
        if (pathName.includes(storeBaseName)) {
            pathName = pathName.replace(storeBaseName, '');
        }

        if (
            location.pathname.includes('/my-account')
            || location.pathname.includes('/cart')
            || location.pathname.includes('/checkout')
            || location.pathname.includes('/adventure/')
            || location.pathname.includes('/slimline2/')
            || location.pathname.includes('/vehicles/')
            || location.pathname.includes('/racks/')
            || location.pathname.includes('/products/')
            || location.pathname.includes('/slimline-ii-roof-rack/')
            || location.pathname.includes('/slimsport-roof-rack/')
            || location.pathname.includes('/slimpro-van-rack/')
            || location.pathname.includes('/promotions/')
            || location.pathname.includes('/pages/')
            || pathName === '/deals'
            || pathName === '/whats-new'
            || pathName === '/gift-guide'
            || pathName === '/payflex'
            || pathName === '/workshop'
            || pathName === '/contact'
            || pathName === '/adventure'
            || pathName === '/vehicles'
            || pathName === '/rewards'
            || pathname === '/vehicle-test-fitments'
            || pathName.length <= 7
        ) {
            return null;
        }

        return (
            <ContentWrapper mix={ { block: 'Breadcrumbs' } } label={ __('Breadcrumbs (current location)...') }>
                <nav aria-label="Breadcrumbs navigation">
                    <ul
                      block="Breadcrumbs"
                      elem="List"
                      itemScope
                      itemType="http://schema.org/BreadcrumbList"
                    >
                        { (
                            breadcrumbs.length
                                ? this.renderBreadcrumbList(breadcrumbs)
                                : this.renderBreadcrumb({}, 0)
                        ) }
                    </ul>
                </nav>
            </ContentWrapper>
        );
    }
}

export default Breadcrumbs;
