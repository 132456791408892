/* eslint-disable new-cap */
import { connect } from 'react-redux';

import {
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps,
    SomethingWentWrongContainer as SourceSomethingWentWrongContainer
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.container';

/** @namespace Pwa/Route/SomethingWentWrong/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Route/SomethingWentWrong/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Route/SomethingWentWrong/Container/SomethingWentWrongContainer */
export class SomethingWentWrongContainer extends SourceSomethingWentWrongContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(SomethingWentWrongContainer);
