/* eslint-disable no-console */
import { get } from 'lodash';

import GearzettePageQuery from 'Query/GearzettePage.query';
import { setLoading, updateGearzettePagePosts } from 'Store/GearzettePage';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { QueryDispatcher } from 'Util/Request';

/** @namespace Pwa/Store/GearzettePage/PostsForSearch/Dispatcher/PostsForSearchDispatcher */
export class PostsForSearchDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    // eslint-disable-next-line no-unused-vars
    onSuccess(response, dispatch, { cb }) {
        const posts = get(response, 'posts', []);
        dispatch(updateGearzettePagePosts(posts, false));
    }

    onError(_, dispatch) {
        dispatch(setLoading(false));
        dispatch(updateNoMatch(true));
    }

    prepareRequest(options, dispatch) {
        dispatch(setLoading(true));
        return GearzettePageQuery.getSearchPostsQuery(options);
    }
}

export default new PostsForSearchDispatcher();
