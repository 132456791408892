/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-magic-numbers */
/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    isEmpty
} from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import ProductCard from 'Component/ProductCard';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { MixType } from 'Type/Common.type';
import { FilterType, ProductType } from 'Type/ProductList';
import { isSignedIn } from 'Util/Auth';
import { isB2BUser } from 'Util/Helper';
import {
    roundPrice
} from 'Util/Price';

import { DEFAULT_PLACEHOLDER_COUNT } from './ProductListPage.config';

import './ProductListPage.style';

/**
 * Placeholder for List of category product
 * @namespace Pwa/Component/ProductListPage/Component/ProductListPageComponent * @class ProductListPage
 */
export class ProductListPageComponent extends PureComponent {
    static propTypes = {
        isInfiniteLoaderEnabled: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isVisible: PropTypes.bool.isRequired,
        updatePages: PropTypes.func.isRequired,
        numberOfPlaceholders: PropTypes.number,
        selectedFilters: FilterType,
        wrapperRef: PropTypes.func,
        pageNumber: PropTypes.number,
        items: PropTypes.arrayOf(ProductType),
        mix: MixType,
        customer: PropTypes.any
    };

    static defaultProps = {
        numberOfPlaceholders: DEFAULT_PLACEHOLDER_COUNT,
        wrapperRef: () => {},
        selectedFilters: {},
        pageNumber: null,
        items: [],
        mix: {},
        customer: {}
    };

    state = {
        siblingsHaveBrands: false,
        siblingsHavePriceBadge: false,
        siblingsHaveTierPrice: false,
        siblingsHaveConfigurableOptions: false
    };

    componentDidMount() {
        this.startObserving();
        this.updateToGaDataProductImpressions();
        if (window.$zopim) {
            $zopim.livechat.button.setOffsetVerticalMobile(0);
        }
    }

    componentDidUpdate() {
        this.startObserving();
    }

    componentWillUnmount() {
        this.stopObserving();
    }

    containerProps() {
        const {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        } = this.state;

        return {
            productCardFunctions: {
                setSiblingsHaveBrands: () => this.setState({ siblingsHaveBrands: true }),
                setSiblingsHavePriceBadge: () => this.setState({ siblingsHavePriceBadge: true }),
                setSiblingsHaveTierPrice: () => this.setState({ siblingsHaveTierPrice: true }),
                setSiblingsHaveConfigurableOptions: () => this.setState({ siblingsHaveConfigurableOptions: true })
            },
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            }
        };
    }

    startObserving() {
        const {
            items,
            updatePages,
            isInfiniteLoaderEnabled
        } = this.props;

        if (!isInfiniteLoaderEnabled || items.length) {
            return;
        }

        if (this.node && !this.observer && 'IntersectionObserver' in window) {
            const options = {
                rootMargin: '0px',
                threshold: 0.1
            };

            this.observer = new IntersectionObserver(([{ intersectionRatio }]) => {
                const { items, isLoading } = this.props;

                // must not be a product items list, and must not be loading
                if (intersectionRatio > 0 && !items.length && !isLoading) {
                    this.stopObserving();
                    updatePages();
                }
            }, options);

            this.observer.observe(this.node);
        }
    }

    stopObserving() {
        if (this.observer) {
            if (this.observer.unobserve && this.node) {
                this.observer.unobserve(this.node);
            }

            if (this.observer.disconnect) {
                this.observer.disconnect();
            }

            this.observer = null;
        }
    }

    renderPlaceholders() {
        const {
            numberOfPlaceholders, mix: {
                mods: {
                    layout = GRID_LAYOUT
                } = {}
            }
        } = this.props;

        return Array.from(
            { length: numberOfPlaceholders },
            (_, i) => (
                <ProductCard
                  key={ i }
                  product={ {} }
                  layout={ layout }
                />
            )
        );
    }

    getPlaceholderRef() {
        const { isVisible } = this.props;

        if (!isVisible) {
            return undefined;
        }

        return (node) => {
            this.node = node;
        };
    }

    updateToGaDataProductImpressions() {
        const {
            items
        } = this.props;
        let emailId = '';
        if (isSignedIn()) {
            emailId = localStorage.getItem('email_id', '');
        }
        const { currencyCode, storeId } = window.storeConfig;
        const productImpressions = [];
        items.forEach((item) => {
            if (!isEmpty(item && item.price_range && item.price_range.maximum_price && item.price_range.maximum_price.default_final_price)) {
                const {
                    id,
                    name,
                    sku,
                    price_range,
                    attributes
                } = item;
                const pro_id = id;
                const { maximum_price } = price_range;
                const { default_final_price } = maximum_price;
                const { value } = default_final_price;
                const pro_price = roundPrice(value);

                // Brand
                let brand = '';
                if (attributes && attributes.brand) {
                    const brandId = attributes.brand?.attribute_value ?? null;
                    if (brandId) {
                        brand = attributes.brand.attribute_options[brandId].label ?? '';
                    }
                }

                productImpressions.push({
                    name,
                    id: sku,
                    sku,
                    product_id: `${pro_id}`,
                    price: `${pro_price}`,
                    brand
                });
            }
        });
        if (productImpressions.length < 1) {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'impressions',
            ecommerce: {
                currencyCode,
                storeId,
                pageType: 'productListPage',
                impressions: productImpressions,
                customer: {
                    email: emailId
                }
            }
        });
    }

    renderPageItems() {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = GRID_LAYOUT
                } = {}
            },
            customer = {}
        } = this.props;

        let filterb2b = items;
        const { is_b2b } = customer;

        if (isB2BUser(is_b2b)) {
            filterb2b = items.filter((item) => {
                const { attributes } = item || {};
                const { b2c_product } = attributes || {};
                return b2c_product?.attribute_value === '0';
            });
        }

        return filterb2b.map((product, i) => (
            <ProductCard
              product={ product }
              // eslint-disable-next-line react/no-array-index-key
              key={ i }
              selectedFilters={ selectedFilters }
              layout={ layout }
              { ...this.containerProps() }
            />
        ));
    }

    renderPlaceholderItems() {
        return (
            <>
                <li
                  block="ProductListPage"
                  elem="Offset"
                  ref={ this.getPlaceholderRef() }
                />

                { this.renderPlaceholders() }
            </>
        );
    }

    renderItems() {
        const { items, isLoading } = this.props;

        if (!items.length || isLoading) {
            return this.renderPlaceholderItems();
        }

        return this.renderPageItems();
    }

    render() {
        const {
            pageNumber,
            wrapperRef,
            mix
        } = this.props;

        return (
            <ul
              block="ProductListPage"
              mix={ { ...mix, elem: 'Page' } }
              key={ pageNumber }
              ref={ wrapperRef }
            >
                { this.renderItems() }
            </ul>
        );
    }
}

export default withRouter(ProductListPageComponent);
