/* eslint-disable no-unused-vars */
import NewsletterSubscriptionQuery from 'Query/NewsletterSubscription.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, QueryDispatcher } from 'Util/Request';

import { setLoading, setResponse } from './NewsletterSubscription.action';

/** @namespace Pwa/Store/NewsletterSubscription/Dispatcher/NewsletterSubscriptionDispatcher */
export class NewsletterSubscriptionDispatcher extends QueryDispatcher {
    subscribe(dispatch, options) {
        dispatch(setLoading(true));
        return fetchMutation(NewsletterSubscriptionQuery.subscribe(options)).then(
            /** @namespace Pwa/Store/NewsletterSubscription/Dispatcher/fetchMutation/then */
            ({ subscribeNewsletter }) => {
                dispatch(showNotification('success', subscribeNewsletter.message));
                dispatch(setLoading(false));
            },
            /** @namespace Pwa/Store/NewsletterSubscription/Dispatcher/fetchMutation/then */
            (error) => {
                dispatch(showNotification('error', __('Something went wrong, please try again.')));
                dispatch(setLoading(false));
            }
        );
    }

    subscribePopup(dispatch, options) {
        dispatch(setLoading(true));
        return fetchMutation(NewsletterSubscriptionQuery.subscribePopup(options)).then(
            /** @namespace Pwa/Store/NewsletterSubscription/Dispatcher/fetchMutation/then */
            ({ subscribeNewsletterPopUp }) => {
                dispatch(setResponse({ data: subscribeNewsletterPopUp }));
                dispatch(showNotification('success', subscribeNewsletterPopUp.message));
                dispatch(setLoading(false));
            },
            /** @namespace Pwa/Store/NewsletterSubscription/Dispatcher/fetchMutation/then */
            (error) => {
                dispatch(showNotification('error', __('Something went wrong, please try again.')));
                dispatch(setLoading(false));
            }
        );
    }
}

export default new NewsletterSubscriptionDispatcher();
