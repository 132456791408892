/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import MenuOverlay from './MenuOverlay.component';

/** @namespace Pwa/Component/MenuOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    mobileMenu: state.HeaderAndFooterReducer.mobileMenu,
    desktopMenu: state.HeaderAndFooterReducer.desktopMenu,
    device: state.ConfigReducer.device

    // blocks: state.CmsBlocksAndSliderReducer.blocks
});

/** @namespace Pwa/Component/MenuOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state)),
    updateMeta: (meta) => dispatch(updateMeta(meta))
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuOverlay);
