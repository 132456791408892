/* eslint-disable react/sort-comp */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-magic-numbers */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */
/* eslint-disable react/no-unused-state */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-ProductReviewListtheme
 */
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
    cloneElement, lazy, PureComponent, Suspense
} from 'react';

import Breadcrumbs from 'Component/Breadcrumbs';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader/Loader.component';
import Popup from 'Component/Popup/Popup.container';
import ProductActions from 'Component/ProductActions';
import ProductCustomizableOptions from 'Component/ProductCustomizableOptions';
import ProductLinks from 'Component/ProductLinks';
import ProductReviewForm from 'Component/ProductReviewForm/ProductReviewForm.container';
import { REVIEW_POPUP_ID } from 'Component/ProductReviews/ProductReviews.config';
import ProductTabs from 'Component/ProductTabs';
import { BREADCRUMBS_TYPE } from 'Component/Router/Router.config';
// import NoMatchHandler from 'Route/NoMatchHandler';
import {
    PRODUCT_ATTRIBUTES,
    PRODUCT_INFORMATION,
    PRODUCT_REVIEWS
} from 'Route/ProductPage/ProductPage.config';
import { RELATED, UPSELL } from 'Store/LinkedProducts/LinkedProducts.reducer';
import { DeviceType } from 'Type/Device.type';
import { ProductType } from 'Type/ProductList';
import { isSignedIn } from 'Util/Auth';
import { hasFreeShipping } from 'Util/FrontRunner/Product';
import { isRsaStore, isUsaStore } from 'Util/FrontRunner/Store';
import { isMobile } from 'Util/Mobile';
import { roundPrice } from 'Util/Price';

import './ProductPage.style';

export const ProductGallery = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-gallery" */
    'Component/ProductGallery'
));
export const ProductInformation = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-info" */
    'Component/ProductInformation'
));
export const ProductReviews = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-reviews" */
    'Component/ProductReviews'
));
export const ProductAttributes = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-attributes" */
    'Component/ProductAttributes'
));

/** @namespace Pwa/Route/ProductPage/Component/ProductPageComponent */
export class ProductPageComponent extends PureComponent {
    static propTypes = {
        configurableVariantIndex: PropTypes.number.isRequired,
        productOrVariant: ProductType.isRequired,
        getLink: PropTypes.func.isRequired,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        updateConfigurableVariant: PropTypes.func.isRequired,
        dataSource: ProductType.isRequired,
        areDetailsLoaded: PropTypes.bool.isRequired,
        getSelectedCustomizableOptions: PropTypes.func.isRequired,
        setLinkedDownloadables: PropTypes.func.isRequired,
        setLinkedDownloadablesPrice: PropTypes.func.isRequired,
        productOptionsData: PropTypes.object.isRequired,
        setBundlePrice: PropTypes.func.isRequired,
        selectedLinkPrice: PropTypes.number.isRequired,
        selectedBundlePrice: PropTypes.number.isRequired,
        device: DeviceType.isRequired,
        isInformationTabEmpty: PropTypes.bool.isRequired,
        isAttributesTabEmpty: PropTypes.bool.isRequired,
        selectedBundlePriceExclTax: PropTypes.number.isRequired,
        selectedInitialBundlePrice: PropTypes.number.isRequired
    };

    tabMap = {
        [PRODUCT_INFORMATION]: {
            name: __('About'),
            shouldTabRender: () => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: (key) => this.renderProductInformationTab(key)
        },
        [PRODUCT_ATTRIBUTES]: {
            name: __('Details'),
            shouldTabRender: () => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: (key) => this.renderProductAttributesTab(key)
        },
        [PRODUCT_REVIEWS]: {
            name: __('Reviews'),
            // Return true since we always show 'Add review' button
            shouldTabRender: () => true,
            render: (key) => this.renderProductReviewsTab(key)
        }
    };

    componentDidUpdate(prevProps) {
        const { product = {} } = this.props;
        if (product.price_range && prevProps.product !== product) {
            this.updateToGaDataProductDetails();
        }

        if (isMobile.any()) {
            const updateWidgetPosition = () => {
                // Set zendesk widget position on mobile
                const launcher = document.getElementById('launcher');
                if (launcher) {
                    launcher.style.bottom = '68px';
                    launcher.style.right = '0px';
                }
            };

            // Try to update immediately
            updateWidgetPosition();

            // Also set a timeout to try again after a short delay
            setTimeout(updateWidgetPosition, 2000);
        }
    }

    componentWillUnmount() {
        const launcher = document.getElementById('launcher');
        if (launcher) {
            launcher.style.bottom = '3px';
            launcher.style.right = '0px';
        }
    }

    renderProductPageContent() {
        const {
            configurableVariantIndex,
            parameters,
            getLink,
            dataSource,
            updateConfigurableVariant,
            productOrVariant,
            areDetailsLoaded,
            getSelectedCustomizableOptions,
            productOptionsData,
            setBundlePrice,
            selectedBundlePrice,
            selectedInitialBundlePrice,
            selectedBundlePriceExclTax,
            setLinkedDownloadables,
            setLinkedDownloadablesPrice,
            selectedLinkPrice,
            updateGiftCardFormData,
            updateGiftCardPrice,
            updateGiftCardDesign,
            giftCardData,
            optionsSelected,
            isLoading,
            selectedInxData,
            videoTab,
            isEnabledVideoTab,
            selectedInxDataVal,
            activeProduct,
            setActiveProduct
        } = this.props;

        return (
            <>
                <div className="ProductGallery-Container">
                <ProductGallery
                //   product={ activeProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                //   giftCardData={ giftCardData }
                  videoTab={ videoTab }
                  selectedInxData={ selectedInxData }
                  product={ productOrVariant }
                />
                </div>
                <ProductActions
                  getLink={ getLink }
                  isLoading={ isLoading }
                  updateGiftCardFormData={ updateGiftCardFormData }
                  optionsSelected={ optionsSelected }
                  updateGiftCardPrice={ updateGiftCardPrice }
                  updateGiftCardDesign={ updateGiftCardDesign }
                  updateConfigurableVariant={ updateConfigurableVariant }
                  product={ dataSource }
                  productOrVariant={ productOrVariant }
                  parameters={ parameters }
                  areDetailsLoaded={ areDetailsLoaded }
                  configurableVariantIndex={ configurableVariantIndex }
                  getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
                  productOptionsData={ productOptionsData }
                  setBundlePrice={ setBundlePrice }
                  selectedBundlePrice={ selectedBundlePrice }
                  selectedInitialBundlePrice={ selectedInitialBundlePrice }
                  selectedBundlePriceExclTax={ selectedBundlePriceExclTax }
                //   setLinkedDownloadables={ setLinkedDownloadables }
                //   setLinkedDownloadablesPrice={ setLinkedDownloadablesPrice }
                //   selectedLinkPrice={ selectedLinkPrice }
                  giftCardData={ giftCardData }
                  videoTab={ videoTab }
                  selectedInxData={ selectedInxData }
                  selectedInxDataVal={ selectedInxDataVal }
                  isEnabledVideoTab={ isEnabledVideoTab }
                />
                { /* <ProductActions
                  getLink={ getLink }
                  isLoading={ isLoading }
                //   changeTabIndex={ changeTabIndex }
                  updateGiftCardFormData={ updateGiftCardFormData }
                  optionsSelected={ optionsSelected }
                  updateGiftCardPrice={ updateGiftCardPrice }
                  updateGiftCardDesign={ updateGiftCardDesign }
                  updateConfigurableVariant={ updateConfigurableVariant }
                  product={ dataSource }
                  productOrVariant={ activeProduct }
                  parameters={ parameters }
                  areDetailsLoaded={ areDetailsLoaded }
                  configurableVariantIndex={ configurableVariantIndex }
                  getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
                  productOptionsData={ productOptionsData }
                  setBundlePrice={ setBundlePrice }
                  selectedBundlePrice={ selectedBundlePrice }
                  selectedBundlePriceExclTax={ selectedBundlePriceExclTax }
                  giftCardData={ giftCardData }
                  videoTab={ videoTab }
                  selectedInxData={ selectedInxData }
                  selectedInxDataVal={ selectedInxDataVal }
                  isEnabledVideoTab={ isEnabledVideoTab }
                //   setActiveProduct={ setActiveProduct }
                /> */ }
            </>
        );
    }

    updateToGaDataProductDetails() {
        const { product } = this.props;
        const {
            id,
            name,
            sku,
            price_range,
            attributes
        } = product;

        const pre_sku = sku;
        let emailId = '';
        if (isSignedIn()) {
            emailId = localStorage.getItem('email_id', '');
        }
        const currentVisited = localStorage.getItem('visitedProduct', '0');
        if (price_range === undefined || currentVisited == sku || isEmpty(`${currentVisited}`)) {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        const { currencyCode, storeId = '' } = window.storeConfig;
        const proId = `${id}`;
        const { maximum_price = {} } = price_range;
        const { default_final_price = {} } = maximum_price;
        const { value } = default_final_price;
        const pr_price = roundPrice(value);

        const pro_price = `${pr_price}`;

        // Brand
        let brand = '';
        if (attributes && attributes.brand) {
            const brandId = attributes.brand?.attribute_value ?? null;
            if (brandId) {
                brand = attributes.brand.attribute_options[brandId].label ?? '';
            }
        }

        // Free Shipping?
        let singleCheck = '';
        let canShipFree = false;
        if (isRsaStore()) {
            singleCheck = '1406'; // Free shipping items only
        }
        if (hasFreeShipping(product, singleCheck)) {
            canShipFree = true;
        }

        window.dataLayer.push({
            event: 'detail',
            ecommerce: {
                currencyCode,
                storeId,
                pageType: 'productPage',
                detail: {
                    products: [{
                        name,
                        id: sku,
                        product_id: proId,
                        sku,
                        price: pro_price,
                        brand
                    }]
                },
                customer: {
                    email: emailId
                },
                promotions: {
                    freeShipping: canShipFree
                }
            }
        });
        localStorage.setItem('visitedProduct', pre_sku);
    }

    renderCustomizableOptions() {
        const {
            dataSource: { options },
            getSelectedCustomizableOptions,
            productOptionsData,
            device
        } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return (
            <ProductCustomizableOptions
              options={ options || [] }
              getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
              productOptionsData={ productOptionsData }
            />
        );
    }

    renderProductInformationTab(key) {
        const {
            dataSource,
            parameters,
            areDetailsLoaded
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> }>
                <ProductInformation
                  product={ { ...dataSource, parameters } }
                  areDetailsLoaded={ areDetailsLoaded }
                  key={ key }
                />
            </Suspense>
        );
    }

    // Zonos Hello
    async renderZonosHello() {
        if (window.zonos !== undefined) {
            const { product } = this.props;
            const {
                name, sku, url, price_range, attributes, image
            } = product;

            if (isEmpty(product)) {
                return null;
            }

            // TODO: We need country and tariff_code on attributes data
            let hsCode = '';
            let countryOfOrigin = 'ZA';
            let description = '';
            if (attributes) {
                const { tariff_code, country_of_origin, tariff_description } = attributes;
                hsCode = tariff_code?.attribute_value;
                countryOfOrigin = country_of_origin?.attribute_value;
                description = tariff_description?.attribute_value;
            }

            let zonosPrice = null;
            let zonosCurrency = null;
            if (price_range?.minimum_price?.regular_price_excl_tax?.value) {
                const { value, currency } = price_range.minimum_price.regular_price_excl_tax;
                zonosPrice = value;
                zonosCurrency = currency;
            }

            const itemData = [
                {
                    sku,
                    name,
                    price: zonosPrice,
                    url,
                    quantity: 1,
                    image: image?.url ?? '',
                    hsCode,
                    country: countryOfOrigin
                }
            ];

            window.zonos = window.zonos || [];
            window.zonos.quote(
                {
                    items: itemData,
                    currencyCode: zonosCurrency,
                    includedTaxRate: 0,
                    showDecimal: true
                }
            );
        }
    }

    renderProductAttributesTab(key) {
        const {
            dataSource,
            parameters,
            areDetailsLoaded
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> }>
                <ProductAttributes
                  product={ { ...dataSource, parameters } }
                  areDetailsLoaded={ areDetailsLoaded }
                  key={ key }
                />
            </Suspense>
        );
    }

    renderProductReviewsTab(key) {
        const {
            dataSource,
            areDetailsLoaded
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> }>
                <ProductReviews
                  product={ dataSource }
                  areDetailsLoaded={ areDetailsLoaded }
                  key={ key }
                />
            </Suspense>
        );
    }

    shouldTabsRender() {
        return Object.values(this.tabMap).filter(({ shouldTabRender }) => shouldTabRender());
    }

    renderProductTabs() {
        return (
            <ProductTabs tabs={ this.shouldTabsRender() } />
        );
    }

    renderAdditionalSections() {
        const {
            areDetailsLoaded
        } = this.props;

        return (
            <>
                { this.renderCustomizableOptions() }
                { this.renderProductTabs() }
                <ProductLinks
                  linkType={ RELATED }
                  title={ __('Recommended for you') }
                  areDetailsLoaded={ areDetailsLoaded }
                />
                <ProductLinks
                  linkType={ UPSELL }
                  title={ __('You might also like') }
                  areDetailsLoaded={ areDetailsLoaded }
                />
            </>
        );
    }

    renderReviewPopup() {
        const { productOrVariant } = this.props;

        return (
            <Popup
              id={ REVIEW_POPUP_ID }
              mix={ { block: 'ProductReviews', elem: 'Popup' } }
            >
                <ProductReviewForm product={ productOrVariant } />
            </Popup>
        );
    }

    renderItemsOfType(type) {
        return this.getSortedItems(type)?.map(({ position, component }) => cloneElement(component, { key: position }));
    }

    getSortedItems(type) {
        return this[type]?.sort(
            (a, b) => a.position - b.position
        ).filter(
            (entry) => {
                if (!entry.component) {
                    // eslint-disable-next-line no-console
                    console.warn('There is an item without a component property declared in main router.');
                    return false;
                }

                return true;
            }
        );
    }

    [BREADCRUMBS_TYPE] = [
        {
            component: <Breadcrumbs />,
            position: 30
        }
    ];

    // Expert Voice Reviews
    renderExpertVoiceReviews() {
        if (!isUsaStore()) {
            return null;
        }

        const {
            product,
            product: {
                configurable_product_options_selection = {},
                type_id
            },
            areDetailsLoaded
        } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        const { variant } = configurable_product_options_selection;
        const productOrVariant = variant ?? product;
        const { sku } = productOrVariant;

        // If type is bundle and last two characters of sku are BP, strip off BP
        let skuFinal = sku;
        if (type_id === 'bundle' && sku.slice(-2) === 'BP') {
            skuFinal = sku.slice(0, -2);
        }

        if (skuFinal && skuFinal !== undefined) {
            window.EV = window.EV || {};
            window.EV.recoSettings = {
                apiKey: '4df3f11567881c39a17bc662004fa9f9',
                productCode: skuFinal,
                target: '#expertvoice-recommendations',
                theme: 'light'
            };

            const script = document.createElement('script');
            script.src = 'https://cdn.expertvoice.com/widget/js/recommendations/v1/plugin.js';
            script.async = true;
            script.onload = () => {
                //
            };
            document.body.appendChild(script);
        }
    }

    renderSchemaScript() {
        const { product = {} } = this.props;
        if (isEmpty(product)) {
            return null;
        }
        const {
            name,
            url,
            short_description,
            sku,
            price_range,
            stock_status,
            type_id,
            media_gallery_entries,
            attributes: { brand, barcode } = {},
            meta_description = ''
        } = product;

        const brandCode = parseInt(brand?.attribute_value);
        const brandValue = brand?.attribute_options[brandCode]?.label ?? '';
        let barcodeValue = barcode?.attribute_value;
        let productStockStatus = stock_status;

        let shortDescription = short_description?.html;
        shortDescription = shortDescription.replace(/<[^>]*>?/gm, '');

        const imagesArray = media_gallery_entries.map((image) => image.large.url);

        let price = price_range?.minimum_price?.final_price?.value.toFixed(2);

        // Check if product is a bundle
        if (!isEmpty(product.items && product.items[0].options) && product.items.length > 0 && product.items[0].options.length > 0 && type_id === 'bundle') {
            const attributesList = product.items[0].options[0].product.attributes;
            // Stock status
            productStockStatus = product.items[0].options[0].product.stock_status;
            // Price
            price = product.items[0].options[0].product.price_range.minimum_price.final_price.value.toFixed(2);
            // Search attributesList for barcode value
            const barcodeSearchResults = attributesList.filter((attribute) => attribute.attribute_code === 'barcode');
            if (barcodeSearchResults && barcodeSearchResults.length > 0) {
                barcodeValue = barcodeSearchResults[0]?.attribute_value ?? null;
            }
        }

        const productMetaDescription = meta_description || shortDescription;

        const schemaData = {
            '@context': 'http://schema.org/',
            '@type': 'Product',
            name,
            image: imagesArray,
            url: location.origin + url,
            description: productMetaDescription,
            sku,
            brand: {
                '@type': 'Brand',
                name: brandValue
            },
            offers: {
                '@type': 'Offer',
                name,
                sku,
                url: location.origin + url,
                price,
                priceCurrency: price_range?.minimum_price?.final_price?.currency,
                availability: productStockStatus === 'IN_STOCK' ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
                itemCondition: 'https://schema.org/NewCondition',
                gtin: barcodeValue,
                seller: {
                    '@type': 'Organization',
                    name: 'Front Runner'
                }
            }
        };

        const structuredData = JSON.stringify(schemaData);

        const script = document.getElementById('product_schema');
        if (script) {
            script.parentNode.removeChild(script);
        }
        const productSchemaScript = document.createElement('script');
        productSchemaScript.setAttribute('id', 'product_schema');
        productSchemaScript.setAttribute('type', 'application/ld+json');
        productSchemaScript.async = true;
        productSchemaScript.textContent = structuredData;
        document.head.appendChild(productSchemaScript);
    }

    renderPopup() {
        const { productOrVariant } = this.props;
        return (
            <Popup
              id={ REVIEW_POPUP_ID }
              open
              mix={ { block: 'ReviewPopup' } }
            >
                <ProductReviewForm product={ productOrVariant } />
            </Popup>
        );
    }

    render() {
        const { product: { attributes = {} }, customer = {} } = this.props;

        const { b2c_product = {} } = attributes;

        const { attribute_value } = b2c_product;

        const { is_b2b } = customer;

        // Zonos
        if (isUsaStore() || window.storeConfig.code === 'en_uk') {
            this.renderZonosHello();
        }

        this.renderSchemaScript();
        // this.renderReviewSchemaScript();
        return (
            <>
            <main
              block="ProductPage"
              aria-label="Product page"
              itemScope
              itemType="http://schema.org/Product"
            >
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                { /* { this.renderAdditionalSections() } */ }
                { this.renderPopup() }
            </main>
            { this.renderItemsOfType(BREADCRUMBS_TYPE) }
            { this.renderExpertVoiceReviews() }
            </>
        );
    }
}

export default ProductPageComponent;
