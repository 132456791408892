/* eslint-disable max-len */
/* eslint-disable padding-line-between-statements */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { data } from 'jquery';
import { values } from 'lodash';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

import {
    ProductConfigurableAttributeDropdownContainer as SourceProductConfigurableAttributes
} from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.container';
import { AttributeType } from 'Type/ProductList';

import ProductConfigurableAttributeDropdown from './ProductConfigurableAttributeDropdown.component';

/** @namespace Pwa/Component/ProductConfigurableAttributeDropdown/Container/ProductConfigurableAttributeDropdownContainer */
export class ProductConfigurableAttributeDropdownContainer extends SourceProductConfigurableAttributes {
     static propTypes = {
         option: AttributeType.isRequired,
         updateConfigurableVariant: PropTypes.func.isRequired,
         getIsConfigurableAttributeAvailable: PropTypes.func.isRequired,
         parameters: PropTypes.objectOf(PropTypes.string).isRequired
     };

     containerFunctions = {
         onChange: this.onChange.bind(this)
     };

     onChange(_val, index) {
         const {
             id, label, attribute_id, value
         } = _val;
         const {
             updateConfigurableVariant,
             option: { attribute_code },
             addIdToIdList,
             enableDropDown,
             selectedIdList,
             dropDownCount
         } = this.props;
         addIdToIdList(id, index, _val);
         enableDropDown(index + 1);
         updateConfigurableVariant(attribute_code, label, selectedIdList, index, dropDownCount, { option_id: attribute_id, option_value: value });
     }

     containerProps = () => {
         const { option: { attribute_code, attribute_label, values = [] } } = this.props;
         return {
             selectValue: this._getSelectValue(),
             selectOptions: this._getSelectOptions(),
             selectName: attribute_code,
             selectLabel: attribute_label
         };
     };

     _getSelectOptions = () => {
         const {
             option: { values, attribute_id, attribute_values }
         } = this.props;
         if (!values) {
             // eslint-disable-next-line no-console
             console.warn(`Please make sure "${ values }" is visible on Storefront.`);

             return [];
         }
         const newList = [];
         values.map((data) => {
             const index = values.indexOf(data);
             const value = attribute_values[index];
             const newObject = {
                 id: data.uid,
                 label: data.label,
                 attribute_id,
                 value

             };

             newList.push(newObject);
             return newObject;
         });
         return newList;
     };

     //  _getDropdownState(newList) {
     //      const stateArray = [];
     //      for (const data in newList) {
     //          if (data === 1) {
     //              stateArray.push(true);
     //          } else {
     //              stateArray.push(false);
     //          }
     //      }
     //      return stateArray;
     //  }

     //  _getSelectValue = () => {
     //      const { option: { attribute_code } } = this.props;
     //      const { parameters = {} } = this.props;

     //      return parameters[attribute_code];
     //  };

     render() {
         return (
             <ProductConfigurableAttributeDropdown
               { ...this.props }
               { ...this.containerFunctions }
               { ...this.containerProps() }
             />
         );
     }
}

export default ProductConfigurableAttributeDropdownContainer;
