/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';

import {
    TextPlaceholder as SourceTextPlaceholder
} from 'SourceComponent/TextPlaceholder/TextPlaceholder.component';

/** @namespace Pwa/Component/ProductReviews/Component/ProductReviews */
export class TextPlaceholder extends SourceTextPlaceholder {
    static propTypes = {
        content: PropTypes.oneOfType([
            PropTypes.any,
            PropTypes.any,
            PropTypes.any
        ])
    };
}

export default TextPlaceholder;
