/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable new-cap */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';

import Meta from './Meta.component';

/** @namespace Pwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    oggraph: state.MetaReducer.oggraph,
    hreflang: state.MetaReducer.hreflang,
    contact_us_snippet: state.MetaReducer.contact_us_snippet,
    meta_ogg: state.MetaReducer.oggraph,
    meta_tag_description: state.MetaReducer.meta_tag_description,
    meta_tag_title: state.MetaReducer.meta_tag_title

    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/Meta/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/Meta/Container/MetaContainer */
export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        default_description: PropTypes.string,
        default_keywords: PropTypes.string,
        default_title: PropTypes.string,
        canonical_url: PropTypes.string,
        title_prefix: PropTypes.string,
        title_suffix: PropTypes.string,
        description: PropTypes.string,
        keywords: PropTypes.string,
        title: PropTypes.string,
        robots: PropTypes.string,
        status_code: PropTypes.string,
        pdp_script: PropTypes.any
    };

    // TODO implement logic
    _getTitle() {
        const {
            title, default_title, meta_ogg = [], meta_tag_title
        } = this.props;
        const ogg_meta_title = meta_ogg.find(({ property }) => property === 'og:title');
        const meta_title = ogg_meta_title?.content || title;

        return meta_tag_title || meta_title || default_title;
    }

    _getDescription() {
        const {
            description, default_description, meta_ogg = [], meta_tag_description
        } = this.props;

        const ogg_meta_desc = meta_ogg.find(({ property }) => property === 'og:description');
        const meta_description = ogg_meta_desc?.content || description;

        if (meta_tag_description) {
            return meta_tag_description;
        }
        if (meta_description) {
            return meta_description;
        }

        return default_description;
    }

    render() {
        return (
            <Meta
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
