/* eslint-disable no-console */
/* eslint-disable max-len */
export const UPDATE_CUSTOMER_INTERESTS_DATA = 'UPDATE_CUSTOMER_INTERESTS_DATA';
export const SET_CUSTOMER_INTERESTS_LOADING = 'SET_CUSTOMER_INTERESTS_LOADING';
export const SAVE_CUSTOMER_INTERESTS_DATA = 'SAVE_CUSTOMER_INTERESTS_DATA';

/** @namespace Pwa/Store/CustomerInterests/Action/updateCustomerInterestsData */
export const updateCustomerInterestsData = ({ active = false, all_activities = [], customer_interest = [] }, isLoading = false) => ({
    type: UPDATE_CUSTOMER_INTERESTS_DATA,
    active,
    all_activities,
    customer_interest,
    isLoading
});

/** @namespace Pwa/Store/CustomerInterests/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_CUSTOMER_INTERESTS_LOADING,
    isLoading
});

/** @namespace Pwa/Store/CustomerInterests/Action/saveCustomerInterestsData */
export const saveCustomerInterestsData = (data, isLoading) => ({
    type: SAVE_CUSTOMER_INTERESTS_DATA,
    data,
    isLoading
});
