/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import {
    SAVE_COMPANY_USERS_DATA,
    SET_COMPANY_USERS_ADD,
    SET_COMPANY_USERS_LOADING,
    UPDATE_COMPANY_USERS_DATA
} from './CompanyUsers.action';

export const initialState = {
    companyUsers: {},
    isLoading: true,
    isOpenPopup: false
};

/** @namespace Pwa/Store/CompanyUsers/Reducer/CompanyUsersReducer */
export const CompanyUsersReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        isOpenPopup = false,
        companyUsers
    } = action;

    const list = state.data;

    switch (type) {
    case UPDATE_COMPANY_USERS_DATA:
        return {
            ...state,
            isLoading,
            companyUsers
        };
    case SET_COMPANY_USERS_LOADING:
        return {
            ...state,
            isLoading
        };

    case SET_COMPANY_USERS_ADD:
        return {
            ...state,
            isOpenPopup
        };
    case SAVE_COMPANY_USERS_DATA:
        const updated_company_users = {};
        return {
            ...state,
            customer_interest: updated_company_users,
            isLoading
        };
    default:
        return state;
    }
};

export default CompanyUsersReducer;
