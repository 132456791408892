import {
    DELIVERY_METHOD_UNAVAILABLE_MESSAGE as SourceDELIVERY_METHOD_UNAVAILABLE_MESSAGE,
    DISPLAY_SHIPPING_PRICES_BOTH as SourceDISPLAY_SHIPPING_PRICES_BOTH,
    DISPLAY_SHIPPING_PRICES_EXCL_TAX as SourceDISPLAY_SHIPPING_PRICES_EXCL_TAX,
    DISPLAY_SHIPPING_PRICES_INCL_TAX as SourceDISPLAY_SHIPPING_PRICES_INCL_TAX
} from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.config';

// TODO: implement DISPLAY_SHIPPING_PRICES_INCL_TAX
export const DISPLAY_SHIPPING_PRICES_INCL_TAX = SourceDISPLAY_SHIPPING_PRICES_INCL_TAX;

// TODO: implement DISPLAY_SHIPPING_PRICES_EXCL_TAX
export const DISPLAY_SHIPPING_PRICES_EXCL_TAX = SourceDISPLAY_SHIPPING_PRICES_EXCL_TAX;

// TODO: implement DISPLAY_SHIPPING_PRICES_BOTH
export const DISPLAY_SHIPPING_PRICES_BOTH = SourceDISPLAY_SHIPPING_PRICES_BOTH;

// TODO: implement DELIVERY_METHOD_UNAVAILABLE_MESSAGE
export const DELIVERY_METHOD_UNAVAILABLE_MESSAGE = SourceDELIVERY_METHOD_UNAVAILABLE_MESSAGE;
