/* eslint-disable arrow-body-style */
/* eslint-disable fp/no-let */
/* eslint-disable max-len */
/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { getAuthorizationToken, isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getCurrency } from 'Util/Currency';

import { hash } from './Hash';

export const GRAPHQL_URI = '/graphql';
export const WINDOW_ID = 'WINDOW_ID';
export const TIMEOUT = 120000;

// Use Stellate to fetch data from GraphQL endpoint
/** @namespace Pwa/Util/Request/useStellate */
export const useStellate = (query, method = 'POST', variables = {}, options = {}) => {
    if (process.env.NODE_ENV !== 'production') {
        return false;
    }

    if (method === 'POST') {
        // Fsync Slider
        if (query.includes('slider:fsyncSlider')) {
            return true;
        }
        if (!isSignedIn()) {
            // Product filters (Landing pages)
            if (query.includes('ProductAttributeFilterInput')) {
                return true;
            }
            // Get privacy information
            // if (query.includes('getPrivacyInformation')) {
            //     return true;
            // }
            // Products list filter query
            if (query.includes('id,sku,name,type_id,stock_status,tax_description,new_from_date,thumbnail')) {
                return true;
            }
        }
    }

    return false;
};

/** @namespace Pwa/Util/Request/getWindowId */
export const getWindowId = () => {
    const result = sessionStorage.getItem(WINDOW_ID);

    if (!result) {
        const id = Date.now();
        sessionStorage.setItem(WINDOW_ID, id);
        return id;
    }

    return result;
};

/** @namespace Pwa/Util/Request/getStoreCodePath */
export const getStoreCodePath = () => {
    const path = location.pathname;
    // eslint-disable-next-line no-undef
    const firstPathPart = path.split('/')[1];

    if (window.storeList.includes(firstPathPart)) {
        return `/${ firstPathPart }`;
    }

    return '';
};

/** @namespace Pwa/Util/Request/getGraphqlEndpoint */
export const getGraphqlEndpoint = () => getStoreCodePath().concat(GRAPHQL_URI);

/**
 * Append authorization token to header object
 * @param {Object} headers
 * @returns {Object} Headers with appended authorization
 * @namespace Pwa/Util/Request/appendTokenToHeaders */
export const appendTokenToHeaders = (headers) => {
    const token = getAuthorizationToken();

    return {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
        Store: window.storeConfig.code
    };
};
/** @namespace Pwa/Util/Request/timeout */
export const timeout = (time) => {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), time);
    return controller;
};
/**
 *
 * @param {String} query Request body
 * @param {Object} variables Request variables
 * @param {String} url GraphQL url
 * @returns {*}
 * @namespace Pwa/Util/Request/formatURI */
export const formatURI = (query, variables, url) => {
    variables._currency = getCurrency();
    const { currencyCode = '', code, baseName = '/en/uk/' } = window.storeConfig;
    // if (baseName !== '/en/uk/') {
    //     // eslint-disable-next-line no-param-reassign
    //     if (query.includes('storeConfig')) {
    //         variables._currency = Date.now();
    //     } else {
    //         variables._currency = currencyCode;
    //     }
    // variables._currency = code;
    // }

    const stringifyVariables = Object.keys(variables).reduce(
        (acc, variable) => [...acc, `${ variable }=${ JSON.stringify(variables[variable]) }`],
        [`?hash=${ hash(query) }`]
    );
    let groupId = '';
    if (query.includes('search') && !query.includes('customer_group_id')) {
        const { group_id } = BrowserDatabase.getItem(CUSTOMER) || {};
        const id = group_id || '0';
        // groupId = `&filter_1={%22price%22:{},%22customer_group_id%22:{%22eq%22:${id}}}&timestamp=${Date.now()}`;
        groupId = `&filter_1={"price":{},"customer_group_id":{"eq":${id}}}`;
        // http://localhost:3000/graphql?hash=1201750627&sort_1={%22relevance%22:%22DESC%22}&filter_1={%22price%22:{},%22customer_group_id%22:{%22eq%22:47}}&search_1=%22KRTT951T%22&pageSize_1=24&currentPage_1=1&_currency=%22%22%27&store_code=%27en_us&customer_group_id=47&timestamp=1649132802653
    }
    const updatedUrl = `${ url }${ stringifyVariables.join('&') }&store_code=${code}${groupId}`;

    // Stellate cache query
    // if (process.env.NODE_ENV === 'production' && !isSignedIn() && updatedUrl.includes('type_1="PRODUCT"')) {
    //     const urlWithoutGraphql = updatedUrl.replace('/graphql', '');
    //     return `https://graphql.frontrunneroutfitters.com${urlWithoutGraphql}`;
    // }

    return updatedUrl;
};

/**
 *
 * @param {String} uri
 * @param {String} name
 * @returns {Promise<Response>}
 * @namespace Pwa/Util/Request/getFetch */

export const getFetch = (uri, name) => {
    // Stellate cache query
    // if (process.env.NODE_ENV === 'production' && !isSignedIn() && uri.includes('type_1="PRODUCT"')) {
    //     let mageUri = uri;
    //     if (!uri.includes('store_code=')) {
    //         mageUri = `${uri}&store_code=${window.storeConfig.code}`;
    //     }

    //     return fetch(mageUri,
    //         {
    //             method: 'GET',
    //             headers: appendTokenToHeaders({
    //                 'Content-Type': 'application/json',
    //                 'Application-Model': `${ name }_${ getWindowId() }`,
    //                 Accept: 'application/json',
    //                 'gcdn-force': '1',
    //                 'Store-Code': window.storeConfig.code
    //             }),
    //             signal: timeout(TIMEOUT).signal
    //         });
    // }

    return fetch(uri,
        {
            method: 'GET',
            headers: appendTokenToHeaders({
                'Content-Type': 'application/json',
                'Application-Model': `${ name }_${ getWindowId() }`,
                Accept: 'application/json'
            }),
            signal: timeout(TIMEOUT).signal
        });
};

/**
 *
 * @param {String} graphQlURI
 * @param {String} queryObject
 * @param {String} name
 * @returns {Promise<Response>}
 * @namespace Pwa/Util/Request/getFsyncFetch */
export const getFsyncFetch = (url, graphql) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    return fetch(`${url}${graphql}`,
        {
            method: 'GET',
            headers: myHeaders,
            signal: timeout(TIMEOUT).signal
        });
};

/**
 *
 * @param {String} graphQlURI
 * @param {String} queryObject
 * @param {String} name
 * @returns {Promise<Response>}
 * @namespace Pwa/Util/Request/postFsyncFetch */
export const postFsyncFetch = (url, graphql, query, variables) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    fetch(`${url}${graphql}`,
        {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({ query, variables }),
            signal: timeout(TIMEOUT).signal

        });
};

/**
 *
 * @param {String} graphQlURI
 * @param {{}} query Request body
 * @param {Int} cacheTTL
 * @namespace Pwa/Util/Request/putPersistedQuery */
export const putPersistedQuery = (graphQlURI, query, cacheTTL) => fetch(`${ graphQlURI }?hash=${ hash(query) }`,
    {
        method: 'PUT',
        body: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json',
            'SW-Cache-Age': cacheTTL
        },
        signal: timeout(TIMEOUT).signal

    });

/**
 *
 * @param {String} graphQlURI
 * @param {String} queryObject
 * @param {String} name
 * @returns {Promise<Response>}
 * @namespace Pwa/Util/Request/postFetch */
export const postFetch = (graphQlURI, query, variables) => {
    // Use Stellate?
    // TODO: @Chris switch on after initial release
    /* if (useStellate(query, 'POST') === true) {
        const urlWithoutGraphql = graphQlURI.replace('/graphql', '');
        let url = `https://frontrunner-staging.stellate.sh${urlWithoutGraphql}?store_code=${window.storeConfig.code}`;
        if (window.location.origin.includes('www.frontrunneroutfitters.com')) {
            url = `https://graphql.frontrunneroutfitters.com${urlWithoutGraphql}?store_code=${window.storeConfig.code}`;
        }

        return fetch(url,
            {
                method: 'POST',
                body: JSON.stringify({ query, variables }),
                headers: appendTokenToHeaders({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'gcdn-force': '1',
                    'Store-Code': window.storeConfig.code
                }),
                signal: timeout(TIMEOUT).signal
            });
    } */

    return fetch(graphQlURI,
        {
            method: 'POST',
            body: JSON.stringify({ query, variables }),
            headers: appendTokenToHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }),
            signal: timeout(TIMEOUT).signal
        });
};

/**
 * Checks for errors in response, if they exist, rejects promise
 * @param  {Object} res Response from GraphQL endpoint
 * @return {Promise<Object>} Handled GraphqlQL results promise
 * @namespace Pwa/Util/Request/checkForErrors */
export const checkForErrors = (res) => new Promise((resolve, reject) => {
    const { errors, data } = res;
    return errors ? reject(errors) : resolve(data);
});

/**
 * Handle connection errors
 * @param  {any} err Error from fetch
 * @return {void} Simply console error
 * @namespace Pwa/Util/Request/handleConnectionError */
export const handleConnectionError = (err) => console.error(err); // TODO: Add to logs pool

/**
 * Parse response and check wether it contains errors
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Pwa/Util/Request/parseResponse */
export const parseResponse = (promise) => new Promise((resolve, reject) => {
    promise.then(
        /** @namespace Pwa/Util/Request/then */
        (res) => res.json().then(
            /** @namespace Pwa/Util/Request/json/then */
            (res) => resolve(checkForErrors(res)),
            /** @namespace Pwa/Util/Request/json/then */
            () => handleConnectionError('Can not transform JSON!') && reject()
        ),
        /** @namespace Pwa/Util/Request/then */
        (err) => handleConnectionError('Can not establish connection!') && reject(err)
    );
});

export const HTTP_410_GONE = 410;
export const HTTP_201_CREATED = 201;

/**
 * Make GET request to endpoint (via ServiceWorker)
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @param  {String} name Name of model for ServiceWorker to send BroadCasts updates to
 * @param  {Number} cacheTTL Cache TTL (in seconds) for ServiceWorker to cache responses
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Pwa/Util/Request/executeGet */
export const executeGet = (queryObject, name, cacheTTL) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    return parseResponse(new Promise((resolve) => {
        getFetch(uri, name).then(
            /** @namespace Pwa/Util/Request/getFetch/then */
            (res) => {
                if (res.status === HTTP_410_GONE) {
                    putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                        /** @namespace Pwa/Util/Request/putPersistedQuery/then */
                        (putResponse) => {
                            if (putResponse.status === HTTP_201_CREATED) {
                                getFetch(uri, name).then(
                                    /** @namespace Pwa/Util/Request/getFetch/then */
                                    (res) => resolve(res)
                                );
                            }
                        }
                    );
                } else {
                    resolve(res);
                }
            }
        );
    }));
};

/**
 * Make POST request to endpoint
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Pwa/Util/Request/executePost */
export const executePost = (queryObject) => {
    const { query, variables } = queryObject;

    return parseResponse(postFetch(getGraphqlEndpoint(), query, variables));
};
/**
 * Make POST request to endpoint
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Pwa/Util/Request/executeFsyncGet */
export const executeFsyncGet = (url, graphql, name, cacheTTL) => parseResponse(new Promise((resolve) => {
    getFsyncFetch(url, graphql).then(
        /** @namespace Pwa/Util/Request/getFsyncFetch/then */
        (res) => {
            if (res.status === HTTP_410_GONE) {
                putPersistedQuery(url, graphql, cacheTTL).then(
                    /** @namespace Pwa/Util/Request/putPersistedQuery/then */
                    (putResponse) => {
                        if (putResponse.status === HTTP_201_CREATED) {
                            getFsyncFetch(url, graphql).then(
                                /** @namespace Pwa/Util/Request/getFsyncFetch/then */
                                (res) => resolve(res)
                            );
                        }
                    }
                );
            } else {
                resolve(res);
            }
        }
    );
}));

/**
 * Make POST request to Fsync endpoint
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Pwa/Util/Request/executeFsyncPost */
export const executeFsyncPost = (queryObject) => {
    const { query, variables } = queryObject;

    return parseResponse(postFsyncFetch('https://frontend.hypernode.frontrunneroutfitters.com/en/us/', 'graphql', query, variables));
};

/**
 * Listen to the BroadCast connection
 * @param  {String} name Name of model for ServiceWorker to send BroadCasts updates to
 * @return {Promise<any>} Broadcast message promise
 * @namespace Pwa/Util/Request/listenForBroadCast */
export const listenForBroadCast = (name) => new Promise((resolve) => {
    const { BroadcastChannel } = window;
    const windowId = getWindowId();

    if (BroadcastChannel) {
        const bc = new BroadcastChannel(`${ name }_${ windowId }`);
        bc.onmessage = (update) => {
            const { data: { payload: body } } = update;
            resolve(checkForErrors(body));
        };
    }
});

/** @namespace Pwa/Util/Request/Debouncer */
export const Debouncer = (callback, delay) => {
    // eslint-disable-next-line fp/no-let
    let timeout;
    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), delay);
    };
};

/** @namespace Pwa/Util/Request/debounce */
export const debounce = (callback, delay) => {
    // eslint-disable-next-line fp/no-let
    let timeout;
    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), delay);
    };
};
