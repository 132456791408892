/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable fp/no-let */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */

import PropTypes from 'prop-types';

import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import {
    NoMatch as SourceNoMatch
} from 'SourceRoute/NoMatch/NoMatch.component';

import './NoMatch.override.style';

/** @namespace Pwa/Route/NoMatch/Component/NoMatch */
export class NoMatch extends SourceNoMatch {
    static propTypes = {
        updateBreadcrumbs: PropTypes.func.isRequired,
        cleanUpTransition: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.updateBreadcrumbs();
        this.cleanUpTransition();
    }

    cleanUpTransition() {
        const { cleanUpTransition } = this.props;

        cleanUpTransition();
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;
        const breadcrumbs = [
            {
                url: '',
                name: __('Not Found')
            }
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    render() {
        return (
            <main block="NoMatch" aria-label={ __('Page not found') }>
                <ContentWrapper
                  mix={ { block: 'NoMatch' } }
                  wrapperMix={ { block: 'NoMatch', elem: 'Wrapper' } }
                  label={ __('Page Not Found Content') }
                >
                    <h1>
                        404
                    </h1>
                    <p block="NoMatch" elem="Subtitle">
                        { __('Page not found') }
                    </p>
                    <p block="NoMatch" elem="Body">
                        { /* eslint-disable-next-line max-len */ }
                        { __('Oops! It seems like the page you\'re searching for has wandered off. Click the button below to head back to our homepage to continue exploring.') }
                    </p>
                    <Link
                      to="/"
                      block="NoMatch"
                      elem="Button"
                      mix={ { block: 'Button' } }
                    >
                        { __('Back to homepage') }
                    </Link>
                </ContentWrapper>
            </main>
        );
    }
}

export default NoMatch;
