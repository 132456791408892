/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_HOME_PAGE = 'UPDATE_HOME_PAGE';

/**
 * Update HOME Page information
 * @param {String} urlKey URL Key of the page that must be returned
 * @namespace Pwa/Store/HomePage/Action/updateHomePage
 */
export const updateHomePage = ({ slider, productSlider, content, newLetterPopupConfig, companyCustomerData, bestSellerProductsData }, isLoading) => ({
    type: UPDATE_HOME_PAGE,
    slider,
    productSlider,
    content,
    newLetterPopupConfig,
    companyCustomerData,
    bestSellerProductsData,
    isLoading
});
