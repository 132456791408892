/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import PropTypes from 'prop-types';

import Field from 'Component/Field';
import {
    CategorySort as SourceCategorySort
} from 'SourceComponent/CategorySort/CategorySort.component';

import './CategorySort.style.override';

export class CategorySort extends SourceCategorySort {
    // TODO implement logic
    static propTypes = {
        onSortChange: PropTypes.func.isRequired,
        sortKey: PropTypes.string.isRequired,
        sortDirection: PropTypes.string.isRequired,
        selectOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            disabled: PropTypes.bool,
            label: PropTypes.any
        })).isRequired,
        isMatchingInfoFilter: PropTypes.bool
    };

    renderSortField() {
        const {
            sortKey,
            sortDirection,
            selectOptions,
            isMatchingInfoFilter
        } = this.props;

        // const isVehiclesPage = window.location.href.includes('/vehicle/') || window.location.href.includes('?find=');
        const isVehiclesPage = window.location.href.includes('/vehicle/');

        if (isVehiclesPage) {
            return null;
        }

        if (!isMatchingInfoFilter) {
            return this.renderPlaceholder();
        }

        // Remove "DESC position" from sort options
        const filteredOptions = selectOptions.filter((option) => option.value !== 'DESC position');

        // Rename "ASC position" to "Default"
        filteredOptions.find((option) => option.value === 'ASC position').label = __('Default');

        // Add "Sort" to the beginning of the label for all options
        const sortLabel = __('Sort by');
        filteredOptions.forEach((option) => {
            option.label = `${sortLabel} ${option.label}`;
        });

        // if (!isMatchingInfoFilter && !isVehiclesPage) {
        //     return this.renderPlaceholder();
        // }

        return (
            <div className="CategorySort-Wrapper">
                <Field
                  id="category-sort"
                  name="category-sort"
                  type="select"
                  label={ __('SORT') }
                  sorting="0"
                  mix={ { block: 'CategorySort', elem: 'Select' } }
                  selectOptions={ filteredOptions }
                  value={ `${sortDirection} ${sortKey}` }
                  onChange={ this.onChange }
                />
            </div>
        );
    }
}

export default CategorySort;
