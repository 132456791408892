/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import {
    SAVE_COMPANY_PRICELIST_DATA,
    SET_COMPANY_PRICELIST_LOADING,
    UPDATE_COMPANY_PRICELIST_API_DATA,
    UPDATE_COMPANY_PRICELIST_DATA
} from './CompanyPriceList.action';

export const initialState = {
    company: {},
    api_data: '',
    isLoading: true
};

/** @namespace Pwa/Store/CompanyPriceList/Reducer/CompanyPriceListReducer */
export const CompanyPriceListReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        company,
        data,
        api_data
    } = action;

    const list = state.data;

    switch (type) {
    case UPDATE_COMPANY_PRICELIST_DATA:
        return {
            ...state,
            isLoading,
            company
        };
    case SET_COMPANY_PRICELIST_LOADING:
        return {
            ...state,
            isLoading
        };
    case UPDATE_COMPANY_PRICELIST_API_DATA:
        return {
            ...state,
            api_data,
            isLoading
        };
    case SAVE_COMPANY_PRICELIST_DATA:
        const updated_company_pricelist = {};
        return {
            ...state,
            customer_interest: updated_company_pricelist,
            isLoading
        };
    default:
        return state;
    }
};

export default CompanyPriceListReducer;
