/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/forbid-prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { MENU_SUBCATEGORY } from 'Component/Header/Header.config';
import MenuQuery from 'Query/Menu.query';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import MenuHelper from 'Util/Menu';
import DataContainer from 'Util/Request/DataContainer';

import HeaderMenu from './HeaderMenu.component';

/** @namespace Pwa/Component/HeaderMenu/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    menu: state.HeaderAndFooterReducer.desktopMenu,
    customer: state.MyAccountReducer.customer

});

/** @namespace Pwa/Component/HeaderMenu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state))
});

/** @namespace Pwa/Component/HeaderMenu/Container/HeaderMenuContainer */
export class HeaderMenuContainer extends DataContainer {
    static propTypes = {
        goToPreviousHeaderState: PropTypes.func.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        menu: PropTypes.any
    };

    state = {
        activeMenuItemsStack: [],
        currentSubCategoryMenu: null,
        activeMenu: ''
    };

    containerFunctions = {
        handleSubcategoryClick: this.handleSubcategoryClick.bind(this),
        closeMenu: this.closeMenu.bind(this),
        onCategoryHover: this.onCategoryHover.bind(this),
        onCategoryClick: this.onCategoryClick.bind(this)

    };

    componentDidMount() {
        // this._getMenu();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.headerMenuClick !== this.props.headerMenuClick) {
            this.onCategoryClick(true);
        }
    }

    _getMenuOptions() {
        const { header_content: { header_menu } = {} } = window.contentConfiguration;

        return {
            identifier: header_menu || 'new-main-menu'
        };
    }

    _getMenu() {
        this.fetchData(
            [MenuQuery.getQuery(this._getMenuOptions())],
            ({ menu }) => this.setState({
                menu: MenuHelper.reduce(menu)
            })
        );
    }

    handleSubcategoryClick(e, activeSubcategory) {
        const { activeMenuItemsStack } = this.state;
        const { changeHeaderState, goToPreviousHeaderState } = this.props;
        const { title } = activeSubcategory;
        e.stopPropagation();

        changeHeaderState({
            name: MENU_SUBCATEGORY,
            force: true,
            title,
            onBackClick: () => {
                this.setState(({ activeMenuItemsStack }) => (
                    { activeMenuItemsStack: activeMenuItemsStack.slice(1) }
                ));
                goToPreviousHeaderState();
            }
        });

        if (!activeMenuItemsStack.includes(title)) {
            this.setState({ activeMenuItemsStack: [title, ...activeMenuItemsStack] });
        }
    }

    onCategoryHover(activeSubcategory) {
        const { device } = this.props;
        if (device.isMobile) {
            return;
        }

        const { activeMenuItemsStack } = this.state;
        const { title, category_id } = activeSubcategory;

        if (!activeMenuItemsStack.includes(title)) {
            this.setState({ activeMenuItemsStack: [title], activeMenuId: category_id });
        }
    }

    onCategoryClick(activeSubcategory) {
        const { device } = this.props;
        const { activeMenu, activeMenuItem } = this.state;
        if (device.isMobile) {
            return;
        }
        this.setState({ subMenu: activeSubcategory, hoverMenu: {} });
        const { activeMenuItemsStack } = this.state;
        const { title } = activeSubcategory;
        if (!activeMenuItemsStack.includes(title)) {
            this.setState({ activeMenuItemsStack: [title] });
        }
        if (activeSubcategory === true) {
            return (
                this.setState({ activeMenu: '' })
            );
        }

        if (activeMenu) {
            this.setState({ activeMenu: '' });
        } else {
            this.setState({ activeMenu: 'activeMenu' });
        }
        if (this.state.subMenu && this.state.subMenu.type !== activeSubcategory.type) {
            this.setState({ activeMenu: 'activeMenu' });
        }
    }

    closeMenu() {
        const { device } = this.props;
        if (device.isMobile) {
            return;
        }
        this.setState({ activeMenu: '' });
        this.setState({ activeMenuItemsStack: [] });
    }

    render() {
        return (
            <HeaderMenu
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenuContainer);
