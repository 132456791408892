import {
    SET_NEWSLETTER_SUBSCRIPTION_LOADING,
    SET_NEWSLETTER_SUBSCRIPTION_RESPONSE
} from './NewsletterSubscription.action';

export const initialState = {
    isLoading: false,
    data: {}
};

/** @namespace Pwa/Store/NewsletterSubscription/Reducer/NewsletterSubscriptionReducer */
export const NewsletterSubscriptionReducer = (state = initialState, action) => {
    const {
        isLoading,
        data,
        type
    } = action;

    switch (type) {
    case SET_NEWSLETTER_SUBSCRIPTION_LOADING:
        return {
            ...state,
            isLoading
        };
    case SET_NEWSLETTER_SUBSCRIPTION_RESPONSE:
        return {
            ...state,
            isLoading: false,
            data
        };
    default:
        return state;
    }
};

export default NewsletterSubscriptionReducer;
