/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

import history from 'Util/History';

/** @namespace Pwa/Util/Algolia/DebouncedSearchBox/SearchBox */
export class SearchBox extends Component {
	timerId = null;

	state = {
	    value: this.props.currentRefinement
	};

	onChangeDebounced = (event) => {
	    const {
	        refine, delay, onSearchResShow, onSearchRes
	    } = this.props;
	    const { value } = event.currentTarget;

	    clearTimeout(this.timerId);

	    this.timerId = setTimeout(() => {
	        refine(value);
	    }, delay);

	    this.setState(() => ({
	        value
	    }));

	    // Optionally, you can show/hide search results based on input change
	    if (event.target.value.trim() !== '') {
	        onSearchResShow();
	    } else {
	        onSearchRes();
	    }
	};

	 // Function to clear the search box
	 clearSearchBox = () => {
	     const { onSearchRes } = this.props;
		 onSearchRes();
	     this.setState({ value: '' });
	  };

	 handleSubmit(e) {
	    const { closeSearch, onSearchOutsideClick } = this.props;
	    e.preventDefault();
	    if (typeof (this.props.onSearchOutsideClick) === 'function') {
	    closeSearch();
	    onSearchOutsideClick();
	    }
	    const { value } = this.state;
	    history.push(`/search?q=${value}`);
	 }

	 render() {
	    const { value } = this.state;
	    return (
			<div className="ais-SearchBox">
				<form className="ais-SearchBox-form" role="search" onSubmit={ this.handleSubmit.bind(this) }>
					<input
  type="text"
  className="ais-SearchBox-input"
  value={ value }
  onChange={ this.onChangeDebounced }
  placeholder={ __('I\'m looking for...') }
//   placeholder={ __('Search racks, accessories, gear, etc.') }
  mods={ this.props.isActive }
  ref={ this.props.ref }
  onFocus={ this.props.onSearchBarFocus }
					/>
				</form>
				{ value && (
					<button className="cancel-button" onClick={ this.clearSearchBox }>
						<FontAwesomeIcon icon={ faClose } />
					</button>
				) }
			</div>
	    );
	 }
}

export default SearchBox;
