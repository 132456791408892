/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
import { Field } from 'Util/Query';

class MaintenanceQuery {
    fetchMainetance = () => new Field('MpBetterMaintenanceConfigDetails')
        .addArgument('key', 'String!', Math.random() * 100001)
        .addFieldList([
            'enabled',
            'end_time'
        ]);

    checkMainetance= '/media/check.php';
}

export default new MaintenanceQuery();
