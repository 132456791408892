/* eslint-disable no-console */
import { AUTHNET_PAYMENT, GET_SAVED_CARDS, SAVE_SELECTED_CARD } from './Authnet.action';

export const initialState = {
    cards: [],
    selectedCard: '',
    isAuthnetPaymentClicked: false
};

/** @namespace Pwa/Store/Authnet/Reducer/AuthnetReducer */
export const AuthnetReducer = (state = initialState, action) => {
    const {
        type,
        data,
        selectedCard,
        isAuthnetPaymentClicked
    } = action;

    switch (type) {
    case GET_SAVED_CARDS:
        return {
            ...state,
            cards: data.data
        };
    case SAVE_SELECTED_CARD:
        return {
            ...state,
            selectedCard
        };
    case AUTHNET_PAYMENT:
        return {
            ...state,
            isAuthnetPaymentClicked
        };
    default:
        return state;
    }
};

export default AuthnetReducer;
