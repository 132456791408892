/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { getIndexedProduct } from 'Util/Product';

import { UPDATE_QUICK_ADDED_ITEMS, UPDATE_QUICK_ITEMS_DETAILS, UPDATE_QUICK_ITEMS_LOADING } from './QuickOrder.action';

/** @namespace Pwa/Store/QuickOrder/Reducer/getInitialState */
export const getInitialState = () => ({
    products: [],
    addedProducts: [],
    loading: false
});

/** @namespace Pwa/Store/QuickOrder/Reducer/QuickOrderReducer */
export const QuickOrderReducer = (
    state = getInitialState(),
    action
) => {
    const { product, isLoading, addedProducts } = action;

    switch (action.type) {
    case UPDATE_QUICK_ITEMS_DETAILS:
        return {
            ...state,
            products: getIndexedProduct(product),
            loading: isLoading
        };
    case UPDATE_QUICK_ADDED_ITEMS:
        return {
            ...state,
            addedProducts,
            loading: isLoading
        };
    case UPDATE_QUICK_ITEMS_LOADING:
        return {
            ...state,
            loading: isLoading
        };

    default:
        return state;
    }
};

export default QuickOrderReducer;
