/* eslint-disable no-new */
/* eslint-disable no-undef */
/* eslint-disable fp/no-let */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/no-unused-prop-types */
import { Component } from 'react';

// import Link from 'Component/Link';
import Overlay from 'Component/Overlay';

import './LanguageSelector.style';

/** @namespace Pwa/Component/LanguageSelector/Component/LanguageSelector */
export class LanguageSelector extends Component {
    render() {
        return (
      <Overlay
        id="langSwitcher"
        mix={ { block: 'langSwitcher' } }
      >
        <div className="langSwitcherPopup">
          <div>
              { __('Select Language') }
          </div>
          <div className="language-popup-footer">
            <div className="language-popup-region"> Translate this website </div>
          </div>
        </div>
      </Overlay>
        );
    }
}

export default LanguageSelector;
