/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { setQueryParams } from '@scandipwa/scandipwa/src/util/Url';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    ProductListContainer as SourceProductListContainer
} from 'SourceComponent/ProductList/ProductList.container';
import ProductListInfoDispatcher from 'Store/ProductListInfo/ProductListInfo.dispatcher';
import { scrollToTop, updatePageInCookies } from 'Util/Helper';
import { getQueryParam } from 'Util/Url';

import ProductList from './ProductList.component';

/** @namespace Pwa/Component/ProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    customer: state.MyAccountReducer.customer
});

/** @namespace Pwa/Component/ProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestProductListInfo: (options) => ProductListInfoDispatcher.handleData(dispatch, options)
});

/** @namespace Pwa/Component/ProductList/Container/ProductListContainer */
export class ProductListContainer extends SourceProductListContainer {
    // _getIsInfiniteLoaderEnabled() { // disable infinite scroll on mobile
    // }
    containerFunctions = {
        loadPrevPage: this.loadPage.bind(this, false),
        loadPage: this.loadPage.bind(this),
        updatePage: this.updatePage.bind(this)
    };

    static defaultProps = {
        pageSize: 20,
        filter: {},
        search: '',
        selectedFilters: {},
        sort: undefined,
        isPreventRequest: false,
        isPaginationEnabled: true,
        isInfiniteLoaderEnabled: true,
        isPageLoading: false,
        noAttributes: false,
        noVariants: false,
        isWidget: false
    };

    state = {
        pagesCount: 1,
        bottom: false
    };

    isEmptyFilter() {
        const { filter } = this.props;

        const validFilters = Object.entries(filter).filter(([key, value]) => {
            switch (key) {
            case 'priceRange':
                return value.min > 0 || value.max > 0;
            case 'customFilters':
                return Object.keys(value).length > 0;
            case 'categoryIds':
            default:
                return true;
            }
        });

        /**
         * If there is more then one valid filter, filters are not empty.
         */
        return validFilters.length > 0;
    }

    componentDidUpdate(prevProps) {
        const { sort, search, filter } = this.props;
        const { sort: prevSort, search: prevSearch, filter: prevFilter } = prevProps;

        const { pages } = this.props;
        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }

        if (search !== prevSearch
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || JSON.stringify(filter) !== JSON.stringify(prevFilter)
        ) {
            this.requestPage(this._getPageFromUrl());
        }
    }

    requestPage = (currentPage = 1, isNext = false) => {
        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            isWidget,
            device
        } = this.props;

        /**
         * In case the wrong category was passed down to the product list,
         * prevent it from being requested.
         */
        if (filter.categoryIds === -1) {
            return;
        }

        /**
         * Do not request page if there are no filters
         */
        if (!search && !this.isEmptyFilter()) {
            return;
        }

        // TODO: product list requests filters alongside the page
        // TODO: sometimes product list is requested more then once
        // TODO: the product list should not request itself, when coming from PDP

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort,
                filter,
                search,
                pageSize,
                currentPage
            }
        };

        const infoOptions = {
            args: {
                filter,
                search
            }
        };

        requestProductList(options);

        if (!isWidget) {
            requestProductListInfo(infoOptions);
        }
    };

    containerProps = () => ({
        currentPage: this._getPageFromUrl(),
        isShowLoading: this._isShowLoading(),
        isVisible: this._isVisible(),
        requestPage: this.requestPage,
        // disable this property to enable infinite scroll on desktop
        isInfiniteLoaderEnabled: this._getIsInfiniteLoaderEnabled()
    });

    _getIsInfiniteLoaderEnabled() { // disable infinite scroll on mobile
        const { isInfiniteLoaderEnabled, device, location } = this.props;
        const { pathname } = location;

        if (pathname && pathname.includes('/vehicle/') && device.isMobile) {
            return false;
        }

        // allow scroll and mobile
        if (device.isMobile) {
            return isInfiniteLoaderEnabled;
        }

        return false;
    }

    updatePage(pageNumber) {
        const { location, history } = this.props;

        setQueryParams({
            page: pageNumber === 1 ? '' : pageNumber
        }, location, history);
    }

    render() {
        return (
            <ProductList
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListContainer));
