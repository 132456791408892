/* eslint-disable eqeqeq */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable consistent-return */
/* eslint-disable max-lines */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-undef */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import {
    faEdit, faQuestionCircle, faUser, faUserCircle, faUsers
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    get,
    isEmpty, map
} from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Popup from 'reactjs-popup';

import AddTeamForm from 'Component/AddTeamForm/AddTeamForm.container';
import AddUserForm from 'Component/AddUserForm/AddUserForm.container';
import AutoComplete from 'Component/AutoComplete';
import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import CloseIcon from 'Component/CloseIcon';
import Loader from 'Component/Loader';

import {
    ADD_TEAM_POPUP, ADD_USER_POPUP, DELETE_SELECTION_POPUP, EDIT_SELECTION_POPUP, NO_SELECTION_POPUP
} from './CompanyStructure.config';

import './CompanyStructure.style';

export const items = [
    {
        id: 0,
        name: 'test'
    },
    {
        id: 1,
        name: 'test1'
    }
];

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/derived-class-names
export class CompanyStructure extends PureComponent {
  static propTypes = {
      handleTeam: PropTypes.func,
      handleDelete: PropTypes.func,
      handleUser: PropTypes.func,
      action: PropTypes.string,
      onActionPopupClick: PropTypes.func.isRequired,
      onClosePopupClick: PropTypes.func.isRequired,
      handleSelection: PropTypes.func.isRequired,
      updateParentTree: PropTypes.func,
      openPopup: false,
      popup_type: '',
      selected_entity: ''
  };

  state = {
      selected: false,
      selectedToUpdateParent: ''
  };

  componentDidMount() {
  }

    renderCompanyStructure = () => {
        const { companyStructure: { id, name, structure = {} } } = this.props;
        const { action = 'normal' } = this.state;
        const parentTree = 'jstree-container-ul normal';
        let { items = [] } = structure;

        if (!structure || isEmpty(structure.items) || structure.items.length < 1) {
            items = [];
        }

        const nest = (items, id = null, link = 'parent_id') => items
            .filter((item) => item[link].toString().toLowerCase() === id.toString().toLowerCase())
            .map((item) => ({ ...item, children: nest(items, item.id) }));

        let treeItems = nest(items, id);
        if (treeItems.length < 1) {
            treeItems = nest(items, 'MA==');
        }

        return (
          <div className="CompanyStructure">
                <div className="box-actions">
                  <button className="action expand" type="button" onClick={ () => this.handleAction({ action: 'expand' }) }>
                  { ' Expand All ' }
                  </button>
                  <button className="action expand" type="button" onClick={ () => this.handleAction({ action: 'collapse' }) }>
                  { ' Collapse All ' }
                  </button>
                  <button className="action add" id="add-customer" type="button" onClick={ () => this.handlePopupAction({ action: 'adduser' }) }>
                  { ' Add User ' }
                  </button>
                  <button className="action add" id="add-team" type="button" onClick={ () => this.handlePopupAction({ action: 'addteam' }) }>
                    { ' Add Team ' }
                  </button>
                  <button className="action edit" id="edit-selected" type="button" onClick={ () => this.handlePopupAction({ action: 'editselection' }) }>
                    { ' Edit Selected ' }
                  </button>
                  <button className="action delete" id="delete-selected" type="button" onClick={ () => this.handlePopupAction({ action: 'deleteselection' }) }>
                    { ' Delete Selected ' }
                  </button>
                  <button className="action _hidden" data-action="alert-modal" type="button">
                    { ' Error ' }
                  </button>
                </div>

                  <div className="company_tree">
                    <ul className={ parentTree }>
                      <li className="jstree-node">
                        <div className="jstree-anchor company-admin">
                          <FontAwesomeIcon icon={ faUserCircle } size="2x" color="#858585" />
                          { name }(me)
                        </div>
                        <ul className="jstree-children">
                        { !isEmpty(items) && map(treeItems, this.renderRowItem) }
                        </ul>
                      </li>
                    </ul>
                  </div>

          </div>
        );
    };

    renderRowItem = (item) => {
        const {
            entity: { __typename }, id, parent_id, children
        } = item;
        const dataItem = {
            ...item.entity, entityId: id, parent_id, children
        };

        return this.renderTeam(dataItem);
    };

    handleExpansion = (id) => {
        let isExpanded = this.state[id] || false;
        if (this.state[id] == undefined) {
            isExpanded = true;
        }
        this.setState({ [id]: !isExpanded });
    };

    handleAction = ({ action }) => {
        const { companyStructure: { id, name, structure = {} } } = this.props;

        let { items = [] } = structure;

        if (!structure || isEmpty(structure.items) || structure.items.length < 1) {
            items = [];
        }

        let val = false;

        if (action !== null && action === 'expand') {
            val = true;
        }

        const dataToUpdateState = {};
        items.forEach((item) => {
            const {
                entity: { __typename }, id, parent_id, children
            } = item;
            const team = {
                ...item.entity, entityId: id
            };

            const { id: structId, company_team_id } = team;

            let newkey = structId;

            if (!isEmpty(company_team_id)) {
                newkey = company_team_id;
            }
            dataToUpdateState[newkey] = val;
        });

        this.setState({
            // eslint-disable-next-line object-shorthand
            action: action, ...dataToUpdateState
        });
    };

    renderAllowedParentsForItem = (team) => {
        const { selectedToUpdateParent, allowedParentOptions } = this.state;
        return (
              <div className="parent-options">
                  <AutoComplete
                    suggestions={ allowedParentOptions }
                    data={ items }
                    name="Auto complete to assing new parrent"
                    updateParentStructure={ this.handleNewParentSelection }
                  />
              </div>
        );
    };

    handleNewParentSelection = (selectedParent) => {
        const { updateParentTree } = this.props;
        const { selectedToUpdateParent: { entityId } } = this.state;
        const { id, parent_id } = selectedParent;
        const dateParams = { tree_id: entityId, parent_tree_id: id };

        updateParentTree(dateParams);
        this.setState({ selectedToUpdateParent: '' });
    };

    updateSelectedToUpdateParent= (team) => {
        const { companyStructure: { structure = {} } } = this.props;
        const { action = 'normal' } = this.state;

        const { selectedToUpdateParent } = this.state;
        let newParentOptions = [];
        const blockedItemsForPerent = [];
        blockedItemsForPerent.push(selectedToUpdateParent);

        let { items = [] } = structure;
        if (!structure || isEmpty(structure.items) || structure.items.length < 1) {
            items = [];
            newParentOptions = [];
        }
        if (items.length > 0) {
            items.forEach((it) => {
                const {
                    id, parent_id, entity: {
                        firstname, description, lastname, name, __typename
                    }
                } = it;

                const updateName = (__typename === 'CompanyTeam') ? name : `${firstname } ${lastname }`;
                // if (id != selectedToUpdateParent.id) {
                let isBlockedParent = false;
                blockedItemsForPerent.forEach((element) => {
                    if (element.id == id || parent_id == element.id || selectedToUpdateParent.entityId == id || selectedToUpdateParent.entityId == parent_id || selectedToUpdateParent.parent_id == id || selectedToUpdateParent.parent_id == parent_id) {
                        isBlockedParent = true;
                        return isBlockedParent;
                    }
                });

                if (isBlockedParent) {
                    blockedItemsForPerent.push(it);
                } else {
                    newParentOptions.push({ ...it, name: updateName });
                }
                // }
            });
        }
        this.setState({ allowedParentOptions: newParentOptions });
        this.setState({ selectedToUpdateParent: team });
    };

    renderTeam = (team) => {
        const {
            __typename, firstname, description, lastname, name, email, id: structId, company_team_id, parent_id, children = []
        } = team;
        const nestedItems = (children || []).map((child) => this.renderRowItem(child));

        let id = structId;

        if (!isEmpty(company_team_id)) {
            id = company_team_id;
        }

        const { selected_entity, companyStructure: { structure, id: root_id } } = this.props;
        const { selectedToUpdateParent } = this.state;

        let itemClassName = 'jstree-node';
        let showOption = false;
        if (__typename === 'Customer') {
            itemClassName = `${itemClassName } Customer`;
        }
        if (__typename === 'CompanyTeam') {
            itemClassName = `${itemClassName } CompanyTeam`;
        }

        if (!isEmpty(selected_entity) && (id === selected_entity.id || id === selected_entity.company_team_id)) {
            itemClassName = `${itemClassName } Selected`;
        }
        if (!isEmpty(selectedToUpdateParent) && (id === selectedToUpdateParent.id || id === selectedToUpdateParent.company_team_id)) {
            showOption = true;
        }
        const isExpanded = this.state[id] || false;
        let itemsOpen = 'closed';

        if (isExpanded) {
            itemsOpen = 'open';
        } else {
            itemsOpen = 'closed';
        }
        if (this.state[id] == undefined) {
            itemsOpen = 'open';
        }

        return (
          <li
            id={ id }
            className={ `${itemClassName } ${ itemsOpen}` }
            onClick={ (e) => {
                e.stopPropagation(); this.handleSelection(team);
            } }
          >
            { !isEmpty(nestedItems) && (
              <div
                className={ `LeftIconArrow ${ itemsOpen}` }
                onClick={ () => this.handleExpansion(id) }
              >
                <ChevronIcon direction={ TOP } />
                <ChevronIcon direction={ BOTTOM } />
              </div>
            ) }

            <i className="jstree-icon jstree-ocl"> </i>
                      <div className="jstree-anchor">
                        <FontAwesomeIcon icon={ faUser } size="1x" color="#858585" />
                        <FontAwesomeIcon icon={ faUsers } size="1x" color="#858585" />
                        <div className="name"> { firstname }  { ' ' } { lastname } { name } </div>
                        <div className="email"> { email }  { ' ' } </div>
                        <div className="type"> { __typename }  { ' ' } </div>
                        { !isEmpty(description) && (
                            <><FontAwesomeIcon icon={ faQuestionCircle } color="#bbbbbb" />
                            <div className="description"> { description }  { ' ' } </div>
                            </>
                        ) }
                        <div className="update-parent-tree" onClick={ () => this.updateSelectedToUpdateParent(team) }>
                          <FontAwesomeIcon icon={ faEdit } color="#bbbbbb" />
                          <div className="description_alt"> { __('Assign new parent') }  { ' ' } </div>
                        </div>
                        { (showOption) && (
                            this.renderAllowedParentsForItem(team)
                        ) }
                      </div>
            <ul className="jstree-children">
              { nestedItems }
            </ul>
          </li>
        );
    };

    handleSelection = (team) => {
        const { handleSelection } = this.props;
        handleSelection(team);
    };

    handlePopupAction = ({ action }) => {
        const { onActionPopupClick } = this.props;

        if (action === 'adduser') {
            return onActionPopupClick(true, ADD_USER_POPUP);
        }
        if (action === 'addteam') {
            return onActionPopupClick(true, ADD_TEAM_POPUP);
        }
        if (action === 'editselection') {
            return onActionPopupClick(true, EDIT_SELECTION_POPUP);
        }
        if (action === 'deleteselection') {
            return onActionPopupClick(true, DELETE_SELECTION_POPUP);
        }
    };

    // onActionPopupClick = () => {
    //     onActionPopupClick(true, ADD_USER_POPUP);
    // };

  closeAddUserModal=() => {
      const { openPopup, onClosePopupClick } = this.props;
      if (openPopup && onClosePopupClick) {
          onClosePopupClick(false);
      }
  };

  closeDeleteUserModal=() => {
      const { openPopup, onClosePopupClick } = this.props;
      if (openPopup && onClosePopupClick) {
          onClosePopupClick(false);
      }
  };

  performDeleteUser=() => {
      const { openPopup, handleDelete } = this.props;
      if (openPopup && handleDelete) {
          handleDelete();
      }
  };

  renderPopup = () => {
      const { popup_type, openPopup, selected_entity } = this.props;
      if (!openPopup) {
          return null;
      }

      if (popup_type === ADD_USER_POPUP) {
          return this.renderUserForm('');
      }
      if (popup_type === ADD_TEAM_POPUP) {
          return this.renderTeamForm('');
      }

      if (popup_type === EDIT_SELECTION_POPUP && !isEmpty(selected_entity)) {
          return this.renderEditSelection(selected_entity);
      }

      if (popup_type === DELETE_SELECTION_POPUP && !isEmpty(selected_entity)) {
          return this.renderDeleteSelection();
      }

      if (popup_type === EDIT_SELECTION_POPUP || popup_type === DELETE_SELECTION_POPUP) {
          return this.renderNoSelectionPopup();
      }
  };

  renderEditSelection = () => {
      const { selected_entity } = this.props;
      let __typename = '';
      if (!isEmpty(selected_entity) && selected_entity.__typename) {
          __typename = selected_entity.__typename;
      }
      if (__typename === 'Customer') {
          return this.renderUserForm(selected_entity);
      }
      if (__typename === 'CompanyTeam') {
          return this.renderTeamForm(selected_entity);
      }
  };

  renderDeleteSelection = () => {
      const { selected_entity } = this.props;
      let __typename = '';
      if (!isEmpty(selected_entity) && selected_entity.__typename) {
          __typename = selected_entity.__typename;
      }
      const { openPopup, handleTeam, team = {} } = this.props;
      return (
        <Popup
          id={ DELETE_SELECTION_POPUP }
          open={ openPopup }
          className="SeleteUserPopup"
          onClose={ this.closeDeleteUserModal }
        >
          <div className="cross_subs_popup">
            <div className="title_subs_popup">
              { __('DELETE THIS USER?') }
            </div>
            <button
              key="close"
              block="Header"
              elem="Button"
              mods={ { type: 'close', openPopup } }
              onClick={ this.closeDeleteUserModal }
              aria-label="Close"
            >
              <CloseIcon />
            </button>
          </div>
          <div className="DeleteMessage">
            { __('Select Delete to permanently delete the user account and content. User\'s orders and quotes are still visible for the merchant. Select Set as Inactive to temporarily lock the user. The user’s content is still available to parent users.') }
          </div>
          <button
            key="delete"
            type="delete"
            block="btn btn-primary"
            onClick={ this.performDeleteUser }
            mix={ { block: 'deleteUser', elem: 'Button' } }
          >
              { __('Delete') }
          </button>
          <button
            key="close"
            type="cancel"
            block="btn"
            onClick={ this.closeDeleteUserModal }
            mix={ { block: 'deletelUser', elem: 'Button' } }
          >
              { __('Cancel') }
          </button>
        </Popup>
      );
  };

  renderTeamForm = (selected_entity) => {
      // eslint-disable-next-line no-unused-vars
      let team = {};
      const { openPopup, handleUser } = this.props;
      let popupTitle = __('ADD TEAM');

      if (!isEmpty(selected_entity)) {
          team = selected_entity;
          popupTitle = __('EDIT TEAM');
      }

      return (
      <Popup
        id={ ADD_TEAM_POPUP }
        open={ openPopup }
        className="AddUserPopup"
        onClose={ this.closeAddUserModal }
        position="right center"
      >
        <div className="cross_subs_popup">
        <div className="title_subs_popup">
          { popupTitle }
        </div>
          <button
            key="close"
            block="Header"
            elem="Button"
            mods={ { type: 'close', openPopup } }
            onClick={ this.closeAddUserModal }
            aria-label="Close"
          >
            <CloseIcon />
          </button>
        </div>
        <AddTeamForm
          team={ team }
          onSave={ handleUser }
        />
        { ' ' }
        <button
          key="close"
          type="cancel"
          block="btn"
          onClick={ this.closeAddUserModal }
          mix={ { block: 'cancelUser', elem: 'Button' } }
        >
            { __('Cancel') }
        </button>
      </Popup>
      );
  };

  renderNoSelectionPopup = () => {
      const { openPopup } = this.props;

      return (
          <Popup
            id={ NO_SELECTION_POPUP }
            open={ openPopup }
            className="NoSelectionPopup"
            onClose={ this.closeAddUserModal }
            position="right center"
          >
            <div className="cross_subs_popup">
              <div className="title_subs_popup">
                { __('PLEASE SELECT USER OR TEAM') }
              </div>
              <button
                key="close"
                block="Header"
                elem="Button"
                mods={ { type: 'close', openPopup } }
                onClick={ this.closeAddUserModal }
                aria-label="Close"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="selectTeam">
              { __('Please select user or team first.') }
            </div>
            <button
              key="close"
              type="cancel"
              block="Button"
              onClick={ this.closeAddUserModal }
              mix={ { block: 'OK', elem: 'Button' } }
            >
                { __('OK') }
            </button>
          </Popup>
      );
  };

  renderUserForm = (selected_entity) => {
      let user = {};
      const {
          handleUser, isLoading, openPopup, companyStructure
      } = this.props;
      let popupTitle = __('ADD USER');

      if (!isEmpty(selected_entity)) {
          user = selected_entity;
          popupTitle = __('EDIT USER');
      }

      return (
        <Popup
          id={ ADD_USER_POPUP }
          open={ openPopup }
          className="AddUserPopup"
          onClose={ this.closeAddUserModal }
          position="right center"
        >
          <Loader isLoading={ isLoading } />

          <div className="cross_subs_popup">
            <div className="title_subs_popup">
              { popupTitle }
            </div>
            <button
              key="close"
              block="Header"
              elem="Button"
              mods={ { type: 'close', openPopup } }
              onClick={ this.closeAddUserModal }
              aria-label="Close"
            >
              <CloseIcon />
            </button>
          </div>
          <AddUserForm
            user={ user }
            roleList={ companyStructure.roles.items }
            onSave={ handleUser }
          />
          { ' ' }
          <button
            key="close"
            type="cancel"
            block="btn"
            onClick={ this.closeAddUserModal }
            mix={ { block: 'cancelUser', elem: 'Button' } }
          >
              { __('Cancel') }
          </button>
        </Popup>

      );
  };

  render() {
      const {
          companyStructure, isLoading, openPopup, popup_type
      } = this.props;

      let { isUpdating = false } = this.props;

      let noSubscription = false;
      if (!isEmpty(companyStructure) && !isLoading) {
          noSubscription = true;
      }
      if (isLoading || isUpdating) {
          isUpdating = true;
      }

      return (
        <div>
          <Loader isLoading={ isUpdating } />
          { (noSubscription) && (
            <div>
            { this.renderCompanyStructure() }
            { (openPopup) && (this.renderPopup()) }
            </div>
          ) }
        </div>
      );
  }
}

export default CompanyStructure;
