/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import SearchBarDispatcher from 'Store/SearchBar/SearchBar.dispatcher';

import SearchOverlay from './SearchOverlay.component';

/** @namespace Pwa/Component/SearchOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    searchResults: state.SearchBarReducer.productsInSearch,
    isLoading: state.SearchBarReducer.isLoading
});

/** @namespace Pwa/Component/SearchOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    makeSearchRequest: (options) => SearchBarDispatcher.handleData(dispatch, options),
    clearSearchResults: () => SearchBarDispatcher.clearSearchResults(dispatch)
});

/** @namespace Pwa/Component/SearchOverlay/Container/SearchOverlayContainer */
export class SearchOverlayContainer extends PureComponent {
    static propTypes = {
        makeSearchRequest: PropTypes.func.isRequired,
        clearSearchResults: PropTypes.func.isRequired,
        searchCriteria: PropTypes.string.isRequired
    };

    containerFunctions = {
        getProductLinkTo: this.getProductLinkTo.bind(this),
        makeSearchRequest: this.makeSearchRequest.bind(this)
    };

    getProductLinkTo(product) {
        const { url = '' } = product;
        if (!url) {
            return undefined;
        }
        const storeBaseName = window.storeConfig.baseName;
        const new_url_key = url.replace(storeBaseName, '/');

        if (!new_url_key) {
            return {};
        }

        return {
            pathname: new_url_key,
            state: { product }
        };
    }

    updateToGaDataQuickSearch(searchTerm) {
        window.dataLayer = window.dataLayer || [];

        // Url encode search term
        const searchTermQuery = encodeURIComponent(searchTerm);

        const modifiedLocation = {
            ...location,
            href: `${location.origin + window.storeConfig.baseName}search?q=${searchTermQuery}`
        };

        window.dataLayer.push({
            event: 'pageview',
            pageTitle: 'Search | Front Runner',
            url: modifiedLocation.href,
            eventCategory: 'search',
            eventAction: 'quickSearch',
            eventLabel: searchTerm,
            eventValue: null,
            page: {
                url: modifiedLocation,
                title: 'Search | Front Runner'
            }
        });
    }

    makeSearchRequest() {
        const { makeSearchRequest, clearSearchResults, searchCriteria } = this.props;
        if (searchCriteria) {
            clearSearchResults();
            this.updateToGaDataQuickSearch(searchCriteria);
            makeSearchRequest({ args: { search: searchCriteria, pageSize: 50 } });
        }
    }

    render() {
        return (
            <SearchOverlay
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchOverlayContainer);
