export const GET_SAVED_CARDS = 'GET_SAVED_CARDS';
export const SAVE_SELECTED_CARD = 'SAVE_SELECTED_CARD';
export const UPDATE_CARD_INTENT_SECRET = 'UPDATE_CARD_INTENT_SECRET';
export const UPDATE_GERO_PAY_INTENT_SECRET = 'UPDATE_GERO_PAY_INTENT_SECRET';
export const UPDATE_KLARNA_INTENT_SECRET = 'UPDATE_KLARNA_INTENT_SECRET';
export const UPDATE_SOFORT_INTENT_SECRET = 'UPDATE_SOFORT_INTENT_SECRET';
export const UPDATE_INTENT_LOADING = 'UPDATE_INTENT_LOADING';
export const CLEAR_INTENT_SECRET = 'CLEAR_INTENT_SECRET';

/** @namespace Pwa/Store/SavedCard/Action/updateSavedCards */
export const updateSavedCards = (data) => ({
    type: GET_SAVED_CARDS,
    data
});

/** @namespace Pwa/Store/SavedCard/Action/saveSelectedCardAction */
export const saveSelectedCardAction = (selectedCard) => ({
    type: SAVE_SELECTED_CARD,
    selectedCard
});

/** @namespace Pwa/Store/SavedCard/Action/updateCardSecretAction */
export const updateCardSecretAction = (intent_secret_card) => ({
    type: UPDATE_CARD_INTENT_SECRET,
    intent_secret_card
});

/** @namespace Pwa/Store/SavedCard/Action/updateGeroPaySecretAction */
export const updateGeroPaySecretAction = (intent_secret_gero_pay) => ({
    type: UPDATE_GERO_PAY_INTENT_SECRET,
    intent_secret_gero_pay
});

/** @namespace Pwa/Store/SavedCard/Action/updateKlarnaSecretAction */
export const updateKlarnaSecretAction = (intent_secret_klarna) => ({
    type: UPDATE_KLARNA_INTENT_SECRET,
    intent_secret_klarna
});

/** @namespace Pwa/Store/SavedCard/Action/updateSofortSecretAction */
export const updateSofortSecretAction = (intent_secret_sofort) => ({
    type: UPDATE_SOFORT_INTENT_SECRET,
    intent_secret_sofort
});

/** @namespace Pwa/Store/SavedCard/Action/setIntentLoading */
export const setIntentLoading = (loading) => ({
    type: UPDATE_INTENT_LOADING,
    loading
});

/** @namespace Pwa/Store/SavedCard/Action/clearIntentSecret */
export const clearIntentSecret = () => ({
    type: CLEAR_INTENT_SECRET,
    loading: false
});
