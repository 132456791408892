export const PLEASE_SELECT = { value: '', label: __('Select Year') };
export const PLEASE_SELECT_MAKE = { value: '', label: __('Select Make') };
export const PLEASE_SELECT_MODEL = { value: '', label: __('Select Model') };
export const PLEASE_SELECT_OPTION = { value: '', label: __('Select Option') };
export const PLEASE_SELECT_LIST = { value: '', label: __('Select') };

export const SELECT_DIFFERENT_VEHICLE = { value: 'hideList', label: __('Select Different Vehicle...') };

export const TYPE_PRODUCT = 'PRODUCT';
export const TYPE_CMS_PAGE = 'CMS_PAGE';
export const TYPE_CATEGORY = 'CATEGORY';
export const TYPE_NOTFOUND = 'NOT_FOUND';
