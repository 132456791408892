/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId } from 'Util/Cart';
import getStore from 'Util/Store';

export const AUTH_TOKEN = 'auth_token';
export const ADMIN_TOKEN_AS_CUSTOMER = 'admin_token_as_customer';
export const ONE_HOUR = 3600;
export const ONE_HOUR_IN_SECONDS = 3600;
export const TOKEN_REFRESH_DELAY = 3600;

/* @namespace Pwa/Util/Auth/Token/getKeyRespectedStore */
// export const getKeyRespectedStore = (dataKey) => dataKey + window.storeConfig.code;
export const getKeyRespectedStore = (dataKey) => {
    const _store = window.storeConfig.code.split('_');
    const _storeCodeList = [
        'de', 'be'
    ];

    if (_store.length > 1) {
        if (_storeCodeList.indexOf(_store[1]) !== -1) {
            const pathName = window.storeConfig.code;
            const allwoedStore = [
                'fr_be',
                'nl_be',
                'es_be',
                'en_de',
                'de_de'

            ];

            if (allwoedStore.indexOf(pathName) !== -1) {
                return `${dataKey }debe`;
            }
        }

        return dataKey + _store[1];
    }

    return dataKey;
};

/** @namespace Pwa/Util/Auth/Token/setAuthorizationToken */
export const setAuthorizationToken = (token) => {
    const state = getStore().getState();
    const {
        cookie_lifetime = ONE_HOUR
    } = state.ConfigReducer;

    BrowserDatabase.setItem(token, getKeyRespectedStore(AUTH_TOKEN), cookie_lifetime);
};

/** @namespace Pwa/Util/Auth/Token/setIsAdminLoggedInAsCustomer */
export const setIsAdminLoggedInAsCustomer = (flag) => BrowserDatabase.setValueForAdminLoggedInAsCustomer(flag, getKeyRespectedStore(ADMIN_TOKEN_AS_CUSTOMER));
/** @namespace Pwa/Util/Auth/Token/getIsAdminLoggedInAsCustomer */
export const getIsAdminLoggedInAsCustomer = () => {
    const value = BrowserDatabase.getvalueForAdminLoggedInAsCustomer(getKeyRespectedStore(ADMIN_TOKEN_AS_CUSTOMER));
    return value;
};

/** @namespace Pwa/Util/Auth/Token/deleteAuthorizationToken */
export const deleteAuthorizationToken = () => BrowserDatabase.deleteItem(getKeyRespectedStore(AUTH_TOKEN));

/** @namespace Pwa/Util/Auth/Token/getAuthorizationToken */
export const getAuthorizationToken = () => BrowserDatabase.getItem(getKeyRespectedStore(AUTH_TOKEN));

/** @namespace Pwa/Util/Auth/Token/refreshAuthorizationToken */
export const refreshAuthorizationToken = () => setAuthorizationToken(getAuthorizationToken());

/** @namespace Pwa/Util/Auth/Token/isInitiallySignedIn */
export const isInitiallySignedIn = () => !!getAuthorizationToken();

/** @namespace Pwa/Util/Auth/Token/isSignedIn */
export const isSignedIn = () => {
    const _isSignedIn = !!getAuthorizationToken();
    const store = getStore();
    const {
        MyAccountReducer: {
            isSignedIn: isCustomerSignedIn
        } = {}
    } = store.getState();
    const { dispatch } = store;

    if (!_isSignedIn && isCustomerSignedIn) {
        // since logout is async and slow, remove cart id
        // and set customer sign in status here on auth token expiration
        deleteGuestQuoteId();
        dispatch(updateCustomerSignInStatus(false));

        const MyAccountDispatcher = import('../../store/MyAccount/MyAccount.dispatcher');
        MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.logout(true, dispatch)
        );
    } else if (_isSignedIn && isCustomerSignedIn) {
        refreshAuthorizationToken();
    }

    return _isSignedIn;
};
