/* eslint-disable no-console */
/* eslint-disable max-len */
export const UPDATE_COMPANY_STRUCTURE_DATA = 'UPDATE_COMPANY_STRUCTURE_DATA';
export const SET_COMPANY_STRUCTURE_LOADING = 'SET_COMPANY_STRUCTURE_LOADING';
export const SAVE_COMPANY_STRUCTURE_DATA = 'SAVE_COMPANY_STRUCTURE_DATA';
export const SET_COMPANY_STRUCTURE_UPDATE_ACTION = 'SET_COMPANY_STRUCTURE_UPDATE_ACTION';

/** @namespace Pwa/Store/CompanyStructure/Action/updateCompanyStructureData */
export const updateCompanyStructureData = ({ companyStructure = {} }, isLoading = false) => ({
    type: UPDATE_COMPANY_STRUCTURE_DATA,
    companyStructure,
    isLoading
});

/** @namespace Pwa/Store/CompanyStructure/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_COMPANY_STRUCTURE_LOADING,
    isLoading
});

/** @namespace Pwa/Store/CompanyStructure/Action/setActionType */
export const setActionType = (openPopup, popup_type) => ({
    type: SET_COMPANY_STRUCTURE_UPDATE_ACTION,
    openPopup,
    popup_type
});

/** @namespace Pwa/Store/CompanyStructure/Action/saveCompanyStructureData */
export const saveCompanyStructureData = (data, isLoading) => ({
    type: SAVE_COMPANY_STRUCTURE_DATA,
    data,
    isLoading
});
