/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import {
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_SELECTED_ADDRESS,
    UPDATE_SHIPPING_FIELDS
} from './Checkout.action';

/** @namespace Pwa/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    shippingFields: {},
    email: '',
    isEmailAvailable: true,
    selectedShippingAddressId: 0,
    selectedBillingAddressId: 0
});

/** @namespace Pwa/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    // case UPDATE_SHIPPING_FIELDS:
    //     const { shippingFields } = action;

    //     return {
    //         ...state,
    //         shippingFields
    //     };

    case UPDATE_EMAIL:
        const { email } = action;

        return {
            ...state,
            email
        };

    case UPDATE_SELECTED_ADDRESS:
        const { checkoutAddress: { isBilling = false, selectedAddressId = 'newaddress' } } = action;
        if (isBilling) {
            return {
                ...state,
                selectedBillingAddressId: selectedAddressId
            };
        }

        return {
            ...state,
            selectedShippingAddressId: selectedAddressId
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;

        return {
            ...state,
            isEmailAvailable
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
