/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import FooterQuery from 'Query/Footer.query';
import HomePageQuery from 'Query/HomePage.query';
import { QueryDispatcher } from 'Util/Request';

import { updateFooter } from './Footer.action';
/**
 * CMS Page Dispatcher
 * @class CmsPageDispatcher
 * @extends QueryDispatcher
 * @namespace Pwa/Store/Footer/Dispatcher/FooterDispatcher
 */
export class FooterDispatcher extends QueryDispatcher {
    // onSuccess({ footer, newLetterPopupConfig }, dispatch) {
    onSuccess(data = {}, dispatch) {
        const { footer, newLetterPopupConfig } = data;
        if (typeof (footer) !== 'undefined') {
            dispatch(updateFooter({ data: footer, newLetterConfig: newLetterPopupConfig }, false));
        }
    }

    onError(_, dispatch) {
        // dispatch(updateNoMatch(true));
    }

    /**
     * Prepare CMS Page query
     * @param {{url_key: String, title: Int, content: String, content_heading: String, page_layout: String, meta_title: String, meta_description: String, meta_keywords, string}} options A object containing different aspects of query, each item can be omitted
     * @return {Query} CMS Page query
     * @memberof CmsPageDispatcher
     */
    prepareRequest(options, dispatch) {
        dispatch(updateFooter({}, true));
        return [
            FooterQuery.getQuery(options),
            HomePageQuery.getNewsletterPopup(options)

        ];
    }
}

export default new FooterDispatcher();
