/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { get, isEmpty } from 'lodash';

import PartsFinderQuery from 'Query/PartsFinder.query';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import {
    updateVehicleSpecificSkus
} from 'Store/VehicleFinder/VehicleFinder.action';
import { QueryDispatcher } from 'Util/Request';

/** @namespace Pwa/Store/PartsFinder/ProductHomepageSlider/Dispatcher/ProductHomepageSliderDispatcher */
export class ProductHomepageSliderDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess(response, dispatch) {
        const vehicle = get(response, 'vehicle', []);
        const skus = vehicle?.products?.map(({ sku }) => sku).join(',');
        if (!isEmpty(skus)) {
            dispatch(updateVehicleSpecificSkus(skus));
        }
    }

    onError(_, dispatch) {
        dispatch(updateNoMatch(true));
    }

    prepareRequest(options, dispatch) {
        const { optionId } = options;
        return PartsFinderQuery.getProductsFromOptionId(optionId);
    }
}

export default new ProductHomepageSliderDispatcher();
