/* eslint-disable no-use-before-define */
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/Helper/getSocialIcons */
export const getSocialIcons = (social) => new Field(social)
    .addField(getIcons('icons'))
    .addFieldList([
        'label'
    ]);

/** @namespace Pwa/Query/Helper/getLinks */
export const getLinks = (linkKey) => new Field(linkKey)
    .addFieldList([
        'label',
        'link'
    ]);

/** @namespace Pwa/Query/Helper/getUrls */
export const getUrls = (urlKey) => new Field(urlKey)
    .addFieldList([
        'label',
        'url'
    ]);

/** @namespace Pwa/Query/Helper/getIcons */
export const getIcons = (iconKey) => new Field(iconKey)
    .addFieldList([
        'title',
        'icon_class',
        'link',
        'icon_name',
        'prefix'
    ]);

/** @namespace Pwa/Query/Helper/getCountry */
export const getCountry = (key) => new Field(key)
    .addFieldList([
        'name',
        'country_code',
        'region',
        'store_id',
        'store_url',
        'sort_order',
        'pinned'
    ]);
