/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/** @namespace Pwa/Query/Config/Query/ConfigQuery */
export class ConfigQuery {
    getStoreListField() {
        return new Field('storeList')
            .addFieldList(this._getStoreListFields());
    }

    getCheckoutAgreements() {
        return new Field('checkoutAgreements')
            .addFieldList(this._getCheckoutAgreementFields());
    }

    getCurrencyField() {
        return new Field('available_currencies_data')
            .addFieldList([
                'id',
                'label',
                'value'
            ]);
    }

    getCurrencyData() {
        return new Field('currencyData')
            .addFieldList([
                this.getCurrencyField(),
                'current_currency_code'
            ]);
    }

    getPriceDisplayTypeField() {
        return new Field('priceTaxDisplay')
            .addFieldList([
                'product_price_display_type',
                'shipping_price_display_type'
            ]);
    }

    getSaveSelectedCurrencyMutation(newCurrency) {
        return new Field('saveSelectedCurrency')
            .addArgument('currency', 'String', newCurrency)
            .addFieldList([
                this.getCurrencyData()
            ]);
    }

    _getCheckoutAgreementFields() {
        return [
            'agreement_id',
            'checkbox_text',
            'content',
            'content_height',
            'is_html',
            'mode',
            'name'
        ];
    }

    _getStoreListFields() {
        return [
            'name',
            'is_active',
            'base_url',
            'base_link_url',
            'code'
        ];
    }

    // getPayOneHostedRequest() {
    //     return new Field('payone_hosted_request')
    //         .addFieldList([
    //             'request',
    //             'responsetype',
    //             'mode',
    //             'mid',
    //             'aid',
    //             'portalid',
    //             'encoding',
    //             'storecarddata',
    //             'hash'
    //         ]);
    // }

    getNotificationFields() {
        return new Field('header_notifications')
            .addFieldList([
                'enable',
                'text',
                'buttonText',
                'ctaButtonText',
                'ctaButtonLink',
                'dateFrom',
                'dateTo'
            ]);
    }

    // getQuery() {
    //     return new Field('storeConfig')
    //         .addArgument('store_code', 'String', window.storeConfig.code)
    //         .addField(this.getPayOneHostedRequest())
    //         .addField(this.getNotificationFields())
    //         .addFieldList(this._getStoreConfigFields());
    // }

    getQuery() {
        return new Field('storeConfig')
            // .addField(this.getPayOneHostedRequest())
            .addField(this.getNotificationFields())
            .addFieldList(this._getStoreConfigFields());
    }

    _getBreadPayConfig() {
        return new Field('breadpay_key_config_data')
            .addFieldList(['src',
                'dataApiKey']);
    }

    _getStoreConfigFields() {
        return [
            'code',
            'is_active',
            'cms_home_page',
            'cms_no_route',
            'copyright',
            'website_name',
            'timezone',
            'header_logo_src',
            'timezone',
            'title_prefix',
            'title_suffix',
            'default_display_currency_code',
            'default_keywords',
            'default_title',
            'default_description',
            'default_country',
            'secure_base_media_url',
            // 'paypal_sandbox_flag',
            // 'paypal_client_id',
            'logo_alt',
            'logo_height',
            'logo_width',
            'cookie_text',
            'cookie_link',
            'terms_are_enabled',
            'address_lines_quantity',
            'base_url',
            'locale',
            'pagination_frame',
            'pagination_frame_skip',
            'anchor_text_for_previous',
            'anchor_text_for_next',
            'reviews_are_enabled',
            'reviews_allow_guest',
            'wishlist_general_active',
            'demo_notice',
            'guest_checkout',
            'is_email_confirmation_required',
            'base_link_url',
            'show_vat_number_on_storefront',
            'show_tax_vat_number',
            'cookie_lifetime',
            'plp_list_mode',
            'layered_navigation_product_count_enabled',
            'region_display_all',
            'redirect_dashboard',
            'homepage_main_slider_fsync_category_id',
            'homepage_small_slider_fsync_category_id',
            'flag',
            'free_shipping_header_text',
            'free_shipping_header_text_b2b',
            'free_shipping_product_text',
            'free_shipping_product_subtext',
            'free_shipping_footer_text',
            'free_shipping_footer_text_b2b',
            'free_shipping_footer_subtext',
            'apr_upto_months',
            'is_free_shipping_enable',
            'authnetcim_client_key',
            'authnetcim_app_login_id',
            'paradoxlabs_stripe_public_key',
            'payone_creditcard_types',
            'payone_creditcard_mode',
            this.getPriceDisplayTypeField(),
            'productSliderCategoryId',
            'sliderCategoryId',
            'reCaptchaKey',
            'secure_base_url',
            'frontend_store_url',
            'facebookPixelCode',
            'gtmCode',
            'isDisableZaCheckout',
            'currencySymbol',
            'base_currency_code',
            'googleAnalyticsAppId',
            'id',
            'stripe_mode',
            'stripe_live_pk',
            'stripe_test_pk',
            'fsync_url',
            'paypal_sandbox_flag',
            'paypal_client_id',
            'gdpr_cookie_bar',
            'is_google_pay_enabled',
            'is_apple_pay_enabled',
            this._getBreadPayConfig()
        ];
    }
}

export default new ConfigQuery();
