/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/** @namespace Pwa/Query/InstallationGuide/Query/InstallationGuideQuery */
export class InstallationGuideQuery {
    getInstallationGuide(sku) {
        if (!sku) {
            throw new Error('Missing argument `sku`');
        }
        const graphql = `query={
            fittingInstructions(sku:"${sku}") {
                code
                id
                filename
                filesize
                thumbUrl
                previewUrl
                downloadUrl
                daysAgo
            }
        }`;

        return graphql;
    }
}

export default new InstallationGuideQuery();
