/* eslint-disable no-console */
export const UPDATE_CUSTOMER_GARAGE_LIST = 'GET_CUSTOMER_GARAGE_LIST';
export const ADD_CUSTOMER_GARAGE_ITEM = 'ADD_CUSTOMER_GARAGE_ITEM';
export const REMOVE_CUSTOMER_GARAGE_ITEM = 'REMOVE_CUSTOMER_GARAGE_ITEM';
export const SET_GARAGE_LOADING = 'SET_GARAGE_LOADING';

/** @namespace Pwa/Store/CustomerGarage/Action/updateGarageList */
export const updateGarageList = ({ data }, isLoading) => ({
    type: UPDATE_CUSTOMER_GARAGE_LIST,
    data,
    isLoading
});

/** @namespace Pwa/Store/CustomerGarage/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_GARAGE_LOADING,
    isLoading
});

/** @namespace Pwa/Store/CustomerGarage/Action/removeItemFromGarageList */
export const removeItemFromGarageList = (id, isLoading) => ({
    type: REMOVE_CUSTOMER_GARAGE_ITEM,
    data: id,
    isLoading
});

/** @namespace Pwa/Store/CustomerGarage/Action/addItemInGarageList */
export const addItemInGarageList = (data, isLoading) => ({
    type: ADD_CUSTOMER_GARAGE_ITEM,
    data,
    isLoading
});
