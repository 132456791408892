/* eslint-disable react/sort-comp */
/* eslint-disable no-magic-numbers */
/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { PureComponent } from 'react';
import {
    withGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';

import Field from 'Component/Field';
import RecaptchaQuery from 'Query/Recaptcha.query';
import { updateGoogleRecaptcha } from 'Store/GoogleRecaptcha/GoogleRecaptcha.action';
import { fetchQuery, getErrorMessage } from 'Util/Request';

import { GOOGLE_SECRET_KEY } from './GoogleRecaptcha.config';

/** @namespace Pwa/Component/GoogleRecaptchaV3/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    googleRecaptchaToken: state.GoogleRecaptchaReducer.recaptchaToken
});

/** @namespace Pwa/Component/GoogleRecaptchaV3/Component/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateGoogleRecaptcha: (recaptchaToken) => dispatch(updateGoogleRecaptcha(recaptchaToken))
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/GoogleRecaptchaV3/Component/GoogleRecaptchaV3Component */
export class GoogleRecaptchaV3Component extends PureComponent {
    __construct(props) {
        super.__construct(props);
    }

    componentDidMount() {
        setTimeout(() => {
            this.handleVerifyRecaptcha();
        }, 2500);

        this.timer = setInterval(
            this.handleVerifyRecaptcha.bind(this),
            600000,
        );
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    async verifyRecaptchaBackend(token) {
        await fetchQuery(RecaptchaQuery.recapchavar(
            GOOGLE_SECRET_KEY,
            token
        )).then(
            /** @namespace Pwa/Component/GoogleRecaptchaV3/Component/fetchQuery/then */
            (response) => {
                this.props.updateGoogleRecaptcha(response.recapchavar.success);
            },
            /** @namespace Pwa/Component/GoogleRecaptchaV3/Component/fetchQuery/then */
            (error) => {
                console.log('err', error);
                this.props.updateGoogleRecaptcha(false);
            }
        );
    }

    handleVerifyRecaptcha = async () => {
        const { executeRecaptcha } = (this.props)
            .googleReCaptchaProps;

        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('homepage').then(
            /** @namespace Pwa/Component/GoogleRecaptchaV3/Component/executeRecaptcha/then */
            (res) => {
                this.verifyRecaptchaBackend(res);
            },
            /** @namespace Pwa/Component/GoogleRecaptchaV3/Component/executeRecaptcha/then */
            (err) => {
                console.log(err);
            }
        );
    };

    render() {
        return (
            <>
            </>
        );
    }
}

export const WithGoogleRecaptcha = withGoogleReCaptcha(GoogleRecaptchaV3Component);

export default connect(mapStateToProps, mapDispatchToProps)(WithGoogleRecaptcha);
