/* eslint-disable max-len */
/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable fp/no-let */
/* eslint-disable fp/no-let */

import axios from 'axios';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import Cookies from 'universal-cookie';

import history from 'Util/History';
import { convertQueryStringToKeyValuePairs } from 'Util/Url';

export const cookies = new Cookies();

/**
 * Process any affiliate codes
 */
export const processAffiliateCode = () => {
    const urlParams = convertQueryStringToKeyValuePairs(window.location.search) || {};
    const { affiliate_code = '', code = '' } = urlParams || {};

    if (!isEmpty(affiliate_code)) {
        const now = new Date();
        now.setTime(now.getTime() + 60 * 24 * 3600 * 1000);

        const cookieData = {
            path: '/',
            expires: now
        };

        // Set cookie to root domain so that backend can access the cookie
        if (process.env.NODE_ENV === 'production') {
            cookieData.domain = 'frontrunneroutfitters.com';
        }
        cookies.set('current_affiliate_account_code', affiliate_code, cookieData);

        // Get affiliate account from local storage
        // const affiliateAccountLS = localStorage.getItem('affiliate_account', '');
        // const affiliateAccount = JSON.parse(affiliateAccountLS);

        // Set new affiliate account
        const newAffiliateAccount = JSON.stringify({
            affiliate_code,
            expiry_date: DateTime.now().setZone('UTC').plus({ months: 3 }).toUnixInteger()
        });

        // Save affiliate account
        localStorage.setItem('affiliate_account', newAffiliateAccount);

        // Fsync Url
        let fsyncUrl = 'https://mcfsync-staging.frontrunneroutfitters.com/';
        if (process.env.NODE_ENV === 'production') {
            fsyncUrl = 'https://fsync.frontrunneroutfitters.com/';
        }

        // Post data to Fsync using axios
        axios.post(`${fsyncUrl}api/v2/magento/affiliates/link`, {
            affiliate_code,
            referrer: document.referrer
        }).then(() => {
            // console.log('Affiliate account updated');
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            const url = new URL(window.location.href);
            const searchParams = new URLSearchParams(url.search);

            searchParams.delete('affiliate_code');
            searchParams.delete('referring_service');

            url.search = searchParams.toString();
            const newUrl = url.toString();
            const parts = newUrl.split('/');
            const newPushUrl = parts.slice(5).join('/');

            history.push(`/${newPushUrl}`);
        });
    }

    return false;
};
