/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// eslint-disable-next-line no-unused-vars
import { CardElement, PaymentElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import { Address, PaymentTotals, Stripe } from '../../type/Stripe';

import './CheckoutPaymentButton.style';

/**
 * @class InjectedStripeCheckoutForm
 * @namespace StripePayments/Component/CheckoutPaymentButton/CheckoutPaymentButton/Component/CheckoutPaymentButtonComponent */
export class CheckoutPaymentButtonComponent extends PureComponent {
    static propTypes = {
        paymentTotals: PaymentTotals.isRequired,
        billingAddress: Address.isRequired,
        stripe: Stripe.isRequired,
        showNotification: PropTypes.func.isRequired,
        onPaymentMethod: PropTypes.func.isRequired,
        onPaymentReady: PropTypes.func.isRequired
    };

    readyToClick = (event) => {
        const { onPaymentReady } = this.props;

        if (event.complete) {
            onPaymentReady(true);
        } else {
            onPaymentReady(false);
        }
    };

    render() {
        const { pr } = this.props;
        // const {
        //     paymentTotals,
        //     billingAddress,
        //     stripe,
        //     showNotification,
        //     onPaymentMethod
        // } = this.props;
        return (

            <div block="InjectedStripeCheckoutForm">
                { pr && <PaymentRequestButtonElement options={ { paymentRequest: pr } } /> }
                { ' ' }
            </div>
        );
    }
}

export default CheckoutPaymentButtonComponent;
