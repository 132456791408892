/* eslint-disable @scandipwa/scandipwa-guidelines/always-both-mappings */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import ProductQA, { QA_POPUP_ID } from './ProductQA.component';

/** @namespace Pwa/Component/ProductQA/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(QA_POPUP_ID, payload))
});

/** @namespace Pwa/Component/ProductQA/Container/ProductQAContainer */
export class ProductQAContainer extends PureComponent {
    static propTypes = {
        showPopup: PropTypes.func.isRequired
    };

    containerFunctions = {
        showPopup: this._showPopup.bind(this)
    };

    _showPopup() {
        const { showPopup } = this.props;
        showPopup({ title: __('Ask a question') });
    }

    render() {
        return (
            <ProductQA
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(null, mapDispatchToProps)(ProductQAContainer);
