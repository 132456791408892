/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import { CookiesReducer } from '../store/Cookies/Cookies.reducer';

/** @namespace GdprCookie/Plugin/ConfigQuery/renderContextProviders */
export const getStaticReducers = (args, callback, instance) => ({
    CookiesReducer,
    ...callback.apply(instance, args)
});

export default {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};
