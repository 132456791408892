/* eslint-disable radix */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable fp/no-let */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import {
    isEmpty
} from 'lodash';
import { Component, PureComponent } from 'react';
import { connect } from 'react-redux';

import Loader from 'Component/Loader';
import { showNotification } from 'Store/Notification/Notification.action';

import ReturnsForm from './ReturnsForm.component';
import {
    MAX_COUNT, MAX_FILE_SIZE, RETURN_FORM_GRAPHQL_URL_LIVE, RETURN_FORM_GRAPHQL_URL_STAGING
} from './ReturnsForm.config';

/** @namespace Pwa/Component/ReturnsForm/Container/ReturnsFormContainer */
export class ReturnsFormContainer extends PureComponent {
    containerFunctions = {
        setFormState: this.setFormState.bind(this),
        renderSubmitButton: this.renderSubmitButton.bind(this),
        handleFileChange: this.handleFileChange.bind(this),
        mutationRequest: this.mutationRequest.bind(this),
        onCreateAccountAttempt: this.onCreateAccountAttempt.bind(this),
        onCreateAccountSuccess: this.onCreateAccountSuccess.bind(this),
        onError: this._onError.bind(this),
        fetchProductsFromInvoice: this.fetchProductsFromInvoice.bind(this),
        prevStep: this.prevStep.bind(this),
        returnFromSecond: this.returnFromSecond.bind(this),
        returnFromThird: this.returnFromThird.bind(this),
        proceedNext: this.proceedNext.bind(this),
        setProductSelection: this.setProductSelection.bind(this),
        onProductSelect: this.onProductSelect.bind(this),
        checkSelectedItems: this.checkSelectedItems.bind(this),
        setProductDropdownSelection: this.setProductDropdownSelection.bind(this),
        setPreferredOutcome: this.setPreferredOutcome.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            step: 1,
            fullName: '',
            orderNumber: '',
            contactNumber: '',
            contactEmail: '',
            returnComments: '',
            productUnder30Days: '',
            usedCondition: '',
            frontrunnerPackaging: '',
            hardwareIncluded: '',
            productImage: null,
            productImages: [],
            uploadedProductImages: [],
            fileLimit: false,
            loading: false,
            totalFizeSize: 0,
            preferredOutcome: '',
            reasonForReturn: '',
            formErrorFirst: false,
            formErrorSecond: false,
            formErrorThird: false,
            uploadInvoice: [],
            invoiceLinkedProducts: {},
            item: {
                name: '',
                sku: '',
                qtyReturn: '',
                reason: '',
                id: '',
                isProductNew: '',
                isInOriginalPackaging: '',
                isIncludeAllComponents: ''
            },
            selectedItems: []
        };
    }

    componentDidMount() {
        const { customer, invoiceNumber = '' } = this.props;

        const { firstname, lastname, email } = customer;
        this.setState({ selectedItems: [] });
        if (customer && !isEmpty(customer)) {
            const contactNumber = customer.addresses[0]?.telephone;
            this.setState({ fullName: `${firstname} ${lastname}`, contactEmail: email, contactNumber });
        }
        if (invoiceNumber && invoiceNumber !== '') {
            this.setState({ orderNumber: invoiceNumber });
        }
    }

    // Proceed to next step
    nextStep() {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    }

    // Go back to prev step
    prevStep() {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    }

    returnFromSecond() {
        const { step } = this.state;
        this.setState({
            step: step - 1,
            selectedItems: []
        });
    }

    returnFromThird() {
        const { step } = this.state;
        this.setState({
            step: step - 1,
            selectedItems: []
        });
    }

    proceedNext(e) {
        e.preventDefault();
        this.nextStep();
    }

    setProductSelection(product) {
        const {
            sku,
            qty
        } = product;

        const min = 0;
        const max = qty;

        const { selectedItems } = this.state;

        const regexPattern = new RegExp('true');

        const newState = selectedItems.map((obj) => {
            const productStateName = event.target.name.split('-')[1];
            //  if sku matches, update object
            if (obj.sku === sku && (productStateName === 'isProductNew' || productStateName === 'isInOriginalPackaging' || productStateName === 'isIncludeAllComponents')) {
                return (
                    { ...obj, [productStateName]: regexPattern.test(event.target.value) });
            }

            if (obj.sku === sku && productStateName === 'qtyReturn') {
                const value = Math.max(min, Math.min(max, Number(event.target.value)));

                return (
                    { ...obj, [productStateName]: value });
            }

            //  otherwise return the object as is
            return obj;
        });

        this.setState({ selectedItems: newState });
    }

    setProductDropdownSelection(product, selectedOption) {
        const {
            sku
        } = product;

        const { selectedItems } = this.state;

        const newState = selectedItems.map((obj) => {
            if (obj.sku === sku) {
                return (
                    { ...obj, reason: selectedOption.value });
            }

            //  otherwise return the object as is
            return obj;
        });

        this.setState({ selectedItems: newState });
    }

    onProductSelect(product) {
        const {
            id, qty, sku, name
        } = product;

        const { selectedItems } = this.state;

        if (event.target.checked === false) {
            return (
                this.setState({
                    selectedItems: selectedItems.filter((product) => product.sku !== sku)
                }));
        }

        this.setState((prevState) => ({
            selectedItems: [...prevState.selectedItems, {
                name,
                id: `'${id}'`,
                sku,
                qtyReturn: qty,
                isProductNew: '',
                isInOriginalPackaging: '',
                isIncludeAllComponents: '',
                reason: ''
            }]
        }));
    }

    checkSelectedItems() {
        const { step, selectedItems } = this.state;
        const { showNotification } = this.props;
        const itemvalidation = selectedItems.every((item) => item.isProductNew !== '' && item.isInOriginalPackaging !== '' && item.isIncludeAllComponents !== '' && item.reason !== '');
        const qtyvalidation = selectedItems.every((item) => item.qtyReturn !== 0);
        if (selectedItems.length === 0) {
            return (showNotification(
                'info',
                __('Please select one of the items to return.')
            )
            );
        }
        if (itemvalidation === false) {
            this.setState({ formErrorSecond: true });
            return (showNotification(
                'info',
                __('Please select all fields for selected return items.')
            )
            );
        }
        if (qtyvalidation === false) {
            this.setState({ formErrorSecond: true });
            return (showNotification(
                'info',
                __("Item return quantity can't be 0.")
            )
            );
        }
        this.setState({
            step: step + 1
        });
    }

    _onSelect(args) {
        let totalSize = 0;
        for (let i = 0; i < args.length; i++) {
            const fileSize = args[i].size;
            totalSize += fileSize;
        }
        const size = Math.round((totalSize / 1024));
        return (
            size
        );
    }

    handleUploadFiles(files) {
        const uploaded = Array.from(this.state.uploadedProductImages);
        if (files && files.length > 0) {
            const fileSizeCheck = this._onSelect(files);
            this.setState({ totalFizeSize: fileSizeCheck });
        }

        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === MAX_COUNT) {
                    this.setState({ fileLimit: true });
                }
                if (uploaded.length > MAX_COUNT) {
                    showNotification(
                        'error',
                        __('You can only add a maximum of %s files', MAX_COUNT)
                    );
                    this.setState({ fileLimit: false });
                    limitExceeded = true;
                    return true;
                }
            }
        });
        if (!limitExceeded) {
            this.setState({ uploadedProductImages: uploaded });
        }
    }

    handleFileChange(e) {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        this.handleUploadFiles(chosenFiles);
    }

    setFormState() {
        this.setState({ [event.target.name]: event.target.value });
    }

    setPreferredOutcome(selectedOption) {
        this.setState({ preferredOutcome: selectedOption.value });
    }

    // _objectToString(itemArray) {
    //     return (
    //         itemArray.map(
    //             (obj) => Object.fromEntries(
    //                 Object.entries(obj)
    //                     .map(([key, val]) => [key, String(val)])
    //             )
    //         )
    //     );
    // }

    _replaceSpecialCharsAndNewlines(text) {
        return text.replace(/[^\w\s]/g, ' ').replace(/\n/g, '<br>');
    }

    async mutationRequest() {
        const { showNotification } = this.props;
        const {
            fullName,
            orderNumber,
            contactNumber,
            contactEmail,
            returnComments,
            productUnder30Days,
            usedCondition,
            frontrunnerPackaging,
            hardwareIncluded,
            uploadedProductImages,
            productImage,
            uploadInvoice,
            reasonForReturn,
            preferredOutcome,
            selectedItems
        } = this.state;

        const comment = this._replaceSpecialCharsAndNewlines(this.state.returnComments);

        if (preferredOutcome === '') {
            this.setState({ formErrorThird: true });
            return showNotification(
                'info',
                __('Please select all required fields.')
            );
        }

        this.setState({ loading: true });

        const selectedItemsJson = JSON.stringify(selectedItems);
        const operations = {
            query: 'mutation CreateReturnMerchandiseAuthorization($files: [Upload], $fullName: String!, $comment: String!, $orderNo: String!, $contactNo: String!, $contactEmail: String!, $items: [ReturnMerchandiseAuthorizationProductItem], $preferredOutcome: String!) { CreateReturnMerchandiseAuthorization(fullName: $fullName, orderNo: $orderNo, contactNo: $contactNo, comment: $comment, contactEmail: $contactEmail, items: $items, uploads: $files, preferredOutcome: $preferredOutcome) { id full_name } }'
        };

        const map = {
            files: ['variables.files.*']
        };

        const variables = {
            files: [],
            fullName,
            comment,
            orderNo: orderNumber,
            contactNo: contactNumber,
            contactEmail,
            items: selectedItems,
            preferredOutcome
        };

        const formdata = new FormData();
        formdata.append('operations', JSON.stringify(operations));
        formdata.append('map', JSON.stringify(map));
        formdata.append('variables', JSON.stringify(variables));

        for (let i = 0; i < uploadedProductImages.length; i++) {
            formdata.append('files', uploadedProductImages[i], uploadedProductImages[i].name);
        }

        const { storeId } = window.storeConfig;

        const myHeaders = new Headers();
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let fsyncUrl = RETURN_FORM_GRAPHQL_URL_STAGING;

        if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
            fsyncUrl = RETURN_FORM_GRAPHQL_URL_LIVE;
        }

        const result = await fetch(fsyncUrl, requestOptions);
        const response = await result.json();

        return response;
    }

    async fetchProductsFromInvoice() {
        const { showNotification } = this.props;
        const {
            fullName,
            orderNumber,
            contactNumber,
            contactEmail,
            invoiceLinkedProducts
        } = this.state;

        if (fullName === '' || orderNumber === '' || contactEmail === '') {
            this.setState({ formErrorFirst: true });
            return (showNotification(
                'info',
                __('Please select all required fields.')
            )
            );
        }

        this.setState({ loading: true });

        const { storeId } = window.storeConfig;

        const myHeaders = new Headers();

        myHeaders.append('Content-Type', 'application/json');

        const formData = JSON.stringify({
            query: `query { ReturnMerchandiseAuthorizationQuery(email: "${contactEmail}", orderNo: "${orderNumber}" ) { sku id name brand qty image } }`
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        let fsyncUrl = RETURN_FORM_GRAPHQL_URL_STAGING;

        if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
            fsyncUrl = RETURN_FORM_GRAPHQL_URL_LIVE;
        }

        const result = await fetch(fsyncUrl, requestOptions).then(
        /** @namespace Pwa/Component/ReturnsForm/Container/fetch/then */
            (response) => response.json()
        )
            .then(
            /** @namespace Pwa/Component/ReturnsForm/Container/fetch/then/then */
                (result) => {
                    if (result.data.ReturnMerchandiseAuthorizationQuery === null && result.errors && result.errors.length > 0) {
                        const { debugMessage, extensions } = result.errors[0];
                        const { message } = result.errors[0];

                        if (debugMessage) {
                            return (
                                showNotification(
                                    'info',
                                    __(debugMessage)
                                ));
                        }

                        const { validation = {} } = extensions;
                        const keysArray = Object.values(validation);

                        if (keysArray[0]) {
                            return (
                                showNotification(
                                    'info',
                                    __(keysArray[0][0])
                                ));
                        }

                        return (
                            showNotification(
                                'info',
                                __(message)
                            ));
                    }
                    this.setState({ invoiceLinkedProducts: result.data }, () => {
                        this.nextStep();
                    });
                }
            )
            .catch(
            /** @namespace Pwa/Component/ReturnsForm/Container/fetch/then/then/catch */
                (error) => (showNotification(
                    'error',
                    __('Please check your email and/or order/invoice number and try again.')
                ))
            );

        this.setState({ loading: false });

        return result;
    }

    async onCreateAccountSuccess(fields) {
        const { showNotification, isVisible } = this.props;
        const { uploadedProductImages, preferredOutcome, totalFizeSize } = this.state;

        if (preferredOutcome === '') {
            this.setState({ formErrorThird: true });
            return (showNotification(
                'info',
                __('Please select all required fields.')
            )
            );
        }

        if (totalFizeSize && totalFizeSize > MAX_FILE_SIZE) {
            return (showNotification(
                'info',
                __('File size has exceeded it max limit of 8MB. Please upload files less than 8MB')
            )
            );
        }

        try {
            const code = await this.mutationRequest();
            if (code && code.errors && code.errors.length > 0) {
                const { debugMessage, extensions } = code.errors[0];
                const { message } = code.errors[0];

                if (debugMessage) {
                    return (
                        showNotification(
                            'error',
                            __(debugMessage)
                        ));
                }

                const { validation = {} } = extensions;
                const keysArray = Object.values(validation);

                if (keysArray[0]) {
                    return (
                        showNotification(
                            'error',
                            __(keysArray[0][0])
                        ));
                }

                return (
                    showNotification(
                        'error',
                        __(message)
                    ));
            }
            showNotification(
                'success',
                __('Return Form submitted successfully.')
            );
            this.setState({ selectedItems: [], formErrorThird: false }, () => this.nextStep());
        } catch (err) {
            return (
                showNotification(
                    'error',
                    __('Please refresh the page try again after sometime.')
                )
            );
        } finally {
            this.setState({
                loading: false, formErrorFirst: false, formErrorSecond: false
            });
        }
    }

    onCreateAccountAttempt(_, invalidFields) {
        const { showNotification } = this.props;
        if (invalidFields) {
            showNotification('info', __('Incorrect data! Please start again and resolve all field validation errors.'));
            this.setState({ showValidationError: '' });
        }
    }

    _onError() {
        this.setState({ loading: false, showValidationError: '' });
    }

    renderSubmitButton() {
        return (
            <button
              block="Contact"
              elem="Button"
              mix={ { block: 'Button' } }
            >
            { __('Return Request') }
            </button>
        );
    }

    render() {
        return (
                <ReturnsForm
                  { ...this.props }
                  { ...this.state }
                  { ...this.containerFunctions }
                />
        );
    }
}

/** @namespace Pwa/Component/ReturnsForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    googleRecaptchaToken: state.GoogleRecaptchaReducer.recaptchaToken,
    customer: state.MyAccountReducer.customer
});

/** @namespace Pwa/Component/ReturnsForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReturnsFormContainer);
