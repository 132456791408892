/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Link from 'Component/Link';
import ProductList from 'Component/ProductList';
import { updateLoadStatus } from 'Store/ProductList/ProductList.action';
import { LayoutType } from 'Type/Layout';
import { FilterInputType } from 'Type/ProductList';

import './CategoryProductList.style';

export const ProductListDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductList/ProductList.dispatcher'
);

/** @namespace Pwa/Component/CategoryProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    pages: state.ProductListReducer.pages,
    isOffline: state.OfflineReducer.isOffline,
    isLoading: state.ProductListReducer.isLoading,
    isPageLoading: state.ProductListReducer.isPageLoading,
    totalItems: state.ProductListReducer.totalItems,
    totalPages: state.ProductListReducer.totalPages,
    isDataLoading: state.VehicleFinderReducer.isProductLoading

});

/** @namespace Pwa/Component/CategoryProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestProductList: (options) => ProductListDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
    ),
    updateLoadStatus: (isLoading) => dispatch(updateLoadStatus(isLoading))
});

/** @namespace Pwa/Component/CategoryProductList/Container/CategoryProductListContainer */
export class CategoryProductListContainer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isDataLoading: PropTypes.bool,
        isMatchingListFilter: PropTypes.bool,
        isMatchingInfoFilter: PropTypes.bool,
        layout: PropTypes.string,
        filter: FilterInputType,
        requestProductList: PropTypes.func.isRequired,
        isCurrentCategoryLoaded: PropTypes.bool,
        isVehiclesPage: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isMatchingListFilter: false,
        isMatchingInfoFilter: false,
        isCurrentCategoryLoaded: false,
        filter: {},
        layout: 'grid'
    };

    containerFunctions = {
        requestProductList: this.requestProductList.bind(this)
    };

    getIsLoading() {
        const {
            filter,
            isLoading,
            isMatchingListFilter,
            isCurrentCategoryLoaded,
            isVehiclesPage,
            isDataLoading
        } = this.props;

        if (isVehiclesPage && isDataLoading) {
            return true;
        }

        if (isVehiclesPage && !isLoading) {
            return false;
        }

        /**
         * In case the wrong category was passed down to the product list,
         * show the loading animation, it will soon change to proper category.
         */
        if (filter.categoryIds === -1) {
            return true;
        }

        /**
         * Do not request page, if category is not yet loaded
         * without this command the products are requested twice:
         * 1. Once with global default sorting
         * 2. Once with category default sortingZ
         */
        if (!isCurrentCategoryLoaded) {
            return true;
        }

        if (!navigator.onLine) {
            return false;
        }

        // if the filter expected matches the last requested filter
        if (isMatchingListFilter) {
            return false;
        }

        return isLoading;
    }

    getIsPreventRequest() {
        const { isMatchingListFilter, isMatchingInfoFilter, isVehiclesPage = false } = this.props;
        // if(isVehiclesPage){
        //     return true
        // }

        return isMatchingListFilter && isMatchingInfoFilter; // if filter match - prevent request
    }

    getLayout() {
        const { layout } = this.props;

        return layout;
    }

    renderContactProducts() {
        const { baseName = '/en/us/' } = window.storeConfig;
        return (
                <div
                  block="ProductList"
                  elem="ProductContact"
                >
                    <a className="product-item-link" href={ `${baseName}contact/` } target="_blank" data-item-page="1">
                        { __('No Products for your vehicle listed here? Please contact us.') }
                        { ' ' }
                    </a>
                    <div className="product-item-contact">
                        <a className="product-item-link btn btn-primary" href={ `${baseName}contact/` } target="_blank" data-item-page="1">
                            { __('Contact Us') }
                            { ' ' }
                        </a>
                    </div>
                </div>
        );
    }

    requestProductList(options) {
        const { requestProductList, isVehiclesPage = false } = this.props;
        if (!isVehiclesPage) {
            requestProductList({ ...options, isVehiclesPage, isPlp: true });
        }
    }

    containerProps = () => ({
        isLoading: this.getIsLoading(),
        isPreventRequest: this.getIsPreventRequest(),
        mix: { block: 'CategoryProductList', mods: { layout: this.getLayout() } }
    });

    renderNullResults = () => {
        const { isLoading, isVehiclesPage = false, isDataLoading } = this.props;
        if (!isLoading && isVehiclesPage && !isDataLoading) {
            // eslint-disable-next-line react/prop-types
            const { totalItems, pageSize = 1 } = this.props;
            const totalPages = Math.ceil(totalItems / pageSize);
            if (!isLoading && totalPages === 0) {
                return this.renderContactProducts();
            }
        }

        return true;
    };

    render() {
        this.renderNullResults();

        return (
            <ProductList
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductListContainer);
