/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * Ogg Postal Code Lookup Query
 * @class PostalCodeLookupQuery
 * @namespace Pwa/Query/PostalCodeLookup/Query/PostalCodeLookupQuery
 */
export class PostalCodeLookupQuery {
    /**
     * get city based on postal code query
     * @return {Query}  query
     * @memberof PostalCodeLookupQuery
     */
    getQuery({ postcode, country }) {
        return new Field('fsyncLocation')
            .addArgument('postcode', 'String!', postcode)
            .addArgument('country', 'String!', country)
            .addFieldList(this._getDataList());
    }

    _getDataList() {
        return [
            this._getSuggestionField()
        ];
    }

    _getSuggestionField() {
        return new Field('suggestion')
            .addFieldList([
                'city'
            ]);
    }
}

export default new PostalCodeLookupQuery();
