/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// eslint-disable-next-line no-unused-vars
import { CardElement, PaymentElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import history from 'Util/History';

import { Address, PaymentTotals, Stripe } from '../../type/Stripe';

import './InjectedStripeCheckoutForm.style';

/**
 * @class InjectedStripeCheckoutForm
 * @namespace StripePayments/Component/InjectedStripeCheckoutForm/Component/InjectedStripeCheckoutFormComponent */
export class InjectedStripeCheckoutFormComponent extends PureComponent {
    static propTypes = {
        paymentTotals: PaymentTotals.isRequired,
        billingAddress: Address.isRequired,
        stripe: Stripe.isRequired,
        showNotification: PropTypes.func.isRequired,
        onPaymentMethod: PropTypes.func.isRequired,
        onPaymentReady: PropTypes.func.isRequired
    };

    readyToClick = (event) => {
        const { onPaymentReady } = this.props;

        if (event.complete) {
            onPaymentReady(true);
        } else {
            onPaymentReady(false);
        }
    };

    render() {
        const { pr } = this.props;
        // const {
        //     paymentTotals,
        //     billingAddress,
        //     stripe,
        //     showNotification,
        //     onPaymentMethod
        // } = this.props;
        return (
            <div block="InjectedStripeCheckoutForm">
                <form id="payment-form">

                    { /* <StripeOneClickPayment
                    paymentTotals={ paymentTotals }
                    billingAddress={ billingAddress }
                    stripe={ stripe }
                    showNotification={ showNotification }
                    onPaymentMethod={ onPaymentMethod }
                    /> */ }
                    { /* <CardElement options={ { hidePostalCode: true } } /> */ }
                    <PaymentElement
                      onChange={ this.readyToClick }
                    />
                </form>
                { /* { pr && <PaymentRequestButtonElement options={ { paymentRequest: pr } } /> }
                { ' ' }
                { pr && (
                    <button
                      className="btn btn-primary"
                      onClick={ () => {
                          pr.show();
                      } }
                    >
                        Proceed
                    </button>
                ) } */ }
            </div>
        );
    }
}

export default InjectedStripeCheckoutFormComponent;
