/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Checks whether every option is in attributes
 * @param {{string} text
 * @returns {string}
 * @namespace Pwa/Util/StringOprations/StringOperations/capitalizeFirstLetter */
export const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1);
