/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_CUSTOMER_SIGN_IN_STATUS = 'UPDATE_CUSTOMER_SIGN_IN_STATUS';
export const UPDATE_CUSTOMER_DETAILS = 'UPDATE_CUSTOMER_DETAILS';
export const UPDATE_CUSTOMER_ADDRESSES = 'UPDATE_CUSTOMER_ADDRESSES';
export const UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL = 'UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL';
export const UPDATE_CUSTOMER_PASSWORD_RESET_STATUS = 'UPDATE_CUSTOMER_PASSWORD_RESET_STATUS';
export const UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS = 'UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS';
export const UPDATE_CUSTOMER_IS_LOADING = 'UPDATE_CUSTOMER_IS_LOADING';
export const UPDATE_CUSTOMER_IS_LOCKED = 'UPDATE_CUSTOMER_IS_LOCKED';
// gift card actions
export const GET_GIFT_CARD_DETAILS = 'GET_GIFT_CARD_DETAILS';
export const ADD_GIFT_CARD = 'ADD_GIFT_CARD';
export const REMOVE_GIFT_CARD = 'REMOVE_GIFT_CARD';

/** @namespace Pwa/Store/MyAccount/Action/updateCustomerSignInStatus */
export const updateCustomerSignInStatus = (status) => ({
    type: UPDATE_CUSTOMER_SIGN_IN_STATUS,
    status
});

/** @namespace Pwa/Store/MyAccount/Action/updateCustomerDetails */
export const updateCustomerDetails = (customer) => ({
    type: UPDATE_CUSTOMER_DETAILS,
    customer
});

/** @namespace Pwa/Store/MyAccount/Action/updateCustomerAddress */
export const updateCustomerAddress = (customeraddress) => ({
    type: UPDATE_CUSTOMER_ADDRESSES,
    customeraddress
});

/** @namespace Pwa/Store/MyAccount/Action/updateCustomerPasswordResetStatus */
export const updateCustomerPasswordResetStatus = (status, message) => ({
    type: UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    status,
    message
});

/** @namespace Pwa/Store/MyAccount/Action/updateCustomerPasswordForgotStatus */
export const updateCustomerPasswordForgotStatus = () => ({
    type: UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS
});

/** @namespace Pwa/Store/MyAccount/Action/updateIsLoading */
export const updateIsLoading = (isLoading) => ({
    type: UPDATE_CUSTOMER_IS_LOADING,
    isLoading
});
/** @namespace Pwa/Store/MyAccount/Action/getGiftCardDetails */
export const getGiftCardDetails = (getassignAmGiftCard = {}) => (
    {
        type: GET_GIFT_CARD_DETAILS,
        getassignAmGiftCard
    }
);
/** @namespace Pwa/Store/MyAccount/Action/getAddGiftCard */
export const getAddGiftCard = (getassignAmGiftCard = {}) => (
    {
        type: ADD_GIFT_CARD,
        getassignAmGiftCard
    }
);
/** @namespace Pwa/Store/MyAccount/Action/getRemoveGiftCard */
export const getRemoveGiftCard = (getassignAmGiftCard = {}) => (
    {
        type: REMOVE_GIFT_CARD,
        getassignAmGiftCard
    }
);
/** @namespace Pwa/Store/MyAccount/Action/updateCustomerPasswordForgotEmail */
export const updateCustomerPasswordForgotEmail = (email) => ({
    type: UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL,
    email
});
/** @namespace Pwa/Store/MyAccount/Action/updateIsLocked */
export const updateIsLocked = (isLocked) => ({
    type: UPDATE_CUSTOMER_IS_LOCKED,
    isLocked
});
