/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export * from './GearzettePage.action';
export { default as GearzettePageReducer } from './GearzettePage.reducer';
export { default as PostsDispatcher } from './Posts.dispatcher';
export { default as PostsForCategoryDispatcher } from './PostsForCategory.dispatcher';
export { default as RecentsPostDispatcher } from './RecentsPost.dispatcher';
export { default as PostDetailsDispatcher } from './PostDetails.dispatcher';
export { default as GearzetteCategoryDispatcher } from './GearzetteCategory.dispatcher';
export { default as PostsForSearchDispatcher } from './PostsForSearch.dispatcher';
export { default as GearzettePostDetailResolverDispatcher } from './GearzettePostDetailResolver.dispatcher';
