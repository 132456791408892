/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_FOOTER = 'UPDATE_FOOTER';

/**
 * Update HOME Page information
 * @param {String} urlKey URL Key of the page that must be returned
 * @namespace Pwa/Store/Footer/Action/updateFooter
 */
export const updateFooter = ({ data, newLetterConfig }, isLoading) => ({
    type: UPDATE_FOOTER,
    data,
    newLetterConfig,
    isLoading
});
