import {
    UPDATE_CURRENT_CATEGORY as SourceUPDATE_CURRENT_CATEGORY,
    updateCurrentCategory as SourceUpdateCurrentCategory
} from 'SourceStore/Category/Category.action';

// TODO: implement UPDATE_CURRENT_CATEGORY
export const UPDATE_CURRENT_CATEGORY = SourceUPDATE_CURRENT_CATEGORY;

// TODO: implement updateCurrentCategory
export const updateCurrentCategory = SourceUpdateCurrentCategory;
