/* eslint-disable no-console */
/* eslint-disable max-len */
export const UPDATE_COMPANY_ROLES_DATA = 'UPDATE_COMPANY_ROLES_DATA';
export const UPDATE_COMPANY_ROLES_EDITOR_DATA = 'UPDATE_COMPANY_ROLES_EDITOR_DATA';

export const SET_COMPANY_ROLES_LOADING = 'SET_COMPANY_ROLES_LOADING';
export const SAVE_COMPANY_ROLES_DATA = 'SAVE_COMPANY_ROLES_DATA';
export const EDIT_COMPANY_ROLES = 'EDIT_COMPANY_ROLES';
export const SET_COMPANY_ROLES_EDIT = 'SET_COMPANY_ROLES_EDIT';
export const ADD_NEW_COMPANY_ROLES = 'ADD_NEW_COMPANY_ROLES';
/** @namespace Pwa/Store/CompanyRoles/Action/updateCompanyRolesData */
export const updateCompanyRolesData = ({ companyRoles = {} }, isLoading = false) => ({
    type: UPDATE_COMPANY_ROLES_DATA,
    companyRoles,
    isLoading
});

/** @namespace Pwa/Store/CompanyRoles/Action/updateCompanyRolesEditorData */
export const updateCompanyRolesEditorData = ({ editorData = {} }, isLoading = false) => ({
    type: UPDATE_COMPANY_ROLES_EDITOR_DATA,
    editorData,
    isLoading
});

/** @namespace Pwa/Store/CompanyRoles/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_COMPANY_ROLES_LOADING,
    isLoading
});

/** @namespace Pwa/Store/CompanyRoles/Action/setOpenEdit */
export const setOpenEdit = (isOpenEdit) => ({
    type: EDIT_COMPANY_ROLES,
    isOpenEdit
});

/** @namespace Pwa/Store/CompanyRoles/Action/setAddNewRole */
export const setAddNewRole = (isAddRole) => ({
    type: ADD_NEW_COMPANY_ROLES,
    isAddRole
});

/** @namespace Pwa/Store/CompanyRoles/Action/saveCompanyRolesData */
export const saveCompanyRolesData = (data, isLoading) => ({
    type: SAVE_COMPANY_ROLES_DATA,
    data,
    isLoading
});
