import {
    CUSTOMER_ACCOUNT_OVERLAY_KEY as SourceCUSTOMER_ACCOUNT_OVERLAY_KEY,
    STATE_CONFIRM_EMAIL as SourceSTATE_CONFIRM_EMAIL,
    STATE_CREATE_ACCOUNT as SourceSTATE_CREATE_ACCOUNT,
    STATE_FORGOT_PASSWORD as SourceSTATE_FORGOT_PASSWORD,
    STATE_FORGOT_PASSWORD_SUCCESS as SourceSTATE_FORGOT_PASSWORD_SUCCESS,
    STATE_LOGGED_IN as SourceSTATE_LOGGED_IN,
    STATE_SIGN_IN as SourceSTATE_SIGN_IN
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.config';

// TODO: implement STATE_SIGN_IN
export const STATE_SIGN_IN = SourceSTATE_SIGN_IN;

// TODO: implement STATE_FORGOT_PASSWORD
export const STATE_FORGOT_PASSWORD = SourceSTATE_FORGOT_PASSWORD;

// TODO: implement STATE_FORGOT_PASSWORD_SUCCESS
export const STATE_FORGOT_PASSWORD_SUCCESS = SourceSTATE_FORGOT_PASSWORD_SUCCESS;

// TODO: implement STATE_CREATE_ACCOUNT
export const STATE_CREATE_ACCOUNT = SourceSTATE_CREATE_ACCOUNT;

// TODO: implement STATE_LOGGED_IN
export const STATE_LOGGED_IN = SourceSTATE_LOGGED_IN;

// TODO: implement STATE_CONFIRM_EMAIL
export const STATE_CONFIRM_EMAIL = SourceSTATE_CONFIRM_EMAIL;

// TODO: implement CUSTOMER_ACCOUNT_OVERLAY_KEY
export const CUSTOMER_ACCOUNT_OVERLAY_KEY = SourceCUSTOMER_ACCOUNT_OVERLAY_KEY;
