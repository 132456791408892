// export * from 'SourceRoute/MyAccount/MyAccount.config';
export const MY_ACCOUNT_URL = '/my-account';
export const ACCOUNT_LOGIN_URL = '/account/login';
export const ACCOUNT_URL = '/my-account';

export const ACCOUNT_INFORMATION_EDIT_URL = '/customer/account/edit';
export const ACCOUNT_FORGOT_PASSWORD_URL = '/customer/account/forgotpassword';
export const ACCOUNT_CONFIRMATION_URL = '/customer/account/confirmation';
export const ACCOUNT_REGISTRATION_URL = '/customer/account/create';
export const ACCOUNT_ORDER_URL = '/sales/order/view/order_id';
export const ACCOUNT_ORDER_PRINT_URL = '/sales/order/print/order_id';
export const ACCOUNT_ORDER_HISTORY = '/sales/order/history';
// eslint-disable-next-line max-len
export const LOCKED_ACCOUNT_ERROR_MESSAGE = __('The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.');
