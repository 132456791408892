/* eslint-disable fp/no-let */
/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

import { CardElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Loader from 'Component/Loader';
import CheckoutQuery from 'Query/Checkout.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

import {
    Address,
    PaymentTotals,
    Stripe,
    StripeElements
} from '../../type/Stripe';
import InjectedStripeCheckoutForm from './InjectedStripeCheckoutForm.component';

/** @namespace StripePayments/Component/InjectedStripeCheckoutForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace StripePayments/Component/InjectedStripeCheckoutForm/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace StripePayments/Component/InjectedStripeCheckoutForm/Container/InjectedStripeCheckoutFormContainer */
export class InjectedStripeCheckoutFormContainer extends PureComponent {
    static propTypes = {
        stripe: Stripe.isRequired,
        email: PropTypes.string,
        billingAddress: Address.isRequired,
        showNotification: PropTypes.func.isRequired,
        onRef: PropTypes.func.isRequired,
        paymentTotals: PaymentTotals.isRequired,
        onPaymentMethod: PropTypes.func.isRequired,
        selectedPaymentCode: PropTypes.string,
        elements: StripeElements.isRequired
    };

    static defaultProps = {
        email: null
    };

    componentDidMount() {
        const { onRef } = this.props;
        onRef(this);
    }

    componentWillUnmount() {
        const { onRef } = this.props;
        onRef(undefined);
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            complete: false,
            pr: null
        };
        // this.submit = this.submit.bind(this);
        this.submit = this.handleBillingSubmit.bind(this);
        this.isReadyToProceedOrder = this.handleReadyToProceedOrder.bind(this);
        // this.handleAuthorization = this.handleAuthorization.bind(this);
    }

    containerProps() {
        const {
            paymentTotals,
            billingAddress,
            stripe,
            showNotification,
            onPaymentMethod
        } = this.props;

        return {
            paymentTotals,
            billingAddress,
            stripe,
            showNotification,
            onPaymentMethod
        };
    }

    /**
    * Handles the response from a card action or a card payment after authorization is complete
    * @param response the API response
    * @param savePaymentInformation
    * @param paymentInformation
    * @returns {boolean} true on success, false otherwise
    */
    handlePostAuthorization(response, savePaymentInformation, paymentInformation) {
        const { showNotification } = this.props;

        if (response.error) {
            showNotification('error', response.error.message);
            return false;
        }

        savePaymentInformation(paymentInformation);
        return true;
    }

    onPaymentReady =(isReadyToPlaceOrder) => {
        this.setState({ isReadyToPlaceOrder });
    };

    _updateRestoreQuoteId() {
        let args = getGuestQuoteId();
        if (!args) {
            args = '0';
        }
        // const isCustomerSignedIn = isSignedIn();

        // if (!isCustomerSignedIn) {
        //     return null;
        // }

        fetchMutation(CheckoutQuery.getRestoreQuoteMutation(
            getGuestQuoteId()
        )).then(
            /** @namespace StripePayments/Component/InjectedStripeCheckoutForm/Container/fetchMutation/then */
            (data) => {
                // console.log('data', data);
            },
            this._handleError
        );
    }

    _activateCart() {
        const guest_quote_id = getGuestQuoteId();

        fetchMutation(CheckoutQuery.activateCart(
            guest_quote_id
        )).then(
            /** @namespace StripePayments/Component/InjectedStripeCheckoutForm/Container/fetchMutation/then */
            (data) => {
                // console.log('data', data);
            },
            this._handleError
        );
    }

    /**
    * If card required 3ds authorization - handle it and place order if success
    * @param paymentInformation
    * @param secret
    * @param savePaymentInformation
    */
    handleAuthorization(paymentInformation, secret, savePaymentInformation) {
        const {
            stripe: { retrievePaymentIntent, handleCardAction, handleCardPayment }
        } = this.props;

        return retrievePaymentIntent(secret).then(
            /** @namespace StripePayments/Component/InjectedStripeCheckoutForm/Container/retrievePaymentIntent/then */
            (result) => {
                const { paymentIntent: { status, confirmation_method } } = result;

                if (['requires_action', 'requires_source_action'].includes(status)) {
                    if (confirmation_method === 'manual') {
                        return handleCardAction(secret).then(
                            /** @namespace StripePayments/Component/InjectedStripeCheckoutForm/Container/handleCardAction/then */
                            (response) => this.handlePostAuthorization(
                                response,
                                savePaymentInformation,
                                paymentInformation
                            )
                        );
                    }

                    return handleCardPayment(secret).then(
                        /** @namespace StripePayments/Component/InjectedStripeCheckoutForm/Container/handleCardPayment/then */
                        (response) => this.handlePostAuthorization(
                            response,
                            savePaymentInformation,
                            paymentInformation
                        )
                    );
                }

                return null;
            }
        );
    }

    handleReadyToProceedOrder(e) {
        const { isReadyToPlaceOrder = false } = this.state;
        if (!isReadyToPlaceOrder) {
            const {
                showNotification
            } = this.props;

            showNotification('error', __('Please enter correct payment details.'));
        }

        return isReadyToPlaceOrder;
    }

    async handleBillingSubmit(e) {
        if (e) {
            e.preventDefault();
        }

        const {
            elements, stripe, showNotification, onPaymentMethod, selectedPaymentCode, intent_client_secret
        } = this.props;

        const { isReadyToPlaceOrder = false } = this.state;

        if (!isReadyToPlaceOrder) {
            showNotification('error', __('Please enter correct payment details.'));
            return;
        }
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        this.setState({ loading: true });
        localStorage.setItem('isProgressCheckout', '1');
        const { loading = false } = this.state || {};

        const storeUrl = window.storeConfig.baseName.endsWith('/') ? window.storeConfig.baseName : `${window.storeConfig.baseName}/`;
        const returnUrl = `${window.location.origin + storeUrl }stripepay/success`;
        setTimeout(async () => {
            const billingdata = JSON.parse(localStorage.getItem('billingAddress', '{}'));

            const {
                billing_address = { },
                email
            } = billingdata;
            const {
                address = { }
            } = billing_address;
            const {
                firstname,
                lastname,
                telephone: phone,
                city,
                country_id,
                street,
                region,
                postcode,
                country_code,
                region_id
            } = address;

            const billingName = `${ firstname } ${ lastname }`;
            const payment_method_data = {
                billing_details: {
                    name: billingName,
                    email,
                    phone,
                    address: {
                        city,
                        country: country_code,
                        line1: street ? street[0] : region_id,
                        state: region_id || '',
                        postal_code: postcode
                    }
                }
            };

            const { paymentTotals: { base_grand_total, quote_currency_code }, intent_client_secret } = this.props;

            const { code } = window.storeConfig;
            const countryCode = code.split('_')[1];
            // const paymentRequest = stripe.paymentRequest({
            //     country: 'US',
            //     currency: 'usd',
            //     total: {
            //         label: 'Demo total',
            //         amount: parseInt(base_grand_total)
            //     },
            //     requestPayerName: true,
            //     requestPayerEmail: true
            // });

            // // dummy for now
            // paymentRequest.canMakePayment().then(
            //     /** @namespace StripePayments/Component/InjectedStripeCheckoutForm/Container/canMakePayment/then */
            //     (rsult) => {
            //         if (rsult) {
            //             this.setState({ pr: paymentRequest });
            //             // Mount paymentRequestButtonElement to the DOM
            //         }
            //     },
            // );

            // if (elements) {
            //     return;
            // }
            // paymentRequest.on('paymentmethod', (ev) => {
            //     // Confirm the PaymentIntent without handling potential next actions (yet).
            //     stripe.confirmCardPayment(
            //         intent_client_secret,
            //         { payment_method: ev.paymentMethod.id },
            //         { handleActions: false }
            //     ).then(
            //         /** @namespace StripePayments/Component/InjectedStripeCheckoutForm/Container/confirmCardPayment/then */
            //         (confirmResult) => {
            //             if (confirmResult.error) {
            //                 // Report to the browser that the payment failed, prompting it to
            //                 // re-show the payment interface, or show an error message and close
            //                 // the payment interface.
            //                 ev.complete('fail');
            //             } else {

            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            // ev.complete('success');
            // // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // // handle the flow. If using an API version older than "2019-02-11"
            // // instead check for: `paymentIntent.status === "requires_source_action"`.
            // if (confirmResult.paymentIntent.status === 'requires_action') {

            //     // Let Stripe.js handle the rest of the payment flow.
            //     stripe.confirmCardPayment(intent_client_secret).then(
            //         /** @namespace StripePayments/Component/InjectedStripeCheckoutForm/Container/confirmCardPayment/then */
            //         (result) => {
            //             if (result.error) {

            //                 // The payment failed -- ask your customer for a new payment method.
            //             } else {

            // The payment has succeeded.
            //                             }
            //                         }
            //                     );
            //                 } else {

            //                     // The payment has succeeded.
            //                 }
            //             }
            //         }
            //     );
            // });

            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                // Make sure to change this to your payment completion page
                    return_url: returnUrl,
                    payment_method_data
                }
            });

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            localStorage.setItem('isProgressCheckout', '0');

            if (error.type === 'card_error' || error.type === 'validation_error') {
                // setMessage(error.message);
                this._activateCart();
                if (error?.code === 'card_declined') {
                    this._updateRestoreQuoteId();
                }
                showNotification('error', error.message);
            } else {
                // setMessage();
                showNotification('error', __('An unexpected error occured.'));
            }

            this.setState({ loading: false });
        }, 500);
        // } catch (error) {
    }

    showPaymentReq() {
        paymentRequest.show();
    }

    /**
    * Submit order information and create token
    * @returns {Promise<{handleAuthorization: InjectedStripeCheckoutForm.handleAuthorization, token: string}|{handleAuthorization: null, token: null}>}
    */

    /**
    * Submit order information and create tokenhttps://qr.ae/pvu9Vb
    * @returns {Promise<{handleAuthorization: InjectedStripeCheckoutForm.handleAuthorization, token: string}|{handleAuthorization: null, token: null}>}
    */
    async submit22() {
        const {
            stripe: { createPaymentMethod },
            billingAddress: {
                firstname,
                lastname,
                telephone: phone,
                city,
                country_id: country,
                street,
                region: state
            },
            email,
            elements
        } = this.props;

        const billingName = `${ firstname } ${ lastname }`;
        const cardElement = elements.getElement(CardElement);

        const { paymentMethod } = await createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: billingName,
                email,
                phone,
                address: {
                    city,
                    country,
                    line1: street[0],
                    state
                }
            }
        });

        if (!paymentMethod) {
            return { token: null, handleAuthorization: null };
        }

        return {
            token: `${paymentMethod.id}:${paymentMethod.card.brand}:${paymentMethod.card.last4}`,
            handleAuthorization: this.handleAuthorization
        };
    }

    render() {
        const { loading = false } = this.state;
        return (
            <>
                <InjectedStripeCheckoutForm
                  { ...this.containerProps() }
                  { ...this.state }
                  { ...this.props }
                  onPaymentReady={ this.onPaymentReady }
                />
                   { (loading) && (
                    <div className="fullScreenloader">
                        { ' ' }
                        <Loader isLoading={ loading } />
                        { ' ' }
                    </div>
                   ) }
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InjectedStripeCheckoutFormContainer);
