/* eslint-disable no-undef */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * MyAccount Mutations
 * @class MyAccount
 * @namespace Pwa/Query/MyAccount/Query/MyAccountQuery */
export class MyAccountQuery {
    /**
     * Get ResetPassword mutation
     * @param {{token: String, password: String, password_confirmation: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Field}
     * @memberof MyAccount
     */
    getResetPasswordMutation(options) {
        const {
            token, password, password_confirmation, id
        } = options;

        return new Field('s_resetPassword')
            .addArgument('token', 'String!', token)
            .addArgument('password', 'String!', password)
            .addArgument('password_confirmation', 'String!', password_confirmation)
            .addArgument('customer_id', 'String!', id)
            .addField('status');
    }

    // /**
    //  * Get SignIn mutation
    //  * @param {{email: String, password: String}} options A object containing different aspects of query, each item can be omitted
    //  * @return {Field}
    //  * @memberof MyAccount
    //  */
    // getSignInMutation(options) {
    //     const { email, password } = options;
    //     const guestQuoteId = getGuestQuoteId();

    //     return new Field('generateCustomerToken')
    //         .addArgument('email', 'String!', email)
    //         .addArgument('password', 'String!', password)
    //         .addArgument('guest_quote_id', 'String', guestQuoteId)
    //         .addField('token');
    // }

    /**
     * Get SignIn mutation
     * @param {{email: String, password: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Field}
     * @memberof MyAccount
     */
    getSignInMutation(options) {
        const { email, password } = options;

        return new Field('generateCustomerToken')
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addField('token');
    }

    getSignInWithTokenMutation(options) {
        const { token } = options;
        return new Field('Getcustomertokenbytoken')
            .addArgument('token', 'String!', token)
            .addField('token');
    }

    getUpdateInformationMutation(options) {
        return new Field('updateCustomer')
            .addArgument('input', 'CustomerInput!', options)
            .addField(this._getCustomerField());
    }

    getChangeCustomerPasswordMutation(options) {
        const { currentPassword, newPassword } = options;

        return new Field('changeCustomerPassword')
            .addArgument('currentPassword', 'String!', currentPassword)
            .addArgument('newPassword', 'String!', newPassword)
            .addField('id')
            .addField('email');
    }

    getCreateAddressMutation(options) {
        return new Field('createCustomerAddress')
            .addArgument('input', 'CustomerAddressInput!', options)
            .addFieldList(this._getAddressFields());
    }

    getDeleteAddressMutation(id) {
        return new Field('deleteCustomerAddress')
            .addArgument('id', 'Int!', id);
    }

    getUpdateAddressMutation(id, options) {
        return new Field('updateCustomerAddress')
            .addArgument('id', 'Int!', id)
            .addArgument('input', 'CustomerAddressInput!', options)
            .addFieldList(this._getAddressFields());
    }

    getCreateAccountMutation(options) {
        const { customer, password } = options;

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', { ...customer, password })
            .addField(this._getCustomerField());
    }

    getConfirmAccountMutation(options) {
        const { key, email, password } = options;

        return new Field('confirmCustomerEmail')
            .addArgument('key', 'String!', key)
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addFieldList(this._getConfirmAccountFields());
    }

    getCustomerQuery() {
        return this._getCustomerField();
    }

    _getConfirmAccountFields() {
        return [
            'status',
            'token',
            this._getCustomerField()
        ];
    }

    _getCustomerField() {
        return new Field('customer')
            .addFieldList(this._getCustomerFields());
    }

    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'is_b2b',
            'yotpo_status',
            'points_balance',
            this._getAddressesField()
        ];
    }

    _getAddressesField() {
        return new Field('addresses')
            .addFieldList(this._getAddressFields());
    }

    _getRegionField() {
        return new Field('region')
            .addFieldList(this._getRegionFields());
    }

    _getRegionFields() {
        return [
            'region_code',
            'region',
            'region_id'
        ];
    }

    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'company',
            'vat_id',
            this._getRegionField()
        ];
    }

    getCheckVatnumber(vatnumber) {
        return new Field('CheckVatnumber')
            .addArgument('vatnumber', 'String!', vatnumber)
            .addFieldList(['status', 'message']);
    }

    /**
     * Get ForgotPassword mutation
     * @param {{email: String}} options
     * @returns {Field}
     * @memberof MyAccount
     */
    getForgotPasswordMutation(options) {
        const { email } = options;

        return new Field('forgotPassword')
            .addArgument('email', 'String!', email)
            .addField('status');
    }

    getAcceptCompanyInviteMutation(options) {
        const {
            code,
            role_id,
            job_title,
            telephone,
            status,
            customer_id,
            company_id
        } = options;

        const input = {
            code,
            role_id,
            job_title,
            telephone,
            status,
            customer_id,
            company_id
        };

        return new Field('companyacceptinvitation')
            .addArgument('input', 'CompanyUserInput!', input)
            .addField('message');
    }

    getSearchAddressByValue(options) {
        const { search_key } = options;
        return new Field('searchaddressbyvalue')
            .addArgument('search_key', 'String!', search_key)
            .addField(this._getCustomeraddress());
    }

    _getCustomeraddress() {
        return new Field('customeraddress')
            .addFieldList(this._getAddressFields());
    }

    // gift card
    getassignAmGiftCard = () => new Field('getassignAmGiftCard').addField(
        this._giftCard()
    );

        _giftCard=() => new Field('giftcard').addFieldList([
            'status',
            'code',
            'id',
            'expiration_date',
            this._currentBalance()
        ]);

        _currentBalance = () => new Field('current_balance').addFieldList([
            'value',
            'currency'
        ]);

        // n add card mutation
        getAddCardMutation(options) {
            const { am_gift_card_code } = options;
            const input = { am_gift_card_code };
            return new Field('addassignAmGiftCard')
                .addArgument('input', 'AmGiftCardAccountInput!', input)
                .addField(
                    this._giftCard()
                );
        }

        // remove gift card
        removecardMutation(options) {
            const { account_id } = options;
            const newLocal = 'removeassignAmGiftCard';
            return new Field(newLocal).addArgument('account_id', 'String!', account_id).addFieldList([
                this._giftCard()
            ]);
        }

        // updateYotpo substotalPagescription
        updateCustomerOnYopto(...args) {
            const [isChecked, emailId] = args;
            const input = {
                email: emailId,
                yotpo_opted_in: isChecked
            };

            return new Field('updateCustomerOnYopto')
                .addArgument('input', 'yoptoDetailsInput', input)
                .addField('success');
        }

        _getAddressFieldPagination() {
            return new Field('addresses')
                .addFieldList(this._getAddressFields());
        }

        getCustomerAddresses(pageSize, currentPage) {
            return new Field('customeraddress')
                .addArgument('pageSize', 'Int!', pageSize)
                .addArgument('currentPage', 'Int!', currentPage)
                .addField('totalPages')
                .addField('totaladdressesCount')
                .addField(this._getAddressFieldPagination());
        }
}

export default new MyAccountQuery();
