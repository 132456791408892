/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/forbid-prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import MenuItem from './MenuItem.component';

/** @namespace Pwa/Component/DesktopMenuItem/MenuItem/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Pwa/Component/DesktopMenuItem/MenuItem/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace Pwa/Component/DesktopMenuItem/MenuItem/Container/MenuItemContainer */
export class MenuItemContainer extends PureComponent {
    static propTypes = {
        onCategoryHover: PropTypes.func,
        onCategoryClick: PropTypes.func,
        item: PropTypes.object.isRequired
    };

    static defaultProps = {
        onCategoryHover: () => {},
        onCategoryClick: () => {}
    };

    containerFunctions = {
        handleCategoryHover: this.handleCategoryHover.bind(this),
        handleCategoryClick: this.handleCategoryClick.bind(this)

    };

    handleCategoryHover() {
        const { onCategoryHover, item } = this.props;
        onCategoryHover(item);
    }

    handleCategoryClick() {
        const { onCategoryClick, item } = this.props;
        onCategoryClick(item);
    }

    render() {
        return (
            <MenuItem
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);
