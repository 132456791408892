/* eslint-disable fp/no-let */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-equals-spacing */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import PropTypes from 'prop-types';

import ContentWrapper from 'Component/ContentWrapper';
import FRSlider from 'Component/FRSlider/FRSlider';
import {
    ProductLinks as SourceProductLinks
} from 'SourceComponent/ProductLinks/ProductLinks.component';
import isMobile from 'Util/Mobile';

import './ProductLinks.override.style';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/derived-class-names
export class ProductLinks extends SourceProductLinks {
    static propTypes = {
        title: PropTypes.any.isRequired
    };

    // TODO implement logic
    renderItemList() {
        const {
            linkType,
            linkedProducts: { [linkType]: { items } },
            numberOfProductsToDisplay
        } = this.props;

        if (!items) {
            return Array.from(
                { length: numberOfProductsToDisplay },
                (_, i) => this.renderProductCard({}, i)
            );
        }
        let itemCount = 0;
        // let itemLoop = false;
        if (!isMobile.any() && linkType === 'related') {
            itemCount = 4;
            // itemLoop = true;
        }
        if (!isMobile.any() && linkType === 'upsell') {
            itemCount = 5;
        }
        if (!isMobile.any() && linkType === 'crosssell') {
            itemCount = 4;
        }

        const inStockList = items.filter((o) => o.stock_status === 'IN_STOCK');
        const inOutStockList = items.filter((o) => o.stock_status === 'OUT_OF_STOCK');

        let sortedIrtem;
        if (inOutStockList === 'undefined' || inOutStockList === undefined) {
            sortedIrtem = inStockList;
        } else {
            sortedIrtem = inStockList.concat(inOutStockList);
        }

        return (
            <FRSlider
            //   data={ items }
              data={ sortedIrtem }
              isRelated={ false }
              count={ itemCount }
              sort="1"
            //   sloop= { itemLoop }
            />
        );
    }

    render() {
        // const { areDetailsLoaded } = this.props;

        // if (!areDetailsLoaded) {
        //     return null;
        // }

        return (
            <ContentWrapper
              mix={ { block: 'ProductLinks' } }
              wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                <ul block="ProductLinks" elem="List">
                    { this.renderItemList() }
                </ul>
            </ContentWrapper>
        );
    }
}

export default ProductLinks;
