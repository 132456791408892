/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable fp/no-let */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import {
    isEmpty
} from 'lodash';
import PropTypes from 'prop-types';
import { Component, PureComponent } from 'react';
import { connect } from 'react-redux';

import Loader from 'Component/Loader';
import {
    ContactPageDispatcher, updateContactPageMakes, updateContactPageModels, updateContactPageOptions,
    updateContactPageYears
} from 'Store/ContactPage';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    MakesDispatcher, ModelsDispatcher, OptionsDispatcher, YearsDispatcher
} from 'Store/PartsFinder';
import { FilterType } from 'Type/ProductList';
import { getVariantsIndexes } from 'Util/Product';

import WebshopForm from './WebshopForm.component';
import {
    MAX_COUNT, MAX_FILE_SIZE, RETURN_FORM_GRAPHQL_URL_LIVE, RETURN_FORM_GRAPHQL_URL_STAGING
} from './WebshopForm.config';

/** @namespace Pwa/Component/WebshopForm/Container/WebshopFormContainer */
export class WebshopFormContainer extends PureComponent {
    static propTypes = {
        selectedFilters: FilterType
    };

    static defaultProps = {
        selectedFilters: {}
    };

    containerFunctions = {
        setFormState: this.setFormState.bind(this),
        renderSubmitButton: this.renderSubmitButton.bind(this),
        handleFileChange: this.handleFileChange.bind(this),
        mutationRequest: this.mutationRequest.bind(this),
        onCreateAccountAttempt: this.onCreateAccountAttempt.bind(this),
        onCreateAccountSuccess: this.onCreateAccountSuccess.bind(this),
        onError: this._onError.bind(this),
        fetchWorkshopLocations: this.fetchWorkshopLocations.bind(this),
        prevStep: this.prevStep.bind(this),
        returnFromSecond: this.returnFromSecond.bind(this),
        returnFromThird: this.returnFromThird.bind(this),
        proceedNext: this.proceedNext.bind(this),
        onProductSelect: this.onProductSelect.bind(this),
        handleFormChange: this.handleFormChange.bind(this),
        handleOptionsDataChange: this._handleFieldChange.bind(this, 'option'),
        handleCustomCheckbox: this.handleCustomCheckbox.bind(this),
        getAttribute: this.getAttribute.bind(this)
    };

    getAttribute(code) {
        const { selectedFilters } = this.props;

        if (!Object.keys(selectedFilters).length) {
            const { cart: { items = [] } } = this.props;
            const { product: { attributes = {} } } = items[0];
            return attributes[code];
        }

        const currentVariantIndex = this._getCurrentVariantIndex();
        const { product, product: { variants = [] } } = items[0];
        const { attributes: parentAttributes = {} } = product;
        const { attributes = parentAttributes } = variants[currentVariantIndex] || product;
        const { attribute_options = {} } = parentAttributes[code] || {};

        return {
            ...attributes[code],
            attribute_options
        };
    }

    _getCurrentVariantIndex() {
        const { index } = this._getConfigurableParameters();
        return index >= 0 ? index : 0;
    }

    _getConfigurableParameters() {
        const { selectedFilters = {} } = this.props;
        const { cart: { items = [] } } = this.props;
        const { product: { variants = [] } } = items[0];
        const filterKeys = Object.keys(selectedFilters);

        if (filterKeys.length < 0) {
            return { indexes: [], parameters: {} };
        }

        const indexes = getVariantsIndexes(variants, selectedFilters);
        const [index] = indexes;

        if (!variants[index]) {
            return { indexes: [], parameters: {} };
        }
        const { attributes } = variants[index];
        let parameters = {};
        if (!isEmpty(attributes)) {
            parameters = Object.entries(attributes)
                .reduce((parameters, [key, { attribute_value }]) => {
                    if (filterKeys.includes(key)) {
                        return { ...parameters, [key]: attribute_value };
                    }

                    return parameters;
                }, {});
        }

        return { indexes, index, parameters };
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            step: 1,
            productImage: null,
            productImages: [],
            uploadedProductImages: [],
            fileLimit: false,
            loading: false,
            totalFizeSize: 0,
            formErrorFirst: false,
            formErrorSecond: false,
            formErrorThird: false,
            formErrorFourth: false,
            formErrorSecondCustomVehicle: false,
            selectedItems: [],
            workshopLocations: [],
            selectedProducts: [],
            appointmentTypes: [],
            formData: {
                fullName: '',
                emailAddress: '',
                contactNumber: '',
                year: '',
                make: '',
                model: '',
                option: '',
                specialAccessories: '',
                modificationsDetail: '',
                productsMounted: '',
                customYear: '',
                customMake: '',
                customModel: '',
                comments: '',
                customVehicle: false,
                appointment_type_id: 0,
                workshopLocationId: 1,
                appointment_type_comment: ''
            }
        };
    }

    componentDidMount() {
        const { yearsData } = this.props;

        this.setState({ selectedItems: [] });

        if (isEmpty(yearsData)) {
            this.storeIdLookupAndRequest();
        }

        this.fetchWorkshopLocations();
        this.fetchAppointmentTypes();
    }

    storeIdLookupAndRequest= () => {
        const pName = window.location.pathname;

        const period = 600; // ms
        const sleepyAlert = setInterval(() => {
            const { storeId } = window.storeConfig;
            if (storeId !== undefined) {
                clearInterval(sleepyAlert);
                const { requestPartsFinderYears } = this.props;
                requestPartsFinderYears({ endpointUrl: '/vfits?', cb: updateContactPageYears });
            }
        }, period);
    };

    handleFormChange() {
        const { name, value } = event.target;

        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    }

    handleCustomCheckbox() {
        const { name, checked, value } = event.target;

        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: checked
            }
        }));
    }

    // Proceed to next step
    nextStep() {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    }

    // Go back to prev step
    prevStep() {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    }

    // Proceed to next step
    finalSubmit() {
        const { step } = this.state;
        this.setState({
            step: 5
        });
    }

    returnFromSecond() {
        const { step } = this.state;
        this.setState({
            step: step - 1,
            selectedItems: []
        });
    }

    returnFromThird() {
        const { step } = this.state;
        this.setState({
            step: step - 1,
            selectedItems: []
        });
    }

    proceedNext(stepNo) {
        const { showNotification } = this.props;
        const { formData, selectedProducts } = this.state;
        const {
            fullName,
            emailAddress,
            specialAccessories,
            modificationsDetail,
            productsMounted,
            customYear,
            customMake,
            customModel,
            comments,
            customVehicle,
            year,
            make,
            model,
            option
        } = formData;

        switch (stepNo) {
        case 'step1':
            if (fullName === '' || emailAddress === '') {
                return (
                    this.setState({ formErrorFirst: true }, () => {
                        showNotification(
                            'info',
                            __('Please fill all required fields to continue.')
                        );
                    })
                );
            }
            this.setState({ formErrorFirst: false });

            return (
                this.setState({ formErrorFirst: false }, () => {
                    this.nextStep();
                })
            );
        case 'step2':
            if (customVehicle === false && (year === '' || make === '' || model === '' || option === '' || specialAccessories === '' || modificationsDetail === '' || productsMounted === '')) {
                return (
                    this.setState({ formErrorSecond: true }, () => {
                        showNotification(
                            'info',
                            __('Please fill all required fields to continue.')
                        );
                    })
                );
            }
            if (customVehicle === true && (customYear === '' || customMake === '' || customModel === '' || specialAccessories === '' || modificationsDetail === '' || productsMounted === '')) {
                return (
                    this.setState({ formErrorSecondCustomVehicle: true, formErrorSecond: false }, () => {
                        showNotification(
                            'info',
                            __('Please fill all required fields to continue.')
                        );
                    })
                );
            }

            return (
                this.setState({ formErrorSecond: false }, () => {
                    this.nextStep();
                })
            );
        case 'step3':
            // if (isEmpty(selectedProducts)) {
            //     return (
            //         this.setState({ formErrorThird: true }, () => {
            //             showNotification(
            //                 'info',
            //                 __('Please select atleast any one of the products to continue.')
            //             );
            //         })
            //     );
            // }

            return (
                this.setState({ formErrorThird: false }, () => {
                    this.nextStep();
                })
            );
        default:
            (console.log('This is a multi-step form built with React.'));
        }
    }

    handleYearsDataChange = (value) => {
        const { requestPartsFinderMakes } = this.props;
        requestPartsFinderMakes({ yearId: value, endpointUrl: '/vfits?', cb: updateContactPageMakes });
        this._handleFieldChange('year', value);
    };

    handleMakesDataChange = (value) => {
        const { requestPartsFinderModels } = this.props;
        const { formData: { year } } = this.state;
        requestPartsFinderModels({
            yearId: year, makeId: value, endpointUrl: '/vfits?', cb: updateContactPageModels
        });
        this._handleFieldChange('make', value);
    };

    handleModelDataChange = (value) => {
        const { requestPartsFinderOptions } = this.props;

        const { formData: { year, make } } = this.state;

        requestPartsFinderOptions({
            yearId: year, makeId: make, modelId: value, endpointUrl: '/vfits?', cb: updateContactPageOptions
        });
        this._handleFieldChange('model', value);
    };

    _handleFieldChange(fieldName, value) {
        const { formData } = this.state;
        this.setState({ formData: { ...formData, [fieldName]: value } });
    }

    handleWorkshopLocationChange = (value) => {
        this._handleFieldChange('workshopLocationId', value);
    };

    onProductSelect(product) {
        const {
            id, qty, sku, name
        } = product;

        const { selectedProducts } = this.state;

        if (event.target.checked === false) {
            return (
                this.setState({
                    selectedProducts: selectedProducts.filter((product) => product.sku !== sku)
                }));
        }

        this.setState((prevState) => ({
            selectedProducts: [...prevState.selectedProducts, {
                name,
                sku,
                qty: 1
            }]
        }));
    }

    _onSelect(args) {
        let totalSize = 0;
        for (let i = 0; i < args.length; i++) {
            const fileSize = args[i].size;
            totalSize += fileSize;
        }
        const size = Math.round((totalSize / 1024));
        return (
            size
        );
    }

    handleUploadFiles(files) {
        const uploaded = Array.from(this.state.uploadedProductImages);
        if (files && files.length > 0) {
            const fileSizeCheck = this._onSelect(files);
            this.setState({ totalFizeSize: fileSizeCheck });
        }

        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === MAX_COUNT) {
                    this.setState({ fileLimit: true });
                }
                if (uploaded.length > MAX_COUNT) {
                    showNotification(
                        'error',
                        __('You can only add a maximum of %s files', MAX_COUNT)
                    );
                    this.setState({ fileLimit: false });
                    limitExceeded = true;
                    return true;
                }
            }
        });
        if (!limitExceeded) {
            this.setState({ uploadedProductImages: uploaded });
        }
    }

    handleFileChange(e) {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        this.handleUploadFiles(chosenFiles);
    }

    setFormState() {
        this.setState({ [event.target.name]: event.target.value });
    }

    _replaceSpecialCharsAndNewlines(text) {
        return text.replace(/[^\w\s]/g, ' ').replace(/\n/g, '<br>');
    }

    _stringifyValuesInArrayOfObjects(arrayOfObjects) {
        return arrayOfObjects.map((obj) => {
            const stringifiedValues = {};
            // eslint-disable-next-line guard-for-in, no-restricted-syntax
            for (const key in obj) {
                stringifiedValues[key] = JSON.stringify(obj[key]);
            }

            return stringifiedValues;
        });
    }

    async mutationRequest() {
        const {
            uploadedProductImages,
            formData,
            selectedProducts
        } = this.state;

        const {
            fullName,
            emailAddress,
            contactNumber,
            year,
            make,
            model,
            option,
            specialAccessories,
            modificationsDetail,
            productsMounted,
            customYear,
            customMake,
            customModel,
            comments,
            appointment_type_id,
            workshopLocationId,
            appointment_type_comment
        } = formData;

        const { storeId } = window.storeConfig;

        this.setState({ loading: true });

        const selectedProductsJson = JSON.stringify(selectedProducts);
        const operations = {
            query: `
          mutation (
            $vehicle_model: Int
            $appointment_type_comment: String,
            $name:  String!
            $contact_number: String
            $email: String!
            $images: [Upload]
            $products: [WorkshopProductItem]
            $vehicle_year: Int
            $vehicle_make: Int
            $vehicle_option: Int
            $vehicle_custom_year: String
            $vehicle_custom_make: String
            $vehicle_custom_model: String
            $vehicle_special_accessories: String
            $vehicle_comments: String
            $vehicle_current_products: String
            $comments_other: String
            $appointment_type_id: Int
            $location_id: Int
            $store_id: Int
          ) {
            CreateWorkshopRequest(
              appointment_type_comment: $appointment_type_comment
              vehicle_model: $vehicle_model
              name: $name
              contact_number: $contact_number
              email: $email
              products: $products
              images: $images
              vehicle_year: $vehicle_year
              vehicle_make: $vehicle_make
              vehicle_option: $vehicle_option
              vehicle_custom_year: $vehicle_custom_year
              vehicle_custom_make: $vehicle_custom_make
              vehicle_custom_model: $vehicle_custom_model
              vehicle_special_accessories: $vehicle_special_accessories
              vehicle_comments: $vehicle_comments
              vehicle_current_products: $vehicle_current_products
              comments_other: $comments_other
              appointment_type_id: $appointment_type_id
              location_id: $location_id
              store_id: $store_id
            ) {
              id
              name
              email
              contact_number
              products{id sku name qty}
              vehicle_year
              vehicle_make
              vehicle_option
              vehicle_custom_year
              vehicle_custom_make
              vehicle_custom_model
              vehicle_special_accessories
              vehicle_comments
              vehicle_current_products
              comments_other
              appointment_type_id
              location_id
              store_id
            }
          }
        `
        };
        const variables = {
            name: fullName,
            contact_number: contactNumber,
            email: emailAddress,
            vehicle_year: parseInt(year),
            vehicle_make: parseInt(make),
            vehicle_option: parseInt(option),
            vehicle_custom_year: customYear,
            vehicle_custom_make: customMake,
            vehicle_custom_model: customModel,
            vehicle_special_accessories: specialAccessories,
            vehicle_comments: modificationsDetail,
            vehicle_current_products: productsMounted,
            comments_other: comments,
            appointment_type_id: parseInt(appointment_type_id),
            location_id: parseInt(workshopLocationId),
            store_id: storeId,
            appointment_type_comment,
            vehicle_model: parseInt(model),
            products: selectedProducts,
            images: []
        };

        const map = {
            images: ['variables.images.*']
        };

        const workshopFormdata = new FormData();
        workshopFormdata.append('operations', JSON.stringify(operations));
        workshopFormdata.append('map', JSON.stringify(map));
        workshopFormdata.append('variables', JSON.stringify(variables));

        for (let i = 0; i < uploadedProductImages.length; i++) {
            workshopFormdata.append('images', uploadedProductImages[i], uploadedProductImages[i].name);
        }

        const myHeaders = new Headers();
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: workshopFormdata,
            redirect: 'follow'
        };

        let fsyncUrl = RETURN_FORM_GRAPHQL_URL_STAGING;

        if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
            fsyncUrl = RETURN_FORM_GRAPHQL_URL_LIVE;
        }

        const result = await fetch(fsyncUrl, requestOptions);
        const response = await result.json();

        return response;
    }

    async fetchWorkshopLocations() {
        const { showNotification } = this.props;
        const {
            formData
        } = this.state;

        this.setState({ loading: true });

        const { storeId } = window.storeConfig;

        const myHeaders = new Headers();

        myHeaders.append('Content-Type', 'application/json');

        const graphqlFormData = JSON.stringify({
            query: `query { WorkshopLocationsQuery(storeId: ${storeId}) { id name country_id province city street_1 street_2 postal_code latitude longitude order_priority region } }`
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: graphqlFormData,
            redirect: 'follow'
        };

        let fsyncUrl = RETURN_FORM_GRAPHQL_URL_STAGING;

        if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
            fsyncUrl = RETURN_FORM_GRAPHQL_URL_LIVE;
        }

        const result = await fetch(fsyncUrl, requestOptions).then(
        /** @namespace Pwa/Component/WebshopForm/Container/fetch/then */
            (response) => response.json()
        )
            .then(
            /** @namespace Pwa/Component/WebshopForm/Container/fetch/then/then */
                (result) => {
                    if (result.data.WorkshopLocationsQuery === null && result.errors && result.errors.length > 0) {
                        const { debugMessage, extensions } = result.errors[0];
                        const { message } = result.errors[0];

                        if (debugMessage) {
                            return (
                                showNotification(
                                    'info',
                                    __(debugMessage)
                                ));
                        }

                        const { validation = {} } = extensions;
                        const keysArray = Object.values(validation);

                        if (keysArray[0]) {
                            return (
                                showNotification(
                                    'info',
                                    __(keysArray[0][0])
                                ));
                        }

                        return (
                            showNotification(
                                'info',
                                __(message)
                            ));
                    }

                    this.setState({ workshopLocations: result.data.WorkshopLocationsQuery }, () => {
                    });
                }
            )
            .catch(
            /** @namespace Pwa/Component/WebshopForm/Container/fetch/then/then/catch */
                (error) => (showNotification(
                    'error',
                    __('Please try again later.')
                ))
            );

        this.setState({ loading: false });
    }

    async fetchAppointmentTypes() {
        const { showNotification } = this.props;
        const {
            formData
        } = this.state;

        const { storeId } = window.storeConfig;

        this.setState({ loading: true });

        const myHeaders = new Headers();

        myHeaders.append('Content-Type', 'application/json');

        const graphqlFormData = JSON.stringify({
            query: `query { WorkshopAppointmentTypesQuery(storeId: ${storeId}) { id name region } }`
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: graphqlFormData,
            redirect: 'follow'
        };

        let fsyncUrl = RETURN_FORM_GRAPHQL_URL_STAGING;

        if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
            fsyncUrl = RETURN_FORM_GRAPHQL_URL_LIVE;
        }

        const result = await fetch(fsyncUrl, requestOptions).then(
        /** @namespace Pwa/Component/WebshopForm/Container/fetch/then */
            (response) => response.json()
        )
            .then(
            /** @namespace Pwa/Component/WebshopForm/Container/fetch/then/then */
                (result) => {
                    if (result.data.WorkshopAppointmentTypesQuery === null && result.errors && result.errors.length > 0) {
                        const { debugMessage, extensions } = result.errors[0];
                        const { message } = result.errors[0];

                        if (debugMessage) {
                            return (
                                showNotification(
                                    'info',
                                    __(debugMessage)
                                ));
                        }

                        const { validation = {} } = extensions;
                        const keysArray = Object.values(validation);

                        if (keysArray[0]) {
                            return (
                                showNotification(
                                    'info',
                                    __(keysArray[0][0])
                                ));
                        }

                        return (
                            showNotification(
                                'info',
                                __(message)
                            ));
                    }

                    this.setState({ appointmentTypes: result.data.WorkshopAppointmentTypesQuery }, () => {
                    });
                }
            )
            .catch(
            /** @namespace Pwa/Component/WebshopForm/Container/fetch/then/then/catch */
                (error) => (showNotification(
                    'error',
                    __('Please try again later.')
                ))
            );

        this.setState({ loading: false });
    }

    async onCreateAccountSuccess(fields) {
        const { showNotification, isVisible } = this.props;
        const { totalFizeSize } = this.state;

        if (totalFizeSize && totalFizeSize > MAX_FILE_SIZE) {
            return (showNotification(
                'info',
                __('File size has exceeded it max limit of 5MB. Please upload files less than 5MB')
            )
            );
        }

        try {
            const code = await this.mutationRequest();
            if (code && code.errors && code.errors.length > 0) {
                const { debugMessage, extensions } = code.errors[0];
                const { message } = code.errors[0];

                if (debugMessage) {
                    return (
                        showNotification(
                            'error',
                            __(debugMessage)
                        ));
                }

                const { validation = {} } = extensions;
                const keysArray = Object.values(validation);

                if (keysArray[0]) {
                    return (
                        showNotification(
                            'error',
                            __(keysArray[0][0])
                        ));
                }

                return (
                    showNotification(
                        'error',
                        __(message)
                    ));
            }
            showNotification(
                'success',
                __('Workshop request submitted successfully.')
            );
            this.setState({ selectedItems: [], formErrorThird: false }, () => this.finalSubmit());
        } catch (err) {
            return (
                showNotification(
                    'error',
                    __('Please refresh the page try again after sometimee.')
                )
            );
        } finally {
            this.setState({
                loading: false, formErrorFirst: false, formErrorSecond: false
            });
        }
    }

    onCreateAccountAttempt(_, invalidFields) {
        const { showNotification } = this.props;
        if (invalidFields) {
            showNotification('info', __('Incorrect data! Please start again and resolve all field validation errors.'));
            this.setState({ showValidationError: '' });
        }
    }

    _onError() {
        this.setState({ loading: false, showValidationError: '' });
    }

    renderSubmitButton() {
        return (
            <button
              block="Contact"
              elem="Button"
              mix={ { block: 'Button' } }
            >
            { __('Submit Details') }
            </button>
        );
    }

    render() {
        return (
                <WebshopForm
                  { ...this.props }
                  { ...this.state }
                  { ...this.containerFunctions }
                  handleYearsDataChange={ this.handleYearsDataChange }
                  handleMakesDataChange={ this.handleMakesDataChange }
                  handleModelDataChange={ this.handleModelDataChange }
                  handleWorkshopLocationChange={ this.handleWorkshopLocationChange }
                />
        );
    }
}

/** @namespace Pwa/Component/WebshopForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    googleRecaptchaToken: state.GoogleRecaptchaReducer.recaptchaToken,
    customer: state.MyAccountReducer.customer,
    yearsData: state.ContactPageReducer.yearsData,
    makesData: state.ContactPageReducer.makesData,
    modelsData: state.ContactPageReducer.modelsData,
    optionsData: state.ContactPageReducer.optionsData,
    cart: state.CartReducer.cartTotals

});

/** @namespace Pwa/Component/WebshopForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    requestPartsFinderYears: (options) => YearsDispatcher.handleFsyncData(dispatch, options),
    requestPartsFinderMakes: (options) => MakesDispatcher.handleFsyncData(dispatch, options),
    requestPartsFinderModels: (options) => ModelsDispatcher.handleFsyncData(dispatch, options),
    requestPartsFinderOptions: (options) => OptionsDispatcher.handleFsyncData(dispatch, options)
});

export default connect(mapStateToProps, mapDispatchToProps)(WebshopFormContainer);
