/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import {
    ProductReviewsContainer as SourceProductReviewsContainer
} from 'SourceComponent/ProductReviews/ProductReviews.container';
import { showNotification } from 'Store/Notification/Notification.action';
/** @namespace Pwa/Component/ProductReviews/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isEnabled: state.ConfigReducer.reviews_are_enabled,
    isGuestEnabled: state.ConfigReducer.reviews_allow_guest,
    device: state.ConfigReducer.device
});

/** @namespace Pwa/Component/ProductReviews/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showInfoNotification: (message) => dispatch(showNotification('info', message))
});

/** @namespace Pwa/Component/ProductReviews/Container/ProductReviewsContainer */
export class ProductReviewsContainer extends SourceProductReviewsContainer {

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewsContainer);
