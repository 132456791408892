/* eslint-disable no-console */
import { get, isEmpty } from 'lodash';

import PartsFinderQuery from 'Query/PartsFinder.query';
import ProductListQuery from 'Query/ProductList.query';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import {
    updateLoadStatus
} from 'Store/ProductList/ProductList.action';
import {
    updateVehicleFinderProductLoading,
    updateVehicleSpecificSkus,
    updateVehicleSpecifiedData
} from 'Store/VehicleFinder/VehicleFinder.action';
import { fetchQuery, QueryDispatcher } from 'Util/Request';

/** @namespace Pwa/Store/PartsFinder/ProductSku/Dispatcher/ProductSkuDispatcher */
export class ProductSkuDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess(response, dispatch, { cb, categoryIds }) {
        const vehicle = get(response, 'vehicle', []);
        // const productArr = JSON.parse(products);
        if (!vehicle) {
            dispatch(updateLoadStatus(false));
            return;
        }
        dispatch(updateVehicleSpecifiedData(vehicle));
        const skus = vehicle?.products?.map(({ sku }) => sku).join(',');
        if (!isEmpty(skus)) {
            dispatch(updateVehicleSpecificSkus(skus));
        }
        if (isEmpty(skus)) {
            const currentPage = 1;
            const args = {};

            const items = [];
            const total_count = 0;
            const total_pages = 0;
            dispatch(updateLoadStatus(false));

            dispatch(cb(items,
                currentPage,
                total_count,
                total_pages,
                args));
            dispatch(updateVehicleFinderProductLoading(false));

            return;
        }
        const productsskuarray = skus.split(',');

        const options = { sku: { in: productsskuarray }, category_id: categoryIds };

        const pageSize = productsskuarray.length;
        const currentPage = 1;
        const sort = { price: 'DESC' };

        fetchQuery(ProductListQuery.getVehicleQuery({
            ...options, pageSize, currentPage, sort
        })).then(
            /** @namespace Pwa/Store/PartsFinder/ProductSku/Dispatcher/fetchQuery/then */
            (data) => {
                const currentPage = 1;
                const args = {};

                const {
                    products: {
                        items,
                        total_count,
                        page_info: { total_pages } = {}
                    } = {}
                } = data;

                const filterProducts = items?.reduce((acc, item) => {
                    if (productsskuarray?.includes(item.sku)) {
                        acc.push(item);
                    }

                    return acc;
                }, []);

                dispatch(updateLoadStatus(false));
                dispatch(cb(filterProducts,
                    currentPage,
                    total_count,
                    total_pages,
                    args));
                dispatch(updateVehicleFinderProductLoading(false));
            },
            this._handleError
        );
    }

    onError(_, dispatch) {
        dispatch(updateNoMatch(true));
        dispatch(updateVehicleFinderProductLoading(false));
    }

    // eslint-disable-next-line no-unused-vars
    prepareRequest(options, dispatch) {
        const { cb } = options;
        const currentPage = 1;
        const args = {};

        const items = [];
        const total_count = 0;
        const total_pages = 0;
        dispatch(updateLoadStatus(true));
        dispatch(updateVehicleFinderProductLoading(true));

        dispatch(cb(items,
            currentPage,
            total_count,
            total_pages,
            args, true));

        return PartsFinderQuery.getProducts(options);
    }
}

export default new ProductSkuDispatcher();
