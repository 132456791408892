/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

import { getSocialIcons } from './helper';

/**
 * CMS Blocks Query
 * @class CmsBlocksQuery
 * @namespace Pwa/Query/HomePage/Query/HomePageQuery */
class HomePageQuery {
    /**
     * get CMS Block query
     * @param  {{identifier: String, title: String, content: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Field} CMS Block query
     * @memberof CmsBlocksQuery
     */
    getSliderQuery = ({ sliderCategoryId }) => new Field('fsyncSlider')
        .addArgument('category_id', 'ID!', sliderCategoryId)
        .addField(this.getSlides())
        .setAlias('slider');

    getProductSliderQuery = ({ productSliderCategoryId }) => new Field('fsyncHomeSlider')
        .addArgument('category_id', 'ID!', productSliderCategoryId)
        .addField(this.getBannerText())
        .addField(this.getProductSlides())
        .setAlias('productSlider');

    getContentQuery = () => new Field('cmsHomePage')
        .addField(this.getSlimeLine2Block())
        .addField(this.getSocialBlock())
        .addField(this.getInstagramFeeds())
        .addField(this.getNewsletterBlock())
        .addFieldList([
            'meta_keywords',
            'meta_description',
            'meta_title'
        ])
        .setAlias('content');

      getNewsletterPopup = () => new Field('catchEmailPopup')
          .addFieldList([
              'active',
              'heading_text',
              'message_text',
              'ask_firstname',
              'ask_lastname',
              'ask_consent',
              'consent_text',
              'submit_text',
              'can_close',
              'popup_delay',
              'show_again_after',
              'show_limit',
              'background_image',
              'terms_url'
          ])
          .setAlias('newLetterPopupConfig');

    getSlimeLine2Block = () => new Field('slimline2_block')
        .addFieldList([
            'title_h3',
            'title_h4',
            'description',
            'backround_image',
            'link_label',
            'link_url'
        ]);

    getSocialBlock = () => new Field('social_block')
        .addField(getSocialIcons('social_icons'))
        .addFieldList([
            'title'
        ]);

    getInstagramFeeds = () => new Field('instagram_feeds')
        .addFieldList([
            'low_resolution_image',
            'url'
        ]);

    getNewsletterBlock = () => new Field('newsletter_block')
        .addFieldList([
            'title'
        ]);

    getBannerText = () => new Field('banner_title')
        .addFieldList([
            'title',
            'link_label',
            'link_url'
        ])
        .setAlias('banner');

    getProductSlides = () => new Field('homeSliderSlides')
        .addFieldList([
            'id',
            'title',
            'subtitle',
            'image_mobile',
            'image',
            'type',
            'price',
            'url_text',
            'url',
            'store_id',
            'date_from',
            'date_to',
            'user_type'
        ])
        .setAlias('productSlides');

    getFields() {
        return [
            'id',
            'language',
            'image',
            'image_mobile',
            'content_top',
            'content_top_color',
            'content_top_size',
            'content_bottom',
            'content_bottom_color',
            'content_bottom_size',
            'content_position',
            'content_position_vertical',
            'button_text',
            'button_url',
            'slider_category_id',
            'banner_type',
            'date_from',
            'date_to',
            'user_type'
        ];
    }

    getSlides = () => new Field('slides')
        .addFieldList([
            'id',
            'language',
            'image',
            'image_mobile',
            'content_top',
            'content_top_color',
            'content_top_size',
            'content_bottom',
            'content_bottom_color',
            'content_bottom_size',
            'content_position',
            'content_position_vertical',
            'button_text',
            'button_url',
            'slider_category_id',
            'banner_type',
            'date_from',
            'date_to',
            'user_type'
        ]);

    // For B2B Users
    getCompanyCustomerQuery = () => new Field('companyCustomer')
        .addFieldList([
            'featured_product_skus',
            'frequently_product_skus'
        ])
        .addField(this.getCustomerInfo())
        .addField(this.getCustomerAccount())
        .addField(this.getBillingAddress())
        .setAlias('companyCustomerData');

    getCustomerInfo = () => new Field('customer_info')
        .addFieldList([
            'customer_id',
            'firstname',
            'lastname',
            'dealer_code',
            'company_name',
            'email',
            'account_email'
        ]);

    getCustomerAccount = () => new Field('customer_account')
        .addFieldList([
            'credit_limit',
            'available_balance'
        ]);

    getBillingAddress = () => new Field('billing_address')
        .addFieldList([
            'street',
            'city',
            'postcode',
            'telephone'
        ]);

    getbestSellerProductsQuery = () => new Field('bestSellerProducts')
        .addFieldList([
            'sku'
        ])
        .setAlias('bestSellerProductsData');
}

export default new HomePageQuery();
