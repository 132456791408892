/* eslint-disable no-console */
export const UPDATE_POPUP_PRODUCTS = 'UPDATE_POPUP_PRODUCTS';
export const SHOW_ADD_TO_CART_POPUP = 'SHOW_ADD_TO_CART_POPUP';

/** @namespace Pwa/Store/AddToCartPopUp/Action/setAddToCartPopupVisibility */
export const setAddToCartPopupVisibility = (isVisible) => ({
    type: SHOW_ADD_TO_CART_POPUP,
    isVisible
});

/** @namespace Pwa/Store/AddToCartPopUp/Action/updatePopupProducts */
export const updatePopupProducts = (data) => ({
    type: UPDATE_POPUP_PRODUCTS,
    data
});
