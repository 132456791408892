/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-const-assign */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import CategoryPagination from 'Component/CategoryPagination';
import ProductListPage from 'Component/ProductListPage';
import NoMatch from 'Route/NoMatch';
import {
    ProductList as SourceProductList
} from 'SourceComponent/ProductList/ProductList.component';

/** @namespace Pwa/Component/ProductList/Component/ProductList */
export class ProductList extends SourceProductList {
    componentDidUpdate(prevProps) {
        const { isWidget, currentPage, device } = this.props;
        const { currentPage: prevCurrentPage } = prevProps;

        // Scroll up on page change, ignore widgets
        if (prevCurrentPage !== currentPage && !isWidget && !device.isMobile) {
            // window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        const { isInfiniteLoaderEnabled } = this.props;

        if (isInfiniteLoaderEnabled) {
            this.observePageChange();
        }
    }

    renderPagination() {
        const {
            isLoading,
            totalPages,
            requestPage,
            isPaginationEnabled
        } = this.props;

        if (!isPaginationEnabled) {
            return null;
        }

        return (
            <CategoryPagination
              isLoading={ isLoading }
              totalPages={ totalPages }
              onPageSelect={ requestPage }
            />
        );
    }

    renderPage(props = {}) {
        const {
            isInfiniteLoaderEnabled,
            loadPage,
            isLoading,
            isVisible,
            currentPage,
            totalPages,
            mix,
            customer
        } = this.props;

        const newProps = this._processProps(props);

        const pathname = window?.location?.search;
        const patternToCheck = 'page=';

        const shouldDisplayCategoryPage = pathname.includes(patternToCheck) && currentPage <= totalPages;

        if (pathname.includes(patternToCheck)) {
            if (!shouldDisplayCategoryPage) {
                return (
                    <NoMatch />
                );
            }
        }

        return (
            <ProductListPage
              key={ currentPage }
              isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
              updatePages={ loadPage }
              isLoading={ isLoading }
              isVisible={ isVisible }
              mix={ mix }
              customer={ customer }
              { ...newProps }
            />

        );
    }
}

export default ProductList;
