/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/NotifyMe/Query/NotifyMeQuery */
class NotifyMeQuery {
  notifyme = (stockNotifInput) => new Field('addStockNotif')
      .addArgument('stockNotifInput', 'StockNotifInput!', stockNotifInput)
      .addFieldList([
          'status',
          'message'
      ]);
}

export default new NotifyMeQuery();
