Mosaic.setPlugins([require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/gdpr-cookie/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/gdpr-cookie/src/plugin/Router.plugin.js'),
require('./../packages/gdpr-cookie/src/plugin/StoreIndex.plugin.js'),
require('./../packages/gdpr/src/plugin/Checkout.plugin.js'),
require('./../packages/gdpr/src/plugin/CheckoutBilling.plugin.js'),
require('./../packages/gdpr/src/plugin/CheckoutGuestForm.plugin.js'),
require('./../packages/gdpr/src/plugin/CheckoutShipping.plugin.js'),
require('./../packages/gdpr/src/plugin/Html.plugin.js'),
require('./../packages/gdpr/src/plugin/MyAccount.plugin.js'),
require('./../packages/gdpr/src/plugin/MyAccountCreateAccount.plugin.js'),
require('./../packages/gdpr/src/plugin/Notification.plugin.js'),
require('./../packages/gdpr/src/plugin/Router.plugin.js'),
require('./../packages/paypal-fe/src/plugin/CartQuery.plugin.js'),
require('./../packages/paypal-fe/src/plugin/CheckoutPayments.plugin.js'),
require('./../packages/paypal-fe/src/plugin/CheckoutPaymentsContainer.plugin.js'),
require('./../packages/paypal-fe/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/stripe-payments/src/plugin/Checkout.plugin.js'),
require('./../packages/stripe-payments/src/plugin/CheckoutBilling.plugin.js'),
require('./../packages/stripe-payments/src/plugin/CheckoutBillingContainer.plugin.js'),
require('./../packages/stripe-payments/src/plugin/CheckoutContainer.plugin.js'),
require('./../packages/stripe-payments/src/plugin/CheckoutPayments.plugin.js'),
require('./../packages/stripe-payments/src/plugin/CheckoutPaymentsContainer.plugin.js')]);
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-magic-numbers */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable fp/no-let */
/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

//import * as PusherPushNotifications from '@pusher/push-notifications-web';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { render } from 'react-dom';
import {
    GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';

// import TagManager from 'react-gtm-module';
// import ReactGA from 'react-ga';
import App from 'Component/App';
import { GOOGLE_SITE_KEY } from 'Component/GoogleRecaptchaV3/GoogleRecaptcha.config';
import { isProduction } from 'Util/Helper';

import packageJson from '../package.json';

import 'react-tooltip/dist/react-tooltip.css';
import '@ungap/global-this';
//import 'Util/Extensions';
import 'Util/Polyfill';
import 'Style/main';
import 'Util/FontAwesome';

// export const googleAnalyticsAppId = window.storeConfig.googleAnalyticsAppId || 'UA-178030641-1';
// ReactGA.initialize(googleAnalyticsAppId);
// ReactGA.plugin.require('ecommerce');

export const hostUrl = window.location.href;

if (isProduction()) {
    // override console log function on production
    console.log = (_) => _;
}

Sentry.init({
    dsn: 'https://cab12bb25dd343ab99ed7a49bf12c1a8@o415944.ingest.sentry.io/5312300',
    environment: isProduction() ? 'production' : 'staging',
    //environment: 'production',
    release: packageJson.version,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: isProduction() ? 0.1 : 1.0,
    // Only monitor own site, ignore all third party scripts
    whitelistUrls: [
        'www.frontrunneroutfitters.com',
        'fsync.frontrunneroutfitters.com',
        'graphql-fin.frontrunneroutfitters.com',
        'graphql-flp.frontrunneroutfitters.com',
        'graphql-fvf.frontrunneroutfitters.com',
        'graphql-fgb.frontrunneroutfitters.com'
    ],
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Bread Pay
        /connect\.breadpayments\.com/i,
        // Google Tag Manager
        /www\.googletagmanager\.com/i,
        // Chipbot
        /static\.chipbot\.com/i,
        /www\.chipbot\.com/i
    ],
    allowUrls: [/https?:\/\/((cdn|www|fsync|graphql-fin|graphql-flp|graphql-fvf|graphql-fgb)\.)?frontrunneroutfitters\.com/],

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: isProduction() ? 0.1 : 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],

    beforeSend: (event, hint) => {
        const error = hint.originalException;
        if (error && error.message && error.message.match(/known issue/i)) {
            // do not send known issue
            return null;
        }

        return event;
    }
});

// Service worker registration
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

// Pusher Beams registration
// window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
//     const beamsClient = new PusherPushNotifications.Client({
//         instanceId: '8eb1a2be-1196-4357-b5bf-6c072dc12df3',
//         serviceWorkerRegistration
//     });

//     beamsClient.start()
//         .then((beamsClient) => beamsClient.getDeviceId())
//         .then((deviceId) => console.log('Successfully registered with Beams. Device ID:', deviceId))
//         .then(() => beamsClient.addDeviceInterest('debug-hello'))
//         .then(() => beamsClient.getDeviceInterests())
//         .then((interests) => console.log('Current interests:', interests))
//         .catch(console.error);
// });

render(
    <GoogleReCaptchaProvider reCaptchaKey={ GOOGLE_SITE_KEY }>
    <App />
    </GoogleReCaptchaProvider>,
    document.getElementById('root')
);
