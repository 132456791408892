/* eslint-disable react/no-unused-state */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/sort-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-console */
/* eslint-disable fp/no-let */
/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import { mode } from 'crypto-js';
import {
    isDate, isEmpty, map
} from 'lodash';
import { Component, lazy } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Field from 'Component/Field';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { convertStringToSlug } from 'Util/Url/Url';

import {
    PLEASE_SELECT, PLEASE_SELECT_LIST, PLEASE_SELECT_MAKE, PLEASE_SELECT_MODEL, PLEASE_SELECT_OPTION, SELECT_DIFFERENT_VEHICLE
} from '../../constants';

import 'react-datepicker/src/stylesheets/datepicker.scss';
import './VehicleFinder.style.scss';

export const CategoryPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/CategoryPage'));

export const LAST_SELECTED_VEHICLE_FROM_FINDER = 'selected_vehicle';

export const EXPIRY_TIME_SECONDS = 3.156e+7;

/** @namespace Pwa/Component/VehicleFinder/Component/VehicleFinder */
export class VehicleFinder extends Component {
  state = {
      purchase_date: new Date(),
      list_value: '',
      changeVehicle: true,
      isChanged: false,
      captureVehicleChangeState: true
  };

  static defaultProps = {
      isLoading: true,
      onSubmitAttempt: () => {},
      onSubmitSuccess: () => {},
      onError: () => {},
      handleYearsDataChange: () => {},
      handleMakesDataChange: () => {},
      handleModelsDataChange: () => {},
      handleOptionsDataChange: () => {}
  };

  componentDidUpdate(prevProps) {
      const {
          list, isCustomerGarage, selectedVehicle, updateSelectedVehicle, requestHomePageSlider, isLoading
      } = this.props;
      const { list_value, isChanged } = this.state;

      if (prevProps === this.props) {
          return;
      }
      const {
          loadedData, selectedYear, saveFormData
      } = this.props;

      if (isEmpty(loadedData) && !isChanged) {
          this.setState({ captureVehicleChangeState: true });
      }

      if (prevProps.loadedData !== loadedData) {
          const { formData = {} } = this.props;
          const { options } = formData;
          const options_id = parseFloat(options);
          //   if (options_id !== loadedData.id) {
          //       saveFormData({});
          //   }
          this.setState({
              changeVehicle: false
          });
      }
      if (!isEmpty(loadedData)) {
          const { changeVehicle = false, isChanged } = this.state;
          if (changeVehicle && !isChanged) {
              this.setState({
                  changeVehicle: false
              });
          }
      }

      if (prevProps !== this.props && !isEmpty(list) && !isCustomerGarage && (list_value !== 'hideList') && selectedVehicle === '') {
          const pageTitle = `${list[0]?.lyear}  ${ list[0]?.lmake}  ${ list[0]?.lmodel}  ${ list[0]?.loption}`;
          updateSelectedVehicle(pageTitle);

          requestHomePageSlider({
              optionId: list[0]?.options, endpointUrl: '/vfits?'
          });
      }
      if (this.props !== prevProps && !isLoading) {
          this.renderAutoSubmitButton();
      }
  }

  getSelectOptions = (options) => map(options, (option) => ({
      ...option,
      id: option.value
  }));

  getSelectOptionsDataList = (options) => map(options, (option) => ({
      ...option,
      id: option.vehicle.id,
      value: option.vehicle.id
  }));

  getListOptions = (options) => {
      const listOptions = map(options, (option) => {
          const {
              lyear, lmake, lmodel, model, loption = 'All', nick_name, options
          } = option;
          let label = `${lyear} ${lmake} ${lmodel} ${loption}`;
          if (nick_name) {
              label = `${lyear} ${lmake} ${lmodel} ${loption} (${nick_name})`;
          }
          const pageLink = convertStringToSlug(`${this.processUrl(lyear)}-${this.processUrl(lmake)}-${this.processUrl(lmodel)}-${this.processUrl(loption)}-${options}`);

          const optionValue = { pageTitle: label, pageLink, ...option };
          const value = JSON.stringify(optionValue);

          return ({
              label,
              value
          });
      });

      return [PLEASE_SELECT_LIST, ...listOptions, SELECT_DIFFERENT_VEHICLE];
  };

  handleListDataChange = (list_value) => this.setState({ list_value });

  _getLabel = (data, value, defaultLabel = '') => {
      const filteredData = data?.filter((item) => item.value == value) || [];
      return filteredData.length > 0 ? filteredData[0].label : defaultLabel;
  };

  renderAutoSubmitButton = () => {
      let {
          list_value,
          captureVehicleChangeState
      } = this.state;

      const { formData, loadedData } = this.props;
      let {
          year = '',
          make = '',
          model = '',
          options = ''
      } = formData;

      const {
          yearsData,
          makesData,
          modelsData,
          resetFormData,
          findResetFormData,
          list

      } = this.props;

      let {
          optionsData

      } = this.props;

      let singleVehicleOption = false;

      const isDisabled = isEmpty(year) || isEmpty(make) || isEmpty(model) || isEmpty(options) || isEmpty(yearsData) || isEmpty(makesData) || isEmpty(modelsData) || isEmpty(optionsData);
      let vehiclePageLink = '/vehicle/';
      let vehiclePageSubTitle = '';
      const isOptionsDisabled = isEmpty(optionsData) || isEmpty(modelsData) || isEmpty(make) || isEmpty(year);
      const optionsSelectData = (isOptionsDisabled) ? [] : optionsData;

      if (Array.isArray(optionsData)) {
          let count = optionsData.reduce((acc) => acc + 1, 0);

          if (count === 1) {
              const selectedOption = optionsData[0];
              const { vehicle } = selectedOption;
              const { id } = vehicle;
              optionsData = selectedOption;
              singleVehicleOption = true;
          }
      }

      //   optionsSelectData?.length == 1
      if (!isDisabled || singleVehicleOption) {
          let optionLabel;

          const yearLabel = this._getLabel(yearsData, year);
          const makeLabel = this._getLabel(makesData, make);
          const modelLabel = this._getLabel(modelsData, model);
          //   const [{ label: optionLabel }] = optionsData.filter((item) => item.vehicle.id == options);

          if (Array.isArray(optionsData)) {
              const [{ label }] = optionsData.filter((item) => item.vehicle.id == options);
              optionLabel = label;
          } else {
              const { label, vehicle } = optionsData;
              const { id } = vehicle;
              optionLabel = label;
              options = id;
          }

          // const { label: modelLabel } = find(modelsData, { value: model });
          // const { label: optionLabel } = find(optionsData, { value: option });

          // Append vehicle details as slug
          vehiclePageLink += convertStringToSlug(`${this.processUrl(yearLabel)}-${this.processUrl(makeLabel)}-${this.processUrl(modelLabel)}-${this.processUrl(optionLabel)}-${options}`);
          vehiclePageSubTitle += `${this.processUrl(yearLabel)} ${this.processUrl(makeLabel)} ${this.processUrl(modelLabel)} ${this.processUrl(optionLabel)}`;
          //   history.push(vehiclePageLink);
      }

      // Adding list checks
      const isFindListDisabled = isEmpty(list_value) || (list_value === 'hideList');
      if (!isFindListDisabled) {
          const optionValue = JSON.parse(list_value);
          const {
              pageTitle, pageLink, make: makeId, model: modelId, options: optionId, year: yearId
          } = optionValue;

          year = yearId;
          make = makeId;
          model = modelId;
          options = optionId;
          vehiclePageSubTitle = pageTitle;
          vehiclePageLink += pageLink;
      }
      const isFindDisabled = isDisabled && isFindListDisabled;
      //   const isMakesDisabled = isEmpty(makesData) || isEmpty(year);
      if ((!isFindDisabled || (singleVehicleOption && !isEmpty(formData))) && captureVehicleChangeState) {
          const { formData } = this.props;
          history.push(vehiclePageLink);
          this.setState({ captureVehicleChangeState: false });
          if (!isEmpty(formData)) {
              const { year } = formData;
              loadedData.year = year;
              const { products, ...vehicleData } = loadedData;
              return BrowserDatabase.setItem(vehicleData, LAST_SELECTED_VEHICLE_FROM_FINDER, EXPIRY_TIME_SECONDS);
          }
      }

      return null;
  };

  showText() {
      const {
          isLoading,
          onSubmitAttempt,
          onSubmitSuccess,
          onError,
          isCustomerGarage,
          selectedYear, loadedData, yearsData = []
      } = this.props;
      const yearLabel = yearsData.filter((val) => val.value === selectedYear);
      const { make: { name: makeLabel } = {}, model: { name: modelLabel } = {}, option: { name: optionLabel } = {} } = loadedData;
      const vehicleUrl = window.location.href;
      const vehicleYear = vehicleUrl.split('/').pop().split('-')[0];
      //   if (!(vehicleUrl.includes('/vehicle/'))) {
      //       return (
      //             <div>
      //                 <Form
      //                   key="vehicle-finder"
      //                   mix={ { block: 'Vehicle-Finder' } }
      //                   onSubmit={ onSubmitAttempt }
      //                   onSubmitSuccess={ onSubmitSuccess }
      //                   onSubmitError={ onError }
      //                 >
      //                 <Loader isLoading={ isLoading } />
      //                 { this.renderFormContent() }
      //                 { !isCustomerGarage && this.renderButton() }
      //                 { isCustomerGarage && this.renderSaveButton() }
      //                 </Form>
      //             </div>
      //       );
      //   }

      const vehicleDetails = `${vehicleYear} ${makeLabel} ${modelLabel} ${optionLabel || ''}`.trim();
      if (vehicleDetails.trim() && makeLabel != undefined && modelLabel != undefined) {
          localStorage.setItem('vehicleDetailsLocal', vehicleDetails);
      }
      const vehicleDetailsData = localStorage.getItem('vehicleDetailsLocal');

      return (
          <div className="container vehicleSelected">
            <span className="fr-link-gear">{ __('Outfit your vehicle') }</span>
            <div className="vehicleText">
                { vehicleDetailsData }
                {/* { yearLabel[0].label } */}
                {/* { vehicleYear }
                { (' ') }
                { makeLabel }
                { (' ') }
                { modelLabel }
                { (' ') }
                { optionLabel } */}
            </div>
            <button
              block="VehicleFinder"
              elem="Button btn"
              onClick={ () => {
                  this.setState({
                      changeVehicle: true,
                      isChanged: true,
                      captureVehicleChangeState: false

                  });
              } }
            >
                   { __('Change Vehicle') }
            </button>
            { !isCustomerGarage && this.renderShowProButton() }
          </div>
      );
  }

  renderFormContent = () => {
      const {
          list_value
      } = this.state;

      const {
          handleYearsDataChange,
          handleMakesDataChange,
          handleModelsDataChange,
          handleOptionsDataChange,
          handleNickNameDataChange,
          formData = {},
          yearsData = [],
          makesData = [],
          modelsData = [],
          optionsData = [],
          isCustomerGarage,
          list,
          selectedYear,
          selectedMake,
          selectedModel,
          selectedoption,
          loadedData,
          saveFormData
      } = this.props;

      const {
          year = '',
          make = '',
          model = '',
          options = '',
          nick_name = ''
      } = formData;

      const isYearsDisabled = isEmpty(yearsData);
      const yearsSelectData = yearsData;
      // const yearsSelectDataR = yearsSelectData.reverse();
      const isMakesDisabled = isEmpty(makesData) || isEmpty(year);

      const makesSelectData = (isMakesDisabled) ? [] : makesData;

      const isModelsDisabled = isEmpty(modelsData) || isEmpty(make) || isEmpty(year);

      const modelsSelectData = (isModelsDisabled) ? [] : modelsData;

      const isOptionsDisabled = isEmpty(optionsData) || isEmpty(modelsData) || isEmpty(make) || isEmpty(year);
      const optionsSelectData = (isOptionsDisabled) ? [] : optionsData;

      const displayDropdown = !isEmpty(list) && !isCustomerGarage && (list_value !== 'hideList');

      return (
          <>
            <span className="fr-link-gear">{ __('Outfit your vehicle') }</span>
            <div
              block="VehicleFinder"
              elem="Wrapper"
            >
              { displayDropdown
                && (
                    <Field
                      type="select"
                      id="list"
                      name="list"
                      sorting="0"
                      value={ list_value }
                      onChange={ this.handleListDataChange }
                      selectOptions={ this.getListOptions(list) }
                    />
                ) }
              { !displayDropdown
                && (
                    <>
                  <Field
                    type="select"
                    id="year"
                    isDisabled={ isYearsDisabled }
                    name="year"
                    value={ year }
                    sorting="0"
                    onChange={ handleYearsDataChange }
                    selectOptions={ this.getSelectOptions(yearsSelectData) }
                    label={ __('Year') }
                    placeholder={ `${PLEASE_SELECT.label}` }
                    placeholderDisabled
                  />
                  <Field
                    type="select"
                    id="make"
                    isDisabled={ isMakesDisabled }
                    name="make"
                    value={ make }
                    sorting="0"
                    onChange={ handleMakesDataChange }
                    selectOptions={ this.getSelectOptions(makesSelectData) }
                    label={ __('Make') }
                    placeholder={ `${PLEASE_SELECT_MAKE.label}` }
                    placeholderDisabled
                  />
                  <Field
                    type="select"
                    id="model"
                    isDisabled={ isModelsDisabled }
                    name="model"
                    value={ model }
                    sorting="0"
                    onChange={ handleModelsDataChange }
                    selectOptions={ this.getSelectOptions(modelsSelectData) }
                    label={ __('Model') }
                    placeholder={ `${PLEASE_SELECT_MODEL.label}` }
                    placeholderDisabled
                  />
                  <Field
                    type="select"
                    id="option"
                    isDisabled={ isOptionsDisabled }
                    name="option"
                    value={ options }
                    sorting="0"
                    onChange={ handleOptionsDataChange }
                    selectOptions={ this.getSelectOptionsDataList(optionsSelectData) }
                    label={ __('Option') }
                    placeholder={ `${PLEASE_SELECT_OPTION.label}` }
                    placeholderDisabled
                  />
                    </>
                ) }
              { isCustomerGarage && (
                  <>
                  { /* <Field
                    type="text"
                    id="purchase_date"
                    name="purchase_date"
                    value={ purchase_date }
                    placeholder={__("Birthday (Purchase Date)")}
                    onChange={ handlePurchaseDateDataChange }
                  /> */ }
                  { /* {__('Purchase Date:')} <DatePicker
                    selected={this.state.purchase_date}
                    onChange={this.handleDatePickerChange}
                    dateFormat="MM/dd/yyyy"
                  /> */ }
                  <Field
                    type="text"
                    id="nick_name"
                    name="nick_name"
                    placeholder={ __('Optional') }
                    value={ nick_name }
                    sorting="0"
                    onChange={ handleNickNameDataChange }
                    selectOptions={ this.getSelectOptions(modelsSelectData) }
                    label={ __('Nick Name') }
                  />
                  </>
              ) }
            </div>
          </>
      );
  };

  handleDatePickerChange = (date) => {
      this.setState({
          purchase_date: date
      });
  };

  // processUrl = (url) => url.replace(/ /g, '-');
  processUrl = (url) => url;

  renderDefaultPage() {
      return (
        <main />
      );
  }

  renderShowProButton = () => {
      let {
          list_value
      } = this.state;

      const { formData, loadedData } = this.props;
      let {
          year = '',
          make = '',
          model = '',
          options = ''
      } = formData;

      const {
          yearsData,
          makesData,
          modelsData,
          resetFormData,
          findResetFormData,
          list

      } = this.props;

      let {
          optionsData

      } = this.props;

      let singleVehicleOption = false;

      const isDisabled = isEmpty(year) || isEmpty(make) || isEmpty(model) || isEmpty(options) || isEmpty(yearsData) || isEmpty(makesData) || isEmpty(modelsData) || isEmpty(optionsData);
      let vehiclePageLink = '/vehicle/';
      let vehiclePageSubTitle = '';

      if (Array.isArray(optionsData)) {
          let count = optionsData.reduce((acc) => acc + 1, 0);

          if (count === 1) {
              const selectedOption = optionsData[0];
              const { vehicle } = selectedOption;
              const { id } = vehicle;
              optionsData = selectedOption;
              singleVehicleOption = true;
          }
      }

      //   optionsSelectData?.length == 1
      if (!isDisabled || singleVehicleOption) {
          let optionLabel;

          const yearLabel = this._getLabel(yearsData, year);
          const makeLabel = this._getLabel(makesData, make);
          const modelLabel = this._getLabel(modelsData, model);
          //   const [{ label: optionLabel }] = optionsData.filter((item) => item.vehicle.id == options);

          if (Array.isArray(optionsData)) {
              const [{ label }] = optionsData.filter((item) => item.vehicle.id == options);
              optionLabel = label;
          } else {
              const { label, vehicle } = optionsData;
              const { id } = vehicle;
              optionLabel = label;
              options = id;
          }

          // const { label: modelLabel } = find(modelsData, { value: model });
          // const { label: optionLabel } = find(optionsData, { value: option });

          // Append vehicle details as slug
          vehiclePageLink += convertStringToSlug(`${this.processUrl(yearLabel)}-${this.processUrl(makeLabel)}-${this.processUrl(modelLabel)}-${this.processUrl(optionLabel)}-${options}`);
          vehiclePageSubTitle += `${this.processUrl(yearLabel)} ${this.processUrl(makeLabel)} ${this.processUrl(modelLabel)} ${this.processUrl(optionLabel)}`;
          //   history.push(vehiclePageLink);
      }
      // Adding list checks
      const isFindListDisabled = isEmpty(list_value) || (list_value === 'hideList');
      if (!isFindListDisabled) {
          const optionValue = JSON.parse(list_value);
          const {
              pageTitle, pageLink, make: makeId, model: modelId, options: optionId, year: yearId
          } = optionValue;

          year = yearId;
          make = makeId;
          model = modelId;
          options = optionId;
          vehiclePageSubTitle = pageTitle;
          vehiclePageLink += pageLink;
      }

      const isFindDisabled = isDisabled && isFindListDisabled;

      return (
        <button
          block="VehicleFinder"
          elem="Button showPro"
          disabled={ isFindDisabled }
        >
        <RouterLink
          to={ {
              pathname: vehiclePageLink,
              state: {
                  vehiclePageSubTitle,
                  partFinderData: {
                      modelId: model, yearId: year, makeId: make, optionId: options
                  }
              }
          } }
          onClick={ findResetFormData }
          className="VehicleFinder-Link Button"
          rel="nofollow noopener noreferrer"
        >
            { __('Show Products') }
        </RouterLink>
        </button>
      );
  };

  renderButton = () => {
      let {
          list_value
      } = this.state;

      const { formData } = this.props;
      let {
          year = '',
          make = '',
          model = '',
          options = ''
      } = formData;

      const {
          yearsData,
          makesData,
          modelsData,
          optionsData,
          resetFormData,
          findResetFormData,
          list,
          isHomeTop
      } = this.props;

      const isDisabled = isEmpty(year) || isEmpty(make) || isEmpty(model) || isEmpty(options) || isEmpty(yearsData) || isEmpty(makesData) || isEmpty(modelsData) || isEmpty(optionsData);
      let vehiclePageLink = '/vehicle/';
      let vehiclePageSubTitle = '';

      if (!isDisabled) {
          const [{ label: yearLabel }] = yearsData.filter((item) => item.value == year);
          const [{ label: makeLabel }] = makesData.filter((item) => item.value == make);

          const [{ label: modelLabel }] = modelsData.filter((item) => item.value == model);

          const [{ label: optionLabel }] = optionsData.filter((item) => item.vehicle.id == options);

          // const { label: modelLabel } = find(modelsData, { value: model });
          // const { label: optionLabel } = find(optionsData, { value: option });

          // Append vehicle details as slug
          vehiclePageLink += convertStringToSlug(`${this.processUrl(yearLabel)}-${this.processUrl(makeLabel)}-${this.processUrl(modelLabel)}-${this.processUrl(optionLabel)}-${options}`);
          vehiclePageSubTitle += `${this.processUrl(yearLabel)} ${this.processUrl(makeLabel)} ${this.processUrl(modelLabel)} ${this.processUrl(optionLabel)}`;
      }

      // Adding list checks
      const isFindListDisabled = isEmpty(list_value) || (list_value === 'hideList');
      if (!isFindListDisabled) {
          const optionValue = JSON.parse(list_value);
          const {
              pageTitle, pageLink, make: makeId, model: modelId, options: optionId, year: yearId
          } = optionValue;

          year = yearId;
          make = makeId;
          model = modelId;
          options = optionId;
          vehiclePageSubTitle = pageTitle;
          vehiclePageLink += pageLink;
      }

      const isFindDisabled = isDisabled && isFindListDisabled;
      const isMakesDisabled = isEmpty(makesData) || isEmpty(year);

      let findBtn = __('Find');
      if (isHomeTop) {
          findBtn = 'Find vehicle-Specific Gear';
      }

      return (
          <>
         {/* { (isSignedIn() || isHomeTop) && ( */}
            <button
              block="VehicleFinder"
              elem="Button"
              disabled={ isFindDisabled }
            >
                        <RouterLink
                          to={ {
                              pathname: vehiclePageLink,
                              state: {
                                  vehiclePageSubTitle,
                                  partFinderData: {
                                      modelId: model, yearId: year, makeId: make, optionId: options
                                  }
                              }
                          } }
                          onClick={ findResetFormData }
                          className="VehicleFinder-Link Button"
                          rel="nofollow noopener noreferrer"
                        >
                            { findBtn }
                        </RouterLink>
            </button>
         {!isHomeTop && (
            <button className="btn clear" disabled={ isMakesDisabled } onClick={ resetFormData }>
                        { __('Reset') }
            </button>
         )}
          </>
      );
  };

  updateVehicleFinderSlider() {
      const {
          list_value
      } = this.state;

      const { updateSelectedVehicle } = this.props;

      const { requestHomePageSlider } = this.props;

      const selectedvehicle = JSON.parse(list_value);

      updateSelectedVehicle(selectedvehicle.pageTitle);

      requestHomePageSlider({
          optionId: selectedvehicle.options, endpointUrl: '/vfits?'
      });
  }

  renderSaveButton = () => {
      const {
          formData,
          resetFormData,
          onSaveClick,
          yearsData,
          makesData,
          modelsData,
          optionsData
      } = this.props;

      const {
          year = '',
          make = '',
          model = '',
          options = ''
      } = formData;

      const {
          purchase_date
      } = this.state;

      let labels = {};

      const isDisabled = isEmpty(year) || isEmpty(make) || isEmpty(model) || isEmpty(options) || !isDate(purchase_date); // || isEmpty(nick_name)
      if (!isDisabled) {
          const [{ label: lyear }] = yearsData.filter((item) => item.value == year);
          const [{ label: lmake }] = makesData.filter((item) => item.value == make);
          const [{ label: lmodel }] = modelsData.filter((item) => item.value == model);
          const [{ label: loption }] = optionsData.filter((item) => item.vehicle.id == options);

          labels = {
              lyear,
              lmake,
              lmodel,
              loption
          };
      }

      return (
      <button
        block="VehicleFinderSave"
        elem="Button"
        mix={ { block: 'Button' } }
        disabled={ isDisabled }
        onClick={ () => {
            resetFormData();
            onSaveClick({ ...formData, purchase_date, ...labels });
        } }
      >
        { __('Save') }
      </button>
      );
  };

  render() {
      const {
          isLoading,
          onSubmitAttempt,
          onSubmitSuccess,
          onError,
          isCustomerGarage
      } = this.props;
      const { changeVehicle } = this.state;
      return (
          changeVehicle
              ? (
                <div>
                    <Form
                      key="vehicle-finder"
                      mix={ { block: 'Vehicle-Finder' } }
                      onSubmit={ onSubmitAttempt }
                      onSubmitSuccess={ onSubmitSuccess }
                      onSubmitError={ onError }
                    >
                    <Loader isLoading={ isLoading } />
                    { this.renderFormContent() }
                    { !isCustomerGarage && this.renderButton() }
                    { isCustomerGarage && this.renderSaveButton() }
                    </Form>
                </div>
              )
              : (
                <div>
                    { this.showText() }
                </div>
              )

      );
  }
}

export default VehicleFinder;
