/* eslint-disable no-console */
/* eslint-disable max-len */
export const LOAD_AMAZON_PAY_CONFIG = 'LOAD_AMAZON_PAY_CONFIG';
export const UPDATE_AMAZON_PAY_CONFIG = 'UPDATE_AMAZON_PAY_CONFIG';
export const SAVE_AMAZON_PAY_SESSION_ID = 'SAVE_AMAZON_PAY_SESSION_ID';
export const UPDATE_AMAZON_PAY_ADDRESS = 'UPDATE_AMAZON_PAY_ADDRESS';
export const UPDATE_AMAZON_PAY_EMAIL = 'UPDATE_AMAZON_PAY_EMAIL';
export const UPDATE_STRIPE_PAYMENT_REQUEST = 'UPDATE_STRIPE_PAYMENT_REQUEST';

/** @namespace Pwa/Store/AmazonPay/Action/updateAmazonPayConfig */
export const updateAmazonPayConfig = (amazonPayConfig = {}, isLoading = false) => ({
    type: UPDATE_AMAZON_PAY_CONFIG,
    amazonPayConfig,
    isLoading
});

/** @namespace Pwa/Store/AmazonPay/Action/updateAmazonPayAddress */
export const updateAmazonPayAddress = (amazonPayShippingAddress = {}) => ({
    type: UPDATE_AMAZON_PAY_ADDRESS,
    amazonPayShippingAddress
});

/** @namespace Pwa/Store/AmazonPay/Action/updateAmazonPayEmail */
export const updateAmazonPayEmail = (email) => ({
    type: UPDATE_AMAZON_PAY_EMAIL,
    email
});

/** @namespace Pwa/Store/AmazonPay/Action/setLoading */
export const setLoading = (isLoading = false) => ({
    type: LOAD_AMAZON_PAY_CONFIG,
    isLoading
});

/** @namespace Pwa/Store/AmazonPay/Action/saveAmazonPaySession */
export const saveAmazonPaySession = (amazonPaySessionId, isLoading) => ({
    type: SAVE_AMAZON_PAY_SESSION_ID,
    amazonPaySessionId,
    isLoading
});
/** @namespace Pwa/Store/AmazonPay/Action/saveStripePaymentRequestForNativePay */
export const saveStripePaymentRequestForNativePay = (options) => ({
    type: UPDATE_STRIPE_PAYMENT_REQUEST,
    options

});
