/* eslint-disable max-lines */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CheckoutQuery from 'Query/Checkout.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import history from 'Util/History';
import { fetchMutation } from 'Util/Request';

import PayPalQuery from '../../query/PayPal.query';
import PayPal from './PayPal.component';
import { PAYPAL_EXPRESS, PAYPAL_SCRIPT } from './PayPal.config';

/** @namespace Paypal/Component/PayPal/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartTotals: state.CartReducer.cartTotals,
    clientId: state.ConfigReducer.paypal_client_id,
    isSandboxEnabled: state.ConfigReducer.paypal_sandbox_flag,
    TermsAndConditions: state.TermsAndConditionsReducer.isSatisfied

});

/** @namespace Paypal/Component/PayPal/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message, e) => dispatch(showNotification(type, message, e))
});

/** @namespace Paypal/Component/PayPal/Container/PayPalContainer */
export class PayPalContainer extends PureComponent {
    static propTypes = {
        clientId: PropTypes.string,
        isSandboxEnabled: PropTypes.bool,
        setLoading: PropTypes.func.isRequired,
        setDetailsStep: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        selectedPaymentCode: PropTypes.string.isRequired
    };

    static defaultProps = {
        clientId: 'sb',
        isSandboxEnabled: false
    };

    // componentDidMount() {
    //     const script = document.getElementById(PAYPAL_SCRIPT);
    //     script.onload = () => this.forceUpdate();
    // }

    containerProps = () => ({
        paypal: this.getPayPal(),
        environment: this.getEnvironment(),
        isDisabled: this.getIsDisabled(),
        scriptLoaded: this.scriptLoaded.bind(this)

    });

    scriptLoaded(isLoadedScript) {
        this.setState({ isLoadedScript });
    }

    getIsDisabled = () => {
        const { selectedPaymentCode } = this.props;
        return selectedPaymentCode !== PAYPAL_EXPRESS;
    };

    containerFunctions = () => ({
        onError: this.onError,
        onCancel: this.onCancel,
        onApprove: this.onApprove,
        createOrder: this.createOrder
    });

    onApprove = async (data) => {
        const { showNotification, setDetailsStep } = this.props;
        const { orderID = '', payerID = '' } = data;
        const guest_cart_id = this._getGuestQuoteId();

        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                guest_cart_id,
                payment_method: {
                    code: 'paypal_express',
                    paypal_express: {
                        token: orderID,
                        payer_id: payerID
                    },
                    extension_attributes: {
                        agreement_ids: ['1']
                    }
                }
            }));

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
            const { placeOrder: { order: { order_id, base_encode } } } = orderData;
            setDetailsStep(order_id);

            history.push(`/checkout/onepage/success?data=${base_encode}&type=${PAYPAL_EXPRESS}`);
        } catch (e) {
            showNotification('error', 'Something went wrong');
        }
    };

    onCancel = (data) => {
        const { showNotification, setLoading } = this.props;
        setLoading(false);
        showNotification('info', 'Your payment has been canceled', data);
    };

    onError = (err) => {
        const { showNotification, setLoading } = this.props;
        let errorMessage = __('Some error appeared with PayPal');
        if (err && err.length > 0 && err[0].message) {
            errorMessage = err[0].message;
        }
        setLoading(false);
        showNotification('error', errorMessage);
    };

    getPayPal = () => {
        const { paypal } = window;
        return paypal || false;
    };

    getEnvironment = () => {
        const { isSandboxEnabled } = this.props;
        return isSandboxEnabled ? 'sandbox' : 'production';
    };

    _findKeyByNameAndValue = (arrayOfObjects, targetKey, targetValue) => {
        for (const obj of arrayOfObjects) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(targetKey) && obj[targetKey] === targetValue) {
                return obj; // Return the key if both key and value match
            }
        }

        return null; // Return null if no matching key is found
    };

    _saveGuestEmail(email, guestCartId) {
        if (!guestCartId) {
            return null;
        }
        const mutation = CheckoutQuery.getSaveGuestEmailMutation(email, guestCartId);

        return fetchMutation(mutation).then(
            /** @namespace Paypal/Component/PayPal/Container/fetchMutation/then */
            ({ setGuestEmailOnCart: data }) => {
                if (data) {
                    this.setState({ isGuestEmailSaved: true });
                }

                return data;
            },
            console.log('Error')
        );
    }

    createOrder = async () => {
        const { setLoading, selectedPaymentCode, cartTotals } = this.props;
        const { is_virtual } = cartTotals;
        const guest_cart_id = this._getGuestQuoteId();

        setLoading(true);

        const getElementValue = (elementId) => {
            const element = document.getElementById(elementId);

            if (element) {
                return element.value || '';
            }
            console.error(`Element with ID '${elementId}' not found.`);
            return '';
        };

        const setBillingAddress = async (billingAddress) => {
            await fetchMutation(CheckoutQuery.getSetBillingAddressOnCart({
                guest_cart_id,
                same_as_shipping: false,
                billing_address: billingAddress
            }));
        };

        const guestEmail = getElementValue('guest_email');

        if (isSignedIn()) {
            const customerDetail = localStorage.getItem('customer');

            const parseCustomerDetail = JSON.parse(customerDetail);

            const { data: { addresses = [] } } = parseCustomerDetail;

            const userBillingAdress = this._findKeyByNameAndValue(addresses, 'default_billing', true);

            if (userBillingAdress && !isEmpty(userBillingAdress)) {
                const {
                    city, company, country_id, firstname, lastname, postcode, telephone, vat_id, region: { region_id }, street = []
                } = userBillingAdress;

                // eslint-disable-next-line no-unused-vars
                const billingAddress = {
                    address: {
                        city,
                        company,
                        country_code: country_id,
                        firstname,
                        lastname,
                        street: street[0],
                        region: region_id,
                        postcode,
                        telephone,
                        vat_id
                    }
                };

                setBillingAddress(billingAddress);
            }
        } else if (is_virtual && guestEmail) {
            await this._saveGuestEmail(guestEmail, guest_cart_id);
        }

        const billingSteetValue = getElementValue('street0');
        // eslint-disable-next-line no-unused-vars
        const billingSteet1Value = getElementValue('street1');
        const billingPostcodeValue = getElementValue('postcode');
        const billingFirstNameValue = getElementValue('firstname');
        const billingLastNameValue = getElementValue('lastname');
        const billingCityValue = getElementValue('city');
        const billingStateValue = getElementValue('region_id');
        const billingCountryValue = getElementValue('country_id');
        const billingVatValue = getElementValue('vat_id');
        const billingCompanyValue = getElementValue('company');
        const billingPhoneValue = getElementValue('telephone');

        if (billingFirstNameValue || billingCityValue || billingStateValue || billingPhoneValue || billingCountryValue || billingPostcodeValue) {
            const billingAddress = {
                address: {
                    city: billingCityValue,
                    company: billingCompanyValue,
                    country_code: billingCountryValue,
                    firstname: billingFirstNameValue,
                    lastname: billingLastNameValue,
                    street: billingSteetValue,
                    region: billingStateValue,
                    postcode: billingPostcodeValue,
                    telephone: billingPhoneValue,
                    vat_id: billingVatValue
                }
            };

            setBillingAddress(billingAddress);
        }

        const {
            paypalExpress: { token }
        } = await fetchMutation(PayPalQuery.getCreatePaypalExpressTokenMutation({
            guest_cart_id,
            express_button: false,
            code: selectedPaymentCode,
            // use_paypal_credit: this.getIsCredit(),
            urls: {
                cancel_url: 'www.paypal.com/checkoutnow/error',
                return_url: 'www.paypal.com/checkoutnow/error'
            }
        }));

        return token;
    };

    _getGuestQuoteId = () => (isSignedIn() ? '' : getGuestQuoteId());

    render() {
        return (
            <PayPal
              { ...this.props }
              { ...this.state }
              { ...this.containerProps() }
              { ...this.containerFunctions() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayPalContainer);
