/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PostalCodeLookupQuery from 'Query/PostalCodeLookup.query';
import { getPostalCodeLookUp } from 'Store/PostalCodeLookUp/PostalCodeLookUp.action';
import { QueryDispatcher } from 'Util/Request';

/**
 * PostalCodeLookUp  Dispatcher
 * @class PostalCodeLookUpDispatcher
 * @extends QueryDispatcher
 * @namespace Pwa/Store/PostalCodeLookUp/Dispatcher/PostalCodeLookUpDispatcher
 */
export class PostalCodeLookUpDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess(data, dispatch) {
        const { fsyncLocation = {} } = data || {};
        const { suggestion = [] } = fsyncLocation || {};

        const city = suggestion != null ? suggestion : [];
        dispatch(getPostalCodeLookUp({ city }));
    }

    onError(_, dispatch) {
    }

    /**
     * Prepare query
     * @return {Query}  query
     * @memberof PostalCodeLookUpDispatcher
     */
    prepareRequest(options, dispatch) {
        dispatch(getPostalCodeLookUp({}));
        return PostalCodeLookupQuery.getQuery({ ...options });
    }
}
export default new PostalCodeLookUpDispatcher();
