/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import {
    SAVE_COMPANY_DATA,
    SET_COMPANY_EDITMODE,
    SET_COMPANY_LOADING,
    UPDATE_COMPANY_DATA
} from './CompanyAccount.action';

export const initialState = {
    company: {},
    isLoading: true,
    editMode: false
};

/** @namespace Pwa/Store/CompanyAccount/Reducer/CompanyReducer */
export const CompanyReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        company = {},
        editMode
    } = action;

    const list = state.data;

    switch (type) {
    case UPDATE_COMPANY_DATA:
        return {
            ...state,
            isLoading,
            editMode: false,
            company
        };
    case SET_COMPANY_EDITMODE:
        return {
            ...state,
            editMode
        };
    case SET_COMPANY_LOADING:
        return {
            ...state,
            isLoading
        };
    case SAVE_COMPANY_DATA:
        const updated_company_profile = {};
        return {
            ...state,
            customer_interest: updated_company_profile,
            isLoading
        };
    default:
        return state;
    }
};

export default CompanyReducer;
