/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';

import { CookieInfoType } from '../../type/GdprCookie';
import { GDPR_COOKIES_POPUP_ID } from '../GdprCookiePopup/GdprCookiePopup.config';
import GdprCookieGroupPopup from './GdprCookieGroupPopup.component';

/** @namespace Pwa/Component/GdprCookieGroupPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cookieInfo: state.CookiesReducer.cookieInfo,
    isCookieBarActive: state?.ConfigReducer?.gdpr_cookie_bar
});

/** @namespace Pwa/Component/GdprCookieGroupPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(GDPR_COOKIES_POPUP_ID, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Pwa/Component/GdprCookieGroupPopup/Container/GdprCookieGroupPopupContainer */
export class GdprCookieGroupPopupContainer extends PureComponent {
    static propTypes = {
        cookieInfo: CookieInfoType.isRequired,
        showPopup: PropTypes.func.isRequired,
        isCookieBarActive: PropTypes.bool.isRequired
    };

    containerFunctions = {
        handleClosePopup: this.handleClosePopup.bind(this)
    };

    handleClosePopup() {
        const { showPopup } = this.props;

        showPopup();
        document.documentElement.classList.add('scrollDisabled');
    }

    render() {
        const { isCookieBarActive, cookieInfo } = this.props;
        return (
            <GdprCookieGroupPopup
              isCookieBarActive={ isCookieBarActive }
              cookieInfo={ cookieInfo }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GdprCookieGroupPopupContainer);
