import { SET_FREE_SHIPPING_AVAILABLE } from './Shipping.action';

export const initialState = {
    isFreeShippingAvailable: true // Set to true initially and determine true state at checkout
};

/** @namespace Pwa/Store/Shipping/Reducer/ShippingReducer */
export const ShippingReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_FREE_SHIPPING_AVAILABLE:
        return {
            ...state,
            isFreeShippingAvailable: action.payload
        };
    default:
        return state;
    }
};

export default ShippingReducer;
