/* eslint-disable no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import OggGraphQuery from 'Query/OggGraph.query';
import ProductListQuery from 'Query/ProductList.query';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateQuickItemDetails } from 'Store/QuickOrder/QuickOrder.action';
import { isSignedIn } from 'Util/Auth';
import { getCurrentUrlForOggData } from 'Util/Helper';
import { fetchMutation, QueryDispatcher } from 'Util/Request';

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkedProducts/LinkedProducts.dispatcher'
);

/**
 * Product List Dispatcher
 * @class ProductDispatcher
 * @extends ProductDispatcher
 * @namespace Pwa/Store/QuickOrder/Dispatcher/QuickOrderDispatcher */
export class QuickOrderDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct('Product');
    }

    onSuccess(data, dispatch) {
        const { products: { items } } = data;
        const { additionalInformation = {}, oggraphData = {} } = data;
        /**
         * In case there are no items, or item count is
         * smaller then 0 => the product was not found.
         */
        if (!items || items.length <= 0) {
            dispatch(updateNoMatch(true));
            return;
        }

        const product_links = items.reduce((links, product) => {
            const { product_links } = product;

            if (product_links) {
                Object.values(product_links).forEach((item) => {
                    links.push(item);
                });
            }

            return links;
        }, []);

        LinkedProductsDispatcher.then(
            ({ default: dispatcher }) => {
                if (product_links.length > 0) {
                    dispatcher.handleData(dispatch, product_links);
                } else {
                    dispatcher.clearLinkedProducts(dispatch);
                }
            }
        );

        items[0] = { ...items[0], additionalInformation, oggraphData };
        const [product] = items;
        dispatch(updateQuickItemDetails(product));
    }

    onError(_, dispatch) {
        dispatch(updateNoMatch(true));
    }

    prepareRequest(options) {
        const loginStatus = isSignedIn();
        const current_url = getCurrentUrlForOggData();

        const currentSku = options.args.filter.productSKU;
        return [
            ProductListQuery.getQuery({ ...options, isSignedIn: loginStatus }),
            ProductListQuery.getAdditionalProductInfo({ ...options, isSignedIn: loginStatus }),
            OggGraphQuery.getQuery({ current_url, type: 'PRODUCT', sku: currentSku })
        ];
    }

    submitProductQA(dispatch, options) {
        return fetchMutation(ProductListQuery.getAddProductQAMutation(
            options
        )).then(
            /** @namespace Pwa/Store/QuickOrder/Dispatcher/fetchMutation/then */
            () => dispatch(showNotification('success', 'You submitted your Question for moderation.')),
            /** @namespace Pwa/Store/QuickOrder/Dispatcher/fetchMutation/then */
            (error) => dispatch(showNotification('error', 'Error submitting question!')) && console.log(error)
        );
    }

    emailFriend(dispatch, options) {
        return fetchMutation(ProductListQuery.emailFriend(
            options
        )).then(
            /** @namespace Pwa/Store/QuickOrder/Dispatcher/fetchMutation/then */
            () => dispatch(showNotification('success', 'You submitted your Question for moderation.')),
            /** @namespace Pwa/Store/QuickOrder/Dispatcher/fetchMutation/then */
            (error) => dispatch(showNotification('error', 'Error submitting question!')) && console.log(error)
        );
    }
}

export default new QuickOrderDispatcher();
