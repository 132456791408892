/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import {
    SAVE_COMPANY_STRUCTURE_DATA,
    SET_COMPANY_STRUCTURE_LOADING,
    SET_COMPANY_STRUCTURE_UPDATE_ACTION,
    UPDATE_COMPANY_STRUCTURE_DATA
} from './CompanyStructure.action';

export const initialState = {
    company: {},
    isLoading: true,
    openPopup: false,
    popup_type: ''
};

/** @namespace Pwa/Store/CompanyStructure/Reducer/CompanyStructureReducer */
export const CompanyStructureReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        companyStructure,
        openPopup,
        popup_type
    } = action;

    const list = state.data;

    switch (type) {
    case UPDATE_COMPANY_STRUCTURE_DATA:
        return {
            ...state,
            isLoading,
            companyStructure
        };
    case SET_COMPANY_STRUCTURE_LOADING:
        return {
            ...state,
            isLoading
        };
    case SET_COMPANY_STRUCTURE_UPDATE_ACTION:
        return {
            ...state,
            openPopup,
            popup_type
        };
    case SAVE_COMPANY_STRUCTURE_DATA:
        const updated_company_structure = {};
        return {
            ...state,
            customer_interest: updated_company_structure,
            isLoading
        };
    default:
        return state;
    }
};

export default CompanyStructureReducer;
