/* eslint-disable no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_TERMS_AND_CONDITIONS_CHECKBOX } from './TermsAndConditions.action';

export const initialState = {
    isSatisfied: false
};

/** @namespace Pwa/Store/TermsAndConditions/Reducer/TermsAndConditionsReducer */
export const TermsAndConditionsReducer = (state = initialState, action) => {
    const {
        type,
        isSatisfied
    } = action;

    switch (type) {
    case UPDATE_TERMS_AND_CONDITIONS_CHECKBOX:

        return {
            ...state,
            isSatisfied
        };

    default:
        return state;
    }
};

export default TermsAndConditionsReducer;
