/* eslint-disable prefer-template */
/* eslint-disable no-magic-numbers */
/* eslint-disable fp/no-let */
/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-console */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import AccordionWrapper from 'Component/AccordionWrapper';
import CmsBlock from 'Component/CmsBlock';
import { MENU, MENU_SUBCATEGORY } from 'Component/Header/Header.config';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import Overlay from 'Component/Overlay';
import VehicleFinder from 'Component/VehicleFinder';
import CategoryPage from 'Route/CategoryPage';
import CmsPage from 'Route/CmsPage';
import NoMatch from 'Route/NoMatch';
import ProductPage from 'Route/ProductPage';
import { HistoryType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';
import { MenuType } from 'Type/Menu.type';
import { isSignedIn } from 'Util/Auth';
import { isEurStore } from 'Util/FrontRunner/Store';
import { getBlackFridayPromo, getSalesPromotion, getXmasPromo } from 'Util/FrontRunner/Store/Promotions';
import { scrollToTop } from 'Util/Helper';
import isMobile from 'Util/Mobile';

import './MenuOverlay.style';

export const TYPE_PRODUCT = 'PRODUCT';
export const TYPE_CMS_PAGE = 'CMS_PAGE';
export const TYPE_CATEGORY = 'CATEGORY';
export const TYPE_NOTFOUND = 'NOT_FOUND';

/** @namespace Pwa/Component/MenuOverlay/Component/MenuOverlay */
export class MenuOverlay extends PureComponent {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        history: HistoryType,
        device: DeviceType.isRequired
    };

    state = { activeMenuItemsStack: [] };

    // closeMenuOverlay = this.closeMenuOverlay.bind(this);

    closeMenuOverlay = () => {
        const { onHeaderOverMenuClick } = this.props;
        scrollToTop();
        onHeaderOverMenuClick();
    };

    onVisible = () => {
        const { changeHeaderState } = this.props;
        changeHeaderState({ name: MENU });
    };

    showSubCategory(e, activeSubcategory) {
        const { activeMenuItemsStack } = this.state;
        const { changeHeaderState, goToPreviousHeaderState } = this.props;
        const { item_id, title } = activeSubcategory;

        e.stopPropagation();
        changeHeaderState({
            name: MENU_SUBCATEGORY,
            force: true,
            title,
            onBackClick: () => {
                this.setState(({ activeMenuItemsStack }) => (
                    { activeMenuItemsStack: activeMenuItemsStack.slice(1) }
                ));
                goToPreviousHeaderState();
            }
        });

        if (!activeMenuItemsStack.includes(item_id)) {
            this.setState({ activeMenuItemsStack: [item_id, ...activeMenuItemsStack] });
        }
    }

    closeMenuOverlay(e) {
        const { hideActiveOverlay } = this.props;

        e.stopPropagation();

        this.setState({ activeMenuItemsStack: [] });
        hideActiveOverlay();
    }

    renderItemContent(item, mods = {}) {
        const { title, icon, item_class } = item;
        const itemMods = item_class === 'MenuOverlay-ItemFigure_type_banner' ? { type: 'banner' } : mods;

        return (
            <figure block="MenuOverlay" elem="ItemFigure" mods={ itemMods }>
                <Image
                  mix={ { block: 'MenuOverlay', elem: 'Image', mods: itemMods } }
                  src={ icon && `/media/${icon}` }
                  ratio="16x9"
                  arePlaceholdersShown
                />
                <figcaption
                  block="MenuOverlay"
                  elem="ItemCaption"
                  mods={ itemMods }
                >
                    { title }
                </figcaption>
            </figure>
        );
    }

    renderSubLevel(category) {
        const { activeMenuItemsStack } = this.state;
        const { item_id, children } = category;
        const childrenArray = Object.values(children);
        const isVisible = activeMenuItemsStack.includes(item_id);
        const subcategoryMods = { type: 'subcategory' };
        return (
            <div
              block="MenuOverlay"
              elem="ItemList"
              mods={ { ...subcategoryMods, isVisible } }
            >
                { childrenArray.map((item) => {
                    const { url, item_id, children } = item;
                    const childrenArray = Object.values(children);

                    return (childrenArray.length
                        ? (
                            <div
                              key={ item_id }
                              onClick={ (e) => this.showSubCategory(e, item) }
                              tabIndex="0"
                              role="button"
                            >
                                { this.renderItemContent(item, subcategoryMods) }
                                { this.renderSubLevel(item) }
                            </div>
                        ) : (
                            <Link
                              key={ item_id }
                              to={ url }
                              onClick={ this.closeMenuOverlay }
                              block="MenuOverlay"
                              elem="Link"
                            >
                                { this.renderItemContent(item, subcategoryMods) }
                            </Link>
                        )
                    );
                }) }
            </div>
        );
    }

    renderFirstLevel(itemList, itemMods) {
        return Object.values(itemList).map((item) => {
            const { item_id, children, url } = item;
            const childrenArray = Object.values(children);

            return (
                <li key={ item_id } block="MenuOverlay" elem="Item">
                    { childrenArray.length
                        ? (
                            <div
                              onClick={ (e) => this.showSubCategory(e, item) }
                              tabIndex="0"
                              role="button"
                            >
                                { this.renderItemContent(item, itemMods) }
                                { this.renderSubLevel(item) }
                            </div>
                        ) : (
                            <Link
                              to={ url }
                              onClick={ this.closeMenuOverlay }
                              block="MenuOverlay"
                              elem="Link"
                            >
                                { this.renderItemContent(item, itemMods) }
                            </Link>
                        ) }
                </li>
            );
        });
    }

    renderAdditionalInformation() {
        return (
            <aside block="MenuOverlay" elem="AdditionalInformation">
                <h3 block="MenuOverlay" elem="PageLink">
                    <Link
                      to="/page/about-us"
                      onClick={ this.closeMenuOverlay }
                      block="MenuOverlay"
                      elem="Link"
                    >
                        { __('ABOUT US') }
                    </Link>
                </h3>
                <h3 block="MenuOverlay" elem="PageLink">
                    <Link
                      to="/page/about-us"
                      onClick={ this.closeMenuOverlay }
                      block="MenuOverlay"
                      elem="Link"
                    >
                    { __('CONTACTS') }
                    </Link>
                </h3>
                <div block="MenuOverlay" elem="Social">
                    <CmsBlock identifiers={ ['social-links'] } />
                </div>
            </aside>
        );
    }

    renderTopLevel() {
        const { menu } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }

        const {
            0: { children: mainCategories, title: mainCategoriesTitle },
            1: { children: trendingCategories, title: trendingCategoriesTitle }
        } = categoryArray;

        const mainMods = { type: 'main' };
        const trendingMods = { type: 'trending' };

        return (
            <div block="MenuOverlay" elem="Menu">
                <ul
                  block="MenuOverlay"
                  elem="ItemList"
                  mods={ mainMods }
                  aria-label={ mainCategoriesTitle }
                >
                    { this.renderFirstLevel(mainCategories, mainMods) }
                </ul>
                <ul
                  block="MenuOverlay"
                  elem="ItemList"
                  mods={ trendingMods }
                  aria-label={ trendingCategoriesTitle }
                >
                    <li block="MenuOverlay" elem="ItemListHeading">
                        { trendingCategoriesTitle }
                    </li>
                    { this.renderFirstLevel(trendingCategories, trendingMods) }
                </ul>
                { this.renderAdditionalInformation() }
            </div>
        );
    }

    renderVehicleFinder = (key, title) => {
        const accordionData = [{
            title,
            child: (<VehicleFinder />)
        }];

        return (
        <AccordionWrapper
          key={ key }
          allowMultipleExpanded
          data={ accordionData }
        />
        );
    };

    renderMobileMenu = (data, key) => {
        const {
            title, url, color, text_color, type, child, url_resolver = {}
        } = data;
        const { onHeaderOverMenuClick } = this.props;
        if (!isMobile.any()) {
            return null;
        }

        if (type === 'vehicle_finder') {
            return this.renderVehicleFinder(key, title);
        }

        const internalStyle = isEmpty(text_color) ? {} : { color: text_color };
        let signCls = '';
        if (isSignedIn()) {
            signCls = 'Sn';
        }
        if (isEmpty(child) && !isEmpty(url)) {
            return (
                <div key={ key } style={ internalStyle } className={ `${type} ${signCls}` }>
                    { this.handleActionView(title, key, type, url, url_resolver) }
                </div>
            );
        }

        if (isEmpty(child)) {
            return (
                <div key={ key } style={ internalStyle }>
                    { title }
                </div>
            );
        }

        const accordionData = [{
            title,
            url,
            child: map(child, this.renderMobileMenu),
            borderLeftColor: color,
            url_resolver
        }];

        return (
            <AccordionWrapper
              key={ key }
              allowMultipleExpanded
              data={ accordionData }
              onHeaderOverMenuClick={ onHeaderOverMenuClick }
            />
        );
    };

    handleActionView = (title, key, type, url, url_resolver = {}) => {
        // if((url_resolver) && !isEmpty(url_resolver)){
        //   return(<div onClick={()=> { this.renderPage(url_resolver)}}>{title}</div>)

        // }else{

        // Check if B2B customer (Use local storage check)
        const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        let itemTitle = title;

        if (url.includes('http') && !url.includes('gearzette')) {
            if (url === '/http://frontrunnerpride.com') {
                return (
                    <a className="MenuOverlay-Link menu_link_ex" href="http://frontrunnerpride.com" rel="nofollow noopener noreferrer" onClick={ this.closeMenuOverlay } key={ key } target="_blank">
                        { itemTitle }
                    </a>
                );
            } if (url === 'https://m.youtube.com/user/FrontRunnerOutfitter') {
                return (
                    <a className="MenuOverlay-Link menu_link_ex" href="https://m.youtube.com/user/FrontRunnerOutfitter" rel="noopener noreferrer" onClick={ this.closeMenuOverlay } key={ key } target="_blank">
                        { itemTitle }
                    </a>
                );
            }

            return (
                <a className="MenuOverlay-Link menu_link_ex" href={ url } onClick={ this.closeMenuOverlay } key={ key } target="_blank">
                    { itemTitle }
                </a>
            );
        }
        // eslint-disable-next-line fp/no-let
        let aUrl = url;
        if (url.includes('gearzette')) {
            aUrl = '/gearzette';
        }

        // Deals link
        if (type === 'deals') {
            aUrl = '/deals';
            if (isB2B) {
                aUrl = '/deals/b2b';
            }

            // Is Black Friday Promotion?
            const blackFridayPromo = getBlackFridayPromo();
            const blackFridayPromoActive = blackFridayPromo?.active ?? false;
            if (blackFridayPromoActive) {
                itemTitle = 'Black Friday Deals';
                if (isEurStore()) {
                    itemTitle = 'Cyber Deals';
                }
            }

            // Is Xmas Promotion?
            // const xmasPromo = getXmasPromo();
            // const xmasPromoActive = xmasPromo?.active ?? false;
            // if (xmasPromoActive) {
            //     itemTitle = 'Xmas Deals';
            //     if (isEurStore()) {
            //         itemTitle = 'Xmas Deals';
            //     }
            // }

            return (
                <RouterLink
                  key={ key }
                  to={ {
                      pathname: aUrl,
                      state: {
                          url_resolver
                      }
                  } }
                  onClick={ this.closeMenuOverlay }
                  className={ `MenuOverlay-Link menu_link menu_${type}` }
                >
                    <div className="deals-badge-container">
                        { itemTitle }
                    </div>
                </RouterLink>
            );
        }

        return (
            <RouterLink
              key={ key }
              to={ {
                  pathname: aUrl,
                  state: {
                      url_resolver
                  }
              } }
              onClick={ this.closeMenuOverlay }
              className={ `MenuOverlay-Link menu_link menu_${type}` }
            >
                { title }
            </RouterLink>
        );

        // }
    };

    renderPage({ type, id, url_key }) {
        const { props } = this;

        const { hideActiveOverlay } = this.props;

        // eslint-disable-next-line no-undef
        e.stopPropagation();

        this.setState({ activeMenuItemsStack: [] });
        hideActiveOverlay();

        switch (type) {
        case TYPE_PRODUCT:
            const newRoute = {
                ...props,
                location: {
                    ...props.location,
                    pathname: `/${ url_key }`
                }

            };

            return <ProductPage { ...newRoute } />;
        case TYPE_CMS_PAGE:
            return <CmsPage { ...props } urlKey={ url_key } id={ id } />;
        case TYPE_CATEGORY:
            return <CategoryPage { ...props } categoryIds={ id } />;
        case TYPE_NOTFOUND:
            return <NoMatch { ...props } />;
        default:

            return (
                <main style={ { height: '400px' } }>
                    <Loader isLoading />
                </main>
            );
        }
    }

    renderLoginRegisterLink() {
        if (!isSignedIn()) {
            return (
                <div>
                    <Link className="MenuOverlay-Link menu_link" to="/account/login" onClick={ this.closeMenuOverlay }>
                        { __('Login / Register') }
                    </Link>
                </div>
            );
        }

        return null;
    }

    render() {
        const { mobileMenu, device } = this.props;

        const salesPromotion = getSalesPromotion();
        const salesPromotionActive = salesPromotion?.active ?? false;

        if (salesPromotionActive) {
            // Check if mobileMenu already has Deals link
            const dealsLinkExists = mobileMenu.some((link) => link.type === 'deals');

            if (!dealsLinkExists) {
                // Add Deals link to the mobile menu in third position
                const dealsLink = {
                    child: null,
                    color: '#fff',
                    text_color: null,
                    title: __('Deals'),
                    type: 'deals',
                    url: '/deals'
                };

                mobileMenu.splice(5, 0, dealsLink);
            }
        }

        return (
            <Overlay
              id="menu"
              mix={ { block: 'MenuOverlay' } }
              onVisible={ this.onVisible }
              isStatic={ !!device.isMobile }
            >
                { this.renderLoginRegisterLink() }
                { !isEmpty(mobileMenu) && map(mobileMenu, this.renderMobileMenu) }
            </Overlay>
        );
    }
}

export default MenuOverlay;
