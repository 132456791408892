/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable eqeqeq */
/* eslint-disable guard-for-in */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isEmpty } from 'lodash';

import {
    ProductConfigurableAttributesContainer as SourceProductConfigurableAttributesContainer
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container';

import ProductConfigurableAttributes from './ProductConfigurableAttributes.component';
/** @namespace Pwa/Component/ProductConfigurableAttributes/Container/mapStateToProps */
export const mapStateToProps = (state) => ({

});

/** @namespace Pwa/Component/ProductConfigurableAttributes/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({

});

/** @namespace Pwa/Component/ProductConfigurableAttributes/Container/ProductConfigurableAttributesContainer */
export class ProductConfigurableAttributesContainer extends SourceProductConfigurableAttributesContainer {
    __construct(props) {
        super.__construct(props);

        this.state = {
            isEnable: this._getDropDownStatus(),
            selectedIdList: [],
            selectedValueList: [],
            resetAlredy: false,
            dropDownCount: 0
        };
    }

    addIdToIdList(id, index, value) {
        const { selectedIdList, dropDownCount, selectedValueList } = this.state;
        const { setAddToCartEnable } = this.props;
        selectedIdList[index] = id;
        selectedValueList[index] = value;
        this.setState({ selectedIdList, resetAlredy: false });
        if (selectedIdList.length === dropDownCount) {
            setAddToCartEnable(true);
        } else {
            setAddToCartEnable(false);
        }
    }

    containerFunctions = {
        handleOptionClick: this.handleOptionClick.bind(this),
        getSubHeading: this.getSubHeading.bind(this),
        isSelected: this.isSelected.bind(this),
        getLink: this.getLink.bind(this),
        getIsConfigurableAttributeAvailable: this.getIsConfigurableAttributeAvailable.bind(this),
        addIdToIdList: this.addIdToIdList.bind(this),
        enableDropDown: this.enableDropDown.bind(this),
        selectAndSetValue: this.selectAndSetValue.bind(this)
    };

    componentDidUpdate() {
        // const { resetDropDown } = this.props;
        // if () {
        this.resetDropDown();
        // }
    }

    _getDropDownStatus() {
        const { configurable_options } = this.props;
        if (!configurable_options) {
            return [];
        }
        const stateList = [];
        for (const index in Object.entries(configurable_options)) {
            if (index == 0) {
                stateList.push(true);
            } else {
                stateList.push(false);
            }
        }

        return stateList;
    }

    selectAndSetValue(value) {

    }

    enableDropDown(index) {
        let { isEnable = [], dropDownCount } = this.state;
        const { configurable_options } = this.props;
        if (dropDownCount === 0) {
            dropDownCount = Object.entries(configurable_options).length;
            this.setState({ dropDownCount });
        }
        if (index < isEnable.length) {
            isEnable[index] = true;
            this.setState({ isEnable });
        }
    }

    resetDropDown() {
        const { resetDropDown, setAddToCartEnable } = this.props;
        const { selectedIdList, isEnable, resetAlredy } = this.state;
        // this.setState({ resetAlredy: true });

        if (resetDropDown && !isEmpty(selectedIdList) && !resetAlredy) {
            if (!isEmpty(isEnable)) {
                for (const value in isEnable) {
                    if (value == 0) {
                        isEnable[value] = true;
                    } else {
                        isEnable[value] = false;
                    }
                }
                setAddToCartEnable(false);
                this.setState({
                    selectedIdList: [],
                    selectedValueList: [
                    ],
                    isEnable,
                    resetAlredy: true
                });
            }
        }
    }

    render() {
        return (
        <ProductConfigurableAttributes
          { ...this.props }
          { ...this.state }
          { ...this.containerFunctions }
        />
        );
    }
}

export default ProductConfigurableAttributesContainer;
