/* eslint-disable new-cap */
import { connect } from 'react-redux';

import {
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.container';

import Breadcrumbs from './Breadcrumbs.component';

/** @namespace Pwa/Component/Breadcrumbs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    category: state.CategoryReducer.category,
    product: state.ProductReducer.product
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/Breadcrumbs/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

// eslint-disable-next-line no-undef
export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
