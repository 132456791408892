/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_PRODUCT_DETAILS = 'UPDATE_PRODUCT_DETAILS';
export const UPDATE_PRODUCT_ADDITIONAL_INFO = 'UPDATE_PRODUCT_ADDITIONAL_INFO';
export const IS_LOADING = 'IS_LOADING';

/**
  * Update product list with new list (rewrite if already exists).
  * @param  {Array<Object>} items List of products returned from fetch
  * @param  {Number} totalItems Total number of products in this filter
  * @return {void}
  * @namespace Pwa/Store/Product/Action/updateProductDetails  */
export const updateProductDetails = (product) => ({
    type: UPDATE_PRODUCT_DETAILS,
    product
});
/** @namespace Pwa/Store/Product/Action/updateProductAdditinoalInfo */
export const updateProductAdditinoalInfo = (additionalData) => ({
    type: UPDATE_PRODUCT_ADDITIONAL_INFO,
    additionalData
});
/** @namespace Pwa/Store/Product/Action/isLoading */
export const isLoading = (value) => ({
    type: IS_LOADING,
    value
});
