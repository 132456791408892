/* eslint-disable import/no-duplicates */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { APPLE_PAY } from 'Component/CheckoutPayments/CheckoutPayments.config';
import { GOOGLE_PAY } from 'Component/CheckoutPayments/CheckoutPayments.config';
import {
    GIROPAY,
    KLARNA, SOFORT, STRIPE,
    STRIPE_KLARNA
} from 'Component/CheckoutPayments/CheckoutPayments.config';

import Stripe from '../component/Stripe';

export class CheckoutPaymentsPlugin {
    aroundPaymentRenderMap = (originalMember, instance) => ({
        ...originalMember,
        [STRIPE]: this.renderStripePayment.bind(instance),
        [APPLE_PAY]: this.renderStripePayment.bind(instance),
        [GOOGLE_PAY]: this.renderStripePayment.bind(instance),
        [GIROPAY]: this.renderStripePayment.bind(instance),
        [KLARNA]: this.renderStripePayment.bind(instance),
        [STRIPE_KLARNA]: this.renderStripePayment.bind(instance),
        [SOFORT]: this.renderStripePayment.bind(instance)
    });

    renderStripePayment() {
        const {
            billingAddress,
            setStripeRef,
            paymentTotals,
            totals: cartTotals,
            onPaymentMethod,
            selectedPaymentCode,
            onBillingSuccess
        } = this.props;

        const totals = Object.keys(paymentTotals).length ? paymentTotals : cartTotals;

        return (
            <Stripe
              billingAddress={ billingAddress }
              setStripeRef={ setStripeRef }
              paymentTotals={ totals }
              onPaymentMethod={ onPaymentMethod }
              selectedPaymentCode={ selectedPaymentCode }
              onBillingSuccess={ onBillingSuccess }
            />
        );
    }
}

const {
    aroundPaymentRenderMap
} = new CheckoutPaymentsPlugin();

export const config = {
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap: aroundPaymentRenderMap
        }
    }
};

export default config;
