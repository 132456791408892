/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import {
    SAVE_COMPANY_STATEMENTS_DATA,
    SET_COMPANY_STATEMENTS_LOADING,
    UPDATE_COMPANY_STATEMENTS_DATA
} from './CompanyStatements.action';

export const initialState = {
    companyStatements: {},
    isLoading: true
};

/** @namespace Pwa/Store/CompanyStatements/Reducer/CompanyStatementsReducer */
export const CompanyStatementsReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        companyStatements
    } = action;

    const list = state.data;

    switch (type) {
    case UPDATE_COMPANY_STATEMENTS_DATA:
        return {
            ...state,
            isLoading,
            companyStatements
        };
    case SET_COMPANY_STATEMENTS_LOADING:
        return {
            ...state,
            isLoading
        };
    case SAVE_COMPANY_STATEMENTS_DATA:
        const updated_company_profile = {};
        return {
            ...state,
            customer_interest: updated_company_profile,
            isLoading
        };
    default:
        return state;
    }
};

export default CompanyStatementsReducer;
