/* eslint-disable react/sort-comp */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-constant-condition */
/* eslint-disable fp/no-let */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

import SidebarComponent from './Sidebar.component';

/** @namespace Pwa/Component/SidebarMenu/Sidebar/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    menu: state.HeaderAndFooterReducer.desktopMenu,
    isDataLoading: state.VehicleFinderReducer.isProductLoading,
    freeShippingHeaderText: state.ConfigReducer.free_shipping_header_text,
    free_shipping_header_text_b2b: state.ConfigReducer.free_shipping_header_text_b2b,
    is_free_shipping_enable: state.ConfigReducer.is_free_shipping_enable
});

/** @namespace Pwa/Component/SidebarMenu/Sidebar/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state))
});

/** @namespace Pwa/Component/SidebarMenu/Sidebar/Container/SidebarContainer */
export class SidebarContainer extends PureComponent {
    static propTypes = {
        sideMenuData: PropTypes.array.isRequired,
        category: PropTypes.any
    };

    componentDidMount() {
        const { sideMenuData } = this.props;

        this.setUpCategories(sideMenuData);
    }

    setUpCategories(sideMenuData) {
        const categoryArray = Object.values(sideMenuData);
        if (!categoryArray.length) {
            return;
        }

        let copySlideMenu = Array.from(sideMenuData);
        copySlideMenu = copySlideMenu.map((mItem) => {
            if (mItem.sub_category && mItem.sub_category.length === 1) {
                const { category_id, sub_category } = mItem;
                if (category_id === sub_category[0].category_id) {
                    return ({ ...mItem, sub_category: [] });
                }
            }

            return ({ ...mItem });
        });

        const moreCat = copySlideMenu.pop();
        if (moreCat.name == 'More' || 'Mehr' || 'Plus' || 'Meer' || 'Más') {
            copySlideMenu = copySlideMenu.concat(moreCat.sub_category);
        }
        this.setState({ copySlideMenu });
    }

    componentWillReceiveProps(nextProps) {
        this.setUpCategories(nextProps.sideMenuData);
    }

    render() {
        return (
            <SidebarComponent
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer);
