/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { isPossiblePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

export const MIN_PASSWORD_LENGTH = 8;

/** @namespace Pwa/Component/Form/Config/validateEmail */
export const validateEmail = ({ value }) => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

/** @namespace Pwa/Component/Form/Config/validateEmails */
export const validateEmails = ({ value }) => value.split(',').every((email) => validateEmail({ value: email.trim() }));

/** @namespace Pwa/Component/Form/Config/validatePassword */
export const validatePassword = ({ value }) => value.length >= MIN_PASSWORD_LENGTH;

/** @namespace Pwa/Component/Form/Config/validateTelephone */
export const validateTelephone = ({ value }) => value.length > 0 && value.match(/^\+?(?:[0-9-] ?){6,14}[0-9]$/);

/** @namespace Pwa/Component/Form/Config/isNotEmpty */
export const isNotEmpty = ({ value }) => value.trim().length > 0;

/** @namespace Pwa/Component/Form/Config/validatePasswordMatch */
export const validatePasswordMatch = ({ value }, { password }) => {
    const { current: { value: passwordValue } } = password || { current: {} };

    return value === passwordValue;
};

/** @namespace Pwa/Component/Form/Config/hasAreaCode */
export const hasAreaCode = ({ value } = {}) => {
    const { storeId } = window.storeConfig;

    let country;

    switch (storeId) {
    case 5:
        country = 'US';
        break;
    case 9:
        country = 'DE';
        break;
    case 4:
        country = 'ZA';
        break;
    case 7:
        country = 'AU';
        break;
    case 17:
        country = 'GB';
        break;
    default:
        country = 'US';
        break;
    }

    const phoneNumber = parsePhoneNumberFromString(value, country);

    // Check if the phoneNumber was parsed correctly
    if (!phoneNumber) {
        return false; // Return false if parsing failed
    }

    const { number, nationalNumber } = phoneNumber;

    if (phoneNumber && isPossiblePhoneNumber(number) && nationalNumber.length >= 8) {
        return true;
    }

    return false; // Return false if the phone number is invalid
};

/** @namespace Pwa/Component/Form/Config/formConfig */
export const formConfig = () => ({
    email: {
        validate: validateEmail,
        message: __('Email is invalid.')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    password: {
        validate: validatePassword,
        message: __('Password should be at least 8 characters long')
    },
    telephone: {
        validate: validateTelephone,
        message: __('Phone number is invalid!')
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!')
    },
    password_match: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    },
    phone_number: {
        validate: hasAreaCode,
        message: __('Please enter a valid Phone number with area code.')
    }
});

export default formConfig();
