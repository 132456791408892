import {
    CHECK_MONEY as SourceCHECK_MONEY,
    KLARNA as SourceKLARNA,
    PAYPAL_EXPRESS as SourcePAYPAL_EXPRESS,
    PAYPAL_EXPRESS_CREDIT as SourcePAYPAL_EXPRESS_CREDIT
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.config';

// TODO: implement KLARNA
export const KLARNA = SourceKLARNA;

// TODO: implement CHECK_MONEY
export const CHECK_MONEY = SourceCHECK_MONEY;

// TODO: implement PAYPAL_EXPRESS
export const PAYPAL_EXPRESS = SourcePAYPAL_EXPRESS;

// TODO: implement PAYPAL_EXPRESS_CREDIT
export const PAYPAL_EXPRESS_CREDIT = SourcePAYPAL_EXPRESS_CREDIT;

export const AUTHNETCIM = 'authnetcim';
export const COMPANY_CREDIT = 'companycredit';
export const FREE = 'free';
// export const ZIPMONEY = 'zipmoneypayment';
export const ZIPMONEY = 'zippayment';
export const PAYGATE = 'paygate';
export const STRIPE = 'stripe_payments';
export const GIROPAY = 'stripe_payments_giropay';
export const SOFORT = 'stripe_payments_sofort';
export const STRIPE_KLARNA = 'stripe_payments_klarna';
export const PAYJUSTNOW = 'payjustnow';
export const PAYONE_PAYDIRECT = 'payone_paydirekt';
export const PAYONE_SOFORT = 'payone_obt_sofortueberweisung';
export const PAYONE_PAYPAL = 'payone_paypal';
export const PAYONE_CREDITCART = 'payone_creditcard';
export const BRAINTREE = 'brain_tree';
export const AMAZON_PAY = 'amazon_payment_v2';
export const BREAD_PAY = 'breadcheckout';
export const PURCHASE_ORDER = 'purchaseorder';
export const GOOGLE_PAY = 'google_pay';
export const APPLE_PAY = 'apple_pay';
export const PAYFLEX = 'mr_partpay';
