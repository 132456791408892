/* eslint-disable eqeqeq */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-magic-numbers */
/* eslint-disable radix */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable max-lines */
/* eslint-disable fp/no-let */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    faStar as faStarRegular
} from '@fortawesome/free-regular-svg-icons';
import {
    faShippingFast,
    faStar
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    get, isArray, isEmpty, lowerCase, replace
} from 'lodash';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import AddToCart from 'Component/AddToCart';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import ProductPrice from 'Component/ProductPrice';
import ProductReviewRating from 'Component/ProductReviewRating';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import TextPlaceholder from 'Component/TextPlaceholder';
import TierPrices from 'Component/TierPrices';
import { GRID_LAYOUT, LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { LayoutType } from 'Type/Layout';
import { ProductType } from 'Type/ProductList';
import { hasFreeShipping, isRackKit } from 'Util/FrontRunner/Product';
import {
    isAusStore,
    isEurStore,
    isGbrStore,
    isIntStore,
    isRsaStore,
    isUsaStore
} from 'Util/FrontRunner/Store';
import { b2bAdditionalDiscounts } from 'Util/FrontRunner/Store/Promotions';
import { isB2BUser, scrollToTop } from 'Util/Helper';
// import media, { PRODUCT_MEDIA } from 'Util/Media';
// import { roundPrice } from 'Util/Price';
import { BUNDLE, CONFIGURABLE } from 'Util/Product';

import { OPTION_TYPE_COLOR, validOptionTypes } from './ProductCard.config';

import './ProductCard.style';

/**
 * Product card
 * @class ProductCard
 * @namespace Pwa/Component/ProductCard/Component/ProductCard */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/derived-class-names
export class ProductCard extends PureComponent {
    static propTypes = {
        linkTo: PropTypes.shape({}),
        product: ProductType.isRequired,
        notifyAdded: PropTypes.bool,
        productOrVariant: ProductType.isRequired,
        thumbnail: PropTypes.string,
        registerSharedElement: PropTypes.func.isRequired,
        setSiblingsHavePriceBadge: PropTypes.func,
        availableVisualOptions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        })).isRequired,
        getAttribute: PropTypes.func.isRequired,
        children: PropTypes.element,
        isList: PropTypes.bool,
        isLoading: PropTypes.bool,
        isSlider: PropTypes.bool,
        url_resolver: PropTypes.object,
        mix: PropTypes.shape({}),
        renderContent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        isConfigurableProductOutOfStock: PropTypes.func.isRequired,
        isBundleProductOutOfStock: PropTypes.func.isRequired,
        hideWishlistButton: PropTypes.bool,
        isWishlistEnabled: PropTypes.bool,
        setSiblingsHaveBrands: PropTypes.func,
        siblingsHavePriceBadge: PropTypes.bool,
        noNeedPopup: PropTypes.bool,
        siblingsHaveConfigurableOptions: PropTypes.bool,
        layout: LayoutType,
        updateConfigurableVariant: PropTypes.func.isRequired,
        configurableVariantIndex: PropTypes.number,
        parameters: PropTypes.shape({}),
        showSelectOptionsNotification: PropTypes.func
    };

    static defaultProps = {
        isList: true,
        isSlider: false,
        thumbnail: '',
        linkTo: {},
        children: null,
        isLoading: false,
        mix: {},
        notifyAdded: true,
        setSiblingsHavePriceBadge: () => null,
        renderContent: false,
        hideWishlistButton: false,
        hideCompareButton: false,
        siblingsHaveBrands: false,
        setSiblingsHaveBrands: () => null,
        siblingsHavePriceBadge: false,
        siblingsHaveConfigurableOptions: false,
        layout: GRID_LAYOUT,
        configurableVariantIndex: -1,
        noNeedPopup: false
    };

    contentObject = {
        renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
        pictureBlock: {
            picture: this.renderPicture.bind(this)
        },
        content: {
            review: this.renderReviews.bind(this),
            productPrice: this.renderProductPrice.bind(this),
            mainDetails: this.renderMainDetails.bind(this),
            additionalProductDetails: this.renderAdditionalProductDetails.bind(this)
        }
    };

    // renderProductPrice() {
    //     const { productOrVariant: { tax_description }, product: { price } } = this.props;
    //     if (!price) {
    //         return <TextPlaceholder />;
    //     }

    //     return (
    //         <ProductPrice
    //           price={ price }
    //           mix={ { block: 'ProductCard', elem: 'Price' } }
    //           tax_description={ tax_description }
    //         />
    //     );
    // }

    imageRef = createRef();

    registerSharedElement = () => {
        const { registerSharedElement } = this.props;
        registerSharedElement(this.imageRef);
    };

    renderProductPrice() {
        const {
            product: {
                price_range, bundle_options, type_id, tax_description
            },
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock
        } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        // switch (type_id) {
        // case CONFIGURABLE:
        //     if (isConfigurableProductOutOfStock()) {
        //         return this.renderEmptyProductPrice();
        //     }
        //     break;
        // case BUNDLE:
        //     if (isBundleProductOutOfStock()) {
        //         return this.renderEmptyProductPrice();
        //     }
        //     break;
        // default:
        //     break;
        // }

        return (
            <div
              block="ProductCard"
              elem="PriceWrapper"
            >
                {/* { this.renderTierPrice() } */}
                { this.renderConfigurablePriceBadge() }
                <ProductPrice
                  price={ price_range }
                  final_price={ bundle_options }
                  mix={ { block: 'ProductCard', elem: 'Price' } }
                  tax_description={ tax_description }
                />
            </div>
        );
    }

    renderEmptyProductPrice() {
        return (
            <div
              block="ProductCard"
              elem="PriceWrapper"
              mods={ { isEmpty: true } }
            />
        );
    }

    renderStockTextLine() {
        // Hide stock text for en_int and en_uk stores
        if (isIntStore() || isGbrStore()) {
            return null;
        }

        const {
            product = {},
            product: {
                configurableVariantIndex,
                sku,
                type_id,
                variants = []
            }
        } = this.props;

        // Get the product or variant
        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        // Get the stock status
        const { stock_status = 'IN_STOCK' } = productOrVariant;

        // For configurable products, check if any variants are in stock
        if (type_id === 'configurable') {
            // If it's out of stock, show the stock status
            if (stock_status !== 'IN_STOCK') {
                return (
                    <div block="ProductCard" elem="StockText">
                        <span className="out-of-stock">
                            { __('Out of stock') }
                        </span>
                    </div>
                );
            }

            return (
                <div block="ProductCard" elem="StockText">
                    { __('In stock') }
                    { ' - ' }
                    { __('Ready to ship') }
                </div>
            );
        }

        // For simple products, check direct stock status
        if (type_id === 'simple' && stock_status === 'IN_STOCK') {
            return (
                <div block="ProductCard" elem="StockText">
                    { __('In stock') }
                    { ' - ' }
                    { __('Ready to ship') }
                </div>
            );
        }

        // If it's out of stock, show the stock status
        if (type_id === 'simple' && stock_status !== 'IN_STOCK') {
            return (
                <div block="ProductCard" elem="StockText">
                    <span className="out-of-stock">&nbsp;</span>
                </div>
            );
        }

        return null;
    }

    renderTierPrice() {
        const {
            productOrVariant,
            siblingsHaveTierPrice,
            setSiblingsHaveTierPrice
        } = this.props;
        const { price_tiers } = productOrVariant;

        if (!price_tiers || !price_tiers.length) {
            return null;
        }

        if (!siblingsHaveTierPrice) {
            // setSiblingsHaveTierPrice();
            return null;
        }

        return (
            <TierPrices
              product={ productOrVariant }
              isLowestPrice
            />
        );
    }

    renderConfigurablePriceBadge() {
        const {
            product: { type_id },
            siblingsHavePriceBadge,
            setSiblingsHavePriceBadge
        } = this.props;

        if (type_id !== CONFIGURABLE) {
            return null;
        }

        if (!siblingsHavePriceBadge) {
            setSiblingsHavePriceBadge();
        }

        return (
            <p
              mix={ {
                  block: 'ProductCard',
                  elem: 'PriceBadge'
              } }
            >
                { __('Price From:') }
            </p>
        );
    }

    renderVisualOption({ value, label, type }, i) {
        const isColor = type === OPTION_TYPE_COLOR;

        return (
            <span
              block="ProductCard"
              elem={ isColor ? 'Color' : 'String' }
              key={ i }
              style={ isColor ? { backgroundColor: value } : {} }
              aria-label={ isColor ? label : '' }
              title={ isColor ? '' : label }
            >
                { isColor ? '' : value }
            </span>
        );
    }

    renderVisualConfigurableOptions() {
        const { availableVisualOptions } = this.props;

        return (
            <div block="ProductCard" elem="ConfigurableOptions">
                { availableVisualOptions.map(({ value, label }) => (
                    <span
                      block="ProductCard"
                      elem="Color"
                      key={ value }
                      style={ { backgroundColor: value } }
                      aria-label={ label }
                    />
                )) }
            </div>
        );
    }

    renderPicture() {
        const {
            product: {
                brand,
                id,
                sku,
                name,
                small_image = {},
                stock_status,
                new_from_date = null,
                attributes,
                price_range = {},
                type_id,
                items = {},
                bundle_options = {},
                url_rewrites = {}
            },
            thumbnail,
            getBlackFridayPromo = {
                active: false
            },
            getXmasPromo = {
                active: false
            },
            getSalesPromotion = {
                active: false
            },
            getAttribute
        } = this.props;

        // Is B2B?
        // Check if B2B customer (Use local storage check)
        const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        // const imageUrl = thumbnail && media(thumbnail, PRODUCT_MEDIA);
        const imageUrl = get(small_image, 'url', '');

        // Is New
        let isNew = false;
        if (new_from_date && new_from_date != null) {
            isNew = true;
        }

        // Coming Soon
        let isComingSoon = false;
        if (attributes && attributes.coming_soon && stock_status !== 'IN_STOCK') {
            const { attribute_label, attribute_value } = attributes.coming_soon;
            if (attribute_value && (attribute_value === '1' || attribute_value === 1)) {
                isNew = false;
                isComingSoon = true;
            }
        }

        // Brand
        let brandText = brand;
        if (isEmpty(brandText)) {
            // Try get from attributes
            if (!attributes) {
                return null;
            }

            const { attribute_value: brand } = getAttribute('brand') || {};
            if (isEmpty(brand)) {
                // Find attribute_code = "brand" in attributes
                let brandAttributeValue = null;
                if (Array.isArray(attributes)) {
                    const brandAttribute = attributes.find((attribute) => attribute.attribute_code === 'brand');
                    brandAttributeValue = brandAttribute?.attribute_value ?? null;
                }
                if (!isEmpty(brandAttributeValue)) {
                    // Find brandAttributeValue in attribute_options
                    const brandAttribute = attributes.find((attribute) => attribute.attribute_code === 'brand');
                    const brandOption = brandAttribute?.attribute_options?.find((option) => option.value === brandAttributeValue);
                    brandText = brandOption?.label ?? null;
                }
            } else {
                brandText = attributes?.brand?.attribute_options[brand]?.label ?? null;
            }
        }

        /* Labels check for Product Slider */
        if (attributes && isArray(attributes)) {
            const filterLabelAttribute = attributes?.find((attribute) => attribute.attribute_id === 745);
            if (filterLabelAttribute?.attribute_value === '1' && stock_status !== 'IN_STOCK') {
                isNew = false;
                isComingSoon = true;
            }
        }

        // Only show for EUR store
        // TODO: @Chris - Remove once promotion is done
        if (isEurStore() && !isB2B && getSalesPromotion.active) {
            /* const allowSkuList = ['SBOX031', 'TBRA037', 'SBOX052', 'CHAI007'];
            let promoLabel = 'Combo Deal';
            if (window.storeConfig.code === 'de_de') {
                promoLabel = 'Angebot';
            }
            if (window.storeConfig.code === 'es_be') {
                promoLabel = 'Oferta';
            }
            if (window.storeConfig.code === 'nl_be') {
                promoLabel = 'Aanbod';
            }
            if (window.storeConfig.code === 'fr_be') {
                promoLabel = 'Offre';
            }

            if (allowSkuList.indexOf(sku) !== -1 || isRackKit({ sku, name })) {
                return (
                    <>
                    <div className="fr_prod_promo_label">
                        { promoLabel }
                    </div>
                    <Image
                      src={ imageUrl }
                      alt={ name }
                      ratio="custom"
                      mix={ { block: 'ProductCard', elem: 'Picture' } }
                      isPlaceholder={ !id }
                    />
                    <img
                      style={ { display: 'none' } }
                      alt={ name }
                      src={ imageUrl }
                      loading="lazy"
                    />
                    { this.renderProductWishlistButton() }
                    </>
                );
            } */

            /* const allowSku = ['TENT184', 'TENT190'];
            if (allowSku.indexOf(sku) !== -1) {
                let promoLabel = 'Save Big!';
                if (window.storeConfig.code === 'de_de') {
                    promoLabel = 'Angebot';
                }
                if (window.storeConfig.code === 'es_be') {
                    promoLabel = 'Oferta';
                }
                if (window.storeConfig.code === 'nl_be') {
                    promoLabel = 'Aanbod';
                }
                if (window.storeConfig.code === 'fr_be') {
                    promoLabel = 'Offre';
                }

                return (
                    <>
                    <div className="fr_prod_promo_label">
                        { promoLabel }
                    </div>
                    <Image
                      src={ imageUrl }
                      alt={ name }
                      ratio="custom"
                      mix={ { block: 'ProductCard', elem: 'Picture' } }
                      isPlaceholder={ !id }
                    />
                    <img
                      style={ { display: 'none' } }
                      alt={ name }
                      src={ imageUrl }
                      loading="lazy"
                    />
                    { this.renderProductWishlistButton() }
                    </>
                );
            } */
        }

        // Front Runner AUS EOFY 2024 Deals
        // if (isAusStore() && getSalesPromotion.active && !isB2B && brandText === 'Front Runner') {
        //     return (
        //         <>
        //         <div className="fr_prod_promo_label">
        //             Save up to 20%
        //         </div>
        //         <Image
        //           src={ imageUrl }
        //           alt={ name }
        //           ratio="custom"
        //           mix={ { block: 'ProductCard', elem: 'Picture' } }
        //           isPlaceholder={ !id }
        //         />
        //         <img
        //           style={ { display: 'none' } }
        //           alt={ name }
        //           src={ imageUrl }
        //           loading="lazy"
        //         />
        //         { this.renderProductWishlistButton() }
        //         </>
        //     );
        // }

        // Initiate discount amount
        let discountAmnt = 0;
        let discountAmntPrefix = '-';
        let discountAmntPostfix = '%';

        // Show discount percentage for dealers
        if (isB2B && !isIntStore()) {
            // Black Friday / Cyber Sale
            if (getBlackFridayPromo.active) {
                // B2B Additional Discounts
                const { group5 = [], group10 = [] } = b2bAdditionalDiscounts();

                // Does product.url_rewrites contain text in array "storage-systems/drawers/"?
                // TODO: @Chris base this off category intead of url match. We need categories in props
                let isStorageSystemDrawer = false;
                if (url_rewrites && url_rewrites.length > 0) {
                    isStorageSystemDrawer = url_rewrites.some((url) => url.url.includes('storage-systems/drawers/'));
                    if (isStorageSystemDrawer) {
                        group10.push(sku);
                    }
                }

                // Default discount amount
                discountAmnt = 5;
                if (group5.indexOf(sku) !== -1) {
                    discountAmnt = 5;
                }
                if (group10.indexOf(sku) !== -1) {
                    discountAmnt = 10;
                }
            }

            // Xmas Promotion
            // TODO: @Chris add rack category check
            if (getXmasPromo.active && isRackKit({ sku, name })) {
                discountAmnt = 5;
            }

            // let group5 = [];
            // let group10 = [];

            // TODO: @Chris - Current deals, Black Friday deals will replace these, revise after Black Friday
            // if (isRsaStore()) {
            //     group5 = ['RRAC159', 'LADD008', 'STRA037'];
            //     group10 = ['LALD007', 'VACC021', 'WTAN059', 'SSST003'];
            // }
            // if (isAusStore()) {
            //     group5 = ['TENT031', 'RRAC159', 'RRAC125', 'LADD008', 'RRAC169'];
            //     group10 = ['RRAC205', 'VACC021', 'STRA037', 'LALD007', 'SSST003', 'SSLD002', 'WTAN059'];
            // }
            // if (isUsaStore()) {
            //     group5 = ['SSST003', 'RRAC159', 'RRAC169', 'RRAC125'];
            //     group10 = ['TENT031', 'RRAC205', 'VACC021', 'STRA037', 'LALD007', 'WTAN059', 'RRAC168'];
            // }
            // if (isEurStore()) {
            //     group5 = ['RRAC159', 'RRAC125', 'LADD008'];
            //     group10 = ['STRA037', 'VACC021', 'LALD007', 'SSLD002', 'SSST003', 'WTAN059', 'RRAC205'];
            // }

            // if (!isEmpty(group5)) {
            //     if (group5.indexOf(sku) !== -1) {
            //         discountAmnt = 5;
            //     }
            // }
            // if (!isEmpty(group10)) {
            //     if (group10.indexOf(sku) !== -1) {
            //         discountAmnt = 10;
            //     }
            // }
        }

        // Show Discount percentage for B2C
        if (!isB2B && price_range) {
            let discount = 0;
            if (type_id === 'bundle') {
                discount = items[0]?.options[0]?.product?.price_range?.minimum_price?.discount?.percent_off ?? null;
                if (discount === null) {
                    // Try calculate from bundle_options values
                    let regularOptionPriceExclTax = bundle_options[0]?.selection_details[0]?.regular_option_price_excl_tax ?? null;
                    let finalOptionPriceExclTax = bundle_options[0]?.selection_details[0]?.final_option_price_excl_tax ?? null;
                    // Try get values from other options
                    if (finalOptionPriceExclTax === null || regularOptionPriceExclTax === null) {
                        regularOptionPriceExclTax = items[0]?.options[0]?.regularOptionPriceExclTax ?? null;
                        finalOptionPriceExclTax = items[0]?.options[0]?.finalOptionPriceExclTax ?? null;
                    }
                    if (regularOptionPriceExclTax !== finalOptionPriceExclTax) {
                        discount = Math.round(((regularOptionPriceExclTax - finalOptionPriceExclTax) / regularOptionPriceExclTax) * 100);
                    }
                }
            } else if (type_id === 'configurable') {
                const discountPr = price_range?.maximum_price?.discount?.percent_off;
                if (discountPr == 0) {
                    discount = price_range?.minimum_price?.discount?.percent_off;
                } else {
                    discount = price_range?.maximum_price?.discount?.percent_off;
                }
            } else {
                discount = price_range?.minimum_price?.discount?.percent_off ?? null;
            }

            if (discount !== null && parseInt(discount) !== 0) {
                // Round discount to zero decimal
                discountAmnt = (Math.round(discount * 100) / 100).toFixed(0);
            }
        }

        // Set pre/postfix
        // window.storeConfig.code starts with "en" then set prefix to ""
        if (window.storeConfig.code.startsWith('en_')) {
            discountAmntPrefix = '';
            discountAmntPostfix = '% Off';
            if (type_id === 'configurable') {
                discountAmntPrefix = 'Up to ';
                discountAmntPostfix = '% Off';
            }
        }

        let discountAmountClass = '';

        if (discountAmnt == 100) {
            discountAmountClass = 'discount_label_hide';
        }

        return (
            <>
               { (isNew && discountAmnt === 0) && (<div className="fr_prod_new_label">{ __('NEW') }</div>) }
               { (isComingSoon && discountAmnt === 0) && (<div className="fr_prod_coming_soon_label">{ __('Coming Soon') }</div>) }
               { (discountAmnt > 0) && (
                <div className={`fr_prod_promo_label ${discountAmountClass}`}>
                    { discountAmntPrefix }
                    { discountAmnt }
                    { discountAmntPostfix }
                </div>
               ) }
                <Image
                  src={ imageUrl }
                  alt={ name }
                  ratio="custom"
                  mix={ { block: 'ProductCard', elem: 'Picture' } }
                  isPlaceholder={ !id }
                />
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ imageUrl }
                  loading="lazy"
                />
                { this.renderProductWishlistButton() }
            </>
        );
    }

    renderReviews() {
        const { product: { review_summary: { rating_summary, review_count } = {} } } = this.props;
        if (!rating_summary) {
            return null;
        }

        const ONE_FIFTH_OF_A_HUNDRED = 20;
        const rating = parseFloat(rating_summary / ONE_FIFTH_OF_A_HUNDRED).toFixed(2);

        return (
            <div
              block="ProductCard"
              elem="Reviews"
              itemProp="aggregateRating"
              itemScope
              itemType="https://schema.org/AggregateRating"
            >
                <meta itemProp="ratingValue" content={ rating || 0 } />
                <meta itemProp="ratingCount" content={ review_count || 0 } />
                <ProductReviewRating summary={ rating_summary || 0 } />
            </div>
        );
    }

    renderAdditionalProductDetails() {
        const { product: { sku, brand, attributes = {} }, getAttribute } = this.props;
        if (!sku) {
            return null;
        }

        let brandText = brand;
        if (isEmpty(brandText)) {
            // Try get from attributes
            if (!attributes) {
                return null;
            }

            const { attribute_value: brand } = getAttribute('brand') || {};
            if (isEmpty(brand)) {
                // Find attribute_code = "brand" in attributes
                let brandAttributeValue = null;
                if (Array.isArray(attributes)) {
                    const brandAttribute = attributes.find((attribute) => attribute.attribute_code === 'brand');
                    brandAttributeValue = brandAttribute?.attribute_value ?? null;
                }
                if (!isEmpty(brandAttributeValue)) {
                    // Find brandAttributeValue in attribute_options
                    const brandAttribute = attributes.find((attribute) => attribute.attribute_code === 'brand');
                    const brandOption = brandAttribute?.attribute_options?.find((option) => option.value === brandAttributeValue);
                    brandText = brandOption?.label ?? null;
                }
            } else {
                brandText = attributes?.brand?.attribute_options[brand]?.label ?? null;
            }
        }

        if (sku && isEmpty(brandText)) {
            return null;
        }

        return (
            <p
              block="ProductCard"
              elem="Brand"
              mods={ { isLoaded: !!sku } }
            >
                { brandText }
            </p>
        );
    }

    renderMainDetails() {
        const { product: { name } } = this.props;

        // TODO: @Chris - Testing display, to be actioned on data directly
        let nameText = name;
        if (name) {
            // Remove "- by Front Runner" from text
            if (nameText.includes('- by Front Runner')) {
                nameText = nameText.replace('- by Front Runner', '').trim();
            }
        }

        return (
            <div
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!nameText } }
            >
                <div block="ProductCard" elem="Name-Text">
                    { this.renderCardWrapper((
                        <TextPlaceholder content={ nameText } length="medium" />
                    ))}
                </div>
                { this.renderAdditionalProductDetails() }
            </div>
        );
    }

    handleClick =() => {
        const { handleClick } = this.props;
        this.registerSharedElement();
        if (handleClick) {
            handleClick();
        }
        // scrollToTop();
    };

    renderCardWrapper(children) {
        const { linkTo, product: { url, product_url, url_resolver = {} } } = this.props;
        const storeBaseName = window.storeConfig.baseName;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <RouterLink
              className="ProductCard-Link 13"
              to={ linkTo }
            //   onClick={ () => this.handleClick() }
              onClick={() => {
                  scrollToTop();
                  this.handleClick();
              }}
            //   to={ {
            //       pathname: url,
            //       state: {
            //           url_resolver
            //       }
            //   } }
            >
              { children }
            </RouterLink>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const { linkTo, product: { url } } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ this.registerSharedElement }
              mix={ mix }
            >
              { children }
            </Link>
        );
    }

    renderStockStatus = () => {
        const {
            product: { stock_status = '', type_id = '' }
        } = this.props;

        if (isEmpty(stock_status) || type_id === 'configurable') {
            return;
        }
        const wrapperClass = lowerCase(replace(stock_status, /_/g, ''));

        return (
        <div block="ProductCard" elem="Stock" className={ wrapperClass }>
            { isEmpty(stock_status) && <TextPlaceholder /> }
            { !isEmpty(stock_status) && (stock_status === 'OUT_OF_STOCK') && __('Out of stock') }
        </div>
        );
    };

    renderAddToCart = () => {
        const {
            configurableVariantIndex = -1, product, notifyAdded = true, noNeedPopup = false
        } = this.props;

        const { type_id = '', stock_status = '' } = product;
        if (type_id !== 'simple' || stock_status !== 'IN_STOCK') {
            return null;
        }

        return (
          <AddToCart
            product={ product }
            configurableVariantIndex={ configurableVariantIndex }
            mix={ { block: 'ProductActions', elem: 'AddToCart' } }
            groupedProductQuantity={ { 1: 1 } }
            notifyAdded={ notifyAdded }
            noNeedPopup={ noNeedPopup }
          />
        );
    };

    renderShopButton = () => {
        const { product: { type_id = '', stock_status = '', url: product_url = '' } } = this.props;
        if (type_id === 'simple' || isEmpty(type_id)) {
            return null;
        }

        const storeBaseName = window.storeConfig.baseName;
        const urlkey = isEmpty(product_url) ? product_url : product_url.replace(storeBaseName, '/');

        return (
            <div>
                <Link
                  block="ProductCard"
                  elem="Shop btn"
                  to={ urlkey }
                >
                { __('SHOP') }
                </Link>
            </div>
        );
    };

    renderYotpoRating = () => {
        const {
            product: {
                sku,
                attributes = {},
                rating_summary = 0,
                review_count = 0
            },
            getAttribute,
            linkTo
        } = this.props;

        // Yotpo Product Rating
        let yotpoRating = 0;
        let yotpoReviewCount = 0;
        if (attributes && attributes.yotpo_product_rating) {
            const { attribute_value: yotpo_product_rating } = getAttribute('yotpo_product_rating') || {};
            yotpoRating = yotpo_product_rating ?? 0;
        }
        if (attributes && Array.isArray(attributes) && attributes.length > 0) {
            const yotpo_product_rating_attribute = attributes.find((item) => item.attribute_id === 842);
            yotpoRating = yotpo_product_rating_attribute?.attribute_value ?? 0;
        }
        if (parseFloat(yotpoRating) === 0 && parseInt(rating_summary) > 0) {
            yotpoRating = rating_summary;
        }

        if (attributes && attributes.yotpo_product_reviews_count) {
            const { attribute_value: yotpo_product_reviews_count } = getAttribute('yotpo_product_reviews_count') || {};
            yotpoReviewCount = yotpo_product_reviews_count ?? 0;
        }
        if (attributes && Array.isArray(attributes) && attributes.length > 0) {
            const yotpo_product_count_attribute = attributes.find((item) => item.attribute_id === 843);
            yotpoReviewCount = yotpo_product_count_attribute?.attribute_value ?? 0;
        }
        if (yotpoReviewCount === 0 && review_count > 0) {
            yotpoReviewCount = review_count;
        }

        // Return Wishlist if no rating added yet
        if (parseInt(yotpoReviewCount) === 0) {
            return (
                <div className="review_rating">
                    <FontAwesomeIcon icon={ faStarRegular } size="1x" color="#F0C332" />
                    0
                    <span>(0)</span>
                </div>
            );
        }

        return (
            <div className="review_rating">
                     <Link
                       to={ `${linkTo?.pathname}#reviews` }
                     >
                        { parseFloat(yotpoRating) > 0 && (
                            <>
                            <FontAwesomeIcon icon={ faStar } size="1x" color="#F0C332" />
                            { `${yotpoRating}` }
                            <span>
                            { `(${yotpoReviewCount})` }
                            </span>
                            </>
                        ) }
                     </Link>
            </div>
        );
    };

    renderProductWishlistButton = () => {
        const {
            product,
            quantity,
            configurableVariantIndex,
            isList
        } = this.props;

        return (
        <div className="product-wishlist">
          <ProductWishlistButton
            product={ product }
            quantity={ quantity }
            configurableVariantIndex={ configurableVariantIndex }
            isList={ isList }
          />
        </div>
        );
    };

    renderSKU = () => {
        const { product: { sku } } = this.props;
        return (
        <div
          block="ProductCard"
          elem="SKU"
          mods={ { isLoaded: !!sku } }
          content={ sku }
        >
          { isEmpty(sku) && <TextPlaceholder /> }
          { !isEmpty(sku) && (
            <div>
                { `${__('SKU')}: ${sku}` }
            </div>
          ) }
        </div>
        );
    };

    renderShortDescription() {
        const { product: { short_description, id } } = this.props;
        const { html } = short_description || {};
        // const htmlWithItemProp = `<div itemProp="description">${html}</div>`;
        const htmlWithItemProp = `<div>${html}</div>`;

        if (!html && id) {
            return null;
        }

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'short' } }
              aria-label="Product short description"
            >
                <div block="ProductActions" elem="ShortDescription">
                    { html ? <Html content={ htmlWithItemProp } /> : <p><TextPlaceholder length="long" /></p> }
                </div>
            </section>
        );
    }

    renderFreeShippingIndicator(product = {}) {
        const {
            is_free_shipping_enable = 0,
            freeShippingHeaderText = {},
            free_shipping_header_text_b2b = {},
            customer: { is_b2b } = {}
        } = this.props;

        if (is_free_shipping_enable !== '1' || isIntStore()) {
            return null;
        }

        // const promoText = isB2BUser(is_b2b) ? free_shipping_header_text_b2b : freeShippingHeaderText;
        // if (isEmpty(free_shipping_header_text_b2b)) {
        //     return null;
        // }
        const promoText = `${__('Qualifies for free shipping')}*`;

        const productObject = { ...product };
        if (isEmpty(product)) {
            return null;
        }

        let singleCheck = '';
        if (isRsaStore()) {
            singleCheck = '1406'; // Free shipping items only
        }
        if (!hasFreeShipping(productObject, singleCheck)) {
            return null;
        }

        return (
            <>
            <div
              className="freeshippingindicator-item"
              data-tooltip-content={ `${ __(promoText) } (${__("T&C's apply")})` }
              data-tooltip-id={ `product-tt-${product.sku}` }
            >
                <FontAwesomeIcon icon={ faShippingFast } size="1x" color="#fff" />
                { ' ' }
                { __('Free Shipping') }
                *
            </div>
            <Tooltip id={ `product-tt-${product.sku}` } effect="solid" place="top" className="freeshippingindicator-tooltip" />
            </>
        );
    }

    render() {
        const {
            product,
            children,
            mix,
            isLoading,
            isList,
            isRelated,
            isSlider,
            renderContent,
            linkTo
        } = this.props;
        const { sku } = product;
        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <li
              block="ProductCard"
              mix={ mix }
            >
            {/* <RouterLink
              className="ProductCard-Link"
              to={ linkTo }
              onClick={ () => this.handleClick() }
            > */}
                <Loader isLoading={ isLoading } />
                <div className="pro-image">
                    { this.renderCardWrapper((
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    )) }
                    { this.renderFreeShippingIndicator(product) }
                </div>
                <div className="product-item-details">
                    <div className="pro-left">
                        <div block="ProductCard" elem="Content">
                            { this.renderMainDetails() }
                            {/* { this.renderAdditionalProductDetails() } */}
                            {/* Hide review on product card, show on product page only */}
                            {/* { this.renderReviews() } */}
                            {/* { isList && this.renderSKU() } */}
                            {/* { this.renderShortDescription() } */}
                        </div>
                    </div>
                    <div className="pro-right">
                        <div className="pro_inner_top">
                            <div className="pro-inner-wrap">
                                {/* { this.renderCardWrapper((
                                    <div className="more_info btn btn-default">
                                        More Info
                                    </div>
                                )) } */}
                                { this.renderStockTextLine() }
                                { this.renderProductPrice() }
                                {/* { this.renderVisualConfigurableOptions() } */}
                            </div>
                            <div className="add_cart_wrap">
                                { this.renderStockStatus() }
                                { isRelated && this.renderAddToCart() }
                                { isList && this.renderAddToCart() }
                                { this.renderCardWrapper((
                                    <>
                                    { this.renderShopButton() }
                                    </>
                                )) }
                            </div>
                        </div>
                        <RouterLink
                          className="ProductCard-Link"
                          to={ linkTo }
                          onClick={ () => this.handleClick() }
                        >
                        <div className="pro_inner_bot">
                            { isList && this.renderSKU() }
                            {/* { isList && this.renderProductWishlistButton() } */}
                            { isList && this.renderYotpoRating() }
                        </div>
                        { isSlider && (
                            <div className="pro_inner_bot">
                            { this.renderSKU() }
                            </div>
                        ) }
                        <div block="ProductCard" elem="AdditionalContent">
                            { children }
                        </div>
                        </RouterLink>
                    </div>
                </div>
            {/* </RouterLink> */}
            </li>
        );
    }
}

export default ProductCard;
