/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
/* eslint-disable no-magic-numbers */
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/CustomerGarage/Query/CustomerGarageQuery */
class CustomerGarageQuery {
  getList = () => new Field('customerVehicle')
      .addArgument('key', 'String!', Math.random() * 100001)
      .addFieldList([
          'id',
          'lyear',
          'lmake',
          'lmodel',
          'loption',
          'year',
          'make',
          'model',
          'options',
          'purchase_date',
          'nick_name'
      ]);

  removeItem = (id) => new Field('removeCustomerVehicle')
      .addArgument('id', 'ID!', id)
      .addFieldList([
          'status'
      ]);

  addItem = (data) => new Field('setCustomerVehicle')
      .addArgument('customerVehicleInput', 'CustomerVehicleInput!', data)
      .addFieldList([
          'status',
          'id'
      ]);
}

export default new CustomerGarageQuery();
