/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import ContentWrapper from 'Component/ContentWrapper';
import ErrorDetailsQuery from 'Query/ErrorDetails.query';
import {
    SomethingWentWrong as SourceSomethingWentWrong
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';
import { fetchMutation } from 'Util/Request';

import './SomethingWentWrong.style.override';

/** @namespace Pwa/Route/SomethingWentWrong/Component/SomethingWentWrong */
export class SomethingWentWrong extends SourceSomethingWentWrong {
    // TODO implement logic

    renderErrorDetails() {
        const { errorDetails: { err, info: { componentStack } = {} } } = this.props;
        const errorString = err.toString();

        console.groupCollapsed('Suppressed error log:');
        console.error(errorString);
        console.groupEnd();

        // fetchMutation(ErrorDetailsQuery.sendError(errorString, componentStack)).then(
        //     /** @namespace Pwa/Route/SomethingWentWrong/Component/fetchMutation/then */
        //     (response) => {
        //         console.log('yanu15', response);
        //     },
        //     /** @namespace Pwa/Route/SomethingWentWrong/Component/fetchMutation/then */
        //     (error) => {
        //         console.log('yanu15', error);
        //     }
        // );

        if (process.env.NODE_ENV === 'production') {
            return null;
        }

        return (
            <div block="SomethingWentWrong" elem="Debug">
                { errorString }
                { componentStack }
            </div>
        );
    }

    render() {
        const { onClick } = this.props;
        const home = window.storeConfig.baseName;

        return (
            <main block="SomethingWentWrong">
                <ContentWrapper label="Something went wrong on the page.">
                    <h1 block="SomethingWentWrong" elem="Heading">{ __('Ooops!') }</h1>
                    <h2 block="SomethingWentWrong" elem="SubHeading">{ __('Something went wrong!') }</h2>
                    <a
                      href={ home }
                      block="SomethingWentWrong"
                      elem="Button"
                      mix={ { block: 'Button' } }
                      onClick={ onClick }
                    >
                        { __('Back to homepage') }
                    </a>
                    { this.renderErrorDetails() }
                </ContentWrapper>
            </main>
        );
    }
}

export default SomethingWentWrong;
