/* eslint-disable fp/no-let */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable arrow-parens */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import PropTypes from 'prop-types';

import FieldForm from 'Component/FieldForm';

import './AddTeamForm.override.style';

/** @namespace Pwa/Component/AddTeamForm/Component/AddTeamForm */
export class AddTeamForm extends FieldForm {
    // TODO implement logic
    static propTypes = {
        team: PropTypes.any,
        onSave: PropTypes.func
    };

    static defaultProps = {
        onSave: () => {}
    };

    __construct(props) {
        super.__construct(props);
    }

    get fieldMap() {
        return {
            name: {
                label: __('Team Title'),
                validation: ['notEmpty']
            },
            description: {
                label: __('Description'),
                validation: ['notEmpty'],
                type: 'textarea'
            }
        };
    }

    onFormSuccess = (fields) => {
        const { onSave } = this.props;
        const updateFields = this.setAddTeamFormObject(fields);

        onSave(updateFields);
    };

  setAddTeamFormObject = (fields) => {
      // removing street related fields from the form object
      const newFields = Object.keys(fields)
          .reduce(
              (acc, key) => {
                  acc[key] = fields[key];
                  return acc;
              }, {}
          );

      return newFields;
  };

  getDefaultValues(fieldEntry) {
      const [key, { value }] = fieldEntry;
      const { team: { [key]: userValue } } = this.props;

      return {
          ...super.getDefaultValues(fieldEntry),
          value: value !== undefined ? value : userValue
      };
  }

  renderActions() {
      return (
        <button
          type="submit"
          block="Button"
          mix={ { block: 'AddTeam', elem: 'Button' } }
        >
            { __('Save') }
        </button>
      );
  }
}

export default AddTeamForm;
