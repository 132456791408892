/* eslint-disable fp/no-let */
/* eslint-disable no-console */
import GearzettePageQuery from 'Query/GearzettePage.query';
import { updateGearzettePageCategories } from 'Store/GearzettePage';
import { QueryDispatcher } from 'Util/Request';

/** @namespace Pwa/Store/GearzettePage/GearzetteCategory/Dispatcher/GearzetteCategoryDispatcher */
export class GearzetteCategoryDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    // eslint-disable-next-line no-unused-vars
    onSuccess({ categories = [] }, dispatch, { cb }) {
        let cate = [];
        if (categories && categories.lenth > 0) {
            const { children: cat } = categories[0];
            cate = cat;
        }
        dispatch(updateGearzettePageCategories(cate));
    }

    // eslint-disable-next-line no-unused-vars
    onError(_, dispatch) {
        // dispatch(updateNoMatch(true));
    }

    // eslint-disable-next-line no-unused-vars
    prepareRequest(options, dispatch) {
        return GearzettePageQuery.getCategoriesQuery(options);
    }
}

export default new GearzetteCategoryDispatcher();
