/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { QueryDispatcher as SourceQueryDispatcher } from 'SourceUtil/Request/QueryDispatcher';
import { formatCleanQuery } from 'Util/Helper';
import { makeCancelable } from 'Util/Promise';
import { Field, prepareQuery } from 'Util/Query';
import { executeFsyncGet, executeGet, listenForBroadCast } from 'Util/Request/Request';

export const ONE_MONTH_IN_SECONDS = 2592000;
export const FIVE_MINUTES_IN_SECONDS = 300;

/**
 * Abstract request dispatcher.
 * IMPORTANT: it is required to implement `prepareRequest(options)` before using!
 * @class QueryDispatcher
 * @namespace Pwa/Util/Request/QueryDispatcher/QueryDispatcher */
export class QueryDispatcher extends SourceQueryDispatcher {
    /**
     * Is responsible for request routing and manages `onError`, `onSuccess`, `onUpdate` functions triggers.
     * @param  {Function} dispatch Store changing function from Redux (dispatches actions)
     * @param  {any} options Any options received from Container
     * @return {void}@memberof QueryDispatcher
     */
    handleData(dispatch, options) {
        const { name, cacheTTL } = this;

        const rawQueries = this.prepareRequest(options, dispatch);

        if (!rawQueries) {
            return;
        }

        const queries = rawQueries instanceof Field ? [rawQueries] : rawQueries;

        if (this.promise) {
            this.promise.cancel();
        }

        this.promise = makeCancelable(
            new Promise((resolve, reject) => {
                executeGet(prepareQuery(queries), name, cacheTTL)
                    .then(
                        /** @namespace Pwa/Util/Request/QueryDispatcher/executeGet/then */
                        (data) => resolve(data),
                        /** @namespace Pwa/Util/Request/QueryDispatcher/executeGet/then */
                        (error) => reject(error)
                    );
            })
        );

        this.promise.promise.then(
            /** @namespace Pwa/Util/Request/QueryDispatcher/then */
            // (data) => this.generalResponseHandling(data, dispatch, options, this.onSuccess),
            (data) => this.onSuccess(data, dispatch, options),

            /** @namespace Pwa/Util/Request/QueryDispatcher/then */
            // (error) => this.generalResponseHandling(error, dispatch, options, this.onError),
            (error) => this.onError(error, dispatch, options),

        );

        listenForBroadCast(name).then(
            /** @namespace Pwa/Util/Request/QueryDispatcher/listenForBroadCast/then */
            // (data) => this.generalResponseHandling(data, dispatch, options, this.onUpdate),
            (data) => this.onUpdate(data, dispatch, options),

        );
    }

    /**
     * Is responsible for request routing and manages `onError`, `onSuccess`, `onUpdate` functions triggers.
     * @param  {Function} dispatch Store changing function from Redux (dispatches actions)
     * @param  {any} options Any options received from Container
     * @return {void}@memberof QueryDispatcher
     */
    handleFsyncData(dispatch, options) {
        const { name, cacheTTL } = this;

        const graphql = this.prepareRequest(options, dispatch);
        const rawQueries = formatCleanQuery(graphql);
        const { endpointUrl = '' } = options;

        const fsyncEndpointUrl = endpointUrl;

        if (!rawQueries) {
            return;
        }

        let fsyncUrl = 'https://mcfsync-staging.frontrunneroutfitters.com/';
        if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
            fsyncUrl = 'https://fsync.frontrunneroutfitters.com/';
        }

        if (!window.storeConfig.fsync_url) {
            // for Staging to handle config loading issue
            window.storeConfig.fsync_url = fsyncUrl;
        }
        // const url = window.storeConfig.fsync_url + fsyncEndpointUrl;
        let url = `${fsyncUrl}graphql${ fsyncEndpointUrl}`;

        // If production
        if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
            // If endpoint url contains /vfits then change the url
            if (url.includes('/graphql/vfits')) {
                url = 'https://graphql-fvf.frontrunneroutfitters.com/?';
            }
        }

        this.promise = makeCancelable(
            new Promise((resolve, reject) => {
                executeFsyncGet(url, rawQueries, name, cacheTTL)
                    .then(
                        /** @namespace Pwa/Util/Request/QueryDispatcher/executeFsyncGet/then */
                        (data) => resolve(data),
                        /** @namespace Pwa/Util/Request/QueryDispatcher/executeFsyncGet/then */
                        (error) => reject(error)
                    );
            })
        );

        this.promise.promise.then(
            /** @namespace Pwa/Util/Request/QueryDispatcher/then */
            // (data) => this.generalResponseHandling(data, dispatch, options, this.onSuccess),
            (data) => this.onSuccess(data, dispatch, options),

            /** @namespace Pwa/Util/Request/QueryDispatcher/then */
            // (error) => this.generalResponseHandling(error, dispatch, options, this.onError),
            (error) => this.onError(error, dispatch, options),

        );

        listenForBroadCast(name).then(
            /** @namespace Pwa/Util/Request/QueryDispatcher/listenForBroadCast/then */
            // (data) => this.generalResponseHandling(data, dispatch, options, this.onUpdate),
            (data) => this.onUpdate(data, dispatch, options),

        );
    }

    // Some queries return error even in success.
    // We need to logout the user for certain cases.
    // This function will act like a middle ware to perform this task.
    generalResponseHandling(response, dispatch, options, cb) {
        // final call
        cb(response, dispatch, options);
    }
}

export default QueryDispatcher;
