/* eslint-disable no-console */
import { SHOW_ADD_TO_CART_POPUP, UPDATE_POPUP_PRODUCTS } from './AddToCartPopUp.action';

export const initialState = {
    products: {},
    currentItem: {},
    isVisible: false
};

/** @namespace Pwa/Store/AddToCartPopUp/Reducer/AddToCartPopUpReducer */
export const AddToCartPopUpReducer = (state = initialState, action) => {
    const {
        type,
        data,
        isVisible
    } = action;

    switch (type) {
    case UPDATE_POPUP_PRODUCTS:
        // const { isVisible: preIsVisible } = state;
        // if (preIsVisible) {
        //     return state;
        // }

        return {
            ...state,
            products: data.success.products,
            currentItem: data.currentItem
        };

    case SHOW_ADD_TO_CART_POPUP:
        // const da = isVisible ? { isVisible } : { isVisible, products: [] };
        return {
            ...state,
            isVisible
        };
    default:
        return state;
    }
};

export default AddToCartPopUpReducer;
