import {
    TOGGLE_BREADCRUMBS as SourceTOGGLE_BREADCRUMBS,
    toggleBreadcrumbs as SourceToggleBreadcrumbs,
    UPDATE_BREADCRUMBS as SourceUPDATE_BREADCRUMBS,
    updateBreadcrumbs as SourceUpdateBreadcrumbs
} from 'SourceStore/Breadcrumbs/Breadcrumbs.action';

// TODO: implement UPDATE_BREADCRUMBS
export const UPDATE_BREADCRUMBS = SourceUPDATE_BREADCRUMBS;

// TODO: implement TOGGLE_BREADCRUMBS
export const TOGGLE_BREADCRUMBS = SourceTOGGLE_BREADCRUMBS;

// TODO: implement updateBreadcrumbs
export const updateBreadcrumbs = SourceUpdateBreadcrumbs;

// TODO: implement toggleBreadcrumbs
export const toggleBreadcrumbs = SourceToggleBreadcrumbs;
