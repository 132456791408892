/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';

// import { persistStore } from 'redux-persist';
import { STORE_CONFIG_KEY } from 'Component/StoreSwitcher/StoreSwitcher.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    RouterContainer as SourceRouterContainer
} from 'SourceComponent/Router/Router.container';
import { updateConfigDevice } from 'Store/Config/Config.action';
import { HeaderAndFooterDispatcher } from 'Store/HeaderAndFooter';
import { updateMeta } from 'Store/Meta/Meta.action';
import { updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { deleteGuestQuoteId } from 'Util/Cart';
import getStore from 'Util/Store';

// import { store } from '../../store';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
export const ConfigDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Config/Config.dispatcher'
);
export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);
export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const MaintenanceDispatcher = import(
    'Store/Maintenance/Maintenance.dispatcher'
);

/** @namespace Pwa/Component/Router/Container/getCmsBlocksToRequest */
export const getCmsBlocksToRequest = () => {
    const blocks = Object.values(window.contentConfiguration).reduce(
        (acc, config) => [
            ...acc,
            ...Object.entries(config).reduce(
                (acc, [key, identifier]) => ((key.indexOf('cms') === -1)
                    ? acc
                    : [...acc, identifier]
                ),
                []
            )
        ],
        []
    ).filter((value, index, self) => value && self.indexOf(value) === index);

    return blocks.length ? blocks : ['social-links'];
};

/** @namespace Pwa/Component/Router/Container/getHeaderAndFooterOptions */
export const getHeaderAndFooterOptions = () => {
    const { header_content: { header_menu } = {} } = window.contentConfiguration;

    return {
        menu: { identifier: [header_menu || 'new-main-menu'] },
        footer: { identifiers: getCmsBlocksToRequest() }
    };
};

/** @namespace Pwa/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps,
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    updateConfigDevice: (device) => dispatch(updateConfigDevice(device)),
    init: () => {
        const url_store = localStorage.getItem('url_store_code', '');
        const active_store = localStorage.getItem('active_store_code', '');
        const active_store_store_ex_lan = active_store?.split('_') || [];
        const url_store_ex_lan = url_store?.split('_') || [];

        let currentStoreCode = '';
        let nextGoingStorCode = '';
        if (active_store_store_ex_lan.length > 1 && url_store_ex_lan.length > 1) {
            currentStoreCode = active_store_store_ex_lan[1];
            nextGoingStorCode = url_store_ex_lan[1];
        }

        if (currentStoreCode !== nextGoingStorCode) {
            const store = getStore();
            const { dispatch } = store;
            if (active_store_store_ex_lan.length > 1 && active_store_store_ex_lan[1] !== url_store_ex_lan[1]) {
                deleteGuestQuoteId();
                BrowserDatabase.deleteItem(STORE_CONFIG_KEY);
                dispatch(updateCustomerSignInStatus(false));
            }
        }
        localStorage.setItem('active_store_code', url_store);
        // MaintenanceDispatcher.then(
        //     ({ default: dispatcher }) => dispatcher.checkMaintainance(dispatch)
        // );

        ConfigDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        HeaderAndFooterDispatcher.handleData(dispatch, getHeaderAndFooterOptions());

        // MenuDataDispatcher.handleData(dispatch, {});
        // MyAccountDispatcher.then(
        //     ({ default: dispatcher }) => dispatcher.handleCustomerDataOnInit(dispatch)
        // );
        // WishlistDispatcher.then(
        //     ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        // );
        // CartDispatcher.then(
        //     ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        // );
        // ProductCompareDispatcher.then(
        //     ({ default: dispatcher }) => dispatcher.updateInitialProductCompareData(dispatch)
        // );
    }
});

/** @namespace Pwa/Component/Router/Container/RouterContainer */
export class RouterContainer extends SourceRouterContainer {
    // __construct(props) {
    //     super.__construct(props);

    //     this.state = ({
    //         currentUrl: window.location.pathname
    //     });

    //     this.initializeApplication();
    //     this.redirectFromPartialUrl();
    //     this.handleResize();
    // }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    handleCheckIfOnlyMainItemsRender() {
        const { pathname } = location;

        // if (URL_ONLY_MAIN_ITEMS_RENDER.find((url) => pathname.includes(url))) {
        //     return true;
        // }

        return false;
    }
}

export default connect(sourceMapStateToProps, mapDispatchToProps)(RouterContainer);
