/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable new-cap */
import { connect } from 'react-redux';

import {
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps,
    ProductLinksContainer as SourceProductLinksContainer
} from 'SourceComponent/ProductLinks/ProductLinks.container';

import ProductLinks from './ProductLinks.component';

/** @namespace Pwa/Component/ProductLinks/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    // ...SourceMapStateToProps(state)
    linkedProducts: state.LinkedProductsReducer.linkedProducts
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/ProductLinks/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/ProductLinks/Container/ProductLinksContainer */
export class ProductLinksContainer extends SourceProductLinksContainer {
    // TODO implement logic
    render() {
        const {
            linkType,
            linkedProducts: {
                [linkType]: {
                    items = []
                } = {}
            }
        } = this.props;

        if (items.length === 0) {
            return null;
        }

        return (
            <ProductLinks
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductLinksContainer);
