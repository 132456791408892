/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import {
    SAVE_COMPANY_CREDIT_DATA,
    SET_COMPANY_CREDIT_LOADING,
    UPDATE_COMPANY_CREDIT_DATA
} from './CompanyCredit.action';

export const initialState = {
    companyCredit: {},
    isLoading: true
};

/** @namespace Pwa/Store/CompanyCredit/Reducer/CompanyCreditReducer */
export const CompanyCreditReducer = (state = initialState, action) => {
    const {
        isLoading,
        type,
        companyCredit
    } = action;

    const list = state.data;

    switch (type) {
    case UPDATE_COMPANY_CREDIT_DATA:
        return {
            ...state,
            isLoading,
            companyCredit
        };
    case SET_COMPANY_CREDIT_LOADING:
        return {
            ...state,
            isLoading
        };
    case SAVE_COMPANY_CREDIT_DATA:
        const updated_company_profile = {};
        return {
            ...state,
            customer_interest: updated_company_profile,
            isLoading
        };
    default:
        return state;
    }
};

export default CompanyCreditReducer;
