/* eslint-disable no-console */
/* eslint-disable max-len */
export const UPDATE_CUSTOMER_QUOTATIONS_DATA = 'UPDATE_CUSTOMER_QUOTATIONS_DATA_ITEM';
export const SET_CUSTOMER_QUOTATIONS_LOADING = 'SET_CUSTOMER_QUOTATIONS_LOADING';
export const UPDATE_CUSTOMER_QUOTATIONS_DETAILS = 'UPDATE_CUSTOMER_QUOTATIONS_DETAILS';

/** @namespace Pwa/Store/CustomerQuotations/Action/updateCustomerQuotationsData */
export const updateCustomerQuotationsData = ({ customer_quotations = [] }, isLoading = false) => ({
    type: UPDATE_CUSTOMER_QUOTATIONS_DATA,
    customer_quotations,
    isLoading
});

/** @namespace Pwa/Store/CustomerQuotations/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_CUSTOMER_QUOTATIONS_LOADING,
    isLoading
});

/** @namespace Pwa/Store/CustomerQuotations/Action/updateCustomerQuotationsDetails */
export const updateCustomerQuotationsDetails = ({ customerQuote }, isLoading) => ({
    type: UPDATE_CUSTOMER_QUOTATIONS_DETAILS,
    customerQuote,
    isLoading
});
