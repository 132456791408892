/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unreachable */
/* eslint-disable fp/no-let */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-tabs */
// import { PureComponent } from 'react'
import PropTypes from 'prop-types';
import React from 'react';
import OwlCarousel from 'react-owl-carousel';

// import Loader from 'Component/Loader';
// eslint-disable-next-line no-unused-vars
import ProductCard from 'Component/ProductCard';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './FRSlider.style';

export const options = {
    margin: 10,
    responsiveClass: true,
    autoplay: false,
    autoplayHoverPause: false,
    dots: false,
    nav: true,
    navText: [
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="ChevronIcon ChevronIcon_direction_left"><path d="M5.8535 13.707L11.5605 7.99997L5.8535 2.29297L4.4395 3.70697L8.7325 7.99997L4.4395 12.293L5.8535 13.707Z"></path></svg>',
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="ChevronIcon ChevronIcon_direction_right"><path d="M5.8535 13.707L11.5605 7.99997L5.8535 2.29297L4.4395 3.70697L8.7325 7.99997L4.4395 12.293L5.8535 13.707Z"></path></svg>'
    ],
    // loop: false,
    smartSpeed: 250,
    lazyLoad: true,
    responsive: {
        0: {
            items: 2,
            dots: true,
            nav: false,
            autoplay: false
        },
        811: {
            items: 3,
            dots: false,
            nav: true,
            autoplay: false
        }
    }
};

export class FRSlider extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        isRelated: PropTypes.bool
    };

    state = {
        // isProLoader: false
    };

    componentDidMount() {
        // TODO: @Ajeet use setTimeout is not a good way to handle this. Please find a better way.
        // setTimeout(() => {
        //     this.setState({
        //         isProLoader: false
        //     });
        // }, 2200);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({ propsdata: '' });
        }
    }

    handleClick = () => {
        const { handleClick } = this.props;
        if (handleClick) {
            handleClick();
        }
    };

    renderProductSliderItem = (product) => {
        // eslint-disable-next-line no-unused-vars
        const { isRelated = false } = this.props;
        const noNeedPopup = true;

        return (
            <div key={ product.id } className="child">
                <ProductCard
                  isList
                  product={ product }
                  key={ product.id }
                  isRelated={ isRelated }
                  handleClick={ this.handleClick }
                  noNeedPopup={ noNeedPopup }
                />
            </div>
        );
    };

    render() {
        const {
            data = [], count = 0, sloop = false, use_slider
        } = this.props;

        // const { isProLoader } = this.state;
        let opt = options;
        if (count > 0) {
            const responsive = {
                ...options.responsive,
                900: {
                    items: count,
                    loop: sloop,
                    nav: true,
                    dots: false,
                    autoplay: false
                }
            };

            opt = { ...options, responsive };
        }
        let k = '12';
        if (data.length > 0) {
            k = data[0].id;
        }

        if (use_slider === 0) {
            return (
                <div key={ k } className="parent">
                    <div className="product-slider product-grid">
                        { data.map((product) => (
                            this.renderProductSliderItem(product)
                        )) }
                        { /* { isProLoader === false ? (
                            data.map((product) => (
                                this.renderProductSliderItem(product)
                            ))
                        ) : (
                            <Loader isLoading={ isProLoader } />
                        ) } */ }
                    </div>
                </div>
            );
        }

        return (
			<div key={ k } className="parent">
                <div className="product-slider">
                    <OwlCarousel
                      className="owl-theme"
                      autoWidth="true"
                      { ...opt }
                    >
                        { data.map((product) => (
                            this.renderProductSliderItem(product)
                        )) }
                    </OwlCarousel>
                </div>
                { /*
                <div className="product-slider">
                { isProLoader === false ? (
                    <OwlCarousel
                      className="owl-theme"
                      autoWidth="true"
                      { ...opt }
                    >
                        { data.map((product) => (
                            this.renderProductSliderItem(product)
                        )) }
                    </OwlCarousel>
                ) : (
                    <Loader isLoading={ isProLoader } />
                ) }
                </div>
                */ }
   </div>
        );
    }
}

export default FRSlider;
