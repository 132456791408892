/* eslint-disable padding-line-between-statements */
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/StripePayment/Query/StripePaymentQuery */
export class StripePaymentQuery {
    getQuery() {
        return new Field('getallcard')
            .addFieldList([
                'brand',
                'last4digit',
                'year',
                'month',
                'id'
            ]);
    }

    createPaymentIntent(options) {
        return new Field('createPaymentIntent')
            .addArgument('input', 'CreateIntentInput!', options)
            .addField('intent_client_secret');
    }

    getQueryToSaveNewCard(options) {
        return new Field('addstriprcard')
            .addArgument('input', 'CreateIntentInput!', options)
            .addField('message');
    }

    getQueryToRemoveCard(token) {
        return new Field('removestripeCard')
            .addArgument('input', 'RemoveStripeCardInput!', { token })
            .addField('message');
    }

    getPayFlexResponse(cart_id) {
        const input = {
            cart_id
        };

        return new Field('payFlexInput').addArgument(
            'input', 'cartId!', input
        ).addField('payFlexUrl');
    }

    getPayFlexSuccess(orderId, status) {
        const input = {
            orderId,

            status,

            payment_method: {

                code: 'mr_partpay'

            }
        };
        return new Field('payFlexOrderUpdate').addArgument(
            'input', 'orderData!', input
        ).addFieldList([
            'status',

            'orderId',

            'message',

            'method',

            'base_encode'
        ]);
    }

    updateStripeQuery(orderId, status, code, addtional_data) {
        const { pid, client_secret } = addtional_data;
        const input = {
            orderId,
            status,
            payment_method: {
                code
            },
            addtional_data: {
                pid,
                client_secret
            }
        };
        return new Field('stripeOrderUpdate')

            .addArgument('input', 'orderData', input)
            .addFieldList([
                'status',
                'orderId',
                'message',
                'method',
                'base_encode'
            ]);
    }

    magentoPlaceOrder(
        guest_cart_id,
        code,
        pid,
        client_secret,
        payment_method,
        created,
        currency,
        shippingMethodCode,
        shippingCarrierCode,
        shipping_amount
    ) {
        const input = {
            guest_cart_id,
            payment_method: {
                code
            },
            addtional_data: {
                pid,
                client_secret,
                payment_method,
                created,
                currency
            },
            shipping_method: {
                code: shippingMethodCode,
                carrier_code: shippingCarrierCode,
                amount: shipping_amount
            }

        };
        return new Field('magentoPlaceOrder')

            .addArgument('input', 'StripeNewPaymentInput!', input)
            .addFieldList([
                'status',
                'orderId',
                'method'
            ]);
    }

    applePayStripeCheckout(cart_id, payment_intent) {
        return new Field('applepaycheckout')
            .addArgument('cart_id', 'String!', cart_id)
            .addArgument('payment_intent', 'String!', payment_intent)
            .addFieldList([
                'status',
                'quoteid'
            ]);
    }

    /**
 * Places a missed Apple Pay order by constructing the necessary input and GraphQL query.
 *
 * @param {string} guestCartId - The ID of the guest cart.
 * @param {string} clientSecret - The client secret for Stripe.
 * @param {string} status - The status of the order.
 * @returns {Field} - A configured Field object for the stripePlaceOrder mutation.
 */
    placeApplePayMissedOrders(guestCartId, clientSecret, status) {
        const input = {
            guest_cart_id: guestCartId,
            client_secret: clientSecret,
            status
        };

        return new Field('stripePlaceOrder')
            .addArgument('input', 'StripePaymentInput!', input)
            .addFieldList([
                'status',
                'orderId',
                'message',
                'method',
                'base_encode'
            ]);
    }
}

export default new StripePaymentQuery();
