import {
    CategorySortContainer as SourceCategorySortContainer
} from 'SourceComponent/CategorySort/CategorySort.container';

/** @namespace Pwa/Component/CategorySort/Container/CategorySortContainer */
export class CategorySortContainer extends SourceCategorySortContainer {
    // TODO implement logic
    _getLabel(option) {
        const { id, label: pureLabel } = option;
        // eslint-disable-next-line fp/no-let
        let [label] = pureLabel.split(' ');
        label = label.charAt(0).toUpperCase() + label.slice(1);

        switch (id) {
        case 'name':
            return {
                asc: __('Name: A to Z', label),
                desc: __('Name: Z to A', label)
            };
        case 'position':
            return {
                asc: __('Position: Ascending', label),
                desc: __('Position: Descending', label)
            };
        case 'price':
            return {
                asc: __('%s: Low to High', label),
                desc: __('%s: High to Low', label)
            };
        default:
            return {
                asc: __('%s: Ascending', label),
                desc: __('%s: Descending', label)
            };
        }
    }
}

export default CategorySortContainer;
