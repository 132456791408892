/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_TERMS_AND_CONDITIONS_CHECKBOX = 'UPDATE_TERMS_AND_CONDITIONS_CHECKBOX';

/** @namespace Pwa/Store/TermsAndConditions/Action/updateTermsAndConditions */
export const updateTermsAndConditions = (isSatisfied) => ({
    type: UPDATE_TERMS_AND_CONDITIONS_CHECKBOX,
    isSatisfied
});
