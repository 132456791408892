/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Html from 'Component/Html';
import {
    CookiePopup as SourceCookiePopup
} from 'SourceComponent/CookiePopup/CookiePopup.component';

import './CookiePopup.override.style';

/** @namespace Pwa/Component/CookiePopup/Component/CookiePopup */
export class CookiePopup extends SourceCookiePopup {
    renderCTA() {
        return (
            <div
              block="CookiePopup"
              elem="CTA Button"
              onClick={ this.acceptCookies }
              onKeyDown={ this.acceptCookies }
              role="button"
              tabIndex={ 0 }
            >
                    { __('Close') }
            </div>
        );
    }

    renderCookieText() {
        const { cookieText } = this.props;

        return (
            <p block="CookiePopup" elem="Content">
                <Html content={ cookieText } />
            </p>
        );
    }
}

export default CookiePopup;
