/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * CMS Page Query
 * @class CmsPageQuery
 * @namespace Pwa/Query/CmsPage/Query/CmsPageQuery
 */
export class CmsPageQuery {
    /**
     * get CMS Page query
     * @param  {{url_key: String, title: Int, content: String, content_heading: String, page_layout: String, meta_title: String, meta_description: String, meta_keywords, string}} options A object containing different aspects of query, each item can be omitted
     * @return {Query} CMS Page query
     * @memberof CmsPageQuery
     */
    getQuery({ id, url_key, identifier }) {
        if (!id && !url_key && !identifier) {
            throw new Error('Missing argument `id` or `url_key`!');
        }

        const items = this._getPageFields();

        const cmsPage = new Field('cmsPage')
            .addFieldList(items)
            .addField(this._getCmsSliderData())
            .addField(this._getCmsBrochureData());
            // .addField(this._getCmsVacancies());

        if (identifier) {
            cmsPage.addArgument('identifier', 'String!', identifier);
        } else if (id) {
            cmsPage.addArgument('id', 'Int!', id);
        }

        return cmsPage;
    }

    getCmsVacancies() {
        return new Field('vacanciesList')
            .addFieldList([
                'content'
            ]);
    }

    _getCmsBrochureData() {
        return new Field('brochure_data')
            .addField(this._getCmsBrochure());
    }

    _getCmsBrochure() {
        return new Field('brochure')
            .addFieldList([
                'id',
                'skus',
                'img_front_url',
                'img_alt',
                'img_back_url',
                'pdf_url',
                'link_url',
                'link_text',
                'brand',
                'model'
            ]);
    }

    _getCmsSliderData() {
        return new Field('slider_data')
            .addField(this._getCmsSlideDataFields());
    }

    _getCmsSlideDataFields() {
        return new Field('sliderdata')
            .addFieldList([
                'productslidesku',
                this._getCmsSlides(),
                this._getCategory()
            ]);
    }

    _getCmsSlides() {
        return new Field('slides')
            .addFieldList([
                'id',
                'language',
                'image',
                'image_mobile',
                'content_top',
                'content_top_color',
                'content_top_size',
                'content_bottom',
                'content_bottom_color',
                'content_bottom_size',
                'content_position',
                'content_position_vertical',
                'button_text',
                'button_url',
                'slider_category_id'
            ]);
    }

    _getCategory() {
        return new Field('category')
            .addFieldList([
                'id',
                'name',
                'custom_css_desktop',
                'custom_css_tablet',
                'custom_css_mobile'
            ]);
    }

    _getPageFields() {
        return [
            'title',
            'content',
            'page_width',
            'content_heading',
            'meta_title',
            'meta_description',
            'meta_keywords'
        ];
    }
}

export default new CmsPageQuery();
