/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import FirstVisitPrivacyPolicyPopup from '../component/FirstVisitPrivacyPolicyPopup';
import PrivacyPolicyPopup from '../component/PrivacyPolicyPopup';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const BEFORE_ITEMS_TYPE = (originalMember) => ([
    ...originalMember,
    {
        component: <PrivacyPolicyPopup />,
        position: 9999
    },
    {
        component: <FirstVisitPrivacyPolicyPopup />,
        position: 9998
    }
]);

export default {
    'Component/Router/Component': {
        'member-property': {
            BEFORE_ITEMS_TYPE
        }
    }
};
