/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable operator-linebreak */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import {
    faCircleQuestion
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CloseIcon from 'Component/CloseIcon';
import Html from 'Component/Html';
import VehicleFinder from 'Component/VehicleFinder';
import {
    CategoryDetails as SourceCategoryDetails
} from 'SourceComponent/CategoryDetails/CategoryDetails.component';

import './CategoryDetails.style';

/** @namespace Pwa/Component/CategoryDetails/Component/CategoryDetails */
export class CategoryDetails extends SourceCategoryDetails {
    // TODO implement logic

    state = {
        showBtn: true,
        clickBtn: true
    };

    renderCategoryName() {
        const { category: { name, id } } = this.props;

        if (id && !name) {
            return null;
        }

        return (
            <span>
                {/* <TextPlaceholder content={ name } /> */}
                { name }
            </span>
        );
    }

    renderCategoryDescription = () => {
        const {
            category: { description, id },
            isCurrentCategoryLoaded
        } = this.props;

        if (!id || !isCurrentCategoryLoaded) {
            return this.renderCategoryDescriptionPlaceholder();
        }

        if (!description) {
            return null;
        }

        return <Html content={ description } />;
    };

    renderCategoryHeadingImg() {
        const { category: { name, image, id }, isCurrentCategoryLoaded } = this.props;

        // if ((id && !image) || !isCurrentCategoryLoaded) {
        //     return (
        //         <div className="CategoryDetails-Picture no_image">
        //             <div className="img-overlay" />
        //         </div>
        //     );
        // }

        return (
            <>
            {/* <div className="CategoryDetails-Picture">
                <img src={ `${image}` } alt={ name } width="1920" height="140" />
                <div className="img-overlay" />
            </div> */}
            <div className="category_heading">
                <h1 className="categoryName">
                    { this.renderCategoryName() }
                </h1>
            </div>
            </>
        );
    }

    showHideDescription = () => {
        const { showBtn, clickBtn } = this.state;
        if (clickBtn) {
            this.setState({ showBtn: false });
            this.setState({ clickBtn: false });
        } else {
            this.setState({ showBtn: true });
            this.setState({ clickBtn: true });
        }
    };

    render() {
        const {
            category: { description }
        } = this.props;

        const { showBtn } = this.state;

        let className = '';
        if (!showBtn) {
            className = 'showDesc';
        }
        if (!description) {
            return (
                <article block="CategoryDetails">
                    { ' ' }
                </article>
            );
        }

        return (
            <article block="CategoryDetails">
                <div className="CategoryDetails-Vehicle">
                    <VehicleFinder />
                </div>
                { this.renderCategoryHeadingImg() }
                <div block="CategoryDetails" elem="Description">
                    <span
                      block="CategoryDetails"
                      elem="Heading"
                      onClick={ this.showHideDescription }
                    >
                    { __('Learn more') }
                    {/* { this.renderCategoryName() } */}
                    <FontAwesomeIcon icon={ faCircleQuestion } />
                    </span>
                    <div className={`cat-desc-text-float ${className}`}>
                        <div className="clsBtn" onClick={ this.showHideDescription }>
                            <CloseIcon />
                        </div>
                        { this.renderCategoryDescription() }
                    </div>
                </div>
            </article>
        );
    }
}

export default CategoryDetails;
